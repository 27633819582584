import styled from "styled-components";
import { colorsDeprecated as c } from "global-style";
import colors from "theme/colors";

export const FlexContainer = styled.div`
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    img {
        width: 1.5rem;
    }
`;

export const CanceledDate = styled.small`
    color: ${colors.text};
    white-space: nowrap;
`;

export const ItemFrequency = styled.div`
    margin-top: 0.2rem;
`;
