import Hint from "components/Hint";
import styled from "styled-components";
import { BillDateContainer } from "../TransferBillDateMultipleField/style";
import { sizes as s } from "global-style";
export const WarnDates = styled(Hint)`
    /* This is used to override "Field > Hint" rule from Field */
    ${BillDateContainer} & {
        margin: 0;
        @media screen and (min-width: ${s.sm}) {
            margin-block-start: 0.7em;
        }
    }
`;
