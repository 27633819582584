import * as S from "./styles";
import React, { useEffect, useRef, useState } from "react";
import { InputProps, InputSizes } from "components/Input";

export interface InputWithAddonProps extends InputProps {
    inputProps?: InputProps;
    addon: {
        content: string;
        position?: "left" | "right";
    };
}

export const InputWithAddon: React.FunctionComponent<InputWithAddonProps> = ({
    inputProps,
    addon: { content, position = "left" },
    ...props
}) => {
    const [addonWidth, setAddonWidth] = useState<number>(0);
    const addonRef = useRef<HTMLSpanElement>(null);

    // Calculate addon width to prevent overlapping content
    useEffect(() => {
        if (!content) return;

        const handleResize = () => {
            if (addonRef.current) {
                setAddonWidth(addonRef.current.getBoundingClientRect().width);
            }
        };

        window.removeEventListener("resize", handleResize);
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, [content]);

    const inputSize = inputProps?.inputSize || InputSizes.Medium;

    return (
        <S.LabelWrapper htmlFor={inputProps?.name}>
            <S.InputComponent
                $addonWidth={addonWidth}
                $addonPosition={position}
                {...inputProps}
                {...props}
            />
            <S.AddonContainer
                ref={addonRef}
                position={position}
                $inputSize={inputSize}
            >
                {content}
            </S.AddonContainer>
        </S.LabelWrapper>
    );
};

export default InputWithAddon;
