import { createElement } from "react";
import * as S from "./style";

interface IconProps {
    src?: string;
    alt?: string;
    img?: JSX.Element;
    wrapped?: boolean;
    colorize?: string;
    disabled?: boolean;
    round?: boolean;
    tighten?: boolean;
    fit?: boolean;
    size?: string;
    block?: boolean;
}

const Icon = ({
    src = ``,
    alt = ``,
    img = undefined,
    wrapped = undefined,
    colorize = undefined,
    disabled = undefined,
    round = undefined,
    tighten = undefined,
    fit = undefined,
    size = `100%`,
    block,
    ...props
}: IconProps) => {
    if (img) {
        img = createElement(S.Img, {
            ...img.props,
            colorize,
            disabled,
            round,
            tighten,
            fit,
            size,
            as: img.type,
        });
    }
    return (
        <>
            <S.Icon
                // @ts-ignore
                dataSrc={wrapped && !img ? `` : src}
                wrapped={wrapped}
                colorize={colorize}
                disabled={disabled}
                round={round}
                tighten={tighten}
                fit={fit}
                size={size}
                block={block}
                {...props}
            >
                {wrapped && !img && <img src={src} alt={alt} />}
                {img && img}
            </S.Icon>
        </>
    );
};

export default Icon;
