import { Company } from "company/types";
import * as S from "./style";
import { Ref } from "react";
import { itemAmountForDisplay, itemFrequencyForDisplay } from "utils/items";

export interface SelectMultipleItemOptionProps {
    item: Company.Item;
}

export type SelectMultipleItemOptionRef = {};

function SelectMultipleItemOption(
    { item }: SelectMultipleItemOptionProps,
    ref: Ref<SelectMultipleItemOptionRef>
) {
    return (
        <S.ItemOption>
            <span>{item.name}</span>
            <span>
                {itemAmountForDisplay(item.amount)}
                {" / "}
                {itemFrequencyForDisplay(item.frequency)}
            </span>
        </S.ItemOption>
    );
}

export default SelectMultipleItemOption;
