import styled from "styled-components";
import colors from "theme/colors";

export const Info = styled.svg`
    width: 1em;
    height: 1em;
    fill: ${colors.primary};
    vertical-align: middle;
    margin-top: -0.1em;
`;

export const Loading = styled.h2`
    text-align: center;
    margin: 2rem 0;
`;
