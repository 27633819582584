import * as S from "./style";
import { CheckoutItem, ItemTokenPrice } from "checkout/types";
import { ItemSourceType } from "company/types";
import { toDollar } from "utils/financial";
import { firstToLower, firstToUpper } from "utils/strings";
import { TotalDueLater, useCheckoutData } from "checkout/context/CheckoutData";
import DescriptionList, {
    DescriptionListItem,
} from "components/DescriptionList";
import CouponForm from "checkout/components/CouponForm";
import { useNetworkAndToken } from "checkout/context/NetworkAndToken";
import ItemTokenPriceForDisplay from "./ItemTokenPriceForDisplay";
import TotalPriceForDisplay from "./TotalPriceForDisplay";

// Function to format the display date from seconds
const formatDateForDisplay = (seconds: number) =>
    new Date(seconds).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
    });

const ItemList = () => {
    const {
        items,
        mainItem,
        fetchLoading,
        totalDueLaterGroupedByDueDate,
        isInvoicedCheckout,
    } = useCheckoutData();

    const { selectedNetwork, selectedToken } = useNetworkAndToken();

    if (fetchLoading || !mainItem) return <></>;

    const anyStripeItems = items.some(
        (item) => item.sourceId === ItemSourceType.Stripe
    );

    // Line items
    const itemsList: DescriptionListItem[] = items.map((item) => {
        const { name, priceMetadata } = item;

        // Fun begins:
        // - Item can be one time payment or subscription
        // - Item can have initialOffset (# free trials days)
        // - Item can have applicable coupon (% or $ off)
        //   - Coupon can be forever
        //   - Coupon can be limited (duration in days)
        //   - Coupon can be once, relevant for first scheduled subscription payment

        // Constructing the itemized list for UI display
        return {
            term: (
                // Displaying the item name, possibly with additional metadata
                <S.SummaryOneLine>
                    <b>{firstToUpper(name)}</b>
                    {priceMetadata && (
                        <small>{firstToUpper(priceMetadata)}</small>
                    )}
                </S.SummaryOneLine>
            ),
            definition: (
                // Displaying the item price and secondary line
                <>
                    <S.SummaryTwoLine>
                        <PrimaryPriceForDisplay item={item} />
                        <SecondaryPriceForDisplay item={item} />
                    </S.SummaryTwoLine>
                </>
            ),
            style: S.ItemRow,
        };
    });

    // Subtotal if more than one item
    if (items.length > 1) {
        itemsList.push({
            term: `Subtotal`,
            definition: (
                <S.PrimaryPrice>
                    <TotalPriceForDisplay />
                </S.PrimaryPrice>
            ),
            style: S.SummaryRow,
        });
    }

    // Total due now
    const subTotalsList: DescriptionListItem[] = [
        {
            term: (
                <>
                    <div>
                        <b>
                            Total due
                            {mainItem.isOneTimePayment ? `` : ` today`}
                        </b>
                    </div>
                </>
            ),
            definition: (
                <S.PrimaryPrice bold>
                    <TotalPriceForDisplay />
                </S.PrimaryPrice>
            ),
            style: S.SummaryRow,
        },
    ];

    subTotalsList.push(
        ...Object.keys(totalDueLaterGroupedByDueDate).reduce(
            (dates: DescriptionListItem[], dueDate) => {
                let dueDateNumber = Number(dueDate);
                let dueDateRow: TotalDueLater =
                    totalDueLaterGroupedByDueDate[dueDateNumber];

                const dueDatePriceMatchSelected =
                    selectedNetwork &&
                    selectedToken &&
                    dueDateRow?.tokens.find(
                        (token) =>
                            token.network === selectedNetwork.id &&
                            token.tokenAddress === selectedToken.address
                    );

                const amount = dueDatePriceMatchSelected ? (
                    <ItemTokenPriceForDisplay
                        itemTokenPrice={
                            dueDatePriceMatchSelected as ItemTokenPrice
                        }
                    />
                ) : dueDateRow.usd !== null ? (
                    toDollar(dueDateRow.usd)
                ) : null;

                if (!amount) return dates;

                return [
                    ...dates,
                    {
                        term: (
                            <>
                                <div>
                                    Due{" "}
                                    {new Date(dueDateNumber).toLocaleDateString(
                                        "en-US",
                                        {
                                            year: "numeric",
                                            month: "short",
                                            day: "numeric",
                                        }
                                    )}
                                </div>
                            </>
                        ),
                        definition: amount,
                        style: S.ItemRow,
                    },
                ];
            },
            []
        )
    );

    return (
        <>
            <DescriptionList items={itemsList} />
            {anyStripeItems && !isInvoicedCheckout && (
                <S.CouponFormWrapper>
                    <CouponForm />
                </S.CouponFormWrapper>
            )}
            <DescriptionList items={subTotalsList} />
        </>
    );
};

const PrimaryPriceForDisplay = ({
    item: {
        isVariablePricing,
        hasInitialOffset,
        hasCoupon,
        amountAfterDiscountForDisplay,
        amountForDisplay,
        initialOffsetForDisplay,
        prices,
    },
}: {
    item: CheckoutItem;
}) => {
    const { usdTotalWithoutDiscount } = useCheckoutData();
    const { selectedNetwork, selectedToken } = useNetworkAndToken();

    // We have a price that match the user's network/token selection
    const itemTokenPriceMatchesSelected =
        selectedNetwork &&
        selectedToken &&
        prices.find(
            (price) =>
                price.network === selectedNetwork.id &&
                price.tokenAddress === selectedToken.address
        );

    // The actual price we show, can be original amount, free trials, discounted amount
    // Default to item's price
    let pricePrimary = itemTokenPriceMatchesSelected ? (
        <ItemTokenPriceForDisplay
            itemTokenPrice={itemTokenPriceMatchesSelected}
        />
    ) : (
        <S.Price>{amountForDisplay}</S.Price>
    );

    // Initial offset
    if (isVariablePricing) {
        pricePrimary = <S.Price>{amountForDisplay}</S.Price>;
    } else if (hasInitialOffset) {
        pricePrimary = <S.Price>{initialOffsetForDisplay}</S.Price>;
    } else if (hasCoupon) {
        pricePrimary = (
            <S.Price>
                {itemTokenPriceMatchesSelected?.amountAfterDiscountForDisplay ||
                    amountAfterDiscountForDisplay}
            </S.Price>
        );
    }

    // The original price, striked if initialOffset or coupon is applicable
    const priceStriked = (hasInitialOffset || hasCoupon) && !isVariablePricing;

    const itemTokenPriceUsdc = prices.find((token) => token.symbol === "USDC");

    const itemTokenPriceFirst = prices[0];

    // [ ] This logic is duplicated in TotalPriceForDisplay.tsx
    // Price display logic
    // 1. Show the token price matching user selected network/token if any, or
    // 2. Show USD price if available, or
    // 3. Show the USDC token price if available, or
    // 4. Show the first token price if available, or
    // 5. Show "No price" (=> internal issue with item's configuration, has no usd or token price)
    return (
        <S.PrimaryPrice bold>
            {itemTokenPriceMatchesSelected ? (
                <>
                    {priceStriked && (
                        <>
                            <S.StrikedPrice>
                                <ItemTokenPriceForDisplay
                                    itemTokenPrice={
                                        itemTokenPriceMatchesSelected
                                    }
                                    includeUsd={false}
                                />
                            </S.StrikedPrice>{" "}
                        </>
                    )}
                    {pricePrimary}
                </>
            ) : usdTotalWithoutDiscount ? (
                <>
                    {priceStriked && (
                        <>
                            <S.StrikedPrice>{amountForDisplay}</S.StrikedPrice>{" "}
                        </>
                    )}
                    {pricePrimary}
                </>
            ) : itemTokenPriceUsdc ? (
                <ItemTokenPriceForDisplay itemTokenPrice={itemTokenPriceUsdc} />
            ) : itemTokenPriceFirst ? (
                <ItemTokenPriceForDisplay
                    itemTokenPrice={itemTokenPriceFirst}
                />
            ) : (
                <small>No Price</small>
            )}
        </S.PrimaryPrice>
    );
};

const SecondaryPriceForDisplay = ({
    item: {
        isVariablePricing,
        isOneTimePayment,
        hasInitialOffset,
        amountAfterDiscountForDisplay,
        frequencyForDisplay,
        couponDetail,
        amountForDisplay,
        prices,
    },
}: {
    item: CheckoutItem;
}) => {
    // Additional information about the price, if initialAmount or discounted
    let priceSecondary;

    const { selectedNetwork, selectedToken } = useNetworkAndToken();

    // We have a price that match the user's network/token selection
    const itemTokenPriceMatchesSelected =
        selectedNetwork &&
        selectedToken &&
        prices.find(
            (price) =>
                price.network === selectedNetwork.id &&
                price.tokenAddress === selectedToken.address
        );

    const priceForDisplay = itemTokenPriceMatchesSelected ? (
        <ItemTokenPriceForDisplay
            itemTokenPrice={itemTokenPriceMatchesSelected}
            includeUsd={false}
        />
    ) : (
        amountForDisplay
    );

    amountAfterDiscountForDisplay =
        itemTokenPriceMatchesSelected?.amountAfterDiscountForDisplay ||
        amountAfterDiscountForDisplay;

    // One time payment, secondary line
    if (!isVariablePricing && isOneTimePayment && hasInitialOffset) {
        priceSecondary = `Then ${amountAfterDiscountForDisplay}`;
    } else if (!isOneTimePayment) {
        const frequencyLower = firstToLower(frequencyForDisplay);

        // Determine the secondary price based on coupon and offset conditions
        if (couponDetail) {
            if (isVariablePricing) {
                priceSecondary = frequencyForDisplay;
            } else if (hasInitialOffset) {
                if (couponDetail.singleUse) {
                    // Single Use Coupon with Initial Offset
                    priceSecondary = (
                        <>
                            {amountAfterDiscountForDisplay}, then{" "}
                            {priceForDisplay} {frequencyLower}
                        </>
                    );
                } else if (couponDetail.couponDurationSeconds) {
                    // Duration-limited Coupon with Initial Offset
                    priceSecondary = (
                        <>
                            Then {amountAfterDiscountForDisplay}{" "}
                            {frequencyLower} until{" "}
                            {formatDateForDisplay(
                                couponDetail.couponDurationSeconds
                            )}
                        </>
                    );
                } else {
                    // Forever Coupon with Initial Offset
                    priceSecondary = (
                        <>
                            Then {amountAfterDiscountForDisplay}{" "}
                            {frequencyLower}
                        </>
                    );
                }
            } else {
                if (couponDetail.singleUse) {
                    // Single Use Coupon without Initial Offset
                    priceSecondary = (
                        <>
                            Then {priceForDisplay} {frequencyLower}
                        </>
                    );
                } else if (couponDetail.couponDurationSeconds) {
                    // Duration-limited Coupon without Initial Offset
                    priceSecondary = (
                        <>
                            Then {amountAfterDiscountForDisplay}{" "}
                            {frequencyLower} until{" "}
                            {formatDateForDisplay(
                                couponDetail.couponDurationSeconds
                            )}
                        </>
                    );
                } else {
                    // Forever Coupon without Initial Offset
                    priceSecondary = frequencyForDisplay;
                }
            }
        } else if (hasInitialOffset) {
            // Only Initial Offset is present, no coupon
            priceSecondary = (
                <>
                    Then {priceForDisplay} {frequencyLower}
                </>
            );
        } else {
            // Default case when no coupon and no initial offset is applied
            priceSecondary = frequencyForDisplay;
        }
    }

    return priceSecondary ? <small>{priceSecondary}</small> : <></>;
};

export default ItemList;
