import { useState, useEffect, useRef, useCallback } from "react";

export interface DataLoaderStatus {
    error: string;
    loading: boolean;
    isError: boolean;
    isSuccess: boolean;
}

interface FetchResponse<T> extends DataLoaderStatus {
    data: T | undefined;
    lastFetched: number;
    refetch: (showLoading?: boolean, options?: any) => Promise<any>;
    reset: () => void;
    abort: () => void;
}

export interface Fetcher {
    <T = any>(
        endpoint: string | string[] | undefined,
        optionsIn?: any,
        fetchImmediately?: boolean
    ): FetchResponse<T>;
}

// Next upgrade: Allow the option to queue fetch() calls
// prettier-ignore
const useFetch: Fetcher = <T = any>(
    endpoint: string | string[] | undefined,
    optionsIn: any = {},
    fetchImmediately: boolean = true
) => {
    const [data, setData] = useState<T | undefined>(undefined);
    const [error, setError] = useState<any>(``);
    const [isError, setIsError] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<any>(fetchImmediately);
    const options = useRef<any>({ ...optionsIn });
    const [lastFetched, setLastFetched] = useState<number>(0);
    const controller = new AbortController();

    const getData = useCallback(
        async (url: string) => {
            try {
                const response = await fetch(url, {
                    ...options.current,
                    signal: controller.signal,
                }).catch((error) => {
                    throw new Error(error);
                });

                if (response.status !== 200) {
                    throw new Error(String(response.status));
                }
                const data = await response.json();
                if (typeof data !== `object`) {
                    throw new Error(`Invalid data format`);
                }

                return Promise.resolve(data);
            } catch (e) {
                if (e instanceof DOMException && e.name === `AbortError`)
                    return Promise.reject();

                setError(`There was a problem fetching the requested data`);
                return Promise.reject(e);
            }
        },
        [options.current]
    );

    const refetch = useCallback(
        async (showLoading = false, optionsIn?: any): Promise<void> => {
            // Reset states
            setIsError(false);
            setIsSuccess(false);
            setLoading(true);
            setData(undefined);

            if (!endpoint || (Array.isArray(endpoint) && endpoint.length < 1)) {
                setError(`There was a problem fetching data`);
                setIsError(true);
                return;
            }

            if (optionsIn) {
                options.current = { ...optionsIn };
            }

            if (showLoading) setLoading(true);
            setError(``);

            if (Array.isArray(endpoint)) {
                /* 
                NOTE: One limitation of sending endpoints as a string[] is that the return
                JSON must be structured as an object, with a property named for the value,
                as the data will be merged together. IE:

                ENDPOINT 1: { "tokens": [ {name: "USDC"}, {name: "WETH"} ] }
                ENDPOINT 2: { "networks": [ {name: "Mainnet"}, {name: "Goerli"} ] }

                RESULT: 
                { 
                    "tokens": [ {name: "USDC"}, {name: "WETH"} ] },
                    "networks": [ {name: "Mainnet"}, {name: "Goerli"} ]
                } 
                */

                const urls: string[] = [...endpoint];

                // Make all endpoint requests
                const requests = urls.map(
                    async (u) =>
                        await getData(u).catch((error) => Promise.reject(error))
                );
                const results = await Promise.allSettled(requests);

                const { success, result } = await results.reduce(
                    async (
                        fetchCheck: Promise<{ success: boolean; result: any }>,
                        data,
                        index
                    ) => {
                        const { success, result } = await fetchCheck;

                        // If the Promise from getData() was not resolved
                        if (data.status !== `fulfilled`) {
                            // Give it another try
                            const retryData = await getData(urls[index]).catch(
                                (error) => {
                                    setError(
                                        `There was a problem fetching the requested data`
                                    );
                                    setIsError(true);
                                }
                            );

                            // Failed again
                            if (!retryData) {
                                return {
                                    success: false,
                                    result: { ...result },
                                };
                            }

                            // Second attempt succeeded, return the retry data
                            return {
                                success,
                                result: { ...result, retryData },
                            };
                        }

                        return {
                            success,
                            result: { ...result, ...data.value },
                        };
                    },
                    Promise.resolve({
                        success: true,
                        result: {},
                    })
                );

                // If any of the endpoints failed, reset the data to undefined
                if (success) {
                    setIsSuccess(true);
                    setData(result);
                } else {
                    setIsError(true);
                }
            } else {
                await getData(endpoint)
                    .then((response) => {
                        setData(response);
                        setIsSuccess(true);
                    })
                    .catch((error) => {
                        setIsError(true);
                        setError(
                            `There was a problem fetching the requested data`
                        );
                    });
            }

            setLastFetched(Date.now());
            setLoading(false);
        },
        [endpoint, getData]
    );

    const reset = () => {
        abort();
        setData(undefined);
        setError(``);
        setIsError(false);
        setIsSuccess(false);
        setLoading(false);
    };

    const abort = () => {
        controller.abort();
    };

    useEffect(() => {
        (async () => {
            if (fetchImmediately) {
                await refetch(true);
            }
        })();
    }, []);

    return {
        data,
        error,
        loading,
        lastFetched,
        refetch,
        reset,
        abort,
        isError,
        isSuccess,
    };
};

export { useFetch };
