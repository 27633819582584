import styled, { css } from "styled-components";
import {
    colorsDeprecated as c,
    properties as p,
    sizes as s,
} from "global-style";
import Modal from "components/Modal";
import Card from "components/Card";
import Button from "components/Button";
import EnvironmentTag from "components/EnvironmentTag";
import colors from "theme/colors";

export const Login = styled(Modal)<{ environment: string | undefined }>`
    display: flex;
    flex-direction: column;
    z-index: ${p.zIndex.login};
    ${({ environment }) =>
        environment === `demo`
            ? css`
                  background-color: #99cbff;
                  background-image: none;
              `
            : ``}
`;

export const Logo = styled.header`
    width: max-content;
    margin: 1rem;
    color: ${c.onModalLight};
    text-align: center;
    align-self: flex-start;
    @media screen and (min-width: ${s.sm}) {
        margin: 1rem;
    }
    img {
        height: 3em;
    }
`;

export const Middle = styled.article`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0 1rem;
`;

export const Prompt = styled(Card)`
    max-width: 30rem;

    @media screen and (min-width: ${s.sm}) {
        padding: 3rem;
    }

    h1 {
        font-size: 1.25rem;
        margin: 1em 0;
    }
`;

export const Wallet = styled.section`
    overflow: hidden;
    border: 1px solid ${colors.primary};
    border-radius: 1rem;
    display: grid;
    grid-template-columns: auto 1fr auto;
`;
export const Connected = styled.span`
    font-weight: 700;
    background-color: ${c.loopColB};
    color: ${colors.textOnPrimary};
    padding: 0.2em 0.6em;
    line-height: 1.3;
    display: flex;
    align-items: center;
`;
export const Address = styled.div`
    display: grid;
    column-gap: 0.25rem;
    grid-template-areas: "icon address" "icon network";
    grid-template-columns: auto 1fr;
    padding: 0.2em 0.6em;
    text-align: left;
    line-height: 1.1;
`;
export const WalletIcon = styled.img`
    grid-area: icon;
    height: 1.5rem;
    align-self: center;
`;
export const Network = styled.small`
    grid-area: network;
    display: block;
    font-size: 0.7rem;
`;
export const EditWallet = styled.span`
    display: flex;
    align-items: center;
    background-color: ${c.lineOnSurface};
    border-inline-start: 1px solid ${colors.primary};
    padding: 0.2em 0.3em;
    line-height: 1.2;
`;

export const Connect = styled.section`
    margin: 1rem 0;
`;

export const ConnectBtn = styled(Button)`
    margin: 0.25rem 0;
`;

export const Terms = styled.footer`
    & > p {
        font-size: 0.8em;
    }
`;

export const EnvBadge = styled(EnvironmentTag)`
    margin-block: 0.5rem;
`;
