import styled, { css } from "styled-components";
import colors from "theme/colors";
import radius from "theme/radius";
import { fontSizes, fontWeight, lineHeights } from "theme/typography";
import spacing from "theme/spacing";
import { Link } from "react-router-dom";
import Spinner from "components/Spinner";
import { ButtonVariants, ButtonSizes } from ".";

export const ButtonSpinner = styled(Spinner)``;

const sharedButtonStyles = {
    common: css`
        border-radius: ${radius.md};
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: ${spacing.xxs};
        text-align: center;
        font-weight: ${fontWeight.bold};
        text-decoration: none;
    `,
    disabled: css`
        background-color: ${colors.neutralLight};
        border-color: ${colors.neutralLight};
        color: ${colors.neutral50};
    `,
    disabledOutlined: css`
        border-color: ${colors.neutralLight};
        color: ${colors.neutral50};
    `,
};

const buttonVariantStyles = (variant: ButtonVariants) => {
    switch (variant) {
        case ButtonVariants.Primary:
            return css`
                background-color: ${colors.primary40};
                color: ${colors.white};
                border: 1px solid ${colors.primary40};
                &:hover {
                    background-color: ${colors.primary50};
                    border-color: ${colors.primary50};
                }
                &:active {
                    background-color: ${colors.primary30};
                    border-color: ${colors.primary30};
                }
                &:focus,
                &:focus-within {
                    background-color: ${colors.primary40};
                    border-color: ${colors.primary40};
                }

                &:disabled {
                    ${sharedButtonStyles.disabled}
                }
            `;
        case ButtonVariants.PrimaryOutlined:
            return css`
                background-color: ${colors.transparent};
                color: ${colors.primary40};
                border: 1px solid ${colors.primary40};

                &:hover {
                    color: ${colors.primary50};
                    border-color: ${colors.primary50};
                }
                &:active {
                    color: ${colors.primary30};
                    border-color: ${colors.primary30};
                }
                &:focus,
                &:focus-within {
                    color: ${colors.primary40};
                    border-color: ${colors.primary40};
                }
                &:disabled {
                    ${sharedButtonStyles.disabledOutlined}
                }
            `;
        case ButtonVariants.PrimaryBorderless:
            return css`
                background-color: ${colors.transparent};
                color: ${colors.primary40};
                border: 1px solid ${colors.transparent};

                &:hover {
                    color: ${colors.primary50};
                }
                &:active {
                    color: ${colors.primary30};
                }
                &:focus,
                &:focus-within {
                    color: ${colors.primary40};
                }

                &:disabled {
                    color: ${colors.neutral50};
                }
            `;
        case ButtonVariants.Neutral:
            return css`
                background-color: ${colors.neutralDark};
                color: ${colors.white};
                border: 1px solid ${colors.neutralDark};
                &:hover {
                    background-color: ${colors.neutral50};
                }
                &:active {
                    background-color: ${colors.neutral30};
                }
                &:focus,
                &:focus-within {
                    background-color: ${colors.neutral40};
                }

                &:disabled {
                    ${sharedButtonStyles.disabled}
                }
            `;
        case ButtonVariants.NeutralOutlined:
            return css`
                background-color: ${colors.transparent};
                color: ${colors.neutralDark};
                border: 1px solid ${colors.neutralDark};
                &:hover {
                    color: ${colors.neutral50};
                    border-color: ${colors.neutral50};
                }
                &:active {
                    color: ${colors.neutral30};
                    border-color: ${colors.neutral30};
                }
                &:focus,
                &:focus-within {
                    color: ${colors.neutralDark};
                    border-color: ${colors.neutralDark};
                }
                &:disabled {
                    ${sharedButtonStyles.disabledOutlined}
                }
            `;
        case ButtonVariants.Anchor:
            return css`
                padding: 0;
                border: none;
                background: none;
                box-shadow: none;
                border: none;
                border-radius: 0;
                color: ${colors.primary40};
                font-weight: ${fontWeight.normal};
                font-size: inherit;

                &:hover {
                    color: ${colors.primary50};
                    border-color: ${colors.neutral50};
                }
                &:active {
                    color: ${colors.primary30};
                }
                &:focus,
                &:focus-within {
                    color: ${colors.primary40};
                }
                &:disabled {
                    color: ${colors.neutral50};
                }
            `;
    }
};

const buttonSizeStyles = (size: ButtonSizes) => {
    switch (size) {
        case ButtonSizes.Small:
            return css`
                font-size: ${fontSizes.xs};
                padding: ${spacing.xxxs} ${spacing.xxs};
                line-height: ${lineHeights.md};
                gap: ${spacing.xxxs};
                ${ButtonSpinner} {
                    height: ${fontSizes.xs};
                    width: ${fontSizes.xs};
                }
            `;
        case ButtonSizes.Medium:
            return css`
                font-size: ${fontSizes.sm};
                padding: ${spacing.xxs} ${spacing.sm};
                line-height: ${lineHeights.xl};
                gap: ${spacing.xxs};
                ${ButtonSpinner} {
                    height: ${fontSizes.sm};
                    width: ${fontSizes.sm};
                }
            `;
        case ButtonSizes.Large:
            return css`
                font-size: ${fontSizes.md};
                padding: ${spacing.xs} ${spacing.md};
                line-height: ${lineHeights.xl};
                gap: ${spacing.xs};
                ${ButtonSpinner} {
                    height: ${fontSizes.md};
                    width: ${fontSizes.md};
                }
            `;
    }
};

const baseButtonStyles = css<{
    $variant: ButtonVariants;
    $size: ButtonSizes;
    $full: boolean;
    $disabled: boolean;
    $loading: boolean;
    $anchorVariantUnderlined: boolean;
}>`
    ${sharedButtonStyles.common}
    ${({ $size }) => buttonSizeStyles($size)}
    ${({ $variant }) => buttonVariantStyles($variant)}
    width: ${({ $full }) => ($full ? `100%` : `max-content`)};

    ${({ $anchorVariantUnderlined, $variant }) =>
        $variant === ButtonVariants.Anchor &&
        $anchorVariantUnderlined &&
        css`
            text-decoration: underline;
        `}

    ${({ $disabled }) =>
        $disabled &&
        css`
            pointer-events: none;
        `};

    ${({ $loading }) =>
        $loading &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `};
`;

export const Button = styled.button`
    ${baseButtonStyles}
`;

export const LinkButton = styled(Link)`
    ${baseButtonStyles}
`;

export const AnchorButton = styled.a`
    ${baseButtonStyles}
`;

export const ButtonNoStyles = styled.button`
    padding: 0;
    margin: 0;
    border: none;
    box-shadow: none;
    border-radius: 0;
    background-color: transparent;
    line-height: inherit;
    color: inherit;
`;
