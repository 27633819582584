import { Company } from "company/types";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";
import * as S from "./style";
import { useMemo } from "react";
import { useGetNetworks } from "hooks/useGetNetworks";
import TokenTableCell from "company/components/TokenTableCell";

export interface ItemTokensCellProps {
    item: Company.Item;
}

export type ItemTokensCellRef = {};

export default function ItemTokensCell({ item }: ItemTokensCellProps) {
    const { tokens, getTokensMetadataIsLoading, getTokensMetadataIsError } =
        useGetTokensMetadata();
    const { networks, getNetworksIsLoading, getNetworksIsError } =
        useGetNetworks();

    const acceptedTokens = useMemo(() => {
        return tokens.filter((token) => {
            if (!item.acceptedTokensByNetwork[token.networkId]) return false;
            return item.acceptedTokensByNetwork[token.networkId].includes(
                token.address
            );
        });
    }, [item.acceptedTokensByNetwork, tokens]);

    const itemNetworks = useMemo(() => {
        const networkIds = Object.keys(item.acceptedTokensByNetwork).map((n) =>
            Number(n)
        );
        return networks.filter((network) => networkIds.includes(network.id));
    }, [item.acceptedTokensByNetwork, networks]);

    if (getTokensMetadataIsLoading || getNetworksIsLoading)
        return <p>Loading</p>;
    if (getTokensMetadataIsError || getNetworksIsError) return <p>Error</p>;

    return (
        <S.NetworksList>
            {itemNetworks.map((network) => (
                <li key={network.id}>
                    <strong>{network.name}</strong>:{" "}
                    <S.TokensList>
                        {acceptedTokens
                            .filter((t) => t.networkId === network.id)
                            .map((token) => (
                                <S.TokenListItem
                                    key={`${token.address}_${token.networkId}`}
                                >
                                    <TokenTableCell
                                        token={token}
                                        showNetwork={false}
                                    />
                                </S.TokenListItem>
                            ))}
                    </S.TokensList>
                </li>
            ))}
        </S.NetworksList>
    );
}
