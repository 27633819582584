import React from "react";

const ExternalLink = ({
    name,
    width = `1rem`,
    height = `1rem`,
    fill = "currentColor",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            viewBox="0 0 18 18"
            xmlns="http://www.w3.org/2000/svg"
        >
            {name && <title>{name}</title>}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 2v14h14V9h2v7l-2 2H2l-2-2V2l2-2h7v2H2Zm9 0V0h7v7h-2V3L6 13l-1-1L15 2h-4Z"
            />
        </svg>
    );
};

export default ExternalLink;
