import { useCallback } from "react";
import { extractObjFromArray } from "utils/arrays";
import { getLocalStorageItem, setLocalStorageItem } from "utils/storage";
import { LocalhostWalletEmail } from "context/Wallet";
import { PrimaryWalletAccount } from "context/Wallet/hooks/useWalletConnected";

const EMAIL_VARIABLE_NAME = `walletEmail`;

// NOTE: Emails are currently stored in local-storage only, but should be GET/POST from the backend
const useWalletEmail = (wallet: PrimaryWalletAccount | null) => {
    const setWalletEmail = useCallback(
        (email: string) => {
            if (!wallet) return;

            const storedEmails =
                getLocalStorageItem<LocalhostWalletEmail[]>(
                    EMAIL_VARIABLE_NAME
                ) || [];

            const { found, remaining } = extractObjFromArray(
                storedEmails,
                ({ wallet: addr }) =>
                    addr === (wallet.proxyFor ?? wallet.address)
            );

            // We already have this set, do nothing
            if (found?.email === email) return;

            setLocalStorageItem<LocalhostWalletEmail[]>({
                key: EMAIL_VARIABLE_NAME,
                value: [
                    ...remaining,
                    { wallet: wallet.proxyFor ?? wallet.address, email },
                ],
            });
        },
        [wallet]
    );

    const getWalletEmail = useCallback((): string | null => {
        if (!wallet) return null;

        const storedEmails =
            getLocalStorageItem<LocalhostWalletEmail[]>(EMAIL_VARIABLE_NAME) ||
            [];

        return (
            storedEmails.find(
                ({ wallet: addr }) =>
                    addr === (wallet.proxyFor ?? wallet.address)
            )?.email || null
        );
    }, [wallet]);

    return { setWalletEmail, getWalletEmail };
};

export default useWalletEmail;
