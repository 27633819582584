import * as S from "./style";
import { HTMLAttributes, ReactNode } from "react";

interface MarkerProps extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode;
    block?: boolean;
}

const Marker = ({ children, block = false, ...props }: MarkerProps) => {
    return (
        <S.Marker block={block} {...props}>
            {children}
        </S.Marker>
    );
};

export default Marker;
