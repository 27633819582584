export enum Spacing {
    none = "none",
    xxxxs = "xxxxs",
    xxxs = "xxxs",
    xxs = "xxs",
    xs = "xs",
    sm = "sm",
    md = "md",
    lg = "lg",
    xl = "xl",
    xxl = "xxl",
}

const spacing = {
    [Spacing.none]: "0",
    [Spacing.xxxxs]: "0.125rem",
    [Spacing.xxxs]: "0.25rem",
    [Spacing.xxs]: "0.5rem",
    [Spacing.xs]: "0.75rem",
    [Spacing.sm]: "1rem",
    [Spacing.md]: "1.5rem",
    [Spacing.lg]: "2rem",
    [Spacing.xl]: "3rem",
    [Spacing.xxl]: "4rem",
};

export type SpacingTrack = [Spacing] | [Spacing, Spacing];

export type SpacingBox =
    | [Spacing]
    | [Spacing, Spacing]
    | [Spacing, Spacing, Spacing]
    | [Spacing, Spacing, Spacing, Spacing];

export const getSpacing = (sides: SpacingTrack | SpacingBox) =>
    sides.map((s) => spacing[s]).join(" ");

export default spacing;
