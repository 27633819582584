import styled, { css } from "styled-components";
import Form from "components/Form";
import spacing from "theme/spacing";

export const CancelAccountForm = styled(Form)`
    p {
        margin-block: 1rem;
    }
`;

export const DetailItem = css`
    display: flex;
    gap: 0.5rem;
    margin-block: ${spacing.xxxs};
    * {
        display: flex;
        align-items: center;
    }
    dt {
        font-weight: 600;
    }
    dd {
        gap: ${spacing.xxxs};
    }
`;

export const Buttons = styled.footer`
    display: flex;
    gap: 1rem;
`;
