import { useCallback, useEffect, useRef, useState } from "react";
import {
    getCheckoutInvoice,
    getCheckoutItem,
    TokenExchangeDetailsResponse,
} from "api";
import { GetCheckoutResponse } from "api/types/checkout";

interface GetCheckoutResponseWithExchangeRates
    extends Omit<GetCheckoutResponse, "tokens"> {
    tokens: TokenExchangeDetailsResponse[];
}

const useFetchCheckout = ({
    entityId,
    itemId,
    queryParams,
}: CheckoutParams) => {
    const [checkoutData, setCheckoutData] =
        useState<GetCheckoutResponseWithExchangeRates>();
    const [fetching, setFetching] = useState<boolean>(true);

    // Used to prevent multiple fetches due to react strict mode
    const fetchingRef = useRef<boolean>(false);

    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [formErrorDisplay, setFormErrorDisplay] = useState<string>("");

    const fetchCheckoutItem = useCallback(
        async (entityId: string, baseItemNameId: string) => {
            let itemCheckoutRequest: GetCheckoutItemRequest = {
                entityId: entityId,
                baseItemNameId: baseItemNameId,
                items: queryParams?.itemIds,
                externalSubscriptionId: queryParams?.sub || undefined,
                couponCodeId: queryParams?.coupon || undefined,
                discount: queryParams?.discountPercent || undefined,
                externalCustomerId: queryParams?.customerId || undefined,
            };
            try {
                const { data, response } = await getCheckoutItem(
                    itemCheckoutRequest
                );

                if (response.ok && data) {
                    setCheckoutData(data);
                    setSuccess(true);
                }

                if (!response.ok) {
                    setError(
                        "We're having problem communicating with the server"
                    );
                }
            } catch (error) {
                setSuccess(false);
                setError("We're having problem communicating with the server");
                console.error(error);
            }
        },
        [queryParams]
    );

    const fetchCheckoutInvoice = useCallback(
        async (
            entityId: string,
            invoiceId?: string,
            invoiceAmount?: number
        ) => {
            // Just render the page as is
            if (!invoiceId || !invoiceAmount) return setSuccess(true);

            const invoiceCheckoutRequest: GetCheckoutInvoiceRequest = {
                entityId: entityId,
                invoiceId: invoiceId,
                invoiceAmount: invoiceAmount,
            };
            try {
                const { data, response, error } = await getCheckoutInvoice(
                    invoiceCheckoutRequest
                );

                // Yay
                if (response.ok && data) {
                    setSuccess(true);
                    setCheckoutData(data);

                    // Meh (success but with error related to finding)
                } else if (!response.ok && error) {
                    // This error is from the backend and can be displayed to the user
                    if (error.code === 400 && error.message) {
                        setFormErrorDisplay(error.message);
                    } else {
                        setError("There was a problem looking up your invoice");
                    }

                    setSuccess(true);

                    // Nay - straight up failed
                } else if (!response.ok) {
                    setSuccess(false);
                }
            } catch (error) {
                setSuccess(false);
                setError("We're having problem communicating with the server");
                console.error(error);
            }
        },
        []
    );

    const fetch = useCallback(async () => {
        // TODO: Future: These states can be moved to a hook like useGet or useQuery library
        if (fetchingRef.current) return;
        fetchingRef.current = true;
        setFetching(true);

        const isItemCheckout = !!itemId && !!entityId;
        const isInvoiceCheckout = !itemId && !!entityId;

        if (isItemCheckout) {
            await fetchCheckoutItem(entityId, itemId);

            // Invoice Request
        } else if (isInvoiceCheckout) {
            await fetchCheckoutInvoice(
                entityId,
                queryParams?.invoiceId,
                queryParams?.invoiceAmount
            );
        } else {
            setError("Fail to load checkout");
        }

        setFetching(false);
        fetchingRef.current = false;
    }, [
        fetchCheckoutItem,
        fetchCheckoutInvoice,
        queryParams,
        entityId,
        itemId,
    ]);

    // Initial fetch
    useEffect(() => {
        fetch();
    }, [fetch]);

    return {
        data: checkoutData,
        loading: fetching,
        error,
        refetch: fetch,
        success,
        formErrorDisplay,
    };
};

export { useFetchCheckout };
