import styled from "styled-components";
import spacing from "theme/spacing";

export const Filters = styled.header`
    display: flex;
    align-items: flex-end;
    gap: 1rem;
`;

export const FiltersHint = styled.p`
    flex: 1;
    text-align: right;
`;

export const SubMenuWrapper = styled.div`
    margin-block-end: ${spacing.lg};
`;
