import { useReducer } from "react";

export enum CheckoutSteps {
    PAYMENT = 0,
    CONTACT,
    APPROVE,
    COMPLETE,
}

export enum CheckoutEvents {
    GO_TO_PAYMENT = "GO_TO_PAYMENT",
    GO_TO_CONTACT = "GO_TO_CONTACT",
    GO_TO_APPROVE = "GO_TO_APPROVE",
    GO_TO_COMPLETE = "GO_TO_COMPLETE",
}

const initialState = {
    step: CheckoutSteps.PAYMENT,
};

const reducer = (
    state: { step: CheckoutSteps },
    action: {
        type: keyof typeof CheckoutEvents;
    }
) => {
    switch (action.type) {
        case CheckoutEvents.GO_TO_PAYMENT:
            return { ...state, step: CheckoutSteps.PAYMENT };
        case CheckoutEvents.GO_TO_CONTACT:
            return { ...state, step: CheckoutSteps.CONTACT };
        case CheckoutEvents.GO_TO_APPROVE:
            return { ...state, step: CheckoutSteps.APPROVE };
        case CheckoutEvents.GO_TO_COMPLETE:
            return { ...state, step: CheckoutSteps.COMPLETE };
        default:
            return state;
    }
};

export const useCheckoutStateMachine = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return { state, dispatch };
};
