import styled from "styled-components";
import {
    noBlockMargin,
    colorsDeprecated as c,
    properties as p,
} from "global-style";

export const Suggestion = styled.article`
    padding: 1rem;
    border: 1px solid ${c.border};
    border-radius: ${p.radiusLg};
    line-height: 1.6;
    ${noBlockMargin}
    p:first-child::before {
        content: "";
        display: inline-block;
        margin-right: 0.5em;
        width: 1.5rem;
        height: 1.6rem;
        vertical-align: middle;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM11 7H12.5V12.25L17 14.92L16.25 16.15L11 13V7Z' fill='black' fill-opacity='0.54'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
    }
`;
