import styled from "styled-components";
import { colorsDeprecated as c, properties as p } from "global-style";
import colors from "theme/colors";

export const CurrencySelector = styled.div`
    margin: 1rem 0;
`;

export const LabelDropInput = styled.label`
    display: flex;
    justify-content: space-between;
    align-items: end;
    > * {
        margin: 0.25rem 0;
    }
`;

export const Currency = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 1em;
    border: 1px solid
        ${({ highlight, valid }) =>
            !valid ? colors.error : highlight ? c.borderHighlight : c.border};
    border-radius: ${p.radiusSm};
    padding: 0.25rem 0.5rem;
    &[disabled] {
        background-color: ${c.disabled};
        color: ${c.onDisabled};
    }
    &[disabled] :is(:is(input, select)[disabled], output) {
        background-color: transparent;
        color: inherit;
    }
`;

export const SelectCurrency = styled.fieldset`
    display: flex;
    gap: 1rem;
    select {
        flex-grow: 1;
        border: none;
        cursor: pointer;
    }
`;

export const InputCurrency = styled.label`
    input,
    output {
        display: block;
        text-align: end;
    }
    input {
        border: 0;
        font-size: 1.5em;
        line-height: 1;
        padding: 0;
        width: 12ch;
        height: 1.25em;
    }
    output {
        font-size: ${p.small};
        color: ${c.lightOnSurface};
    }
    &:focus-within {
        outline: ${p.focus};
    }
    > :focus {
        outline: none;
    }
`;

export const NoTokens = styled.strong`
    color: ${colors.error};
    line-height: 3;
    text-align: center;
    grid-column: span 2;
`;

export const SingleCurrency = styled.input`
    line-height: 3;
    &:disabled {
        background-color: transparent;
        color: inherit;
        border: none;
    }
`;
