import React from "react";

const Home = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 18"
            {...props}
        >
            {name && <title>{name}</title>}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 9.574H0l10-9 10 9h-3v8h-6v-6H9v6H3v-8Zm12-1.81-5-4.5-5 4.5v7.81h2v-6h6v6h2v-7.81Z"
                fill="inherit"
            />
        </svg>
    );
};

export default Home;
