import { ReactNode, Ref, forwardRef } from "react";
import * as S from "./style";

interface DropdownItemProps {
    children: ReactNode;
    onClick?: () => void;
    className?: string;
}

export type DropdownItemRef = {};

function DropdownItem(
    { children, onClick, className }: DropdownItemProps,
    ref: Ref<DropdownItemRef>
) {
    return (
        <S.DropdownItem
            className={className}
            onClick={onClick}
            tabIndex={0}
            role="option"
        >
            {children}
        </S.DropdownItem>
    );
}

export default forwardRef(DropdownItem);
