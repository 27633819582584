import styled from "styled-components";
import colors from "theme/colors";

export const Checkmark = styled.svg`
    width: 1em;
    height: 1em;
    fill: ${colors.primary};
    vertical-align: middle;
    margin-top: -0.1em;
    margin-right: 0.25rem;
`;
