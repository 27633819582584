import styled from "styled-components";
import { sizes as s } from "global-style";

export const Authorizations = styled.ul`
    margin-block: 0;
    display: grid;
    align-items: start;
    & > * {
        width: 100%;
        min-width: 0;
    }

    grid-gap: 1rem;

    @media screen and (min-width: ${s.sm}) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: ${s.md}) {
        grid-gap: 2rem;
    }

    @media screen and (min-width: ${s.lg}) {
        grid-template-columns: repeat(3, 1fr);
    }
`;
