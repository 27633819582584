import React, { HTMLAttributes, RefObject, useEffect, useState } from "react";
import * as S from "./styles";

export enum InputSizes {
    Small = "small",
    Medium = "medium",
    Large = "large",
}

// Type
export const INPUT_TYPE_DATE = "date";
export const INPUT_TYPE_DATETIME_LOCAL = "datetime-local";
export const INPUT_TYPE_EMAIL = "email";
export const INPUT_TYPE_MONTH = "month";
export const INPUT_TYPE_NUMBER = "number";
export const INPUT_TYPE_PASSWORD = "password";
export const INPUT_TYPE_SEARCH = "search";
export const INPUT_TYPE_TEL = "tel";
export const INPUT_TYPE_TEXT = "text";
export const INPUT_TYPE_TIME = "time";
export const INPUT_TYPE_URL = "url";
export const INPUT_TYPE_WEEK = "week";
export const InputTypes = [
    INPUT_TYPE_DATE,
    INPUT_TYPE_DATETIME_LOCAL,
    INPUT_TYPE_EMAIL,
    INPUT_TYPE_MONTH,
    INPUT_TYPE_NUMBER,
    INPUT_TYPE_PASSWORD,
    INPUT_TYPE_SEARCH,
    INPUT_TYPE_TEL,
    INPUT_TYPE_TEXT,
    INPUT_TYPE_TIME,
    INPUT_TYPE_URL,
    INPUT_TYPE_WEEK,
] as const;

export interface InputProps extends HTMLAttributes<HTMLInputElement> {
    value?: string | number;
    name?: string;
    placeholder?: string;
    className?: string;
    type?: typeof InputTypes[number];
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    onInput?: React.ChangeEventHandler<HTMLInputElement>;
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
    disabled?: boolean;
    readOnly?: boolean;
    required?: boolean;
    pattern?: string;
    min?: number | string;
    max?: number | string;
    step?: number | string;
    stepper?: boolean;
    inputSize?: InputSizes;
    size?: number;
    innerRef?: RefObject<HTMLInputElement>;
    removeValidationOnChange?: boolean;
}

// NOTE: This is designed for all Box-styled input, everything except Radio and Checkbox
export const Input: React.FunctionComponent<InputProps> = ({
    value: valueFromProp,
    name,
    placeholder,
    className,
    type = INPUT_TYPE_TEXT,
    onBlur,
    onChange,
    onFocus,
    onInput,
    onKeyDown,
    disabled = false,
    readOnly = false,
    required = false,
    pattern,
    min,
    max,
    step,
    stepper = false,
    inputSize = InputSizes.Medium,
    size,
    innerRef,
    removeValidationOnChange = true,
    ...inputProps
}) => {
    const [value, setValue] =
        useState<string | number | undefined>(valueFromProp);

    useEffect(() => {
        setValue(valueFromProp);
    }, [valueFromProp]);

    const handleOnBlur: React.FocusEventHandler<HTMLInputElement> = (
        event: React.FocusEvent<HTMLInputElement>
    ) => {
        if (onBlur) {
            onBlur(event);
        }
    };

    const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        // If the field is not valid, don't allow further input (something we should consider)
        //if (event.target.validity.valid) setValue(event.target.value);
        setValue(event.target.value);
        // ! Noting here that `setValue` should only be called when an onChange handler is provided, so moving it within the if(onChange) control structure should be done to ensure this remains a controlled component. This should be on the "todo" list at some point soon (in general this Input component is pretty poorly written). Reenable the test for this when fixed.

        // Avoid the browser tooltip bug
        if (removeValidationOnChange) {
            event.target.setCustomValidity("");
        }
        if (onChange) {
            onChange(event);
        }
    };

    const handleOnFocus: React.FocusEventHandler<HTMLInputElement> = (
        event: React.FocusEvent<HTMLInputElement>
    ) => {
        if (onFocus) {
            onFocus(event);
        }
    };

    const handleOnInput: React.FocusEventHandler<HTMLInputElement> = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (onInput) {
            onInput(event);
        }
    };

    const handleOnKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (onKeyDown) {
            onKeyDown(event);
        }
    };

    // Prevent number input change on scroll
    // From https://medium.com/modernnerd-code/how-to-disabled-scrolling-on-html-number-input-in-react-6548841166fb
    const handleOnWheel: React.WheelEventHandler<HTMLInputElement> = (
        event: React.WheelEvent<HTMLInputElement>
    ) => {
        if (type !== INPUT_TYPE_NUMBER) {
            return;
        }
        // @ts-ignore
        event.target.blur();

        // Prevent the page/container scrolling
        event.stopPropagation();

        setTimeout(() => {
            // @ts-ignore
            event.target.focus();
        }, 0);
    };

    return (
        <S.Input
            {...inputProps}
            name={name}
            type={type}
            className={className}
            placeholder={placeholder}
            id={name}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            onFocus={handleOnFocus}
            onInput={handleOnInput}
            onWheel={handleOnWheel}
            onKeyDown={handleOnKeyDown}
            disabled={disabled}
            value={value}
            readOnly={readOnly}
            min={min}
            max={max}
            step={step}
            $size={inputSize}
            size={size}
            stepper={type === INPUT_TYPE_NUMBER && stepper}
            required={required}
            pattern={pattern}
            ref={innerRef}
        />
    );
};

export default Input;
