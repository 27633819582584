import { TokenExchangeDetailsResponse } from "api";

export interface InvoiceEntity {
    id: string;
    name: string;
}

export interface InvoiceNetworkConfig {
    id: number;
    hexId: string;
    name: string;
    treasury: string;
    tokens: TokenExchangeDetailsResponse[];
}

// aka InvoicingConfigResponse in the backend
export interface GetInvoiceEntityResponse {
    entity: InvoiceEntity;
    networks: InvoiceNetworkConfig[];
}

export interface PostInvoicePaymentResponse
    extends Partial<InvoicingPaymentResponse> {
    success: boolean;
}

export interface CreateInvoicingPaymentRequest {
    invoiceEntityId: string;
    invoiceId: string;
    walletAddress: string;
    tokenAddress: string;
    tokenAmount: string;
    usdAmount: string;
    emailAddress: string;
    transactionHash: string;
    networkId: number;
}

export interface PaginatedPaymentViewResponse {
    totalResults: number;
    invoices: InvoicingPaymentResponse[];
}

export enum InvoiceStatus {
    Pending = `Pending`,
    Successful = `Successful`,
    Failed = `Failed`,
    Irregular = `Irregular`,
}

export interface InvoicingPaymentResponse {
    /**
     * The ID of the payment
     * @example "123e4567-e89b-12d3-a456-426614174000"
     */
    id: string;
    /**
     * The merchant name
     * @example "Test Merchant"
     */
    merchant: string;
    /**
     * The invoice ID for the payment
     * @example "INV-1234567890"
     */
    invoiceId: string;
    /**
     * The network ID for the payment
     * @example 1
     */
    networkId: number;
    /**
     * The status of the payment
     */
    status: string;
    /**
     * The wallet address the payment was sent from
     * @example "0x222c9e261f59962efc5248d8ee1d8b5c650b304b"
     */
    fromWallet: string;
    /**
     * The wallet address the payment was sent to
     * @example "0x222c9e261f59962efc5248d8ee1d8b5c650b304b"
     */
    toWallet: string;
    /**
     * The token address the payment was sent with
     * @example "0x222c9e261f59962efc5248d8ee1d8b5c650b304b"
     */
    tokenAddress?: string;
    /**
     * The symbol of the token
     * @example "USDC"
     */
    tokenSymbol?: string;
    /**
     * The amount of the token paid - not formatted with token decimals
     * @example "1000000"
     */
    tokenAmount?: string;
    /**
     * The amount paid in USD where 100 is $1.00
     * @example "100"
     */
    usdAmount: string;
    /**
     * The hash of the transaction
     * @example "0x222c9e261f59962efc5248d8ee1d8b5c650b304b"
     */
    txHash: string;
    /**
     * The date the payment was made in seconds since epoch
     * @example "1714204800"
     */
    datePaid: number | null;
    /**
     * The email address of the payer
     * @example "test@example.com"
     */
    email: string;
}
