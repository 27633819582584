import * as S from "./style";
import Button from "components/Button";

const Paginate = ({
    totalResults,
    onPageChange,
    page = 1,
    perPage = 25,
    minimize = false, // Minimize the UI where possible
    ...props
}) => {
    const totalPages = Math.ceil(totalResults / perPage);
    const startRow = (page - 1) * perPage;
    const endRow = Math.min(startRow + perPage, totalResults);

    const updatePage = (page) => {
        if (page <= 0) onPageChange(1);
        else if (page > totalPages) onPageChange(totalPages);
        else onPageChange(page);
    };

    return (
        <>
            {totalResults > 0 && (
                <S.Paginate {...props}>
                    <div>
                        {minimize && totalPages <= 1 ? (
                            <>
                                Showing {totalResults} result
                                {totalResults !== 1 ? `s` : ``}
                            </>
                        ) : (
                            <>
                                Showing {startRow + 1}-{endRow} of{" "}
                                {totalResults} result
                                {totalResults !== 1 ? `s` : ``}
                            </>
                        )}
                    </div>
                    <S.Controls>
                        {!(minimize && page === 1) && (
                            <Button
                                onClick={() => updatePage(page - 1)}
                                disabled={page === 1 ? `disabled` : ``}
                                size="small"
                            >
                                Prev
                            </Button>
                        )}

                        {!(minimize && page === totalPages) && (
                            <Button
                                onClick={() => updatePage(page + 1)}
                                disabled={page === totalPages ? `disabled` : ``}
                                size="small"
                            >
                                Next
                            </Button>
                        )}
                    </S.Controls>
                </S.Paginate>
            )}
        </>
    );
};

export default Paginate;
