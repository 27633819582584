import styled, { css } from "styled-components";
import spacing, { Spacing } from "theme/spacing";
import { fontSizes, fontWeight } from "theme/typography";

export const Paragraph = styled.p<{
    $bold: boolean;
    $small: boolean;
    $spacing?: Spacing;
}>`
    font-size: ${({ $small }) => ($small ? fontSizes.sm : fontSizes.md)};
    font-weight: ${({ $bold }) =>
        $bold ? fontWeight.bold : fontWeight.normal};
    margin: 0;

    ${({ $spacing }) =>
        $spacing &&
        css`
            margin-block: ${spacing[$spacing]};
        `}
`;
