import useOnClickOutside from "hooks/useOnClickOutside";
import React, { useRef, FunctionComponent } from "react";
import * as S from "./styles";
import Close from "components/icons/Close";
import Title from "components/Title";
import Paragraph from "components/Paragraph";

interface SidePopoverProps {
    onClose: () => void;
    children: React.ReactNode;
    title?: React.ReactNode;
    description?: React.ReactNode;
}

// TODO: When we migrate all Modal/Popover to this, rename Modal/Popover
const SidePopover: FunctionComponent<SidePopoverProps> = ({
    onClose,
    children,
    title,
    description,
}) => {
    const dialogRef = useRef<HTMLDialogElement>(null);
    const onClickClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        onClose();
    };
    useOnClickOutside(dialogRef, () => {
        onClose();
    });
    return (
        <S.Backdrop>
            <S.Dialog ref={dialogRef} onClose={onClose} open>
                {(title || description) && (
                    <S.Header>
                        <Title level="h1">{title}</Title>
                        <Paragraph>{description}</Paragraph>
                        <S.ClosePopoverLink onClick={onClickClose}>
                            <Close />
                        </S.ClosePopoverLink>
                    </S.Header>
                )}
                <S.Main>{children}</S.Main>
            </S.Dialog>
        </S.Backdrop>
    );
};

export default SidePopover;
