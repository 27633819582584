import React from "react";
import * as S from "./styles";
import Spinner from "components/Spinner";
import spacing from "theme/spacing";

export enum LoadingPlaceholderStyle {
    Box = "box",
    Table = "table",
}
interface LoadingBoxProps {
    height?: string;
    placeholderStyle?: LoadingPlaceholderStyle;
    tablePlaceholderCols?: number;
    tablePlaceholderRows?: number;
}

export const LoadingBox: React.FunctionComponent<LoadingBoxProps> = ({
    placeholderStyle = "box",
    height = placeholderStyle === LoadingPlaceholderStyle.Table
        ? spacing.lg
        : "5rem",
    tablePlaceholderCols = 7,
    tablePlaceholderRows = 6,
}) => {
    return placeholderStyle === LoadingPlaceholderStyle.Table ? (
        <S.Table cellSpacing="0">
            <thead>
                <tr>
                    <th colSpan={tablePlaceholderCols}>
                        <S.LoadingBox height={height} />
                    </th>
                </tr>
            </thead>
            <tbody>
                {[...Array(tablePlaceholderRows)].map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {[...Array(tablePlaceholderCols)].map(
                            (cell, cellIndex) => (
                                <td key={`${rowIndex}--${cellIndex}`}>
                                    <S.LoadingBox
                                        height={height}
                                        animationDelay={
                                            rowIndex * cellIndex * 100
                                        }
                                    />
                                </td>
                            )
                        )}
                    </tr>
                ))}
            </tbody>
        </S.Table>
    ) : (
        <S.LoadingBox height={height}>
            <Spinner />
        </S.LoadingBox>
    );
};

export default LoadingBox;
