import styled, { css } from "styled-components";
import { properties as p, shrink, spin } from "global-style";
import colors from "theme/colors";
import Spinner from "components/Spinner";
import { NotificationContext, NotificationMessage, NotificationType } from ".";

const getColor = (type: NotificationType) =>
    type === NotificationType.INFO
        ? { bg: colors.primary, fg: colors.textOnPrimary }
        : type === NotificationType.SUCCESS
        ? { bg: colors.success, fg: colors.textOnSuccess }
        : type === NotificationType.WARNING
        ? { bg: colors.warning, fg: colors.textOnWarning }
        : type === NotificationType.ERROR
        ? { bg: colors.error, fg: colors.textOnError }
        : type === NotificationType.WORKING
        ? { bg: colors.primary, fg: colors.textOnPrimary }
        : null;

const getIcon = (type: NotificationType) => css`
    ${type === NotificationType.INFO
        ? `"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M8 2a6 6 0 1 0 0 12A6 6 0 0 0 8 2ZM.7 8a7.3 7.3 0 1 1 14.6 0A7.3 7.3 0 0 1 .7 8ZM8 7.3c.4 0 .7.3.7.7v2.7a.7.7 0 0 1-1.4 0V8c0-.4.3-.7.7-.7Zm0-2.6A.7.7 0 1 0 8 6a.7.7 0 0 0 0-1.3Z' /%3E%3C/svg%3E"`
        : type === NotificationType.SUCCESS
        ? `"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M8 2a6 6 0 1 0 0 12A6 6 0 0 0 8 2ZM.7 8a7.3 7.3 0 1 1 14.6 0A7.3 7.3 0 0 1 .7 8ZM8 7.3c.4 0 .7.3.7.7v2.7a.7.7 0 0 1-1.4 0V8c0-.4.3-.7.7-.7Zm0-2.6A.7.7 0 1 0 8 6a.7.7 0 0 0 0-1.3Z' /%3E%3C/svg%3E"`
        : type === NotificationType.WARNING
        ? `"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47 40'%3e%3cpath d='M23.1579 8.4L39.0105 35.7895H7.30526L23.1579 8.4ZM23.1579 0L0 40H46.3158L23.1579 0ZM25.2632 29.4737H21.0526V33.6842H25.2632V29.4737ZM25.2632 16.8421H21.0526V25.2632H25.2632V16.8421Z' /%3e%3c/svg%3e"`
        : type === NotificationType.ERROR
        ? `"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M8 2a6 6 0 1 0 0 12A6 6 0 0 0 8 2ZM.7 8a7.3 7.3 0 1 1 14.6 0A7.3 7.3 0 0 1 .7 8ZM8 7.3c.4 0 .7.3.7.7v2.7a.7.7 0 0 1-1.4 0V8c0-.4.3-.7.7-.7Zm0-2.6A.7.7 0 1 0 8 6a.7.7 0 0 0 0-1.3Z' /%3E%3C/svg%3E"`
        : type === NotificationType.WORKING
        ? ``
        : ``}
`;

export const Notification = styled.li<
    Pick<NotificationMessage, "type" | "expires" | "context">
>`
    display: flex;
    flex-wrap: nowrap;
    column-gap: 1rem;
    align-items: center;
    margin: 1rem 0;
    padding: 1em;
    padding-left: 3em;
    position: relative;
    background-image: ${({ type }) =>
        `linear-gradient(to right, ${getColor(type)?.bg} 0%, ${
            getColor(type)?.bg
        } 2em, ${colors.white} 2em, ${colors.white} 100%)`};
    color: ${colors.text};

    ${({ context, type }) =>
        context === NotificationContext.HOVERING
            ? css`
                  box-shadow: rgba(50, 50, 93, 0.15) 0px 0.75rem 1.5rem -0.25rem,
                      rgba(20, 20, 20, 0.25) 0px 0.5rem 1rem -0.5rem;
                  border-radius: ${p.radiusSm};
              `
            : context === NotificationContext.STATIC
            ? css`
                  border: 1px solid ${getColor(type)?.bg};
                  border-radius: ${p.radiusLg};
              `
            : ``};

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 2em;
        border-radius: ${p.radiusSm} 0 0 ${p.radiusSm};
        background-color: ${({ type }) => getColor(type)?.fg};
        mask-size: 1em;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-image: url(${({ type }) => getIcon(type)});
    }

    &::after {
        ${({ expires, type }) =>
            expires &&
            css`
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                height: 0.125em;
                margin-left: 2em;
                width: calc(100% - 2em);
                transform: scale(1);
                transform-origin: left;
                animation: ${shrink} linear ${expires}ms 1;
                background-color: ${getColor(type)?.bg};
            `}
    }
`;

export const Working = styled(Spinner)`
    position: absolute;
    width: 1.25em;
    height: 1.25em;
    inset: 0 auto 0 0.375em;
    margin: auto;
    animation: 1s linear ${spin} infinite;
`;

export const Message = styled.strong`
    font-weight: 400;
    flex-grow: 1;
`;

export const Close = styled.button`
    width: 1.4em;
    height: 1.4em;
    padding: 0;
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath d='M17.3 4A.8.8 0 1 0 16 2.6L10 8.8l-6-6a.8.8 0 1 0-1.3 1.1L8.8 10l-6 6a.8.8 0 1 0 1.1 1.3l6.1-6.1 6 6a.8.8 0 1 0 1.3-1.1L11.2 10l6-6Z' /%3E%3C/svg%3E");
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: ${colors.text};
`;
