import React, { useCallback, useState } from "react";
import { Company } from "company/types";
import * as S from "./style";
import Button, { ButtonVariants } from "components/Button";
import { apiServerUrl } from "default-variables";
import { useNotificationQueue } from "context/NotificationQueue";
import TagsInput from "company/components/TagsInput";
import { NotificationType } from "components/Notification";
import { useGetCompanyTransfers } from "company/hooks/useGetCompanyTransfers";
import { useUser } from "context/User";

interface UpdateTransferTagsFormProps {
    transaction: Company.Transaction;
    onCancel?: () => void;
    onSuccess?: () => void;
}

const UpdateTransferTagsForm: React.FunctionComponent<UpdateTransferTagsFormProps> =
    ({ transaction, onCancel, onSuccess }) => {
        const [formLoading, setFormLoading] = useState<boolean>(false);

        // Session & Company data
        const { invalidateAllTransfersQueries } = useGetCompanyTransfers({
            id: transaction.transferId,
        });
        const { getEntityId, getSessionToken } = useUser();

        // Notifications
        const { addNotification } = useNotificationQueue();

        // Data & hooks
        const defaultSelectedTagIds =
            transaction.tags.map(({ id }) => id) || [];
        const [tagsIds, setTagsIds] = useState<number[]>(defaultSelectedTagIds);

        const successHandle = useCallback(async () => {
            await invalidateAllTransfersQueries();
            setFormLoading(false);
            if (onSuccess) onSuccess();
        }, [invalidateAllTransfersQueries, onSuccess]);

        // RIP: useFetch :sad_face_emoji:
        // Note: Talked with Rocco, we need to consider refactoring the useFetch
        // and/or use a library like react-query for the POST scenario
        const updateTags = async () => {
            setFormLoading(true);

            const putTransfersTagsBody = JSON.stringify([
                {
                    transferId: transaction.transferId,
                    tagIds: tagsIds,
                },
            ]);

            const response = await fetch(
                `${apiServerUrl}/api/v1/company/transfers/tags`,
                {
                    method: `PUT`,
                    headers: {
                        Authorization: getSessionToken(),
                        "Content-Type": "application/json",
                        "entity-id": getEntityId(),
                    },
                    body: putTransfersTagsBody,
                }
            );

            await response.json();
            const successFullResponse = response.ok && response.status === 200;

            if (successFullResponse) {
                await successHandle();
            } else {
                addNotification({
                    msg: `There was an error while updating the tags`,
                    type: NotificationType.ERROR,
                });
            }

            setFormLoading(false);
        };

        return (
            <S.EditForm onSubmit={updateTags}>
                <TagsInput
                    name="tags"
                    onChange={(values) => setTagsIds(values)}
                    defaultSelectedTagIds={defaultSelectedTagIds}
                    disabled={formLoading}
                />
                <Button type="submit" loading={formLoading}>
                    {formLoading ? "Saving" : "Save"}
                </Button>
                <Button
                    type="button"
                    disabled={formLoading}
                    onClick={onCancel}
                    variant={ButtonVariants.Anchor}
                >
                    Cancel
                </Button>
            </S.EditForm>
        );
    };

export default UpdateTransferTagsForm;
