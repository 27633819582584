import React from "react";

const Chart = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
        >
            {name && <title>{name}</title>}
            <path
                d="M16 0H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2Zm0 16H2V2h14v14Z"
                fill="inherit"
            />
            <path
                d="M6 9H4v5h2V9ZM14 4h-2v10h2V4ZM10 11H8v3h2v-3ZM10 7H8v2h2V7Z"
                fill="inherit"
            />
        </svg>
    );
};

export default Chart;
