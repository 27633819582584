import SelectWithIcon from "components/SelectWithIcon";
import Select from "components/Select";
import { Ref, forwardRef, useMemo } from "react";
import { firstToUpper } from "utils/strings";
import { useAvailableNetworks } from "hooks/useAvailableNetworks";
import Spinner from "components/Spinner";
import * as S from "./styles";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useWallet } from "context/Wallet";

export interface WalletNetworkSelectProps {
    disabled: boolean;

    // Only let user select a network with a corresponding contract
    withContractOnly: boolean;
}

export type WalletNetworkSelectRef = {};

function WalletNetworkSelect(
    { disabled, withContractOnly }: WalletNetworkSelectProps,
    ref: Ref<WalletNetworkSelectRef>
) {
    const {
        config: { contracts },
    } = useGetCompanyConfig();
    const { networks } = useGetNetworks();

    const {
        walletConnected,
        networkConnected,
        setConnectedNetwork,
        isNetworkSetting,
    } = useWallet();
    const { getNetworkById } = useAvailableNetworks();

    // Get contract's network IDS
    const contractsNetworkIds = useMemo(
        () => contracts.map((c) => c.networkId),
        [contracts]
    );

    const networksForSelect = useMemo(() => {
        if (withContractOnly) {
            return networks.filter((n) => contractsNetworkIds.includes(n.id));
        }
        return networks;
    }, [contractsNetworkIds, networks, withContractOnly]);

    // Currently connected network
    const currentNetwork = networks.find(
        (network) => network.hexId === networkConnected?.networkId
    );

    // User is on a valid network (ie: displayed / selectable)
    const currentNetworkSupported = useMemo(() => {
        return networksForSelect.find(
            (network) => network.id === currentNetwork?.id
        );
    }, [currentNetwork?.id, networksForSelect]);

    const networkData = currentNetwork && getNetworkById(currentNetwork.hexId);

    const updateChain = async (chainId: string) => {
        if (!walletConnected) {
            return Promise.resolve();
        }
        await setConnectedNetwork({ networkId: chainId });
    };

    // [ ] Will this work? This needs more testing. See other "Select a valid chain" instanaces
    const noChainValue = "noValidChain";
    const networkOptions = useMemo(() => {
        let options = [];
        if (!currentNetworkSupported) {
            options.push({
                label: "Select a valid chain",
                value: noChainValue,
                disabled: true,
            });
        }
        networksForSelect.map((network) => {
            options.push({
                label: firstToUpper(network.name),
                value: network.hexId,
            });
        });

        return options;
    }, [networksForSelect, currentNetworkSupported]);

    if (!walletConnected || !networkConnected) {
        return <p>Connect your wallet</p>;
    }

    if (isNetworkSetting)
        return (
            <S.ChainLoading>
                <Spinner /> Switching network
            </S.ChainLoading>
        );

    if (!currentNetworkSupported || !currentNetwork || !networkData) {
        return (
            <Select
                value={noChainValue}
                disabled={disabled}
                onChange={(event) => updateChain(event.target.value)}
                options={networkOptions}
                name="networkId"
            />
        );
    }

    return (
        <SelectWithIcon
            selectProps={{
                value: currentNetwork?.hexId,
                disabled: disabled,
                onChange: (event) => updateChain(event.target.value),
                options: networkOptions,
                name: "networkId",
            }}
            icon={{
                src: networkData.icon,
                alt: `${networkData.label} logo`,
            }}
        />
    );
}
export default forwardRef(WalletNetworkSelect);
