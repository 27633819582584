import styled from "styled-components";
import { sizes as s } from "global-style";
import spacing from "theme/spacing";

export const Filters = styled.section`
    display: flex;
    align-items: baseline;
    gap: ${spacing.sm};
    margin-bottom: ${spacing.md};
    fieldset {
        width: 100%;
        max-width: 33%;
    }
    flex-direction: column;
    @media screen and (min-width: ${s.md}) {
        flex-direction: row;
    }
`;
