import React from "react";

const ThreeDotsPending = ({
    name,
    width = `1rem`,
    height = `1rem`,
    fill = "currentColor",
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <div>
            <svg
                width={width}
                height={height}
                viewBox="0 0 50 50"
                name={name}
                fill={fill}
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="50" height="50" rx="25" />
                <g clipPath="url(#clip0_2_1132)">
                    <path
                        d="M15.5002 21.8334C13.7585 21.8334 12.3335 23.2584 12.3335 25C12.3335 26.7417 13.7585 28.1667 15.5002 28.1667C17.2418 28.1667 18.6668 26.7417 18.6668 25C18.6668 23.2584 17.2418 21.8334 15.5002 21.8334ZM34.5002 21.8334C32.7585 21.8334 31.3335 23.2584 31.3335 25C31.3335 26.7417 32.7585 28.1667 34.5002 28.1667C36.2418 28.1667 37.6668 26.7417 37.6668 25C37.6668 23.2584 36.2418 21.8334 34.5002 21.8334ZM25.0002 21.8334C23.2585 21.8334 21.8335 23.2584 21.8335 25C21.8335 26.7417 23.2585 28.1667 25.0002 28.1667C26.7418 28.1667 28.1668 26.7417 28.1668 25C28.1668 23.2584 26.7418 21.8334 25.0002 21.8334Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_2_1132">
                        <rect
                            width="38"
                            height="38"
                            fill="white"
                            transform="translate(6 6)"
                        />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default ThreeDotsPending;
