import {
    forwardRef,
    Ref,
    useRef,
    useImperativeHandle,
    useCallback,
    ChangeEvent,
    ComponentProps,
} from "react";
import Label from "components/Label";
import Field from "components/Field";
import Input from "components/Input";

export interface InputValidatedRef {
    value: () => string;
    validate: () => boolean;
}

interface InputValidatedProps extends ComponentProps<typeof Input> {
    defaultValue?: string;
    label?: string;
    validate: (input: HTMLInputElement) => boolean;
}

const InputValidated = forwardRef(
    (
        {
            className,
            defaultValue,
            label,
            onChange,
            validate,
            ...props
        }: InputValidatedProps,
        ref: Ref<InputValidatedRef>
    ) => {
        const inputRef = useRef<HTMLInputElement>(null);

        const handleValidation = useCallback(() => {
            if (!inputRef.current) return false;
            return validate(inputRef.current);
        }, [validate]);

        useImperativeHandle(
            ref,
            () => ({
                value: () => inputRef.current?.value || ``,
                validate: handleValidation,
            }),
            [handleValidation]
        );

        const handleChange = useCallback(
            (event: ChangeEvent<HTMLInputElement>) => {
                if (onChange) onChange(event);
            },
            [onChange]
        );

        return (
            <Field className={className}>
                {label && <Label htmlFor="invoiceAmt">{label}</Label>}
                <Input
                    {...props}
                    innerRef={inputRef}
                    value={defaultValue}
                    onChange={handleChange}
                />
            </Field>
        );
    }
);

export default InputValidated;
