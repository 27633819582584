import { Company } from "company/types";
import * as S from "./style";
import { Ref } from "react";
import { firstToUpper } from "utils/strings";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";

export interface ItemAcceptedTokensByNetworkProps {
    acceptedTokensByNetwork: Company.AcceptedTokensByNetwork;
}

export type ItemAcceptedTokensByNetworkRef = {
    itemSourceId: number;
};

function ItemAcceptedTokensByNetwork(
    { acceptedTokensByNetwork }: ItemAcceptedTokensByNetworkProps,
    ref: Ref<ItemAcceptedTokensByNetworkRef>
) {
    const { tokens } = useGetTokensMetadata();
    const { networks } = useGetNetworks();

    const networkIds = Object.keys(acceptedTokensByNetwork).map((id) =>
        Number(id)
    );

    const acceptedNetworks = networks.filter((network) =>
        networkIds.includes(network.id)
    );
    const acceptedTokens = networkIds
        .map((networkId) => {
            const tokenAddresses = acceptedTokensByNetwork[networkId];
            const networkTokens = tokens.filter((token) =>
                tokenAddresses.includes(token.address.toLocaleLowerCase())
            );
            return networkTokens;
        })
        .flat();

    // Pay with USDC on BNB Smart Chain (Chapel)
    return (
        <S.ItemAcceptedTokensByNetwork>
            Customers can pay with{" "}
            <strong>{acceptedTokens.map((t) => t.symbol).join(", ")}</strong> on{" "}
            <strong>
                {acceptedNetworks.map((n) => firstToUpper(n.name)).join(", ")}
            </strong>
        </S.ItemAcceptedTokensByNetwork>
    );
}

export default ItemAcceptedTokensByNetwork;
