import { useEffect, useRef } from "react";

export const simulateDelay = (ms = 1000) =>
    new Promise((resolve) => setTimeout(resolve, ms));

// Quickly check if a variable was updated between renders
// USAGE: Wrap the variable to check as an object: useWasUpdated({var})
export const useWasUpdated = <T extends Record<string, any>>(varAsObj: T) => {
    const [propName] = Object.keys(varAsObj) as (keyof T)[];
    const value = varAsObj[propName];

    const prevValueRef = useRef<T[keyof T]>();

    useEffect(() => {
        if (prevValueRef?.current !== value) {
            console.log(`${String(propName)} was updated:`, value);
        }
        prevValueRef.current = value;
    }, [value, propName]);
};
