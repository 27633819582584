import {
    Ref,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import * as S from "./style";
import DownloadCsv from "components/DownloadCsv";
import { formateDateForUrl } from "utils/datetime";
import DateRange from "company/components/DateRange";
import Input from "components/Input";
import Field from "components/Field";
import { SelectOption } from "components/Select";
import SelectMultiple, {
    SelectMultipleOptionProps,
} from "components/SelectMultiple";
import { firstToUpper } from "utils/strings";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import { useGetNetworks } from "hooks/useGetNetworks";

interface FiltersProps {
    csvHeadings?: Heading[];
    csvRows?: Tuple[];
    csvName?: string;
    dateRangeFilters:
        | {
              options: SelectOption<string | number>[];
              default: any;
          }
        | false;
    onChange: () => void;
}

export type FiltersRef = {
    search: string;
    dateRange: number;
    selectedNetworksNames: string[];
    selectedEntitiesNames: string[];
};

const Filters = forwardRef(
    (
        {
            csvHeadings,
            csvRows,
            csvName,
            dateRangeFilters,
            onChange,
        }: FiltersProps,
        ref: Ref<FiltersRef>
    ) => {
        const { networks } = useGetNetworks();
        const {
            config: { entities },
        } = useGetCompanyConfig();

        const [selectedNetworksNames, setSelectedNetworksNames] = useState<
            string[]
        >([]);
        const [selectedEntitiesNames, setSelectedEntitiesNames] = useState<
            string[]
        >([]);

        const [search, setSearch] = useState<string>("");

        const [dateRange, setDateRange] = useState<number>(
            (dateRangeFilters && dateRangeFilters.default) || 0
        );

        useImperativeHandle(ref, () => ({
            search,
            dateRange,
            selectedNetworksNames,
            selectedEntitiesNames,
        }));

        const networksOptions: SelectMultipleOptionProps[] =
            networks.map((network) => {
                return {
                    value: network.id,
                    label: firstToUpper(network.name),
                };
            }) || [];

        const entitiesOptions: SelectMultipleOptionProps[] =
            entities.map((entity) => {
                return {
                    value: entity.entityId,
                    label: firstToUpper(entity.name),
                };
            }) || [];

        const onNetworksSelectChange = (values: string[]) => {
            setSelectedNetworksNames(values);
        };
        const onEntitiesSelectChange = (values: string[]) => {
            setSelectedEntitiesNames(values);
        };

        useEffect(() => {
            onChange();
        }, [
            search,
            dateRange,
            selectedNetworksNames,
            selectedEntitiesNames,
            onChange,
        ]);

        return (
            <S.Filters>
                <S.FiltersRow>
                    {dateRangeFilters && (
                        <DateRange
                            value={dateRange}
                            options={dateRangeFilters.options}
                            setDateRange={setDateRange}
                        />
                    )}

                    {entities.length > 1 && (
                        <Field>
                            <SelectMultiple
                                options={entitiesOptions}
                                name="entities"
                                values={selectedEntitiesNames}
                                onChange={onEntitiesSelectChange}
                                placeholder="Filter by entity"
                            />
                        </Field>
                    )}
                    {networks.length > 1 && (
                        <Field>
                            <SelectMultiple
                                options={networksOptions}
                                name="networks"
                                values={selectedNetworksNames}
                                onChange={onNetworksSelectChange}
                                placeholder="Filter by network"
                            />
                        </Field>
                    )}
                    {csvHeadings && csvRows && csvName && (
                        <S.Download>
                            <DownloadCsv
                                filename={`${csvName}-transactions_exported-on-${formateDateForUrl()}.csv`}
                                columns={csvHeadings}
                                rows={csvRows}
                            />
                        </S.Download>
                    )}
                </S.FiltersRow>
                <S.FiltersRow>
                    <Field>
                        <Input
                            type="search"
                            name="search"
                            placeholder="Search"
                            value={search}
                            onChange={(event) => setSearch(event.target.value)}
                        />
                    </Field>
                </S.FiltersRow>
            </S.Filters>
        );
    }
);

export default Filters;
