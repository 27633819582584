import { css } from "styled-components";
import colors from "theme/colors";
import { properties } from "global-style";

// Can be used by input, textarea, checkbox, etc..
export const boxInputStates = {
    placeholder: css`
        color: ${colors.neutralLight};
    `,
    default: css`
        border: 1px solid ${colors.text};
        color: ${colors.lightText};
        background-color: ${colors.white};
        border-radius: ${properties.radiusLg};
    `,

    hover: css`
        border: 1px solid ${colors.text};
        color: ${colors.text};
        background-color: ${colors.white};
    `,
    focus: css`
        border: 1px solid ${colors.primary};
        color: ${colors.text};
        background-color: ${colors.white};
    `,
    disabled: css`
        border: 1px solid ${colors.neutral40};
        color: ${colors.neutral40};
        background-color: ${colors.neutralBackground};
    `,
};

export const boxInputStyles = css`
    ${boxInputStates.default};
    &::placeholder {
        ${boxInputStates.placeholder};
    }
    &:disabled {
        ${boxInputStates.disabled};
    }

    &:not(:disabled) {
        &:focus {
            ${boxInputStates.focus};
        }
        &:not(:focus) {
            &:hover {
                ${boxInputStates.hover};
            }
        }
    }
`;
