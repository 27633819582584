import { GetCompanyExternalSubscriptionResponse } from "company/types";
import ItemsList from "company/components/items/ItemsList";
import { useMemo } from "react";
import ItemCheckoutUrlField from "../items/ItemCheckoutUrlField";
import Label from "components/Label";
import Field from "components/Field";
import Form from "components/Form";
import { canCheckoutItems } from "company/utils/items";
import Notification, { NotificationType } from "components/Notification";
import ItemAcceptedTokensByNetwork from "../items/ItemAcceptedTokensByNetwork";
import { useGetCompanyItems } from "company/hooks/useGetCompanyItems";
import SubSection from "components/SubSection";

interface ExternalSubscriptionDetailsProps {
    companyExternalSubscriptionDetails: GetCompanyExternalSubscriptionResponse;
}

const ExternalSubscriptionDetails: React.FunctionComponent<ExternalSubscriptionDetailsProps> =
    ({ companyExternalSubscriptionDetails }) => {
        const { items } = useGetCompanyItems();

        // Edge-case: if a user edits/updates en item in the ItemList, we'll reload
        // the company items endpoint, but *NOT* the external subscriptions one, leading
        // to inconsistency in the data.

        // There is also a scenario where the Company.Item[] doesn't include the external subscription item's

        // For that reason, we get the external subscriptions item and use those
        // to get a subset of company items

        const selectedItems = useMemo(() => {
            const externalItemIds =
                companyExternalSubscriptionDetails.items.map((item) => item.id);
            return items.filter((item) => externalItemIds.includes(item.id));
        }, [companyExternalSubscriptionDetails.items, items]);

        const externalSubscriptionId =
            companyExternalSubscriptionDetails.externalSubscriptionId;
        const email = companyExternalSubscriptionDetails.email;

        const checkoutBuilderUrlQuery = new URLSearchParams({});

        selectedItems.forEach((item) =>
            checkoutBuilderUrlQuery.append("itemId", item.id)
        );

        // Real fun beings: Can a user checkout with a given array of items
        // We useMemo this because canCheckoutItems does a lot
        const { canCheckout, reason, commonAcceptedTokensByNetwork } = useMemo(
            () => canCheckoutItems(selectedItems),
            [selectedItems]
        );

        // We only show common tokens if 1) there are any and 2) more than one item is selected
        const showCommonAcceptedTokens =
            Object.keys(commonAcceptedTokensByNetwork).length > 0 &&
            selectedItems.length > 1;

        return (
            <div>
                <Field>
                    <Label>Items</Label>
                    <ItemsList items={selectedItems} />
                    {showCommonAcceptedTokens && (
                        <ItemAcceptedTokensByNetwork
                            acceptedTokensByNetwork={
                                commonAcceptedTokensByNetwork
                            }
                        />
                    )}
                </Field>
                {/* Checkout URL */}
                {canCheckout && (
                    <SubSection
                        title="Use this link to get paid today!"
                        description="The item's base link will be available on your
                    company dashboard. It does not expire and can be
                    used more than once."
                    >
                        <ItemCheckoutUrlField
                            label={false}
                            items={selectedItems}
                            forceEmail={email}
                            forceExternalSubscriptionId={externalSubscriptionId}
                        />
                    </SubSection>
                )}

                {selectedItems.length > 0 && !canCheckout && (
                    <Notification
                        key={"msg.id"}
                        message={{
                            id: "formErrorDisplay",
                            msg: (
                                <>
                                    <strong>{reason}</strong>
                                    <p>
                                        Select a different subscription or edit
                                        the items
                                    </p>
                                </>
                            ),
                            type: NotificationType.ERROR,
                            expires: false,
                        }}
                        removeNotification={false}
                    />
                )}
            </div>
        );
    };

export default ExternalSubscriptionDetails;
