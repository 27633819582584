import * as S from "./style";
import Submenu, { SubmenuItem } from "components/Submenu";
import { Outlet } from "react-router-dom";

const submenu: SubmenuItem[] = [
    {
        to: `active`,
        label: `Active`,
    },
    {
        to: `inactive`,
        label: `Inactive`,
    },
];

const OneTimePayments = () => {
    return (
        <>
            <S.SubMenuWrapper>
                <Submenu data={submenu} name="Transaction types" />
            </S.SubMenuWrapper>
            <Outlet />
        </>
    );
};

export default OneTimePayments;
