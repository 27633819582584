import { Company } from "company/types";
import * as S from "./style";
import { Ref } from "react";
import ItemRow from "company/components/items/ItemRow";

export interface ItemsListProps {
    onRemoveItem?: (itemId: Company.Item["id"]) => void;
    items: Company.Item[];
}

export type ItemsListRef = {
    itemSourceId: number;
};

function ItemsList(
    { items, onRemoveItem }: ItemsListProps,
    ref: Ref<ItemsListRef>
) {
    return (
        <S.ItemsList>
            {items.map((item) => (
                <S.ItemRowWrapper key={item.id}>
                    <ItemRow item={item} onRemoveItem={onRemoveItem} />
                </S.ItemRowWrapper>
            ))}
        </S.ItemsList>
    );
}

export default ItemsList;
