import * as S from "./style";
import { ReactNode } from "react";
import Tooltip from "components/Tooltip";

interface AbbrProps extends React.HTMLAttributes<HTMLElement> {
    children: ReactNode;
    variant?: "loop" | "default";
}

const Abbr = ({ children, title, variant = "loop", ...props }: AbbrProps) => {
    const Abbreviation = (
        <S.Abbr variant={variant} aria-label={title} {...props}>
            {children}
        </S.Abbr>
    );

    if (!title) return Abbreviation;

    return (
        <Tooltip title={title} placement="right-end" slim>
            <span>{Abbreviation}</span>
        </Tooltip>
    );
};

export default Abbr;
