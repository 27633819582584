// @ts-nocheck
import * as S from "./style";
import {
    ForwardRefExoticComponent,
    ForwardedRef,
    MutableRefObject,
    PropsWithoutRef,
    RefAttributes,
    RefObject,
    createRef,
    forwardRef,
    memo,
    useCallback,
    useEffect,
    useRef,
} from "react";
import { addTimePeriodToDate } from "utils/datetime";
import { validatePresence } from "utils/formValidation";
import Label from "components/Label";
import Button, { ButtonVariants } from "components/Button";
import Input from "components/Input";
import Field from "components/Field";
import TransferBillDateField, {
    TransferBillDateFieldRef,
} from "company/components/transfers/TransferBillDateField";
import React from "react";

interface BillDetailsProps extends React.HTMLAttributes<HTMLFieldSetElement> {
    invoiceIds: string[];
    setInvoiceIds: React.Dispatch<React.SetStateAction<string[]>>;
    value?: Date[];
    setValue: React.Dispatch<React.SetStateAction<Date[]>>;
    className?: string;
    disabled?: boolean;
}

export interface BillDetailsRefs {
    billDatesRef: MutableRefObject<RefObject<HTMLInputElement>[]>;
    invoiceIdsRef: MutableRefObject<RefObject<HTMLInputElement>[]>;
}

export const BillDetails: ForwardRefExoticComponent<
    PropsWithoutRef<BillDetailsProps> & RefAttributes<BillDetailsRefs>
> = memo(
    forwardRef<BillDetailsRefs, BillDetailsProps>(
        (
            {
                invoiceIds,
                setInvoiceIds,
                value = [],
                setValue,
                className,
                disabled = false,
            }: BillDetailsProps,
            ref: ForwardedRef<BillDetailsRefs>
        ) => {
            const refs = ref as React.MutableRefObject<BillDetailsRefs>;
            const { billDatesRef, invoiceIdsRef } = refs.current;

            const transferBillDateFieldRefs = useRef(
                value.map(() => React.createRef<TransferBillDateFieldRef>())
            );

            useEffect(() => {
                const currentLength = transferBillDateFieldRefs.current.length;
                if (value.length > currentLength) {
                    transferBillDateFieldRefs.current.push(
                        ...Array(value.length - currentLength)
                            .fill(0)
                            .map(() =>
                                React.createRef<TransferBillDateFieldRef>()
                            )
                    );
                } else {
                    transferBillDateFieldRefs.current.splice(value.length);
                }
            }, [value]);

            const updateBillDates = useCallback(
                (updatedDate: Date, index: number) => {
                    setValue((billDates) =>
                        billDates.map((billDate, i) =>
                            i !== index ? billDate : updatedDate
                        )
                    );
                },
                [setValue]
            );

            const updateInvoiceIds = useCallback(
                (updatedInvoiceId: string, index: number) => {
                    setInvoiceIds((invoiceIds) =>
                        invoiceIds.map((invoiceId, i) =>
                            i !== index ? invoiceId : updatedInvoiceId
                        )
                    );
                },
                [setInvoiceIds]
            );

            const removeBillDetails = useCallback(
                (index: number) => {
                    setValue((billDates) =>
                        billDates.filter((_, i) => i !== index)
                    );

                    setInvoiceIds((invoiceIds) =>
                        invoiceIds.filter((_, i) => i !== index)
                    );
                },
                [setValue, setInvoiceIds]
            );

            const addBillDate = useCallback(
                (date: Date) => {
                    setValue((billDates) => [...billDates, date]);
                    setInvoiceIds((invoiceIds) => [...invoiceIds, ""]);
                },
                [setValue, setInvoiceIds]
            );

            const addBillDateByPeriod = useCallback(
                (
                    event: any, //React.MouseEvent<HTMLAnchorElement>,
                    period: "day" | "week" | "month" = "month",
                    billDates: Date[]
                ) => {
                    event.preventDefault();

                    const type =
                        period === "day"
                            ? [1, `days`]
                            : period === "week"
                            ? [7, `days`]
                            : [1, `months`];

                    const lastDate = billDates?.at(-1) || new Date();

                    const newDate = addTimePeriodToDate(
                        type[0],
                        false,
                        new Date(lastDate).valueOf() / 1000,
                        type[1] as string
                    );

                    addBillDate(new Date(newDate));
                },
                [addBillDate]
            );

            return (
                <Field className={className}>
                    <Label htmlFor="billDate" required>
                        Bill details
                    </Label>
                    <S.Dates>
                        {value?.map((date, index, dates) => {
                            billDatesRef.current[index] =
                                createRef<HTMLInputElement>();

                            invoiceIdsRef.current[index] =
                                createRef<HTMLInputElement>();

                            return (
                                <S.BillDateContainer key={index}>
                                    <div>
                                        <TransferBillDateField
                                            defaultBillDate={date}
                                            disabled={disabled}
                                            ref={
                                                transferBillDateFieldRefs
                                                    .current[index]
                                            }
                                            onChangeBillDate={(updatedDate) =>
                                                updateBillDates(
                                                    updatedDate,
                                                    index
                                                )
                                            }
                                        />
                                    </div>
                                    <S.InvoiceAndRemove>
                                        <Input
                                            type="text"
                                            name="invoiceId"
                                            innerRef={
                                                invoiceIdsRef.current[index]
                                            }
                                            value={invoiceIds[index]}
                                            onBlur={(event) =>
                                                validatePresence({
                                                    input: event.target,
                                                    reportValidity: false,
                                                })
                                            }
                                            onChange={(event) =>
                                                updateInvoiceIds(
                                                    event.target.value,
                                                    index
                                                )
                                            }
                                            disabled={disabled}
                                            placeholder="Invoice Number"
                                        />

                                        <S.Remove>
                                            {dates.length > 1 ? (
                                                <Button
                                                    variant={
                                                        ButtonVariants.Anchor
                                                    }
                                                    underlined={false}
                                                    onClick={() =>
                                                        removeBillDetails(index)
                                                    }
                                                    disabled={disabled}
                                                >
                                                    Remove
                                                </Button>
                                            ) : (
                                                <S.Hide>Remove</S.Hide>
                                            )}
                                        </S.Remove>
                                    </S.InvoiceAndRemove>
                                </S.BillDateContainer>
                            );
                        })}
                    </S.Dates>
                    <S.AddDates>
                        <S.AddDatesLabel>Add another date:</S.AddDatesLabel>
                        <S.Period
                            variant={ButtonVariants.Anchor}
                            onClick={(event: any) =>
                                addBillDateByPeriod(event, "day", value)
                            }
                            disabled={disabled}
                        >
                            + 1 day
                        </S.Period>
                        <S.Period
                            variant={ButtonVariants.Anchor}
                            onClick={(event: any) =>
                                addBillDateByPeriod(event, "week", value)
                            }
                            disabled={disabled}
                        >
                            + 1 week
                        </S.Period>
                        <S.Period
                            variant={ButtonVariants.Anchor}
                            onClick={(event: any) =>
                                addBillDateByPeriod(event, "month", value)
                            }
                            disabled={disabled}
                        >
                            + 1 month
                        </S.Period>
                    </S.AddDates>
                </Field>
            );
        }
    )
);

export default BillDetails;
