import React from "react";

const Check = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            {name && <title>{name}</title>}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.459 12.609 13.89 0 16 1.331l-8.777 14.67L0 11.552l1.39-2.046 5.069 3.102v.001Z"
                fill="inherit"
            />
        </svg>
    );
};

export default Check;
