import ItemManage from "company/components/ItemManage";
import ItemEntityCell from "company/components/items/ItemEntityCell";
import ItemNameCell from "company/components/items/ItemNameCell";
import ItemTokensCell from "company/components/items/ItemTokensCell";
import { Company, ItemSourceType, itemSourceForDisplay } from "company/types";
import { UserRole, useUser } from "context/User";
import { useCallback, useMemo } from "react";
import {
    itemAmountForDisplay,
    itemFrequencyForDisplay,
    itemInitialOffsetForDisplay,
} from "utils/items";
import ItemAutoInvoiceCell from "company/components/items/ItemAutoInvoiceCell";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";
import { useGetCompanyConfig } from "./useGetCompanyConfig";
import { FrequencyType } from "types/common-enums";

export enum ItemValues {
    id = "id",
    entity = "entity",
    name = "name",
    amount = "amount",
    tokens = "tokens",
    frequency = "frequency",
    invoicing = "invoicing",
    manage = "manage",
    initialOffset = "initialOffset",
}

export type ItemRowValues = {
    [key in ItemValues]: Field;
};

export type ItemTableRow = TableRow<ItemRowValues>;

export default function useFormatCompanyItemsTable(items: Company.Item[]) {
    const { hasRole } = useUser();
    const canManage = hasRole(UserRole.COMPANY);
    const {
        config: { entities },
        getCompanyConfigIsLoading,
    } = useGetCompanyConfig();
    const singleEntity = entities.length === 1;

    const { tokens, getTokensMetadataIsLoading } = useGetTokensMetadata();
    const { networks, getNetworksIsLoading } = useGetNetworks();

    const isLoading =
        getCompanyConfigIsLoading ||
        getTokensMetadataIsLoading ||
        getNetworksIsLoading;

    const itemsTableHeadings: RowHeading<ItemTableRow>[] = useMemo(() => {
        return [
            { label: "ID", field: ItemValues.id },
            {
                label: "Entity",
                field: ItemValues.entity,
                hide: singleEntity,
                sortable: true,
            },
            { label: "Name", field: ItemValues.name, sortable: true },
            {
                label: "Amount",
                field: ItemValues.amount,
                sortable: true,
                style: { textAlign: "right" },
            },
            { label: "Tokens", field: ItemValues.tokens },
            { label: "Frequency", field: ItemValues.frequency },
            { label: "Source", field: ItemValues.invoicing, sortable: true },

            { label: "Manage", field: ItemValues.manage, hide: !canManage },
            { label: "Free trial days", field: ItemValues.initialOffset },
        ];
    }, [canManage, singleEntity]);

    const getItemsTableHeadings = useCallback(
        (itemsValues: ItemValues[]) => {
            const orderLookup = itemsValues.reduce<Record<string, number>>(
                (acc, field, index) => {
                    acc[field] = index;
                    return acc;
                },
                {}
            );

            return itemsTableHeadings
                .filter(
                    (heading) =>
                        heading.field !== "id" &&
                        !heading.hide &&
                        itemsValues.includes(heading.field)
                )
                .sort((a, b) => orderLookup[a.field] - orderLookup[b.field]);
        },
        [itemsTableHeadings]
    );

    const filterItemTableRow = useCallback(
        (
            itemsTableRow: ItemTableRow[],
            search: string = "",
            sourceIds: Company.Item["sourceId"][] = [],
            frequencyTypes: FrequencyType[]
        ): ItemTableRow[] => {
            const searchFormatted = search.trim().toLocaleLowerCase();
            return itemsTableRow.filter((itemTableRow) => {
                const searchMatch =
                    searchFormatted.length === 0 ||
                    Object.values(itemTableRow).some((v) => {
                        const textFormatted = v.text
                            .toString()
                            .toLocaleLowerCase();
                        const valueFormatted = v.value
                            .toString()
                            .toLocaleLowerCase();
                        return (
                            textFormatted.includes(searchFormatted) ||
                            valueFormatted.includes(searchFormatted)
                        );
                    });

                const sourcesForDisplay = sourceIds.map((sourceId) =>
                    itemSourceForDisplay[sourceId].toLocaleLowerCase()
                );

                const sourceIdsMatch =
                    sourcesForDisplay.length === 0 ||
                    sourcesForDisplay.some((sourceForDisplay) =>
                        itemTableRow.invoicing.value
                            .toLocaleLowerCase()
                            .includes(sourceForDisplay)
                    );

                const frequencyMatch =
                    frequencyTypes.length === 0 ||
                    frequencyTypes.some((frequencyType) =>
                        itemTableRow.frequency.value.includes(frequencyType)
                    );
                return searchMatch && sourceIdsMatch && frequencyMatch;
            });
        },
        []
    );

    const itemTableRows: ItemTableRow[] = useMemo(() => {
        return items.map((item) => {
            const entity = entities.find(
                (e: Company.Entity) => e.entityId === item.entityId
            );

            const entityName = entity?.name || "";
            const frequencyString = itemFrequencyForDisplay(item.frequency);
            const initialOffsetForDisplay = itemInitialOffsetForDisplay(
                item.initialOffset
            );

            const acceptedTokens = tokens.filter((token) => {
                if (!item.acceptedTokensByNetwork[token.networkId])
                    return false;
                return item.acceptedTokensByNetwork[token.networkId].includes(
                    token.address
                );
            });

            const networkIds = Object.keys(item.acceptedTokensByNetwork).map(
                (n) => Number(n)
            );

            const itemNetworks = networks.filter((network) =>
                networkIds.includes(network.id)
            );

            const tokensForDisplay = [
                itemNetworks.map((t) => t.name).join("  "),
                acceptedTokens.map((t) => t.symbol).join("  "),
            ].join(" ");

            const invoicingForDisplay =
                item.sourceId === ItemSourceType.Loop
                    ? [
                          itemSourceForDisplay[item.sourceId],
                          item.autoInvoice ? "Automatic" : "Manual",
                      ].join(" - ")
                    : itemSourceForDisplay[item.sourceId] +
                      " " +
                      item.externalId;

            return {
                [ItemValues.id]: {
                    label: item.id,
                    value: item.id,
                    text: item.id,
                },
                [ItemValues.entity]: {
                    label: <ItemEntityCell item={item} />,
                    value: entityName,
                    text: entityName,
                },
                [ItemValues.name]: {
                    label: <ItemNameCell item={item} />,
                    value: item.name,
                    text: item.name,
                },
                [ItemValues.amount]: {
                    label: itemAmountForDisplay(item.amount),
                    value: itemAmountForDisplay(item.amount),
                    text: itemAmountForDisplay(item.amount),
                    style: { textAlign: "right" },
                },
                [ItemValues.tokens]: {
                    label: <ItemTokensCell item={item} />,
                    value: tokensForDisplay,
                    text: tokensForDisplay,
                },
                [ItemValues.frequency]: {
                    label: frequencyString,
                    value: `${item.frequency.type} ${frequencyString}`,
                    text: `${item.frequency.type} ${frequencyString}`,
                },
                [ItemValues.invoicing]: {
                    label: <ItemAutoInvoiceCell item={item} />,
                    value: invoicingForDisplay,
                    text: invoicingForDisplay,
                },
                [ItemValues.initialOffset]: {
                    label: initialOffsetForDisplay,
                    value: initialOffsetForDisplay,
                    text: initialOffsetForDisplay,
                },
                [ItemValues.manage]: {
                    label: <ItemManage item={item} />,
                    value: "Manage",
                    text: "Manage",
                    style: { textAlign: "center" },
                },
            };
        });
    }, [entities, items, networks, tokens]);

    return {
        getItemsTableHeadings,
        itemTableRows,
        filterItemTableRow,
        isLoading,
    };
}
