import * as S from "./style";
import { useRef } from "react";
import { toDollar } from "utils/financial";
import { regex, regexToPattern } from "utils/regex";
import Icon from "components/Icon";

const CurrencySelector = ({
    label = "",
    subLabel = "",
    balance = null,
    data,
    amount,
    currency,
    onCurrencyChange,
    onAmountChange,
    basis,
    usdValue,
    loading = false,
    valid = true,
    id,
}) => {
    const inputAmt = useRef(null);
    const inputCurr = useRef(null);

    const handleFocusOrClick = (e) => {
        // Select the entire text to overwrite when focused or clicked
        inputAmt.current.select();
    };

    const handleCurrencyChange = (e) => {
        onCurrencyChange(inputCurr.current.value, inputAmt.current.value);
    };

    const handleAmountChange = (e) => {
        if (!e.target.validity.valid) return;
        onAmountChange(inputCurr.current.value, inputAmt.current.value);
    };

    return (
        <S.CurrencySelector>
            <S.LabelDropInput>
                <h2>
                    {label} {subLabel && <small>{subLabel}</small>}
                </h2>
                {balance !== null && (
                    <small>
                        <b id="balanceLbl">Balance</b>
                        {` `}
                        <data value={balance} aria-labelledby="balanceLbl">
                            {balance} {currency}
                        </data>
                    </small>
                )}
            </S.LabelDropInput>

            {!data.length || !currency ? (
                <S.Currency valid={false}>
                    <S.NoTokens>
                        Tokens on this network are unavailable.
                    </S.NoTokens>
                </S.Currency>
            ) : (
                <S.Currency
                    highlight={basis}
                    disabled={loading}
                    valid={valid}
                    title={`1 ${currency} = ${toDollar(usdValue)} USD`}
                >
                    <S.SelectCurrency>
                        <Icon
                            wrapped
                            src={`/img/token-logo/${currency.toLowerCase()}.svg`}
                            alt={`${currency} logo`}
                            disabled={loading}
                            round
                            tighten
                            size="3rem"
                            as="label"
                            fit="width"
                            htmlFor={data.length > 1 ? id : null}
                        />
                        {data.length > 1 ? (
                            <select
                                name="currency"
                                ref={inputCurr}
                                defaultValue={currency}
                                onChange={handleCurrencyChange}
                                disabled={loading}
                                id={id}
                            >
                                {data.map(({ symbol, label }) => (
                                    <option key={symbol} value={symbol}>
                                        {label} ({symbol})
                                    </option>
                                ))}
                            </select>
                        ) : (
                            <S.SingleCurrency
                                type="text"
                                name="currency"
                                ref={inputCurr}
                                value={currency}
                                disabled={true}
                            />
                        )}
                    </S.SelectCurrency>
                    <S.InputCurrency>
                        <input
                            type="text"
                            name="amount"
                            ref={inputAmt}
                            value={amount}
                            pattern={regexToPattern(regex.coins)}
                            onFocus={handleFocusOrClick}
                            onClick={handleFocusOrClick}
                            onChange={handleAmountChange}
                            disabled={loading}
                        />
                        <output>
                            {loading
                                ? `Loading...`
                                : `1 ${currency} = ${toDollar(usdValue)} USD`}
                        </output>
                    </S.InputCurrency>
                </S.Currency>
            )}
        </S.CurrencySelector>
    );
};

export default CurrencySelector;
