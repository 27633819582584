import styled from "styled-components";

export const GridColumn = styled.div<{ col: number }>`
    display: grid;
    grid-template-columns: repeat(${(props) => props.col}, 1fr);
    grid-gap: 1em;
    align-items: top;
`;
export const Fieldset = styled.fieldset`
    width: 100%;
`;
