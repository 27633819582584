import { link, highlight, colorsDeprecated as c } from "global-style";
import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import Anchor from "components/Anchor";

export const SectionHeading = styled.h3`
    font-weight: 700;
    text-transform: uppercase;
    margin-block: 1rem;
`;

export const List = styled.ul`
    margin-block: 1.5rem;
`;

export const Item = styled.li`
    margin-block: 1rem;
`;

export const InternalLink = styled(NavLink)<{ disabled?: boolean }>`
    ${link}
    ${({ disabled }) =>
        disabled &&
        css`
            pointer-events: none;
            color: ${c.disabledInverse};
            fill: ${c.disabledInverse};
        `}
    &.active {
        ${highlight}
    }
    &:hover {
        ${highlight}
    }
`;
export const StaticLink = styled(Anchor)`
    ${link}
    &:hover {
        ${highlight}
    }
`;

export const DynamicSubNav = styled.ul<{ selected: boolean }>`
    margin-block-start: 0.5rem;
    margin-inline-start: 0.6667em;
    padding-inline-start: 1.75rem;
    border-inline-start: 0.1rem solid #ddd;
    display: ${({ selected }: { selected: boolean }) =>
        selected ? "block" : "none"};

    & li {
        margin-block: 0 0.5rem;
    }
`;
