import { useEffect, useState, useRef } from "react";
import * as S from "./style";
import { useLoopContract } from "hooks/useLoopContract";
import { frequencyTypes } from "default-variables";
import { useSwapForm } from "recurring/context/SwapForm";
import { useTokens } from "hooks/useTokens";
import { useScreenController } from "recurring/context/ScreenController";
import { CardHeader, CardSection, CardFooter } from "components/Card";
import Suggestion from "components/Suggestion";
import StatusUpdate from "components/StatusUpdate";
import InputText from "components/InputText";
import { useAvailableNetworks } from "hooks/useAvailableNetworks";
import { useNotificationQueue } from "context/NotificationQueue";
import { NotificationType } from "components/Notification";
import { useWallet } from "context/Wallet";

const TransactionPending = () => {
    const { exchange, frequency, slippage } = useSwapForm();
    const { networkConnected, addToTokenAllowance } = useWallet();
    const { getTokenOnNetwork } = useTokens();
    const { getNetworkByContract } = useAvailableNetworks();
    const { onContractComplete, onLoadForm } = useScreenController();
    const { addNotification } = useNotificationQueue();
    const { sendRecurringSwapOrder } = useLoopContract();

    const [stage, setStage] = useState({ action: ``, data: {} });
    const copyInput = useRef(null);

    useEffect(() => {
        const callAllowance = async () => {
            const { tokenAddress: sellTokenAddress, decimal: sellDecimal } =
                getTokenOnNetwork(
                    networkConnected.networkId,
                    exchange.sell.currency
                );

            let result = null;
            try {
                result = await addToTokenAllowance({
                    contractAddress: networkConnected.contractAddress,
                    tokenAddress: sellTokenAddress,
                    amount: String(
                        Number(exchange.sell.amount) *
                            frequencyTypes.find(
                                ({ value }) => value === frequency
                            ).expectedNumberOfTransactions
                    ),
                    decimals: sellDecimal,
                });
                if (result === null) {
                    throw new Error(`Your allowance was not updated`);
                }
            } catch (error) {
                throw new Error(error);
            }
            return result;
        };

        const callOrder = async () => {
            const {
                tokenAddress: sellTokenAddress,
                decimal: sellDecimal,
                swapsTo,
            } = getTokenOnNetwork(
                networkConnected.networkId,
                exchange.sell.currency
            );

            const { tokenAddress: buyTokenAddress } = getTokenOnNetwork(
                networkConnected.networkId,
                exchange.buy.currency
            );

            const txFee =
                swapsTo.find(({ token }) => token === exchange.buy.currency)
                    ?.fee || 0;

            let result = null;
            try {
                result = await sendRecurringSwapOrder(
                    sellTokenAddress,
                    exchange.sell.amount,
                    sellDecimal,
                    buyTokenAddress,
                    frequency,
                    slippage,
                    txFee
                );
                return result;
            } catch (error) {
                throw new Error(error);
            }
        };

        if (stage.action === `idle`) {
            setStage({ action: `allowing`, data: {} });
            // Extend the allowance amount
            callAllowance()
                .then(
                    (result) => {
                        setStage({ action: `subscribing`, data: {} });
                        // Execute the contract/order
                        callOrder().then(
                            (result) => {
                                setStage({
                                    action: `complete`,
                                    data: {
                                        contractAddress: result.to,
                                        networkId: getNetworkByContract(
                                            result.to
                                        ),
                                        walletAddress: result.from,
                                    },
                                });
                            },
                            (reason) => {
                                addNotification({
                                    msg: reason,
                                    type: NotificationType.ERROR,
                                });
                                onLoadForm();
                            }
                        );
                    },
                    (reason) => {
                        addNotification({
                            msg: reason,
                            type: NotificationType.ERROR,
                        });
                        onLoadForm();
                    }
                )
                .catch((err) => {
                    console.error(err);
                });
        } else if (stage.action === `complete`) {
            const { contractAddress, networkId, walletAddress } = stage.data;
            onContractComplete(contractAddress, networkId, walletAddress);
        } else if (!stage.action) {
            setStage({ action: `idle`, data: {} });
        }
    }, [stage, exchange, frequency, slippage, addToTokenAllowance]);

    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(copyInput.current.value);
        } catch (e) {
            console.error(e);
        }
    };

    const statusUpdates = {
        allowed:
            stage.action === `allowing`
                ? `loading`
                : stage.action === `subscribing`
                ? `complete`
                : !stage.action
                ? `idle`
                : stage.action,
        subscribed:
            stage.action === `subscribing`
                ? `loading`
                : stage.action === `allowing`
                ? `idle`
                : !stage.action
                ? `idle`
                : stage.action,
    };

    return (
        <S.TransactionPending>
            <CardHeader>
                <S.Heading>Transaction scheduled</S.Heading>
            </CardHeader>
            <CardSection>
                <S.TransactionList>
                    <StatusUpdate as="li" status={statusUpdates.allowed}>
                        Setting the allowance
                    </StatusUpdate>
                    <StatusUpdate as="li" status={statusUpdates.subscribed}>
                        Authorizing the recurring swap
                    </StatusUpdate>
                </S.TransactionList>
                <p>
                    Two transactions should automatically initiate in your
                    wallet. Please confirm both of them. This page will
                    automatically change to complete when your transaction
                    confirms.
                </p>
            </CardSection>
            <CardSection>
                <Suggestion>
                    <p>
                        If you've been waiting more than 5 minutes, your
                        transactions may be stuck due to gas fees being set too
                        low.
                    </p>
                </Suggestion>
            </CardSection>
            <CardFooter>
                <h2>Contract address</h2>
                <InputText
                    value={networkConnected.contractAddress}
                    readOnly="readonly"
                    ref={copyInput}
                    button={{
                        label: copyBtnImg,
                        onClick: handleCopyClick,
                        title: `Copy the contract address`,
                        hallow: true,
                        borderless: true,
                    }}
                />
            </CardFooter>
        </S.TransactionPending>
    );
};

const copyBtnImg = (
    <S.CopyBtnImg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.5 1H4.5C3.4 1 2.5 1.9 2.5 3V17H4.5V3H16.5V1ZM19.5 5H8.5C7.4 5 6.5 5.9 6.5 7V21C6.5 22.1 7.4 23 8.5 23H19.5C20.6 23 21.5 22.1 21.5 21V7C21.5 5.9 20.6 5 19.5 5ZM8.5 21H19.5V7H8.5V21Z"
        />
    </S.CopyBtnImg>
);

export default TransactionPending;
