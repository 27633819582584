import * as S from "./style";
import { ReactNode, useRef } from "react";
import { useNotificationQueue } from "context/NotificationQueue";
import Copy from "components/icons/Copy";
import { NotificationType } from "components/Notification";

interface CopyableProps {
    children: ReactNode;
    value?: string;
    fill?: string;
}

const Copyable = ({ children, value, fill, ...props }: CopyableProps) => {
    const { addNotification } = useNotificationQueue();
    const ref = useRef<HTMLSpanElement>(null);

    const handleClick = () => {
        if (!ref.current) return;
        if (value) {
            navigator.clipboard.writeText(value);
        } else if (ref.current.textContent) {
            navigator.clipboard.writeText(ref.current.textContent);
        } else {
            navigator.clipboard.writeText("");
        }
        if (addNotification) {
            addNotification({
                msg: `Copied to clipboard`,
                type: NotificationType.INFO,
                expires: 3000,
            });
        }
    };

    return (
        <S.Copyable {...props}>
            <S.Children ref={ref}>{children}</S.Children>
            <span>
                <Copy
                    onClick={handleClick}
                    height="1rem"
                    width="1rem"
                    fill={fill}
                />
            </span>
        </S.Copyable>
    );
};

export default Copyable;
