import React, {
    HTMLAttributes,
    RefObject,
    useEffect,
    useRef,
    useState,
} from "react";
import * as S from "./styles";

export interface TextAreaProps extends HTMLAttributes<HTMLTextAreaElement> {
    value?: string | number;
    name?: string;
    placeholder?: string;
    className?: string;
    onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
    onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
    onInput?: React.ChangeEventHandler<HTMLTextAreaElement>;
    onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;
    resize?: boolean;
    autoGrow?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    required?: boolean;
    pattern?: string;
    min?: number | string;
    max?: number | string;
    step?: number | string;
    stepper?: boolean;
    size?: number;
    slim?: boolean;
    innerRef?: RefObject<HTMLTextAreaElement>;
    removeValidationOnChange?: boolean;
}

export const TextArea: React.FunctionComponent<TextAreaProps> = ({
    value: valueFromProp,
    name,
    placeholder,
    className,
    onBlur,
    onChange,
    onFocus,
    onInput,
    onKeyDown,
    resize = true,
    autoGrow = false,
    disabled = false,
    readOnly = false,
    required = false,
    pattern,
    min,
    max,
    step,
    stepper = false,
    size,
    slim,
    innerRef,
    removeValidationOnChange = true,
    ...inputProps
}) => {
    const [value, setValue] =
        useState<string | number | undefined>(valueFromProp);

    const defaultRef = useRef<HTMLTextAreaElement>(null);
    const ref = innerRef || defaultRef;

    useEffect(() => {
        setValue(valueFromProp);
    }, [valueFromProp]);

    // AutoGrow
    useEffect(() => {
        if (ref?.current && autoGrow) {
            ref.current.style.height = "5px";
            ref.current.style.height = ref.current.scrollHeight + "px";
        }
    }, [ref, value, autoGrow]);

    const handleOnBlur: React.FocusEventHandler<HTMLTextAreaElement> = (
        event: React.FocusEvent<HTMLTextAreaElement>
    ) => {
        if (onBlur) {
            onBlur(event);
        }
    };

    const handleOnChange: React.ChangeEventHandler<HTMLTextAreaElement> = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setValue(event.target.value);

        // Avoid the browser tooltip bug
        if (removeValidationOnChange) {
            event.target.setCustomValidity("");
        }
        if (onChange) {
            onChange(event);
        }
    };

    const handleOnFocus: React.FocusEventHandler<HTMLTextAreaElement> = (
        event: React.FocusEvent<HTMLTextAreaElement>
    ) => {
        if (onFocus) {
            onFocus(event);
        }
    };

    const handleOnInput: React.FocusEventHandler<HTMLTextAreaElement> = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        if (onInput) {
            onInput(event);
        }
    };

    const handleOnKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (
        event: React.KeyboardEvent<HTMLTextAreaElement>
    ) => {
        if (onKeyDown) {
            onKeyDown(event);
        }
    };

    return (
        <S.TextArea
            resize={resize}
            autoGrow={autoGrow}
            {...inputProps}
            name={name}
            className={className}
            placeholder={placeholder}
            id={name}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            onFocus={handleOnFocus}
            onInput={handleOnInput}
            onKeyDown={handleOnKeyDown}
            disabled={disabled}
            value={value}
            readOnly={readOnly}
            required={required}
            ref={ref}
        />
    );
};

export default TextArea;
