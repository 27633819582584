import { toDollar } from "utils/financial";
import { strFrequency } from "utils/datetime";
import { Company, ItemSourceType } from "company/types";
import { sumArray } from "utils/arrays";
import {
    calculateAmountAfterDiscount,
    defaultNumTimesCharged,
    DiscountInfo,
} from "utils/checkout";
import { regex } from "utils/regex";
import { toNumber } from "utils/numbers";
import { BaseItem, ItemFrequency } from "types/common";
import {
    FrequencyType,
    ItemCategoryType,
    ItemCategoryTypeOutbound,
} from "types/common-enums";
import { CheckoutItemResponse } from "api/types/checkout";
import { CheckoutItem } from "checkout/types";

/* 
    Constants
*/

// "Conceptual" frequency options, these are only used for the frontend to switch
// the UI when creating/editing a subscription or a one time payment, which later
// becomes into the item.frequency

export enum ItemFrequencyNames {
    Subscription = "subscription",
    OneTimePayment = "one_time_payment",
}

export const itemFrequencyNamesForDisplay: Record<ItemFrequencyNames, string> =
    {
        [ItemFrequencyNames.OneTimePayment]: "one-time purchase",
        [ItemFrequencyNames.Subscription]: "subscription",
    };

export const ITEM_MINIMUM_AMOUNT_IN_CENT = 1;
export const ITEM_MAXIMUM_AMOUNT_IN_CENT = 50_000_00;

/*
    Filters
*/

export function isVariablePricing(amount: number | null) {
    return amount !== null && amount === 0;
}

export function isSubscription(
    frequencyValue: number,
    frequencyType: FrequencyType | ""
) {
    return (
        frequencyValue !== 0 &&
        frequencyType &&
        Object.values(FrequencyType).includes(frequencyType)
    );
}

export function isOneTimePayment(frequencyValue: number) {
    return frequencyValue === 0;
}

export const isOutboundPayment = (type: ItemCategoryType) => {
    return ItemCategoryTypeOutbound.includes(type);
};

export function isValidSourceId(sourceId: number): boolean {
    return Object.values(ItemSourceType).includes(sourceId);
}

export const isPricedInToken = (amount: number | null): amount is null =>
    amount === null;

export enum ItemPriceType {
    USD,
    TOKEN,
}

export const getItemPriceType = (amount: number | null) =>
    isPricedInToken(amount) ? ItemPriceType.TOKEN : ItemPriceType.USD;

/*
    Formatters
*/

export const VARIABLE_PRICING_FOR_DISPLAY = "Price varies";
export const TOKEN_PRICING_FOR_DISPLAY = "Priced in token";

export function itemAmountForDisplay(amount: number | null) {
    if (isPricedInToken(amount)) {
        return TOKEN_PRICING_FOR_DISPLAY;
    } else if (isVariablePricing(amount)) {
        return VARIABLE_PRICING_FOR_DISPLAY;
    } else {
        return toDollar(amount);
    }
}

export function itemFrequencyForDisplay(frequency: ItemFrequency) {
    return strFrequency(frequency.value, frequency.type);
}

export const itemFrequencyTypeForDisplay = {
    [FrequencyType.Hour]: "Hour",
    [FrequencyType.Day]: "Day",
    [FrequencyType.Week]: "Week",
    [FrequencyType.Month]: "Month",
    [FrequencyType.Year]: "Year",
};

export function itemInitialOffsetForDisplay(initialOffset: number) {
    if (initialOffset === 0) return "No free trial";
    return `${initialOffset} ${initialOffset === 1 ? "day" : "days"} free`;
}

/* 
    Calculators 
*/

type SuggestedAllowanceForItem = {
    amountInCents: number;
    frequency: ItemFrequency;
};

export function suggestedAllowanceForItem(
    { amountInCents, frequency }: SuggestedAllowanceForItem,
    discountInfo?: DiscountInfo
) {
    const isSubscriptionType =
        frequency.type &&
        isSubscription(frequency.value, frequency.type as FrequencyType);

    if (!isSubscriptionType) return amountInCents;

    const numTimesCharged = defaultNumTimesCharged[frequency.type] ?? 1;
    const discountedAmount = discountInfo
        ? calculateAmountAfterDiscount(amountInCents, discountInfo)
        : amountInCents;

    return discountInfo?.singleUse
        ? Math.ceil((numTimesCharged - 1) * amountInCents + discountedAmount)
        : Math.ceil(discountedAmount * numTimesCharged);
}

export function suggestedAllowanceForItems(
    items: Company.Item[] | CheckoutItemResponse[] | BaseItem[],
    discountInfo?: DiscountInfo
) {
    return sumArray(
        items.map((item) =>
            suggestedAllowanceForItem(
                {
                    amountInCents: toNumber(item.amount ?? 0),
                    frequency: item.frequency,
                },
                discountInfo
            )
        )
    );
}

// Sums items minimum allowance for you
export function minimumAllowanceForItems(
    items: Company.Item[] | CheckoutItemResponse[] | BaseItem[]
) {
    return sumArray(items.map(({ amount }) => toNumber(amount ?? 0)));
}

// Sums items minimum allowance for you
export function minimumBalanceRequiredForItems(
    items: Company.Item[] | CheckoutItemResponse[] | BaseItem[] | CheckoutItem[]
) {
    return sumArray(items.map(({ amount }) => toNumber(amount ?? 0)));
}

/* 
    Checkout URL query
*/

type GetCheckoutUrlQuery = {
    items?: Company.Item[] | CheckoutItemResponse[];
    cartEnabled?: boolean;
    email?: string;
    minimumBalanceRequired?: number;
    minimumBalanceRequiredWasEdited?: boolean;
    suggestedAllowance?: number;
    suggestedAllowanceWasEdited?: boolean;
    refId?: string;
    externalSubscriptionId?: string;
};

export function getCheckoutUrlQuery({
    items = [],
    cartEnabled = true,
    email,
    minimumBalanceRequired,
    minimumBalanceRequiredWasEdited,
    suggestedAllowance,
    suggestedAllowanceWasEdited,
    refId,
    externalSubscriptionId,
}: GetCheckoutUrlQuery) {
    // Query time
    const urlQuery = new URLSearchParams();
    const otherItems = items.slice(1);
    if (otherItems) {
        otherItems.forEach((item) => urlQuery.append("itemId", item.id));
    }

    // Query: cartEnabled (only append if false)
    if (!cartEnabled) {
        urlQuery.append("cartEnabled", cartEnabled.toString());
    }

    // Query: User email
    if (email && regex.email.test(email)) {
        urlQuery.append("email", email);
    }

    // Query: minimumBalanceRequired
    if (
        minimumBalanceRequired !== undefined &&
        minimumBalanceRequiredWasEdited
    ) {
        urlQuery.append(
            "minimumBalanceRequired",
            minimumBalanceRequired.toString()
        );
    }

    // Query: defaultSpendingCap (suggestedAllowance)
    if (suggestedAllowance !== undefined && suggestedAllowanceWasEdited) {
        urlQuery.append("defaultSpendingCap", suggestedAllowance.toString());
    }

    if (
        externalSubscriptionId !== undefined &&
        externalSubscriptionId.trim().length > 0
    ) {
        urlQuery.append("sub", externalSubscriptionId.toString());
    }

    // Query: refId
    if (refId && refId.trim().length > 0) {
        urlQuery.append("refId", refId.trim());
    }

    return urlQuery;
}
