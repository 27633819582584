import React from "react";

const ArrowDownright = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            viewBox="0 0 15 15"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            {name && <title>{name}</title>}
            <path d="M15 5H13V11.59L1.41 0L0 1.41L11.59 13H5V15H15V5Z" />
        </svg>
    );
};

export default ArrowDownright;
