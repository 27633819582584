import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import colors from "theme/colors";
import spacing from "theme/spacing";
import radius from "theme/radius";

export const HomeLink = styled(NavLink)<{ disabled?: boolean }>`
    text-decoration: none;
    color: inherit;
    border: 1px solid ${colors.borderLight};
    padding: ${spacing.sm};
    border-radius: ${radius.md};
    display: flex;
    gap: ${spacing.sm};

    &:hover {
        border: 1px solid ${colors.primary};
    }

    ${({ disabled }) =>
        disabled &&
        css`
            pointer-events: none;
            background-color: ${colors.borderLight};
        `}
`;

export const HomeLinkContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: ${spacing.xxxs};
`;
