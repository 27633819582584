import { SubSection } from "components/SubSection/style";
import styled, { css } from "styled-components";
import spacing from "theme/spacing";

export const Section = styled.section<{ $narrow: boolean }>`
    ${({ $narrow }) =>
        $narrow &&
        css`
            max-width: 40rem;
        `};
    ${SubSection} {
        &:not(:last-child) {
            margin-bottom: ${spacing.lg};
        }
    }
`;

export const TitleWrapper = styled.header`
    margin-bottom: ${spacing.sm};
`;
