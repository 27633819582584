import * as S from "./style";
import React, {
    useCallback,
    useState,
    useRef,
    useEffect,
    useMemo,
} from "react";
import { parseUnits } from "ethers";
import {
    AmountType,
    Company,
    CompanyEntityFeeResponse,
    UpdateTransferDelegatedSigner,
} from "company/types";
import { toDollar, toCoin } from "utils/financial";
import Button, { ButtonVariants } from "components/Button";
import { useNotificationQueue } from "context/NotificationQueue";
import TransferAmountField, {
    TransferAmountFieldRef,
} from "company/components/transfers/TransferAmountField";
import TransferUsdField, {
    TransferUsdFieldRef,
} from "company/components/transfers/TransferUsdField";
import {
    CommonBlockchainNetworkResponse,
    GeneralTokenDetailsResponse,
    patchCompanyTransfers,
} from "api";
import { NotificationType } from "components/Notification";
import { useGetCompanyTransfers } from "company/hooks/useGetCompanyTransfers";
import { useUser } from "context/User";
import TransferFeeSubtext from "company/routes/TransactionDetails/TransactionInformation/TransferFeeSubtext";
import Anchor from "components/Anchor";
import { formatPaymentAndFees, getApplicableFee } from "company/utils/fees";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";
import { convertCentsToToken } from "utils/exchangeRates";
import { CompanyItemWithNumberAmount } from "company/hooks/useGetCompanyItems";

interface UpdateTransferAmountFormProps {
    canEditAmount: boolean;
    transaction: Company.Transaction;
    token: GeneralTokenDetailsResponse;
    entity: Company.Entity;
    network: CommonBlockchainNetworkResponse;
    item: CompanyItemWithNumberAmount;
}

const UpdateTransferAmountForm: React.FunctionComponent<UpdateTransferAmountFormProps> =
    ({ canEditAmount, transaction, token, entity, network, item }) => {
        const [showAmountForm, setShowAmountForm] = useState<boolean>(false);

        const [formLoading, setFormLoading] = useState<boolean>(false);
        const [usd, setUsd] = useState<boolean>(transaction.usd);

        // Session & Company data
        const { invalidateAllTransfersQueries } = useGetCompanyTransfers({
            id: transaction.transferId,
        });
        const { getTokenRate } = useGetTokensMetadata();
        const { getEntityId, getSessionToken } = useUser();

        // Notifications
        const { addNotification } = useNotificationQueue();

        const transferAmountFieldRef = useRef<TransferAmountFieldRef>(null);
        const transferUsdFieldRef = useRef<TransferUsdFieldRef>(null);

        const successHandle = useCallback(async () => {
            await invalidateAllTransfersQueries();
            setShowAmountForm(false);
            setUsd(transaction.usd);
        }, [invalidateAllTransfersQueries, transaction.usd]);

        const updateAmount = async () => {
            setFormLoading(true);

            // Fields not present, return
            if (!transferAmountFieldRef.current || !transferUsdFieldRef.current)
                return;

            // Fields have not changed, return
            if (
                !transferAmountFieldRef.current.hasChanged &&
                !transferUsdFieldRef.current.hasChanged
            ) {
                setShowAmountForm(false);
                setUsd(transaction.usd);
                return;
            }

            // Amount isn't valid, return
            if (!transferAmountFieldRef.current.validate()) return;

            const patchTransfer: UpdateTransferDelegatedSigner = {
                transferId: transaction.transferId,
                amount: undefined,
                usd: undefined,
            };

            // Always update the amount if usd field has changed
            if (
                transferAmountFieldRef.current.hasChanged ||
                transferUsdFieldRef.current.hasChanged
            ) {
                patchTransfer.amount =
                    transferAmountFieldRef.current.amountForApi;
            }

            if (transferUsdFieldRef.current.hasChanged) {
                patchTransfer.usd = transferUsdFieldRef.current.usd;
            }

            const headers = {
                Authorization: getSessionToken(),
                "entity-id": getEntityId(),
            };
            const { response } = await patchCompanyTransfers(
                [patchTransfer],
                headers
            );

            const successFullResponse = response.ok && response.status === 200;

            if (successFullResponse) {
                await successHandle();
            } else {
                addNotification({
                    msg: `There was an error while updating the amount`,
                    type: NotificationType.ERROR,
                });
            }

            setFormLoading(false);
        };

        const [{ payment, paymentFees, paymentMinusFees }, setAmountAndFee] =
            useState<ReturnType<typeof formatPaymentAndFees>>({
                payment: "",
                paymentFees: "",
                paymentMinusFees: "",
            });
        const [showFeeAndTotal, setShowFeeAndTotal] = useState<boolean>(true);

        const feesApplied: CompanyEntityFeeResponse | undefined =
            useMemo(() => {
                return getApplicableFee(item, entity.fees, network.id);
            }, [item, entity.fees, network.id]);

        const tokenRate = getTokenRate(token)?.rate || 1;

        const handleAmountChange = useCallback(
            (amount: string) => {
                if (!feesApplied) {
                    setShowFeeAndTotal(false);
                    setAmountAndFee({
                        payment: transaction.amount || "0",
                        paymentFees: "",
                        paymentMinusFees: transaction.amount || "0",
                    });
                    return;
                }
                if (!transferAmountFieldRef.current?.validate()) {
                    setShowFeeAndTotal(false);
                    return;
                }
                setShowFeeAndTotal(true);

                // Revert the amount to the original value, pre discount
                const feeInCurrency =
                    feesApplied.amountType === AmountType.Fiat && !usd
                        ? convertCentsToToken(feesApplied.amount, tokenRate)
                        : feesApplied.amount / 100;

                amount =
                    feesApplied.amountType === AmountType.Percent
                        ? String(
                              Number(amount) / (1 - feesApplied.amount / 10000)
                          )
                        : String(Number(amount) + feeInCurrency);

                const formattedAmount = usd
                    ? String(Number(amount) * 100)
                    : parseUnits(
                          parseFloat(amount).toFixed(token.decimals),
                          token.decimals
                      ).toString();

                const { payment, paymentFees, paymentMinusFees } =
                    formatPaymentAndFees({
                        fee: feesApplied,
                        amount: formattedAmount,
                        usd: usd,
                        decimals: token.decimals,
                        rate: tokenRate,
                        isFiat: feesApplied?.amountType === AmountType.Fiat,
                    });

                setAmountAndFee({
                    payment,
                    paymentFees,
                    paymentMinusFees,
                });
            },
            [feesApplied, tokenRate, token.decimals, usd, transaction.amount]
        );

        useEffect(() => {
            if (showAmountForm) return;
            if (!feesApplied) {
                setShowFeeAndTotal(false);
                setAmountAndFee({
                    payment: transaction.amount || "0",
                    paymentFees: "",
                    paymentMinusFees: transaction.amount || "0",
                });
                return;
            }

            setAmountAndFee(
                formatPaymentAndFees({
                    fee: feesApplied,
                    amount: transaction.amount,
                    usd: transaction.usd,
                    decimals: token.decimals,
                    rate: tokenRate,
                    isFiat: feesApplied?.amountType === AmountType.Fiat,
                })
            );
        }, [
            showAmountForm,
            feesApplied,
            transaction.amount,
            transaction.usd,
            token.decimals,
            tokenRate,
        ]);

        const showUsd = showAmountForm ? usd : transaction.usd;

        // Data for display
        const amountForDisplay = showUsd
            ? `${toDollar(paymentMinusFees)} in ${token.symbol}`
            : `${toCoin(paymentMinusFees)} ${token.symbol}`;

        const amountForInput = showUsd
            ? Number(paymentMinusFees) / 100
            : Number(paymentMinusFees);

        const amountForTotal = showUsd ? toDollar(payment) : toCoin(payment);

        const feeRateForDisplay =
            feesApplied?.amountType === AmountType.Percent
                ? `${feesApplied.amount / 100}%`
                : feesApplied?.amountType === AmountType.Fiat
                ? toDollar(feesApplied?.amount || 0)
                : toCoin(feesApplied?.amount || 0);

        const feeInToken =
            feesApplied?.amountType === AmountType.Token ||
            (feesApplied?.amountType === AmountType.Percent && !showUsd);

        return !showAmountForm ? (
            <>
                <S.AmountWrapper>
                    {amountForDisplay}
                    {canEditAmount && (
                        <Anchor
                            href="#"
                            onClick={() => setShowAmountForm(true)}
                        >
                            Edit
                        </Anchor>
                    )}
                </S.AmountWrapper>
                <TransferFeeSubtext
                    feeRate={feeRateForDisplay}
                    fee={paymentFees}
                    paidInSymbol={token.symbol}
                    total={amountForTotal}
                    feeInToken={feeInToken}
                    txInToken={!transaction.usd}
                    isPercentage={
                        feesApplied?.amountType === AmountType.Percent
                    }
                />
            </>
        ) : (
            <S.EditForm onSubmit={updateAmount}>
                <S.InputWrapper>
                    <TransferAmountField
                        disabled={formLoading}
                        usd={usd}
                        token={token}
                        defaultAmount={String(amountForInput)}
                        ref={transferAmountFieldRef}
                        onChange={handleAmountChange}
                    />
                    {showFeeAndTotal && (
                        <TransferFeeSubtext
                            feeRate={feeRateForDisplay}
                            fee={paymentFees}
                            paidInSymbol={token.symbol}
                            total={amountForTotal}
                            feeInToken={feeInToken}
                            txInToken={!usd}
                            isPercentage={
                                feesApplied?.amountType === AmountType.Percent
                            }
                        />
                    )}
                    <S.UsdCheckboxWrapper>
                        <TransferUsdField
                            disabled={formLoading}
                            defaultUsd={transaction.usd}
                            onChangeUsd={(usd) => setUsd(usd)}
                            ref={transferUsdFieldRef}
                        />
                    </S.UsdCheckboxWrapper>
                </S.InputWrapper>
                <Button type="submit" loading={formLoading}>
                    {formLoading ? "Saving" : "Save"}
                </Button>
                <Button
                    variant={ButtonVariants.Anchor}
                    type="button"
                    disabled={formLoading}
                    onClick={() => {
                        setShowAmountForm(false);
                        setUsd(transaction.usd);
                    }}
                >
                    Cancel
                </Button>
            </S.EditForm>
        );
    };

export default UpdateTransferAmountForm;
