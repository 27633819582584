import styled from "styled-components";

export const CreateSelfServeItemList = styled.ul`
    margin: 0;
`;

export const ItemRowWrapper = styled.li`
    &:not(:last-of-type) {
        margin-bottom: 1rem;
    }
`;
