import { ComponentType } from "react";
import {
    DynamicContextProps,
    DynamicContextProvider,
} from "@dynamic-labs/sdk-react-core";

const withDynamicContext = <
    T extends object & { requiresDynamicLogin?: boolean }
>(
    ContextProvider: ComponentType<T>,
    settings: DynamicContextProps["settings"]
) => {
    return (props: T) => {
        if (!props.requiresDynamicLogin) {
            settings = {
                ...settings,
                initialAuthenticationMode: "connect-only",
                cssOverrides: `
                    ${settings.cssOverrides}
                    /* Hide email when connecting with wallet only */
                    .login-with-email-form {
                        display: none;
                    }
                    .login-view__scroll__section:has(.login-with-email-form) + .login-view__scroll__section--separator {
                        display: none;
                    }
                    
                    /* Change "Login or Signup" */
                    [copykey="dyn_wallet_list.title.connect"], [copykey="dyn_login.title.all"] {
                        visibility: hidden;
                        position: relative;
                    }
                    [copykey="dyn_wallet_list.title.connect"]::after, [copykey="dyn_login.title.all"]::after {
                        content: "Connect a wallet";
                        visibility: visible;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        white-space: nowrap;
                    }
                `,
            };
        }

        return (
            <DynamicContextProvider settings={settings}>
                <ContextProvider {...props} />
            </DynamicContextProvider>
        );
    };
};

export default withDynamicContext;
