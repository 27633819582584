export const STRIPE_SUBSCRIPTION_ID_PREFIX = "sub_";
export const STRIPE_SUBSCRIPTION_ID_LENGTH = 28;
export const STRIPE_SUBSCRIPTION_ID_EXAMPLE = "sub_1OrmgqEsKlfF20DHibWPI5pa";

export function validateStripeSubscriptionId(subscriptionId: string): boolean {
    return (
        !subscriptionId.includes(" ") &&
        subscriptionId.startsWith(STRIPE_SUBSCRIPTION_ID_PREFIX) &&
        subscriptionId.length === STRIPE_SUBSCRIPTION_ID_LENGTH
    );
}
