import styled from "styled-components";
import radius from "theme/radius";

export const SubscriptionId = styled.div`
    background: #f2efff;
    border: 1px solid black;
    border-radius: ${radius.md};
    padding: 1rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
`;
