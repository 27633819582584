import styled from "styled-components";
import {
    colorsDeprecated as c,
    properties as p,
    noBlockMargin,
} from "global-style";
import colors from "theme/colors";

export const Panel = styled.article`
    display: flow-root;
    margin: 1rem 0;
    padding: 1rem;
    border: 1px solid ${c.border};
    border-radius: ${p.radiusLg};
    background-color: ${colors.neutralBackground};
    ${noBlockMargin}
`;
