import React from "react";

const CrossedDollar = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            viewBox="0 0 17 18"
            xmlns="http://www.w3.org/2000/svg"
        >
            {name && <title>{name}</title>}
            <path
                d="M8.50006 3.9C10.2801 3.9 10.9401 4.75 11.0001 6H13.2101C13.1401 4.28 12.0901 2.7 10.0001 2.19V0H7.00006V2.16C6.61006 2.24 6.25006 2.37 5.90006 2.52L7.41006 4.03C7.73006 3.95 8.10006 3.9 8.50006 3.9ZM1.47006 0.92L0.0600586 2.33L3.50006 5.77C3.50006 7.85 5.06006 8.99 7.41006 9.68L10.9201 13.19C10.5801 13.68 9.87006 14.1 8.50006 14.1C6.44006 14.1 5.63006 13.18 5.52006 12H3.32006C3.44006 14.19 5.08006 15.42 7.00006 15.83V18H10.0001V15.85C10.9601 15.67 11.8301 15.3 12.4601 14.73L14.6801 16.95L16.0901 15.54L1.47006 0.92Z"
                fill="inherit"
            />
        </svg>
    );
};

export default CrossedDollar;
