import * as S from "./style";
import { memo, useMemo, useCallback } from "react";
import { firstToUpper } from "utils/strings";
import { CompanyAgreementResponse } from "company/types";
import { AgreementType } from "types/common-enums";
import Table from "components/Table";
import Icon from "components/Icon";
import DynamicWalletAddressDisplay from "components/DynamicWalletAddress/DynamicWalletAddressDisplay";
import {
    CommonBlockchainNetworkResponse,
    GeneralTokenDetailsResponse,
} from "api";
import { useEns } from "contexts/EnsProvider";
import { useGetCompanyAgreements } from "company/hooks/useGetCompanyAgreements";
import EmptyTableMessage from "components/EmptyTableMessage";

interface AgreementToInvoiceProps {
    agreements: CompanyAgreementResponse[];
    networks: CommonBlockchainNetworkResponse[];
    tokens: GeneralTokenDetailsResponse[];
    itemSelected?: string;
    value?: CompanyAgreementResponse;
    setValue: React.Dispatch<
        React.SetStateAction<CompanyAgreementResponse | undefined>
    >;
    disabled?: boolean;
}

const tableHeading: Heading[] = [
    { label: `Wallet`, sortable: true },
    { label: `Email`, sortable: true },
    { label: `Token`, sortable: true },
    { label: `Network`, sortable: true },
];

const AgreementToInvoice = memo(
    ({
        agreements,
        networks,
        tokens,
        itemSelected,
        value,
        setValue,
        disabled = false,
    }: AgreementToInvoiceProps) => {
        const { getEnsRecord } = useEns();
        const { getCompanyAgreementsIsFetching } = useGetCompanyAgreements();

        const invoiceableAgreements: Tuple[] = useMemo(() => {
            if (!itemSelected) return [];
            return agreements
                .filter(
                    (agrmnt) =>
                        agrmnt.items.includes(itemSelected) &&
                        agrmnt.status !==
                            AgreementType[AgreementType.Canceled] &&
                        agrmnt.type !== `Static`
                )
                .map((agrmnt) => {
                    const network = networks.find(
                        ({ id }) => id === agrmnt.networkId
                    );
                    const networkName =
                        network?.name || agrmnt.networkId.toString();
                    const token = tokens.find(
                        ({ address }) => address === agrmnt.token
                    );

                    return {
                        id: agrmnt.id,
                        values: [
                            {
                                label: (
                                    <DynamicWalletAddressDisplay
                                        address={agrmnt.sender?.wallet}
                                        ensName={
                                            getEnsRecord(agrmnt.sender?.wallet)
                                                ?.name
                                        }
                                        networkId={network?.hexId}
                                        anchor={false}
                                    />
                                ),
                                value: agrmnt.sender?.wallet,
                                text: agrmnt.sender?.wallet,
                            },
                            {
                                label: agrmnt.sender?.email || ``,
                                value: agrmnt.sender?.email || ``,
                                text: agrmnt.sender?.email || ``,
                            },
                            {
                                label: token ? (
                                    <S.TokenMetadataContainer>
                                        <Icon
                                            src={token.logoUrl}
                                            alt={`${token.symbol} logo`}
                                            size="2.25rem"
                                            wrapped
                                            round
                                            tighten
                                        />
                                        <div>
                                            <b>{token.symbol}</b>
                                            <br />
                                            <small>{token.name}</small>
                                        </div>
                                    </S.TokenMetadataContainer>
                                ) : (
                                    agrmnt.token
                                ),
                                value: token?.symbol || agrmnt.token,
                                text: token?.symbol || agrmnt.token,
                            },
                            {
                                label: firstToUpper(networkName),
                                value: networkName,
                                text: networkName,
                            },
                        ],
                    };
                });
        }, [agreements, networks, tokens, itemSelected, getEnsRecord]);

        const handleChange = useCallback(
            (event: any) => {
                setValue(
                    agreements.find(({ id }) => id === event.target.value)
                );
            },
            [setValue, agreements]
        );

        return (
            <Table
                data={{
                    headings: tableHeading,
                    records: invoiceableAgreements,
                }}
                onChange={handleChange}
                defaultSort={{
                    column: 1,
                    isAscending: false,
                }}
                defaultValue={value?.id}
                selectable={true}
                disabled={disabled}
                refetching={getCompanyAgreementsIsFetching}
                ifNoRecords={
                    <EmptyTableMessage
                        title="No customer wallets"
                        description={
                            <>
                                There are no customer wallets associated with
                                item. Try selecting a new subscription or
                                one-time-payment.
                            </>
                        }
                    />
                }
            />
        );
    }
);

export default AgreementToInvoice;
