import styled from "styled-components";
import { properties as p } from "global-style";

export const InboundTreasuriesInfoPanel = styled.div`
    background: #f2efff;
    border: 1px solid black;
    border-radius: ${p.radiusLg};
    padding: 1rem;
    display: flex;
    gap: 1rem;
    align-items: center;
`;

export const TreasuriesWrapper = styled.ul`
    flex: 1;
    margin: 0;
`;
