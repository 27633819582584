import styled from "styled-components";
import { colorsDeprecated as c } from "global-style";
import colors from "theme/colors";

export const DropdownItem = styled.li`
    padding: 0.5rem 0;
    display: inline-block;
    width: 100%;
    padding: 0.25rem 1rem;
    font-size: 1rem;
    white-space: nowrap;
    &:hover {
        background: ${c.dropdownItemHover};
        cursor: pointer;
    }
    &:focus {
        background: ${c.dropdownItemHover};
        outline: 1px solid ${colors.primary};
    }
`;
