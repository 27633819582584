import * as S from "./style";
import { HTMLAttributes, ReactNode } from "react";

interface ModalProps extends HTMLAttributes<HTMLDialogElement> {
    children: ReactNode;
    gradient?: boolean;
    onClose?: () => void;
}

const Modal: React.FunctionComponent<ModalProps> = ({
    children,
    gradient = false,
    onClose,
    ...props
}) => {
    return (
        <S.Modal gradient={gradient} {...props}>
            <S.GlobalStyle />
            {children}
        </S.Modal>
    );
};

export default Modal;
