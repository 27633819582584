import styled from "styled-components";
import { sizes as s, colorsDeprecated as c } from "global-style";
import { BillDetails } from "company/components/InvoiceForm/BillDetails";
import Button from "components/Button";
import Hint from "components/Hint";
import Check from "components/icons/Check";
import Field from "components/Field";
import DynamicWalletAddressDisplay from "components/DynamicWalletAddress/DynamicWalletAddressDisplay";
import TagsList from "./TagsList";
import InvoiceNotes from "./InvoiceNotes";
import colors from "theme/colors";

export const DetailsLayout = styled.div`
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-areas: "wallet" "amount" "tags" "notes" "dates";
    grid-gap: 1rem;
    margin-block-start: 2rem;
    @media screen and (min-width: ${s.lg}) {
        grid-gap: 1.5rem;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "wallet amount" "tags notes" "dates dates";
    }
`;

export const DetailsIntro = styled.header`
    margin-block: 1.5rem;
`;

export const Amount = styled.div`
    grid-area: amount;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 0.5rem;
    align-items: end;

    /* // ! This is a hack to counter build-in label margin within Field 🙄 */
    & :last-child label {
        margin-bottom: 0;
    }
`;

export const UsdField = styled(Field)`
    margin-block: 0.421875rem;
`;

export const Tags = styled(TagsList)`
    grid-area: tags;
`;

export const Notes = styled(InvoiceNotes)`
    grid-area: notes;
`;

export const BillDetailsSC = styled(BillDetails)`
    grid-area: dates;
`;

export const TokenMetadataContainer = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: max-content;
    img {
        max-height: 100%;
    }
`;

export const Dates = styled.ol`
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const BillDateContainer = styled.li`
    display: grid;
    align-items: top;
    align-content: center;
    margin-block-end: 1em;
    grid-gap: 0.25rem;

    @media screen and (min-width: ${s.sm}) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 1.5em;
        margin-block-end: 0.5em;
    }
`;

export const WarnDates = styled(Hint)`
    /* This is used to override "Field > Hint" rule from Field */
    ${BillDateContainer} & {
        margin: 0;
        @media screen and (min-width: ${s.sm}) {
            margin-block-start: 0.7em;
        }
    }
`;

export const AddDates = styled.footer`
    display: grid;
    align-items: center;
    grid-template-columns: auto auto auto;
    justify-content: start;
    @media screen and (min-width: ${s.sm}) {
        grid-template-columns: auto auto auto auto;
    }
`;

export const AddDatesLabel = styled.p`
    margin-inline-end: 1rem;
    grid-column: span 3;
    @media screen and (min-width: ${s.sm}) {
        grid-column: unset;
    }
`;

export const InvoiceAndRemove = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 0.25rem;
    align-items: start;
    @media screen and (min-width: ${s.sm}) {
        grid-gap: 1.5em;
    }
`;

export const Remove = styled.div`
    @media screen and (min-width: ${s.sm}) {
        margin-block: 0.5rem;
        display: block;
    }
`;

export const Hide = styled.span`
    visibility: hidden;
`;

export const Period = styled(Button)`
    text-decoration: none;
    position: relative;

    &:has(+ &) {
        margin-inline-end: 0.75rem;
        @media screen and (min-width: ${s.sm}) {
            margin-inline-end: 1.25rem;
        }
    }

    &:has(+ &)::after {
        content: "\\00B7";
        pointer-events: none;
        display: inline-block;
        margin-inline: 0.25rem;
        position: absolute;
        left: 100%;
        @media screen and (min-width: ${s.sm}) {
            margin-inline: 0.5rem;
        }
    }
`;

export const SummaryRow = styled.span<{ isNextStep?: boolean }>`
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    ${({ isNextStep }) => isNextStep && `color: ${colors.text};`}
`;

export const CheckDone = styled(Check)`
    line-height: 1;
    fill: ${c.primaryLight};
    height: 1.25rem;
    width: 1.25rem;
    flex-shrink: 0;
`;

export const SummaryValue = styled.em`
    color: ${c.primaryLight};
`;

export const CertifiedSigner = styled(DynamicWalletAddressDisplay)`
    font-weight: 700;
`;

export const EntityItem = styled.div`
    display: grid;
    gap: 1.5rem;
`;

export const List = styled.ol`
    padding-inline-start: 0;
    counter-reset: list-item 0;
    list-style: none;
    display: grid;
    gap: 1rem;
    margin: 1rem 0 0;
    @media screen and (min-width: ${s.sm}) {
        grid-column: span 2;
    }
`;

export const ListItem = styled.li`
    display: flex;
    gap: 1rem;
    counter-increment: list-item;

    small {
        line-height: normal;
        display: inline-block;
    }

    &::before {
        content: counter(list-item);
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        text-align: center;
        border: 2px solid ${c.lighterOnSurface};
        color: ${c.lightOnSurface};
        border-radius: 50%;
        font-size: 0.9rem;
        margin-block-start: 0.25rem;
        flex-shrink: 0;
    }
    &[data-complete="true"]::before {
        content: "";
        background-color: ${colors.success};
        mask-image: ${`url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 8'%3e%3cpath d='M9.002 0 3.404 5.61 1.2 3.417 0 4.616l3.404 3.391L10.2 1.211 9.002 0Z' /%3e%3c/svg%3e")`};
        mask-position: center center;
        mask-repeat: no-repeat;
        mask-size: 70% auto;
        border-color: ${colors.success};
    }
`;

export const SubmitButton = styled(Button)`
    display: block;
    margin-block-start: 1rem;
`;
