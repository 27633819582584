import * as S from "./styles";
import {
    Ref,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";
import EntityUrlField, {
    EntityUrlFieldRef,
} from "company/components/entities/EntityUrlField";
import EntityReplyToField, {
    EntityReplyToFieldRef,
} from "company/components/entities/EntityReplyToField";
import EntityCompanyNameField, {
    EntityCompanyNameFieldRef,
} from "company/components/entities/EntityCompanyNameField";
import { SelfServerEntityDetailsOnly } from "company/routes/SelfServe";
import Button from "components/Button";

interface SelfServeNewEntityConfigFormProps {
    onSubmit?: (
        selfServeNewEntityConfig: SelfServerEntityDetailsOnly,
        isValid: boolean
    ) => void;
    onChange?: () => void;
    selfServeNewEntityConfigValid: boolean;
}

export type SelfServeNewEntityConfigFormRef = {
    validate: () => boolean;
    selfServeNewEntityConfig: SelfServerEntityDetailsOnly;
};

function SelfServeNewEntityConfigForm(
    {
        onSubmit,
        onChange,
        selfServeNewEntityConfigValid,
    }: SelfServeNewEntityConfigFormProps,
    ref: Ref<SelfServeNewEntityConfigFormRef>
) {
    const [selfServeNewEntityConfig, setSelfServeNewEntityConfig] =
        useState<SelfServerEntityDetailsOnly>({
            companyName: "",
            url: "",
            replyTo: "",
        });

    const entityCompanyNameFieldRef = useRef<EntityCompanyNameFieldRef>(null);
    const entityUrlFieldRef = useRef<EntityUrlFieldRef>(null);
    const entityReplyToFieldRef = useRef<EntityReplyToFieldRef>(null);

    const refs = useMemo(() => {
        return [
            entityCompanyNameFieldRef,
            entityUrlFieldRef,
            entityReplyToFieldRef,
        ];
    }, []);

    const validate = useCallback((): boolean => {
        return refs.every((ref) => ref.current && ref.current.validate());
    }, [refs]);

    const updateSelfServeNewEntityConfig = useCallback(() => {
        if (!entityCompanyNameFieldRef.current) return;
        if (!entityUrlFieldRef.current) return;
        if (!entityReplyToFieldRef.current) return;

        const selfServeNewEntityConfig: SelfServerEntityDetailsOnly = {
            companyName: entityCompanyNameFieldRef.current.value,
            url: entityUrlFieldRef.current.value,
            replyTo: entityReplyToFieldRef.current.value,
        };

        setSelfServeNewEntityConfig((newEntity) => {
            newEntity.companyName = selfServeNewEntityConfig.companyName;
            newEntity.url = selfServeNewEntityConfig.url;
            newEntity.replyTo = selfServeNewEntityConfig.replyTo;
            return newEntity;
        });

        if (onChange) onChange();
    }, [onChange]);

    useImperativeHandle(
        ref,
        () => ({
            validate,
            selfServeNewEntityConfig,
        }),
        [validate, selfServeNewEntityConfig]
    );

    const handleNewEntityConfigSubmit = useCallback(() => {
        if (onSubmit) onSubmit(selfServeNewEntityConfig, validate());
    }, [onSubmit, selfServeNewEntityConfig, validate]);

    return (
        <S.SelfServeNewEntityConfigForm preventEnterKeySubmission>
            <EntityCompanyNameField
                ref={entityCompanyNameFieldRef}
                onChange={() => {
                    updateSelfServeNewEntityConfig();
                    if (entityCompanyNameFieldRef?.current)
                        entityCompanyNameFieldRef.current.validate();
                }}
            />
            <EntityUrlField
                ref={entityUrlFieldRef}
                onChange={() => {
                    updateSelfServeNewEntityConfig();
                    if (entityUrlFieldRef?.current)
                        entityUrlFieldRef.current.validate();
                }}
            />
            <EntityReplyToField
                ref={entityReplyToFieldRef}
                onChange={() => {
                    updateSelfServeNewEntityConfig();
                    if (entityReplyToFieldRef?.current)
                        entityReplyToFieldRef.current.validate();
                }}
            />
            {!selfServeNewEntityConfigValid && (
                <Button onClick={handleNewEntityConfigSubmit}>Continue</Button>
            )}
        </S.SelfServeNewEntityConfigForm>
    );
}

export default forwardRef(SelfServeNewEntityConfigForm);
