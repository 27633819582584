import * as S from "company/routes/Transactions/style";
import CancelTransactionForm from "company/components/CancelTransactionForm";
import { Attention } from "company/routes/Transactions/style";
import {
    Company,
    CompanyAgreementResponse,
    SortableCompanyTransactionColumns,
    TransactionTableRow,
    TransactionValues,
    TransferSource,
} from "company/types";
import BalanceAndAllowanceCell from "components/BalanceAndAllowanceCell";
import Dropdown from "components/Dropdown";
import DropdownItem from "components/DropdownItem";
import DynamicAddressDisplay from "components/DynamicAddressDisplay";
import DynamicWalletAddressDisplay from "components/DynamicWalletAddress/DynamicWalletAddressDisplay";
import VerticalDots from "components/icons/VerticalDots";
import { useUser, UserRole } from "context/User";
import { useEns } from "contexts/EnsProvider";
import { formatUnits } from "ethers";
import { useMemo, useCallback } from "react";
import { TransferStatus } from "types/common-enums";
import { getDateTimeFromSeconds } from "utils/datetime";
import { toDollar, toCoin } from "utils/financial";
import { firstToUpper } from "utils/strings";
import { BlockExplorerEntityType } from "utils/urls";
import Tooltip from "components/Tooltip";
import Anchor from "components/Anchor";
import Badge from "components/Badge";
import { useModal } from "context/ModalProvider";
import { useNavigate } from "react-router-dom";
import {
    GeneralTokenDetailsResponse,
    CommonBlockchainNetworkResponse,
} from "api";
import { PaymentPlatformUrls } from "company/utils/entities";

type SortableKeys = CheckSubset<
    keyof typeof SortableCompanyTransactionColumns,
    keyof typeof TransactionValues
>;

const uniqueWalletsFromTx = (
    lookupArray: string[],
    txRow: TransactionTableRow
): string[] => {
    // Check if sender and receiver are in the ENS lookup array (and not identical)
    const sender = txRow?.senderAddress.value.toLowerCase();
    const receiver = txRow?.receiverAddress.value.toLowerCase();
    return [sender, ...(sender !== receiver ? [receiver] : [])].filter(
        (addr) => !lookupArray.some((address: string) => address === addr)
    );
};

interface UseTransactionFormatProps {
    entities: Company.Entity[];
    agreements: CompanyAgreementResponse[];
    tokens: GeneralTokenDetailsResponse[];
    networks: CommonBlockchainNetworkResponse[];
}

export const useTransactionFormat = ({
    entities,
    agreements,
    tokens,
    networks,
}: UseTransactionFormatProps) => {
    const navigate = useNavigate();
    const { hasRole } = useUser();
    const canManage = hasRole(UserRole.COMPANY);
    const { lookupEnsRecords, getEnsRecord } = useEns();

    const singleEntity = entities.length === 1;

    const { openModal, closeModal } = useModal();

    const handleCreateItemRequestFormModal = useCallback(
        (transferId: string) => {
            openModal(
                <CancelTransactionForm
                    transferId={transferId}
                    onClose={closeModal}
                />,
                `Cancel Transaction`
            );
        },
        [closeModal, openModal]
    );

    const transactionsTableHeadings: RowHeading<TransactionTableRow>[] =
        useMemo(() => {
            const tableCols: Omit<
                RowHeading<TransactionTableRow>,
                "sortable"
            >[] = [
                { label: "ID", field: TransactionValues.id },
                {
                    label: "Date due",
                    field: TransactionValues.dateDue,
                },
                {
                    label: "Processed",
                    field: TransactionValues.datePaid,
                },
                { label: "Draft", field: TransactionValues.draftStatus },
                { label: "Invoiced", field: TransactionValues.invoiced },
                {
                    label: "Billed in USD",
                    field: TransactionValues.billedInUsd,
                },
                { label: "Invoiced", field: TransactionValues.invoicedAmt },
                { label: "Token", field: TransactionValues.invoicedToken },
                { label: "Received", field: TransactionValues.received },
                { label: "Received", field: TransactionValues.receivedAmt },
                { label: "Tx Hash", field: TransactionValues.txHash },
                { label: "Sender", field: TransactionValues.sender },
                {
                    label: "Sender Address",
                    field: TransactionValues.senderAddress,
                },
                { label: "Sender Email", field: TransactionValues.senderEmail },
                { label: "Receiver", field: TransactionValues.receiver },
                {
                    label: "Receiver Address",
                    field: TransactionValues.receiverAddress,
                },
                {
                    label: "Receiver Email",
                    field: TransactionValues.receiverEmail,
                },
                { label: "Network", field: TransactionValues.network },
                { label: "Network ID", field: TransactionValues.networkId },
                { label: `Item`, field: TransactionValues.itemName },
                {
                    label: "Invoice #",
                    field: TransactionValues.invoice,
                },
                {
                    label: "Entity",
                    field: TransactionValues.entity,
                    hide: singleEntity,
                },
                { label: `Status`, field: TransactionValues.status },
                { label: "Tags", field: TransactionValues.tags },
                {
                    label: "Manage",
                    field: TransactionValues.details,
                    style: { textAlign: "center" },
                    hide: !canManage,
                },
                { label: "Notes", field: TransactionValues.notes },
                {
                    label: "Allowance / Balance",
                    field: TransactionValues.allowanceBalance,
                },
            ];

            return tableCols.map((heading) => ({
                ...heading,
                sortable:
                    (heading.field as SortableKeys) in
                    SortableCompanyTransactionColumns,
            }));
        }, [canManage, singleEntity]);

    const formatTransaction = useCallback(
        (transaction: Company.Transaction): TransactionTableRow | undefined => {
            const agreement = agreements.find(
                (a) => a.id === transaction.agreementId
            );
            const token =
                agreement &&
                tokens.find(
                    (t) =>
                        t.address.toLocaleLowerCase() ===
                        agreement.token.toLocaleLowerCase()
                );
            const network =
                agreement && networks.find((n) => n.id === agreement.networkId);
            const entity =
                agreement &&
                entities.find((e) => e.entityId === agreement.entity);

            if (!agreement || !token || !entity) {
                return;
                // [ ] Notification: Some records may not be displayed due to insufficient data
            }

            const { symbol, decimals } = token;
            const {
                usd,
                billDate,
                amount,
                status,
                invoiceId,
                tags,
                transferId,
                notes,
                elements,
                receiver,
                sourceId,
            } = transaction;
            const { sender, networkId } = agreement;

            const allItemNames = elements
                .map(({ name }) => firstToUpper(name))
                .join(", ");

            // Payment details
            const {
                paidAmount = `0`,
                processedAt = 0,
                transactionHash = null,
            } = transaction.payment ?? {};

            // Paid date
            const {
                date: atDate,
                time: atTime,
                zone: atZone,
            } = getDateTimeFromSeconds(processedAt, true);

            // Billed date
            const {
                date: forDate,
                time: forTime,
                zone: forZone,
            } = getDateTimeFromSeconds(billDate, true);

            // If this invoice is in USD, convert to dollar (2 decimal), otherwise, toCoin
            const amountInvoicedLabel = usd ? (
                <>
                    {toDollar(amount)}
                    {symbol ? (
                        <>
                            <br />
                            in {symbol}
                        </>
                    ) : (
                        ``
                    )}
                </>
            ) : decimals === 0 && !symbol ? (
                ``
            ) : (
                <>
                    {toCoin(Number(formatUnits(String(amount), decimals)))}
                    <br />
                    {symbol}
                </>
            );
            const amountInvoicedText = usd
                ? `${toDollar(amount)}${symbol ? ` in ${symbol}` : ``}`
                : decimals === 0 && !symbol
                ? ``
                : `${toCoin(
                      Number(formatUnits(String(amount), decimals))
                  )} ${symbol}`;

            const dateDueLabel = `${forDate} ${forTime} ${forZone}`;
            const canCancel = canManage && status === TransferStatus.Scheduled;

            const senderNameQueued =
                sender && getEnsRecord(sender.wallet)?.name;

            const receiverNameQueued =
                receiver && getEnsRecord(receiver.wallet)?.name;

            const amountReceived =
                status === TransferStatus.Failed
                    ? `Not fulfilled`
                    : paidAmount
                    ? toCoin(Number(formatUnits(String(paidAmount), decimals)))
                    : ``;

            const amountInvoiced = usd
                ? toDollar(amount)
                : toCoin(Number(formatUnits(String(amount), decimals)));

            const paymentPlatformUrls = new PaymentPlatformUrls(
                entity.paymentPlatformProvider,
                entity.externalSite
            );

            const renderInvoiceId = (invoiceId: string | null) => {
                if (!invoiceId) return "-";

                const invoiceSnippet =
                    invoiceId.length > 8 ? (
                        <Tooltip title={invoiceId} placement="left">
                            <span>{invoiceId.slice(0, 8).trim()}&hellip;</span>
                        </Tooltip>
                    ) : (
                        invoiceId
                    );

                const isPaymentPlatformSource = [
                    TransferSource.Stripe,
                    TransferSource.Chargebee,
                ].includes(sourceId);

                return isPaymentPlatformSource ? (
                    <Anchor
                        href={paymentPlatformUrls.invoiceUrl(invoiceId)}
                        target="_blank"
                    >
                        {invoiceSnippet}
                    </Anchor>
                ) : (
                    invoiceSnippet
                );
            };

            const invoiceIdLabel = renderInvoiceId(invoiceId);

            return {
                id: {
                    label: transferId,
                    value: transferId,
                    text: transferId,
                },
                dateDue: {
                    label: (
                        <S.SplitCol>
                            <span>
                                {forDate}
                                <br />
                                <S.NoWrap>
                                    {forTime} {forZone}
                                </S.NoWrap>
                            </span>
                            {status === TransferStatus.Draft ? (
                                <Badge variant="purple">Draft</Badge>
                            ) : (
                                ``
                            )}
                        </S.SplitCol>
                    ),
                    value: billDate,
                    text: dateDueLabel,
                },
                draftStatus: {
                    label: status === TransferStatus.Draft ? `Yes` : ``,
                    value: status === TransferStatus.Draft ? `Yes` : ``,
                    text: status === TransferStatus.Draft ? `Yes` : ``,
                },
                datePaid: {
                    label: (
                        <>
                            {atDate}
                            <br />
                            <S.NoWrap>
                                {atTime} {atZone}
                            </S.NoWrap>
                        </>
                    ),
                    value: processedAt,
                    text: `${atDate} ${atTime} ${atZone}`,
                },
                invoiced: {
                    label: amountInvoicedLabel,
                    value: amount,
                    text: amountInvoicedText,
                    style: { textAlign: `right` },
                },
                billedInUsd: {
                    label: usd ? `Yes` : ``,
                    value: usd ? `Yes` : ``,
                    text: usd ? `Yes` : ``,
                },
                invoicedAmt: {
                    label: amountInvoiced,
                    value: amountInvoiced,
                    text: amountInvoiced,
                },
                invoicedToken: {
                    label: symbol,
                    value: symbol,
                    text: symbol,
                },
                received: {
                    label: (
                        <>
                            {status === TransferStatus.Failed ? (
                                <NotFullfilled />
                            ) : (
                                <>
                                    {paidAmount
                                        ? `${toCoin(
                                              Number(
                                                  formatUnits(
                                                      String(paidAmount),
                                                      decimals
                                                  )
                                              )
                                          )} ${symbol}`
                                        : `-`}{" "}
                                </>
                            )}
                            {transactionHash && network ? (
                                <>
                                    <br />
                                    <DynamicAddressDisplay
                                        address={transactionHash}
                                        networkId={network.hexId}
                                        type={
                                            BlockExplorerEntityType.Transaction
                                        }
                                        shorten
                                        icon
                                        iconFill="currentColor"
                                    >
                                        Tx
                                    </DynamicAddressDisplay>
                                </>
                            ) : (
                                ``
                            )}
                        </>
                    ),
                    value: paidAmount ?? 0,
                    text: `${
                        paidAmount
                            ? toCoin(
                                  Number(
                                      formatUnits(String(paidAmount), decimals)
                                  )
                              )
                            : `-`
                    } ${symbol}${
                        transactionHash ? ` (${transactionHash})` : ``
                    }`,
                    style: {
                        whiteSpace: `nowrap`,
                        textAlign: `right`,
                    },
                },
                receivedAmt: {
                    label: amountReceived,
                    value: amountReceived,
                    text: amountReceived,
                },
                txHash: {
                    label: transactionHash || ``,
                    value: transactionHash || ``,
                    text: transactionHash || ``,
                },
                sender: {
                    label: sender ? (
                        <AddressAndEmail
                            wallet={sender.wallet}
                            ens={senderNameQueued}
                            network={network?.hexId}
                            email={sender.email}
                        />
                    ) : (
                        ``
                    ),
                    value: sender?.wallet ?? ``,
                    text: sender
                        ? `${sender.wallet}${
                              sender.email ? ` (${sender.email})` : ``
                          }`
                        : ``,
                    style: { expand: true },
                },
                senderAddress: {
                    label: sender?.wallet || ``,
                    value: sender?.wallet || ``,
                    text: sender?.wallet || ``,
                },
                senderEmail: {
                    label: sender?.email || ``,
                    value: sender?.email || ``,
                    text: sender?.email || ``,
                },
                receiver: {
                    label:
                        receiver && receiverNameQueued ? (
                            <AddressAndEmail
                                wallet={receiver.wallet}
                                ens={receiverNameQueued}
                                network={network?.hexId}
                                email={receiver.email}
                            />
                        ) : (
                            ``
                        ),
                    value: receiver?.wallet ?? ``,
                    text: receiver
                        ? `${receiver.wallet}${
                              receiver.email ? ` (${receiver.email})` : ``
                          }`
                        : ``,
                    style: { expand: true },
                },
                receiverAddress: {
                    label: receiver?.wallet || ``,
                    value: receiver?.wallet || ``,
                    text: receiver?.wallet || ``,
                },
                receiverEmail: {
                    label: receiver?.email || ``,
                    value: receiver?.email || ``,
                    text: receiver?.email || ``,
                },
                network: {
                    label: firstToUpper(network?.name || ""),
                    value: firstToUpper(network?.name || ""),
                    text: firstToUpper(network?.name || ""),
                    style: { textTransform: `capitalize` },
                },
                networkId: {
                    label: networkId,
                    value: networkId,
                    text: `${networkId}`,
                },
                itemName: {
                    label: allItemNames,
                    value: allItemNames,
                    text: allItemNames,
                    style: { expand: true, textTransform: `capitalize` },
                },
                invoice: {
                    label: invoiceIdLabel,
                    value: invoiceId ?? ``,
                    text: `${invoiceId ?? `-`}`,
                },
                entity: {
                    label: entity.name,
                    value: entity.name,
                    text: entity.name,
                    style: { expand: true },
                },
                status: {
                    label: status || ``,
                    value: status || ``,
                    text: status || ``,
                },
                tags: {
                    label: tags.map((tag) => tag.content).join(", "),
                    value: tags.map((tag) => tag.content).join(", "),
                    text: tags.map((tag) => tag.content).join(", "),
                },
                details: {
                    label: transferId && (
                        <>
                            <Dropdown
                                anchorEl={
                                    <a
                                        href="#menu"
                                        onClick={(event) =>
                                            event.preventDefault()
                                        }
                                    >
                                        <VerticalDots
                                            width="1rem"
                                            height="1rem"
                                            fill="#888"
                                        />
                                    </a>
                                }
                            >
                                <DropdownItem
                                    onClick={() =>
                                        navigate(
                                            `/transactions/details/${transferId}`
                                        )
                                    }
                                >
                                    Manage
                                </DropdownItem>
                                {canCancel && (
                                    <DropdownItem
                                        onClick={() =>
                                            handleCreateItemRequestFormModal(
                                                transferId
                                            )
                                        }
                                    >
                                        Cancel
                                    </DropdownItem>
                                )}
                            </Dropdown>
                        </>
                    ),
                    value: "manage",
                    text: "manage",
                    style: { textAlign: `center` },
                },
                notes: {
                    label: notes || ``,
                    value: notes || ``,
                    text: notes || ``,
                },

                allowanceBalance: {
                    label: (
                        <BalanceAndAllowanceCell
                            token={token}
                            walletAddress={sender.wallet}
                        />
                    ),
                    value: "Get Data",
                    text: "Get Data",
                },
            };
        },
        [
            networks,
            tokens,
            entities,
            agreements,
            canManage,
            getEnsRecord,
            navigate,
            handleCreateItemRequestFormModal,
        ]
    );

    const getFormatTransactions = useCallback(
        (transactions: Company.Transaction[]) => {
            const { formattedTx, uniqueWallets } = transactions.reduce(
                (
                    tx: {
                        formattedTx: TransactionTableRow[];
                        uniqueWallets: string[];
                    },
                    row: Company.Transaction
                ) => {
                    const txRow = formatTransaction(row);
                    if (!txRow) return tx;

                    return {
                        formattedTx: [...tx.formattedTx, txRow],
                        uniqueWallets: tx.uniqueWallets.concat(
                            uniqueWalletsFromTx(tx.uniqueWallets, txRow)
                        ),
                    };
                },
                {
                    formattedTx: [],
                    uniqueWallets: [],
                }
            );

            // Send a batch ENS lookup for all unique wallets
            lookupEnsRecords(uniqueWallets);
            return formattedTx;
        },
        [formatTransaction, lookupEnsRecords]
    );

    // This should just return both heading and records - is there any reason to split them up?
    const getTransactionsTableHeadings = useCallback(
        (transactionsValues: TransactionValues[]) => {
            // Creating a lookup for the order
            const orderLookup = transactionsValues.reduce<
                Record<string, number>
            >((acc, field, index) => {
                acc[field] = index;
                return acc;
            }, {});

            return transactionsTableHeadings
                .filter(
                    (heading) =>
                        heading.field !== "id" &&
                        !heading.hide &&
                        transactionsValues.includes(heading.field)
                )
                .sort((a, b) => orderLookup[a.field] - orderLookup[b.field]);
        },
        [transactionsTableHeadings]
    );

    return { getFormatTransactions, getTransactionsTableHeadings };
};

const NotFullfilled = () => (
    <Tooltip
        title={
            <>
                While this transaction <em>was</em>
                {` `}
                processed on-chain, the invoice{` `}
                <em>was not</em>
                {` `}
                fulfilled. Please send another request with a new invoice# if
                you would like this transaction to be processed.{` `}
                <Anchor
                    href={import.meta.env.VITE_LOOP_DOCS_FAILED_TX}
                    inheritColor={true}
                >
                    Learn more
                </Anchor>
                .
            </>
        }
        placement="top"
    >
        <Attention>Not fulfilled</Attention>
    </Tooltip>
);

interface AddressAndEmailProps {
    wallet: string;
    ens: Promise<string | null> | undefined;
    network: string | undefined;
    email: string | null;
}
const AddressAndEmail = ({
    wallet,
    ens,
    network,
    email,
}: AddressAndEmailProps) => (
    <>
        <DynamicWalletAddressDisplay
            address={wallet}
            ensName={ens}
            networkId={network}
            shorten
        />
        {email && (
            <>
                <br />
                <Anchor href={`mailto:${email}`} inheritColor={true}>
                    {email}
                </Anchor>
            </>
        )}
    </>
);
