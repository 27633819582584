import styled from "styled-components";
import { sizes as s } from "global-style";
import colors from "theme/colors";
import Title from "components/Title";
import Spinner from "components/Spinner";
import { fontSizes } from "theme/typography";
import Anchor from "components/Anchor";

export const TransactionSenderAndReceiver = styled.section`
    display: flex;
    gap: 2rem;
    align-items: start;
    flex-direction: column;
    @media screen and (min-width: ${s.md}) {
        flex-direction: row;
    }
`;
export const Header = styled(Title)`
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: ${colors.text};
`;

export const WalletContainer = styled.div`
    width: 100%;
    flex: 1;
`;

export const ArrowContainer = styled.div`
    padding-top: 3.5rem;
    display: none;
    @media screen and (min-width: ${s.md}) {
        display: inline-block;
    }
`;

export const AllowanceAndBalanceContainer = styled.div`
    margin-top: 1rem;
`;

export const Row = styled.div`
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 2fr;
    justify-content: start;
    margin-top: 0.25rem;
`;

export const AmountInToken = styled.span`
    margin-right: 0.5rem;
    white-space: nowrap;
`;

export const AmountInUsd = styled.span`
    font-size: ${fontSizes.sm};
    color: ${colors.lightText};
    white-space: nowrap;
`;

export const WalletDetails = styled.div`
    padding: 1rem 1.25rem;
    border: 1px solid ${colors.borderMedium};
    border-radius: 0.5rem;
    word-break: break-all;
`;

export const Loading = styled(Spinner)`
    height: 1.25rem;
    width: 1.25rem;
`;

export const Edit = styled(Anchor)`
    font-weight: 500;
    margin-inline-start: 0.5rem;
`;
