import React from "react";

const Checkmark = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            {...props}
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            viewBox="0 0 11 11"
            xmlns="http://www.w3.org/2000/svg"
        >
            {name && <title>{name}</title>}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.25.25a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm0 9a4 4 0 1 1 0-8.01 4 4 0 0 1 0 8Zm-1-2.91 3.3-3.3.7.71-4 4-2-2 .7-.7 1.3 1.29Z"
                fill="inherit"
            />
        </svg>
    );
};

export default Checkmark;
