import styled from "styled-components";
import { colorsDeprecated as c, properties as p } from "global-style";
import colors from "theme/colors";

export const OptionList = styled.fieldset`
    border: 0;
    margin: 0;
    padding: 0;
    display: flex;
    & > * {
        flex-grow: ${({ grow }) => (grow ? `1` : `0`)};
    }
`;

export const Option = styled.label`
    span {
        display: block;
        padding: 0.5em 1em;
        border: 1px solid ${c.border};
        // border-width: 1px 0 1px 1px;
        margin-right: 6px;
        text-align: center;
        border-radius: inherit;
        cursor: pointer;
        border-radius: ${p.radiusLg};
        font-size: 0.8em;
        color: ${colors.primary};
        border-color: ${colors.primary};
        &:hover {
            color: ${c.onPrimaryHover};
            border-color: ${c.onPrimaryHover};
        }
    }
    //&:has(> input[checked]) + * span {
    //  border-left-color: ${colors.primary};
    //}
    input {
        //display: none;
        appearance: none;
        margin: 0;
        opacity: 0;
        display: inline;
        // Ensure hiding this is still accessible
    }
    input:focus {
        outline: none;
        border: none;
    }
    input:focus + span {
        position: relative;
        outline-width: 1px;
        outline-style: solid;
        outline-offset: -2px;
        outline-color: ${colors.textOnPrimary};
    }
    input[checked] + span {
        position: relative;
        background-color: ${colors.primary};
        color: ${colors.textOnPrimary};
        border-color: ${colors.primary};
    }
`;
