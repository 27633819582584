import { Company, ItemSourceType } from "company/types";
import SelectMultiple, {
    SelectMultipleGroupedOptionProps,
    SelectMultipleOptionProps,
} from "components/SelectMultiple";
import { Ref } from "react";
import SelectMultipleItemOption from "../SelectMultipleItemOption";
import { itemAmountForDisplay, itemFrequencyForDisplay } from "utils/items";

export interface SelectMultipleItemsProps {
    items: Company.Item[];
    itemSourceId: Company.Item["sourceId"];
    disabled?: boolean;
    onChange?: (itemIds: Company.Item["id"][]) => void;
}

export type SelectMultipleItemsRef = {};

function SelectMultipleItems(
    {
        disabled = false,
        onChange,
        items,
        itemSourceId,
    }: SelectMultipleItemsProps,
    ref: Ref<SelectMultipleItemsRef>
) {
    // Fun time 2: Item selector
    const getItemOption = (item: Company.Item): SelectMultipleOptionProps => {
        // TODO: Could prevent selected items that won't work with checkout
        const isDisabled = false;
        return {
            label: <SelectMultipleItemOption item={item} />,
            value: item.id,
            isDisabled,
            labelForSearch: [
                item.name,
                itemAmountForDisplay(item.amount),
                itemFrequencyForDisplay(item.frequency),
            ].join(" "),
        };
    };

    // For Stripe & Chargebee, we don't distinct between manual & automatic
    const isStripe = itemSourceId === ItemSourceType.Stripe;
    const isChargebee = itemSourceId === ItemSourceType.Chargebee;

    const selectProps = {
        name: "tokenAddresses",
        placeholder: "Add items",
        disabled: disabled,
        values: [], // We want to use this as a "dropdown", ie: no selected value
        closeMenuOnSelect: false,
        onChange: onChange,
    };

    // Simple select, no "group"
    if (isStripe || isChargebee) {
        return (
            <SelectMultiple
                options={items.map(getItemOption)}
                {...selectProps}
            />
        );
    }

    // Select with grouped options
    const itemsGroupedOptions: SelectMultipleGroupedOptionProps[] = [];
    const autoInvoiceItems = items.filter((item) => item.autoInvoice);
    const manualItems = items.filter((item) => !item.autoInvoice);

    const autoInvoiceItemsOptions = autoInvoiceItems.map(getItemOption);
    const manualItemsItemsOptions = manualItems.map(getItemOption);

    itemsGroupedOptions.push({
        label: "Automatic billing",
        options: autoInvoiceItemsOptions,
    });
    itemsGroupedOptions.push({
        label: "Manual billing",
        options: manualItemsItemsOptions,
    });

    return (
        <SelectMultiple groupedOptions={itemsGroupedOptions} {...selectProps} />
    );
}

export default SelectMultipleItems;
