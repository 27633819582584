import React, {
    createContext,
    useContext,
    ReactNode,
    useState,
    useEffect,
    Dispatch,
    SetStateAction,
    useCallback,
} from "react";
import SidePopover from "components/SidePopover";

interface ModalContextType {
    modalContent: ReactNode | null;
    openModal: (
        content: ReactNode,
        title?: ReactNode,
        description?: ReactNode,
        onClose?: () => void
    ) => void;
    closeModal: () => void;
    setCanCloseModal: Dispatch<SetStateAction<boolean>>;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

interface ModalProviderProps {
    children: ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
    const [modalContent, setModalContent] = useState<ReactNode | null>(null);
    const [onCloseCallback, setOnCloseCallback] =
        useState<(() => void) | null>(null);

    const [canClose, setCanCloseModal] = useState<boolean>(true);
    const [title, setTitle] = useState<ReactNode>();
    const [description, setDescription] = useState<ReactNode>();

    const openModal = useCallback(
        (
            content: ReactNode,
            title?: ReactNode,
            description?: ReactNode,
            onClose?: () => void
        ) => {
            setModalContent(content);

            if (title) setTitle(title);
            else setTitle(null);

            if (description) setDescription(description);
            else setDescription(null);

            if (onClose) setOnCloseCallback(() => onClose);
        },
        []
    );

    const closeModal = useCallback(() => {
        if (!canClose) return;

        setModalContent(null);
        if (onCloseCallback) {
            onCloseCallback();
            setOnCloseCallback(null);
        }
    }, [canClose, onCloseCallback]);

    useEffect(() => {
        if (document.body) {
            if (modalContent) {
                document.body.classList.add("no-scroll");
            } else {
                document.body.classList.remove("no-scroll");
            }
        }
    }, [modalContent]);

    return (
        <ModalContext.Provider
            value={{ modalContent, openModal, closeModal, setCanCloseModal }}
        >
            {children}
            {modalContent && (
                <SidePopover
                    onClose={closeModal}
                    title={title}
                    description={description}
                >
                    {modalContent}
                </SidePopover>
            )}
        </ModalContext.Provider>
    );
};

export const useModal = (): ModalContextType => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error("useModal must be used within a ModalProvider");
    }
    return context;
};
