import { useState } from "react";
import { ItemCategoryType } from "types/common-enums";
import Select from "components/Select";
import Button, { ButtonVariants } from "components/Button";
import * as S from "./styles";
import AllowanceNewWallet from "company/components/AllowanceNewWallet";
import { useModal } from "context/ModalProvider";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import { useGetCompanyItems } from "company/hooks/useGetCompanyItems";

interface AuthorizedNewWalletFormProps {}

const AuthorizedNewWalletForm: React.FunctionComponent<AuthorizedNewWalletFormProps> =
    () => {
        const {
            config: { entities },
        } = useGetCompanyConfig();
        const { items } = useGetCompanyItems();

        const { openModal, setCanCloseModal } = useModal();

        const outboundPaymentItems = items.filter(
            (item) =>
                item.type === ItemCategoryType.OutboundPayment && item.active
        );

        const showSelect = outboundPaymentItems.length > 1;
        const defaultItemId = outboundPaymentItems[0].id;
        const [itemId, setItemId] = useState<string>(defaultItemId);

        const selectedItem = outboundPaymentItems.find(
            ({ id }) => itemId === id
        );

        const selectedEntity = entities.find(
            (entity) => entity.entityId === selectedItem?.entityId
        );

        // Form: Item select options
        const itemOptions = outboundPaymentItems.map((item) => {
            const showType = item.name !== item.type;
            return {
                label: showType ? `${item.name} - (${item.type})` : item.name,
                value: item.id,
            };
        });

        if (
            outboundPaymentItems.length === 0 ||
            !selectedItem ||
            !selectedEntity
        ) {
            return <></>;
        }

        const handleOpenModal = () => {
            openModal(
                <AllowanceNewWallet
                    entity={selectedEntity}
                    item={selectedItem}
                    setCanCloseModal={setCanCloseModal}
                />,
                "Add a new wallet",
                "Authorize a new wallet to spend from"
            );
        };

        return (
            <S.AuthorizedNewWalletForm>
                {showSelect && (
                    <Select
                        name="itemId"
                        value={selectedItem.id}
                        onChange={(event) => setItemId(event.target.value)}
                        options={itemOptions}
                    />
                )}
                <Button
                    onClick={handleOpenModal}
                    variant={ButtonVariants.PrimaryOutlined}
                >
                    Add wallet / Manage allowance
                </Button>
            </S.AuthorizedNewWalletForm>
        );
    };

export default AuthorizedNewWalletForm;
