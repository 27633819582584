const fontFamilyPoppins = "'Poppins'";
const fontFamilyOpenSans = "'Open Sans'";
const fontFamilyRoboto = "'Roboto Mono'";

export const lineHeight = "1.25rem";

export const fontFamily = {
    text: `${fontFamilyOpenSans}, system-ui, Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;`,
    title: `${fontFamilyPoppins}, system-ui, Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;`,
    monospace: `${fontFamilyRoboto}, monospace;`,
};

export const baseFontSize = "16px";

export const fontSizes = {
    xs: `0.75rem`, // 12px
    sm: `0.875rem`, // 14px
    md: `1rem`, // 16px
    ml: `1.125rem`, // 18px (medium large) 🙄
    lg: `1.25rem`, // 20px
    xl: `1.5rem`, // 24px
    xxl: `2rem`, // 32px
};

export const lineHeights = {
    md: `1rem`, // 16px
    lg: `1.25rem`, // 20px
    xl: `1.5rem`, // 24px
};

export const fontWeight = {
    normal: `400`,
    bold: `700`,
};

// This is to avoid paragraphs over ~12 words in width
export const paragraphMaxWidth = "40rem";
