import * as S from "./style";
import Select, { SelectOption } from "components/Select";
import { getDateTimeFromSeconds } from "utils/datetime";
import Hint from "components/Hint";
import Field from "components/Field";

interface DateRangeProps {
    value: number;
    options: SelectOption[];
    setDateRange: (_: number) => void;
}

const DateRange = ({ value, options, setDateRange }: DateRangeProps) => {
    const handleChangeDateRange = (event: any) => {
        const target = event.target as HTMLSelectElement;
        setDateRange(Number(target.value));
    };

    let dateFrom, dateTo;
    if (value > Date.now()) {
        // Future
        dateFrom = getDateTimeFromSeconds().date;
        dateTo = getDateTimeFromSeconds((value - 1) / 1000).date;
    } else {
        // Past
        dateFrom = getDateTimeFromSeconds(value / 1000).date;
        dateTo = getDateTimeFromSeconds().date;
    }

    return (
        <Field>
            <Select
                value={value}
                onChange={handleChangeDateRange}
                options={options}
            />
            <Hint>
                {dateFrom}
                {dateTo !== dateFrom && <> to {dateTo}</>}
            </Hint>
        </Field>
    );
};

export default DateRange;
