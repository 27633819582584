import { useEffect, useMemo, useState } from "react";
import { Company, ItemSourceType, itemSourceForDisplay } from "company/types";
import FailedDataFetchingMessage from "components/FailedDataFetchingMessage";
import LoadingBox from "components/LoadingBox";
import ItemSourceIdField from "company/components/items/ItemSourceIdField";
import ItemCheckoutUrlField from "company/components/items/ItemCheckoutUrlField";
import InboundTreasuriesInfoPanel from "company/components/entities/InboundTreasuriesInfoPanel";
import SelectMultipleItems from "company/components/items/SelectMultipleItems";
import ItemsList from "company/components/items/ItemsList";
import Notification, { NotificationType } from "components/Notification";
import ItemAcceptedTokensByNetwork from "company/components/items/ItemAcceptedTokensByNetwork";
import { isValidSourceId } from "utils/items";
import { ItemCategoryTypeInbound } from "types/common-enums";
import { useSearchParams } from "react-router-dom";
import { onlyUnique } from "utils/arrays";
import { canCheckoutItems } from "company/utils/items";
import * as S from "./style";
import { useGetCompanyItems } from "company/hooks/useGetCompanyItems";
import SubSection from "components/SubSection";
import Section from "components/Section";

const CheckoutUrlBuilder = () => {
    // Data
    const { items, getCompanyItemsIsError, getCompanyItemsIsLoading } =
        useGetCompanyItems();

    // URL params for this page
    const [searchParams] = useSearchParams();

    // States

    // 1. The source of items (loop, stripe, etc..) to select
    const sourceIdFromSearchParamsRaw = searchParams.get("sourceId");
    const sourceIdFromSearchParamsNumber = Number(sourceIdFromSearchParamsRaw);
    const validInitialSourceId = isValidSourceId(sourceIdFromSearchParamsNumber)
        ? sourceIdFromSearchParamsNumber
        : ItemSourceType.Loop;

    const [itemSourceId, setItemSourceId] =
        useState<Company.Item["sourceId"]>(validInitialSourceId);

    // 2. The items selected
    const itemIdsFromSearchParams = searchParams.getAll("itemId");
    const [selectedItemsIds, setSelectedItemsIds] = useState<
        Company.Item["id"][]
    >(itemIdsFromSearchParams);

    // 2.1. Remove items
    const removeSelectedItemId = (itemIdToRemove: Company.Item["id"]) => {
        const updatedItemIds = selectedItemsIds.filter(
            (itemId) => itemId !== itemIdToRemove
        );
        setSelectedItemsIds(updatedItemIds);
    };

    // 2.1. Add items
    const addSelectedItemIds = (itemIdsToAdd: Company.Item["id"][]) => {
        const uniqueItemIds = new Set([...selectedItemsIds, ...itemIdsToAdd]);
        setSelectedItemsIds(Array.from(uniqueItemIds));
    };

    // Items available to select
    // Muse be 1) currently selected source 2) Inbound and 3) active
    const itemsForSelect = useMemo(
        () =>
            items.filter(
                (item) =>
                    ItemCategoryTypeInbound.includes(item.type) &&
                    item.sourceId === itemSourceId &&
                    item.active
            ),
        [items, itemSourceId]
    );

    // Get em back
    const selectedItems = useMemo(
        () => items.filter((item) => selectedItemsIds.includes(item.id)),
        [items, selectedItemsIds]
    );

    // In case case of malformed query:
    // Could happen if a we pass multiple itemId to this page that don't share the same source

    // When switching the source, clear the selected item(s)
    useEffect(() => {
        // Early return
        if (selectedItems.length === 0) return;

        const selectedItemsSourceIds = selectedItems
            .map((item) => item.sourceId)
            .filter(onlyUnique);

        const itemsBelongsToSource =
            selectedItemsSourceIds.includes(itemSourceId);

        if (selectedItems.length > 0 && !itemsBelongsToSource) {
            setSelectedItemsIds([]);
        }
    }, [itemSourceId, selectedItems]);

    // Real fun beings: Can a user checkout with a given array of items
    // We useMemo this because canCheckoutItems does a lot
    const { canCheckout, reason, commonAcceptedTokensByNetwork } = useMemo(
        () => canCheckoutItems(selectedItems),
        [selectedItems]
    );

    // We only show common tokens if 1) there are any and 2) more than one item is selected
    const showCommonAcceptedTokens =
        Object.keys(commonAcceptedTokensByNetwork).length > 0 &&
        selectedItems.length > 1;

    // Loading state
    if (getCompanyItemsIsLoading) return <LoadingBox height="20rem" />;

    // Error state
    if (getCompanyItemsIsError) return <FailedDataFetchingMessage />;

    return (
        <Section narrow={true}>
            {/* Source */}
            <SubSection
                title="1. Select a source"
                description="Items can be imported or made directly in Loop"
            >
                <ItemSourceIdField
                    label={false}
                    defaultSource={itemSourceId}
                    onChange={(sourceId) => setItemSourceId(sourceId)}
                />
            </SubSection>

            {/* Funds */}
            <SubSection
                title="2. Confirm receiving wallet"
                description="To add or change please contact Loop"
            >
                <InboundTreasuriesInfoPanel />
            </SubSection>

            {/* Items */}
            <SubSection
                title={`3. Select ${itemSourceForDisplay[itemSourceId]} item(s)`}
                description="Select the item(s) you'd like to collect payment for"
            >
                <S.ItemsSelection>
                    {selectedItems.length > 0 && (
                        <ItemsList
                            items={selectedItems}
                            onRemoveItem={removeSelectedItemId}
                        />
                    )}
                    {showCommonAcceptedTokens && (
                        <ItemAcceptedTokensByNetwork
                            acceptedTokensByNetwork={
                                commonAcceptedTokensByNetwork
                            }
                        />
                    )}
                    <SelectMultipleItems
                        items={itemsForSelect.filter(
                            (item) => !selectedItemsIds.includes(item.id)
                        )}
                        onChange={addSelectedItemIds}
                        itemSourceId={itemSourceId}
                    />
                </S.ItemsSelection>
            </SubSection>

            {/* Checkout URL */}
            {canCheckout && (
                <SubSection
                    title="4. Use this link to get paid today!"
                    description="The item's base link will be available on your
                company dashboard. It does not expire and can be
                used more than once."
                >
                    <ItemCheckoutUrlField label={false} items={selectedItems} />
                </SubSection>
            )}

            {selectedItems.length > 0 && !canCheckout && (
                <Notification
                    key={"msg.id"}
                    message={{
                        id: "formErrorDisplay",
                        msg: (
                            <>
                                <strong>{reason}</strong>
                                <p>Select different items or edit the items</p>
                            </>
                        ),
                        type: NotificationType.ERROR,
                        expires: false,
                    }}
                    removeNotification={false}
                />
            )}
        </Section>
    );
};

export default CheckoutUrlBuilder;
