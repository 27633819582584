import TextArea from "components/TextArea";
import { Form } from "react-router-dom";
import styled from "styled-components";
import { properties as p, colorsDeprecated } from "global-style";

export const CheckoutUrlTextArea = styled(TextArea)`
    font-size: 1.1rem;
    background: #f2efff;
    border: 1px solid black;
    padding: 1rem;
`;

export const AdvancedOptions = styled.div`
    margin-bottom: 2rem;
    margin-top: 2rem;
    cursor: pointer;
`;

export const AmountSetting = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0.5rem;
`;

export const AmountSettingHeader = styled.span`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const AmountSettingsValue = styled.span`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const EditForm = styled(Form)`
    display: flex;
    gap: 1rem;
    align-items: baseline;
    width: 100%;
    max-width: 20rem;
`;

export const InputWrapper = styled.div`
    /* min-width: 6rem; */
`;

export const AdvancedOptionsTitle = styled.strong`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const CarretContainer = styled.span<{ showAdvancedOptions: boolean }>`
    display: inline-block;
    font-size: 0;
    transition: transform ${p.speed};
    transform: ${({ showAdvancedOptions }) =>
        showAdvancedOptions ? "rotate(180deg)" : "rotate(0deg)"};
`;

export const AmountEdited = styled.span`
    color: ${colorsDeprecated.onDisabled};
`;
