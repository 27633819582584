import { useIsLoggedIn, getAuthToken } from "@dynamic-labs/sdk-react-core";

const useWalletLogin = (requiresDynamicLogin: boolean) => {
    // In "connect-only" mode, connecting a wallet is enough to return true here
    const isLoggedInOrConnected = useIsLoggedIn();

    // Only set to true if the user is logged in AND the login is required
    const isDynamicLoggedIn = requiresDynamicLogin && isLoggedInOrConnected;

    const walletSessionToken = isDynamicLoggedIn
        ? getAuthToken() ?? null
        : null;

    return { isDynamicLoggedIn, walletSessionToken };
};

export default useWalletLogin;
