import styled from "styled-components";

export const AuthorizationInformation = styled.ol`
    display: grid;
    margin: 1.5rem 5rem 1.5rem auto;
    padding: 0 1rem;
    gap: 0.5rem;
    width: fit-content;
`;

export const InformationRow = styled.li`
    display: flex;
    gap: 1rem;
    width: 100%;
    justify-content: space-between;

    p {
        margin: 0;
    }
`;

export const InformationCellRight = styled.div`
    text-align: right;
    margin-inline-start: 1rem;
    white-space: nowrap;
`;
