import {
    useMutation,
    UseMutationResult,
    MutationOptions,
} from "@tanstack/react-query";
import { getErrorForDisplay } from "api";
import { NotificationType } from "components/Notification";
import { useNotificationQueue } from "context/NotificationQueue";

function useCustomMutation<TData, TError, TVariables>(
    options: MutationOptions<TData, TError, TVariables, any>
): UseMutationResult<TData, TError, TVariables> {
    const { addNotification } = useNotificationQueue();

    const mutation = useMutation<TData, TError, TVariables>({
        // Default Behavior, show notification
        onError: (error) => {
            const errorForDisplay = getErrorForDisplay(error);
            addNotification({
                msg: errorForDisplay,
                type: NotificationType.ERROR,
            });
        },
        ...options,
    });

    return mutation;
}

export default useCustomMutation;
