import styled from "styled-components";
import { colorsDeprecated as c } from "global-style";
import { CardFooter } from "components/Card";
import Label from "components/Label";
import Field from "components/Field";
import InputWithAddon from "components/InputWithAddon";
import ManagedDetails from "components/Details/ManagedDetails";

export const StepThreeDetails = styled(ManagedDetails)``;

export const AfterTrial = styled(CardFooter)`
    font-size: 0.8rem;
    text-align: center;
    color: ${c.lightOnSurface};
`;

export const AllowanceFooter = styled.footer`
    margin-block: 2rem;
    text-align: center;
    font-weight: 700;
`;

export const Approve = styled(Label)`
    grid-area: approve;
`;

export const Amount = styled(Field)`
    flex: 2;
`;

export const Stepper = styled(Field)`
    flex: 1;
`;

export const Row = styled.div`
    display: flex;
    gap: 1rem;
    margin-block: 1rem;
`;

export const AmountInCoin = styled(InputWithAddon)`
    &:read-only:not([disabled]) {
        background-color: ${c.inputReadonly};
        color: ${c.onInputReadonly};
        &:hover,
        &:focus {
            border-color: #ddd !important;
            outline: none;
        }
    }
`;

export const ApproveAllowanceTitle = styled.h2`
    font-weight: 500;
    small {
        margin-top: 1rem;
        display: inline-block;
    }
`;
