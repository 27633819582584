// Note a companion definition exists in common.d.ts
export enum AgreementType {
    New = 1,
    Active = 2,
    Canceled = 3,
    One_Time = 4,
    Scheduled_Cancel = 5,
}

export const AgreementCanceledStatuses = [
    AgreementType[AgreementType.Canceled],
    AgreementType[AgreementType.Scheduled_Cancel],
];

export enum TransferStatus {
    Scheduled = "Scheduled",
    Succeeded = "Succeeded",
    Failed = "Failed",
    Cancelled = "Cancelled",
    Uncollectible = "Uncollectible",
    Pending = "Pending",
    Draft = "Draft",
}

export enum ItemCategoryType {
    Donation = "Donation",
    Subscription = "Subscription",
    Loan = "Loan",
    OutboundPayment = "Outbound Payment",
    Fee = "Fee",
    Other = "Other",
    One_Time = "One Time",
}

export const ItemCategoryTypeOutbound = [ItemCategoryType.OutboundPayment];

export const ItemCategoryTypeInbound = [
    ItemCategoryType.Donation,
    ItemCategoryType.Subscription,
    ItemCategoryType.Fee,
    ItemCategoryType.Loan,
    ItemCategoryType.Other,
    ItemCategoryType.One_Time,
];

export enum SmartContractType {
    Subscription = 1,
    RecurringSwap = 2,
    VariableRate = 3,
}

export enum ItemType {
    Donation = 1,
    Subscription = 2,
    Loan = 3,
    OutboundPayment = 4,
    Fee = 7,
    Other = 8,
    One_Time = 9,
}

export enum FrequencyType {
    Hour = "hour",
    Day = "day",
    Week = "week",
    Month = "month",
    Year = "year",
}

export enum CurrencyType {
    USD = "USD",
}
