import * as S from "./style";
import { ReactNode, CSSProperties, MouseEventHandler } from "react";
import { useLocation } from "react-router-dom";
import { isAbsolutePath } from "utils/urls";
import { getBooleanFromFnOrBool } from "utils/booleans";
import { HasRoleFunction, UserRole } from "context/User";

export enum MenuType {
    Main,
    Bottom,
}

export interface MenuData {
    id: string;
    heading?: string | ReactNode;
    type: MenuType;
    style?: CSSProperties;
    path?: string;
    isAvailable?: boolean | (() => boolean);
    displayOnlyForRoles?: UserRole[];
    disableUnlessWithinRoles?: UserRole[];
    items: MenuItem[];
}

/* ## USAGE NOTES: 
- `displayOnlyForRoles` and `disableUnlessWithinRoles` are for UserRole conditional checks
- `isAvailable` is for runtime functions that can check misc properties, like "does this entity have children entities?" 

Most importantly: All will assume to be displayed/enabled/available by default, if not specified */
interface MenuItem {
    path: string;
    icon?: ReactNode;
    label: string;
    isAvailable?: boolean | (() => boolean);
    displayOnlyForRoles?: UserRole[];
    disableUnlessWithinRoles?: UserRole[];
    items?: MenuItem[];
}

interface MenuProps {
    data: MenuData;
    tabIndex?: number;
    onStaticLinkClick?: MouseEventHandler<HTMLAnchorElement>;
    onInternalLinkClick?: MouseEventHandler<HTMLAnchorElement>;
    checkUsersRole?: false | HasRoleFunction;
}

const DynamicSubNav = ({ children, parentPath, ...props }: any) => {
    const location = useLocation();
    const match = location.pathname.startsWith(parentPath);
    return (
        <S.DynamicSubNav selected={!!match} {...props}>
            {children}
        </S.DynamicSubNav>
    );
};

const buildMenu = (
    disableMenu: boolean,
    items: MenuItem[],
    menuId: string,
    parentPath: string = ``,
    tabIndex?: number,
    onStaticLinkClick?: MouseEventHandler<HTMLAnchorElement>,
    onInternalLinkClick?: MouseEventHandler<HTMLAnchorElement>,
    checkUsersRole?: false | HasRoleFunction
) => {
    const itemsToDisplay = items.filter(
        ({ isAvailable, displayOnlyForRoles }: MenuItem) =>
            getBooleanFromFnOrBool(isAvailable) &&
            // If there are no roles or way to check them, or they are present and the user has them
            (!checkUsersRole ||
                !displayOnlyForRoles ||
                checkUsersRole(displayOnlyForRoles))
    );

    if (!itemsToDisplay.length) return null;

    return itemsToDisplay.map((item: MenuItem) => {
        // If there was a need to dynamically update the menu items based on changing
        // parameters, this could be build into a component where the state is stored
        // and returns the component when available (rather than pre-filtering)

        const fullPath = `${
            parentPath.endsWith(`/`) ? parentPath.slice(0, -1) : parentPath
        }${
            item.path?.startsWith(`/`) || isAbsolutePath(item.path)
                ? item.path
                : `/${item.path}`
        }`;

        const innerMenuItem = (
            <>
                {item.icon}
                {item.label}
            </>
        );

        const subMenus =
            item.items &&
            item.items.length > 0 &&
            buildMenu(
                disableMenu,
                item.items,
                fullPath,
                fullPath,
                tabIndex,
                onStaticLinkClick,
                onInternalLinkClick,
                checkUsersRole
            );

        const disabledItem: boolean =
            disableMenu ||
            (checkUsersRole &&
                item.disableUnlessWithinRoles &&
                !checkUsersRole(item.disableUnlessWithinRoles)) ||
            false;

        return (
            <S.Item key={`${menuId}-${item.label}`}>
                {isAbsolutePath(fullPath) ? (
                    <S.StaticLink
                        href={fullPath}
                        onClick={!disabledItem ? onStaticLinkClick : undefined}
                        tabIndex={tabIndex}
                        disabled={disabledItem}
                    >
                        {innerMenuItem}
                    </S.StaticLink>
                ) : fullPath ? (
                    <>
                        <S.InternalLink
                            to={fullPath}
                            onClick={
                                !disabledItem ? onInternalLinkClick : undefined
                            }
                            tabIndex={tabIndex}
                            disabled={disabledItem}
                        >
                            {innerMenuItem}
                        </S.InternalLink>
                        {subMenus && (
                            <DynamicSubNav
                                parentPath={fullPath}
                                id={fullPath}
                                role="group"
                            >
                                {subMenus}
                            </DynamicSubNav>
                        )}
                    </>
                ) : (
                    <></>
                )}
            </S.Item>
        );
    });
};

const Menu = ({
    data,
    tabIndex,
    onStaticLinkClick = () => {},
    onInternalLinkClick = () => {},
    checkUsersRole = false,
}: MenuProps) => {
    const display: boolean =
        getBooleanFromFnOrBool(data.isAvailable) &&
        // If there are no roles or way to check them, or they are present and the user has them
        (!checkUsersRole ||
            !data.displayOnlyForRoles ||
            checkUsersRole(data.displayOnlyForRoles));

    const disabledMenu: boolean =
        (checkUsersRole &&
            data.disableUnlessWithinRoles &&
            !checkUsersRole(data.disableUnlessWithinRoles)) ||
        false;

    const menuItems =
        display &&
        buildMenu(
            disabledMenu,
            data.items,
            data.id,
            data.path || ``,
            tabIndex,
            onStaticLinkClick,
            onInternalLinkClick,
            checkUsersRole
        );

    return display && menuItems ? (
        <section>
            {data.heading && (
                <S.SectionHeading>{data.heading}</S.SectionHeading>
            )}
            <S.List id={data.id} role="group" style={data.style}>
                {menuItems}
            </S.List>
        </section>
    ) : (
        <></>
    );
};

export default Menu;
