import { CardSection } from "components/Card";
import { useNetworkAndToken } from "checkout/context/NetworkAndToken";
import MinimumBalanceRequiredDescriptionList from "./MinimumBalanceRequiredDescriptionList";
import NativeTokenBalanceDescriptionList from "./NativeTokenBalanceDescriptionList";
import TokenBalanceDescriptionList from "./TokenBalanceDescriptionList";

const WalletDetailsSection = () => {
    const { networkHexId } = useNetworkAndToken();
    if (!networkHexId) return <></>;
    return (
        <CardSection>
            <TokenBalanceDescriptionList />
            <NativeTokenBalanceDescriptionList />
            <MinimumBalanceRequiredDescriptionList />
        </CardSection>
    );
};

export default WalletDetailsSection;
