import { Dispatch, SetStateAction, memo, useCallback } from "react";
import Input from "components/Input";
import Label from "components/Label";
import Field from "components/Field";

interface InvoiceNotesProps {
    value?: string;
    setValue: Dispatch<SetStateAction<string>>;
    disabled?: boolean;
}

const InvoiceNotes = memo(
    ({ value = ``, setValue, disabled = false }: InvoiceNotesProps) => {
        const handleChange = useCallback(
            (event: any) => {
                setValue(event.target.value);
            },
            [setValue]
        );

        return (
            <Field>
                <Label htmlFor="notes">Notes</Label>
                <Input
                    name="notes"
                    value={value}
                    onChange={handleChange}
                    disabled={disabled}
                />
            </Field>
        );
    }
);

export default InvoiceNotes;
