import * as S from "./style";
import { Link, Outlet } from "react-router-dom";
import Anchor from "components/Anchor";
import Logo from "components/Logo";
import { reactAppBuildType } from "default-variables";
import { useHistoryState } from "hooks/useHistoryState";

// This was obtained from the props of the LogUserInResponse backend class
// and is likely shared between `/admin` and `/company`. There is some
// ambiguity of type on the BE, so move this once that's resolved
export interface LogUserInResponse {
    success: boolean;
    email: string;
    entityId: string;
    delegatedSigning: boolean;
    companyName: string;
    url: string;
    logoUrl: string;
    token: string;
    roles: string[];
}

export interface LoginContextData {
    redirectPath?: string;
}

const LoginWeb2 = () => {
    const { from } = useHistoryState(true);
    const loginContext: LoginContextData = {
        redirectPath: from,
    };

    return (
        <S.Background environment={reactAppBuildType}>
            <S.TopCorner>
                <Link to="/">
                    <S.WhiteLogo
                        src="/img/loop-logo/loop-crypto-tall-white.svg"
                        alt="Loop Crypto"
                    />
                </Link>
            </S.TopCorner>
            <S.Container>
                <S.FullCard forwardedAs="main">
                    <header>
                        <Logo tall lg />
                        <S.EnvBadge />
                    </header>
                    <S.MainBlock>
                        <Outlet context={loginContext} />
                    </S.MainBlock>
                    <S.Footer>
                        <Anchor
                            href={`mailto:${
                                import.meta.env.VITE_EMAIL_SUPPORT
                            }`}
                        >
                            Need help? Contact us.
                        </Anchor>
                    </S.Footer>
                </S.FullCard>
            </S.Container>
        </S.Background>
    );
};

export default LoginWeb2;
