import styled from "styled-components";
import { properties as p } from "global-style";

export const Queue = styled.ul`
    position: fixed;
    z-index: ${p.zIndex.notifications};
    top: 1.5rem;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: end;
    width: calc(100% - 3rem);
    height: 0;
    margin: 0 1.5rem;

    & > li {
        margin: 0 0 1rem 0;
    }
`;
