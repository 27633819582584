import {
    forwardRef,
    useImperativeHandle,
    useState,
    Ref,
    useRef,
    useEffect,
} from "react";
import { validateZeroOrHigher } from "utils/formValidation";
import Label from "components/Label";
import Field from "components/Field";
import Input from "components/Input";
import { Company } from "company/types";

export interface ItemInitialOffsetFieldProps {
    disabled?: boolean;
    defaultInitialOffset?: number;
    onChange?: (initialOffset: Company.Item["initialOffset"]) => void;
    canEdit: boolean;
}

export type ItemInitialOffsetFieldRef = {
    initialOffset: Company.Item["initialOffset"];
};

export enum InvoicePlurality {
    Single,
    Multiple,
}

function ItemInitialOffsetField(
    {
        disabled,
        defaultInitialOffset = 0,
        onChange,
        canEdit,
    }: ItemInitialOffsetFieldProps,
    ref: Ref<ItemInitialOffsetFieldRef>
) {
    const [initialOffset, setInitialOffset] =
        useState<Company.Item["initialOffset"]>(defaultInitialOffset);
    const initialOffsetRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
        initialOffset: initialOffset ? Number(initialOffset) : 0,
    }));

    const initialOffsetName = "initialOffset";

    useEffect(() => {
        if (onChange) onChange(initialOffset);
    }, [initialOffset, onChange]);

    useEffect(() => {
        if (!canEdit) {
            setInitialOffset(0);
        }
    }, [canEdit]);

    return (
        <Field disabled={disabled || !canEdit}>
            <Label
                htmlFor={initialOffsetName}
                optional
                subLabel="Free trials can be set at the product or subscription level "
            >
                Free trial days
            </Label>
            <Input
                type="number"
                disabled={disabled || !canEdit}
                name={initialOffsetName}
                value={initialOffset}
                innerRef={initialOffsetRef}
                onChange={(event) =>
                    setInitialOffset(Number(event.target.value))
                }
                onBlur={(event) =>
                    event.target.value.trim().length > 0 &&
                    validateZeroOrHigher({
                        input: event.target,
                        reportValidity: false,
                    })
                }
                step="1"
                min="0"
                max={500_000}
                placeholder=""
            />
        </Field>
    );
}

export default forwardRef(ItemInitialOffsetField);
