import styled from "styled-components";
import { sizes as s } from "global-style";
import spacing from "theme/spacing";

export const ItemAutoInvoiceCell = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: ${spacing.xxs};
    @media screen and (min-width: ${s.md}) {
        justify-content: unset;
    }
`;
