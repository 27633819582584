import styled, { css } from "styled-components";
import { colorsDeprecated as c, sizes as s } from "global-style";
import ItemizedCheckout from "checkout/components/ItemizedCheckout";
import Marker from "components/Marker";
import Check from "components/icons/Check";
import colors from "theme/colors";

const half = css`
    padding-inline: 1.5rem;
    padding-block: 1.5rem;

    @media screen and (min-width: ${s.md}) {
        padding-block: 2rem;
        padding-inline: 3rem;
    }
    @media screen and (min-width: ${s.lg}) {
        padding-block: 5rem;
        padding-inline: 5rem;
    }
`;

export const Details = styled.section`
    background-color: ${colors.white};
    ${half}
    display: grid;
    justify-items: center;
    align-content: stretch;

    @media screen and (min-width: ${s.md}) {
        grid-template-rows: 1fr auto;
        justify-items: end;
        height: max-content;
        min-height: 100vh;
        position: sticky;
        top: 0;
    }
`;

export const WalletConnectWrapper = styled.div`
    @media screen and (min-width: ${s.md}) {
        padding-top: 20rem;
    }
`;

export const Steps = styled.section`
    ${half}
    display: flex;
    align-items: start;
    justify-content: center;
    @media screen and (min-width: ${s.md}) {
        justify-content: start;
        padding-bottom: 10rem;
    }
`;

export const Checkout = styled.main<{ showCart: boolean }>`
    position: relative;
    display: grid;
    min-height: 100vh;
    background-color: ${colors.neutralBackground};
    align-content: start;

    @media screen and (min-width: ${s.md}) {
        padding-block-end: 0;

        ${({ showCart }) =>
            showCart
                ? `grid-template-columns: 1fr 1fr;`
                : `grid-template-columns: 1fr;`}
        align-content: stretch;

        & ${Steps} {
            ${({ showCart }) => !showCart && `justify-content: center;`}
        }
    }
`;

export const GridContent = styled.div`
    width: 100%;
    max-width: ${s.sm};
`;

export const ItemizedCart = styled(ItemizedCheckout)`
    width: 100%;
    max-width: ${s.sm};
`;

export const Summary = styled.span`
    summary:not([disabled]) & {
        color: ${colors.text};
    }
`;

export const Idle = styled(Marker)`
    margin-inline-end: 1rem;
    summary:is([disabled]) & {
        background-color: ${c.lightestOnSurface};
        color: ${colors.white};
    }
`;

export const Complete = styled(Marker)`
    margin-inline-end: 1rem;
    background-color: ${c.secondaryLight};
`;

export const Checkmark = styled(Check)`
    height: 0.8rem;
    width: 0.8rem;
    fill: ${colors.white};
`;
