import * as S from "./style";
import { useEffect, useRef } from "react";
import SelfServeCreateItemRequestSection from "company/components/entities/SelfServeCreateItemRequestSection";
import SelfServeNewEntityConfigForm, {
    SelfServeNewEntityConfigFormRef,
} from "company/components/entities/SelfServeNewEntityConfigForm";
import SelfServeSubmitForm from "company/components/entities/SelfServeSubmitForm";
import ItemSourceIdField from "company/components/items/ItemSourceIdField";
import SelfServeNewUserForm, {
    SelfServeNewUserFormRef,
} from "company/components/users/SelfServeNewUserForm";
import {
    CreateItemRequest,
    EntityInboundTreasuriesByNetworkId,
    ItemSourceType,
} from "company/types";
import { SelfServePanel, SelfServerEntityDetailsOnly, panelSummaries } from ".";
import { useSelfServePanelManager } from "./hooks/useSelfServePanelManager";
import {
    SelfServeState,
    useSelfServeStateMachine,
} from "./hooks/useSelfServeStateMachine";
import { PostSelfServeCreateAccountResponse, SelfServeNewUser } from "api";
import SubSection from "components/SubSection";
import EntityInboundTreasuriesFields, {
    EntityInboundTreasuriesFieldsRef,
} from "company/components/entities/EntityInboundTreasuriesFields";
import ManagedDetails from "components/Details/ManagedDetails";

interface FormPanelProps {
    itemsNetworkIds: number[];
    createItemRequests: CreateItemRequest[];
    setCreateItemRequests: (requests: CreateItemRequest[]) => void;
    selfServeInboundTreasuries: EntityInboundTreasuriesByNetworkId;
    setSelfServeInboundTreasuries: (
        addresses: EntityInboundTreasuriesByNetworkId
    ) => void;
    selfServeNewEntityConfig: SelfServerEntityDetailsOnly | undefined;
    setSelfServeNewEntityConfig: (config: SelfServerEntityDetailsOnly) => void;
    selfServeNewEntityConfigValid: boolean;
    setSelfServeNewEntityConfigValid: (valid: boolean) => void;
    selfServeNewUser: SelfServeNewUser | undefined;
    setSelfServeNewUser: (user: SelfServeNewUser) => void;
    selfServeNewUserValid: boolean;
    setSelfServeNewUserValid: (valid: boolean) => void;
    setPostSelfServeCreateAccountResponse: (
        response: PostSelfServeCreateAccountResponse
    ) => void;
}

const FormPanels = ({
    itemsNetworkIds,
    createItemRequests,
    setCreateItemRequests,
    selfServeInboundTreasuries,
    setSelfServeInboundTreasuries,
    selfServeNewEntityConfig,
    setSelfServeNewEntityConfig,
    selfServeNewEntityConfigValid,
    setSelfServeNewEntityConfigValid,
    selfServeNewUser,
    setSelfServeNewUser,
    selfServeNewUserValid,
    setSelfServeNewUserValid,
    setPostSelfServeCreateAccountResponse,
}: FormPanelProps) => {
    const { state, dispatch } = useSelfServeStateMachine();
    useSelfServePanelManager(panelSummaries, state);

    const selfServeNewEntityConfigFormRef =
        useRef<SelfServeNewEntityConfigFormRef>(null);
    const selfServeNewUserFormRef = useRef<SelfServeNewUserFormRef>(null);
    const entityInboundTreasuriesFieldsRef =
        useRef<EntityInboundTreasuriesFieldsRef>(null);

    useEffect(() => {
        if (createItemRequests.length === 0)
            dispatch({ type: SelfServeState.ITEM });
        else if (state.step === SelfServeState.ITEM)
            dispatch({ type: SelfServeState.WALLETS });
    }, [state.step, createItemRequests, dispatch]);

    useEffect(() => {
        if (state.step < SelfServeState.WALLETS) return;

        if (!entityInboundTreasuriesFieldsRef?.current?.validate())
            dispatch({ type: SelfServeState.WALLETS });
        else if (state.step === SelfServeState.WALLETS)
            dispatch({ type: SelfServeState.COMPANY });
    }, [state.step, selfServeInboundTreasuries, itemsNetworkIds, dispatch]);

    useEffect(() => {
        if (state.step < SelfServeState.COMPANY) return;

        if (!selfServeNewEntityConfigValid)
            dispatch({ type: SelfServeState.COMPANY });
        else if (state.step === SelfServeState.COMPANY)
            dispatch({ type: SelfServeState.CREDENTIALS });
    }, [state.step, selfServeNewEntityConfigValid, dispatch]);

    const handleSuccess = (
        postSelfServeCreateAccountData: PostSelfServeCreateAccountResponse
    ) => {
        setPostSelfServeCreateAccountResponse(postSelfServeCreateAccountData);
        dispatch({ type: SelfServeState.COMPLETE });
    };

    return (
        <S.Panels>
            <ManagedDetails name={SelfServePanel.ITEM}>
                <SubSection
                    title="Source"
                    description="Items can be imported from another platform, or created right here in Loop"
                >
                    <ItemSourceIdField
                        label={false}
                        defaultSource={ItemSourceType.Loop}
                    />
                </SubSection>
                <SubSection
                    title="Items"
                    description="Add the item you'd like to collect payment for. This
                        item will be saved to your account and can be reused."
                >
                    <SelfServeCreateItemRequestSection
                        onChange={(createItemRequests) =>
                            setCreateItemRequests(createItemRequests)
                        }
                    />
                </SubSection>
            </ManagedDetails>
            <ManagedDetails name={SelfServePanel.WALLETS}>
                {/* SubSection is within the component */}
                <EntityInboundTreasuriesFields
                    ref={entityInboundTreasuriesFieldsRef}
                    onChange={setSelfServeInboundTreasuries}
                    inboundTreasuriesNetworkIdsRequired={itemsNetworkIds}
                    itemName={createItemRequests[0]?.name || ""}
                />
            </ManagedDetails>
            <ManagedDetails name={SelfServePanel.COMPANY}>
                <SubSection
                    title="About you"
                    description="Customize your checkout page with your company's
                    information."
                >
                    <SelfServeNewEntityConfigForm
                        ref={selfServeNewEntityConfigFormRef}
                        selfServeNewEntityConfigValid={
                            selfServeNewEntityConfigValid
                        }
                        onSubmit={(selfServeNewEntityConfig, isValid) => {
                            setSelfServeNewEntityConfig(
                                selfServeNewEntityConfig
                            );
                            setSelfServeNewEntityConfigValid(isValid);
                        }}
                        onChange={() => {
                            if (!selfServeNewEntityConfigValid) return;

                            const isValid =
                                selfServeNewEntityConfigFormRef?.current?.validate() ??
                                false;
                            setSelfServeNewEntityConfigValid(isValid);
                            if (!isValid) {
                                dispatch({ type: SelfServeState.COMPANY });
                            }
                        }}
                    />
                </SubSection>
            </ManagedDetails>
            <ManagedDetails name={SelfServePanel.CREDENTIALS}>
                <SubSection
                    title="Login credentials"
                    description="To manage this payment and create future payment links."
                >
                    <SelfServeNewUserForm
                        ref={selfServeNewUserFormRef}
                        onChange={(selfServeNewUser, isValid) => {
                            setSelfServeNewUser(selfServeNewUser);
                            setSelfServeNewUserValid(isValid);
                        }}
                    />
                </SubSection>
                <SelfServeSubmitForm
                    selfServeNewUser={selfServeNewUser}
                    selfServeNewUserValid={selfServeNewUserValid}
                    selfServeNewEntityConfig={selfServeNewEntityConfig}
                    selfServeNewEntityConfigValid={
                        selfServeNewEntityConfigValid
                    }
                    createItemRequests={createItemRequests}
                    selfServeInboundTreasuries={selfServeInboundTreasuries}
                    onSuccess={handleSuccess}
                />
            </ManagedDetails>
        </S.Panels>
    );
};

export default FormPanels;
