import styled, { css } from "styled-components";
import { boxInputStyles } from "theme/shared";
import { fontSizes, lineHeights } from "theme/typography";
import spacing from "theme/spacing";
import { SelectSizes } from ".";
import colors from "theme/colors";

const selectSizeStyles = (size: SelectSizes) => {
    switch (size) {
        case SelectSizes.Small:
            return css`
                font-size: ${fontSizes.xs};
                padding: ${spacing.xxxs} ${spacing.xxs};
                line-height: ${lineHeights.md};
            `;
        case SelectSizes.Medium:
            return css`
                font-size: ${fontSizes.sm};
                padding: ${spacing.xxs} ${spacing.sm};
                line-height: ${lineHeights.xl};
            `;
        case SelectSizes.Large:
            return css`
                font-size: ${fontSizes.md};
                padding: ${spacing.xs} ${spacing.md};
                line-height: ${lineHeights.xl};
            `;
    }
};

export const selectArrow =
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='5' viewBox='0 0 10 5'%3E%3Cpath d='M0 0L5 5L10 0H0Z' fill='%235F5D67'/%3E%3C/svg%3E%0A";
export const selectArrowPurple =
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='5' viewBox='0 0 10 5'%3E%3Cpath d='M0 0L5 5L10 0H0Z' fill='%234A3AF8'/%3E%3C/svg%3E%0A";

export const Select = styled.select<{
    bold: boolean;
    hollow: boolean;
    $size: SelectSizes;
}>`
    flex-grow: 1;
    width: 100%;
    font-size: ${fontSizes.sm};
    padding: ${spacing.xxs} ${spacing.sm};
    line-height: ${lineHeights.xl};

    background-position: center right 0.5em;
    background-repeat: no-repeat;
    background-image: url("${selectArrow}");
    appearance: none;
    ${({ hollow }) => (hollow ? `background-color: transparent;` : ``)}

    font-weight: ${({ bold }) => (bold ? 700 : 400)};

    ${({ $size }) => selectSizeStyles($size)}

    ${boxInputStyles};

    &:focus,
    &:hover:not(:disabled) {
        background-image: url("${selectArrowPurple}");
        color: var(${colors.neutral50});
        border-color: var(${colors.neutral50});
        cursor: pointer;
    }
`;
