import { useMemo } from "react";
import { userCurrency } from "default-variables";
import { ExchangableToken } from "types/common";
import { TokenExchangeDetailsResponse } from "api";

export const useExchangableTokens = (
    tokens: TokenExchangeDetailsResponse[] | undefined
): { exchangableTokens: ExchangableToken[]; tokenError: string | null } => {
    return useMemo(() => {
        if (!tokens) {
            return { exchangableTokens: [], tokenError: null };
        }

        let tokenError: string | null = null;

        const exchangableTokens = tokens.map((token) => {
            const { exchangeRates, ...rest } = token;

            const exchange = exchangeRates?.find(
                (rate) => rate.currency === userCurrency
            );

            if (!exchange) {
                tokenError = `Exchange rate for "${rest.name}" to "${userCurrency}" was not found.`;
                return {} as ExchangableToken;
            }

            return {
                ...rest,
                exchange,
                exchangeRates,
            };
        });

        return { exchangableTokens, tokenError };
    }, [tokens]);
};
