import { getSelfServeAcceptedTokens } from "api";
import { useCustomQuery } from "./useCustomQuery";
import { useMemo } from "react";

export const GET_SELF_SERVE_ACCEPTED_TOKENS_QUERY = `getSelfServeAcceptedTokens`;

export const useGetSelfServeAcceptedTokens = () => {
    const {
        data,
        isError: getSelfServeAcceptedTokensIsError,
        isLoading: getSelfServeAcceptedTokensIsLoading,
        isSuccess: getSelfServeAcceptedTokensIsSuccess,
        isFetching: getSelfServeAcceptedTokensIsFetching,
    } = useCustomQuery({
        queryKey: [GET_SELF_SERVE_ACCEPTED_TOKENS_QUERY],
        queryFn: getSelfServeAcceptedTokens,
    });

    const acceptedTokensByNetwork = useMemo(
        () => data?.acceptedTokensByNetwork || {},
        [data?.acceptedTokensByNetwork]
    );

    const acceptedNetworkIds = useMemo(() => {
        return Object.keys(acceptedTokensByNetwork).map((id) => Number(id));
    }, [acceptedTokensByNetwork]);

    return {
        acceptedTokensByNetwork,
        acceptedNetworkIds,
        getSelfServeAcceptedTokensIsError,
        getSelfServeAcceptedTokensIsLoading,
        getSelfServeAcceptedTokensIsSuccess,
        getSelfServeAcceptedTokensIsFetching,
    };
};
