import { useMemo } from "react";
import { getInvoiceConfig } from "api";
import { useUser } from "context/User";
import { useAuthQuery } from "hooks/useAuthQuery";

const useGetInvoiceConfig = () => {
    const { getEntityId, getSessionToken } = useUser();

    const {
        data: invoiceConfig,
        isError,
        isLoading,
        isFetching,
        refetch: getInvoiceConfigRefetch,
    } = useAuthQuery({
        queryKey: [`getInvoiceConfig`],
        queryFn: () =>
            getInvoiceConfig(getEntityId(), {
                Authorization: getSessionToken(),
            }),
    });

    const entity = useMemo(() => invoiceConfig?.entity, [invoiceConfig]);
    const networks = useMemo(
        () => invoiceConfig?.networks || [],
        [invoiceConfig]
    );

    // [ ] ENS lookup here if necessary (see `useGetCompanyConfig`)

    return {
        data: {
            entity,
            networks,
        },
        isError,
        isLoading,
        isFetching,
        getInvoiceConfigRefetch,
    };
};

export default useGetInvoiceConfig;
