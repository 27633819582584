import styled from "styled-components";
import colors from "theme/colors";
import { colorsDeprecated as c, spin } from "global-style";

// prettier-ignore
export const Spinner = styled.span<{ desaturate?: boolean; color?: string }>`
    display: inline-block;
    height: 1.6em;
    width: 1.6em;
    background-color: ${({ desaturate, color }) => desaturate ? c.lighterOnSurface : color ? color : colors.primary};
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 22'%3E%3Cpath d='M11 0v3a8 8 0 0 0-6.76 12.26L5.7 13.8A6 6 0 0 1 11 5v3l4-4-4-4zm6 11a6 6 0 0 1-6 6v-3l-4 4 4 4v-3a8 8 0 0 0 6.76-12.26L16.3 8.2c.45.83.7 1.79.7 2.8z' /%3E%3C/svg%3E");
    mask-size: cover;
    mask-repeat: no-repeat;
    animation: 1s linear ${spin} infinite;
`;
