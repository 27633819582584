import styled from "styled-components";
import { sizes as s, properties as p } from "global-style";
import Card from "components/Card";
import colors from "theme/colors";

export const TransactionPending = styled(Card)`
    max-width: ${s.sm};
    box-shadow: ${p.shadowLg};
    margin: 1rem auto;
`;
export const TransactionList = styled.ol`
    padding: 0;
    counter-reset: list;
    list-style-type: none;
    position: relative;
    li {
        counter-increment: list;
        margin: 1rem 0;
        padding-left: 2.5em;
    }
    > li::before {
        content: counter(list) ".";
        position: absolute;
        inset: auto auto auto 1rem;
        margin: auto;
        font-size: 1.25em;
        font-weight: 700;
        line-height: 0.9;
    }
`;
export const CopyBtnImg = styled.svg`
    * {
        fill: ${colors.primary};
    }
`;
export const Heading = styled.h1`
    font-size: 2rem;
    margin: 0;
`;
