import * as S from "./style";
import {
    ReactNode,
    MouseEvent,
    MouseEventHandler,
    AnchorHTMLAttributes,
    forwardRef,
    CSSProperties,
} from "react";
import { isAbsolutePath } from "utils/urls";

interface AnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    children: ReactNode;
    href?: string;
    onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
    anchorLinkPreventDefault?: boolean;
    icon?: boolean;
    disabled?: boolean;
    underlined?: boolean;
    inheritColor?: boolean;
    block?: boolean;
    nowrap?: boolean;
    iconFill?: string;
    className?: string | undefined;
    style?: Partial<CSSProperties> | undefined;
    download?: string;
}

export const Anchor = forwardRef(
    (
        {
            children,
            href = "",
            onClick,
            anchorLinkPreventDefault = true,
            disabled = false,
            underlined = true,
            inheritColor = false,
            block = false,
            icon = false,
            nowrap = false,
            iconFill,
            className,
            style,
            download,
            title,
        }: AnchorProps,
        ref
    ) => {
        const onClickHandler: MouseEventHandler<HTMLAnchorElement> = (
            event: MouseEvent<HTMLAnchorElement>
        ) => {
            // Default behavior assumes we don't want anchor links to add a hash to the URL
            if (href.startsWith(`#`) && anchorLinkPreventDefault) {
                event.preventDefault();
            }
            if (disabled) {
                return;
            }
            if (onClick) {
                onClick(event);
            }
        };

        return !isAbsolutePath(href) ? (
            <S.InternalAnchor
                to={href}
                title={title}
                onClick={onClickHandler}
                disabled={disabled}
                $underlined={underlined}
                $inheritColor={inheritColor}
                $block={block}
                $nowrap={nowrap}
                $icon={icon}
                className={className}
                style={style}
            >
                {children}
            </S.InternalAnchor>
        ) : (
            <S.Anchor
                href={href}
                title={title}
                target={isAbsolutePath(href) ? `_blank` : `_self`}
                rel={isAbsolutePath(href) ? `noreferrer` : undefined}
                onClick={onClickHandler}
                disabled={disabled}
                $underlined={underlined}
                $inheritColor={inheritColor}
                $block={block}
                $nowrap={nowrap}
                $icon={icon}
                className={className}
                style={style}
                download={download}
            >
                {children}
                {icon && (
                    <S.Icon
                        viewBox="0 0 18 18"
                        xmlns="http://www.w3.org/2000/svg"
                        $nowrap={nowrap}
                    >
                        <S.Path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2 2v14h14V9h2v7l-2 2H2l-2-2V2l2-2h7v2H2Zm9 0V0h7v7h-2V3L6 13l-1-1L15 2h-4Z"
                            style={iconFill ? { fill: iconFill } : undefined}
                        />
                    </S.Icon>
                )}
            </S.Anchor>
        );
    }
);

export default Anchor;
