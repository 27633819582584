import * as S from "./style";
import {
    createContext,
    useContext,
    useRef,
    useState,
    useCallback,
    ReactNode,
    CSSProperties,
} from "react";
import { v4 as uuid } from "uuid";
import Notification, {
    NotificationMessage,
    NotificationType,
} from "components/Notification";

export interface QueuedNotification {
    msg: ReactNode | Error;
    type?: NotificationType;
    expires?: number | false;
    style?: CSSProperties;
    customId?: string | null;
}

type NotificationMsgsThatTimeout = NotificationMessage & {
    // Store the timeout so the notification clears itself in `expires` milliseconds
    timeout: NodeJS.Timeout | false;
};

interface NotificationQueueValues {
    addNotification: (params: QueuedNotification) => string;
    removeNotification: (id: string) => void;
    clearAllNotifications: () => void;
}

const NotificationQueueContext = createContext<NotificationQueueValues>(
    {} as NotificationQueueValues
);

const NotificationQueueProvider = ({
    children,
    ...props
}: {
    children: ReactNode;
}) => {
    const [notifications, setNotifications] = useState<
        NotificationMsgsThatTimeout[]
    >([]);
    const msgQueue = useRef<NotificationMsgsThatTimeout[]>(notifications);

    const removeNotification = useCallback((id: string) => {
        msgQueue.current = msgQueue.current.filter((m) => m.id !== id);
        setNotifications(msgQueue.current);
    }, []);

    const addNotification = useCallback(
        ({
            msg,
            type = NotificationType.INFO,
            expires,
            style = {},
            customId = null,
        }: QueuedNotification) => {
            const id = customId || uuid();

            // Check for existing notifications with this id, if so, remove them
            const existingNotification = msgQueue.current.find(
                ({ id: existingId }) => id === existingId
            );
            if (existingNotification) {
                removeNotification(existingNotification.id);
            }

            // If it's an Error() typed message, use that string and remove "Error: "
            if (msg instanceof Error && msg?.message)
                msg = msg.message.replace(/Error: /gi, ``);
            const msgTxt = msg as ReactNode;

            // This ensures that the notification will not auto-expire if it's a "working" type
            // Typescript struggles to understand this logic, so added a ts-ignore below
            if (type === NotificationType.WORKING) {
                if (expires)
                    console.warn(
                        `A "working" notification can not auto-expire: Setting expires to false.`
                    );
                expires = false;
            }

            // If expires still undefined, set to 10 seconds
            expires = expires ?? 10000;

            // @ts-ignore
            const newNotification: NotificationMsgsThatTimeout = {
                id,
                type,
                msg: msgTxt,
                expires,
                style,
                timeout:
                    !!expires &&
                    setTimeout(() => {
                        removeNotification(id);
                    }, expires),
            };

            msgQueue.current = [newNotification, ...msgQueue.current];
            setNotifications(msgQueue.current);

            return id;
        },
        [removeNotification]
    );

    const clearAllNotifications = useCallback(() => {
        msgQueue.current = [];
        setNotifications(msgQueue.current);
    }, []);

    const defaultValue = {
        addNotification,
        removeNotification,
        clearAllNotifications,
    };

    return (
        <NotificationQueueContext.Provider value={defaultValue} {...props}>
            {children}
            <NotificationQueue
                notifications={notifications}
                removeNotification={removeNotification}
            />
        </NotificationQueueContext.Provider>
    );
};

const useNotificationQueue = () => {
    const context = useContext(NotificationQueueContext);
    if (context === undefined) {
        throw new Error(
            `useNotificationQueue() must be used within a NotificationQueueProvider`
        );
    }
    return context;
};

interface NotificationQueueProps {
    notifications: NotificationMessage[];
    removeNotification: (id: string) => void;
}

const NotificationQueue = ({
    notifications = [],
    removeNotification,
}: NotificationQueueProps) => {
    return (
        <S.Queue role="group">
            {notifications.map((msg) => (
                <Notification
                    key={msg.id}
                    message={msg}
                    removeNotification={removeNotification}
                />
            ))}
        </S.Queue>
    );
};

export { NotificationQueueProvider, useNotificationQueue };
