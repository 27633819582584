import styled from "styled-components";

export const Transactions = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const TypeRow = styled.nav`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const Filters = styled.header`
    display: flex;
    align-items: flex-end;
    gap: 1em;
    margin-block-end: 1rem;
`;

export const Download = styled.div`
    flex-grow: 1;
    text-align: right;
`;

export const TableSection = styled.section`
    h1 {
        font-size: 2rem;
    }
    h2 {
        font-size: 1.5rem;
    }
    h3 {
        font-size: 1.17rem;
        margin-block: 1rem;
    }
    p {
        margin-block: 1rem;
    }
    & + & {
        margin-top: 2rem;
    }
    :first-child {
        h3 {
            margin-block-start: 0;
        }
    }
`;

export const Explainer = styled.small`
    line-height: 1.15;
    display: inline-block;
`;

export const SplitCol = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const NoWrap = styled.span`
    white-space: nowrap;
`;
