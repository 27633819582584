import { useMemo } from "react";
import { useTableQuery } from "./useTableQuery";
import { HeadersObject } from "api/rest";

export interface TableQueryParams<TColumns = string> {
    page?: number;
    limit?: number;
    sortBy?: TColumns;
    sortDir?: "asc" | "desc" | undefined;
}

export type ExtendedQueryParams<TFilters> = TableQueryParams & TFilters;

export interface TableFormattingData<TResponse, TFormattingData> {
    response: TResponse;
    formattingData: TFormattingData;
}

interface TableConfig<TResponse, TFilters, THeadings, TFormattingData> {
    queryKey: string;
    apiQueryFn: (
        entityId: string,
        request: ExtendedQueryParams<TFilters>,
        headers: HeadersObject
    ) => Promise<TResponse>;
    headings: RowHeading<THeadings>[];
    formatFn: (
        response: TableFormattingData<TResponse, TFormattingData>
    ) => TableFormatted<TResponse>;
    formattingData: TFormattingData;
    initialFilters?: TFilters;
    initialSort?: SortBy;
    initialPagination?: Pagination;
}

interface TableFormatted<TResponse> {
    response: TResponse;
    totalResults: number;
    records: Tuple[];
}

export const useTableData = <TResponse, TFilters, THeadings, TFormattingData>({
    queryKey,
    apiQueryFn,
    headings,
    formatFn,
    formattingData,
    ...config
}: TableConfig<TResponse, TFilters, THeadings, TFormattingData>) => {
    const { query, pagination, sort, handlers } = useTableQuery<
        TResponse,
        THeadings,
        TFilters
    >({
        queryKey,
        apiQueryFn,
        headings,
        ...config,
    });

    const { response, totalResults, records } = useMemo(() => {
        if (!query.data) {
            return {
                response: [],
                totalResults: 0,
                records: [],
            };
        }
        return formatFn({ response: query.data, formattingData });
    }, [query.data, formatFn, formattingData]);

    return {
        response,
        totalResults,
        headings,
        records,
        pagination,
        sort,
        isError: query.isError,
        isLoading: query.isLoading,
        isFetching: query.isFetching,
        dataUpdatedAt: query.dataUpdatedAt,
        refetch: query.refetch,
        ...handlers,
    };
};

export default useTableData;
