import * as S from "./style";

interface WalletSwitchMessageProps {}

const WalletSwitchMessage = (props: WalletSwitchMessageProps) => {
    return (
        <S.WalletSwitchMessage>
            <p>Hi 👋</p>
            <p>
                You can now change your wallet within your wallet's browser
                extension.
            </p>
        </S.WalletSwitchMessage>
    );
};

export default WalletSwitchMessage;
