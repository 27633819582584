import {
    forwardRef,
    Ref,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import SelectMultiple, {
    SelectMultipleOptionProps,
} from "components/SelectMultiple";
import { useFetch } from "hooks/useFetch";
import { apiServerUrl } from "default-variables";
import { TagPayload } from "company/types";
import { useUser } from "context/User";

export interface TagsInputProps {
    name?: string;
    defaultSelectedTagIds?: number[];
    onChange?: (values: number[]) => void;
    disabled?: boolean;
}

export type TagsInputRef = {
    getSelectedTagsIds: () => number[];
};

function TagsInput(
    {
        name = "tags",
        defaultSelectedTagIds = [],
        onChange,
        disabled = false,
    }: TagsInputProps,
    ref: Ref<TagsInputRef>
) {
    const { getEntityId, getSessionToken } = useUser();
    const [tags, setTags] = useState<TagPayload[]>([]);
    const [options, setOptions] = useState<SelectMultipleOptionProps[]>([]);
    const [selectedTagIds, setSelectedTagIds] = useState<number[]>(
        defaultSelectedTagIds
    );
    const [postTagLoading, setPostTagLoading] = useState<boolean>(false);

    const getSelectedTagsIds = () => selectedTagIds;
    useImperativeHandle(ref, () => ({ getSelectedTagsIds }));

    // Get Tags
    const getTagsEndpoint = `${apiServerUrl}/api/v1/company/tags`;
    const { data: getTagsData, loading: getTagsLoading }: any = useFetch(
        getTagsEndpoint,
        {
            headers: {
                Authorization: getSessionToken(),
                "Content-Type": "application/json",
                "entity-id": getEntityId(),
            },
        }
    );

    // Post tags
    // Note: Using fetch instead of useFetch here because it doesn't take a body
    // arguments, and having the [tag] in a useState than useFetch relies on
    // introduces race condition. Ideally we'd have a usePost or useMutation
    // hooks instead of useFetch
    const postTagsEndpoint = `${apiServerUrl}/api/v1/company/tags`;
    const postTags = async (tag: string) => {
        setPostTagLoading(true);
        const response = await fetch(postTagsEndpoint, {
            method: `POST`,
            body: JSON.stringify([tag]),
            headers: {
                Authorization: getSessionToken(),
                "Content-Type": "application/json",
                "entity-id": getEntityId(),
            },
        });
        const data = await response.json();
        setPostTagLoading(false);
        return data;
    };

    // On load
    useEffect(() => {
        if (!getTagsLoading && getTagsData?.tags) {
            setTags(getTagsData.tags);
        }
    }, [getTagsLoading, getTagsData]);

    useEffect(() => {
        if (tags) {
            const tagsOptions =
                tags.map((tag) => {
                    return {
                        value: tag.id,
                        label: tag.content,
                    };
                }) || [];
            setOptions(tagsOptions);
        }
    }, [tags]);

    const onCreateNewTag = async (value: string) => {
        const data = await postTags(value);
        if (data?.tags) {
            setTags(data.tags);
            const tag = data.tags.find(
                (tag: TagPayload) => tag.content.trim() === value
            );
            setSelectedTagIds((prev) => [...prev, tag.id]);
        }
    };
    const handleOnChange = (values: number[]) => {
        setSelectedTagIds(values);
    };

    useEffect(() => {
        if (onChange) {
            onChange(selectedTagIds);
        }
    }, [selectedTagIds, onChange]);

    return (
        <SelectMultiple
            options={options}
            name={name}
            disabled={disabled || getTagsLoading || postTagLoading}
            values={selectedTagIds}
            onChange={handleOnChange}
            onCreateOption={onCreateNewTag}
        />
    );
}
export default forwardRef(TagsInput);
