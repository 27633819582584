import * as S from "./style";
import React, {
    forwardRef,
    useState,
    useRef,
    useEffect,
    useImperativeHandle,
} from "react";
import dayjs from "dayjs";
import { TimeFrequency } from "utils/dates";
import Field from "components/Field";
import TransferBillDateField, {
    TransferBillDateFieldRef,
} from "company/components/transfers/TransferBillDateField";
import Anchor from "components/Anchor";

export type TransferBillDateMultipleFieldProps = {
    disabled: boolean;
    defaultBillDates?: Date[];
    onChangeBillDates?: (billDates: Date[]) => void;
    billDateWillReachTransferLimit: boolean;
    maxTransfersPerRequest: number;
};

export type TransferBillDateMultipleFieldRef = {
    billDates: Date[];
    billDatesForApi: number[];
    validate: () => boolean;
};

const TransferBillDateMultipleField = (
    {
        disabled,
        defaultBillDates = [new Date()],
        onChangeBillDates,
        billDateWillReachTransferLimit,
        maxTransfersPerRequest,
    }: TransferBillDateMultipleFieldProps,
    ref: React.Ref<TransferBillDateMultipleFieldRef>
) => {
    const [billDates, setBillDates] = useState<Date[]>(defaultBillDates);
    const transferBillDateFieldRefs = useRef(
        billDates.map(() => React.createRef<TransferBillDateFieldRef>())
    );

    useEffect(() => {
        const currentLength = transferBillDateFieldRefs.current.length;
        if (billDates.length > currentLength) {
            transferBillDateFieldRefs.current.push(
                ...Array(billDates.length - currentLength)
                    .fill(0)
                    .map(() => React.createRef<TransferBillDateFieldRef>())
            );
        } else {
            transferBillDateFieldRefs.current.splice(billDates.length);
        }
        if (onChangeBillDates) onChangeBillDates(billDates);
    }, [billDates]);

    const availableIncrements = Object.values(TimeFrequency).filter((value) =>
        ["day", "week", "month"].includes(value)
    );

    const addBillDateByIncrement = (incrementType: TimeFrequency) => {
        const anchor = dayjs(billDates[0]);

        const nextBillDate = anchor.add(
            billDates.length,
            incrementType as dayjs.ManipulateType
        );

        setBillDates([...billDates, nextBillDate.toDate()]);
    };

    const updateBillDates = (updatedDate: Date, index: number) => {
        setBillDates((prev) =>
            prev.map((date, i) => (i !== index ? date : updatedDate))
        );
    };

    useImperativeHandle(ref, () => ({
        billDates,
        billDatesForApi: billDates.map((date) => date.getTime() / 1000),
        validate: () =>
            transferBillDateFieldRefs.current.every(
                (ref) => ref.current?.validate() ?? false
            ),
    }));

    return (
        <div>
            <Field>
                {billDates.map((date, index) => (
                    <S.BillDateContainer key={index}>
                        <TransferBillDateField
                            defaultBillDate={date}
                            disabled={disabled}
                            ref={transferBillDateFieldRefs.current[index]}
                            onChangeBillDate={(updatedDate) =>
                                updateBillDates(updatedDate, index)
                            }
                        />
                        {index > 0 && (
                            <Anchor
                                href="#"
                                disabled={disabled}
                                underlined={false}
                                onClick={() =>
                                    setBillDates((prev) =>
                                        prev.filter((_, i) => i !== index)
                                    )
                                }
                            >
                                Remove
                            </Anchor>
                        )}
                    </S.BillDateContainer>
                ))}
            </Field>
            {billDateWillReachTransferLimit ? (
                <footer>
                    You can submit a maximum of {maxTransfersPerRequest}{" "}
                    transfers
                </footer>
            ) : (
                <footer>
                    Add another date:
                    {availableIncrements.map((type) => (
                        <S.AddDateContainer key={type}>
                            <Anchor
                                href="#"
                                disabled={disabled}
                                underlined={false}
                                onClick={() =>
                                    addBillDateByIncrement(type as any)
                                }
                            >
                                + 1 {type}
                            </Anchor>
                        </S.AddDateContainer>
                    ))}
                </footer>
            )}
        </div>
    );
};

export default forwardRef(TransferBillDateMultipleField);
