import { CreateItemRequest } from "company/types";
import * as S from "./style";
import { Ref } from "react";
import CreateItemRequestListRow from "../CreateItemRequestListRow";

export interface CreateSelfServeItemListProps {
    onRemoveItem?: (item: CreateItemRequest) => void;
    onEditItem?: (item: CreateItemRequest) => void;
    items: CreateItemRequest[];
}

export type CreateSelfServeItemListRef = {
    itemSourceId: number;
};

function CreateSelfServeItemList(
    { items, onRemoveItem, onEditItem }: CreateSelfServeItemListProps,
    ref: Ref<CreateSelfServeItemListRef>
) {
    return (
        <S.CreateSelfServeItemList>
            {items.map((item) => (
                <S.ItemRowWrapper key={item.name}>
                    <CreateItemRequestListRow
                        item={item}
                        onRemoveItem={onRemoveItem}
                        onEditItem={onEditItem}
                    />
                </S.ItemRowWrapper>
            ))}
        </S.CreateSelfServeItemList>
    );
}

export default CreateSelfServeItemList;
