import { useEffect, useState } from "react";

const useWindowSizes = () => {
    const [windowWidth, setWindowWidth] = useState<number>(() => {
        // Support for race condition and server rendering
        return typeof window === "undefined" ? 0 : window.innerWidth;
    });

    const [windowHeight, setWindowHeight] = useState<number>(() => {
        // Support for race condition and server rendering
        return typeof window === "undefined" ? 0 : window.innerHeight;
    });

    const [scrollTop, setScrollTop] = useState<number>(() => {
        // Support for race condition and server rendering
        return typeof window === "undefined" ? 0 : window.pageYOffset;
    });

    // Resize / scroll effect
    useEffect(() => {
        const updateWindowDimensions = () => {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        };

        const updateWindowScrollTop = () => {
            setScrollTop(window.pageYOffset);
        };

        window.addEventListener("resize", updateWindowDimensions);
        window.addEventListener("scroll", updateWindowScrollTop);
        updateWindowDimensions();

        return () => {
            window.removeEventListener("resize", updateWindowDimensions);
            window.removeEventListener("scroll", updateWindowScrollTop);
        };
    }, []);

    return {
        windowWidth,
        windowHeight,
        scrollTop,
    };
};

export default useWindowSizes;
