import styled, { css } from "styled-components";
import { colorsDeprecated as c } from "global-style";
import Input from "components/Input";
import Button from "components/Button";
import Spinner from "components/Spinner";
import Pencil from "components/icons/Pencil";
import Check from "components/icons/Check";
import X from "components/icons/X";
import colors from "theme/colors";

export enum Side {
    RIGHT = "inline-end",
    LEFT = "inline-start",
}
export type Placement = `${Side}`;

export const InputContent = styled(Input)`
    width: max-content;
    overflow: clip;
    margin-inline-start: 1px;
`;
export const SavedContent = styled.span`
    line-height: 1.34375;
    padding-block: 0.5rem;
    border: 1px solid transparent;
    overflow-x: clip;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
export const ActionBtn = styled(Button)`
    font-weight: bold;
`;

export const InputEditInline = styled.form<{
    disabled: boolean;
    slim: boolean;
    placement: Placement;
    icons: boolean;
}>`
    display: inline-flex;
    overflow-x: clip;
    gap: ${({ icons }) => (icons ? `0.25rem` : `0.5rem`)};
    align-items: center;
    color: ${({ disabled }) => (disabled ? c.onDisabled : `inherit`)};
    flex-direction: ${({ placement }) =>
        placement === Side.LEFT ? `row-reverse` : `row`};

    & ${SavedContent}, & ${InputContent} {
        ${({ slim }) =>
            slim
                ? css`
                      padding-block: 0;
                      line-height: 1.34375;
                  `
                : ``}
    }
    & ${InputContent} {
        ${({ slim }) =>
            slim
                ? css`
                      padding-inline: 0.25rem;
                      border-radius: 0.25em;
                  `
                : ``}
        &:invalid {
            ${({ slim }) =>
                slim
                    ? css`
                          background-size: 1.25em;
                          background-position: calc(100% - 0.125em) center;
                          padding-inline-end: 1.5em !important;
                      `
                    : ``}
        }
    }
`;

export const WorkingIcon = styled(Spinner)`
    height: 1.25rem;
    width: 1.25rem;
`;

const icons = css<{ disabled: boolean }>`
    display: block;
    height: 0.8rem;
    width: 0.8rem;
    ${({ disabled }) => (disabled ? `fill: ${c.disabled}` : ``)}
`;
export const Edit = styled(Pencil)`
    fill: ${colors.primary};
    ${icons}
`;
export const Save = styled(Check)`
    fill: ${colors.success};
    ${icons}
`;
export const Cancel = styled(X)`
    fill: ${colors.error};
    ${icons}
`;
