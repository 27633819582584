import { memo, forwardRef, ForwardedRef, useCallback, RefObject } from "react";
import { validateWalletAddress } from "utils/formValidation";
import Label from "components/Label";
import Input from "components/Input";
import Field from "components/Field";
import TransferAmountToAddress from "company/components/transfers/TransferAmountToAddressField";

interface ReceivingWalletProps {
    value?: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    disabled?: boolean;
    readOnly?: boolean;
}

const ReceivingWallet = memo(
    forwardRef<HTMLInputElement, ReceivingWalletProps>(
        (
            {
                value,
                setValue,
                disabled = false,
                readOnly = false,
            }: ReceivingWalletProps,
            ref: ForwardedRef<HTMLInputElement>
        ) => {
            const handleChange = useCallback(
                (event: any) => {
                    setValue(event.target.value);
                },
                [setValue]
            );

            return (
                <Field>
                    <Label htmlFor="toAddress" required>
                        Receiving wallet address
                    </Label>
                    <Input
                        type="text"
                        name="toAddress"
                        value={value}
                        innerRef={ref as RefObject<HTMLInputElement>}
                        disabled={disabled}
                        readOnly={readOnly}
                        onChange={handleChange}
                        onBlur={(event) =>
                            validateWalletAddress({
                                input: event.target,
                                reportValidity: false,
                            })
                        }
                        placeholder="0x1234..."
                    />
                </Field>
            );
        }
    )
);

export default ReceivingWallet;
