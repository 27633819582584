import Title from "components/Title";
import * as S from "./style";
import Paragraph from "components/Paragraph";

interface EmptyTableMessageProps {
    title: React.ReactNode;
    description: React.ReactNode;
}

const EmptyTableMessage = ({ title, description }: EmptyTableMessageProps) => {
    return (
        <>
            <S.EmptyTableMessage>
                <S.EmptyTableMessageWrapper>
                    <Title level="h3">{title}</Title>
                    <Paragraph>{description}</Paragraph>
                </S.EmptyTableMessageWrapper>
            </S.EmptyTableMessage>
        </>
    );
};

export default EmptyTableMessage;
