import styled from "styled-components";
import Card, { CardFooter, CardHeader, CardSection } from "components/Card";
import { colorsDeprecated as c } from "global-style";

export const WalletSwitchSection = styled(Card)`
    p:not(:last-of-type) {
        margin-bottom: 1rem;
    }
`;

export const WalletSwitchHeader = styled(CardHeader)`
    font-size: 1.25rem;
    line-height: 1.2;
    font-weight: 700;
`;

export const WalletSwitchContent = styled(CardSection)`
    border-block-start: 1px solid ${c.border};
    padding-block-start: 1.5rem;
`;

export const WalletSwitchFooter = styled(CardFooter)``;

export const SuccessMessage = styled.strong`
    color: #00a570;
`;

export const MultiSigWrapper = styled.div`
    text-align: center;
    margin-top: 0.5rem;
`;

export const Terms = styled.footer`
    font-size: 0.8rem;
    text-align: center;
    color: ${c.lightOnSurface};
`;
