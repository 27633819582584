import Title from "components/Title";
import * as S from "./style";
import { ReactNode } from "react";
import Paragraph from "components/Paragraph";
import { SpacingTrack } from "theme/spacing";

export enum Separator {
    Top = "top",
    Bottom = "bottom",
    Both = "both",
}

interface SubSectionProps {
    children?: ReactNode;
    title?: ReactNode;
    description?: ReactNode;
    spacing?: SpacingTrack;
    separator?: Separator | `${Separator}`;
}

const SubSection = ({
    children,
    title,
    description,
    spacing,
    separator,
}: SubSectionProps) => {
    return (
        <S.SubSection spacing={spacing} separator={separator}>
            {(title || description) && (
                <S.TitleWrapper>
                    {title && <Title level="h3">{title}</Title>}
                    {description && <Paragraph>{description}</Paragraph>}
                </S.TitleWrapper>
            )}
            {children}
        </S.SubSection>
    );
};

export default SubSection;
