import { apiServerUrl } from "default-variables";

export const POST_REQUEST_ERROR = "POST request failed";
export const POST_REQUEST_PARSE_ERROR = "POST request failed to parse";
export const GET_REQUEST_ERROR = "GET request failed";
export const GET_REQUEST_PARSE_ERROR = "GET request failed to parse";

export const UNAUTHORIZED_MESSAGE = "Unauthorized";

export type HeadersObject = Record<string, string>;
type JsonPrimitive = string | number | boolean | null;
type JsonArray = JsonPrimitive[];

export interface Payload {
    [k: string]: JsonPrimitive | JsonArray | undefined | any;
}

export async function post<TData = unknown, TError = unknown>(
    path: string,
    payload?: Payload,
    headers?: HeadersObject
): Promise<{
    data: TData | undefined;
    error: TError | undefined;
    response: Response;
}> {
    const body = payload ? JSON.stringify(payload) : null;

    const response = await fetch(`${apiServerUrl}/${path}`, {
        method: "POST",
        body: body,
        headers: getHeaders(headers),
    });

    let data;
    let error;

    try {
        data = await response.json();
    } catch (err) {
        error = { message: POST_REQUEST_PARSE_ERROR, originalError: err };
    }

    if (!response.ok) {
        error = data ? data : { message: POST_REQUEST_ERROR };
    }

    return { data, error, response };
}

export async function patch<TData = unknown, TError = unknown>(
    path: string,
    payload?: Payload,
    headers?: HeadersObject
): Promise<{
    data: TData | undefined;
    error: TError | undefined;
    response: Response;
}> {
    const body = payload ? JSON.stringify(payload) : null;

    const response = await fetch(`${apiServerUrl}/${path}`, {
        method: "PATCH",
        body: body,
        headers: getHeaders(headers),
    });

    let data;
    let error;

    try {
        data = await response.json();
    } catch (err) {
        error = { message: POST_REQUEST_PARSE_ERROR, originalError: err };
    }

    if (!response.ok) {
        error = data ? data : { message: POST_REQUEST_ERROR };
    }

    return { data, error, response };
}

export interface GetPayload {
    [k: string]: string;
}

export async function get<TData = unknown, TError = unknown>(
    path: string,
    payload?: GetPayload,
    headers?: HeadersObject
): Promise<{
    data: TData | undefined;
    error: TError | undefined;
    response: Response;
}> {
    const queryString = payload ? new URLSearchParams(payload).toString() : "";
    const url = queryString
        ? `${apiServerUrl}/${path}?${queryString}`
        : `${apiServerUrl}/${path}`;

    const response = await fetch(url, {
        method: "GET",
        headers: getHeaders(headers),
    });

    let data;
    let error;

    try {
        data = await response.json();
    } catch (err) {
        error = { message: GET_REQUEST_PARSE_ERROR, originalError: err };
    }

    if (!response.ok) {
        error = data ? data : { message: GET_REQUEST_ERROR };
    }

    return { data, error, response };
}

export async function put<TData = unknown, TError = unknown>(
    path: string,
    payload?: Payload,
    headers?: HeadersObject
): Promise<{
    data: TData | undefined;
    error: TError | undefined;
    response: Response;
}> {
    const body = payload ? JSON.stringify(payload) : null;

    const response = await fetch(`${apiServerUrl}/${path}`, {
        method: "PUT",
        body: body,
        headers: getHeaders(headers),
    });

    let data;
    let error;

    try {
        data = await response.json();
    } catch (err) {
        error = { message: POST_REQUEST_PARSE_ERROR, originalError: err };
    }

    if (!response.ok) {
        error = data ? data : { message: POST_REQUEST_ERROR };
    }

    return { data, error, response };
}

export function getHeaders(headers?: HeadersObject): Headers {
    const defaultHeaders = new Headers({ "Content-Type": "application/json" });
    if (headers) {
        Object.entries(headers).forEach(([key, value]) => {
            defaultHeaders.set(key, value);
        });
    }
    return defaultHeaders;
}
