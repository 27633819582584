import * as S from "./style";
import { colorsDeprecated as c } from "global-style";
import {
    forwardRef,
    Ref,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import { Company } from "company/types";
import {
    suggestedAllowanceAmountForCheckout,
    calculateSuggestedAllowance,
} from "utils/checkout";
import { toDollar } from "utils/financial";
import Button, { ButtonVariants } from "components/Button";
import Tooltip from "components/Tooltip";
import Info from "components/icons/Info";
import Anchor from "components/Anchor";
import InputWithAddon from "components/InputWithAddon";

export interface ItemsCheckoutSuggestedAllowanceFormProps {
    disabled?: boolean;
    items: Company.Item[];
    minimumBalanceRequired: number;
    onChange: (minimumAllowance: number) => void;
}

export type ItemsCheckoutSuggestedAllowanceFormRef = {
    suggestedAllowance: number;
    wasEdited: boolean;
};

function ItemsCheckoutSuggestedAllowanceForm(
    {
        disabled = false,
        items,
        minimumBalanceRequired,
        onChange,
    }: ItemsCheckoutSuggestedAllowanceFormProps,
    ref: Ref<ItemsCheckoutSuggestedAllowanceFormRef>
) {
    // Is the form being edited?
    const [showSuggestedAllowanceForm, setShowSuggestedAllowanceForm] =
        useState<boolean>(false);

    const [defaultValue, setDefaultValue] = useState<number>(
        calculateSuggestedAllowance(undefined, items, minimumBalanceRequired, 0)
    );

    const [suggestedAllowance, setSuggestedAllowance] =
        useState<number>(defaultValue);

    const [wasEdited, setWasEdited] = useState<boolean>(
        suggestedAllowance !== defaultValue
    );

    // Input props that affect the calculation have been updated
    useEffect(() => {
        // Recalculate the new default suggested allowance
        const newDefaultSuggestedAllowance = calculateSuggestedAllowance(
            undefined,
            items,
            minimumBalanceRequired,
            0
        );
        setDefaultValue(newDefaultSuggestedAllowance);

        // If the suggested allowance was previously edited (ie, defaultSpendingCap query param):
        //   use that to recalculate the new suggested allowance
        //   else assign it the new default suggested allowance
        setSuggestedAllowance((prevSuggestedAllowance) => {
            return wasEdited
                ? calculateSuggestedAllowance(
                      undefined,
                      items,
                      minimumBalanceRequired,
                      prevSuggestedAllowance
                  )
                : newDefaultSuggestedAllowance;
        });
    }, [items, minimumBalanceRequired, wasEdited]);

    // The suggested allowance has been edited from its default
    useEffect(() => {
        setWasEdited(suggestedAllowance !== defaultValue);
    }, [defaultValue, suggestedAllowance]);

    // Update the parent component with the new suggested allowance
    useEffect(() => {
        onChange(suggestedAllowance);
    }, [onChange, suggestedAllowance]);

    useImperativeHandle(
        ref,
        () => ({
            suggestedAllowance,
            wasEdited,
        }),
        [suggestedAllowance, wasEdited]
    );

    const updateSuggestedAllowance = (
        event: React.FormEvent<HTMLFormElement>
    ) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const values = Object.fromEntries(formData.entries());

        const proposedSuggestedAllowance = calculateSuggestedAllowance(
            undefined,
            items,
            minimumBalanceRequired,
            Number(values.suggestedAllowance) * 100
        );
        setSuggestedAllowance(proposedSuggestedAllowance);
        setShowSuggestedAllowanceForm(false);
    };

    // The "checkout equivalent" (ie: slippage included)
    const checkoutSuggestedAllowance =
        suggestedAllowanceAmountForCheckout(suggestedAllowance);

    return (
        <S.AmountSetting>
            <Tooltip title="Wallets set an authorization that allows Loop to transfer funds. Loop will suggest this amount. To get the customer through fast, this amount is only used if the wallet does not have enough to complete checkout.">
                <S.AmountSettingHeader>
                    <Info
                        height="1rem"
                        width="1rem"
                        fill={c.popoverCloseLink}
                    />
                    Suggested authorization:
                </S.AmountSettingHeader>
            </Tooltip>
            {showSuggestedAllowanceForm ? (
                <S.EditForm onSubmit={updateSuggestedAllowance}>
                    <InputWithAddon
                        name="suggestedAllowance"
                        value={suggestedAllowance / 100}
                        type="number"
                        step={0.01}
                        addon={{
                            content: "$",
                            position: "left",
                        }}
                    />
                    <Button type="submit" disabled={disabled}>
                        {disabled ? "Saving" : "Save"}
                    </Button>
                    <Button
                        variant={ButtonVariants.Anchor}
                        type="button"
                        disabled={disabled}
                        onClick={() => setShowSuggestedAllowanceForm(false)}
                    >
                        Cancel
                    </Button>
                </S.EditForm>
            ) : (
                <S.AmountSettingsValue>
                    {toDollar(checkoutSuggestedAllowance)}{" "}
                    {wasEdited && <S.AmountEdited>Edited</S.AmountEdited>}
                    <Anchor
                        underlined={false}
                        onClick={() => setShowSuggestedAllowanceForm(true)}
                    >
                        Edit
                    </Anchor>
                </S.AmountSettingsValue>
            )}
        </S.AmountSetting>
    );
}

export default forwardRef(ItemsCheckoutSuggestedAllowanceForm);
