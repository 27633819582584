import * as React from "react";
import { useRef, useEffect } from "react";
import * as S from "./styles";

export interface CheckboxProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    checked?: boolean;
    indeterminate?: boolean;
    name?: string;
    value?: string;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    onInput?: React.ChangeEventHandler<HTMLInputElement>;
    disabled?: boolean;
}

export const Checkbox: React.FunctionComponent<CheckboxProps> = ({
    checked,
    indeterminate = false,
    name,
    value,
    onBlur,
    onChange,
    onFocus,
    onInput,
    disabled = false,
    ...props
}) => {
    const checkboxRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (checkboxRef && checkboxRef.current) {
            checkboxRef.current.indeterminate = indeterminate;
        }
    }, [indeterminate]);

    const handleOnBlur: React.FocusEventHandler<HTMLInputElement> = (
        event: React.FocusEvent<HTMLInputElement>
    ) => {
        if (onBlur) {
            onBlur(event);
        }
    };

    const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (onChange) {
            onChange(event);
        }
    };

    const handleOnFocus: React.FocusEventHandler<HTMLInputElement> = (
        event: React.FocusEvent<HTMLInputElement>
    ) => {
        if (onFocus) {
            onFocus(event);
        }
    };

    const handleOnInput: React.FocusEventHandler<HTMLInputElement> = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (onInput) {
            onInput(event);
        }
    };

    return (
        <S.Checkbox
            {...props}
            name={name}
            type="checkbox"
            checked={checked}
            value={value}
            ref={checkboxRef}
            id={name}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            onFocus={handleOnFocus}
            onInput={handleOnInput}
            disabled={disabled}
        />
    );
};

export default Checkbox;
