import { Customer } from "customer/types";
import { useEffect, useState } from "react";

interface EntityFamily {
    parent: string;
    children: string[];
}
interface EntityFamilies {
    (entities: Customer.Entity[]): EntityFamily[];
}

const useEntityFamilies: EntityFamilies = (entities) => {
    const [families, setFamilies] = useState<EntityFamily[]>([]);

    useEffect(() => {
        if (!entities) return;

        // Divide entities into roles (parents and children)
        const roles = entities.reduce<{
            parents: EntityFamily[];
            children: Customer.Entity[];
        }>(
            (arr, entity) => {
                if (entity.parentId) {
                    arr.children.push(entity);
                } else {
                    arr.parents.push({ parent: entity.entityId, children: [] });
                }
                return arr;
            },
            { parents: [], children: [] }
        );

        // Assign the children and store the families
        setFamilies(
            roles.children.reduce<EntityFamily[]>(
                (allFamilies, { entityId, parentId }) => {
                    // Add child to parent OR this child is an orphan so make it a parent
                    const family = allFamilies.find(
                        ({ parent }) => parent === parentId
                    );

                    if (family) family.children.push(entityId);
                    else allFamilies.push({ parent: entityId, children: [] });

                    return allFamilies;
                },
                [...roles.parents]
            )
        );
    }, [entities]);

    return families;
};

export { useEntityFamilies };
