import * as S from "./style";
import safeLogo from "assets/img/logos/safe-wallet.svg";
import safeLogoWhite from "assets/img/logos/safe-wallet-white.svg";
import Anchor from "components/Anchor";
import Tooltip from "components/Tooltip";

export enum SafeLanguageVariant {
    Customer = "customer",
    Company = "company",
    Checkout = "checkout",
}

const WalletConnectMultiSig = ({
    variant = SafeLanguageVariant.Customer,
    ...props
}: {
    variant?: SafeLanguageVariant;
}) => {
    return (
        <S.SafeMultisig {...props}>
            <Tooltip
                title={
                    <S.SafeTip>
                        <p>
                            To connect and manage your{" "}
                            <S.SafeLogo
                                src={safeLogoWhite}
                                alt="Safe (wallet)"
                            />{" "}
                            Safe multisig wallet:
                        </p>
                        {variant === SafeLanguageVariant.Customer ? (
                            <ol>
                                <li>
                                    Connect your EOA (externally owned account)
                                    and sign the authentication message sent to
                                    your wallet.
                                </li>
                                <li>
                                    Ensure you are connected to the EOA wallet
                                    and network that matches your multisig
                                    wallet.
                                </li>
                                <li>
                                    Click the wallet address dropdown (in the
                                    top-right corner) and select the Safe
                                    multisig wallets you wish to manage.
                                </li>
                            </ol>
                        ) : (
                            <ol>
                                <li>
                                    Connect your EOA (externally owner account)
                                    that is one of the signers for the Safe.
                                </li>
                                <li>
                                    Ensure you are on the network that matches
                                    your multisig wallet and that you have
                                    connected the correct EOA signer wallet.
                                </li>
                                <li>
                                    Once the EOA is connected, you will see the
                                    address (or addesses) of any Safe multisigs
                                    that this EOA is a signer on. Click on the
                                    address of the Safe multsig that you wish to
                                    use.
                                </li>
                            </ol>
                        )}
                        {variant === SafeLanguageVariant.Customer && (
                            <p>
                                Read more about{" "}
                                <Anchor
                                    href={import.meta.env.VITE_SAFE_CUSTOMER}
                                >
                                    using Loop with your multisig wallet
                                </Anchor>{" "}
                                in our user guide
                            </p>
                        )}
                    </S.SafeTip>
                }
                placement="bottom"
                abbr
                noWrap
            >
                Want to use a{" "}
                <Anchor
                    href="https://safe.global/"
                    underlined={false}
                    inheritColor={true}
                >
                    <S.SafeLogo src={safeLogo} alt="Safe (wallet)" />
                    Safe
                </Anchor>{" "}
                multisig wallet?
            </Tooltip>
        </S.SafeMultisig>
    );
};

export default WalletConnectMultiSig;
