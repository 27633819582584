import React from "react";

const Contact = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 17"
            {...props}
        >
            {name && <title>{name}</title>}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 .13H2c-1.1 0-1.99.9-1.99 2l-.01 12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2Zm-16 4 8 5 8-5v10H2v-10Zm0-2 8 5 8-5H2Z"
                fill="inherit"
            />
        </svg>
    );
};

export default Contact;
