import * as S from "./style";

interface SpinnerProps {
    desaturate?: boolean;
    color?: string;
    className?: string;
}

const Spinner = ({ desaturate = false, color, className }: SpinnerProps) => {
    return (
        <S.Spinner
            desaturate={desaturate}
            color={color}
            className={className}
        />
    );
};

export default Spinner;
