import Field from "components/Field";
import Label from "components/Label";
import styled from "styled-components";

export const AcceptedTokensField = styled(Field)`
    display: flex;
    width: 100%;
    gap: 1rem;
    align-items: baseline;
`;

export const AcceptedTokensInputWrapper = styled.div`
    width: 100%;
`;

export const AcceptedTokensLabel = styled(Label)`
    width: 100%;
    max-width: 7rem;
    margin: 0 !important;
`;
