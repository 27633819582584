import * as S from "./style";
import {
    memo,
    useMemo,
    useCallback,
    Dispatch,
    SetStateAction,
    useState,
    useEffect,
} from "react";
import {
    Company,
    CompanyAgreementResponse,
    ItemSourceType,
} from "company/types";
import { ItemCategoryTypeInbound, AgreementType } from "types/common-enums";
import { firstToUpper } from "utils/strings";
import Select, { SelectSizes } from "components/Select";
import Label from "components/Label";
import Field from "components/Field";

interface AgreementItem {
    id: string;
    name: string;
    entity: string;
    agreements: string[];
}

interface UniqueEntity {
    id: string;
    name: string;
    isParent: boolean;
}

interface AgreementItemsProps {
    items: Company.Item[];
    agreements: CompanyAgreementResponse[];
    entities: Company.Entity[];
    value?: string;
    setValue: Dispatch<SetStateAction<Company.Item | undefined>>;
    disabled?: boolean;
}

const AgreementItems = memo(
    ({
        items,
        agreements,
        entities,
        value,
        setValue,
        disabled = false,
    }: AgreementItemsProps) => {
        const [parentEntity, setParentEntity] = useState<string>(``);

        const { agreementItems, parentEntities } = useMemo(
            () =>
                items.reduce<{
                    agreementItems: AgreementItem[];
                    parentEntities: UniqueEntity[];
                }>(
                    (agrmntItms, item) => {
                        // If item is not active, not "inbound", or not a "Loop" item, skip it
                        if (
                            !item.active ||
                            !ItemCategoryTypeInbound.includes(item.type) ||
                            item.sourceId !== ItemSourceType.Loop
                        )
                            return agrmntItms;
                        // Find the active agreements this item belongs to
                        const agrmnts = agreements
                            .filter(
                                (agrmnt) =>
                                    agrmnt.items.includes(item.id) &&
                                    agrmnt.status !==
                                        AgreementType[AgreementType.Canceled] &&
                                    agrmnt.type !== `Static`
                            )
                            .map(({ id }) => id);
                        if (agrmnts.length < 1) return agrmntItms;

                        // If the entity is not already in the list, add it
                        if (
                            !agrmntItms.parentEntities.some(
                                ({ id }) => id === item.entityId
                            )
                        ) {
                            const ent = entities.find(
                                ({ entityId }) => entityId === item.entityId
                            );
                            if (ent) {
                                agrmntItms.parentEntities.push({
                                    id: ent.entityId,
                                    name: ent.name,
                                    isParent: !ent.parentId,
                                });
                            }
                        }

                        return {
                            agreementItems: [
                                ...agrmntItms.agreementItems,
                                {
                                    id: item.id,
                                    name: item.name,
                                    entity: item.entityId,
                                    agreements: agrmnts,
                                },
                            ],
                            parentEntities: agrmntItms.parentEntities,
                        };
                    },
                    {
                        agreementItems: [],
                        parentEntities: [],
                    }
                ),
            [items, agreements, entities]
        );

        // Reset parent if item is updated from outside the component (via link)
        useEffect(() => {
            if (!value) return;
            setParentEntity(
                items.find(({ id }) => id === value)?.entityId || ``
            );
        }, [value, items]);

        const parentEntitiesSorted: UniqueEntity[] = useMemo(() => {
            if (parentEntities.length < 1) return [];
            const sorted = parentEntities.sort((a, b) =>
                a.isParent ? -1 : b.isParent ? 1 : a.name < b.name ? -1 : 1
            );
            return sorted;
        }, [parentEntities]);

        // Auto-select parent entity if only one exists
        useEffect(() => {
            if (parentEntitiesSorted.length === 1)
                setParentEntity(parentEntitiesSorted[0].id);
        }, [parentEntitiesSorted, items, setValue]);

        const agreementItemsSorted: AgreementItem[] = useMemo(() => {
            if (agreementItems.length < 1) return [];
            const sorted = agreementItems
                .filter(({ entity }) => entity === parentEntity)
                .sort((a, b) => (a.name < b.name ? -1 : 1));

            return sorted;
        }, [agreementItems, parentEntity]);

        // Auto-select item if only one exists
        useEffect(() => {
            if (agreementItemsSorted.length === 1)
                setValue(
                    items.find(({ id }) => id === agreementItemsSorted[0].id) ||
                        undefined
                );
        }, [agreementItemsSorted, items, setValue]);

        const handleEntityChange = useCallback(
            (event: any) => {
                setParentEntity(event.target.value);
                setValue(undefined);
            },
            [setParentEntity, setValue]
        );

        const handleItemChange = useCallback(
            (event: any) => {
                setValue(items.find(({ id }) => id === event.target.value));
            },
            [setValue, items]
        );

        return (
            <S.EntityItem>
                {parentEntitiesSorted.length > 1 && (
                    <Field>
                        <Label htmlFor="parentEntities">Entity</Label>
                        <Select
                            name="parentEntities"
                            value={parentEntity || ``}
                            disabled={disabled}
                            onChange={handleEntityChange}
                            size={SelectSizes.Large}
                            placeholder={
                                parentEntitiesSorted.length > 1 && !parentEntity
                                    ? {
                                          value: ``,
                                          label: "Select an entity account to get started",
                                      }
                                    : undefined
                            }
                            options={parentEntitiesSorted.map(
                                ({ id, name, isParent }) => ({
                                    value: id,
                                    label: `${firstToUpper(name)}${
                                        !isParent &&
                                        parentEntitiesSorted.length > 1
                                            ? ` (child)`
                                            : ``
                                    }`,
                                })
                            )}
                        />
                    </Field>
                )}

                <Field>
                    <Label htmlFor="agreementItems">Item</Label>
                    <Select
                        name="agreementItems"
                        value={value || ``}
                        disabled={disabled || !parentEntity}
                        onChange={handleItemChange}
                        size={SelectSizes.Large}
                        placeholder={
                            agreementItemsSorted.length > 1 && !value
                                ? {
                                      value: ``,
                                      label: `Select one of ${agreementItemsSorted.length} available items to invoice`,
                                  }
                                : undefined
                        }
                        options={agreementItemsSorted.map(
                            ({ id, name, agreements: { length: count } }) => ({
                                value: id,
                                label: `${firstToUpper(
                                    name
                                )} (${count} customer${
                                    count === 1 ? `` : `s`
                                })`,
                            })
                        )}
                    />
                </Field>
            </S.EntityItem>
        );
    }
);

export default AgreementItems;
