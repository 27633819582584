import { FunctionComponent } from "react";
import * as S from "./style";
import FailedDataFetchingMessage from "components/FailedDataFetchingMessage";
import LoadingBox from "components/LoadingBox";
import CrossedDollar from "components/icons/CrossedDollar";
import InboundTreasuriesNetwork from "../InboundTreasuriesNetwork";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";

interface InboundTreasuriesInfoPanelProps {}

const InboundTreasuriesInfoPanel: FunctionComponent<InboundTreasuriesInfoPanelProps> =
    () => {
        const { networks, getNetworksIsLoading, getNetworksIsError } =
            useGetNetworks();

        const {
            config: { entities },
            getCompanyConfigIsLoading,
            getCompanyConfigIsError,
        } = useGetCompanyConfig();

        const isLoading = getNetworksIsLoading || getCompanyConfigIsLoading;
        const isError = getNetworksIsError || getCompanyConfigIsError;

        const entity = entities.find((entity) => !entity.parentId);
        const inboundTreasuries = entity && entity.inboundTreasuries;

        // Loading state
        if (isLoading) return <LoadingBox height="10rem" />;

        // Error state
        if (isError) return <FailedDataFetchingMessage />;

        // No treasury setup
        if (!inboundTreasuries || Object.keys(inboundTreasuries).length === 0) {
            return (
                <S.InboundTreasuriesInfoPanel>
                    <CrossedDollar
                        fill="inherit"
                        height="1.25rem"
                        width="1.25rem"
                        name="Pay"
                    />
                    <S.TreasuriesWrapper>
                        <p>You have no receiving wallets configured.</p>
                        <p>
                            You can only charge for items with manual billing.
                        </p>
                    </S.TreasuriesWrapper>
                </S.InboundTreasuriesInfoPanel>
            );
        }

        const inboundTreasuriesNetworkIds = Object.keys(inboundTreasuries).map(
            (n) => Number(n)
        );

        return (
            <S.InboundTreasuriesInfoPanel>
                <S.TreasuriesWrapper>
                    {inboundTreasuriesNetworkIds.map((networkId) => (
                        <li key={networkId}>
                            <InboundTreasuriesNetwork
                                key={networkId}
                                networkId={networkId}
                                networks={networks}
                                inboundTreasuries={inboundTreasuries}
                            />
                        </li>
                    ))}
                </S.TreasuriesWrapper>
            </S.InboundTreasuriesInfoPanel>
        );
    };

export default InboundTreasuriesInfoPanel;
