import * as S from "./style";
import { colorsDeprecated as c } from "global-style";
import { useId } from "react";

interface LogoProps {
    tall?: boolean;
    lg?: boolean;
    full?: boolean;
    height?: string;
    theme?: `dark` | `light`; // Refers to the site's theme, not the logo itself
    href?: string;
    target?: string;
}

const Logo = ({
    tall = false,
    lg = false,
    full = false,
    height,
    theme = `light`,
    href = import.meta.env.VITE_LOOP_WEBSITE || `https://loopcrytpo.xyz/`,
    target = "",
    ...props
}: LogoProps) => {
    const gradientId = useId();

    /* RP: LC-657
    This is a temporary solution to theming our application. Similar temporary
    code exists in other files, search "LC-657" to find other instances */
    const loop = theme === `light` ? `#4A3AF8` : c.loopColB;
    const crypto = theme === `light` ? `#231F20` : `#fff`;
    const bigL =
        theme === `light` ? (
            <>
                <stop stopColor="#5AA9F0" />
                <stop offset="0.11" stopColor="#5485F2" />
                <stop offset="0.25" stopColor="#4F5CF5" />
                <stop offset="0.36" stopColor="#4B43F7" />
                <stop offset="0.43" stopColor="#4A3AF8" />
            </>
        ) : (
            <>
                <stop stopColor="#56A0EC" />
                <stop offset="0.12" stopColor="#6693F0" />
                <stop offset="0.35" stopColor="#7D81F6" />
                <stop offset="0.48" stopColor="#867BF9" />
            </>
        );

    return (
        <S.LogoLink href={href} {...props}>
            {tall ? (
                <S.Logo
                    role="img"
                    tall={tall}
                    lg={lg}
                    full={full}
                    height={height}
                    viewBox="0 0 659 380"
                    fill="none"
                    name="Loop Crypto"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <title>Loop Crypto</title>
                    <path
                        d="M301.09 351.515C282.22 351.515 267.21 337.055 267.21 315.425C267.21 293.795 281.81 279.335 300.54 279.335C316.1 279.335 328.91 289.525 331.67 303.575L319.55 307.435C318.45 297.515 310.6 290.085 300.54 290.085C288.42 290.085 279.05 300.555 279.05 315.425C279.05 330.295 288.42 340.495 300.54 340.495C311.01 340.495 319.27 333.335 320.1 322.315L332.77 326.175C330.29 341.055 317.07 351.515 301.09 351.515Z"
                        fill={crypto}
                    />
                    <path
                        d="M346.41 280.995H355.78L357.16 292.425C360.47 284.435 367.08 279.345 375.07 279.345C377.82 279.345 380.44 280.035 382.78 280.995L382.23 293.945C379.48 292.295 376.17 291.745 373.97 291.745C364.33 291.745 357.44 299.455 357.44 310.205V349.875H346.42V281.005L346.41 280.995Z"
                        fill={crypto}
                    />
                    <path
                        d="M405.91 280.995L426.02 333.885L444.75 280.995H457.42L427.12 359.775C422.85 370.795 415.27 377.955 405.91 379.055L402.88 367.485C408.94 366.935 413.62 363.905 416.1 357.565L419.96 347.645L392.96 280.985H405.91V280.995Z"
                        fill={crypto}
                    />
                    <path
                        d="M478.63 338.295V377.415H467.61V281.005H476.98L478.36 292.575C483.59 284.315 492.13 279.355 502.88 279.355C521.34 279.355 535.66 294.235 535.66 315.165C535.66 336.095 520.65 351.525 502.88 351.525C492.55 351.525 484.01 346.565 478.64 338.305L478.63 338.295ZM501.22 340.495C514.44 340.495 524.08 330.025 524.08 315.425C524.08 300.825 514.44 290.085 501.22 290.085C488 290.085 478.36 300.695 478.36 315.425C478.36 330.155 488 340.495 501.22 340.495Z"
                        fill={crypto}
                    />
                    <path
                        d="M542.27 280.995H546.68C550.95 280.995 554.12 277.415 554.12 272.455V263.365H564.59V280.995H579.74V291.735H564.59V331.675C564.59 336.775 568.31 340.215 573.96 340.215C575.89 340.215 578.09 339.935 579.74 339.385L580.02 350.405C577.82 351.095 574.92 351.505 572.31 351.505C561.15 351.505 553.58 344.485 553.58 333.875V291.725H542.29V280.985L542.27 280.995Z"
                        fill={crypto}
                    />
                    <path
                        d="M589.93 315.435C589.93 294.495 604.39 279.345 624.36 279.345C644.33 279.345 658.79 294.495 658.79 315.435C658.79 336.375 644.33 351.525 624.36 351.525C604.39 351.525 589.93 336.375 589.93 315.435ZM624.36 340.505C637.58 340.505 647.22 330.035 647.22 315.435C647.22 300.835 637.58 290.095 624.36 290.095C611.14 290.095 601.5 300.705 601.5 315.435C601.5 330.165 611.14 340.505 624.36 340.505Z"
                        fill={crypto}
                    />
                    <path
                        d="M19.28 256.205V349.865H0V256.205H19.28Z"
                        fill={loop}
                    />
                    <path
                        d="M29.75 315.435C29.75 294.495 44.49 279.345 64.73 279.345C84.97 279.345 99.71 294.495 99.71 315.435C99.71 336.375 84.97 351.525 64.73 351.525C44.49 351.525 29.75 336.375 29.75 315.435ZM64.73 333.065C73.55 333.065 79.88 325.625 79.88 315.295C79.88 304.965 73.54 297.525 64.73 297.525C55.92 297.525 49.58 304.965 49.58 315.295C49.58 325.625 55.92 333.065 64.73 333.065Z"
                        fill={loop}
                    />
                    <path
                        d="M107.71 315.435C107.71 294.495 122.45 279.345 142.69 279.345C162.93 279.345 177.67 294.495 177.67 315.435C177.67 336.375 162.93 351.525 142.69 351.525C122.45 351.525 107.71 336.375 107.71 315.435ZM142.69 333.065C151.51 333.065 157.84 325.625 157.84 315.295C157.84 304.965 151.5 297.525 142.69 297.525C133.88 297.525 127.54 304.965 127.54 315.295C127.54 325.625 133.88 333.065 142.69 333.065Z"
                        fill={loop}
                    />
                    <path
                        d="M206.88 340.915V377.415H187.6V281.005H202.48L205.79 289.825C210.75 283.215 218.05 279.355 226.73 279.355C244.08 279.355 256.76 294.365 256.76 314.895C256.76 335.425 244.5 351.535 227.84 351.535C219.44 351.535 212.14 347.545 206.9 340.925L206.88 340.915ZM222.03 332.785C230.85 332.785 237.18 325.485 237.18 315.295C237.18 305.105 230.84 297.805 222.03 297.805C213.22 297.805 206.88 305.105 206.88 315.295C206.88 325.485 213.22 332.785 222.03 332.785Z"
                        fill={loop}
                    />
                    <path
                        d="M394.29 153.045H330.9L345.56 139.415L339.82 133.255L312.69 158.485L339.82 183.705L345.56 177.545L330.89 163.915H394.29C400.52 163.915 405.59 168.985 405.59 175.215V198.555H300.49V23.2753C300.49 11.1553 290.62 1.21531 278.49 1.11531L249.48 0.945312C247.56 0.965312 245.77 1.69531 244.45 3.00531C243.1 4.34531 242.35 6.13531 242.35 8.04531V156.805C242.35 160.725 245.54 163.905 249.45 163.905H277.83V153.045H253.2V11.8253L278.4 11.9753C284.58 12.0253 289.61 17.0953 289.61 23.2753V202.305C289.61 206.225 292.8 209.405 296.71 209.405H409.32C413.24 209.405 416.42 206.215 416.42 202.305V175.205C416.42 162.985 406.48 153.045 394.26 153.045H394.29Z"
                        fill={`url(#${gradientId})`}
                    />
                    <defs>
                        <linearGradient
                            id={gradientId}
                            x1="242.35"
                            y1="105.175"
                            x2="416.45"
                            y2="105.175"
                            gradientUnits="userSpaceOnUse"
                        >
                            {bigL}
                        </linearGradient>
                    </defs>
                </S.Logo>
            ) : (
                <S.Logo
                    role="img"
                    tall={tall}
                    lg={lg}
                    full={full}
                    height={height}
                    viewBox="0 0 182 29"
                    fill="none"
                    name="Loop Crypto"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <title>Loop Crypto</title>
                    <path
                        d="M102.708 23.2982C98.5256 23.2982 95.1975 20.3048 95.1975 15.8273C95.1975 11.3498 98.4335 8.35638 102.587 8.35638C106.038 8.35638 108.876 10.4661 109.487 13.3752L106.8 14.1729C106.556 12.1205 104.815 10.5808 102.587 10.5808C99.8998 10.5808 97.8231 12.7479 97.8231 15.8273C97.8231 18.9067 99.8998 21.0164 102.587 21.0164C104.907 21.0164 106.738 19.5341 106.923 17.2523L109.732 18.0499C109.183 21.1294 106.253 23.2964 102.71 23.2964L102.708 23.2982Z"
                        fill={crypto}
                    />
                    <path
                        d="M112.753 8.69874H114.83L115.135 11.0648C115.868 9.41034 117.333 8.35638 119.104 8.35638C119.715 8.35638 120.294 8.49978 120.814 8.69874L120.692 11.3784C120.081 11.0361 119.348 10.9214 118.861 10.9214C116.723 10.9214 115.197 12.5184 115.197 14.7429V22.9541H112.753V8.69874Z"
                        fill={crypto}
                    />
                    <path
                        d="M125.943 8.69876L130.402 19.6488L134.553 8.69876H137.363L130.645 25.0082C129.699 27.29 128.02 28.7724 125.943 29L125.271 26.6053C126.615 26.4906 127.653 25.8632 128.202 24.553L129.056 22.5006L123.072 8.70055H125.941L125.943 8.69876Z"
                        fill={crypto}
                    />
                    <path
                        d="M142.065 20.5612V28.6594H139.622V8.69874H141.699L142.004 11.0935C143.163 9.38346 145.057 8.35638 147.439 8.35638C151.531 8.35638 154.706 11.4358 154.706 15.7699C154.706 20.1041 151.378 23.2982 147.439 23.2982C145.149 23.2982 143.257 22.2711 142.065 20.5612ZM147.073 21.0182C150.003 21.0182 152.141 18.8512 152.141 15.8291C152.141 12.807 150.003 10.5826 147.073 10.5826C144.142 10.5826 142.004 12.7784 142.004 15.8291C142.004 18.8798 144.142 21.0182 147.073 21.0182Z"
                        fill={crypto}
                    />
                    <path
                        d="M156.17 8.69877H157.147C158.093 8.69877 158.796 7.9567 158.796 6.93142V5.04935H161.116V8.69877H164.475V10.9232H161.116V19.1918C161.116 20.2475 161.942 20.9591 163.193 20.9591C163.621 20.9591 164.108 20.9017 164.475 20.7888L164.536 23.0706C164.047 23.214 163.406 23.2982 162.826 23.2982C160.352 23.2982 158.675 21.8446 158.675 19.6488V10.9232H156.172V8.69877H156.17Z"
                        fill={crypto}
                    />
                    <path
                        d="M166.734 15.8273C166.734 11.4932 169.939 8.35638 174.367 8.35638C178.795 8.35638 182 11.4932 182 15.8273C182 20.1614 178.795 23.2982 174.367 23.2982C169.939 23.2982 166.734 20.1614 166.734 15.8273ZM174.367 21.0164C177.298 21.0164 179.436 18.8494 179.436 15.8273C179.436 12.8052 177.298 10.5808 174.367 10.5808C171.436 10.5808 169.298 12.7766 169.298 15.8273C169.298 18.878 171.436 21.0164 174.367 21.0164Z"
                        fill={crypto}
                    />
                    <path
                        d="M40.2382 3.56699V22.9559H35.9639V3.56699H40.2382Z"
                        fill={loop}
                    />
                    <path
                        d="M42.5588 15.8273C42.5588 11.4932 45.8255 8.35638 50.3147 8.35638C54.804 8.35638 58.0707 11.4932 58.0707 15.8273C58.0707 20.1614 54.804 23.2982 50.3147 23.2982C45.8255 23.2982 42.5588 20.1614 42.5588 15.8273ZM50.3147 19.4767C52.2686 19.4767 53.6735 17.937 53.6735 15.7986C53.6735 13.6602 52.2686 12.1205 50.3147 12.1205C48.3609 12.1205 46.956 13.6602 46.956 15.7986C46.956 17.937 48.3609 19.4767 50.3147 19.4767Z"
                        fill={loop}
                    />
                    <path
                        d="M59.8401 15.8273C59.8401 11.4932 63.1067 8.35638 67.596 8.35638C72.0853 8.35638 75.3519 11.4932 75.3519 15.8273C75.3519 20.1614 72.0853 23.2982 67.596 23.2982C63.1067 23.2982 59.8401 20.1614 59.8401 15.8273ZM67.596 19.4767C69.5499 19.4767 70.9548 17.937 70.9548 15.7986C70.9548 13.6602 69.5499 12.1205 67.596 12.1205C65.6421 12.1205 64.2372 13.6602 64.2372 15.7986C64.2372 17.937 65.6421 19.4767 67.596 19.4767Z"
                        fill={loop}
                    />
                    <path
                        d="M81.8236 21.1025V28.6594H77.5493V8.69874H80.8467L81.5799 10.5235C82.6796 9.15402 84.2976 8.35638 86.2207 8.35638C90.067 8.35638 92.8769 11.4645 92.8769 15.7126C92.8769 19.9607 90.1592 23.2964 86.4645 23.2964C84.6028 23.2964 82.9848 22.4701 81.8236 21.1007V21.1025ZM85.1824 19.4194C87.1362 19.4194 88.5412 17.9083 88.5412 15.7986C88.5412 13.6889 87.1362 12.1779 85.1824 12.1779C83.2285 12.1779 81.8236 13.6889 81.8236 15.7986C81.8236 17.9083 83.2285 19.4194 85.1824 19.4194Z"
                        fill={loop}
                    />
                    <path
                        d="M20.9089 19.5484H12.1857L14.2029 17.7972L13.4121 17.0049L9.67905 20.2475L13.4121 23.4882L14.2029 22.696L12.1837 20.9429H20.9089C21.7668 20.9429 22.4635 21.5936 22.4635 22.3948V25.3954H7.99966V2.86971C7.99966 1.31207 6.64271 0.0340565 4.97292 0.0233018L0.980765 0C0.715901 0.00179245 0.47023 0.0967921 0.287896 0.265282C0.101723 0.437357 0 0.668583 0 0.912355V20.0306C0 20.5343 0.437602 20.9429 0.976926 20.9429H4.88271V19.5466H1.49514V1.39811L4.96332 1.41783C5.81357 1.4232 6.50644 2.07565 6.50644 2.86971V25.8793C6.50644 26.383 6.94405 26.7917 7.48337 26.7917H22.9798C23.5192 26.7917 23.9568 26.383 23.9568 25.8793V22.3966C23.9568 20.8264 22.5883 19.5484 20.907 19.5484H20.9089Z"
                        fill={`url(#${gradientId})`}
                    />
                    <defs>
                        <linearGradient
                            id={gradientId}
                            x1="0"
                            y1="13.3967"
                            x2="23.9587"
                            y2="13.3967"
                            gradientUnits="userSpaceOnUse"
                        >
                            {bigL}
                        </linearGradient>
                    </defs>
                </S.Logo>
            )}
        </S.LogoLink>
    );
};

export default Logo;
