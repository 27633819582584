import styled, { css } from "styled-components";
import DescriptionList from "components/DescriptionList";
import colors from "theme/colors";

export const MinimumBalanceRequiredDescriptionList = styled(DescriptionList)<{
    hasSufficientBalance: boolean;
}>`
    margin-block: 0.25rem;
    ${({ hasSufficientBalance }) =>
        !hasSufficientBalance &&
        css`
            color: ${colors.error};
        `}
`;
