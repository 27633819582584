//import * as S from './style'
import Button from "components/Button";
import { useWallet } from "context/Wallet";

const SubmitButton = ({ children, type, canSubmit, ...props }) => {
    const { walletConnected, handleConnectWallet, isWalletConnecting } =
        useWallet();

    return (
        <>
            {walletConnected ? (
                <Button
                    type={type || `submit`}
                    disabled={!canSubmit}
                    {...props}
                >
                    {children}
                </Button>
            ) : (
                <Button
                    type={type || `submit`}
                    title="Connect wallet"
                    onClick={() => handleConnectWallet()}
                    disabled={isWalletConnecting}
                    {...props}
                >
                    {isWalletConnecting ? "Connecting..." : "Connect wallet"}
                </Button>
            )}
        </>
    );
};

export default SubmitButton;
