import styled, { css } from "styled-components";
import { colorsDeprecated as c, properties as p } from "global-style";
import Input, { InputSizes } from "components/Input";
import { fontSizes, lineHeights } from "theme/typography";
import spacing from "theme/spacing";

export const LabelWrapper = styled.label`
    position: relative;
    display: inline-block;
    width: 100%;
`;

const addonPaddingForInputSize = (size: InputSizes = InputSizes.Medium) => {
    switch (size) {
        case InputSizes.Small:
            return css`
                padding: ${spacing.xxxs} ${spacing.xxxs};
                font-size: ${fontSizes.xs};
                line-height: ${lineHeights.md};
            `;
        case InputSizes.Medium:
            return css`
                padding: ${spacing.xxs} ${spacing.xxs};
                font-size: ${fontSizes.sm};
                line-height: ${lineHeights.xl};
            `;
        case InputSizes.Large:
            return css`
                padding: ${spacing.xs} ${spacing.xs};
                font-size: ${fontSizes.md};
                line-height: ${lineHeights.xl};
            `;
    }
};

export const AddonContainer = styled.span<{
    position: "left" | "right";
    $inputSize?: InputSizes;
}>`
    position: absolute;
    z-index: ${p.zIndex.inputIcon};
    display: flex;
    ${({ $inputSize }) => addonPaddingForInputSize($inputSize)};
    text-align: center;
    justify-content: center;
    /* top: calc(50%);
    transform: translateY(-50%); */
    top: 0;

    font-weight: 600;
    color: ${c.lightOnSurface};
    ${({ position }) => {
        switch (position) {
            case "left":
                return css`
                    left: 0;
                `;
            case "right":
                return css`
                    right: 0;
                `;
        }
    }};

    input:read-only + & {
        pointer-events: none;
    }
`;

export const InputComponent = styled(Input)<{
    $addonPosition: "left" | "right";
    $addonWidth: number;
}>`
    ${({ $addonWidth, $addonPosition }) => {
        switch ($addonPosition) {
            case "left":
                return css`
                    padding-left: ${$addonWidth}px;
                `;
            case "right":
                return css`
                    padding-right: ${$addonWidth}px;
                    &:invalid {
                        background-position: calc(100% - ${$addonWidth}px)
                            center !important;
                        padding-right: calc(
                            1.5rem + ${$addonWidth}px
                        ) !important;
                    }
                `;
        }
    }}
`;
