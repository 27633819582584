import { Navigate } from "react-router-dom";
import { getBooleanFromFnOrBool } from "utils/booleans";

interface RestrictRouteInterface {
    (props: {
        children: JSX.Element;
        isAvailable: boolean | (() => boolean);
        onFalseNavTo?: string;
    }): JSX.Element;
}

const RestrictRoute: RestrictRouteInterface = ({
    children,
    isAvailable,
    onFalseNavTo = `/`,
}) => {
    const willRenderChildren = getBooleanFromFnOrBool(isAvailable);
    return willRenderChildren ? (
        children
    ) : (
        <Navigate to={onFalseNavTo} replace />
    );
};

export default RestrictRoute;
