import styled from "styled-components";
import { sizes as s, colorsDeprecated as c } from "global-style";
import { Button } from "components/Button/style";
export const GridColumn = styled.div<{ col: number }>`
    @media screen and (min-width: ${s.sm}) {
        display: grid;
        grid-template-columns: repeat(${(props) => props.col}, 1fr);
        grid-gap: 1.5em;
        align-items: top;
    }
`;

export const PriceSectionWrapper = styled.div`
    display: flex;
    gap: 0;
    flex-direction: column;
    @media screen and (min-width: ${s.sm}) {
        flex-direction: row;
        gap: 1.5em;
    }
`;

export const AmountWrapper = styled.div`
    flex: 1;
`;

export const PriceMetadataWrapper = styled.div`
    flex: 2;
`;

export const Paragraph = styled.p`
    margin-top: 1rem;
`;

export const ButtonsContainer = styled.div`
    margin-top: 1em;
    ${Button} {
        margin-right: 1rem;
    }
`;
