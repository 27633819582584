import { forwardRef, useImperativeHandle, useState, Ref, useRef } from "react";
import Input from "components/Input";
import {
    validatePositiveNumber,
    validateWalletAddress,
} from "utils/formValidation";

export interface TransferAmountToAddressFieldProps {
    disabled: boolean;
    defaultToAddress?: string;
}

export type TransferAmountToAddressFieldRef = {
    toAddress: string;
    hasChanged: boolean;
    validate: () => boolean;
};

function TransferAmountToAddressField(
    { disabled, defaultToAddress = "" }: TransferAmountToAddressFieldProps,
    ref: Ref<TransferAmountToAddressFieldRef>
) {
    const [toAddress, setToAddress] = useState<string>(defaultToAddress);

    const amountRef = useRef<HTMLInputElement>(null);

    const validate = () => {
        if (!amountRef.current) {
            return false;
        }

        return validateWalletAddress({
            input: amountRef.current,
        });
    };

    const hasChanged = toAddress !== defaultToAddress;

    useImperativeHandle(ref, () => ({
        toAddress: toAddress,
        validate,
        hasChanged,
    }));

    return (
        <Input
            type="text"
            name="toAddress"
            innerRef={amountRef}
            value={toAddress}
            disabled={disabled}
            onChange={(event) => setToAddress(event.target.value)}
            onBlur={(event) =>
                validateWalletAddress({
                    input: event.target,
                    reportValidity: false,
                })
            }
            placeholder="0x1234..."
        />
    );
}
export default forwardRef(TransferAmountToAddressField);
