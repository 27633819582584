import styled from "styled-components";
import { colorsDeprecated as c, sizes as s } from "global-style";
import { CardFooter } from "components/Card";
import { FooterBarProps } from ".";

export const Credits = styled(CardFooter)<FooterBarProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    font-size: 0.833333rem;
    color: ${c.lightOnSurface};
    margin-block: 1.5rem;
    width: 100%;
    bottom: 0;
    @media (min-width: ${s.md}) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: end;
        margin-block: 1rem;

        ${({ showCart }) =>
            !showCart ? `padding: 2rem 3rem;` : `max-width: ${s.sm};`}
    }
`;

export const SiteLinks = styled.ul`
    display: flex;
    margin: 0;
    li {
        white-space: nowrap;
        padding-inline: 0.5rem;
        &:not(:last-child) {
            border-inline-end: 1px solid ${c.border};
        }
        &:first-child {
            padding-inline-start: 0;
        }
        &:last-child {
            padding-inline-end: 0;
        }
    }
`;

export const PoweredBy = styled.span`
    white-space: nowrap;
    img {
        height: 1em;
    }
`;
