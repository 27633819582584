import {
    Ref,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import { CommonBlockchainNetworkResponse } from "api";
import Label from "components/Label";
import Input from "components/Input";
import { getNetworkName } from "company/utils/networks";
import { validateInput, validateWalletAddress } from "utils/formValidation";
import * as S from "./styles";
import Hint from "components/Hint";
import { Spacing } from "theme/spacing";

interface EntityInboundTreasuryFieldProps {
    onChange?: (inboundTreasury: string) => void;
    network: CommonBlockchainNetworkResponse;
    disabled?: boolean;
    required?: boolean;
}

export type EntityInboundTreasuryFieldRef = {
    inboundTreasury: string;
    network: CommonBlockchainNetworkResponse;
    networkEnabled: boolean;
    validate: () => boolean;
};

function EntityInboundTreasuryField(
    {
        onChange,
        network,
        disabled = false,
        required = false,
    }: EntityInboundTreasuryFieldProps,
    ref: Ref<EntityInboundTreasuryFieldRef>
) {
    const inputRef = useRef<HTMLInputElement>(null);

    const [networkEnabled, setNetworkEnabled] = useState<boolean>(required);
    const [inboundTreasury, setInboundTreasury] = useState<string>("");

    const validate = useCallback(() => {
        if (!inputRef.current) return false;

        const isValid =
            required || inboundTreasury
                ? validateWalletAddress({
                      input: inputRef.current,
                      reportValidity: true,
                  })
                : validateInput({
                      valid: true,
                      input: inputRef.current,
                      reportValidity: false,
                  });

        return isValid;
    }, [required, inboundTreasury]);

    useImperativeHandle(
        ref,
        () => ({
            inboundTreasury,
            validate,
            network,
            networkEnabled,
        }),
        [inboundTreasury, validate, network, networkEnabled]
    );

    useEffect(() => {
        if (onChange) onChange(inboundTreasury);
    }, [onChange, inboundTreasury]);

    useEffect(() => {
        validate();
        if (required || inboundTreasury) setNetworkEnabled(true);
        else setNetworkEnabled(false);
    }, [required, inboundTreasury, validate]);

    return (
        <S.EntityInboundTreasuryField spacing={[Spacing.sm]}>
            <Label htmlFor={network.hexId} inline key={network.id}>
                <b>{getNetworkName(network)}</b>
            </Label>
            <S.InputWrapper>
                <Input
                    type="text"
                    placeholder="Receiving wallet address"
                    disabled={disabled /* || !networkEnabled */}
                    innerRef={inputRef}
                    value={inboundTreasury}
                    onChange={(event) => setInboundTreasury(event.target.value)}
                />
                {required && (
                    <Hint>Required by the item's accepted tokens</Hint>
                )}
            </S.InputWrapper>
        </S.EntityInboundTreasuryField>
    );
}

export default forwardRef(EntityInboundTreasuryField);
