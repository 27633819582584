import styled from "styled-components";
import spacing from "theme/spacing";

export const ErrorMessage = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${spacing.sm};
    text-align: center;
`;
