type ApiRequestError = {
    code?: string;
    message?: string;
};

const errorMessageMap: { [key: string]: string } = {
    "504": `The server was slow to respond, please try again`,
    "404": `The requested page could not be found`,
    invalid_user_password: `The password you provided is incorrect`,
    invalid_reset_password_key: `Your password reset key is invalid`,
    not_verified_user: `You are not a verified user`,
    not_active_user: `This user account has not been activated`,
};

export const defaultErrorMessage = `An unexpected error occurred`;

export const getErrorMessage = (error: unknown): string => {
    if (typeof error !== `object` || error === null) return defaultErrorMessage;

    const { message = ``, code = `` } = error as ApiRequestError;
    const mappedErrorMessage =
        errorMessageMap[message] ?? errorMessageMap[code];

    return mappedErrorMessage ?? defaultErrorMessage;
};
