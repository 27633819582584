import styled from "styled-components";
import { sizes as s } from "global-style";
import Badge from "components/Badge";

export const EnvBadge = styled(Badge)`
    width: 100%;
    grid-area: env;
    font-weight: 700;
    line-height: 1;
    @media screen and (min-width: ${s.md}) {
        line-height: unset;
    }
`;
