import Button from "components/Button";
import { useUser, UserRole } from "context/User";
import { Ref } from "react";
import { ItemFrequencyNames, itemFrequencyNamesForDisplay } from "utils/items";
import CreateItemForm from "../CreateItemForm";
import { useModal } from "context/ModalProvider";
import Tooltip from "components/Tooltip";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";

export interface AddItemButtonProps {
    itemFrequencyName: ItemFrequencyNames;
}

export type AddItemButtonRef = {};

function AddItemButton(
    { itemFrequencyName }: AddItemButtonProps,
    ref: Ref<AddItemButtonRef>
) {
    const { hasRole } = useUser();
    const canManage = hasRole(UserRole.COMPANY);
    const { openModal, closeModal } = useModal();
    const { getCompanyConfigIsLoading } = useGetCompanyConfig();

    const itemFrequencyNameForDisplay =
        itemFrequencyNamesForDisplay[itemFrequencyName];

    const buttonCopy = `Add a ${itemFrequencyNameForDisplay}`;

    const handleOpenCreateOneTimePaymentFormModal = () => {
        openModal(
            <CreateItemForm
                onSuccess={closeModal}
                itemFrequencyName={itemFrequencyName}
            />,
            `Add item`
        );
    };

    if (!canManage) {
        return (
            <Tooltip
                title={`You do not have the permission to add ${itemFrequencyNameForDisplay}, contact your administrator.`}
                placement="top"
            >
                <Button disabled>{buttonCopy}</Button>
            </Tooltip>
        );
    }

    return (
        <Button
            onClick={handleOpenCreateOneTimePaymentFormModal}
            loading={getCompanyConfigIsLoading}
        >
            {buttonCopy}
        </Button>
    );
}

export default AddItemButton;
