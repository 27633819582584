import * as React from "react";
import * as S from "./styles";

export interface RadioProps {
    checked?: boolean;
    indeterminate?: boolean;
    name?: string;
    value?: string | number;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    disabled?: boolean;
}

export const Radio: React.FunctionComponent<RadioProps> = ({
    checked,
    name,
    value,
    onBlur,
    onChange,
    onFocus,
    disabled = false,
}) => {
    const handleOnBlur: React.FocusEventHandler<HTMLInputElement> = (
        event: React.FocusEvent<HTMLInputElement>
    ) => {
        if (onBlur) {
            onBlur(event);
        }
    };

    const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (onChange) {
            onChange(event);
        }
    };

    const handleOnFocus: React.FocusEventHandler<HTMLInputElement> = (
        event: React.FocusEvent<HTMLInputElement>
    ) => {
        if (onFocus) {
            onFocus(event);
        }
    };

    return (
        <S.Radio
            name={name}
            type="radio"
            checked={checked}
            value={value}
            id={name}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            onFocus={handleOnFocus}
            disabled={disabled}
        />
    );
};

export default Radio;
