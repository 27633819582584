import { useCallback, useEffect, useState } from "react";
import { getCookie, setCookie } from "utils/storage";
import { TimeFrequency, addTimePeriodToDate } from "utils/dates";
import { useWallet } from "context/Wallet";

const useAuthorizationToken = () => {
    const { walletConnected, getSignedMessage, verifySignedMessage } =
        useWallet();
    const [authToken, setAuthToken] = useState<string>(``);

    const createNewAuthToken = useCallback(
        async (
            expiration = new Date(
                addTimePeriodToDate({
                    addend: 1,
                    type: TimeFrequency.DAY,
                })
            )
        ) => {
            if (!walletConnected) throw new Error(`No wallet connected`);
            const newToken = await getSignedMessage();
            if (!newToken) throw new Error(`No token was generated`);

            setAuthToken(newToken.signature);
            setCookie([
                {
                    key: walletConnected.address.toLowerCase(),
                    value: newToken.signature,
                    expiration,
                },
            ]);

            return Promise.resolve(`${newToken.signature}`);
        },
        [getSignedMessage, walletConnected]
    );

    const checkForExistingAuthToken = useCallback(() => {
        if (!walletConnected) throw new Error(`No wallet connected`);

        const walletToken = getCookie(walletConnected.address.toLowerCase());
        if (!walletToken) throw new Error(`No token found for this wallet`);

        // Validate token
        if (!verifySignedMessage(walletToken))
            throw new Error(`Wallet address did not match token`);

        setAuthToken(walletToken);
        // Update expiration
        setCookie([
            {
                key: walletConnected.address.toLowerCase(),
                value: walletToken,
                expiration: new Date(
                    addTimePeriodToDate({
                        addend: 1,
                        type: TimeFrequency.DAY,
                    })
                ),
            },
        ]);

        return walletToken;
    }, [walletConnected]);

    // Clear or update authToken when new wallet EOA address connects
    useEffect(() => {
        setAuthToken(``);
        if (!walletConnected?.address) return;

        try {
            checkForExistingAuthToken();
        } catch (error) {
            setAuthToken(``);
        }
    }, [walletConnected?.address, checkForExistingAuthToken]);

    return { authToken, createNewAuthToken };
};

export { useAuthorizationToken };
