import { GlobalStyle } from "global-style";
import { Navigate, RouterProvider, useParams } from "react-router-dom";
import { typedCreateBrowserRouter } from "utils/router";
import { NotificationQueueProvider } from "context/NotificationQueue";
import WalletContextProvider from "context/Wallet";
import { EnsProvider } from "contexts/EnsProvider";
import QueryProvider from "contexts/QueryProvider";
import Loading from "components/Loading";
import ErrorMessage from "components/ErrorMessage";
import SearchByInvoice from "pay/components/SearchByInvoice";
import CheckoutForm from "pay/components/CheckoutForm";
import ConfirmedPayment from "pay/components/ConfirmedPayment";
import { InvoicePayProvider } from "pay/context/InvoicePay";
import InvoicePayLayout from "pay/components/InvoicePayLayout";

const PayController = () => {
    const { entityAlias } = useParams();

    return (
        <InvoicePayProvider entityAlias={entityAlias}>
            <InvoicePayLayout />
        </InvoicePayProvider>
    );
};

const router = typedCreateBrowserRouter(
    [
        {
            path: "/",
            element: <PayController />,
            errorElement: <ErrorMessage msg="404">Page not found</ErrorMessage>,
            children: [
                {
                    index: true,
                    element: (
                        <ErrorMessage msg="404">Page not found</ErrorMessage>
                    ),
                },
                {
                    path: ":entityAlias/id?/:invoiceId?/amount?/:invoiceAmount?/email?/:invoiceEmail?",
                    element: <SearchByInvoice />,
                },
                {
                    path: "checkout/:entityAlias",
                    element: <CheckoutForm />,
                },
                {
                    path: "confirmed/:entityAlias",
                    element: <ConfirmedPayment />,
                },
                {
                    path: "*",
                    element: <Navigate to="/" />,
                },
            ],
        },
    ],
    {
        future: {
            v7_normalizeFormMethod: true,
        },
    }
);

const PayApp = () => {
    return (
        <>
            <QueryProvider>
                <EnsProvider>
                    <NotificationQueueProvider>
                        <WalletContextProvider>
                            <GlobalStyle />
                            <RouterProvider
                                router={router}
                                fallbackElement={<Loading />}
                            />
                        </WalletContextProvider>
                    </NotificationQueueProvider>
                </EnsProvider>
            </QueryProvider>
        </>
    );
};

export default PayApp;
