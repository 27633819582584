import * as S from "./style";
import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { useCustomerData } from "customer/context/CustomerData";
import { formateDateForUrl } from "utils/datetime";
import DownloadCsv from "components/DownloadCsv";
import Anchor from "components/Anchor";
import RefreshCounter, { CounterType } from "components/RefreshCounter";
import Section from "components/Section";
import TransactionTable from "components/TransactionTable";
import { rowsToTableRecords } from "utils/tables";
import { TransactionDataLoader, TransactionTableRow } from "customer/types";
import EmptyTableMessage from "components/EmptyTableMessage";

const tableHeadings: RowHeading<TransactionTableRow>[] = [
    { label: `Date due`, field: `dateDue`, sortable: true },
    { label: `Processed`, field: `datePaid`, sortable: true },
    {
        label: `Invoiced`,
        field: `invoiced`,
        style: { shrink: true, textAlign: "right" },
    },
    { label: `Paid`, field: `paid`, style: { textAlign: "right" } },
    { label: `Authorization`, field: `authorization` },
    { label: `Item`, field: `item` },
    { label: `Invoice #`, field: `invoice`, sortable: true },
];

const csvHeadings: RowHeading<TransactionTableRow>[] = [
    { label: `Date due`, field: "dateDue" },
    { label: `Processed`, field: "datePaid" },
    { label: `Billed in USD`, field: "billedInUsd" },
    { label: `Invoiced`, field: "invoicedAmt" },
    { label: `Token`, field: "invoicedToken" },
    { label: `Paid`, field: "paidAmt" },
    { label: `Tx Hash`, field: "txHash" },
    { label: `Authorization`, field: `authorization` },
    { label: `Item`, field: "itemName" },
    { label: `Frequency`, field: "frequency" },
    { label: `Invoice #`, field: "invoice" },
];

const Confirmed = () => {
    const {
        lastFetched,
        refetch,
        transactions: { confirmed: data },
    } = useCustomerData();

    const { pagination, sort } = useLoaderData<TransactionDataLoader>();

    const [searchResults, setSearchResults] = useState<Tuple[]>([]);

    useEffect(() => {
        if (!data) return;
        setSearchResults(rowsToTableRecords(data, tableHeadings));
    }, [data]);

    const handleRefresh = () => {
        if (!refetch) return;

        // Could replace tables with spinning loader animations
        refetch();
    };

    return (
        <>
            <S.Filters>
                <RefreshCounter
                    refreshedAt={lastFetched}
                    counter={CounterType.Running}
                    onRefresh={handleRefresh}
                />
                <S.Download>
                    <DownloadCsv
                        filename={`confirmed-transactions_${formateDateForUrl(
                            Date.now()
                        )}.csv`}
                        columns={csvHeadings}
                        rows={rowsToTableRecords(data, csvHeadings)}
                    />
                </S.Download>
            </S.Filters>
            <Section>
                <TransactionTable
                    sort={sort}
                    pagination={pagination}
                    headings={tableHeadings}
                    records={searchResults}
                    ifNoRecords={
                        <EmptyTableMessage
                            title="No confirmed transactions"
                            description={
                                <>
                                    Need help?{` `}
                                    <Anchor
                                        href={`mailto:${
                                            import.meta.env.VITE_EMAIL_HELP
                                        }`}
                                    >
                                        Contact us
                                    </Anchor>
                                    .
                                </>
                            }
                        />
                    }
                ></TransactionTable>
            </Section>
        </>
    );
};

export default Confirmed;
