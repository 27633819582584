import * as S from "./style";
import { FunctionComponent } from "react";
import Tooltip from "components/Tooltip";
import Title from "components/Title";
import Paragraph from "components/Paragraph";

export interface HomeLinkProps {
    title: React.ReactNode;
    description: React.ReactNode;
    icon: React.ReactNode;
    path: string;
    disabled?: boolean;
    disabledReason?: React.ReactNode;
    onClick?: () => void;
}

const HomeLink: FunctionComponent<HomeLinkProps> = ({
    title,
    description,
    icon,
    path,
    disabled,
    disabledReason,
    onClick,
}) => {
    const homeLink = (
        <S.HomeLink
            to={path}
            aria-disabled={disabled}
            disabled={disabled}
            onClick={onClick}
        >
            <span>{icon}</span>
            <S.HomeLinkContent>
                <Title level="h4">{title}</Title>
                <Paragraph small={true}>{description}</Paragraph>
            </S.HomeLinkContent>
        </S.HomeLink>
    );

    // Disabled links
    if (disabled && disabledReason) {
        return (
            <Tooltip placement="top" title={disabledReason}>
                <span>{homeLink}</span>
            </Tooltip>
        );
    }

    return homeLink;
};

export default HomeLink;
