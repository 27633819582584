import React from "react";

const Loop = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            viewBox="0 0 17 22"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            {name && <title>{name}</title>}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.85712 0V3C13.2771 3 16.8571 6.58 16.8571 11C16.8571 12.57 16.3971 14.03 15.6171 15.26L14.1571 13.8C14.6071 12.97 14.8571 12.01 14.8571 11C14.8571 7.69 12.1671 5 8.85712 5V8L4.85712 4L8.85712 0ZM2.85712 11C2.85712 14.31 5.54712 17 8.85712 17V14L12.8571 18L8.85712 22V19C4.43712 19 0.857117 15.42 0.857117 11C0.857117 9.43 1.31712 7.97 2.09712 6.74L3.55712 8.2C3.10712 9.03 2.85712 9.99 2.85712 11Z"
                fill="inherit"
            />
        </svg>
    );
};

export default Loop;
