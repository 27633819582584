import * as S from "./style";
import { bnToCoin, toDollar } from "utils/financial";
import { CheckoutTokenPrice } from "checkout/context/CheckoutData/useTotalPricePerCommonToken";
import { biToStr, toNumber } from "utils/numbers";
import { convertTokenToCents } from "utils/exchangeRates";

interface CheckoutTokenPriceProps {
    checkoutTokenPrice: CheckoutTokenPrice;
    priceType?: "total" | "totalWithDiscount" | "totalDueToday";
    includeUsd?: boolean;
}

const CheckoutTokenPriceForDisplay = ({
    checkoutTokenPrice,
    priceType = "total",
    includeUsd = true,
}: CheckoutTokenPriceProps) => {
    const tokenPrice = biToStr(
        checkoutTokenPrice[priceType],
        checkoutTokenPrice.decimals
    );

    const usdPrice = convertTokenToCents(
        Number(tokenPrice),
        checkoutTokenPrice.exchange.rate
    );

    return (
        <>
            {bnToCoin(
                checkoutTokenPrice[priceType],
                checkoutTokenPrice.decimals,
                6
            )}{" "}
            {checkoutTokenPrice.symbol}
            {includeUsd && toNumber(tokenPrice) > 0 && (
                <>
                    {" "}
                    <S.TokenUsdPrice>({toDollar(usdPrice)})</S.TokenUsdPrice>
                </>
            )}
        </>
    );
};

export default CheckoutTokenPriceForDisplay;
