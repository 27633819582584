import React from "react";

const DollarCoin = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 22 16"
        >
            {name && <title>{name}</title>}
            <path
                d="M18 10V2c0-1.1-.9-2-2-2H2C.9 0 0 .9 0 2v8c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2Zm-2 0H2V2h14v8ZM9 3C7.34 3 6 4.34 6 6s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3Zm13 0v11c0 1.1-.9 2-2 2H3v-2h17V3h2Z"
                fill="inherit"
            />
        </svg>
    );
};

export default DollarCoin;
