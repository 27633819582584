import Copyable from "components/Copyable";
import styled from "styled-components";

export const Paragraph = styled.p`
    margin-bottom: 1rem;
`;

export const TooltipContainer = styled.div`
    margin-left: 0.5rem;
    margin-top: 0.2rem;
`;

export const CopyableInput = styled(Copyable)`
    width: 100%;
    span {
        width: 100%;
        max-width: 27rem;
    }
`;
