// Cast a queryparams as number
export function getNumberQueryParam(
    searchParams: URLSearchParams,
    name: string
): number | undefined {
    const paramValue = searchParams.get(name);
    if (!paramValue) return undefined;
    const paramValueNumber = Number(paramValue);
    if (Number.isNaN(paramValueNumber)) return undefined;
    return paramValueNumber;
}

// Cast a queryparams as string
export function getStringQueryParam(
    searchParams: URLSearchParams,
    name: string
): string | undefined {
    const paramValue = searchParams.get(name);
    if (!paramValue) return undefined;
    return paramValue;
}

// Cast a queryparams as an Array of string
export function getAllStringQueryParams(
    searchParams: URLSearchParams,
    name: string
): string[] {
    return searchParams.getAll(name);
}

// Cast a queryparams as a boolean
export function getBooleanQueryParams(
    searchParams: URLSearchParams,
    name: string,
    defaultValue: boolean
): boolean {
    const paramValue = searchParams.get(name);
    if (!paramValue) return defaultValue;
    return paramValue.toLocaleLowerCase() === "true";
}
