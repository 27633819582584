import styled from "styled-components";
import { colorsDeprecated as c } from "global-style";
import { CardSection } from "components/Card";
import Button from "components/Button";

export const CollectEmail = styled(CardSection)``;

export const Suggested = styled(Button)`
    color: inherit;
    font-weight: 600;
    &:hover {
        text-decoration: underline;
    }
`;
