import * as S from "./style";

interface HamburgerProps {
    open?: boolean;
    fast?: boolean;
    slow?: boolean;
    color?: string;
}

const Hamburger = ({
    open = false,
    fast = false,
    slow = false,
    color = "inherit",
    ...props
}: HamburgerProps) => {
    return (
        <S.Burger
            role="img"
            open={open}
            speed={fast ? `0.1s` : slow ? `0.4s` : `0.2s`}
            $color={color}
            {...props}
        >
            <span></span>
            <span></span>
            <span></span>
        </S.Burger>
    );
};
export default Hamburger;
