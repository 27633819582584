// PER https://www.figma.com/file/OaFZMpTdyV4wVreoFn3nrI/Loop-Product-Design-System-(Working-Source-of-Truth)?type=design&node-id=9-516&mode=design&t=EW7bosy0tJTr2Wtv-0

export type ColorPalette = {
    [key: string]: string;
};

export const primary: ColorPalette = {
    primary99: "#FFFBFF",
    primary95: "#F2EFFF",
    primary90: "#E2DFFF",
    primary80: "#C3C0FF",
    primary70: "#A4A1FF",
    primary60: "#8682FF",
    primary50: "#6760FF",
    primary40: "#4A3AF8",
    primaryDefault: "#4A3AF8",
    primary30: "#2F06E2",
    primary20: "#1E00A5",
    primary10: "#0F0069",
};

export const secondary: ColorPalette = {
    secondary99: "#FCFCFF",
    secondary95: "#E9F1FF",
    secondary90: "#CFE5FF",
    secondary80: "#99CBFF",
    secondary70: "#63B1F8",
    secondary65: "#5AA9F0",
    secondary60: "#4496DC",
    secondary50: "#1E7CC0",
    secondary40: "#00629E",
    secondary30: "#004A78",
    secondary20: "#003355",
    secondary10: "#001D34",
};

export const neutral: ColorPalette = {
    neutral99: "#FFFBFF",
    neutral95: "#F3EFFA",
    neutral90: "#E4E1EC",
    neutral80: "#C8C5D0",
    neutral70: "#ACAAB4",
    neutral60: "#928F9A",
    neutral50: "#787680",
    neutral40: "#5F5D67",
    neutral30: "#47464F",
    neutral20: "#302F38",
    neutral10: "#1B1B23",
};

export const success: ColorPalette = {
    success99: "#F4FFF6",
    success95: "#BFFFDA",
    success90: "#70FBBC",
    success80: "#50DEA1",
    success70: "#29C287",
    success65: "#00B37A",
    success60: "#00A570",
    success50: "#00885C",
    success40: "#006C48",
    success30: "#005235",
    success20: "#003824",
    success10: "#002113",
};

export const warning: ColorPalette = {
    warning99: "#FFFBFF",
    warning95: "#FFEFD1",
    warning90: "#FFDF95",
    warning80: "#F4BF16",
    warning70: "#D4A500",
    warning60: "#B48B00",
    warning50: "#947200",
    warning40: "#765A00",
    warning30: "#594400",
    warning20: "#3E2E00",
    warning10: "#251A00",
};

export const error: ColorPalette = {
    error99: "#FFFBFF",
    error95: "#FFEDEA",
    error90: "#FFDAD6",
    error80: "#FFB4AB",
    error70: "#FF897D",
    error60: "#FF5449",
    error50: "#DE3730",
    error40: "#BA1A1A",
    error30: "#93000A",
    error20: "#690005",
    error10: "#410002",
};

export const core: ColorPalette = {
    primary: primary.primary40,
    primaryOnDark: "#867BF9",
    secondary: secondary.secondary65,
    error: error.error40,
    warning: warning.warning80,
    success: success.success65,
    white: "#FFFFFF",
    neutralBackground: neutral.neutral95,
    neutralLight: neutral.neutral80,
    neutralDark: neutral.neutral40,
    lightText: neutral.neutral30,
    text: neutral.neutral10,
    black: "#000000",
    transparent: "transparent",
};

// Re-usable / shared colors outside of the figma design doc
export const shared: ColorPalette = {
    textOnPrimary: core.white,
    textOnSecondary: core.text,
    textOnWarning: core.white,
    textOnError: core.white,
    textOnSuccess: core.white,

    borderLight: neutral.neutral90,
    borderMedium: neutral.neutral50,
    borderStrong: neutral.neutral10,

    buttonFocusShadow: "#424ff2",
};

export const colorsAsHex: ColorPalette = {
    ...core,
    ...primary,
    ...secondary,
    ...neutral,
    ...success,
    ...warning,
    ...error,
    ...shared,
};

const colorPrefix = "color";
export const getColorVariableName = (colorName: string) => {
    return `--${colorPrefix}-${colorName}`;
};

const colors: ColorPalette = Object.keys({
    ...core,
    ...primary,
    ...secondary,
    ...neutral,
    ...success,
    ...warning,
    ...error,
    ...shared,
}).reduce<ColorPalette>((acc, colorName) => {
    acc[colorName] = `var(${getColorVariableName(colorName)})`;
    return acc;
}, {});

export default colors;
