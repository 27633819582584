const ratePrecision = 100; // backend format to 4 decimals

export const convertTokenToCents = (
    tokenAmount: number,
    rate: number
): number => (tokenAmount * rate) / ratePrecision;

export const convertCentsToToken = (
    amountInCents: number,
    rate: number
): number => (amountInCents * ratePrecision) / rate;

// [ ] This definitely doesn't work as intended, it's truncating decimals
export const convertCentsToTokenBN = (
    amountInCents: bigint,
    rate: number
): bigint => (amountInCents * BigInt(ratePrecision)) / BigInt(rate);

export const centsToToken = (
    cents: string | number | bigint,
    rate: string | number | bigint,
    decimalLimit: number = 18
): string => {
    // Scale to preserve decimal precision
    const scaleFactor = BigInt(10 ** decimalLimit);

    const scaledResult =
        (BigInt(cents) * BigInt(ratePrecision) * scaleFactor) / BigInt(rate);

    const scaledStr = scaledResult.toString();

    // Calculate the position of the decimal point
    const integerPart = scaledStr.slice(0, -decimalLimit) || "0";
    const decimalPart = scaledStr
        .slice(-decimalLimit)
        .padStart(decimalLimit, "0")
        .replace(/0+$/, ""); // trim trailing zeros

    return `${integerPart}${decimalPart ? `.${decimalPart}` : ""}`;
};
