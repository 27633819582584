import FailedDataFetchingMessage from "components/FailedDataFetchingMessage";
import ExternalSubscriptionDetails from "company/components/ExternalSubscriptionDetails";
import ImportExternalSubscriptionForm from "company/components/ImportExternalSubscriptionForm";
import { GetCompanyExternalSubscriptionResponse } from "company/types";
import LoadingBox from "components/LoadingBox";
import Section from "components/Section";
import Anchor from "components/Anchor";
import { useState } from "react";
import {
    PaymentPlatformUrls,
    canImportEntityExternalSubscription,
    entityPaymentPlatformSourceForDisplay,
} from "company/utils/entities";
import EntityPaymentPlatformProviderLogo from "company/components/entities/EntityPaymentPlatformProviderLogo";
import * as S from "./style";
import Field from "components/Field";
import Label from "components/Label";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import { useUser } from "context/User";
import SubSection from "components/SubSection";

const SubscriptionImport = () => {
    const { getEntityId } = useUser();
    const [
        companyExternalSubscriptionDetails,
        setCompanyExternalSubscriptionDetails,
    ] = useState<GetCompanyExternalSubscriptionResponse>();
    const {
        config: { entities },
        getCompanyConfigIsLoading,
        getCompanyConfigIsError,
    } = useGetCompanyConfig();

    const [showForm, setShowForm] = useState<boolean>(
        !companyExternalSubscriptionDetails
    );

    // Loading state
    if (getCompanyConfigIsLoading) return <LoadingBox height="20rem" />;

    // Error state
    if (getCompanyConfigIsError) return <FailedDataFetchingMessage />;

    const entity = entities.find((entity) => entity.entityId === getEntityId());

    if (!entity || !entity.paymentPlatformProvider) return <></>;

    const canImport =
        entity.paymentPlatformProvider &&
        canImportEntityExternalSubscription(entity);

    if (!canImport)
        return (
            <p>
                {entity.name} doesn't support charging an existing subscription
            </p>
        );

    const paymentPlatformSourceForDisplay =
        entityPaymentPlatformSourceForDisplay[entity.paymentPlatformProvider];

    const paymentPlatformUrls = new PaymentPlatformUrls(
        entity.paymentPlatformProvider,
        entity.externalSite
    );

    return (
        <Section narrow={true}>
            <SubSection
                title={
                    <>
                        <EntityPaymentPlatformProviderLogo
                            paymentPlatformProvider={
                                entity.paymentPlatformProvider
                            }
                            height="2rem"
                        />
                    </>
                }
                description={
                    <>
                        You can view all your subscriptions on the{" "}
                        <Anchor
                            href={paymentPlatformUrls.subscriptionsUrl}
                            target="_blank"
                        >
                            {paymentPlatformSourceForDisplay} subscriptions
                            dashboard
                        </Anchor>
                    </>
                }
            >
                {showForm && (
                    <ImportExternalSubscriptionForm
                        onSuccess={(companyExternalSubscriptionDetails) => {
                            setCompanyExternalSubscriptionDetails(
                                companyExternalSubscriptionDetails
                            );
                            setShowForm(false);
                        }}
                        entity={entity}
                        defaultExternalSubscriptionId={
                            companyExternalSubscriptionDetails?.externalSubscriptionId
                        }
                    />
                )}
            </SubSection>
            {!showForm && companyExternalSubscriptionDetails && (
                <SubSection>
                    {companyExternalSubscriptionDetails.externalSubscriptionId && (
                        <Field>
                            <Label>
                                {paymentPlatformSourceForDisplay} subscription
                                ID
                            </Label>
                            <S.SubscriptionId>
                                <div>
                                    <Anchor
                                        href={paymentPlatformUrls.subscriptionUrl(
                                            companyExternalSubscriptionDetails.externalSubscriptionId
                                        )}
                                        target="_blank"
                                    >
                                        {
                                            companyExternalSubscriptionDetails.externalSubscriptionId
                                        }
                                    </Anchor>
                                    {" - "}
                                    {companyExternalSubscriptionDetails.email}
                                </div>

                                <Anchor
                                    onClick={() => setShowForm(true)}
                                    underlined={false}
                                >
                                    Edit
                                </Anchor>
                            </S.SubscriptionId>
                        </Field>
                    )}
                    <ExternalSubscriptionDetails
                        companyExternalSubscriptionDetails={
                            companyExternalSubscriptionDetails
                        }
                    />
                </SubSection>
            )}
        </Section>
    );
};

export default SubscriptionImport;
