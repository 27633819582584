import styled from "styled-components";
import { sizes as s } from "global-style";
import Card from "components/Card";
import EnvironmentTag from "components/EnvironmentTag";
import Section from "components/Section";
import Title from "components/Title";

export const Background = styled.div<{ environment: string | undefined }>`
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 100%;
    min-height: 100vh;
    ${({ environment }) =>
        environment === `demo`
            ? `background-color: #99CBFF;`
            : `background-image: linear-gradient(180deg, #3545e8 0%, #6f7bf9 124.22%);`}
`;

export const Container = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
`;

export const FullCard = styled(Card)`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: ${s.sm};
    text-align: center;
    margin-inline: auto;
    @media screen and (min-width: ${s.sm}) {
        padding-inline: 8rem;
    }
`;

export const WhiteLogo = styled.img`
    width: 6rem;
`;

export const TopCorner = styled.div`
    padding: 2rem;
`;

export const Heading = styled(Title)`
    text-align: center;
`;

export const Footer = styled.footer`
    margin-block: 2rem;
`;

export const EnvBadge = styled(EnvironmentTag)`
    margin-block: 0.5rem;
`;

export const MainBlock = styled(Section)`
    margin: 0;
`;
