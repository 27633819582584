import styled from "styled-components";
export const CheckoutConnectWallet = styled.div`
    width: 100%;
    p {
        margin-top: 1rem;
    }
`;

export const MultiSigWrapper = styled.div`
    margin-top: 0.5rem;
    text-align: center;
`;
