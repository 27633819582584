import * as S from "./styles";
import { useState, FunctionComponent, FormEvent } from "react";
import { Company, UpdateItemRequest } from "company/types";
import Form from "components/Form";
import Button from "components/Button";
import Anchor from "components/Anchor";
import { useUser } from "context/User";
import { updatedCompanyItems } from "api";
import { useGetCompanyItems } from "company/hooks/useGetCompanyItems";

interface ItemActiveFormProps {
    item: Company.Item;
    onSuccess: () => void;
    onCancel: () => void;
}

const ItemActiveForm: FunctionComponent<ItemActiveFormProps> = ({
    item,
    onSuccess,
    onCancel,
}) => {
    // Hooks
    const { getCompanyItemsRefetch } = useGetCompanyItems();
    const { getEntityId, getSessionToken } = useUser();

    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const handleDisableItem = async (event: FormEvent<HTMLFormElement>) => {
        const itemPayload: UpdateItemRequest = {
            itemId: item.id,
            active: !item.active,
        };

        setButtonLoading(true);

        const { response } = await updatedCompanyItems([itemPayload], {
            Authorization: getSessionToken(),
            "Content-Type": "application/json",
            "entity-id": getEntityId(),
        });

        const successFullResponse = response.ok && response.status === 200;
        if (successFullResponse) {
            await getCompanyItemsRefetch();
            onSuccess();
        }
    };

    let buttonText: string;
    if (buttonLoading) {
        buttonText = item.active ? "Disabling" : "Enabling";
    } else {
        buttonText = item.active ? "Disable" : "Enable";
    }

    return (
        <Form preventEnterKeySubmission onSubmit={handleDisableItem}>
            <h1>
                Are you sure you want to {item.active ? "disable" : "enable"}{" "}
                {item.name}?
            </h1>
            {item.active && (
                <S.Paragraph>
                    Disabling stops wallets from authorizing future agreements
                    (i.e. new authorizations on the accounts page). You will
                    still be able to send invoices for current agreements.
                </S.Paragraph>
            )}
            <S.ButtonsContainer>
                <Button loading={buttonLoading}>{buttonText}</Button>
                <Anchor onClick={onCancel} href="#cancel" underlined={false}>
                    Cancel
                </Anchor>
            </S.ButtonsContainer>
        </Form>
    );
};

export default ItemActiveForm;
