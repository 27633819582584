import { useEffect, useState, useRef, useCallback } from "react";

export const useTimeout = (timeout = 30000) => {
    const [expired, setExpired] = useState(false);
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const cancelTimer = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    }, []);

    const startTimer = useCallback(() => {
        cancelTimer();
        timeoutRef.current = setTimeout(() => {
            setExpired(true);
        }, timeout);
    }, [cancelTimer, timeout]);

    useEffect(() => {
        startTimer();

        return () => cancelTimer();
    }, [startTimer, cancelTimer]);

    return { expired, cancelTimer, startTimer };
};
