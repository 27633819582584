import { useWallet } from "context/Wallet";
import { SafeWallet } from "context/Wallet/hooks/useWalletSafes";
import { useState, useEffect } from "react";

const useSafesConnected = () => {
    const { safeWallet } = useWallet();

    const [safesConnected, setSafesConnected] =
        useState<SafeWallet["wallets"] | null>(null);

    useEffect(() => {
        let isMounted = true;

        safeWallet
            .then((wallet) => {
                if (isMounted) {
                    setSafesConnected(
                        wallet?.wallets.length ? wallet.wallets : null
                    );
                }
            })
            .catch(() => {
                if (isMounted) {
                    setSafesConnected(null);
                }
            });

        return () => {
            isMounted = false;
        };
    }, [safeWallet]);

    return { safesConnected };
};

export default useSafesConnected;
