import React from "react";

const Warning = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            {...props}
            role="img"
            name={name}
            width={width}
            height={height}
            viewBox="0 0 22 19"
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
        >
            {name && <title>{name}</title>}
            <path
                d="M11 3.99L18.53 17H3.47L11 3.99ZM11 0L0 19H22L11 0ZM12 14H10V16H12V14ZM12 8H10V12H12V8Z"
                fill="inherit"
            />
        </svg>
    );
};

export default Warning;
