import { CheckoutItemResponse } from "api/types/checkout";
import { BaseItem, ExchangableToken } from "types/common";
import { Company } from "company/types";
import { FrequencyType } from "types/common-enums";
import { CheckoutItem } from "checkout/types";
import {
    isSubscription,
    minimumBalanceRequiredForItems,
    suggestedAllowanceForItem,
    suggestedAllowanceForItems,
} from "utils/items";
import { biToNum, toNumber } from "utils/numbers";

export type DiscountInfo = Pick<
    CouponResponse,
    "discountAmount" | "discountPercentage" | "singleUse"
>;

/* 
    Constants
*/
export const slippage = 1.005;
export const defaultNumTimesCharged: { [key in FrequencyType]: number } = {
    [FrequencyType.Hour]: 24,
    [FrequencyType.Day]: 30,
    [FrequencyType.Week]: 26,
    [FrequencyType.Month]: 13,
    [FrequencyType.Year]: 3,
};

/* 
    Calculators
*/

// Calculate the minimum balance required for an invoice or items
export const calculateMinimumBalance = (
    invoiceAmount: number | undefined,
    items:
        | CheckoutItemResponse[]
        | BaseItem[]
        | Company.Item[]
        | CheckoutItem[],
    minimumBalanceRequired: number | undefined,
    discountInfo?: DiscountInfo
) => {
    const { discountPercentage, discountAmount } = discountInfo ?? {};

    // If there is an invoice amount, use that, else use the sum of all item costs
    const minimumFromInvoiceOrItems =
        invoiceAmount ?? minimumBalanceRequiredForItems(items);

    const minimumFromInvoiceOrItemsWithDiscount = discountAmount
        ? minimumFromInvoiceOrItems - discountAmount
        : discountPercentage
        ? minimumFromInvoiceOrItems * (1 - discountPercentage / 100)
        : minimumFromInvoiceOrItems;

    return Math.max(
        minimumBalanceRequired ?? 0,
        minimumFromInvoiceOrItemsWithDiscount
    );
};

// Calculate the minimum allowance required for an invoice or items
export const calculateMinimumAllowance = (
    invoiceAmount: number | undefined,
    items: CheckoutItemResponse[] | BaseItem[] | Company.Item[],
    minimumBalanceRequired: number | undefined,
    discountInfo?: DiscountInfo
) => {
    // Currently, minimum allowance is identical to min balance
    return calculateMinimumBalance(
        invoiceAmount,
        items,
        minimumBalanceRequired,
        discountInfo
    );
};

type ItemWithFrequency =
    | Pick<BaseItem, "frequency">
    | Pick<CheckoutItemResponse, "frequency">;

// Calculate the suggested allowance for an invoice or items
export const calculateSuggestedAllowance = (
    invoiceAmount: number | undefined,
    items: CheckoutItemResponse[] | BaseItem[] | Company.Item[],
    minimumBalanceRequired: number | undefined,
    defaultSpendingCap: number = 0,
    discountInfo?: DiscountInfo
) => {
    // If its an invoice, check if any items are subscriptions and multiply by the first one's frequency multiplier
    const invoiceAmountMultipliedIfSubItemPresent: number | undefined =
        invoiceAmount &&
        (items as ItemWithFrequency[]).reduce<number | undefined>(
            (invoiceMultiplied, { frequency }) => {
                if (invoiceMultiplied !== undefined) return invoiceMultiplied; // already found a sub item

                const multipliedInvoiceAmount = suggestedAllowanceForItem({
                    amountInCents: invoiceAmount,
                    frequency: frequency,
                });

                // If the value hasn't changed, then it did not meet the criteria
                return multipliedInvoiceAmount === invoiceAmount
                    ? undefined
                    : multipliedInvoiceAmount;
            },
            undefined
        );

    // If there is an invoice amount, use that, else use the sum of all item costs
    const suggestedAllowanceAmount =
        invoiceAmountMultipliedIfSubItemPresent ??
        invoiceAmount ??
        suggestedAllowanceForItems(items, discountInfo);

    // Determine the minimum allowance required
    const minimumAllowance = calculateMinimumAllowance(
        invoiceAmount,
        items,
        minimumBalanceRequired,
        discountInfo
    );

    return Math.max(
        defaultSpendingCap,
        suggestedAllowanceAmount,
        minimumAllowance
    );
};

export const calculateTokenPricedMinimumBalance = (
    tokenTotal: bigint | null,
    token: ExchangableToken
) => {
    // Reminder that queryParam.minimumRequiredBalance is not considered here
    return biToNum(tokenTotal ?? BigInt(0), token.decimals);
};

export const calculateTokenPricedMinimumAllowance = (
    tokenTotal: bigint | null,
    token: ExchangableToken
) => {
    return biToNum(tokenTotal ?? BigInt(0), token.decimals);
};

export const calculateTokenPricedSuggestedAllowance = (
    items: CheckoutItem[],
    token: ExchangableToken
) => {
    return biToNum(
        items.reduce((totalSuggestedByItem, { prices, frequency }) => {
            const priceAmount = BigInt(
                prices.find(
                    ({ tokenAddress, network }) =>
                        tokenAddress === token.address &&
                        network === token.networkId
                )?.amount ?? 0
            );

            return (
                totalSuggestedByItem +
                (frequency.type &&
                isSubscription(frequency.value, frequency.type)
                    ? priceAmount *
                      BigInt(defaultNumTimesCharged[frequency.type] ?? 1)
                    : priceAmount)
            );
        }, BigInt(0)),
        token.decimals
    );
};

export const calculateAmountAfterDiscount = (
    amount: number | string,
    { discountAmount, discountPercentage }: DiscountInfo
) => {
    const amountAfterDiscount = discountAmount
        ? toNumber(amount) - discountAmount
        : discountPercentage
        ? (toNumber(amount) / 100) * (100 - discountPercentage)
        : toNumber(amount);

    return Math.max(amountAfterDiscount, 0);
};

export const getItemsUsdTotal = ({
    items,
    withDiscount = false,
}: {
    items: CheckoutItem[];
    withDiscount?: boolean;
}): number | null =>
    items.reduce<number | null>(
        (
            totalInUsd,
            { amount: amountWithoutDiscount, amountAfterDiscount }
        ) => {
            const amount = withDiscount
                ? amountAfterDiscount
                : amountWithoutDiscount;
            if (totalInUsd === null || amount === null) return null;
            return totalInUsd + amount;
        },
        0
    );

/* 
    Slippage Calculators
*/

// Note: These are a bit "dumb" but the idea is to have a common place
// math related to checkout amounts is being calculated

// Note 2: If/when we have item amount denoted in Token (e.g: USDC), this
// logic will change
export function suggestedAllowanceAmountForCheckout(
    suggestedAllowanceAmount: number
) {
    return Math.ceil(suggestedAllowanceAmount * slippage);
}

export function minimumAllowanceAmountForCheckout(
    minimumAllowanceAmount: number
) {
    return Math.ceil(minimumAllowanceAmount * slippage);
}

export function minimumBalanceRequiredAmountForCheckout(
    minimumBalanceRequiredAmount: number
) {
    return Math.ceil(minimumBalanceRequiredAmount * slippage);
}
