import styled from "styled-components";

export const SafeLogo = styled.img`
    height: 1rem;
    vertical-align: middle;
    margin: 0 0.125rem 0.2rem;
`;

export const SafeMultisig = styled.small``;

export const SafeTip = styled.article`
    max-width: 32rem;
`;
