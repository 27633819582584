import * as S from "./style";
import { useState, useEffect } from "react";
import { CheckoutEntityResponse } from "api/types/checkout";

interface LogoData {
    name: string | undefined;
    logo: string | undefined | null;
    url: string | undefined | null;
}

interface EntityLogoProps {
    entity: CheckoutEntityResponse | undefined;
}

const EntityLogo = ({ entity }: EntityLogoProps) => {
    const [logoData, setLogoData] = useState<LogoData>({
        name: entity?.name || ``,
        logo: entity?.logoUrl || ``,
        url: entity?.websiteUrl || ``,
    });

    // Only change the logo data if the entity changes - to prevent unnecessary reloading
    useEffect(() => {
        setLogoData({
            name: entity?.name || ``,
            logo: entity?.logoUrl || ``,
            url: entity?.websiteUrl || ``,
        });
    }, [entity]);

    return <S.Entity entity={logoData} />;
};

export default EntityLogo;
