import {
    forwardRef,
    useImperativeHandle,
    useState,
    Ref,
    useEffect,
} from "react";
import Label from "components/Label";
import Field from "components/Field";
import Radio from "components/Radio";
import { VARIABLE_PRICING_FOR_DISPLAY } from "utils/items";

export interface ItemPricingModelFieldProps {
    disabled?: boolean;
    defaultFixedPrice?: boolean;
    onChangeFixedPrice?: (fixedPrice: boolean) => void;
}

export type ItemPricingModelFieldRef = {
    fixedPrice: boolean;
    hasChanged: boolean;
};

function ItemPricingModelField(
    {
        disabled,
        defaultFixedPrice = true,
        onChangeFixedPrice,
    }: ItemPricingModelFieldProps,
    ref: Ref<ItemPricingModelFieldRef>
) {
    const [fixedPrice, setFixedPrice] = useState<boolean>(defaultFixedPrice);

    const hasChanged = fixedPrice !== defaultFixedPrice;
    useImperativeHandle(ref, () => ({
        fixedPrice,
        hasChanged,
    }));

    useEffect(() => {
        if (onChangeFixedPrice) {
            onChangeFixedPrice(fixedPrice);
        }
    }, [fixedPrice, onChangeFixedPrice]);

    const pricingModelName = "pricingModel";

    return (
        <Field disabled={disabled}>
            <Label
                htmlFor={pricingModelName}
                subLabel="Varied pricing allows bills based on usage "
            >
                Pricing model
            </Label>
            <Label inline>
                <Radio
                    name={pricingModelName}
                    value="true"
                    disabled={disabled}
                    checked={fixedPrice}
                    onChange={() => setFixedPrice(true)}
                />
                Fixed
            </Label>
            <Label inline>
                <Radio
                    name={pricingModelName}
                    value="false"
                    disabled={disabled}
                    checked={!fixedPrice}
                    onChange={() => setFixedPrice(false)}
                />
                {VARIABLE_PRICING_FOR_DISPLAY}
            </Label>
        </Field>
    );
}
export default forwardRef(ItemPricingModelField);
