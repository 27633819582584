import React from "react";

const Info = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            {...props}
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
        >
            {name && <title>{name}</title>}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 2a6 6 0 1 0 0 12A6 6 0 0 0 8 2ZM.7 8a7.3 7.3 0 1 1 14.6 0A7.3 7.3 0 0 1 .7 8ZM8 7.3c.4 0 .7.3.7.7v2.7a.7.7 0 0 1-1.4 0V8c0-.4.3-.7.7-.7Zm0-2.6A.7.7 0 0 0 8 6a.7.7 0 1 0 0-1.3Z"
            />
        </svg>
    );
};

export default Info;
