import {
    Dispatch,
    SetStateAction,
    memo,
    forwardRef,
    ForwardedRef,
    useCallback,
} from "react";
import Label from "components/Label";
import Field from "components/Field";
import TagsInput, { TagsInputRef } from "company/components/TagsInput";

interface TagsListProps {
    value?: number[];
    setValue: Dispatch<SetStateAction<number[]>>;
    disabled?: boolean;
}

const TagsList = memo(
    forwardRef<TagsInputRef, TagsListProps>(
        (
            { value = [], setValue, disabled = false }: TagsListProps,
            ref: ForwardedRef<TagsInputRef>
        ) => {
            const handleChange = useCallback(
                (tags: number[]) => {
                    setValue(tags);
                },
                [setValue]
            );

            return (
                <Field>
                    <Label htmlFor="tags">Tags</Label>
                    <TagsInput
                        name="tags"
                        onChange={handleChange}
                        defaultSelectedTagIds={value}
                        ref={ref}
                        disabled={disabled}
                    />
                </Field>
            );
        }
    )
);

export default TagsList;
