import * as S from "./style";
import {
    DetailsHTMLAttributes,
    FunctionComponent,
    HTMLAttributes,
    ReactNode,
} from "react";

export interface DetailsProps extends HTMLAttributes<HTMLDetailsElement> {
    children?: ReactNode;
    summary?: ReactNode;
    open?: boolean;
    disabled?: boolean;
    onToggle?: DetailsHTMLAttributes<HTMLDetailsElement>["onToggle"];
}

const Details: FunctionComponent<DetailsProps> = ({
    children,
    summary,
    open = false,
    disabled = false,
    ...props
}) => {
    return (
        <S.Details open={!disabled && open} {...props}>
            {summary && (
                <S.Summary
                    disabled={disabled}
                    tabIndex={disabled ? -1 : undefined}
                >
                    {summary}
                </S.Summary>
            )}
            {children}
        </S.Details>
    );
};

export default Details;
