const slippageTypes = [
    { value: String(5000), label: `0.5%` },
    { value: String(10000), label: `1%` },
    { value: String(15000), label: `1.5%` },
    { value: String(20000), label: `2.0%` },
];

const orderFormDefaultValues = {
    exchange: {
        sell: {
            amount: `0`,
            currency: `USDC`,
        },
        buy: {
            amount: `0`,
            currency: `WETH`,
        },
    },
    frequency: String(24 * 60 * 60),
    slippage: String(5000),
};

const minutes = 5;
const exchangeRateExpiry = minutes * 60 * 1000;

export { slippageTypes, orderFormDefaultValues, exchangeRateExpiry };
