import styled from "styled-components";
import Anchor from "components/Anchor";
import colors from "theme/colors";

export const FlexContainer = styled.div`
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    img {
        max-width: 25px;
    }
`;

export const Filters = styled.header`
    display: flex;
    align-items: flex-end;
    gap: 1rem;
`;
export const Download = styled.div`
    flex-grow: 1;
    text-align: right;
`;

export const LearnMore = styled(Anchor)`
    color: inherit;
`;

export const CanceledDate = styled.small`
    color: ${colors.text};
    white-space: nowrap;
`;

export const ManageBtns = styled.ul`
    display: flex;
    gap: 0.75rem;
    justify-content: end;
    align-items: center;
    margin-block: 0;
`;

export const ManageTip = styled.span`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    & *:disabled {
        pointer-events: none;
    }
`;

export const HeaderRightSideContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const DisabledButtonContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const ItemFrequency = styled.div`
    margin-top: 0.2rem;
`;

export const SenderContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const AgreementTokenContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    img {
        max-width: 25px;
    }
`;

export const ItemList = styled.ul`
    margin: 0;
    & > li {
        white-space: nowrap;
    }
`;
