import { UserRole } from "context/User";
import { ReactNode } from "react";
import {
    IndexRouteObject,
    NonIndexRouteObject,
    createBrowserRouter,
} from "react-router-dom";

interface Handle {
    name?: string;
    heading?: ReactNode;
    tip?: ReactNode;
    description?: ReactNode;
    help?: string;
    wallet?: UserRole[] | boolean;
}

type RouteConfig =
    | (Omit<IndexRouteObject, "handle"> & {
          handle?: Handle;
      })
    | (Omit<NonIndexRouteObject, "handle" | "children"> & {
          handle?: Handle;
          children?: RouteConfig[];
      });

// Create a custom function that enforces the type constraints for the `handle` property
export const typedCreateBrowserRouter = (
    routes: RouteConfig[],
    opts?: any
): any => {
    return createBrowserRouter(routes, opts);
};
