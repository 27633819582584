import styled, { css } from "styled-components";
import { Label } from "components/Label/styles";
import { Hint } from "components/Hint/styles";
import spacing, { SpacingTrack, getSpacing } from "theme/spacing";

export const Field = styled.fieldset<{
    disabled: boolean;
    spacing?: SpacingTrack;
}>`
    ${({ spacing }) =>
        spacing && `margin-block: ${getSpacing(spacing)} !important;`}
    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
            user-select: none;
        `}

    ${Label} {
        margin-bottom: 0.7em;
    }
    ${Hint} {
        margin-top: ${spacing.xxxxs};
    }
`;
