import styled from "styled-components";
import colors from "theme/colors";
import spacing from "theme/spacing";

export const NetworksList = styled.ul`
    margin: 0;
`;

export const TokensList = styled.ul`
    margin: 0;
    display: inline;
`;

export const TokenListItem = styled.li`
    display: inline-block;
    &:not(:last-child):after {
        content: ", ";
        margin-right: ${spacing.xxxs};
        color: ${colors.neutralDark};
    }
`;
