import React, { useCallback, useState, useRef } from "react";
import { Company, UpdateTransferDelegatedSigner } from "company/types";
import * as S from "./style";
import Button, { ButtonVariants } from "components/Button";
import { useNotificationQueue } from "context/NotificationQueue";
import TransferAmountToAddress, {
    TransferAmountToAddressFieldRef,
} from "company/components/transfers/TransferAmountToAddressField";
import { patchCompanyTransfers } from "api";
import { NotificationType } from "components/Notification";
import { useGetCompanyTransfers } from "company/hooks/useGetCompanyTransfers";
import { useUser } from "context/User";

interface UpdateTransferToAddressFormProps {
    transaction: Company.Transaction;
    onCancel?: () => void;
    onSuccess?: () => void;
}

const UpdateTransferToAddressForm: React.FunctionComponent<UpdateTransferToAddressFormProps> =
    ({ transaction, onCancel, onSuccess }) => {
        const [formLoading, setFormLoading] = useState<boolean>(false);

        // Session & Company data
        const { invalidateAllTransfersQueries } = useGetCompanyTransfers({
            id: transaction.transferId,
        });
        const { getEntityId, getSessionToken } = useUser();

        // Notifications
        const { addNotification } = useNotificationQueue();

        const transferAmountToAddressFieldRef =
            useRef<TransferAmountToAddressFieldRef>(null);

        const successHandle = useCallback(async () => {
            await invalidateAllTransfersQueries();
            if (onSuccess) onSuccess();
        }, [invalidateAllTransfersQueries, onSuccess]);

        // RIP: useFetch :sad_face_emoji:
        // Note: Talked with Rocco, we need to consider refactoring the useFetch
        // and/or use a library like react-query for the POST scenario
        const updateToAddress = async () => {
            setFormLoading(true);

            // Get & Validate billDate
            let toAddress: string | undefined;
            if (transferAmountToAddressFieldRef.current) {
                toAddress = transferAmountToAddressFieldRef.current.toAddress;
                if (!transferAmountToAddressFieldRef.current.validate()) return;

                // if data hasn't change, trigger onSuccess
                if (!transferAmountToAddressFieldRef.current.hasChanged) {
                    if (onSuccess) onSuccess();
                    return;
                }
            }

            if (!toAddress) return;

            const patchTransfer: UpdateTransferDelegatedSigner = {
                transferId: transaction.transferId,
                toAddress,
            };

            const headers = {
                Authorization: getSessionToken(),
                "entity-id": getEntityId(),
            };

            const { response } = await patchCompanyTransfers(
                [patchTransfer],
                headers
            );

            const successFullResponse = response.ok && response.status === 200;

            if (successFullResponse) {
                await successHandle();
            } else {
                addNotification({
                    msg: `There was an error while updating the receiver address`,
                    type: NotificationType.ERROR,
                });
            }

            setFormLoading(false);
        };

        return (
            <S.EditForm onSubmit={updateToAddress}>
                <TransferAmountToAddress
                    defaultToAddress={transaction.receiver.wallet}
                    disabled={formLoading}
                    ref={transferAmountToAddressFieldRef}
                />
                <Button type="submit" loading={formLoading}>
                    {formLoading ? "Saving" : "Save"}
                </Button>
                <Button
                    type="button"
                    disabled={formLoading}
                    onClick={onCancel}
                    variant={ButtonVariants.Anchor}
                >
                    Cancel
                </Button>
            </S.EditForm>
        );
    };

export default UpdateTransferToAddressForm;
