import * as S from "./style";
import { HTMLAttributes, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { dollarsFromCents, toCents } from "utils/financial";
import Title from "components/Title";
import Form from "components/Form";
import Button from "components/Button";
import InputValidated, { InputValidatedRef } from "components/InputValidated";
import {
    validatePresence,
    validateUsDollars,
    validateEmailAddress,
} from "utils/formValidation";
import { useInvoicePay } from "pay/context/InvoicePay";

interface SearchByInvoiceProps extends HTMLAttributes<HTMLDivElement> {}

const validateId = (input: HTMLInputElement) => {
    return validatePresence({
        input,
        errorMessage: `Please enter an invoice id`,
    });
};

const validateAmount = (input: HTMLInputElement) => {
    return validateUsDollars({
        input,
        errorMessage: `Please enter a dollar value`,
    });
};

const validateEmail = (input: HTMLInputElement) => {
    return validateEmailAddress({
        input,
    });
};

const SearchByInvoice = ({ ...props }: SearchByInvoiceProps) => {
    const {
        invoiceId = ``,
        invoiceAmount = ``,
        invoiceEmail = ``,
    } = useParams();

    const {
        entityAlias,
        entity,
        setInvoiceId,
        setInvoiceAmt,
        setInvoiceEmail,
    } = useInvoicePay();

    const navigate = useNavigate();

    const invoiceIdRef = useRef<InputValidatedRef>(null);
    const invoiceAmountRef = useRef<InputValidatedRef>(null);
    const invoiceEmailRef = useRef<InputValidatedRef>(null);

    const handleSubmit = () => {
        // Allows back button to pre-fill data using path
        const newPath: string[] = [];
        if (invoiceIdRef?.current?.validate())
            newPath.push(`id`, invoiceIdRef.current.value());
        if (invoiceAmountRef?.current?.validate())
            newPath.push(
                `amount`,
                toCents(invoiceAmountRef.current.value()).toString()
            );
        if (invoiceEmailRef?.current?.validate())
            newPath.push(`email`, invoiceEmailRef.current.value());
        navigate(
            `/${entityAlias}${newPath.length ? `/` + newPath.join("/") : ``}`,
            { replace: true }
        );

        if (
            !invoiceIdRef?.current?.validate() ||
            !invoiceAmountRef?.current?.validate() ||
            !invoiceEmailRef?.current?.validate()
        )
            return;

        setInvoiceId(invoiceIdRef.current.value());
        setInvoiceAmt(toCents(invoiceAmountRef.current.value()).toString());
        setInvoiceEmail(invoiceEmailRef.current.value());

        navigate(`/checkout/${entityAlias}`);
    };

    return (
        <S.SearchByInvoice {...props}>
            <S.Header>
                <Title level="h2">Pay {entity.name}</Title>
            </S.Header>
            <Form onSubmit={handleSubmit}>
                <InputValidated
                    ref={invoiceIdRef}
                    label="Invoice number"
                    name="invoiceId"
                    placeholder="INV-001"
                    defaultValue={invoiceId}
                    validate={validateId}
                />
                {/* // [ ] Make this is dollar value field */}
                <InputValidated
                    ref={invoiceAmountRef}
                    label="Invoice amount"
                    name="invoiceAmount"
                    placeholder="99.99"
                    defaultValue={
                        invoiceAmount
                            ? dollarsFromCents(invoiceAmount)
                            : undefined
                    }
                    validate={validateAmount}
                />
                <InputValidated
                    ref={invoiceEmailRef}
                    label="Email for receipt"
                    name="invoiceEmail"
                    placeholder="me@loopcrypto.xyz"
                    defaultValue={invoiceEmail}
                    validate={validateEmail}
                />
                <footer>
                    <Button type="submit" full>
                        Continue
                    </Button>
                </footer>
            </Form>
        </S.SearchByInvoice>
    );
};

export default SearchByInvoice;
