import { SVGProps } from "react";
import styled from "styled-components";

const Copy = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            {name && <title>{name}</title>}
            <path
                d="M16.375 21.0714H7.52885C6.64904 21.0714 5.80528 20.7102 5.18315 20.0672C4.56103 19.4242 4.21154 18.5521 4.21154 17.6429V6.21429C4.21154 5.91118 4.09503 5.62049 3.88766 5.40617C3.68029 5.19184 3.39904 5.07142 3.10577 5.07142C2.8125 5.07142 2.53125 5.19184 2.32388 5.40617C2.1165 5.62049 2 5.91118 2 6.21429V17.6429C2 19.1585 2.5825 20.6118 3.61936 21.6834C4.65622 22.755 6.06251 23.3571 7.52885 23.3571H16.375C16.6683 23.3571 16.9495 23.2367 17.1569 23.0225C17.3643 22.808 17.4808 22.5174 17.4808 22.2144C17.4808 21.9113 17.3643 21.6205 17.1569 21.4062C16.9495 21.1918 16.6683 21.0714 16.375 21.0714ZM21.9038 8.43142C21.8923 8.32644 21.8701 8.223 21.8375 8.12285V8.02C21.7842 7.9025 21.7135 7.79448 21.6275 7.70001L14.9928 0.842857C14.9014 0.753961 14.7969 0.680663 14.6832 0.625714H14.5837L14.2298 0.5H9.74038C8.86058 0.5 8.01682 0.861224 7.39469 1.50421C6.77257 2.1472 6.42308 3.01925 6.42308 3.92858V15.3571C6.42308 16.2665 6.77257 17.1385 7.39469 17.7815C8.01682 18.4244 8.86058 18.7858 9.74038 18.7858H18.5865C19.4664 18.7858 20.3101 18.4244 20.9322 17.7815C21.5544 17.1385 21.9038 16.2665 21.9038 15.3571V8.5C21.9038 8.5 21.9038 8.49999 21.9038 8.43142ZM15.2692 4.39714L18.1332 7.35715H16.375C16.0817 7.35715 15.8005 7.23674 15.5931 7.0224C15.3857 6.80808 15.2692 6.51739 15.2692 6.21429V4.39714ZM19.6923 15.3571C19.6923 15.6602 19.5759 15.9509 19.3684 16.1653C19.161 16.3796 18.8799 16.5 18.5865 16.5H9.74038C9.44712 16.5 9.16586 16.3796 8.95849 16.1653C8.75112 15.9509 8.63462 15.6602 8.63462 15.3571V3.92858C8.63462 3.62548 8.75112 3.33478 8.95849 3.12045C9.16586 2.90613 9.44712 2.78572 9.74038 2.78572H13.0577V6.21429C13.0577 7.1236 13.4072 7.99567 14.0293 8.63865C14.6514 9.28164 15.4952 9.64286 16.375 9.64286H19.6923V15.3571Z"
                fill="inherit"
            />
        </svg>
    );
};

export default styled(Copy)`
    cursor: pointer;
    &:hover {
    }
    &:active {
    }
`;
