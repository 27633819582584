import styled, { keyframes } from "styled-components";
import { colorsDeprecated as c, properties as p } from "global-style";
import Modal from "components/Modal";

export const Loading = styled(Modal)`
    z-index: ${p.zIndex.loading};
`;

export const Logo = styled.svg`
    --speed: ${p.logoAnimation};
    height: 8em;
    stroke-width: 22px;
    fill: none;
`;

export const StartGradient = styled.stop`
    stop-color: ${c.loopColA};
`;

export const StopGradient = styled.stop`
    stop-color: ${c.loopColB};
`;

const loop = keyframes`
  to {
    stroke-dashoffset: -1261;
  }
`;

const arrow = keyframes`
  0% {
    stroke-dashoffset: 126;
    opacity: 0;
  }
  45% {
    stroke-dashoffset: 126;
  }
  50% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
`;

export const Loop = styled.path`
    stroke-dasharray: 1261;
    stroke-dashoffset: 1261;
    stroke-linejoin: round;
    animation: ${loop} var(--speed) linear infinite;
    animation-delay: 0.25s;
`;

export const Arrow = styled.path`
    stroke-dasharray: 126;
    stroke-dashoffset: 126;
    opacity: 0;
    animation: ${arrow} var(--speed) linear infinite;
    animation-delay: 0.25s;
    stroke: ${c.loopColB};
`;
