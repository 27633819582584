import { useCallback, useEffect, useState } from "react";
import { CheckoutItem } from "checkout/types";
import { ExchangableToken } from "types/common";
import { ExchangeRateDetails } from "api";
import { convertCentsToToken } from "utils/exchangeRates";
import { undecimalizeNumber } from "utils/financial";

export interface CheckoutTokenPrice {
    address: string;
    network: number;
    symbol: string;
    decimals: number;
    exchange: ExchangeRateDetails;
    total: bigint;
    totalWithDiscount: bigint;
    totalDueToday: bigint;
}

// Based on the token selected, calculate the price of this invoice
export const useTotalPricePerCommonToken = (
    setOfAllPossibleTokens: ExchangableToken[],
    checkoutItems: CheckoutItem[]
) => {
    const [tokenTotalsReady, setTokenTotalsReady] = useState<boolean>(false);
    const [totalPricePerCommonToken, setTotalPricePerCommonToken] = useState<
        CheckoutTokenPrice[]
    >([]);

    const getTotalsByToken = useCallback(
        (token: ExchangableToken) => {
            const price = totalPricePerCommonToken.find(
                ({ address, network }) => {
                    return (
                        address === token.address && network === token.networkId
                    );
                }
            );
            return {
                total: price?.total,
                totalWithDiscount: price?.totalWithDiscount,
                totalDueToday: price?.totalDueToday,
            };
        },
        [totalPricePerCommonToken]
    );

    const getTotalPriceByAddressAndNetwork = useCallback(
        (address: string, network: number) => {
            return totalPricePerCommonToken.find(
                ({ address: tokenAddress, network: tokenNetwork }) => {
                    return address === tokenAddress && network === tokenNetwork;
                }
            );
        },
        [totalPricePerCommonToken]
    );

    useEffect(() => {
        setTotalPricePerCommonToken(
            // Go through each token, accumulate the total due in that token
            setOfAllPossibleTokens.reduce<CheckoutTokenPrice[]>(
                (availablePrices, token) => {
                    // Check if any item has a price in this token
                    const anyItemsHaveThisTokenPrice = checkoutItems.some(
                        ({ prices }) =>
                            prices.some(
                                (price) =>
                                    price.tokenAddress === token.address &&
                                    price.network === token.networkId
                            )
                    );
                    if (!anyItemsHaveThisTokenPrice) return availablePrices;

                    // If an amount (BigInt), return an CheckoutItemPrice object with the number as `total`
                    // If false is returned, then the price can't be computed in that token
                    const allItemsCanHaveThisTokenPrice = checkoutItems.reduce<
                        | Pick<
                              CheckoutTokenPrice,
                              "total" | "totalWithDiscount" | "totalDueToday"
                          >
                        | false
                    >(
                        (
                            amountDue,
                            {
                                prices,
                                amount: usdAmount,
                                amountAfterDiscount,
                                dueDate,
                            }
                        ) => {
                            // If price for this token not found on any item, then stop accumulating
                            if (amountDue === false) return false;

                            // Get the amount in token
                            const tokenPrice = prices.find(
                                (price) =>
                                    price.tokenAddress === token.address &&
                                    price.network === token.networkId
                            );
                            const tokenAmount = tokenPrice?.amount ?? null;

                            // If no ItemTokenPrice found, convert USD to token price if possible
                            const usdAmountInToken =
                                !tokenPrice && usdAmount !== null
                                    ? undecimalizeNumber(
                                          convertCentsToToken(
                                              usdAmount,
                                              token.exchange.rate
                                          ),
                                          token.decimals
                                      )
                                    : null;

                            // No price found
                            if (
                                tokenAmount === null &&
                                usdAmountInToken === null
                            )
                                return false;

                            // If there is a discount, calculate the discount amount
                            const discountAmount = tokenPrice
                                ? tokenPrice.amountAfterDiscount
                                : undecimalizeNumber(
                                      convertCentsToToken(
                                          amountAfterDiscount ?? 0,
                                          token.exchange.rate
                                      ),
                                      token.decimals
                                  );

                            // Add the price to the total
                            return {
                                total:
                                    amountDue.total +
                                    BigInt(
                                        tokenAmount || usdAmountInToken || 0
                                    ),
                                totalWithDiscount:
                                    amountDue.totalWithDiscount +
                                    BigInt(discountAmount),
                                totalDueToday:
                                    amountDue.totalDueToday +
                                    (dueDate === 0
                                        ? BigInt(discountAmount)
                                        : BigInt(0)),
                            };
                        },
                        {
                            total: BigInt(0),
                            totalWithDiscount: BigInt(0),
                            totalDueToday: BigInt(0),
                        }
                    );

                    if (allItemsCanHaveThisTokenPrice) {
                        return [
                            ...availablePrices,
                            {
                                address: token.address,
                                network: token.networkId,
                                symbol: token.symbol,
                                decimals: token.decimals,
                                exchange: token.exchange,
                                total: allItemsCanHaveThisTokenPrice.total,
                                totalWithDiscount:
                                    allItemsCanHaveThisTokenPrice.totalWithDiscount,
                                totalDueToday:
                                    allItemsCanHaveThisTokenPrice.totalDueToday,
                            },
                        ];
                    }

                    return availablePrices;
                },
                []
            )
        );
        if (setOfAllPossibleTokens.length && checkoutItems.length)
            setTokenTotalsReady(true);
    }, [setOfAllPossibleTokens, checkoutItems]);

    return {
        tokenTotalsReady,
        totalPricePerCommonToken,
        getTotalsByToken,
        getTotalPriceByAddressAndNetwork,
    };
};
