import * as S from "./style";
import { bnToCoin, toDollar } from "utils/financial";
import { biToStr, toNumber } from "utils/numbers";
import { useCheckoutData } from "checkout/context/CheckoutData";
import { ItemTokenPrice } from "checkout/types";
import { convertTokenToCents } from "utils/exchangeRates";

interface ItemTokenPriceProps {
    itemTokenPrice: ItemTokenPrice;
    amountType?: "amount" | "amountAfterDiscount";
    includeUsd?: boolean;
}

const ItemTokenPriceForDisplay = ({
    itemTokenPrice,
    amountType = "amount",
    includeUsd = true,
}: ItemTokenPriceProps) => {
    const { getTotalPriceByAddressAndNetwork } = useCheckoutData();

    const checkoutTokenPrice = getTotalPriceByAddressAndNetwork(
        itemTokenPrice.tokenAddress,
        itemTokenPrice.network
    );

    // This cannot happen since ItemTokenPrice is based on prices :pray_emoji:
    if (!checkoutTokenPrice) {
        return <>No price</>;
    }

    const tokenPrice = biToStr(
        BigInt(itemTokenPrice[amountType]),
        checkoutTokenPrice.decimals
    );

    const usdPrice = convertTokenToCents(
        Number(tokenPrice),
        checkoutTokenPrice.exchange.rate
    );

    return (
        <>
            {bnToCoin(
                BigInt(itemTokenPrice[amountType]),
                checkoutTokenPrice.decimals,
                6
            )}{" "}
            {checkoutTokenPrice.symbol}
            {includeUsd && toNumber(itemTokenPrice[amountType]) > 0 && (
                <>
                    {` `}
                    <S.TokenUsdPrice>({toDollar(usdPrice)})</S.TokenUsdPrice>
                </>
            )}
        </>
    );
};

export default ItemTokenPriceForDisplay;
