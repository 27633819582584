import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
    QueuedNotification,
    useNotificationQueue,
} from "context/NotificationQueue";

export interface NavigateState {
    from: string;
    message: QueuedNotification;
}

export const useHistoryState = (showMsgNotification: boolean = false) => {
    const { addNotification } = useNotificationQueue();
    const location = useLocation<NavigateState>();

    // Receive a message from a redirecting page and display it as a notification
    const { from, message } = location.state || {};

    /* Clear the location state after obtaining it. Note that this will NOT 
    trigger a re-render.  However, if state is required for other purposes, 
    useNavigate can better manage this at the expense of a re-render. */
    if (window?.history) window.history.replaceState({}, ``);

    useEffect(() => {
        if (!message || !showMsgNotification) return;

        addNotification(message);
    }, [message, addNotification, showMsgNotification]);

    return { from, message };
};
