import { forwardRef, Ref } from "react";
import Label from "components/Label";
import Field from "components/Field";
import { Company, itemSourceForDisplay, ItemSourceType } from "company/types";
import Hint, { HintType } from "components/Hint";
import ItemSourceLogo from "company/components/items/ItemSourceLogo";

export interface ItemSourceProps {
    item: Company.Item;
}

export type ItemSourceRef = {
    name: string;
    validate: () => boolean;
};

function ItemSource({ item }: ItemSourceProps, ref: Ref<ItemSourceRef>) {
    const isLoop = item.sourceId === ItemSourceType.Loop;

    return (
        <Field>
            <Label htmlFor="name">Source</Label>
            <ItemSourceLogo itemSourceId={item.sourceId} />
            {!isLoop && (
                <Hint type={HintType.Info}>
                    Note: The disabled fields can only be edited on your{" "}
                    {itemSourceForDisplay[item.sourceId]} dashboard.
                </Hint>
            )}
        </Field>
    );
}
export default forwardRef(ItemSource);
