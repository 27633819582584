import * as S from "./style";
import { commonEmailDomains } from "default-variables";
import { useCallback, ChangeEvent, FocusEvent, useState } from "react";
import { validateEmailAddress } from "utils/formValidation";
import { checkLevenshtein } from "utils/strings";
import { regex } from "utils/regex";
import Label from "components/Label";
import Input from "components/Input";
import Field from "components/Field";
import InfoToolTip from "components/InfoToolTip";
import { useContactInfo } from "checkout/context/ContactInfo";
import Button, { ButtonVariants } from "components/Button";
import Form from "components/Form";
import Hint, { HintType } from "components/Hint";
import { useCheckoutForm } from "checkout/context/CheckoutForm";
import { CheckoutSteps } from "checkout/hooks/useCheckoutStateMachine";
import { clarity } from "react-microsoft-clarity";

const InputEmail = () => {
    const {
        inputEmail,
        isGettingEmail,
        isPostingEmail,
        setInputEmail,
        handleSaveAndOrContinue,
        isSetByQueryParams,
    } = useContactInfo();

    const [suggestion, setSuggestion] = useState<string>(``);

    const handleEmailChange = useCallback(
        ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
            setInputEmail(value);
            setSuggestion(``);

            if (clarity.hasStarted()) clarity.setTag("Email", value);
        },
        [setInputEmail]
    );

    const handleEmailBlur = useCallback(
        ({ target }: FocusEvent<HTMLInputElement>) => {
            const newEmail = target.value.trim();

            // Check email validity
            const isValid =
                newEmail.length > 0 &&
                validateEmailAddress({
                    input: target,
                    reportValidity: false,
                });

            // Check for common email domain typos
            if (isValid) {
                const emailParts = newEmail.split("@");
                const domain = checkLevenshtein(
                    emailParts[1],
                    commonEmailDomains,
                    2
                );
                if (domain) {
                    setSuggestion(`${emailParts[0]}@${domain}`);
                }
            }
        },
        []
    );

    const isEmailValid = regex.email.test(inputEmail);

    return (
        <Form onSubmit={handleSaveAndOrContinue}>
            <Field>
                <Label htmlFor="emailInput">
                    Email{" "}
                    <InfoToolTip
                        title={
                            <>
                                Providing an email address allows
                                <br />
                                you to be notified of upcoming billings,
                                <br />
                                failed transactions, and cancellations.
                                <br />
                                We'll also send reminders if your
                                <br />
                                wallet balance is insufficient.
                            </>
                        }
                    />
                </Label>
                <Input
                    type="email"
                    name="emailInput"
                    value={isGettingEmail ? `` : inputEmail || ``}
                    onChange={handleEmailChange}
                    onBlur={handleEmailBlur}
                    disabled={
                        isGettingEmail || isPostingEmail || isSetByQueryParams
                    }
                />
                {isEmailValid && suggestion && (
                    <Hint type={HintType.Error}>
                        Is it possible you meant{" "}
                        <S.Suggested
                            type="button"
                            variant={ButtonVariants.Anchor}
                            anchorVariantUnderlined={false}
                            onClick={() => {
                                setInputEmail(suggestion);
                                setSuggestion(``);
                            }}
                        >
                            {suggestion}
                        </S.Suggested>
                        ?
                    </Hint>
                )}
            </Field>
            <SaveAndContinueButton isEmailValid={isEmailValid} />
        </Form>
    );
};

const SaveAndContinueButton = ({ isEmailValid }: { isEmailValid: boolean }) => {
    const { inputEmail, isGettingEmail, isPostingEmail, isEdited } =
        useContactInfo();

    const { checkoutFormStep } = useCheckoutForm();

    if (checkoutFormStep !== CheckoutSteps.CONTACT && !isEdited) return <></>;

    return (
        <Button
            type="submit"
            full
            disabled={!inputEmail || !isEmailValid}
            loading={isGettingEmail || isPostingEmail}
        >
            {!inputEmail
                ? `Enter your email address`
                : !isEmailValid
                ? `Invalid email address`
                : isGettingEmail
                ? `Checked for existing email...`
                : isPostingEmail
                ? `Storing your email...`
                : isEdited
                ? `Save and continue`
                : `Continue`}
        </Button>
    );
};

export default InputEmail;
