import * as S from "./style";
import { Outlet } from "react-router-dom";
import { TransactionDataLoader } from "customer/types";
import Submenu from "components/Submenu";

export const transactionDataLoader = (): TransactionDataLoader => {
    return {
        sort: {
            column: 0,
            isAscending: true,
        },
        pagination: {
            perPage: 25,
            page: 1,
        },
    };
};

const Transactions = () => {
    const submenu = [
        {
            to: `upcoming`,
            label: `Upcoming`,
        },
        {
            to: `confirmed`,
            label: `Confirmed`,
        },
    ];

    return (
        <>
            <S.TypeRow>
                <Submenu data={submenu} name="Transaction types" />
            </S.TypeRow>
            <S.Transactions>
                <Outlet />
            </S.Transactions>
        </>
    );
};

export default Transactions;
