import React from "react";

const Download = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            {...props}
        >
            {name && <title>{name}</title>}
            <path
                d="M2 16c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 0 14v-3h2v3h12v-3h2v3c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 14 16H2Zm6-4L3 7l1.4-1.45L7 8.15V0h2v8.15l2.6-2.6L13 7l-5 5Z"
                fill="inherit"
            />
        </svg>
    );
};

export default Download;
