import { DetailsState, useDetailsManager } from "context/DetailsManager";
import { useEffect } from "react";
import { PanelSummaries, SelfServePanel } from "company/routes/SelfServe";
import { SelfServeState } from "./useSelfServeStateMachine";

const initialPanelState = {} as Record<SelfServePanel, Partial<DetailsState>>;

export const useSelfServePanelManager = (
    panels: PanelSummaries,
    state: { step: SelfServeState }
) => {
    const { details, setDetailsState } = useDetailsManager();

    useEffect(() => {
        Object.values(SelfServePanel).forEach((panel) => {
            initialPanelState[panel as SelfServePanel] = {
                open: false,
                disabled: true,
                summary: panels[panel as SelfServePanel].idle,
            };
        });
        setDetailsState(initialPanelState);
    }, [panels, setDetailsState]);

    useEffect(() => {
        switch (state.step) {
            case SelfServeState.ITEM:
                setDetailsState({
                    ...initialPanelState,
                    [SelfServePanel.ITEM]: {
                        open: true,
                        disabled: false,
                        summary: panels[SelfServePanel.ITEM].idle,
                    },
                });
                break;
            case SelfServeState.WALLETS:
                setDetailsState({
                    ...initialPanelState,
                    [SelfServePanel.ITEM]: {
                        open: false,
                        disabled: false,
                        summary: panels[SelfServePanel.ITEM].complete,
                    },
                    [SelfServePanel.WALLETS]: {
                        open: true,
                        disabled: false,
                        summary: panels[SelfServePanel.WALLETS].idle,
                    },
                });
                break;
            case SelfServeState.COMPANY:
                setDetailsState({
                    ...initialPanelState,
                    [SelfServePanel.ITEM]: {
                        open: false,
                        disabled: false,
                        summary: panels[SelfServePanel.ITEM].complete,
                    },
                    [SelfServePanel.WALLETS]: {
                        open: false,
                        disabled: false,
                        summary: panels[SelfServePanel.WALLETS].complete,
                    },
                    [SelfServePanel.COMPANY]: {
                        open: true,
                        disabled: false,
                        summary: panels[SelfServePanel.COMPANY].idle,
                    },
                });
                break;
            case SelfServeState.CREDENTIALS:
                setDetailsState({
                    ...details,
                    [SelfServePanel.COMPANY]: {
                        open: false,
                        disabled: false,
                        summary: panels[SelfServePanel.COMPANY].complete,
                    },
                    [SelfServePanel.CREDENTIALS]: {
                        open: true,
                        disabled: false,
                        summary: panels[SelfServePanel.CREDENTIALS].idle,
                    },
                });
                break;
            case SelfServeState.COMPLETE:
                setDetailsState({
                    ...details,
                    [SelfServePanel.CREDENTIALS]: {
                        open: false,
                        disabled: false,
                        summary: panels[SelfServePanel.CREDENTIALS].complete,
                    },
                });
                break;
        }
    }, [state.step, panels, setDetailsState]);
};
