import { useEffect, useState } from "react";
import { CheckoutItemResponse } from "api/types/checkout";
import { BaseItem } from "types/common";
import {
    minimumAllowanceAmountForCheckout,
    calculateMinimumAllowance,
    DiscountInfo,
} from "utils/checkout";

export const useMinimumAllowance = (
    invoiceAmount: number | undefined,
    items: CheckoutItemResponse[] | BaseItem[],
    minimumBalanceRequired: number | undefined,
    coupons: CouponResponse[]
) => {
    const [minimumAllowance, setMinimumAllowance] = useState<number>(0);

    useEffect(() => {
        const discountInfo: DiscountInfo = {
            discountPercentage: coupons[0]?.discountPercentage ?? null,
            discountAmount: coupons[0]?.discountAmount ?? null,
            singleUse: coupons[0]?.singleUse ?? false,
        };

        setMinimumAllowance(
            minimumAllowanceAmountForCheckout(
                calculateMinimumAllowance(
                    invoiceAmount,
                    items,
                    minimumBalanceRequired,
                    discountInfo
                )
            )
        );
    }, [invoiceAmount, items, minimumBalanceRequired, coupons]);

    return minimumAllowance;
};
