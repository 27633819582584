import { HTMLAttributes } from "react";
import * as S from "./style";

export enum Status {
    IDLE = `idle`, // looks like "disabled"
    SIGNING = `signing`, // awaiting for signing
    LOADING = `loading`, // spinning
    COMPLETE = `complete`, // checkmark
    ERROR = `error`,
}

export type StatusValues = Status[keyof Status];

const StatusUpdate = ({
    children,
    status = `idle`,
    square = false,
    ...props
}: HTMLAttributes<HTMLElement> & {
    status?: StatusValues;
    square?: boolean;
}) => {
    return (
        <S.StatusUpdate
            status={status}
            disabled={status === `idle` ? true : false}
            square={square}
            {...props}
        >
            {children}
        </S.StatusUpdate>
    );
};

export default StatusUpdate;
