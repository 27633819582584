import styled from "styled-components";
import { layout, sizes as s, colorsDeprecated as c } from "global-style";
import colors from "theme/colors";

export const Layout = styled.main`
    ${layout}
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-image: ${c.bgGradientLight};
    background-attachment: fixed;
`;
export const Header = styled.header`
    margin: 1em 0;
    display: grid;
    grid-template-columns: 1fr auto;
    @media screen and (min-width: ${s.md}) {
        grid-template-columns: 1fr auto 1fr;
        gap: 1rem;
        & > :last-child {
            justify-content: end;
        }
    }
`;
export const Logo = styled.h1`
    // font-size: 2.2em;
    // line-height: 1;
    line-height: 1.1;
    align-self: center;
    img {
        height: 1em;
        vertical-align: middle;
    }
`;
export const Nav = styled.nav`
    align-self: center;
`;
export const Menu = styled.ul`
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    gap: 1rem;
    font-size: 1.2em;
    font-weight: 700;
    a {
        color: ${colors.text};
    }
`;
export const Content = styled.article`
    flex-grow: 1;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const FooterMenu = styled(Menu)`
    font-size: 1em;
    margin: 1em 0;
    font-weight: 400;
    gap: 0;
    li {
        padding: 0 0.5em;
        line-height: 1;
    }
    li:not(:last-child) {
        border-right: 1.5px solid ${colors.text};
    }
    a:not(:hover) {
        text-decoration: none;
    }
`;
