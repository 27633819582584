import { forwardRef, Ref, useEffect, useState } from "react";
import { Authorization } from "../AuthorizationInformation";
import Anchor from "components/Anchor";
import Button from "components/Button";
import Checkmark from "components/icons/Checkmark";
import * as S from "./style";
import WalletConnect from "components/WalletConnect";
import { useEns } from "contexts/EnsProvider";
import DynamicAddressDisplay from "components/DynamicAddressDisplay";

export interface SubmitStepsProps {
    authorization?: Authorization;
    fromAddress: string;
    network: string | undefined;
    handleAuthorizeWallet: (params: any) => void;
    handleCreateTransfer: () => void;
    connectedWalledIsCertifiedSigner: boolean;
    certifiedSigner: string;
    delegatedSigning: boolean;
    buttonEnabled: boolean;
    walletTransactionsCount: number;
}

export type SubmitStepsRef = {};

function SubmitSteps(
    {
        authorization,
        fromAddress,
        network,
        handleAuthorizeWallet,
        connectedWalledIsCertifiedSigner,
        certifiedSigner,
        delegatedSigning,
        buttonEnabled,
        handleCreateTransfer,
        walletTransactionsCount,
    }: SubmitStepsProps,
    ref: Ref<SubmitStepsRef>
) {
    const [fromWallet, setFromWallet] = useState<string>(fromAddress);
    const [signerWallet, setSignerWallet] = useState<string>(certifiedSigner);
    const { getEnsRecord } = useEns();

    const hasAuthorized = authorization && fromWallet;
    const canSign =
        hasAuthorized && (connectedWalledIsCertifiedSigner || delegatedSigning);
    const enabled = canSign && buttonEnabled;

    useEffect(() => {
        (async () => {
            const ensRecordName = getEnsRecord(fromAddress)?.name;
            // Set the ensRecordName if found, else just use the new fromAddress
            if (ensRecordName)
                ensRecordName.then(
                    (n) => setFromWallet(n || fromAddress),
                    () => setFromWallet(fromAddress)
                );
            else setFromWallet(fromAddress);
        })();
    }, [fromAddress, getEnsRecord]);

    useEffect(() => {
        (async () => {
            const ensRecordName = getEnsRecord(certifiedSigner)?.name;
            // Set the ensRecordName if found, else just use the new certifiedSigner
            if (ensRecordName)
                ensRecordName.then(
                    (n) => setSignerWallet(n || certifiedSigner),
                    () => setSignerWallet(certifiedSigner)
                );
            else setSignerWallet(certifiedSigner);
        })();
    }, [certifiedSigner, getEnsRecord]);

    const addressDisplay = fromWallet && (
        <DynamicAddressDisplay
            address={fromWallet}
            networkId={network}
            underlined={false}
            icon
            anchor
        />
    );

    return (
        <>
            <S.List>
                <S.ListItem>
                    <S.ListItemIcon>
                        {hasAuthorized ? (
                            <Checkmark
                                fill="green"
                                width="1.7rem"
                                height="1.7rem"
                            />
                        ) : (
                            <S.NumberContainer>1</S.NumberContainer>
                        )}
                    </S.ListItemIcon>
                    <S.ListItemContent>
                        <p>
                            {hasAuthorized ? (
                                <>
                                    <b>Authorized</b>: {addressDisplay}
                                </>
                            ) : (
                                <>
                                    <Anchor onClick={handleAuthorizeWallet}>
                                        Authorize
                                    </Anchor>
                                    {fromWallet ? (
                                        <>: ${addressDisplay}</>
                                    ) : (
                                        " the new sender wallet"
                                    )}
                                </>
                            )}
                        </p>
                    </S.ListItemContent>
                </S.ListItem>
                {!delegatedSigning && (
                    <>
                        <S.ListItem>
                            <S.ListItemIcon>
                                {connectedWalledIsCertifiedSigner ? (
                                    <Checkmark
                                        fill="green"
                                        width="1.7rem"
                                        height="1.7rem"
                                    />
                                ) : (
                                    <S.NumberContainer>2</S.NumberContainer>
                                )}
                            </S.ListItemIcon>
                            <S.ListItemContent>
                                {connectedWalledIsCertifiedSigner ? (
                                    <>
                                        <p>Signer wallet connected</p>
                                        <small>
                                            Signer wallet: {signerWallet}
                                        </small>
                                    </>
                                ) : (
                                    <>
                                        <p>Connect signer wallet</p>
                                        <small>
                                            Signer wallet: {signerWallet}
                                        </small>
                                        <WalletConnect />
                                    </>
                                )}
                            </S.ListItemContent>
                        </S.ListItem>
                        <S.ListItem>
                            <S.ListItemIcon>
                                <S.NumberContainer>3</S.NumberContainer>
                            </S.ListItemIcon>
                            <S.ListItemContent>
                                <p>
                                    Sign {walletTransactionsCount}{" "}
                                    {walletTransactionsCount > 1
                                        ? "transactions"
                                        : "transaction"}{" "}
                                    with the signer wallet
                                </p>
                                <small>
                                    Tired of signing? You can delegate this to
                                    Loop. Ping us at{" "}
                                    <Anchor
                                        href={`mailto:${
                                            import.meta.env.VITE_EMAIL_SUPPORT
                                        }`}
                                    >
                                        {import.meta.env.VITE_EMAIL_SUPPORT}
                                    </Anchor>{" "}
                                    to find out more.
                                </small>
                                <S.ButtonContainer>
                                    <Button
                                        type="submit"
                                        disabled={!enabled}
                                        onClick={handleCreateTransfer}
                                    >
                                        Approve
                                    </Button>
                                </S.ButtonContainer>
                            </S.ListItemContent>
                        </S.ListItem>
                    </>
                )}
            </S.List>
            {delegatedSigning && (
                <S.ButtonContainer>
                    <Button
                        type="submit"
                        disabled={!enabled}
                        onClick={handleCreateTransfer}
                    >
                        Approve
                    </Button>
                </S.ButtonContainer>
            )}
        </>
    );
}
export default forwardRef(SubmitSteps);
