import {
    forwardRef,
    useImperativeHandle,
    useState,
    Ref,
    useRef,
    useEffect,
    useCallback,
} from "react";
import Label from "components/Label";
import Input from "components/Input";
import { validateEmailAddress } from "utils/formValidation";
import { SelfServeNewEntityConfig } from "api";
import * as S from "./styles";

export interface EntityReplyToFieldProps {
    disabled?: boolean;
    label?: React.ReactNode;
    defaultReplyTo?: string;
    onChange?: (replyTo: string) => void;
}

export type EntityReplyToFieldRef = {
    value: string;
    validate: () => boolean;
};

function EntityReplyToField(
    {
        disabled,
        label = "Help email",
        defaultReplyTo = "",
        onChange,
    }: EntityReplyToFieldProps,
    ref: Ref<EntityReplyToFieldRef>
) {
    const [replyTo, setReplyTo] =
        useState<SelfServeNewEntityConfig["replyTo"]>(defaultReplyTo);
    const replyToRef = useRef<HTMLInputElement>(null);
    const validationMessage = "Must be a valid email address";
    const [isValid, setIsValid] = useState<boolean>(true);

    const validate = useCallback(() => {
        if (!replyToRef.current) {
            return false;
        }

        const isValid = validateEmailAddress({
            input: replyToRef.current,
            errorMessage: validationMessage,
            reportValidity: false,
        });
        setIsValid(isValid);
        return isValid;
    }, [replyToRef]);

    useImperativeHandle(ref, () => ({ value: replyTo, validate }), [
        replyTo,
        validate,
    ]);

    useEffect(() => {
        if (onChange) onChange(replyTo);
    }, [onChange, replyTo]);

    return (
        <S.EntityReplyToField disabled={disabled}>
            <Label htmlFor="replyTo">{label}</Label>
            <Input
                innerRef={replyToRef}
                type="email"
                name="replyTo"
                placeholder="support@company.com"
                value={replyTo}
                disabled={disabled}
                onChange={(event) => setReplyTo(event.target.value)}
            />
            {!isValid && <S.ErrorMsg>{validationMessage}</S.ErrorMsg>}
        </S.EntityReplyToField>
    );
}
export default forwardRef(EntityReplyToField);
