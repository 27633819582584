import styled, { css } from "styled-components";
import { colorsDeprecated as c, properties as p } from "global-style";
import CompanyLogo from "components/CompanyLogo";
import Card from "components/Card";
import Anchor from "components/Anchor";
import colors from "theme/colors";

export const Authorization = styled(Card)`
    margin: 0;
`;

export const Entity = styled.header`
    margin-block: 0.5rem;
    h2 {
        font-size: 1.2rem;
    }
    h2:has(+ a) {
        margin-block-end: 0.5rem;
    }
    & > :last-child {
        margin-block-end: 1rem;
    }
`;

export const Logo = styled.div`
    height: 5rem;
    width: 5rem;
    border: 1px solid ${c.border};
    border-radius: 2.5rem;
    padding: 0.5rem;
    background-color: ${colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1;
    & + h2 {
        margin-block-start: 0.5rem;
    }
`;

export const NameOrLogo = styled(CompanyLogo)<{
    entity: any;
    short: any;
}>`
    font-size: 1.15rem;
    font-family: ${p.font.monospace};
    letter-spacing: -0.15ch;
    word-break: break-all;
`;

const AuthSections = css`
    border-block-start: 1px solid ${c.border};
    padding-block: 1rem;
    &:last-child {
        padding-block-end: 0;
    }
    h3 {
        margin-block: 0.5rem;
        &:has(+ ul) {
            margin-block-end: 0;
        }
    }
    li {
        margin-block: 0.5rem;
    }
    & ul > :last-child {
        margin-block-end: 0;
    }
`;

export const Allowances = styled.section`
    ${AuthSections}
`;

export const Wrappables = styled.section`
    ${AuthSections}
`;

export const Items = styled.section`
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 1fr auto;
    ${AuthSections}
`;

export const Manage = styled(Anchor)`
    text-decoration: none;
    font-weight: 700;
    align-self: center;
`;

export const CoinList = styled.ul`
    & > li {
        margin-block: 0.5rem;
    }
`;

export const HeadingWithTip = styled.header`
    display: flex;
    gap: 0.25rem;
`;

export const TipIcon = styled.span`
    align-self: flex-start;
`;

export const Cancelled = styled.del`
    text-decoration-color: ${c.lightOnSurface};
    text-decoration-thickness: 1px;
`;
