import styled from "styled-components";
import spacing from "theme/spacing";

export const SelfServeCreateItemRequestSection = styled.div`
    margin-block: ${spacing.lg} 0;
`;

export const AddItemsButtonsWrapper = styled.div`
    display: flex;
    gap: 1rem;
`;
