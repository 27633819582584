import { forwardRef, Ref } from "react";
import * as S from "./styles";
import Checkbox from "components/Checkbox";
import { toDollar } from "utils/financial";
import Icon from "components/Icon";
import {
    TokenRow,
    UpdateTokenRowProps,
} from "company/components/AllowanceNewWallet";
import { firstToUpper } from "utils/strings";
import { CommonBlockchainNetworkResponse } from "api";
import EmptyTableMessage from "components/EmptyTableMessage";
import { convertTokenToCents } from "utils/exchangeRates";

export interface TokenAllowanceTableProps {
    tokenRows: TokenRow[];
    network: CommonBlockchainNetworkResponse;
    updateTokenRow: (
        tokenRowToUpdate: TokenRow,
        updates: UpdateTokenRowProps
    ) => void;
    disabled: boolean;
}

export type TokenAllowanceTableRef = {};

function TokenAllowanceTable(
    { tokenRows, updateTokenRow, disabled, network }: TokenAllowanceTableProps,
    ref: Ref<TokenAllowanceTableRef>
) {
    const headings = [{ label: `Token` }, { label: `Set Allowance` }];

    const records: Tuple[] = tokenRows.map((tokenRow) => {
        return {
            id: `${tokenRow.token.address}-${tokenRow.token.networkId}}`,
            values: [
                {
                    label: (
                        <div
                            style={{
                                display: `flex`,
                                gap: `0.5rem`,
                                alignItems: `center`,
                            }}
                        >
                            <Checkbox
                                checked={tokenRow.selected}
                                disabled={disabled}
                                onChange={(event) =>
                                    updateTokenRow(tokenRow, {
                                        selected: event.target.checked,
                                    })
                                }
                            />
                            <Icon
                                wrapped
                                src={tokenRow.token.logoUrl}
                                alt={`${tokenRow.token.symbol} logo`}
                                round
                                tighten
                                size="2.25rem"
                            />
                            <div>
                                <b>{tokenRow.token.symbol}</b>
                                <br />
                                <small>{tokenRow.token.name}</small>
                            </div>
                        </div>
                    ),
                    value: tokenRow.token.symbol,
                    text: tokenRow.token.symbol,
                },
                {
                    label: (
                        <>
                            {
                                <>
                                    <b>
                                        <S.SmallInput
                                            type="number"
                                            name="balance"
                                            value={tokenRow.allowance}
                                            min={0}
                                            step={(
                                                1 /
                                                10 ** tokenRow.token.decimals
                                            ).toFixed(tokenRow.token.decimals)}
                                            disabled={
                                                disabled || !tokenRow.selected
                                            }
                                            onChange={(event) =>
                                                updateTokenRow(tokenRow, {
                                                    allowance: Number(
                                                        event.target.value
                                                    ),
                                                })
                                            }
                                        />
                                    </b>
                                    <br />

                                    <small>
                                        {tokenRow.usdValue !== undefined &&
                                        tokenRow.usdValue !== null
                                            ? toDollar(
                                                  convertTokenToCents(
                                                      tokenRow.allowance,
                                                      tokenRow.usdValue
                                                  )
                                              )
                                            : `Exchange rate not available`}
                                    </small>
                                </>
                            }
                        </>
                    ),
                    value: tokenRow.allowance,
                    text: tokenRow.allowance,
                },
            ],
        };
    });

    return (
        <>
            <S.TokenAllowanceTable
                data={{
                    headings: headings,
                    records: records,
                }}
                ifNoRecords={
                    <EmptyTableMessage
                        title="No tokens to display"
                        description={
                            <>
                                There are no tokens available for{" "}
                                {firstToUpper(network.name)}
                            </>
                        }
                    />
                }
            />
        </>
    );
}
export default forwardRef(TokenAllowanceTable);
