import styled, { css } from "styled-components";
import { sizes as s } from "global-style";
import Label from "components/Label";

export const SourceLabelsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media screen and (min-width: ${s.md}) {
        flex-direction: row;
        gap: 1.5rem;
    }
`;

export const SourceLabel = styled(Label)<{ disabled: boolean }>`
    margin-right: 0 !important;
    width: auto;

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.5;
            filter: grayscale(1);
            user-select: none;
        `}
`;
