import styled, { css } from "styled-components";
import {
    colorsDeprecated as c,
    properties as p,
    shadowBox,
} from "global-style";

export const Details = styled.details`
    ${shadowBox}

    h2 {
        margin: 1em 0;
    }
    & > :is(section, article) {
        margin-block: 2rem;
    }
    & > header + footer,
    & > :is(section:last-child, article:last-child) {
        margin-block: 2rem 0.5rem;
    }
    & > :is(section:first-child, article:first-child),
    & > summary:first-child + :is(section, article) {
        margin-block: 0 2rem;
    }
    & > summary:first-child + :is(section:last-child, article:last-child) {
        margin-block: 0;
    }
    & > summary + * {
        border-block-start: 1px solid ${c.border};
        padding-block-start: 1.5rem;
    }
`;

// This will end up being standard in general, realistically.
export const Summary = styled.summary<{ disabled?: boolean }>`
    && {
        cursor: pointer;
        font-size: 1.25rem;
        line-height: 1.2;
        font-weight: 700;
        padding: 0.5rem;
        margin: -0.5rem;
        position: relative;
        ${({ disabled }) => disabled && `pointer-events: none;`}

        ${Details}[open] &:not(:last-child) {
            margin-block-end: 1rem;
        }
        ${Details}:not([open]) &, &[disabled] {
            color: ${c.lightestOnSurface};
        }

        &::marker {
            content: "";
        }
        ${({ disabled }) =>
            !disabled &&
            css`
                padding-inline-end: 1.375rem;
                &::before {
                    content: "";
                    display: block;
                    font-size: 0.75em;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0.5rem;
                    margin: auto;
                    width: 1em;
                    height: 1em;
                    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.59 0.294922L6 4.87492L1.41 0.294922L0 1.70492L6 7.70492L12 1.70492L10.59 0.294922Z' fill='black' fill-opacity='0.54'/%3E%3C/svg%3E");
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: center;
                    transform: rotate(0deg);
                    transition: transform ${p.speed};
                }
            `}
        ${Details}[open] &::before {
            transform: rotate(180deg);
        }
    }
`;

// Consider how annoying it's going to be individually stylize the various places a heading-like style will be. Would need atomic css
