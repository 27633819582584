import React from "react";
import Select, { SelectProps } from "components/Select";
import * as S from "./styles";

export interface SelectWithIconProps {
    selectProps: SelectProps;
    icon?: {
        src: string;
        alt: string;
    };
    className?: string;
}

const SelectWithIcon: React.FunctionComponent<SelectWithIconProps> = ({
    selectProps,
    icon,
    className,
}) => {
    return (
        <S.LabelWrapper
            htmlFor={selectProps.name}
            className={className}
            hasIcon={!!icon?.src}
        >
            {icon?.src && (
                <S.IconContainer>
                    <img src={icon?.src} alt={icon?.alt || ``} />
                </S.IconContainer>
            )}
            <Select {...selectProps} />
        </S.LabelWrapper>
    );
};

export default SelectWithIcon;
