import { Transition, useStateMachine } from "hooks/useStateMachine";

export enum SelfServeState {
    ITEM = 0,
    WALLETS = 1,
    COMPANY = 2,
    CREDENTIALS = 3,
    COMPLETE = 4,
}

const selfServeValidTransitions: Transition<SelfServeState> = {
    [SelfServeState.ITEM]: [SelfServeState.WALLETS],
    [SelfServeState.WALLETS]: [SelfServeState.ITEM, SelfServeState.COMPANY],
    [SelfServeState.COMPANY]: [
        SelfServeState.ITEM,
        SelfServeState.WALLETS,
        SelfServeState.CREDENTIALS,
    ],
    [SelfServeState.CREDENTIALS]: [
        SelfServeState.ITEM,
        SelfServeState.WALLETS,
        SelfServeState.COMPANY,
        SelfServeState.COMPLETE,
    ],
    [SelfServeState.COMPLETE]: [],
};

export const useSelfServeStateMachine = () => {
    return useStateMachine({
        initialState: SelfServeState.ITEM,
        validTransitions: selfServeValidTransitions,
    });
};
