import * as S from "./style";
import {
    forwardRef,
    Ref,
    useRef,
    useImperativeHandle,
    useCallback,
    ChangeEvent,
} from "react";
import { validatePresence } from "utils/formValidation";
import Label from "components/Label";
import Input from "components/Input";

interface InvoiceIdProps {
    defaultValue: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const InputInvoiceId = forwardRef(
    (
        { defaultValue, onChange }: InvoiceIdProps,
        ref: Ref<InvoiceIdFieldRef>
    ) => {
        const invoiceIdRef = useRef<HTMLInputElement>(null);

        const validate = useCallback(() => {
            if (!invoiceIdRef.current) return false;

            return validatePresence({
                input: invoiceIdRef.current,
                errorMessage: `Please enter an Invoice Id`,
            });
        }, []);

        useImperativeHandle(
            ref,
            () => ({
                value: () => invoiceIdRef.current?.value || ``,
                validate,
            }),
            [validate]
        );

        const handleIdChange = useCallback(
            (event: ChangeEvent<HTMLInputElement>) => {
                if (onChange) onChange(event);
            },
            [onChange]
        );

        return (
            <S.FormFields>
                <Label htmlFor="invoiceId">Invoice Number</Label>
                <Input
                    innerRef={invoiceIdRef}
                    name="invoiceId"
                    value={defaultValue}
                    placeholder="INV-1234"
                    onChange={handleIdChange}
                />
            </S.FormFields>
        );
    }
);

export default InputInvoiceId;
