import styled from "styled-components";
import { colorsDeprecated as c, properties as p } from "global-style";
import CompanyLogo from "components/CompanyLogo";
import DynamicAddressDisplay from "components/DynamicAddressDisplay";
import Section from "components/Section";
import colors from "theme/colors";

export const Entity = styled.header`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 3rem;
    padding-block-end: 2rem;
    border-block-end: 1px solid ${c.border};
    h2 {
        font-size: 1.2rem;
    }
    h2:has(+ a) {
        margin-block-end: 0.5rem;
    }
`;

export const Logo = styled.div`
    height: 8rem;
    width: 8rem;
    border: 1px solid ${c.border};
    border-radius: 4rem;
    padding: 1rem;
    background-color: ${colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1;
    & + h2 {
        margin-block-start: 0.5rem;
    }
`;

export const CompanyInfo = styled.article`
    min-width: 0;
`;

export const NameOrLogo = styled(CompanyLogo)<{
    entity: any;
    short: any;
}>`
    font-size: 1.15rem;
    font-family: ${p.font.monospace};
    letter-spacing: -0.15ch;
    word-break: break-all;
`;

export const Contract = styled.article`
    margin-block: 0.5rem;
    h4 {
        margin-block-end: 0;
    }
`;

export const ContractAddr = styled(DynamicAddressDisplay)`
    color: ${colors.primary};
`;

export const Authorizations = styled(Section)`
    padding-block-start: 1rem;
`;

export const Filters = styled.header`
    display: flex;
    align-items: flex-end;
    gap: 1em;
    margin-block-end: 1rem;
`;

export const Download = styled.div`
    flex-grow: 1;
    text-align: right;
`;
