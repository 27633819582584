import styled, { css } from "styled-components";
import colors from "theme/colors";
import radius from "theme/radius";
import spacing from "theme/spacing";
import { fontSizes } from "theme/typography";
import { colorsDeprecated as c, sizes as s } from "global-style";
import Section from "components/Section";
import Marker from "components/Marker";
import Check from "components/icons/Check";

export const Panels = styled.article`
    margin-block-start: ${spacing.lg};
`;

export const SelfServe = styled.div`
    width: 100%;
    display: grid;
    gap: ${spacing.lg};
    margin-top: ${spacing.lg};
    grid-template-areas: "terms" "steps";

    @media screen and (min-width: ${s.lg}) {
        grid-template-columns: auto 18rem;
        grid-template-areas: "steps terms";
    }

    @media screen and (min-width: ${s.xl}) {
        grid-template-columns: auto 25rem;
    }
`;

export const Steps = styled(Section)`
    grid-area: steps;
`;

export const Terms = styled.aside`
    grid-area: terms;
    font-size: ${fontSizes.sm};
    background: ${colors.primary90};
    padding: ${spacing.xs} ${spacing.md};
    border-radius: ${radius.sm};
    border: 1px dashed ${colors.primary80};
    align-self: start;
`;

export const Summary = styled.span`
    display: flex;
    summary:not([disabled]) & {
        color: ${colors.text};
    }
`;

export const Idle = styled(Marker)<{ plainText?: boolean }>`
    margin-inline-end: 1rem;
    ${({ plainText = false }) =>
        plainText &&
        css`
            background-color: transparent;
            color: inherit;
            font-size: inherit;
        `}
    summary:is([disabled]) & {
        ${({ plainText = false }) =>
            !plainText &&
            css`
                background-color: ${c.lightestOnSurface};
                color: ${colors.white};
            `}
    }
`;

export const Complete = styled(Marker)`
    margin-inline-end: 1rem;
    background-color: ${c.secondaryLight};
`;

export const Checkmark = styled(Check)`
    height: 0.8rem;
    width: 0.8rem;
    fill: ${colors.white};
`;
