import * as S from "./style";
import {
    ReactNode,
    ReactElement,
    useEffect,
    useRef,
    useState,
    ChangeEvent,
    FormEvent,
    KeyboardEvent,
    ChangeEventHandler,
    FormEventHandler,
    KeyboardEventHandler,
} from "react";
import { regexToPattern } from "utils/regex";
import Tooltip from "components/Tooltip";
import { ButtonVariants } from "components/Button";

export enum State {
    Idle = 1,
    Editing,
    Working,
}

interface InputEditInlineProps {
    value: string | number;
    id?: string;
    state?: State | null;
    pattern?: string | RegExp;
    before?: ReactNode;
    after?: ReactNode;
    edit?: ReactNode;
    save?: ReactNode;
    cancel?: ReactNode;
    icons?: boolean;
    disabled?: boolean;
    disableSaveIfUnchanged?: boolean;
    slim?: boolean;
    placement?: S.Placement;
    onInput?: () => void;
    onCancel?: () => void;
    onSubmit?: (proposedAllowance: string) => void;
    onChange?: () => void;
    onStateChange?: (newState: State) => void;
}

const InputEditInline = ({
    value,
    id = ``,
    state,
    pattern,
    before = ``,
    after = ``,
    edit = `edit`,
    save = `save`,
    cancel = `cancel`,
    icons = false,
    disabled = false,
    disableSaveIfUnchanged = true,
    slim = false,
    placement = S.Side.RIGHT,
    onInput,
    onCancel,
    onSubmit,
    onChange,
    onStateChange,
}: InputEditInlineProps) => {
    const [liveInput, setLiveInput] = useState<string>(String(value));
    const [editingState, setEditingState] = useState<State>(
        state || State.Idle
    );
    const inputEle = useRef<HTMLInputElement>(null);

    const callSubmitAction = () => {
        if (
            !onSubmit ||
            (disableSaveIfUnchanged &&
                inputEle.current?.value.trim() === value) ||
            !inputEle.current?.value.trim() ||
            !inputEle.current?.validity.valid
        ) {
            setLiveInput(String(value));
            setEditingState(State.Idle);
            return;
        }

        setEditingState(State.Working);
        onSubmit(inputEle.current.value.trim());
    };

    const callCancelAction = () => {
        setLiveInput(String(value));
        setEditingState(State.Idle);

        if (!onCancel) return;
        onCancel();
    };

    const handleContentChange: ChangeEventHandler<HTMLInputElement> = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setLiveInput(event.target.value);
    };

    const handleEditClick = () => {
        setEditingState(State.Editing);
    };

    const handleSaveClick = () => {
        callSubmitAction();
    };

    const handleCancelClick = () => {
        callCancelAction();
    };

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (
        event: KeyboardEvent
    ) => {
        if (event.key !== `Escape`) return;
        callCancelAction();
    };

    const handleSubmit: FormEventHandler<HTMLFormElement> = (
        event: FormEvent<HTMLFormElement>
    ) => {
        event.preventDefault();
        callSubmitAction();
    };

    useEffect(() => {
        if (editingState === State.Editing && inputEle.current) {
            inputEle.current.focus();
        }

        if (!onStateChange) return;
        onStateChange(editingState);
    }, [editingState]);

    useEffect(() => {
        if (!onInput) return;
        onInput();
    }, [liveInput]);

    useEffect(() => {
        setLiveInput(String(value));
        if (!onChange) return;
        onChange();
    }, [value]);

    useEffect(() => {
        if (!state) return;
        setEditingState(state);
    }, [state]);

    const editableContent =
        editingState === State.Editing || editingState === State.Working ? (
            <S.InputContent
                name={id ? id : undefined}
                value={liveInput}
                pattern={
                    pattern instanceof RegExp
                        ? regexToPattern(pattern)
                        : pattern
                }
                size={liveInput.length || 1}
                innerRef={inputEle}
                onChange={handleContentChange}
                onKeyDown={handleKeyDown}
                disabled={disabled || editingState === State.Working}
            />
        ) : (
            <S.SavedContent>
                {before}
                {value}
                {after}
            </S.SavedContent>
        );

    const btnsOrStatus =
        editingState === State.Idle ? (
            <>
                <S.ActionBtn
                    key="edit"
                    type="button"
                    onClick={handleEditClick}
                    variant={ButtonVariants.Anchor}
                    anchorVariantUnderlined={false}
                    disabled={disabled}
                >
                    {icons ? (
                        <Tooltip
                            title={edit as ReactElement}
                            placement="top"
                            slim
                        >
                            <span>
                                <S.Edit disabled={disabled} />
                            </span>
                        </Tooltip>
                    ) : (
                        edit
                    )}
                </S.ActionBtn>
            </>
        ) : editingState === State.Editing ? (
            <>
                <S.ActionBtn
                    key="save"
                    type="submit"
                    onClick={handleSaveClick}
                    variant={ButtonVariants.Anchor}
                    anchorVariantUnderlined={false}
                    disabled={
                        disabled ||
                        (disableSaveIfUnchanged &&
                            inputEle.current?.value.trim() === value) ||
                        !inputEle.current?.value.trim() ||
                        !inputEle.current?.validity.valid
                    }
                >
                    {icons ? (
                        <Tooltip
                            title={save as ReactElement}
                            placement="top"
                            slim
                        >
                            <span>
                                <S.Save
                                    disabled={
                                        disabled ||
                                        (disableSaveIfUnchanged &&
                                            inputEle.current?.value.trim() ===
                                                value) ||
                                        !inputEle.current?.value.trim() ||
                                        !inputEle.current?.validity.valid
                                    }
                                />
                            </span>
                        </Tooltip>
                    ) : (
                        save
                    )}
                </S.ActionBtn>
                <S.ActionBtn
                    key="cancel"
                    type="button"
                    onClick={handleCancelClick}
                    variant={ButtonVariants.Anchor}
                    anchorVariantUnderlined={false}
                    disabled={disabled}
                >
                    {icons ? (
                        <Tooltip
                            title={cancel as ReactElement}
                            placement="top"
                            slim
                        >
                            <span>
                                <S.Cancel disabled={disabled} />
                            </span>
                        </Tooltip>
                    ) : (
                        cancel
                    )}
                </S.ActionBtn>
            </>
        ) : (
            <S.WorkingIcon desaturate />
        );

    return (
        <S.InputEditInline
            disabled={disabled || editingState === State.Working}
            slim={slim}
            placement={placement}
            onSubmit={handleSubmit}
            icons
        >
            {editableContent}
            {btnsOrStatus}
        </S.InputEditInline>
    );
};

export default InputEditInline;
