import * as S from "./style";
import { useState, useEffect, useCallback } from "react";
import { regex, regexToPattern } from "utils/regex";
import { toNumber } from "utils/numbers";
import { useNotificationQueue } from "context/NotificationQueue";
import { WrapperToken, wrapNativeToken } from "hooks/useWrappableTokens";
import { useSafeApi } from "hooks/useSafeApi";
import InputEditInline, { State } from "components/InputEditInline";
import { NotificationType } from "components/Notification";
import { useWalletConnected } from "context/Wallet/WalletConnectedContext";
import { EvmWalletSigner } from "types/common";

interface TokenWrappableProps {
    token: WrapperToken;
}

const TokenWrappable = ({ token }: TokenWrappableProps) => {
    const { walletConnected, getTokenBalance, safeWallet } =
        useWalletConnected();
    const { addNotification, removeNotification } = useNotificationQueue();

    const { sendWrapNativeToken } = useSafeApi(walletConnected, safeWallet);
    const [amount, setAmount] = useState<string | null>(null);
    const [state, setState] = useState<State>();

    const onSubmit = useCallback(
        async (proposedWrapAmount: string) => {
            let notificationId: string | null = null;
            try {
                notificationId = addNotification({
                    msg: `Wrapping ${proposedWrapAmount} ${token.symbol} to ${token.wrapsTo}...`,
                    type: NotificationType.WORKING,
                    expires: false,
                });
                setAmount(proposedWrapAmount);

                if (walletConnected.proxyFor) {
                    const msg = await sendWrapNativeToken({
                        token: token,
                        amount: proposedWrapAmount,
                    });
                    addNotification({
                        msg: msg,
                        type: NotificationType.SUCCESS,
                    });
                } else {
                    await wrapNativeToken(
                        token.address,
                        walletConnected.signer as EvmWalletSigner,
                        proposedWrapAmount,
                        token.symbol,
                        token.wrapsTo
                    );
                    addNotification({
                        msg: `${proposedWrapAmount} ${token.symbol} successfully wrapped to ${token.wrapsTo}`,
                        type: NotificationType.SUCCESS,
                    });
                }

                if (notificationId) removeNotification(notificationId);
            } catch (error: any) {
                if (notificationId) removeNotification(notificationId);
                addNotification({ msg: error, type: NotificationType.ERROR });
            } finally {
                const balance = await getTokenBalance({
                    tokenSymbol: token.symbol,
                });
                setAmount(balance);
                setState(State.Idle);
            }
        },
        [
            token,
            sendWrapNativeToken,
            addNotification,
            removeNotification,
            getTokenBalance,
            walletConnected.signer,
            walletConnected.proxyFor,
        ]
    );

    const onStateChange = useCallback((newState: State) => {
        setState(newState);
    }, []);

    useEffect(() => {
        (async () => {
            const balance = await getTokenBalance({
                tokenSymbol: token.symbol,
            });
            setAmount(balance);
        })();
    }, [
        token.symbol,
        token.address,
        token.decimals,
        getTokenBalance,
        walletConnected.signer,
        walletConnected.proxyFor,
    ]);

    return (
        <S.TokenWrappable disabled={state === State.Working}>
            <label title={token.name} htmlFor={token.address}>
                {token.symbol}
            </label>
            {!amount ? (
                <S.LoadingBalance desaturate />
            ) : (
                <InputEditInline
                    id={token.address}
                    value={amount || `-`}
                    edit={`wrap to ${token.wrapsTo.toLowerCase()}`}
                    save={`wrap`}
                    pattern={regexToPattern(regex.coins)}
                    state={state}
                    disabled={!amount}
                    disableSaveIfUnchanged={false}
                    onSubmit={onSubmit}
                    onStateChange={onStateChange}
                    slim
                />
            )}
        </S.TokenWrappable>
    );
};
export default TokenWrappable;
