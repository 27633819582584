import { Spacing } from "theme/spacing";
import * as S from "./styles";

export type TitleLevel = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

interface TitleProps extends React.HTMLAttributes<HTMLElement> {
    level?: TitleLevel;
    size?: TitleLevel;
    bold?: boolean;
    spacing?: Spacing;
    wrap?: boolean;
}

const Title: React.FC<TitleProps> = ({
    children,
    level = "h1",
    bold = true,
    className,
    size = level,
    spacing,
    wrap = true,
    ...props
}) => {
    return (
        <S.Title
            as={level}
            role="heading"
            $size={size}
            $bold={bold}
            $spacing={spacing}
            $wrap={wrap}
            className={className}
            {...props}
        >
            {children}
        </S.Title>
    );
};

export default Title;
