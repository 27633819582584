import styled, { css, keyframes } from "styled-components";
import {
    colorsDeprecated as c,
    properties as p,
    sizes as s,
    hideElement,
    scrollBar,
} from "global-style";
import colors from "theme/colors";
import { fontSizes } from "theme/typography";
import spacing from "theme/spacing";

interface LoopTable {
    disabled?: boolean;
}
interface LoopSortable {
    active: any;
    asc?: any;
    disabled?: boolean;
}
interface LoopRow {
    disabled: any;
    "aria-label"?: any;
    "aria-checked"?: any;
}

export const OverflowWrapper = styled.div`
    overflow-x: scroll;
    padding: 0 0.5rem 0.75rem;
    margin: 0 -0.5rem -1.25rem;
    ${scrollBar}
`;

const refetchingAnimation = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.5;
  }
`;

export const OuterWrapper = styled.div<{
    overflowShadow: boolean;
    paginating: boolean;
    refetching: boolean;
}>`
    position: relative;

    ${({ refetching }) =>
        refetching &&
        css`
            pointer-events: none;
            animation-duration: 2s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: ${refetchingAnimation};
            animation-timing-function: linear;
        `}

    ${({ overflowShadow }) =>
        overflowShadow &&
        css`
            &::before,
            &::after {
                content: "";
                width: 0.5rem;
                height: calc(100% + 0.5rem);
                position: absolute;
                top: 0;
                z-index: 1;
            }
            &::before {
                background-image: linear-gradient(
                    90deg,
                    ${colors.white},
                    transparent
                );
                left: -0.5rem;
            }
            &::after {
                background-image: linear-gradient(
                    270deg,
                    ${colors.white},
                    transparent
                );
                right: -0.5rem;
            }
        `}

    & > ${OverflowWrapper} {
        ${({ paginating }) => (paginating ? `margin: 0 -0.5rem` : ``)};
    }

    &:has(+ *) {
        ${({ paginating }) => (!paginating ? `margin-block-end: 2rem` : ``)};
    }
`;

export const Table = styled.table<LoopTable>`
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    position: relative;
    background-color: ${colors.white};
    box-shadow: ${p.shadowMd};
    line-height: 1.1rem;
    font-size: ${fontSizes.xs};
    border-radius: ${p.radiusLg};
    overflow: hidden;
    thead {
        ${hideElement}
    }

    tbody {
        display: flex;
        flex-direction: column;
    }
    tr,
    th,
    td {
        display: block;
    }
    th {
        text-align: left;
        text-transform: uppercase;
    }
    tr {
        border-bottom: 1px solid ${c.lineOnSurface};
        padding: 0.5rem;
        position: relative;
        overflow: hidden;
    }
    tbody tr:first-child {
        border-top: 1px solid ${c.lineOnSurface};
    }

    td {
        text-align: right;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-block: 0.25rem;
    }
    td::before {
        content: attr(data-label);
    }
    td::before,
    th {
        font-weight: 700;
        color: ${c.onHighlightLight};
        white-space: nowrap;
        margin-right: 1rem;
    }
    th[data-sortable="true"]:hover {
        ${({ disabled }) =>
            !disabled
                ? css`
                      cursor: pointer;
                      background-color: ${c.primaryLightest};
                  `
                : ``}
    }
    td data > * {
        margin-inline-start: auto;
    }
    td > * {
        flex-grow: 1;
        text-align: right;
        justify-content: flex-end;
    }

    @media screen and (min-width: ${s.md}) {
        table-layout: revert;
        thead {
            all: revert;
            text-align: start;
            background-color: ${colors.neutral90};
            color: ${colors.lightText};
            tr th {
                padding: ${spacing.xxs} ${spacing.xs};
            }
        }

        tbody {
            color: ${colors.black};
            tr td,
            tr[role="radio"] td label {
                padding: ${spacing.xxs} ${spacing.xs};
            }
        }

        tbody,
        tr,
        th,
        td {
            display: revert;
            box-shadow: revert;
            border-radius: revert;
            padding: revert;
            margin: revert;
        }
        tr {
            overflow: revert;
        }
        tbody tr:first-child {
            border-top: revert;
        }
        tr[role="radio"] td {
            padding: 0;
        }

        td {
            text-align: revert;
            min-height: 1.925em;
        }
        td::before {
            all: revert;
        }
        td data > * {
            margin-inline-start: revert;
        }
        td > * {
            flex-grow: revert;
            text-align: revert;
            justify-content: revert;
        }
    }
`;

export const Sortable = styled.span`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    & :first-child {
        flex-grow: 1;
    }
`;

export const Sorted = styled.span`
    color: ${colors.primary};
`;

export const Sort = styled.button`
    display: inline-grid;
    padding: 0;
    gap: 0.1em;
    background-color: transparent;
`;

export const SortArrow = styled.span<LoopSortable>`
    display: block;
    width: 12px;
    height: 6px;

    background-color: ${({ active, disabled }) =>
        active
            ? disabled
                ? c.onDisabled
                : colors.primary
            : c.lightestOnSurface};
    mask-repeat: no-repeat;
    mask-image: ${`url("data:image/svg+xml,%3csvg viewBox='348.97 233.276 6.667 3.333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='m355.636 236.61-3.333-3.334-3.333 3.333h6.666Z' /%3e%3c/svg%3e")`};

    ${({ asc }) => !asc && `transform: scaleY(-1);`};
`;

export const Nothing = styled.p`
    margin: 1rem 0;
`;

export const RadioGroup = styled.tbody`
    &:focus-within {
        outline: ${p.focus};
        outline-offset: -1px;
    }
    tr {
        position: relative;
    }
`;
export const Row = styled.tr<LoopRow>`
    &[aria-checked="true"] td,
    &[aria-checked="true"] td::before {
        background-color: ${colors.primary};
        color: ${colors.textOnPrimary};
    }
    &[aria-checked="true"] :is(a, button[data-anchor="true"]) {
        color: ${colors.textOnPrimary};
    }
    ${Table}[disabled]  &, &[disabled] {
        background-color: ${c.disabled};
        color: ${c.onDisabled};
        &,
        & * {
            cursor: auto;
            pointer-events: none;
            filter: saturate(0);
        }
    }
    ${Table}[disabled] &[aria-checked="true"]:not(:disabled) {
        background-color: ${c.primaryLightest};
    }
`;
export const Radio = styled.input`
    ${hideElement}
`;
export const Clickable = styled.label`
    display: block;
`;
