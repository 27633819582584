import { createContext, useContext, useReducer } from "react";
import RecurringSwapForm from "recurring/components/RecurringSwapForm";
import TransactionPending from "recurring/components/TransactionPending";
import SummaryAndEmail from "recurring/components/SummaryAndEmail";

// Set the initial component
const ScreenControllerContext = createContext({
    component: <RecurringSwapForm />,
});

// State reducer actions
const screenControllerState = {
    fillbuyform: `FILLBUYFORM`,
    seekallowance: `SEEKALLOWANCE`,
    callingcontract: `CALLINGCONTRACT`,
    takingemail: `TAKINGEMAIL`,
    buycomplete: `BUYCOMPLETE`,
};

const ScreenControllerProvider = ({ children }) => {
    const context = useContext(ScreenControllerContext);

    const defaultValue = {
        ...context,
        onLoadForm: () => dispatch({ type: screenControllerState.fillbuyform }),
        onFormComplete: () =>
            dispatch({ type: screenControllerState.seekallowance }),
        onAllowanceComplete: () =>
            dispatch({ type: screenControllerState.callingcontract }),
        onContractComplete: (contractAddress, networkId, walletAddress) =>
            dispatch({
                type: screenControllerState.takingemail,
                data: { contractAddress, networkId, walletAddress },
            }),
        onEmailComplete: () =>
            dispatch({ type: screenControllerState.buycomplete }),
    };

    // Determine which data is to be updated, modify only that prop within the state
    const buyReducer = (state, action) => {
        switch (action.type) {
            case screenControllerState.fillbuyform:
                return { ...state, component: <RecurringSwapForm /> };
            case screenControllerState.seekallowance:
                return { ...state, component: <TransactionPending step={1} /> };
            case screenControllerState.callingcontract:
                return { ...state, component: <TransactionPending step={2} /> };
            case screenControllerState.takingemail:
                return {
                    ...state,
                    component: <SummaryAndEmail {...action.data} />,
                };
            case screenControllerState.buycomplete:
                return { ...state, component: <RecurringSwapForm /> };
            default:
                throw new Error(`Woops, that's not a valid application state`);
        }
    };

    const [appState, dispatch] = useReducer(buyReducer, defaultValue);

    return (
        <ScreenControllerContext.Provider value={appState}>
            {children}
        </ScreenControllerContext.Provider>
    );
};

const useScreenController = () => {
    const context = useContext(ScreenControllerContext);
    if (context === undefined) {
        throw new Error(
            `useScreenController() must be used within a ScreenControllerProvider`
        );
    }
    return context;
};

export { ScreenControllerProvider, useScreenController };
