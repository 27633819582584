import * as S from "./style";
import {
    Ref,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import { SelfServeNewUser } from "api";
import UserEmailField, { UserEmailFieldRef } from "../UserEmailField";
import UserPasswordField, { UserPasswordFieldRef } from "../UserPasswordField";

interface SelfServeNewUserFormProps {
    onChange?: (selfServeNewUser: SelfServeNewUser, isValid: boolean) => void;
}

export type SelfServeNewUserFormRef = {
    validate: () => boolean;
    isValid: boolean;
    getSelfServeNewUser: () => false | SelfServeNewUser;
};

function SelfServeNewUserForm(
    { onChange }: SelfServeNewUserFormProps,
    ref: Ref<SelfServeNewUserFormRef>
) {
    const [selfServeNewUser, setSelfServeNewUser] = useState<SelfServeNewUser>({
        email: "",
        password: "",
    });
    const userEmailFieldRef = useRef<UserEmailFieldRef>(null);
    const userPasswordFieldRef = useRef<UserPasswordFieldRef>(null);
    const [isValid, setIsValid] = useState(false);
    const refs = [userEmailFieldRef, userPasswordFieldRef];

    const validate = (): boolean => {
        return refs.every((ref) => ref.current && ref.current.validate());
    };

    const getSelfServeNewUser = () => {
        if (!validate()) return false;

        const SelfServeNewUser: SelfServeNewUser = {
            email: userEmailFieldRef.current!.email,
            password: userPasswordFieldRef.current!.password,
        };

        return SelfServeNewUser;
    };

    const updateSelfServeNewUser = useCallback(() => {
        if (!userEmailFieldRef.current) return;
        if (!userPasswordFieldRef.current) return;

        setSelfServeNewUser({
            email: userEmailFieldRef.current.email,
            password: userPasswordFieldRef.current.password,
        });
        setIsValid(
            userEmailFieldRef.current.isValid &&
                userPasswordFieldRef.current.isValid
        );
    }, []);

    useImperativeHandle(ref, () => ({
        validate,
        isValid,
        getSelfServeNewUser,
    }));

    useEffect(() => {
        if (onChange) onChange(selfServeNewUser, isValid);
    }, [isValid, onChange, selfServeNewUser]);
    return (
        <S.SelfServeNewUserForm preventEnterKeySubmission>
            <UserEmailField
                ref={userEmailFieldRef}
                onChange={updateSelfServeNewUser}
            />
            <UserPasswordField
                ref={userPasswordFieldRef}
                onChange={updateSelfServeNewUser}
            />
        </S.SelfServeNewUserForm>
    );
}

export default forwardRef(SelfServeNewUserForm);
