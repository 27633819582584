import styled from "styled-components";
import { properties as p } from "global-style";
import { boxInputStyles } from "theme/shared";

export const TextArea = styled.textarea<{ resize: boolean; autoGrow: boolean }>`
    flex-grow: 1;
    width: 100%;
    line-height: 1.6;

    border-radius: ${p.radiusLg};
    max-width: 100%;

    ${({ resize }) => !resize && `resize: none;`}
    ${({ autoGrow }) => autoGrow && `overflow: hidden;`}

    ${boxInputStyles};
`;
