import { ReactElement } from "react";
import {
    useLocation,
    RouteObject,
    useMatches,
    Navigate,
} from "react-router-dom";
import { pathToText } from "utils/urls";
import { UserRole, UserTier, useUser } from "context/User";
import { NotificationType } from "components/Notification";
import { QueuedNotification } from "context/NotificationQueue";
import { enumToNumArray } from "utils/arrays";
import { useHistoryState } from "hooks/useHistoryState";
import { useQueryClient } from "@tanstack/react-query";

export interface AccessControlProps {
    rolesWithAccess?: UserRole[];
    tiersWithAccess?: UserTier[];
    minimumTierWithAccess?: UserTier;
    children: ReactElement;
}

const AccessControl = ({
    rolesWithAccess,
    tiersWithAccess,
    minimumTierWithAccess,
    children,
}: AccessControlProps) => {
    const { getSessionToken, hasRole, isTier, isAtLeastTier } = useUser();
    const location = useLocation();
    const { from: redirectPath } = useHistoryState();
    const routeVariables: RouteObject[] = useMatches();
    const queryClient = useQueryClient();

    // A role/tier was specified as required, and is not only GUEST
    const roleRequired = !!(
        rolesWithAccess &&
        rolesWithAccess.filter((r) => r !== UserRole.GUEST).length
    );
    const tierRequired = !!(
        tiersWithAccess &&
        tiersWithAccess.filter((r) => r !== UserTier.GUEST).length
    );

    if (
        !hasRole(rolesWithAccess || Object.values(UserRole)) ||
        !isTier(tiersWithAccess || enumToNumArray(UserTier)) ||
        !isAtLeastTier(minimumTierWithAccess || UserTier.GUEST) ||
        ((roleRequired || tierRequired) && !getSessionToken())
    ) {
        // Determine the last name/heading in the route-tree
        const lastName = routeVariables.findLast(
            ({ handle }: RouteObject): boolean => handle?.name
        )?.handle.name;
        const lastHeading = routeVariables.findLast(
            ({ handle }: RouteObject): boolean => handle?.heading
        )?.handle.heading;

        const pageName =
            lastName ||
            (typeof lastHeading !== `object` && lastHeading) ||
            pathToText(location.pathname);

        const message: QueuedNotification = {
            msg: `You don't have access to ${
                pageName ? `the ${pageName}` : `that`
            } page`,
            type: NotificationType.ERROR,
        };

        // Clear query cache
        queryClient.clear();

        console.log(`Redirecting to login`);

        if (location.pathname === `/`) {
            // Redirect to the login if rejecting from the root (home) page
            // Maintain the redirectPath of a page that redirected here, if one exists
            return (
                <Navigate
                    to="/login"
                    replace
                    state={{
                        from: redirectPath || location.pathname,
                    }}
                />
            );
        }

        // Redirect to the root (home) page from any other restricted route
        // Note if `/` is also restricted, the condition above will be caught and prevent an infinite loop
        return (
            <Navigate
                to="/"
                replace
                state={{
                    from: location.pathname,
                    message,
                }}
            />
        );
    }

    return children;
};

export default AccessControl;
