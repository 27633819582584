import Tooltip from "components/Tooltip";
import Info from "components/icons/Info";

const InfoToolTip = ({
    title,
    size = `1rem`,
    fill = `currentColor`,
    placement = `right`,
}: any) => {
    return (
        <Tooltip title={title} placement={placement}>
            <span>
                <Info height={size} width={size} fill={fill} />
            </span>
        </Tooltip>
    );
};

export default InfoToolTip;
