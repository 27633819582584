import * as S from "./style";
import {
    useEffect,
    useRef,
    useState,
    CSSProperties,
    isValidElement,
    memo,
    useCallback,
    ChangeEvent,
    MouseEvent,
} from "react";
import { getStrIfComponent } from "utils/components";
import Paginate from "components/Paginate";

export enum CustomCSSProperty {
    Shrink = "shrink",
    Expand = "expand",
}

/*
Incoming `data` prop should take the following form:

{
    headings: [
        { label: `Column Heading 1`, sortable: true, style: {color: `red`} },
        { label: `Column Heading 2`, sortable: false },
        { label: `Column Heading 3` },
        // ...
    ],
    records: [   // ROWS
        {
            id: [any unique identifier],
            disabled: false,
            values: [    // COLUMNS
                {label: `Some number`, value: 123, style: {color: `red`}},
                {label: `Some string`, value: `xyz`},
                {label: `Some boolean` },
                // ...
            ]
        }
    ]
}

Notes: 
- headings.length must match records[i].values.length
- headings[i].sortable is optional, will default to false
- records[i].disabled is optional, will default to false
- records[i].values.value can be omitted, will default to the label
- records[i].values.style can be set with an optional CSSProperties object
- headings[i].style can be set with an optional CSSProperties object
- When styling containers within cells, use inline over block (ie, `inline-flex` over `flex`)
*/

export const isField = (value: any): value is Field => {
    return (
        value &&
        (["string", "number", "boolean"].includes(typeof value.label) ||
            isValidElement(value.label)) &&
        (typeof value.text === "string" || typeof value.text === "number") &&
        (value.style === undefined ||
            (typeof value.style === "object" && value.style !== null))
    );
};

const isAscendingDefault = true;

const customStyles = (styles: CustomStyles): Partial<CSSProperties> => {
    const cssProps = Object.keys(styles).reduce(
        (cssProps, key: CustomCSSProperty | string | number) => {
            let declaration: {
                [index: string]: string;
            } = {};

            switch (key) {
                // Shrink a column to its content width (USE ON COLUMN HEADING)
                case CustomCSSProperty.Shrink:
                    if (styles[CustomCSSProperty.Shrink])
                        declaration[`width`] = `0px`;
                    break;
                // Expand a column's width to fit its content without wrapping
                case CustomCSSProperty.Expand:
                    if (styles[CustomCSSProperty.Expand])
                        declaration[`whiteSpace`] = `pre`;
                    break;
                default:
                    declaration[key] = styles[key];
                    break;
            }

            return { ...cssProps, ...declaration };
        },
        {}
    );
    return cssProps;
};

const getSortMethod = (direction: SortBy) => {
    const col = direction.column;
    const collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
    });

    if (direction.isAscending) {
        return (a: Tuple, b: Tuple) =>
            collator.compare(a.values[col].value, b.values[col].value);
    } else {
        return (a: Tuple, b: Tuple) =>
            collator.compare(b.values[col].value, a.values[col].value);
    }
};

const sortTable = (
    [...sortableData]: any[],
    direction: SortBy,
    onSort: ((sortMethod: any, direction?: SortBy) => void) | undefined
) => {
    const sortMethod = getSortMethod(direction);
    // Callback to caller in order to sort by same criteria
    if (onSort) {
        onSort(sortMethod, direction);
    }
    return sortableData.sort(sortMethod);
};

const Table = memo(
    ({
        data,
        defaultValue = "",
        defaultSort = {
            column: -1,
            isAscending: isAscendingDefault,
        },
        disabled = false,
        selectable = false,
        pagination,
        totalRecords,
        ifNoRecords,
        overflowShadow = true,
        refetching = false,
        externalPageAndSort = false,
        onChange,
        onPageChange,
        onSort,
        ...props
    }: TableProps) => {
        const [records, setRecords] = useState(data?.records || []);
        const [selected, setSelected] = useState(defaultValue);
        const [page, setPage] = useState(pagination?.page || 1);

        const direction = useRef(defaultSort);

        const handleClickColumnHeading = useCallback(
            (event: MouseEvent<HTMLTableCellElement>) => {
                const colIndex = Number(event.currentTarget.dataset.col);

                // Invert direction if the same column, else set the default direction on the new column
                if (colIndex === direction.current.column) {
                    direction.current.isAscending =
                        !direction.current.isAscending;
                } else {
                    direction.current = {
                        column: colIndex,
                        isAscending: isAscendingDefault,
                    };
                }

                if (!externalPageAndSort) {
                    setRecords(sortTable(records, direction.current, onSort));
                } else {
                    setRecords(records);
                    if (onSort) onSort(() => {}, direction.current);
                }
            },
            [records, onSort, externalPageAndSort]
        );

        const handleOnSelectedRowChange = useCallback(
            (event: ChangeEvent<HTMLInputElement>) => {
                if (onChange) {
                    onChange(event);
                    return; // [ ] Why not still run setSelected?
                }
                setSelected(event.target.value);
            },
            [onChange]
        );

        const handleOnPageChange = useCallback(
            (page: number) => {
                setPage(page);
                if (onPageChange) onPageChange(page);
            },
            [onPageChange]
        );

        useEffect(() => {
            setSelected(defaultValue);
        }, [defaultValue]);

        useEffect(() => {
            setPage(pagination?.page || 1);

            if (direction.current.column !== -1 && !externalPageAndSort) {
                setRecords(
                    sortTable([...data.records], direction.current, onSort)
                );
            } else {
                setRecords([...data.records]);
            }
        }, [data.records, pagination?.page, onSort, externalPageAndSort]);

        const showPagination =
            (pagination?.perPage && pagination.perPage > 0) || false;

        // [ ] Should just convert all pages to start at 0
        const startRecord =
            pagination?.perPage && !externalPageAndSort
                ? (page - 1) * pagination.perPage
                : 0;
        const endRecord =
            pagination?.perPage && !externalPageAndSort
                ? (page - 1) * pagination.perPage + pagination.perPage
                : records.length;

        return records.length ? (
            <S.OuterWrapper
                overflowShadow={overflowShadow}
                paginating={showPagination}
                refetching={refetching}
            >
                <S.OverflowWrapper>
                    <S.Table disabled={disabled} {...props}>
                        <thead>
                            <tr>
                                {data.headings
                                    .filter((heading) => !heading.hide)
                                    .map(
                                        (
                                            { label, sortable, style }: Heading,
                                            colIndex: number
                                        ) => (
                                            <th
                                                key={label}
                                                onClick={
                                                    sortable &&
                                                    !disabled &&
                                                    data.records.length > 1
                                                        ? handleClickColumnHeading
                                                        : undefined
                                                }
                                                data-col={colIndex}
                                                data-sortable={
                                                    sortable &&
                                                    !disabled &&
                                                    data.records.length > 1
                                                }
                                                style={
                                                    style && customStyles(style)
                                                }
                                            >
                                                <S.Sortable>
                                                    <span>{label}</span>
                                                    {` `}
                                                    {sortable &&
                                                        !disabled &&
                                                        data.records.length >
                                                            1 && (
                                                            <S.Sort type="button">
                                                                <S.SortArrow
                                                                    asc
                                                                    active={
                                                                        direction
                                                                            .current
                                                                            .isAscending &&
                                                                        colIndex ===
                                                                            direction
                                                                                .current
                                                                                .column
                                                                    }
                                                                    disabled={
                                                                        disabled
                                                                    }
                                                                />
                                                                <S.SortArrow
                                                                    active={
                                                                        !direction
                                                                            .current
                                                                            .isAscending &&
                                                                        colIndex ===
                                                                            direction
                                                                                .current
                                                                                .column
                                                                    }
                                                                    disabled={
                                                                        disabled
                                                                    }
                                                                />
                                                            </S.Sort>
                                                        )}
                                                </S.Sortable>
                                            </th>
                                        )
                                    )}
                            </tr>
                        </thead>
                        <S.RadioGroup
                            role={selectable ? `radiogroup` : undefined}
                            aria-label="table"
                            defaultValue={selected || defaultValue}
                        >
                            {records
                                .slice(startRecord, endRecord)
                                .map(({ id, values, disabled: d }: Tuple) =>
                                    selectable ? (
                                        <S.Row
                                            key={id}
                                            role="radio"
                                            aria-label={id}
                                            aria-checked={
                                                id === selected ||
                                                id === defaultValue
                                            }
                                            disabled={d}
                                        >
                                            {values.map(
                                                (
                                                    { label, value, style },
                                                    col
                                                ) =>
                                                    data.headings[col] &&
                                                    !data.headings[col]
                                                        .hide && (
                                                        <td
                                                            key={`${data.headings[col].label}-${value}-${col}`}
                                                            data-label={
                                                                data.headings[
                                                                    col
                                                                ].label
                                                            }
                                                            style={
                                                                style &&
                                                                customStyles(
                                                                    style
                                                                )
                                                            }
                                                        >
                                                            {col === 0 && (
                                                                <S.Radio
                                                                    type="radio"
                                                                    name="listOptions"
                                                                    id={`${id}`}
                                                                    value={id}
                                                                    checked={
                                                                        id ===
                                                                            selected ||
                                                                        id ===
                                                                            defaultValue
                                                                    }
                                                                    onChange={
                                                                        handleOnSelectedRowChange
                                                                    }
                                                                    disabled={
                                                                        d ||
                                                                        disabled
                                                                    }
                                                                />
                                                            )}
                                                            <S.Clickable
                                                                htmlFor={`${id}`}
                                                            >
                                                                <data
                                                                    value={getStrIfComponent(
                                                                        value !==
                                                                            undefined
                                                                            ? value
                                                                            : label
                                                                    )}
                                                                >
                                                                    {label || (
                                                                        <>
                                                                            &nbsp;
                                                                        </>
                                                                    )}
                                                                </data>
                                                            </S.Clickable>
                                                        </td>
                                                    )
                                            )}
                                        </S.Row>
                                    ) : (
                                        <S.Row key={id} disabled={d}>
                                            {values.map(
                                                (
                                                    { label, value, style },
                                                    col
                                                ) =>
                                                    data.headings[col] &&
                                                    !data.headings[col]
                                                        ?.hide && (
                                                        <td
                                                            key={`${data.headings[col].label}`}
                                                            data-label={
                                                                data.headings[
                                                                    col
                                                                ].label
                                                            }
                                                            style={
                                                                style &&
                                                                customStyles(
                                                                    style
                                                                )
                                                            }
                                                        >
                                                            <data
                                                                value={getStrIfComponent(
                                                                    value !==
                                                                        undefined
                                                                        ? value
                                                                        : label
                                                                )}
                                                            >
                                                                {label || (
                                                                    <>&nbsp;</>
                                                                )}
                                                            </data>
                                                        </td>
                                                    )
                                            )}
                                        </S.Row>
                                    )
                                )}
                        </S.RadioGroup>
                    </S.Table>
                </S.OverflowWrapper>
                {showPagination && (
                    <Paginate
                        totalResults={totalRecords ?? records.length}
                        perPage={pagination?.perPage}
                        page={page}
                        onPageChange={handleOnPageChange}
                        minimize={true}
                    />
                )}
            </S.OuterWrapper>
        ) : (
            <>{ifNoRecords || <S.Nothing>No data to display</S.Nothing>}</>
        );
    }
);

export default Table;
