import { forwardRef, Ref } from "react";
import { Company } from "company/types";
import Tooltip from "components/Tooltip";
import Anchor from "components/Anchor";
import Copyable from "components/Copyable";
import Cart from "components/icons/Cart";
import * as S from "./style";
import { canCheckoutItem } from "company/utils/items";

export interface ItemNameCellProps {
    item: Company.Item;
}

export type ItemNameCellRef = {};

function ItemNameCell({ item }: ItemNameCellProps, ref: Ref<ItemNameCellRef>) {
    const canCheckout = canCheckoutItem(item);
    const paymentLinkUrl = `${import.meta.env.VITE_CHECKOUT_URL}${
        item.invoiceLink
    }`;

    const checkoutBuilderUrlQuery = new URLSearchParams({
        itemId: item.id,
        sourceId: item.sourceId.toString(),
    });

    // Item is disabled
    if (!item.active) {
        return (
            <S.ItemNameCell>
                <span>{item.name}</span>
                <S.Disabled>Disabled</S.Disabled>
            </S.ItemNameCell>
        );
    }

    return (
        <S.ItemNameCell>
            <Copyable value={paymentLinkUrl}>
                <Tooltip placement="top" title={paymentLinkUrl}>
                    <span>
                        <Anchor href={paymentLinkUrl}>{item.name}</Anchor>
                    </span>
                </Tooltip>
            </Copyable>
            {canCheckout && (
                <Tooltip placement="top" title="Customize Checkout Link">
                    <S.CheckoutUrlBuilderIconWrapper>
                        <Anchor
                            href={`/checkout-url?${checkoutBuilderUrlQuery.toString()}`}
                        >
                            <Cart width="1rem" height="1rem" />
                        </Anchor>
                    </S.CheckoutUrlBuilderIconWrapper>
                </Tooltip>
            )}
        </S.ItemNameCell>
    );
}
export default forwardRef(ItemNameCell);
