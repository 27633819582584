import WalletConnectPanel from "components/WalletConnectPanel";
import * as S from "./style";
import { useEffect, useRef, useState } from "react";
import Anchor from "components/Anchor";
import WalletConnectMultiSig, {
    SafeLanguageVariant,
} from "components/WalletConnectMultiSig";
import { useWallet } from "context/Wallet";

interface WalletSwitchSectionProps {}

const WalletSwitchSection = (props: WalletSwitchSectionProps) => {
    const { walletConnected } = useWallet();
    const [hasUpdatedWallet, setHasUpdatedWallet] = useState<boolean>(false);

    const initialWallet = useRef<string | null>(null);

    useEffect(() => {
        if (!walletConnected) {
            initialWallet.current = null;
            setHasUpdatedWallet(false);
            return;
        }

        // User connected for first time
        if (initialWallet.current === null) {
            initialWallet.current = walletConnected.address;
            return;
        }

        // User has connected a different wallet
        if (initialWallet.current !== walletConnected.address) {
            setHasUpdatedWallet(true);
        }
    }, [walletConnected]);

    return (
        <S.WalletSwitchSection>
            <S.WalletSwitchHeader>Select wallet</S.WalletSwitchHeader>
            <S.WalletSwitchContent>
                {hasUpdatedWallet && (
                    <S.SuccessMessage>
                        Wallet updated! You can now close this page.{" "}
                    </S.SuccessMessage>
                )}

                <WalletConnectPanel full>
                    Connect wallet to continue
                </WalletConnectPanel>
                <S.MultiSigWrapper>
                    <WalletConnectMultiSig
                        variant={SafeLanguageVariant.Checkout}
                    />
                </S.MultiSigWrapper>
            </S.WalletSwitchContent>
            <S.WalletSwitchFooter>
                <S.Terms>
                    By connecting your wallet, you agree to our{" "}
                    <Anchor href={import.meta.env.VITE_TERMS_OF_SERVICE}>
                        Terms of service
                    </Anchor>
                    .
                </S.Terms>
            </S.WalletSwitchFooter>
        </S.WalletSwitchSection>
    );
};

export default WalletSwitchSection;
