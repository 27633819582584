import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useState,
} from "react";
import { ReactNodesAndMemoizedObjects } from "utils/components";
import { DetailsNames } from "components/Details/ManagedDetails";

export interface DetailsState {
    summary: ReactNodesAndMemoizedObjects;
    open: boolean;
    disabled: boolean;
}

interface DetailsManagerContextValue<T extends DetailsNames> {
    details: Record<T, DetailsState>;
    registerDetails: (name: DetailsNames, initialState: DetailsState) => void;
    deregisterDetails: (name: DetailsNames) => void;
    setDetailsSummary: (panel: T, summary?: ReactNode) => void;
    setDetailsOpen: (panel: T, open?: boolean) => void;
    setDetailsDisabled: (panel: T, disabled?: boolean) => void;
    setDetailsState: (panels: Record<T, Partial<DetailsState>>) => void;
}

const DetailsManagerContext =
    createContext<DetailsManagerContextValue<any> | null>(null);

const DetailsManagerProvider = <T extends DetailsNames>({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [details, setDetails] = useState<Record<T, DetailsState>>(
        {} as Record<T, DetailsState>
    );

    const registerDetails = useCallback(
        (name: DetailsNames, initialState: DetailsState) => {
            setDetails((prevDetails) => ({
                ...prevDetails,
                [name]: initialState,
            }));
        },
        []
    );

    const deregisterDetails = useCallback((name: DetailsNames) => {
        setDetails((prevDetails) => {
            const newDetails = Object.fromEntries(
                Object.entries(prevDetails).filter(
                    ([key]) => key !== name.toString()
                )
            );
            return newDetails as Record<T, DetailsState>;
        });
    }, []);

    const setDetailsSummary = useCallback(
        (panel: T, summary: ReactNode = ``) => {
            setDetails((prevDetails) => ({
                ...prevDetails,
                [panel]: { ...prevDetails[panel], summary },
            }));
        },
        []
    );

    const setDetailsOpen = useCallback((panel: T, open: boolean = true) => {
        setDetails((prevDetails) => ({
            ...prevDetails,
            [panel]: { ...prevDetails[panel], open },
        }));
    }, []);

    const setDetailsDisabled = useCallback(
        (panel: T, disabled: boolean = false) => {
            setDetails((prevDetails) => ({
                ...prevDetails,
                [panel]: { ...prevDetails[panel], disabled },
            }));
        },
        []
    );

    const setDetailsState = useCallback(
        (panels: Record<T, Partial<DetailsState>>) => {
            setDetails((prevDetails: Record<T, DetailsState>) => {
                return Object.keys(panels).reduce(
                    (acc: Record<T, DetailsState>, panel: string) => {
                        const key = panel as T;
                        return {
                            ...acc,
                            [key]: {
                                ...prevDetails[key],
                                ...panels[key],
                            },
                        };
                    },
                    { ...prevDetails }
                );
            });
        },
        []
    );

    return (
        <DetailsManagerContext.Provider
            value={{
                details,
                registerDetails,
                deregisterDetails,
                setDetailsSummary,
                setDetailsOpen,
                setDetailsDisabled,
                setDetailsState,
            }}
        >
            {children}
        </DetailsManagerContext.Provider>
    );
};

const useDetailsManager = <T extends DetailsNames>() => {
    const context = useContext(DetailsManagerContext);
    if (!context) {
        throw new Error(
            "useDetailsManager must be used within a DetailsManagerProvider"
        );
    }
    return context as DetailsManagerContextValue<T>;
};

export { DetailsManagerProvider, useDetailsManager };
