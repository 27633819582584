import styled from "styled-components";
import { properties as p } from "global-style";
import colors from "theme/colors";

export const RemoveItem = styled.button`
    position: absolute;
    appearance: none;
    background: none;
    padding: 0;
    margin: 0;
    font-size: 0;
    top: 0.75rem;
    right: 0.75rem;
    &:hover {
        svg {
            fill: ${colors.error};
        }
    }
`;

export const EditItem = styled.button`
    appearance: none;
    background: none;
    padding: 0;
    margin: 0;
    font-size: 0;
`;

export const CreateItemRequestListRow = styled.div`
    background: #f2efff;
    border: 1px solid black;
    border-radius: ${p.radiusLg};
    padding: 1rem;
    position: relative;
    &:not(:last-of-type) {
        margin-bottom: 1rem;
    }
    &:hover {
        border: 1px solid ${colors.primary};
        ${EditItem} {
            svg {
                fill: ${colors.primary};
            }
        }
    }
`;

export const ItemInformation = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`;

export const ItemAcceptedTokens = styled.small`
    margin-top: 0.5rem;
    display: inline-block;
`;
