import {
    Ref,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import * as S from "./styles";
import Button from "components/Button";
import CreateItemRequestForm from "company/components/items/CreateItemRequestForm";
import CreateItemRequestList from "company/components/items/CreateItemRequestList";
import { CreateItemRequest, CompanyFrequency } from "company/types";
import { ItemType } from "types/common-enums";
import { useModal } from "context/ModalProvider";

interface SelfServeSelfServeCreateItemRequestSectionProps {
    onChange?: (
        createItemRequests: CreateItemRequest[],
        isValid: boolean
    ) => void;
}

export type SelfServeCreateItemRequestSectionRef = {
    createItemRequests: CreateItemRequest[];
};

function SelfServeCreateItemRequestSection(
    { onChange }: SelfServeSelfServeCreateItemRequestSectionProps,
    ref: Ref<SelfServeCreateItemRequestSectionRef>
) {
    const { openModal, closeModal } = useModal();
    // Items
    const [createItemRequests, setCreateItemRequests] = useState<
        CreateItemRequest[]
    >([]);

    const removeCreateItemRequests = (item: CreateItemRequest) => {
        const updatedItem = createItemRequests.filter(
            (i) => i.name !== item.name
        );
        setCreateItemRequests(updatedItem);
    };

    const defaultSubscriptionRequest: CreateItemRequest = {
        name: "",
        amount: 1,
        frequency: CompanyFrequency.Week,
        frequencyCount: 1,
        typeId: ItemType.Subscription,
        autoInvoice: true,
    };

    const defaultOneTimePaymentRequest: CreateItemRequest = {
        name: "",
        amount: 1,
        frequencyCount: 0,
        typeId: ItemType.One_Time,
        autoInvoice: true,
    };

    const handleCreateItemRequestFormModal = (
        item: CreateItemRequest,
        overrideAmount = false
    ) => {
        openModal(
            <CreateItemRequestForm
                onSubmit={(item: CreateItemRequest) => {
                    setCreateItemRequests([item]);
                    closeModal();
                }}
                item={item}
                overrideAmount={overrideAmount}
            />,
            `Add a new item`
        );
    };

    useImperativeHandle(ref, () => ({
        createItemRequests,
    }));

    const isValid = createItemRequests.length === 1;
    useEffect(() => {
        if (onChange) onChange(createItemRequests, isValid);
    }, [isValid, onChange, createItemRequests]);

    return (
        <S.SelfServeCreateItemRequestSection>
            {createItemRequests.length > 0 ? (
                <CreateItemRequestList
                    items={createItemRequests}
                    onRemoveItem={removeCreateItemRequests}
                    onEditItem={(item) =>
                        handleCreateItemRequestFormModal(item)
                    }
                />
            ) : (
                <S.AddItemsButtonsWrapper>
                    <Button
                        onClick={() =>
                            handleCreateItemRequestFormModal(
                                defaultSubscriptionRequest,
                                true
                            )
                        }
                    >
                        Add subscription
                    </Button>
                    <Button
                        onClick={() =>
                            handleCreateItemRequestFormModal(
                                defaultOneTimePaymentRequest,
                                true
                            )
                        }
                    >
                        Add one time payment
                    </Button>
                </S.AddItemsButtonsWrapper>
            )}
        </S.SelfServeCreateItemRequestSection>
    );
}

export default forwardRef(SelfServeCreateItemRequestSection);
