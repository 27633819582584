import * as S from "./style";
import React, { useState } from "react";
import { formatUnits } from "ethers";
import { Company, TransferSource } from "company/types";
import { ItemCategoryType, TransferStatus } from "types/common-enums";
import { toCoin, toDollar } from "utils/financial";
import { getDateTimeFromSeconds } from "utils/datetime";
import { firstToUpper } from "utils/strings";
import { BlockExplorerEntityType } from "utils/urls";
import DynamicAddressDisplay from "components/DynamicAddressDisplay";
import Badge from "components/Badge";
import Anchor from "components/Anchor";
import UpdateTransferNotesForm from "company/components/transfers/UpdateTransferNotesForm";
import UpdateTransferAmountForm from "./UpdateTransferAmountForm";
import UpdateTransfeBillDateForm from "company/components/transfers/UpdateTransferBillDateForm";
import UpdateTransferTagsForm from "company/components/transfers/UpdateTransferTagsForm";
import { useUser, UserRole } from "context/User";
import { useGetCompanyAgreements } from "company/hooks/useGetCompanyAgreements";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import { useGetCompanyItems } from "company/hooks/useGetCompanyItems";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";
import FailedDataFetchingMessage from "components/FailedDataFetchingMessage";
import LoadingBox from "components/LoadingBox";
import Button, { ButtonVariants } from "components/Button";
import { PaymentPlatformUrls } from "company/utils/entities";

interface TransactionInformationProps {
    transaction: Company.Transaction;
}

// It's useful to have a <TransactionInformation> from a usability standpoint
const TransactionInformation: React.FunctionComponent<TransactionInformationProps> =
    ({ transaction }) => {
        // Session & Company data
        const { hasRole } = useUser();

        const { tokens, getTokensMetadataIsLoading, getTokensMetadataIsError } =
            useGetTokensMetadata();

        const {
            config: { entities },
            getCompanyConfigIsLoading,
            getCompanyConfigIsError,
        } = useGetCompanyConfig();

        const { networks, getNetworksIsLoading, getNetworksIsError } =
            useGetNetworks();
        const { items, getCompanyItemsIsLoading, getCompanyItemsIsError } =
            useGetCompanyItems();

        const {
            agreements,
            getCompanyAgreementsIsLoading,
            getCompanyAgreementsIsError,
        } = useGetCompanyAgreements();

        const isLoading =
            getTokensMetadataIsLoading ||
            getCompanyConfigIsLoading ||
            getNetworksIsLoading ||
            getCompanyItemsIsLoading ||
            getCompanyAgreementsIsLoading;

        const isError =
            getTokensMetadataIsError ||
            getCompanyConfigIsError ||
            getNetworksIsError ||
            getCompanyItemsIsError ||
            getCompanyAgreementsIsError;

        const agreement = agreements.find(
            (a) => a.id === transaction?.agreementId
        );
        const token = tokens.find(
            (t) =>
                t.address === agreement?.token &&
                t.networkId === agreement?.networkId
        );

        const network = networks.find(
            (network) => network.id === agreement?.networkId
        );

        const item = items.find((item) => agreement?.items.includes(item.id));

        const entity = entities.find(
            (entity) => entity.entityId === agreement?.entity
        );

        // Data & hooks
        const canManage = hasRole(UserRole.COMPANY);
        const [showTagsForm, setShowTagsForm] = useState<boolean>(false);
        const [showNotesForm, setShowNotesForm] = useState<boolean>(false);
        const [showBillDateForm, setShowBillDateForm] =
            useState<boolean>(false);

        if (isLoading) {
            return <LoadingBox height="20rem" />;
        }

        if (isError) {
            return <FailedDataFetchingMessage />;
        }

        const hasRequiredData = agreement && token && network && entity && item;

        if (!hasRequiredData) {
            return <FailedDataFetchingMessage />;
        }

        const isLoopTransfer =
            transaction.sourceId &&
            [TransferSource.Manual, TransferSource.AutoGenerated].includes(
                transaction.sourceId
            );

        const isScheduledOrDraftTransfer = [
            TransferStatus.Scheduled,
            TransferStatus.Draft,
        ].includes(transaction.status as TransferStatus);

        const canEditAmount =
            canManage &&
            entity.delegatedSigning &&
            isLoopTransfer &&
            isScheduledOrDraftTransfer &&
            transaction.elements.length === 1;

        const canEditBillDate =
            canManage &&
            entity.delegatedSigning &&
            isLoopTransfer &&
            isScheduledOrDraftTransfer;

        // Data for display
        const amountForDisplay = transaction.usd
            ? toDollar(transaction.amount)
            : toCoin(
                  Number(
                      formatUnits(String(transaction.amount), token.decimals)
                  )
              );

        /* --------------- PAID TRANSACTIONS (move to its own component) --------------- */
        const {
            date: forDate,
            time: forTime,
            zone: forZone,
        } = getDateTimeFromSeconds(transaction.billDate, true);
        const dateDueLabel = `${forDate} ${forTime} ${forZone}`;
        const transactionHash = transaction.payment?.transactionHash;
        const processedAt = transaction.payment?.processedAt;
        const paidAmount = transaction.payment?.paidAmount;

        let paidAmountForDisplay = "";
        if (paidAmount) {
            paidAmountForDisplay = toCoin(
                Number(formatUnits(String(paidAmount), token.decimals))
            );
        }

        let processedAtForDisplay = "";
        if (processedAt) {
            const {
                date: forDate,
                time: forTime,
                zone: forZone,
            } = getDateTimeFromSeconds(processedAt, true);
            processedAtForDisplay = `${forDate} ${forTime} ${forZone}`;
        }

        const paymentPlatformUrls = new PaymentPlatformUrls(
            entity.paymentPlatformProvider,
            entity.externalSite
        );

        return (
            <>
                <S.Article>
                    <S.Header level="h3" size="h4" bold={false}>
                        Invoice
                    </S.Header>
                    <ul>
                        <S.Row>
                            <dt>Due</dt>
                            <S.RowValue>
                                {!showBillDateForm ? (
                                    <S.AmountWrapper>
                                        {dateDueLabel}
                                        {canEditBillDate && (
                                            <Anchor
                                                href="#"
                                                onClick={() =>
                                                    setShowBillDateForm(true)
                                                }
                                            >
                                                Edit
                                            </Anchor>
                                        )}
                                    </S.AmountWrapper>
                                ) : (
                                    <UpdateTransfeBillDateForm
                                        transaction={transaction}
                                        onSuccess={() =>
                                            setShowBillDateForm(false)
                                        }
                                        onCancel={() =>
                                            setShowBillDateForm(false)
                                        }
                                    />
                                )}
                            </S.RowValue>
                        </S.Row>
                        <S.Row>
                            <dt>Item</dt>
                            <S.RowValue>{item.name}</S.RowValue>
                        </S.Row>
                        {agreement.externalId &&
                            item.type !== ItemCategoryType.One_Time && (
                                <S.Row>
                                    <dt>External subscription ID</dt>
                                    <S.RowValue>
                                        <Anchor
                                            href={paymentPlatformUrls.subscriptionUrl(
                                                agreement.externalId
                                            )}
                                            target="_blank"
                                        >
                                            {agreement.externalId}
                                        </Anchor>
                                    </S.RowValue>
                                </S.Row>
                            )}
                        {agreement.externalId &&
                            item.type === ItemCategoryType.One_Time && (
                                <S.Row>
                                    <dt>External Invoice ID</dt>
                                    <S.RowValue>
                                        <Anchor
                                            href={paymentPlatformUrls.invoiceUrl(
                                                agreement.externalId
                                            )}
                                            target="_blank"
                                        >
                                            {agreement.externalId}
                                        </Anchor>
                                    </S.RowValue>
                                </S.Row>
                            )}
                        {agreement.externalReferenceId && (
                            <S.Row>
                                <dt>External Reference ID</dt>
                                <S.RowValue>
                                    {agreement.externalReferenceId}
                                </S.RowValue>
                            </S.Row>
                        )}
                        <S.Row>
                            <dt>Amount</dt>
                            <S.RowValue style={{ gap: 0 }}>
                                <UpdateTransferAmountForm
                                    canEditAmount={!!canEditAmount}
                                    transaction={transaction}
                                    token={token}
                                    entity={entity}
                                    network={network}
                                    item={item}
                                />
                            </S.RowValue>
                        </S.Row>
                        <S.Row>
                            <dt>Network</dt>
                            <S.RowValue>
                                {firstToUpper(network.name)}
                            </S.RowValue>
                        </S.Row>
                        {transaction.payment && (
                            <>
                                {paidAmountForDisplay && (
                                    <S.Row>
                                        <dt>Paid</dt>
                                        <S.RowValue>
                                            {paidAmountForDisplay}
                                        </S.RowValue>
                                    </S.Row>
                                )}
                                {processedAtForDisplay && (
                                    <S.Row>
                                        <dt>
                                            {transaction.status ===
                                            TransferStatus.Cancelled
                                                ? "Canceled"
                                                : transaction.status ===
                                                  TransferStatus.Uncollectible
                                                ? "Uncollectible"
                                                : "Processed"}{" "}
                                            on
                                        </dt>
                                        <S.RowValue>
                                            {processedAtForDisplay}
                                        </S.RowValue>
                                    </S.Row>
                                )}
                                {transactionHash && (
                                    <S.Row>
                                        <dt>Transaction</dt>
                                        <S.RowValue>
                                            <DynamicAddressDisplay
                                                address={transactionHash}
                                                networkId={network.hexId}
                                                type={
                                                    BlockExplorerEntityType.Transaction
                                                }
                                                icon
                                                iconFill="currentColor"
                                                shorten
                                            ></DynamicAddressDisplay>
                                        </S.RowValue>
                                    </S.Row>
                                )}
                            </>
                        )}
                        <S.Row>
                            <dt>Tags</dt>
                            <S.RowBadgeContainer>
                                {!showTagsForm ? (
                                    <>
                                        {transaction.tags.length ? (
                                            transaction.tags.map((tag) => (
                                                <Badge key={tag.id}>
                                                    {tag.content}
                                                </Badge>
                                            ))
                                        ) : (
                                            <span>No Tags</span>
                                        )}
                                        {canManage && (
                                            <Button
                                                variant={ButtonVariants.Anchor}
                                                onClick={() =>
                                                    setShowTagsForm(true)
                                                }
                                            >
                                                Edit
                                            </Button>
                                        )}
                                    </>
                                ) : (
                                    <UpdateTransferTagsForm
                                        transaction={transaction}
                                        onSuccess={() => setShowTagsForm(false)}
                                        onCancel={() => setShowTagsForm(false)}
                                    />
                                )}
                            </S.RowBadgeContainer>
                        </S.Row>
                    </ul>
                </S.Article>
                <S.Article>
                    <S.Header level="h3" size="h4" bold={false}>
                        Internal notes
                    </S.Header>
                    {!showNotesForm ? (
                        <>
                            {transaction.notes ? (
                                <S.Notes>{transaction.notes}</S.Notes>
                            ) : (
                                <S.EmptyNotes>No notes to display</S.EmptyNotes>
                            )}
                            {canManage && (
                                <Button
                                    variant={ButtonVariants.Anchor}
                                    onClick={() => setShowNotesForm(true)}
                                >
                                    Edit
                                </Button>
                            )}
                        </>
                    ) : (
                        <UpdateTransferNotesForm
                            transaction={transaction}
                            onSuccess={() => setShowNotesForm(false)}
                            onCancel={() => setShowNotesForm(false)}
                        />
                    )}
                </S.Article>
            </>
        );
    };

export default TransactionInformation;
