import React from "react";
import * as S from "./styles";
import { SpacingTrack } from "theme/spacing";

interface FieldProps extends React.HTMLAttributes<HTMLFieldSetElement> {
    children: React.ReactNode;
    disabled?: boolean;
    spacing?: SpacingTrack;
}

// Note: useful to apply styles to field children like label and inputs
export const Field: React.FunctionComponent<FieldProps> = ({
    children,
    disabled = false,
    spacing,
    ...props
}) => {
    return (
        <S.Field disabled={disabled} spacing={spacing} {...props}>
            {children}
        </S.Field>
    );
};

export default Field;
