import styled from "styled-components";
import { colorsDeprecated as c } from "global-style";

export const WalletSwitchLayout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    position: relative;
`;

export const WalletSwitchContent = styled.main`
    max-width: 35rem;
    margin: 0 auto;
    padding: 4rem 2rem 2rem 2rem;
    flex: 1;
    padding-block-end: 5rem;
`;
