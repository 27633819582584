import { useState } from "react";
import { parseUnits } from "ethers";
import { postInvoicePayment } from "api";
import {
    CreateInvoicingPaymentRequest,
    PostInvoicePaymentResponse,
} from "api/types/invoice";
import { useWallet } from "context/Wallet";
import { useInvoicePay } from "pay/context/InvoicePay";
import { ConfirmedPayment } from "pay/types";
import { WalletTransactionData } from "context/Wallet/hooks/useWalletTransaction";

interface UsePostPaymentReturn {
    sendPayment: () => Promise<ConfirmedPayment | null>;
    loading: boolean;
    error: string;
}

export const POST_INVOICE_PAYMENT_QUERY_KEY = `postInvoicePayment`;

const usePostPayment = (): UsePostPaymentReturn => {
    const {
        entity,
        invoiceId,
        invoiceAmt,
        invoiceEmail,
        tokenAmt,
        network,
        token,
    } = useInvoicePay();

    const { walletConnected, sendTokenPayment, confirmTransaction } =
        useWallet();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const postInvoice = async (
        invoiceEntityId: string,
        invoiceId: string,
        walletAddress: string,
        tokenAddress: string,
        tokenAmount: string,
        usdAmount: string,
        emailAddress: string,
        transactionHash: string,
        networkId: number
    ): Promise<PostInvoicePaymentResponse> => {
        const request: CreateInvoicingPaymentRequest = {
            invoiceEntityId,
            invoiceId,
            walletAddress,
            tokenAddress,
            tokenAmount,
            usdAmount,
            emailAddress,
            transactionHash,
            networkId,
        };

        return await postInvoicePayment(request, {
            "Content-Type": "application/json",
        });
    };

    const sendPayment = async (): Promise<ConfirmedPayment | null> => {
        if (
            !entity ||
            !invoiceId ||
            !invoiceAmt ||
            !invoiceEmail ||
            !network ||
            !walletConnected ||
            !token ||
            !tokenAmt
        ) {
            setError("Missing required fields");
            return null;
        }

        setError("");
        setLoading(true);

        let resultData: WalletTransactionData;

        // Get the transaction data and/or hash
        try {
            resultData = await sendTokenPayment({
                token,
                amount: tokenAmt,
                toAddress: network?.treasury,
                nativeToken: token.address === network?.hexId,
            }).then((data: WalletTransactionData) => {
                if (!data?.txHash) throw new Error();
                return data;
            });
        } catch (error: any) {
            setError(
                error.code === `ACTION_REJECTED` || error.code === 4001
                    ? "Transaction rejected by your wallet"
                    : "Could not send transaction"
            );
            setLoading(false);
            return null;
        }

        // Confirm the transaction and post the payment
        try {
            await Promise.all([
                confirmTransaction(resultData).then((confirmed: boolean) => {
                    if (!confirmed)
                        throw new Error("Transaction could not be confirmed");
                    return confirmed;
                }),
                postInvoice(
                    entity.id,
                    invoiceId,
                    walletConnected?.proxyFor || walletConnected?.address,
                    token?.address,
                    parseUnits(tokenAmt, token.decimals).toString(),
                    invoiceAmt,
                    invoiceEmail,
                    resultData.txHash,
                    network.id
                ).catch((error) => {
                    console.error("Error posting invoice:", error);
                    return null; // Currently set to always move on to success without an error, even if POST fails
                }),
            ]);

            return {
                ...resultData,
                success: true,
            };
        } catch (error: any) {
            setError(error.message);
            return null;
        } finally {
            setLoading(false);
        }
    };

    return { sendPayment, loading, error };
};

export default usePostPayment;
