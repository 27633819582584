import * as S from "./style";
import { useCallback, useState } from "react";
import { useCheckoutForm } from "checkout/context/CheckoutForm";
import { useCheckoutData } from "checkout/context/CheckoutData";
import { useNetworkAndToken } from "checkout/context/NetworkAndToken";
import { CardSection } from "components/Card";
import LoadingBox from "components/LoadingBox";
import WalletConnectPanel from "components/WalletConnectPanel";
import WrapNativeTokenForm from "checkout/components/StepOneDetails/WrapNativeTokenForm";
import AlreadySubscribed from "./AlreadySubscribed";
import ButtonConfirmPayment from "./ButtonConfirmPayment";
import Notification, {
    NotificationContext,
    NotificationType,
} from "components/Notification";
import Anchor from "components/Anchor";
import Button, { ButtonVariants } from "components/Button";
import { useWallet } from "context/Wallet";

const ConnectedWalletSection = () => {
    const [showWrapMoreForm, setShowWrapMoreForm] = useState<boolean>(false);
    const { queryParams } = useCheckoutData();
    const { networkHexId } = useNetworkAndToken();
    const { walletConnected } = useWallet();

    const {
        token,
        nativeToken,
        tokenBalance,
        loadingBalance,
        hasSufficientBalance,
        wrapperBalance,
        tokenMinimumBalance,
    } = useCheckoutForm();

    const InsufficientMessageShouldWrap = () => (
        <Notification
            message={{
                id: `insufficient-message-wrap-more`,
                type: NotificationType.WARNING,
                msg: (
                    <>
                        <h4>You need more {token?.symbol}</h4>
                        <p>
                            You can wrap {nativeToken?.symbol} for{" "}
                            {token?.symbol}
                        </p>
                    </>
                ),
                expires: false,
                context: NotificationContext.STATIC,
            }}
        />
    );

    // We have enough token + wrapped token (WETH + ETH) to cover the minimum balance required
    const hasSufficientWrappableBalance =
        tokenBalance + wrapperBalance >= tokenMinimumBalance;

    // User *can* wrap
    const canWrapToCoverPayment =
        walletConnected &&
        token &&
        nativeToken &&
        !loadingBalance &&
        hasSufficientWrappableBalance;

    // user *should* wrap (to cover payment)
    const shouldWrapToCoverPayment =
        canWrapToCoverPayment && !hasSufficientBalance;

    // User *can* but not *should* wrap more
    const showWrapMoreMessage =
        token && nativeToken && !shouldWrapToCoverPayment && !loadingBalance;

    const handleWrapMoreClick = useCallback(() => {
        setShowWrapMoreForm(true);
    }, []);

    return (
        <CardSection>
            <AlreadySubscribed />
            {shouldWrapToCoverPayment ? (
                <>
                    <InsufficientMessageShouldWrap />
                    <WrapNativeTokenForm token={token} />
                </>
            ) : showWrapMoreMessage ? (
                <>
                    {!showWrapMoreForm && (
                        <S.WrapMoreMessage>
                            <Button
                                type="button"
                                onClick={handleWrapMoreClick}
                                variant={ButtonVariants.Anchor}
                                anchorVariantUnderlined={false}
                            >
                                Wrap more {nativeToken.symbol}
                            </Button>
                        </S.WrapMoreMessage>
                    )}
                    {showWrapMoreForm && (
                        <WrapNativeTokenForm
                            token={token}
                            onSuccess={() => setShowWrapMoreForm(false)}
                            onCancel={() => setShowWrapMoreForm(false)}
                        />
                    )}
                </>
            ) : (
                <></>
            )}
            <WalletConnectPanel
                full
                useCheckoutWalletSwitch={queryParams.embed}
            />
            <ButtonConfirmPayment showWrapMoreForm={showWrapMoreForm} />
            <S.Terms>
                By connecting your wallet, you agree to our{" "}
                <Anchor href={import.meta.env.VITE_TERMS_OF_SERVICE}>
                    Terms of service
                </Anchor>
                .
            </S.Terms>
        </CardSection>
    );
};

export default ConnectedWalletSection;
