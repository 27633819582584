import { Form } from "react-router-dom";
import styled from "styled-components";
import { colorsDeprecated } from "global-style";

export const AmountSetting = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0.5rem;
`;

export const AmountSettingHeader = styled.span`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const AmountSettingsValue = styled.span`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const EditForm = styled(Form)`
    display: flex;
    gap: 1rem;
    align-items: baseline;
    width: 100%;
    max-width: 20rem;
`;

export const AmountEdited = styled.span`
    color: ${colorsDeprecated.onDisabled};
`;
