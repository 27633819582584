import { useWallet } from "context/Wallet";
import { EvmWalletSigner } from "types/common";
import { WrapperToken, wrapNativeToken } from "hooks/useWrappableTokens";
import { useSafeApi } from "hooks/useSafeApi";
import { useCallback } from "react";

const errorReturn = (error: any) => {
    throw new Error(
        error.code === 4001
            ? `Authorization denied. Resend the transaction and sign from your wallet to continue`
            : typeof error === `string`
            ? error
            : `There was a problem getting authorization to swap tokens`
    );
};

const useWrapToken = (nativeToken?: WrapperToken) => {
    const { walletConnected, safeWallet } = useWallet();
    const { sendWrapNativeToken } = useSafeApi(walletConnected, safeWallet);

    const sendWrap = useCallback(
        async (wrapAmount: string | undefined): Promise<any> => {
            if (!nativeToken || !wrapAmount || !walletConnected) return;

            if (walletConnected.proxyFor) {
                return await sendWrapNativeToken({
                    token: nativeToken as WrapperToken,
                    amount: wrapAmount,
                }).catch(errorReturn);
            }

            await wrapNativeToken(
                nativeToken.address,
                walletConnected.signer as EvmWalletSigner,
                wrapAmount,
                nativeToken.symbol,
                nativeToken?.wrapsTo || ``
            ).catch(errorReturn);

            return Promise.resolve(
                `Successfully swapped ${nativeToken.symbol}${
                    nativeToken?.wrapsTo ? ` to ${nativeToken.wrapsTo}` : ``
                }`
            );
        },
        [sendWrapNativeToken, walletConnected, nativeToken]
    );

    return { sendWrap };
};

export { useWrapToken };
