import { useMemo } from "react";
import { AcceptedTokensPayload } from "company/types";
import { useGetCompanyConfig } from "./useGetCompanyConfig";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";

export const GET_COMPANY_CONFIG_QUERY_KEY = `getCompanyConfig`;

export const useAcceptedTokensByNetwork = () => {
    const {
        config: { entities, contracts },
    } = useGetCompanyConfig();

    const { tokens } = useGetTokensMetadata();
    const entity = entities[0];
    const contractVersion = entity?.contractVersion;

    const acceptedTokensByNetwork: AcceptedTokensPayload = useMemo(() => {
        let result: AcceptedTokensPayload = {};

        // Contract V1: Accepted Token stored on the contract
        if (contractVersion === 1) {
            contracts.forEach((contract) => {
                result[contract.networkId] = contract.acceptedTokens;
            });

            // Contract V2: Not stored on contract, any tokens matching the contract's networkId => supported
        } else if (contractVersion === 2) {
            contracts.forEach((contract) => {
                const tokensOnNetwork = tokens.filter(
                    (t) => t.networkId === contract.networkId && t.active
                );

                // Note: We are losing the selfServe/defaultToken
                result[contract.networkId] = tokensOnNetwork.map(
                    (t) => t.address
                );
            });
        }

        return result;
    }, [contractVersion, contracts, tokens]);

    return {
        acceptedTokensByNetwork,
    };
};
