import * as S from "./style";
import { colorsDeprecated as c } from "global-style";
import {
    forwardRef,
    Ref,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import { Company } from "company/types";
import {
    minimumBalanceRequiredAmountForCheckout,
    calculateMinimumBalance,
} from "utils/checkout";
import { toDollar } from "utils/financial";
import Button, { ButtonVariants } from "components/Button";
import Tooltip from "components/Tooltip";
import Info from "components/icons/Info";
import Anchor from "components/Anchor";
import InputWithAddon from "components/InputWithAddon";

export interface ItemsCheckoutMinimumRequiredBalanceFormProps {
    disabled?: boolean;
    items: Company.Item[];
    onChange: (minimumAllowance: number) => void;
}

export type ItemsCheckoutMinimumRequiredBalanceFormRef = {
    minimumBalance: number;
    wasEdited: boolean;
};

function ItemsCheckoutMinimumRequiredBalanceForm(
    {
        disabled = false,
        items,
        onChange,
    }: ItemsCheckoutMinimumRequiredBalanceFormProps,
    ref: Ref<ItemsCheckoutMinimumRequiredBalanceFormRef>
) {
    // Is the form being edited?
    const [showMinimumBalanceRequiredForm, setShowMinimumBalanceRequiredForm] =
        useState<boolean>(false);

    const [defaultValue, setDefaultValue] = useState<number>(
        calculateMinimumBalance(undefined, items, 0)
    );

    const [minimumBalanceRequired, setMinimumBalanceRequired] =
        useState<number>(defaultValue);

    const [wasEdited, setWasEdited] = useState<boolean>(
        minimumBalanceRequired !== defaultValue
    );

    // Input props that affect the calculation have been updated
    useEffect(() => {
        // Recalculate the new default minimum balance
        const newDefaultMinimumBalance = calculateMinimumBalance(
            undefined,
            items,
            0
        );
        setDefaultValue(newDefaultMinimumBalance);

        // If the minimum balance was previously set (ie, minimumBalanceRequired query param):
        //   use that to recalculate the new minimum balance
        //   else assign it the new default suggested allowance
        setMinimumBalanceRequired((prevMinimumBalance) => {
            return wasEdited
                ? calculateMinimumBalance(undefined, items, prevMinimumBalance)
                : newDefaultMinimumBalance;
        });
    }, [items, wasEdited]);

    // The minimum balance has been edited from its default
    useEffect(() => {
        setWasEdited(minimumBalanceRequired !== defaultValue);
    }, [defaultValue, minimumBalanceRequired]);

    // Update the parent component with the new minimum balance
    useEffect(() => {
        onChange(minimumBalanceRequired);
    }, [onChange, minimumBalanceRequired]);

    useImperativeHandle(
        ref,
        () => ({
            minimumBalance: minimumBalanceRequired,
            wasEdited,
        }),
        [minimumBalanceRequired, wasEdited]
    );

    const updateMinimumBalanceRequired = (
        event: React.FormEvent<HTMLFormElement>
    ) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const values = Object.fromEntries(formData.entries());

        const proposedMinimumBalance = calculateMinimumBalance(
            undefined,
            items,
            Number(values.minimumBalanceRequired) * 100
        );
        setMinimumBalanceRequired(proposedMinimumBalance);
        setShowMinimumBalanceRequiredForm(false);
    };

    // The "checkout equivalent" (ie: slippage included)
    const checkoutMinimumBalanceRequired =
        minimumBalanceRequiredAmountForCheckout(minimumBalanceRequired);

    return (
        <S.AmountSetting>
            <Tooltip title="A customer must authorize at least enough to cover the first payment. We add small buffer for slippage (0.5%)">
                <S.AmountSettingHeader>
                    <Info
                        height="1rem"
                        width="1rem"
                        fill={c.popoverCloseLink}
                    />
                    Minimum required balance:
                </S.AmountSettingHeader>
            </Tooltip>
            {showMinimumBalanceRequiredForm ? (
                <S.EditForm onSubmit={updateMinimumBalanceRequired}>
                    <S.InputWrapper>
                        <InputWithAddon
                            name="minimumBalanceRequired"
                            value={minimumBalanceRequired / 100}
                            type="number"
                            step={0.01}
                            addon={{
                                content: "$",
                                position: "left",
                            }}
                        />
                    </S.InputWrapper>
                    <Button type="submit" disabled={disabled}>
                        {disabled ? "Saving" : "Save"}
                    </Button>
                    <Button
                        variant={ButtonVariants.Anchor}
                        type="button"
                        disabled={disabled}
                        onClick={() => setShowMinimumBalanceRequiredForm(false)}
                    >
                        Cancel
                    </Button>
                </S.EditForm>
            ) : (
                <S.AmountSettingsValue>
                    {toDollar(checkoutMinimumBalanceRequired)}{" "}
                    {wasEdited && <S.AmountEdited>Edited</S.AmountEdited>}
                    <Anchor
                        underlined={false}
                        onClick={() => setShowMinimumBalanceRequiredForm(true)}
                    >
                        Edit
                    </Anchor>
                </S.AmountSettingsValue>
            )}
        </S.AmountSetting>
    );
}

export default forwardRef(ItemsCheckoutMinimumRequiredBalanceForm);
