import * as S from "./style";
import React, {
    ReactElement,
    ReactNode,
    FunctionComponent,
    useEffect,
    useRef,
    useState,
    useId,
    cloneElement,
} from "react";
import useOnClickOutside from "hooks/useOnClickOutside";
import useWindowSizes from "hooks/useWindowSizes";
import { createPortal } from "react-dom";

interface DropdownProps {
    children: ReactNode;
    anchorEl: ReactElement<any>;
    topExtraOffset?: number;
    className?: string;
    clickOutsideCloseDropdown?: boolean;
}

const Dropdown: FunctionComponent<DropdownProps> = ({
    children,
    anchorEl,
    topExtraOffset = 8,
    className,
    clickOutsideCloseDropdown = true,
}) => {
    const dropdownRef = useRef<HTMLUListElement>(null);
    const anchorRef = useRef<HTMLElement>(null);

    // To avoid the dropdown "sticking" to close from the container

    const { windowWidth, windowHeight, scrollTop } = useWindowSizes();
    const [open, setOpen] = useState<boolean>(false);
    const id = useId();
    const dropdownId = `dropdown-${id}`;

    // Attach ref & onclick event to the anchorElement
    const clonedAnchorEl = cloneElement(anchorEl, {
        onClick: (event: any) => {
            // Can still attach event from the parent view
            if (anchorEl.props.onClick) {
                anchorEl.props.onClick(event);
            }
            // Toggle the open state
            setOpen(!open);
        },
        "aria-expanded": open ? true : false,
        "aria-controls": dropdownId,
        "aria-haspopup": "listbox",
        ref: anchorRef,
    });

    useOnClickOutside(
        dropdownRef,
        () => clickOutsideCloseDropdown && setOpen(false)
    );

    // Calculate and refresh position of the dropdown
    useEffect(() => {
        if (open && anchorRef.current && dropdownRef.current) {
            // The anchor element position on the page & width/height
            const {
                top: anchorTop,
                left: anchorLeft,
                height: anchorHeight,
                width: anchorWidth,
            } = anchorRef.current.getBoundingClientRect();

            // Dropdown width/height
            const { width: dropdownWidth, height: dropdownHeight } =
                dropdownRef.current.getBoundingClientRect();

            // Math time - will it overflow
            const dropdownOverflowRight =
                anchorLeft + dropdownWidth > windowWidth;
            const dropdownOverflowBottom =
                anchorTop + anchorHeight + dropdownHeight > windowHeight;

            // If the dropdown overflow to the right
            if (dropdownOverflowRight) {
                const left = anchorLeft + anchorWidth - dropdownWidth;
                dropdownRef.current.style.left = `${left}px`;
            } else {
                const left = anchorLeft;
                dropdownRef.current.style.left = `${left}px`;
            }

            // If the dropdown overflow to the bottom
            if (dropdownOverflowBottom) {
                const top =
                    anchorTop - dropdownHeight - topExtraOffset + scrollTop;
                dropdownRef.current.style.top = `${top}px`;
            } else {
                const top =
                    anchorTop + anchorHeight + topExtraOffset + scrollTop;
                dropdownRef.current.style.top = `${top}px`;
            }
        }
    }, [
        anchorEl,
        dropdownRef,
        open,
        topExtraOffset,
        windowWidth,
        windowHeight,
        scrollTop,
    ]);

    // Since menu needs to be position: absolute, it needs to be outside possible relative parents
    return (
        <S.DropdownWrapper>
            {clonedAnchorEl}
            {open &&
                createPortal(
                    <S.Dropdown
                        ref={dropdownRef}
                        className={className}
                        id={dropdownId}
                        role="listbox"
                    >
                        {children}
                    </S.Dropdown>,
                    document.body
                )}
        </S.DropdownWrapper>
    );
};

export default Dropdown;
