import styled, { css } from "styled-components";
import { colorsDeprecated as c } from "global-style";
import Card, { CardSection, CardFooter } from "components/Card";
import Check from "components/icons/Check";
import Spinner from "components/Spinner";
import Exclamation from "components/icons/Exclamation";
import ThreeDotsPending from "components/icons/ThreeDotsPending";
import colors from "theme/colors";

export const CheckoutComplete = styled.main`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 100vh;
    padding: 1rem;
`;

export const CenterCard = styled(Card)`
    width: 100%;
    max-width: 26rem;
    height: fit-content;
    h1 {
        font-size: 1.25rem;
        margin: 1.25rem 0;
        text-align: center;
    }
`;

export const StatusMsg = styled.p`
    text-align: center;
`;

export const Details = styled(CardSection)`
    border-block: 1px solid ${c.border};
`;

export const Footer = styled(CardFooter)`
    margin-top: 1rem;
`;

export const Circle = styled.div<{ iconBgColor: string }>`
    display: grid;
    place-content: center;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    margin: 0 auto;
    background-color: ${({ iconBgColor }) => iconBgColor};
`;

export const Checkmark = styled(Check)`
    fill: ${c.onSecondaryDark};
`;

export const Waiting = styled(Spinner).attrs({ color: colors.textOnPrimary })`
    height: 2rem;
    width: 2rem;
`;

export const Pending = styled(ThreeDotsPending).attrs({
    fill: "transparent",
    height: "3rem",
    width: "3rem",
})``;

export const Warning = styled(Exclamation)`
    fill: ${colors.textOnWarning};
`;

export const Def = styled.span`
    text-align: end;
    & > * {
        display: block;
    }
`;

export const ItemRow = css`
    padding-block: 0.5rem;
`;

export const Loading = styled(Spinner)`
    height: 1rem;
    width: 1rem;
    vertical-align: middle;
`;
