import { Company, ItemSourceType } from "company/types";
import * as S from "./style";
import Loop from "components/icons/Loop";
import ItemSourceLogo from "../ItemSourceLogo";
import {
    PaymentPlatformUrls,
    entityPaymentPlatformSourceForDisplay,
} from "company/utils/entities";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import Anchor from "components/Anchor";
import ExternalLink from "components/icons/ExternalLink";
import Tooltip from "components/Tooltip";

export interface ItemAutoInvoiceCellProps {
    item: Company.Item;
}

export default function ItemAutoInvoiceCell({
    item,
}: ItemAutoInvoiceCellProps) {
    const isLoop = item.sourceId === ItemSourceType.Loop;
    const {
        config: { entities },
    } = useGetCompanyConfig();

    const entity = entities.find((entity) => entity.entityId === item.entityId);

    const paymentPlatformUrls =
        entity &&
        new PaymentPlatformUrls(
            entity.paymentPlatformProvider,
            entity.externalSite
        );

    const paymentPlatformSourceForDisplay =
        entity &&
        entity.paymentPlatformProvider &&
        entityPaymentPlatformSourceForDisplay[entity.paymentPlatformProvider];
    const externalItemUrl =
        item.externalId && paymentPlatformUrls?.itemUrl(item.externalId);

    return (
        <S.ItemAutoInvoiceCell>
            <ItemSourceLogo itemSourceId={item.sourceId} height="1rem" />
            {externalItemUrl && (
                <Tooltip
                    title={`View on ${paymentPlatformSourceForDisplay}`}
                    placement="top"
                    slim
                >
                    <Anchor href={externalItemUrl} target="_blank">
                        <ExternalLink height="0.75rem" width="0.75rem" />
                    </Anchor>
                </Tooltip>
            )}
            {isLoop && (
                <>
                    <span>&mdash;</span>
                    {item.autoInvoice ? (
                        <>
                            <Loop height="1rem" width="1rem" name="Automatic" />{" "}
                            Automatic
                        </>
                    ) : (
                        "Manual"
                    )}
                </>
            )}
        </S.ItemAutoInvoiceCell>
    );
}
