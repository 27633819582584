import { CommonBlockchainNetworkResponse } from "api";
import { availableNetworksForCurrentEnv } from "default-variables";

// TODO: Once we remove reliance on static, frontend networks, we can remove this
// in favor of the backend payload name (CommonBlockchainNetworkResponse)
export function getNetworkName(
    network: CommonBlockchainNetworkResponse
): string {
    const availableNetwork = availableNetworksForCurrentEnv.find(
        (n) => n.networkId === network.hexId
    );
    return availableNetwork?.label || network.name;
}
