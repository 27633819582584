import styled from "styled-components";
import { sizes } from "global-style";
import spacing from "theme/spacing";

export const HomeLinksGrid = styled.ul`
    display: grid;
    gap: ${spacing.sm};

    @media screen and (min-width: ${sizes.md}) {
        grid-template-columns: repeat(2, 1fr);
    }
`;
