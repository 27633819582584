import * as S from "./style";
import {
    Dispatch,
    Ref,
    SetStateAction,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useState,
    MouseEvent,
} from "react";
import { DynamicEmbeddedWidget } from "@dynamic-labs/sdk-react-core";
import Close from "components/icons/Close";
import { ButtonVariants } from "components/Button";
import Loading from "components/LoadingLogo";

interface WalletModalProps {
    onStateChange?: Dispatch<SetStateAction<boolean>>;
    isWalletConnecting: boolean;
    isWalletConnected: boolean;
}

export interface WalletModalRef {
    connecting: boolean;
    connect: () => void;
}

const WalletModal = forwardRef(
    (
        {
            onStateChange,
            isWalletConnecting,
            isWalletConnected,
        }: WalletModalProps,
        ref: Ref<WalletModalRef>
    ) => {
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [isWidgetOpen, setIsWidgetOpen] = useState(false);

        // Expose functionality to parent component
        useImperativeHandle(
            ref,
            () => ({
                connecting: isModalOpen && isWidgetOpen,
                connect: () => {
                    setIsModalOpen(true);
                    setIsWidgetOpen(true);
                },
            }),
            [isModalOpen, isWidgetOpen]
        );

        const handleCloseModal = useCallback(
            (event: MouseEvent<HTMLDialogElement>) => {
                event.stopPropagation();
                setIsModalOpen(false);
            },
            []
        );

        // Prevent modal from closing when widget is clicked
        const handleWidgetClick = useCallback(
            (event: MouseEvent<HTMLSpanElement>) => {
                event.stopPropagation();
            },
            []
        );

        useEffect(() => {
            // Remove the widget when a wallet is connecting, or the modal is closed
            if (isWalletConnecting || !isModalOpen) setIsWidgetOpen(false);
            // Add the widget if the modal is opened and not connecting
            if (!isWalletConnecting && isModalOpen) setIsWidgetOpen(true);
        }, [isWalletConnecting, isModalOpen]);

        useEffect(() => {
            // If the wallet is connected and its data is set
            if (!isWalletConnecting && isWalletConnected) setIsModalOpen(false);
        }, [isWalletConnected, isWalletConnecting]);

        useEffect(() => {
            // Notify parent component of connecting state change
            if (onStateChange) onStateChange(isModalOpen);
        }, [isModalOpen, onStateChange]);

        // Replace with this Modal component
        return isModalOpen ? (
            <S.WalletModal onClick={handleCloseModal} id="wallet-modal">
                <S.WidgetWrapper onClick={handleWidgetClick}>
                    {isWidgetOpen ? (
                        <>
                            <S.Close
                                variant={ButtonVariants.Anchor}
                                onClick={handleCloseModal}
                            >
                                <Close width="0.8rem" height="0.8rem" />
                            </S.Close>
                            <DynamicEmbeddedWidget
                                background="with-border"
                                style={{ width: `var(--dynamic-modal-width)` }}
                            />
                        </>
                    ) : (
                        <Loading />
                    )}
                </S.WidgetWrapper>
            </S.WalletModal>
        ) : (
            <></>
        );
    }
);

export default WalletModal;
