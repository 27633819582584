import * as S from "./style";
import { toCoin, toDollar } from "utils/financial";
import Abbr from "components/Abbr";

const TransferFeeSubtext = ({
    fee,
    paidInSymbol,
    feeInToken,
    txInToken,
    total,
    feeRate,
    isPercentage,
}: {
    fee: string;
    paidInSymbol: string;
    feeInToken: boolean;
    txInToken: boolean;
    total: string;
    feeRate: string;
    isPercentage: boolean;
}) => {
    if (!fee) return;

    const feeAmount = txInToken ? toCoin(Number(fee)) : toDollar(fee);

    return (
        <S.TransferFeeSubtext>
            + {feeRate} service fee{" "}
            {feeInToken ? (
                <>
                    (<Abbr title={`Approximately ${feeAmount}`}>~</Abbr>{" "}
                    {feeAmount} {paidInSymbol})
                </>
            ) : (
                <>
                    {txInToken ? (
                        <>
                            (<Abbr title={`Approximately ${feeAmount}`}>~</Abbr>{" "}
                            {feeAmount} in {paidInSymbol})
                        </>
                    ) : (
                        `(${
                            isPercentage ? `${feeAmount} ` : ``
                        }paid in ${paidInSymbol})`
                    )}
                </>
            )}
            <br />
            &#61; {total} {txInToken ? paidInSymbol : `in ${paidInSymbol}`}
        </S.TransferFeeSubtext>
    );
};
export default TransferFeeSubtext;
