import { getCompanyItems } from "api";
import { Company } from "company/types";
import { useMemo } from "react";
import { useAuthQuery } from "hooks/useAuthQuery";
import { useUser } from "context/User";
import { toNumber } from "utils/numbers";

export const GET_COMPANY_ITEMS_QUERY_KEY = `getCompanyItems`;

export interface CompanyItemWithNumberAmount
    extends Omit<Company.Item, "amount"> {
    amount: number | null;
}

// For the time being, map undefined amount to null and convert string amounts to number
const tempMapItemAmount = (item: Company.Item): CompanyItemWithNumberAmount =>
    item.amount === undefined
        ? { ...item, amount: null }
        : { ...item, amount: toNumber(item.amount || "0") };

export const useGetCompanyItems = () => {
    const { getEntityId, getSessionToken } = useUser();

    const {
        data: getCompanyItemsData,
        isError: getCompanyItemsIsError,
        isLoading: getCompanyItemsIsLoading,
        dataUpdatedAt: getCompanyItemsDataUpdatedAt,
        refetch: getCompanyItemsRefetch,
        isFetching: getCompanyItemsIsFetching,
    } = useAuthQuery({
        queryKey: [GET_COMPANY_ITEMS_QUERY_KEY],
        queryFn: () =>
            getCompanyItems(getEntityId(), {
                Authorization: getSessionToken(),
            }),
    });

    const items = useMemo(() => {
        return getCompanyItemsData?.items.map(tempMapItemAmount) || [];
    }, [getCompanyItemsData?.items]);

    return {
        items,
        getCompanyItemsIsError,
        getCompanyItemsIsLoading,
        getCompanyItemsIsFetching,
        getCompanyItemsDataUpdatedAt,
        getCompanyItemsRefetch,
    };
};
