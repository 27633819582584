import React from "react";

const Pencil = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            {name && <title>{name}</title>}
            <path
                d="M12.622 0 1.69 10.934 0 16l5.066-1.689L16 3.378 12.622 0ZM9.245 4.729l.675.675-6.463 6.463-.675-.676L9.245 4.73Zm-7.01 9.519-.483-.483.563-1.69 1.61 1.61-1.69.563Zm2.573-1.03-.675-.675 6.463-6.463.675.675-6.463 6.463Zm7.139-7.138L9.92 4.053l.676-.675 2.026 2.026-.675.676Zm-.676-3.378 1.351-1.351 2.027 2.027-1.351 1.35-2.027-2.026Z"
                fill="inherit"
            />
        </svg>
    );
};

export default Pencil;
