import { InputSizes } from ".";
import styled, { css } from "styled-components";
import { properties as p } from "global-style";
import { boxInputStyles } from "theme/shared";
import { fontSizes, lineHeights } from "theme/typography";
import spacing from "theme/spacing";

const inputSizeStyles = (size: InputSizes) => {
    switch (size) {
        case InputSizes.Small:
            return css`
                font-size: ${fontSizes.xs};
                padding: ${spacing.xxxs} ${spacing.xxxs};
                line-height: ${lineHeights.md};
            `;
        case InputSizes.Medium:
            return css`
                font-size: ${fontSizes.sm};
                padding: ${spacing.xxs} ${spacing.xxs};
                line-height: ${lineHeights.xl};
            `;
        case InputSizes.Large:
            return css`
                font-size: ${fontSizes.md};
                padding: ${spacing.xs} ${spacing.xs};
                line-height: ${lineHeights.xl};
            `;
    }
};

export const Input = styled.input<{
    stepper: boolean;
    slim?: boolean;
    $size: InputSizes;
}>`
    flex-grow: 1;
    width: 100%;
    line-height: ${lineHeights.xl};
    border-radius: ${p.radiusLg};
    ${boxInputStyles};
    ${({ $size }) => inputSizeStyles($size)}

    &[type="number"] {
        ${({ stepper, slim }) =>
            !stepper
                ? css`
                      appearance: none;
                      &::-webkit-outer-spin-button,
                      &::-webkit-inner-spin-button {
                          appearance: none;
                          margin: 0;
                      }
                  `
                : css`
                      padding-inline-end: ${slim ? `0.125em` : `0.25em`};
                      &:invalid {
                          background-position: calc(100% - 0.125rem) center;
                          padding-inline-end: ${slim ? `1.625em` : `1.75em`};
                      }
                  `}
    }
`;
