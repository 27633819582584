import * as S from "./style";
import { useParams } from "react-router-dom";
import TransactionHeader from "./TransactionHeader";
import TransactionSenderAndReceiver from "./TransactionSenderAndReceiver";
import TransactionInformation from "./TransactionInformation";
import { useGetCompanyTransfers } from "company/hooks/useGetCompanyTransfers";
import LoadingBox from "components/LoadingBox";
import FailedDataFetchingMessage from "components/FailedDataFetchingMessage";
import Section from "components/Section";

const TransactionDetails = () => {
    let { transactionId } = useParams();

    const {
        transactions,
        getCompanyTransfersIsLoading,
        getCompanyTransfersIsError,
        getCompanyTransfersIsFetching,
    } = useGetCompanyTransfers({ id: transactionId });

    const transaction = transactions?.[0];

    const isLoading =
        getCompanyTransfersIsLoading || getCompanyTransfersIsFetching;

    if (isLoading) {
        return <LoadingBox height="30rem" />;
    }

    if (getCompanyTransfersIsError || !transaction) {
        return <FailedDataFetchingMessage />;
    }

    return (
        <S.TransactionDetails>
            <S.TransactionDetailsContent>
                <Section>
                    <TransactionHeader transaction={transaction} />
                </Section>
                <Section>
                    <TransactionSenderAndReceiver transaction={transaction} />
                </Section>
                <Section>
                    <TransactionInformation transaction={transaction} />
                </Section>
            </S.TransactionDetailsContent>
        </S.TransactionDetails>
    );
};

export default TransactionDetails;
