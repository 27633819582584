import React from "react";

const X = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            {name && <title>{name}</title>}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m7.957 9.371-6.543 6.543L0 14.5l6.543-6.543L0 1.414 1.414 0l6.543 6.543L14.5 0l1.414 1.414-6.543 6.543 6.543 6.543-1.414 1.414-6.543-6.543Z"
                fill="inherit"
            />
        </svg>
    );
};

export default X;
