import {
    forwardRef,
    useImperativeHandle,
    useState,
    Ref,
    useRef,
    ChangeEvent,
} from "react";
import Label from "components/Label";
import Field from "components/Field";
import InputWithAddon from "components/InputWithAddon";
import Input from "components/Input";
import { validateZeroOrHigher } from "utils/formValidation";
import {
    ITEM_MAXIMUM_AMOUNT_IN_CENT,
    ITEM_MINIMUM_AMOUNT_IN_CENT,
    VARIABLE_PRICING_FOR_DISPLAY,
    ItemPriceType,
    TOKEN_PRICING_FOR_DISPLAY,
} from "utils/items";

export interface ItemAmountFieldProps {
    disabled?: boolean;
    defaultAmount?: string;
    fixedPrice: boolean;
    priceType?: ItemPriceType;
}

export type ItemAmountFieldRef = {
    amount: number;
    hasChanged: boolean;
    validate: () => boolean;
};

function ItemAmountField(
    {
        disabled,
        defaultAmount = "",
        fixedPrice,
        priceType = ItemPriceType.USD,
    }: ItemAmountFieldProps,
    ref: Ref<ItemAmountFieldRef>
) {
    const [amount, setAmount] = useState<string>(defaultAmount);
    const amountRef = useRef<HTMLInputElement>(null);
    const itemAmountValidation = `Please enter a positive amount, or use 0 for "${VARIABLE_PRICING_FOR_DISPLAY}"`;

    const validate = () => {
        if (!amountRef.current) {
            return false;
        }

        return validateZeroOrHigher({
            input: amountRef.current,
            errorMessage: itemAmountValidation,
        });
    };

    const hasChanged = amount !== defaultAmount;
    useImperativeHandle(ref, () => ({
        amount: parseFloat(amount),
        validate,
        hasChanged,
    }));

    if (!fixedPrice) {
        return (
            <Field disabled={disabled}>
                <Label htmlFor="itemAmount" subLabel="In USD">
                    Amount
                </Label>
                <Input
                    disabled={true}
                    value={VARIABLE_PRICING_FOR_DISPLAY}
                    type="text"
                />
            </Field>
        );
    }

    return (
        <Field disabled={disabled}>
            <Label
                htmlFor="itemAmount"
                subLabel={
                    priceType === ItemPriceType.USD
                        ? "In USD"
                        : TOKEN_PRICING_FOR_DISPLAY
                }
            >
                Amount
            </Label>
            <InputWithAddon
                inputProps={{
                    type: priceType === ItemPriceType.USD ? "number" : "text",
                    disabled: disabled || priceType === ItemPriceType.TOKEN,
                    name: "itemAmount",
                    placeholder: "0",
                    value: amount,
                    innerRef: amountRef,
                    onBlur: (event) =>
                        validateZeroOrHigher({
                            input: event.target,
                            reportValidity: false,
                            errorMessage: itemAmountValidation,
                        }),
                    onChange: (event: ChangeEvent<HTMLInputElement>) =>
                        setAmount(event.target.value),
                    min: ITEM_MINIMUM_AMOUNT_IN_CENT / 100,
                    max: ITEM_MAXIMUM_AMOUNT_IN_CENT / 100,
                    step: ".01",
                }}
                addon={{
                    content: priceType === ItemPriceType.USD ? "$" : " ",
                    position: "left",
                }}
            />
        </Field>
    );
}
export default forwardRef(ItemAmountField);
