import * as S from "./style";
import { HTMLAttributes } from "react";
import { useCheckoutData } from "checkout/context/CheckoutData";
import { CardHeader } from "components/Card";
import EntityLogo from "checkout/components/ItemizedCheckout/EntityLogo";
import MainItemSummary from "checkout/components/ItemizedCheckout/MainItemSummary";
import ItemList from "checkout/components/ItemizedCheckout/ItemList";

interface ItemizedCheckoutProps extends HTMLAttributes<HTMLElement> {}

const ItemizedCheckout = (props: ItemizedCheckoutProps) => {
    const {
        entity,
        queryParams: { invoiceId },
        isInvoicedCheckout,
        isExternalSubscription,
    } = useCheckoutData();
    return (
        <S.ItemizedCheckout as="article" noShadow noSurface {...props}>
            <CardHeader>
                <S.TopRow>
                    <EntityLogo entity={entity} />
                    {isInvoicedCheckout && (
                        <S.InvoiceId>{invoiceId}</S.InvoiceId>
                    )}
                </S.TopRow>
                <MainItemSummary />
            </CardHeader>
            {!isInvoicedCheckout && !isExternalSubscription && (
                <S.MiddleSection>
                    <ItemList />
                </S.MiddleSection>
            )}
        </S.ItemizedCheckout>
    );
};

export default ItemizedCheckout;
