import styled from "styled-components";
import { colorsDeprecated as c, properties as p } from "global-style";
import colors from "theme/colors";

const checkboxChecked =
    "data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.3536 0.146447C10.5488 0.341709 10.5488 0.658291 10.3536 0.853553L3.85355 7.35355C3.65829 7.54882 3.34171 7.54882 3.14645 7.35355L0.146447 4.35355C-0.0488155 4.15829 -0.0488155 3.84171 0.146447 3.64645C0.341709 3.45118 0.658291 3.45118 0.853553 3.64645L3.5 6.29289L9.64645 0.146447C9.84171 -0.0488155 10.1583 -0.0488155 10.3536 0.146447Z' fill='white'/%3E%3C/svg%3E%0A";
const checkboxIndeterminate =
    "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNyIgaGVpZ2h0PSIyOCIgdmlld0JveD0iMCAwIDI3IDI4Ij4KICA8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSIzMiIgeD0iMTEiIHk9Ii0yIiBmaWxsPSIjRkZGIiB0cmFuc2Zvcm09InJvdGF0ZSg0NSAxMy41IDE0KSIvPgo8L3N2Zz4K";
const checkboxHover =
    "data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.3536 0.146447C10.5488 0.341709 10.5488 0.658291 10.3536 0.853553L3.85355 7.35355C3.65829 7.54882 3.34171 7.54882 3.14645 7.35355L0.146447 4.35355C-0.0488155 4.15829 -0.0488155 3.84171 0.146447 3.64645C0.341709 3.45118 0.658291 3.45118 0.853553 3.64645L3.5 6.29289L9.64645 0.146447C9.84171 -0.0488155 10.1583 -0.0488155 10.3536 0.146447Z' fill='%23999999'/%3E%3C/svg%3E%0A";

export const Checkbox = styled.input`
    appearance: none;
    flex-grow: 1;
    width: 1.25em;
    height: 1.25em;
    max-width: 1.25em;
    max-height: 1.25em;
    min-width: 1.25em;
    min-height: 1.25em;
    border: 1px solid ${c.border};
    border-radius: ${p.radiusSm};
    background-size: 70%;
    background-position: 50%;
    background-repeat: no-repeat;
    margin: 0;
    &:checked {
        background-color: ${colors.primary};
        border-color: ${colors.primary};
        background-image: url("${checkboxChecked}");
    }
    &:not(:checked, :disabled):hover {
        background-image: url("${checkboxHover}");
    }
    &:indeterminate {
        background-color: ${colors.primary};
        border-color: ${colors.primary};
        background-image: url("${checkboxIndeterminate}");
    }
    &:disabled {
        background-color: ${c.inputDisabled};
        border-color: ${c.disabled};
    }
`;
