import styled from "styled-components";
import { colorsDeprecated as c } from "global-style";
import colors from "theme/colors";

export const Radio = styled.input`
    appearance: none;
    flex-grow: 1;
    width: 1em;
    height: 1em;
    max-width: 1em;
    max-height: 1em;
    min-width: 1em;
    min-height: 1em;
    border: 1px solid ${c.border};
    border-radius: 100%;
    background-size: 70%;
    background-position: 50%;
    background-repeat: no-repeat;
    &:checked {
        border: 0.3rem solid ${colors.primary};
    }
    &:not(:checked, :disabled):hover {
    }

    &:disabled {
        background-color: ${c.inputDisabled};
        border-color: ${c.disabled};
    }
`;
