import { useState, useEffect } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

export const useHistory = (defaultStack: string[] = []): string[] => {
    const [stack, setStack] = useState([...(defaultStack as never[])]);
    const { pathname } = useLocation();
    const type = useNavigationType();

    useEffect(() => {
        if (type === "POP") {
            setStack((history) => history.slice(0, history.length - 1));
        } else if (type === "PUSH") {
            setStack((history) => [...history, pathname] as never[]);
        } else {
            setStack(
                (history) =>
                    [
                        ...history.slice(0, history.length - 1),
                        pathname,
                    ] as never[]
            );
        }
    }, [pathname, type]);

    return stack;
};
