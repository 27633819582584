// The escape before the dash character is necessary for conversion to "pattern" attribute
export const regex = {
    email: /^[a-zA-Z0-9._%+\-]+@([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/, // eslint-disable-line
    password: /^.{8,}$/,
    coins: /^(?!,)(?:\d{1,3}(?:,\d{3})*|\d+)(?:\.\d*)?$/,
    dollars: /^\d*\.{0,1}\d{0,2}$/, // Allows for no digit before or after decimal. Use /^(?:\d+\.\d{0,2}|\.\d{1,2}|\d+)$/ to require at least one digit before or after decimal
    digits: /^\d+$/,
    dateTime:
        /^\d{4}-(?:0[1-9]|1[0-2])-\d{2}(?:T(?:[01]\d|2[0-3]):\d{2}(?::\d{2}(?:\.\d{1,3})?)?)?$/, // YYYY-MM-DD{THH:MM{:SS{.SSS}}}
    url: /^(https?:\/\/)([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9\-._~!$&'()*+,;=:@%]*)?(\?[a-zA-Z0-9\-._~!$&'()*+,;=:@%/]*)?$/,
    domain: /^([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/,
};

// Used to convert a regex patterns to the HTML "pattern" attribute
// This may not be a full solution, it simply strips "/^" and "$/"
export const regexToPattern = (regex: RegExp): string => {
    const slashCaretOrSlashDollar = /^\/?\^?|\/?\$?/g;
    return regex.toString().replace(slashCaretOrSlashDollar, "");
};
