import styled from "styled-components";
import { colorsDeprecated as c } from "global-style";
import Spinner from "components/Spinner";

export const TokenWrappable = styled.span<{
    disabled: boolean;
}>`
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    color: ${({ disabled }) => (disabled ? c.onDisabled : `inherit`)};
    max-width: 100%;
    overflow-x: clip;
`;

export const LoadingBalance = styled(Spinner)`
    width: 1.46875rem;
    height: 1.46875rem;
`;
