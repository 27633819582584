import React from "react";

const Route = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            {...props}
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28 21"
        >
            {name && <title>{name}</title>}
            <path
                d="M22 .333333 16.6666 5.66667h4V15c0 1.4667-1.2 2.6667-2.6666 2.6667-1.4667 0-2.6667-1.2-2.6667-2.6667V5.66667c0-2.94667-2.3867-5.333337-5.33334-5.333337-2.94667 0-5.33333 2.386667-5.33333 5.333337V15H.666626l5.333334 5.3333L11.3333 15H7.33329V5.66667C7.33329 4.2 8.53329 3 9.99996 3c1.46664 0 2.66664 1.2 2.66664 2.66667V15c0 2.9467 2.3867 5.3333 5.3334 5.3333 2.9466 0 5.3333-2.3866 5.3333-5.3333V5.66667h4L22 .333333Z"
                fill="inherit"
            />
        </svg>
    );
};

export default Route;
