import styled from "styled-components";

export const Copyable = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5em;
`;

export const Children = styled.span`
    width: 100%;
`;
