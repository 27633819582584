import {
    forwardRef,
    useImperativeHandle,
    useState,
    Ref,
    useEffect,
} from "react";
import Checkbox from "components/Checkbox";
import Label from "components/Label";

export interface TransferUsdFieldProps {
    disabled: boolean;
    defaultUsd: boolean;
    onChangeUsd?: (usd: boolean) => void;
    className?: string;
}

export type TransferUsdFieldRef = {
    usd: boolean;
    hasChanged: boolean;
    validate: () => boolean;
};

function TransferUsdField(
    { disabled, defaultUsd, onChangeUsd, className }: TransferUsdFieldProps,
    ref: Ref<TransferUsdFieldRef>
) {
    const [usd, setUsd] = useState<boolean>(defaultUsd);

    const validate = () => {
        return true;
    };

    const hasChanged = usd !== defaultUsd;

    useImperativeHandle(ref, () => ({
        usd: usd,
        validate,
        hasChanged,
    }));

    useEffect(() => {
        if (onChangeUsd) onChangeUsd(usd);
    }, [usd]);

    return (
        <Label htmlFor="usd" className={className}>
            <Checkbox
                name="usd"
                disabled={disabled}
                checked={usd}
                onChange={(event) => setUsd(event.target.checked)}
            />
            Amount in USD
        </Label>
    );
}
export default forwardRef(TransferUsdField);
