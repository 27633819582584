import * as S from "./style";
import {
    memo,
    useMemo,
    useCallback,
    useState,
    useEffect,
    useRef,
    createElement,
    forwardRef,
    Fragment,
} from "react";
import Anchor from "components/Anchor";

// NOTE: `interface LogoData` exists in src/checkout/components/ItemizedCheckout/EntityLogo.tsx

const CompanyLogo = memo(
    forwardRef(({ entity, short = false, ...props }, ref) => {
        const [logo, setLogo] = useState(<>&nbsp;</>);
        const loaded = useRef(false);

        const namingLimits = useMemo(
            () =>
                short
                    ? {
                          lineBreak: true, // Can it wrap to multiple lines?
                          acronymMin: 2, // Max words before converting to an acronym
                          maxLineLength: 5, // Characters per line
                          wrappableMaxLength: 8, // If it's a single word, this is the max chars
                      }
                    : {
                          lineBreak: false,
                          acronymMin: null,
                          maxLineLength: 100,
                          wrappableMaxLength: 100,
                      },
            [short]
        );

        const handleLogoError = useCallback(
            (event) => {
                setLogo(
                    getDisplayableName(entity?.name, namingLimits) || (
                        <>&nbsp;</>
                    )
                );
            },
            [entity?.name, namingLimits]
        );

        const handleLogoLoaded = useCallback((event) => {
            // Prevent the useEffect for `entity` from running after the image is loaded (when loaded faster than the effect has a chance to run)
            loaded.current = true;

            // Once loaded, replace the logo with only the image
            setLogo(
                createElement(
                    event.target.localName,
                    Array.from(event.target.attributes).reduce(
                        (attrs, attr) => ({
                            ...attrs,
                            [attr.nodeName]: attr.value,
                        }),
                        {}
                    )
                )
            );
        }, []);

        useEffect(() => {
            if (!entity || loaded.current) return;

            entity?.logo
                ? setLogo(
                      <>
                          <img
                              src={entity.logo}
                              alt={`${entity?.name + ` ` || ``}Logo`}
                              onError={handleLogoError}
                              onLoad={handleLogoLoaded}
                          />
                          <div className="preloadName">
                              {getDisplayableName(entity?.name, namingLimits)}
                          </div>
                      </>
                  )
                : getDisplayableName(entity?.name, namingLimits) || <>&nbsp;</>;
        }, [entity, handleLogoError, handleLogoLoaded, namingLimits]);

        return (
            <S.CompanyLogo
                {...props}
                ref={ref}
                size={short ? `short` : `long`}
                title={entity?.name}
            >
                {entity?.url ? <Anchor href={entity.url}>{logo}</Anchor> : logo}
            </S.CompanyLogo>
        );
    })
);

const getDisplayableName = (name, limit) => {
    if (!name) return;
    name = name.replace(`-`, ` `);

    if (name.includes(` `)) {
        const nameParts = name.split(` `);
        if (limit?.acronymMin && nameParts.length > limit.acronymMin) {
            // Create acronym to replace name
            return nameParts
                .reduce((acronym, part) => acronym + part.charAt(0), ``)
                .slice(0, limit?.maxLineLength || 5);
        } else {
            return (
                <>
                    {nameParts.map((word, i) => (
                        <Fragment key={word + i}>
                            {word.slice(0, limit?.maxLineLength || 5)}
                            {i < nameParts.length - 1 &&
                                (limit?.lineBreak ? <br /> : ` `)}
                        </Fragment>
                    ))}
                </>
            );
        }
    } else {
        // Single-word name, crop the name based on limit property
        return <>{name.slice(0, limit?.wrappableMaxLength || 8)}</>;
    }
};

export default CompanyLogo;
