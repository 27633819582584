import { colorsDeprecated as c } from "global-style";
import styled, { css } from "styled-components";
import Card from "components/Card";
import Title from "components/Title";
import spacing from "theme/spacing";
import Check from "components/icons/Check";

export const ConfirmedPayment = styled(Card)``;

export const CardHeading = styled(Title)`
    padding-block-end: ${spacing.sm};
    border-block-end: 1px solid ${c.border};
`;

export const ItemRow = css`
    padding-block: ${spacing.xxs};
`;

export const Circle = styled.div`
    display: inline-grid;
    place-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    margin-inline-end: ${spacing.sm};
    background-color: ${c.secondaryLight};
`;

export const Checkmark = styled(Check)`
    fill: ${c.onSecondaryDark};
    width: 0.8rem;
    height: 0.8rem;
`;
