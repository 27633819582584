import { ItemSourceType } from "company/types";
import Anchor from "components/Anchor";
import Table from "components/Table";
import { useGetCompanyItems } from "company/hooks/useGetCompanyItems";
import EmptyTableMessage from "components/EmptyTableMessage";
import useFormatCompanyItemsTable, {
    ItemTableRow,
    ItemValues,
} from "company/hooks/useFormatCompanyItemsTable";
import { rowsToTableRecords } from "utils/tables";
import LoadingBox, { LoadingPlaceholderStyle } from "components/LoadingBox";
import ItemsTableFilters from "../ItemsTableFilters";
import { useCallback, useMemo, useState } from "react";
import RefreshCounter, { CounterType } from "components/RefreshCounter";
import { ItemFrequencyNames } from "utils/items";
import { itemIsSubscription, itemIsOneTimePayment } from "company/utils/items";
import FailedDataFetchingMessage from "components/FailedDataFetchingMessage";
import { FrequencyType } from "types/common-enums";

interface ItemsTableProps {
    headings: ItemValues[];
    itemFrequency: ItemFrequencyNames;
    active: boolean;
}

const ItemsTable: React.FunctionComponent<ItemsTableProps> = ({
    headings,
    itemFrequency,
    active,
}) => {
    const {
        items,
        getCompanyItemsIsFetching,
        getCompanyItemsIsLoading,
        getCompanyItemsDataUpdatedAt,
        getCompanyItemsRefetch,
        getCompanyItemsIsError,
    } = useGetCompanyItems();

    const itemsForTable = useMemo(() => {
        return items.filter((item) => {
            const frequencyMatch =
                (itemFrequency === ItemFrequencyNames.Subscription &&
                    itemIsSubscription(item)) ||
                (itemFrequency === ItemFrequencyNames.OneTimePayment &&
                    itemIsOneTimePayment(item));

            const activeMatch = item.active === active;

            return frequencyMatch && activeMatch;
        });
    }, [active, itemFrequency, items]);

    const emptyTableMessageTitle = useMemo(() => {
        if (itemFrequency === ItemFrequencyNames.Subscription) {
            return active
                ? "No subscriptions to display yet"
                : "No inactive subscriptions to display";
        } else if (itemFrequency === ItemFrequencyNames.OneTimePayment) {
            return active
                ? "No one-time purchase to display yet"
                : "No inactive one-time purchase to display";
        } else {
            return "No items to display";
        }
    }, [active, itemFrequency]);

    const {
        getItemsTableHeadings,
        itemTableRows,
        filterItemTableRow,
        isLoading,
    } = useFormatCompanyItemsTable(itemsForTable);

    const [filteredItemsTableRows, setFilteredItemsTableRow] =
        useState<ItemTableRow[]>(itemTableRows);

    const subscriptionTableHeading = getItemsTableHeadings(headings);

    const searchResults: Tuple[] = rowsToTableRecords(
        filteredItemsTableRows,
        subscriptionTableHeading
    );

    const onFilterChange = useCallback(
        (
            search: string,
            selectedSourceIds: ItemSourceType[],
            selectedFrequencyTypes: FrequencyType[]
        ) => {
            setFilteredItemsTableRow(
                filterItemTableRow(
                    itemTableRows,
                    search,
                    selectedSourceIds,
                    selectedFrequencyTypes
                )
            );
        },
        [filterItemTableRow, itemTableRows]
    );

    const tableIsLoading = getCompanyItemsIsLoading || isLoading;

    return (
        <>
            {itemsForTable.length > 0 && (
                <ItemsTableFilters
                    items={itemsForTable}
                    headings={headings}
                    onChange={onFilterChange}
                />
            )}
            <RefreshCounter
                refreshedAt={getCompanyItemsDataUpdatedAt}
                counter={CounterType.Running}
                onRefresh={getCompanyItemsRefetch}
                refreshing={getCompanyItemsIsFetching}
            />

            {tableIsLoading ? (
                <LoadingBox
                    height="18rem"
                    tablePlaceholderCols={headings.length}
                    placeholderStyle={LoadingPlaceholderStyle.Table}
                />
            ) : getCompanyItemsIsError ? (
                <FailedDataFetchingMessage />
            ) : (
                <Table
                    data={{
                        headings: subscriptionTableHeading,
                        records: searchResults,
                    }}
                    refetching={getCompanyItemsIsFetching}
                    ifNoRecords={
                        <EmptyTableMessage
                            title={emptyTableMessageTitle}
                            description={
                                <>
                                    Need help? Take a look at our{" "}
                                    <Anchor
                                        href={
                                            import.meta.env
                                                .VITE_LOOP_DOCS_API_ENABLED_CONTRACT_ADDING_ITEMS
                                        }
                                    >
                                        documentation
                                    </Anchor>{" "}
                                    or{" "}
                                    <Anchor
                                        href={`mailto:${
                                            import.meta.env.VITE_EMAIL_SUPPORT
                                        }`}
                                    >
                                        contact us
                                    </Anchor>
                                    .
                                </>
                            }
                        />
                    }
                />
            )}
        </>
    );
};

export default ItemsTable;
