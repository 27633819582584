import { clusterToNetworkId } from "utils/solana";

export const isValidHex = (value: string): value is HexAddress =>
    /^0x[0-9a-fA-F]+$/.test(value);

// [ ] Expand this to accept BigInt, possibly replace all together with toNetworkId
export const toNetworkHex = (value: number): HexAddress =>
    `0x${value.toString(16)}`;

export const toNetworkId = (value: string | number): number => {
    if (typeof value === `string` && value.startsWith("0x"))
        return parseInt(value, 16);
    else if (typeof value === `string`) return parseInt(value);

    return value;
};

export const networkToHex = (value: string | number): HexAddress => {
    if (typeof value === "number") return toNetworkHex(value);

    const numericValue = Number(value);
    if (!isNaN(numericValue)) return toNetworkHex(numericValue);

    const solanaNetwork = clusterToNetworkId(value);
    if (solanaNetwork) return solanaNetwork;

    if (isValidHex(value)) return value;
    throw new Error(`Cannot convert ${value} to a network hex address.`);
};
