import { MouseEventHandler } from "react";
import styled from "styled-components";
import { properties as p } from "global-style";

export const Address = styled.span<{
    monospace?: boolean;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
}>`
    ${({ monospace }) => (monospace ? `font-family: ${p.font.monospace};` : ``)}
    ${({ onClick }) => (onClick ? `cursor: pointer;` : ``)}
`;
