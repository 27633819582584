import styled from "styled-components";
import { Input } from "components/Input/styles";
import { Select } from "components/Select/styles";
export const FrequencyInputsContainer = styled.div`
    display: flex;
    position: relative;
    ${Input} {
        position: relative;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        flex: 2;
        &:focus {
            z-index: 20;
        }
    }
    ${Select} {
        position: relative;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        flex: 1;
        &:focus {
            z-index: 20;
        }
    }
`;
