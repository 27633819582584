import * as S from "./styles";
import { useState, FunctionComponent, FormEvent } from "react";
import { Company, UpdateItemRequest } from "company/types";
import Form from "components/Form";
import Button from "components/Button";
import Anchor from "components/Anchor";
import { useUser } from "context/User";
import { useNotificationQueue } from "context/NotificationQueue";
import { NotificationType } from "components/Notification";
import { updatedCompanyItems } from "api";
import { useGetCompanyItems } from "company/hooks/useGetCompanyItems";

interface ItemAutoInvoiceFormProps {
    item: Company.Item;
    onSuccess: () => void;
    onCancel: () => void;
}

const ItemAutoInvoiceForm: FunctionComponent<ItemAutoInvoiceFormProps> = ({
    item,
    onSuccess,
    onCancel,
}) => {
    // Hooks
    const { getCompanyItemsRefetch } = useGetCompanyItems();
    const { getEntityId, getSessionToken } = useUser();

    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const { addNotification } = useNotificationQueue();

    const handleDisableItem = async (event: FormEvent<HTMLFormElement>) => {
        const itemPayload: UpdateItemRequest = {
            itemId: item.id,
            autoInvoice: true,
        };

        setButtonLoading(true);

        const { response } = await updatedCompanyItems([itemPayload], {
            Authorization: getSessionToken(),
            "Content-Type": "application/json",
            "entity-id": getEntityId(),
        });

        const successFullResponse = response.ok && response.status === 200;
        if (successFullResponse) {
            getCompanyItemsRefetch();
            onSuccess();
        } else {
            addNotification({
                msg: "Something went wrong",
                type: NotificationType.ERROR,
            });
        }
    };

    return (
        <Form preventEnterKeySubmission onSubmit={handleDisableItem}>
            <h1>Enable automatic invoicing</h1>
            <S.Paragraph>
                Loop will automatically generate invoices for customers
                subscribed to this plan based on the plan's frequency.
            </S.Paragraph>
            <S.Paragraph>
                Each customer's next invoice will be generated based on their
                last pending invoice. If there are no pending invoices, the next
                bill will be based on the last paid invoice.
            </S.Paragraph>
            <S.Paragraph>
                Please note, if a customer has never been invoiced, then you
                will need to set up the first invoice to trigger the automatic
                invoicing.
            </S.Paragraph>
            <S.ButtonsContainer>
                <Button loading={buttonLoading}>
                    {buttonLoading ? "Confirming" : "Confirm"}
                </Button>
                <Anchor onClick={onCancel} href="#cancel" underlined={false}>
                    Cancel
                </Anchor>
            </S.ButtonsContainer>
        </Form>
    );
};

export default ItemAutoInvoiceForm;
