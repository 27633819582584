import styled from "styled-components";
import Form from "components/Form";
import spacing from "theme/spacing";

export const CancelTransactionForm = styled(Form)`
    section {
        margin-block-end: ${spacing.lg};
    }
    p {
        margin-block: ${spacing.sm};
    }
    dl > div {
        display: flex;
        gap: ${spacing.xxs};
    }
    dt::after {
        content: ":";
    }
    dd {
        margin: 0;
    }
`;

export const Buttons = styled.footer`
    display: flex;
    gap: 1rem;
`;
