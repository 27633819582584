import styled from "styled-components";
import { Box } from "@mui/material";
import colors from "theme/colors";
import WalletConnect from "components/WalletConnect";

const TopBar = styled(Box)`
    display: flex;
    flex-direction: row-reverse;
    border-bottom: 1px solid white;
`;

const Main = styled(Box)`
    background-color: ${colors.neutralBackground};
    color: ${colors.text};
    min-height: 100%;
    width: 100%;
`;

const Layout = ({ children }: any) => {
    return (
        <Box display="flex" flexDirection="column" height="100%">
            <TopBar px={4}>
                <WalletConnect />
            </TopBar>
            <Main component="main" px={{ xs: 4, md: 8 }} py={{ xs: 6, md: 6 }}>
                {children}
            </Main>
        </Box>
    );
};

export default Layout;
