import styled, { css } from "styled-components";

const shortName = css`
    line-height: 0.8;
    letter-spacing: -0.05ch;
    .preloadName {
        transform: translateY(-0.4em);
    }
`;

const longName = css`
    line-height: 1.1;
`;

export const CompanyLogo = styled.h1`
    ${({ size }) => (size === `short` ? shortName : longName)}
    font-size: 1.5rem;
    align-self: center;
    text-transform: uppercase;
    margin-block-end: 0;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        vertical-align: middle;
    }
    a {
        color: inherit;
        text-decoration: none;
    }
`;
