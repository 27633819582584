import styled from "styled-components";
import { sizes as s, properties as p } from "global-style";
import Card, { CardHeader, CardSection, CardFooter } from "components/Card";
import colors from "theme/colors";

export const SummaryAndEmail = styled(Card)`
    max-width: ${s.sm};
    box-shadow: ${p.shadowLg};
    margin: 1rem auto;
    abbr,
    data,
    time {
        font-weight: bold;
    }
`;

export const Congratulations = styled(CardHeader)`
    text-align: center;
    margin-bottom: 2rem;
    h1 {
        font-size: 2rem;
        margin: 0 0 0.5em;
        color: ${colors.primary};
    }
`;

export const CollectEmail = styled.div`
    margin: 1rem 0 2rem;
`;

export const EmailSuccess = styled(CardSection)`
    display: flex;
    align-items: center;
    column-gap: 2rem;
    margin-bottom: 3rem;
    :last-child {
        flex-grow: 1;
    }
`;

export const BtnLabelImg = styled.svg`
    * {
        fill: ${colors.textOnPrimary};
    }
`;

export const Footer = styled(CardFooter)`
    margin-block-start: 1.5rem;
    text-align: center;
`;
