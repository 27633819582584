import { ReactNode, useCallback, useEffect, useState } from "react";
import Table from "components/Table";

interface TransactionTableProps {
    headings: Heading[];
    records: Tuple[];
    pagination?: Pagination;
    sort?: SortBy;
    ifNoRecords: ReactNode;
}
export const TransactionTable = ({
    headings,
    records,
    pagination,
    sort,
    ifNoRecords,
}: TransactionTableProps) => {
    const [tableData, setTableData] = useState<Tuple[]>(records);

    useEffect(() => {
        if (!records) return;
        setTableData(records);
    }, [records]);

    const handleSortTable = useCallback(
        (sort: any) => {
            tableData.sort(sort);
        },
        [tableData]
    );

    return (
        <Table
            defaultSort={sort}
            onSort={handleSortTable}
            pagination={pagination}
            data={{
                headings: headings,
                records: tableData,
            }}
            ifNoRecords={ifNoRecords}
        ></Table>
    );
};
export default TransactionTable;
