import styled from "styled-components";
import colors from "theme/colors";

export const Disabled = styled.span`
    margin-left: 1rem;
    display: inline-block;
    color: ${colors.error};
`;

export const CheckoutUrlBuilderIconWrapper = styled.span`
    margin-left: 0.5em;
    opacity: 0;
`;

export const ItemNameCell = styled.div`
    display: inline-flex;
    align-items: center;
    width: 100%;
    &:hover ${CheckoutUrlBuilderIconWrapper} {
        opacity: 1;
    }
`;
