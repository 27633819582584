import * as S from "./styles";
import React, { memo, useMemo } from "react";
import {
    components,
    ClearIndicatorProps,
    MultiValueRemoveProps,
    DropdownIndicatorProps,
    OptionProps,
    MultiValueProps,
    createFilter,
} from "react-select";
import Close from "components/icons/Close";
import CarretDown from "components/icons/CarretDown";

export interface SelectMultipleOptionProps {
    label: React.ReactNode;
    value?: any;
    labelForSearch?: string; // has to be a string
    imgSrc?: string;
    imgAlt?: string;
    isDisabled?: boolean;
}

export interface SelectMultipleGroupedOptionProps {
    label: React.ReactNode;
    options: SelectMultipleOptionProps[];
}

export interface SelectMultipleProps {
    options?: SelectMultipleOptionProps[];
    groupedOptions?: SelectMultipleGroupedOptionProps[];
    values: any[];
    name?: string;
    closeMenuOnSelect?: boolean;
    onChange?: (values: any[]) => void;
    disabled?: boolean;
    required?: boolean;
    icon?: React.ReactNode;
    bold?: boolean;
    innerRef?: React.RefObject<HTMLSelectElement>;
    onCreateOption?: (value: string) => void;
    placeholder?: string;
}

export const SelectMultiple: React.FunctionComponent<SelectMultipleProps> =
    memo(
        ({
            options,
            groupedOptions,
            values = [],
            name,
            closeMenuOnSelect = true,
            onChange,
            disabled = false,
            required,
            onCreateOption,
            placeholder,
        }) => {
            const handleOnChange = (selected: any | null) => {
                if (onChange) {
                    const selectedValues = selected.map(
                        ({ value }: SelectMultipleOptionProps) => value
                    );
                    onChange(selectedValues);
                }
            };

            const selectedOptions = values?.map((value: any) => {
                if (groupedOptions) {
                    const match = groupedOptions
                        .flatMap((group) => group.options)
                        .find(
                            (option) =>
                                JSON.stringify(option.value) ===
                                JSON.stringify(value)
                        );

                    return match;
                } else if (options) {
                    return options.find((option) => {
                        return (
                            JSON.stringify(option.value) ===
                            JSON.stringify(value)
                        );
                    });
                } else {
                    return [];
                }
            });

            const optionsForSelect = useMemo(
                () => options || groupedOptions,
                [options, groupedOptions]
            );

            const filterOption = createFilter({
                ignoreCase: true,
                ignoreAccents: true,
                trim: true,
                stringify: (option) => {
                    const { value, label } = option;
                    const data = option.data as SelectMultipleOptionProps;
                    if (data.labelForSearch) return data.labelForSearch;
                    if (typeof label === "string") return label;
                    return value;
                },
            });

            const sharedProps = {
                name: name,
                inputId: name,
                value: selectedOptions,
                isMulti: true,
                classNamePrefix: S.classNamePrefix,
                unstyled: true,
                closeMenuOnSelect,
                filterOption: filterOption,
                components: {
                    ClearIndicator,
                    MultiValueRemove,
                    DropdownIndicator,
                    Option,
                    MultiValue,
                },
                onChange: handleOnChange,
                isDisabled: disabled,
                required: required,
                options: optionsForSelect,
                placeholder: placeholder,
            };

            return onCreateOption ? (
                <S.SelectMultipleCreatable
                    {...sharedProps}
                    onCreateOption={onCreateOption}
                />
            ) : (
                <S.SelectMultiple {...sharedProps} />
            );
        }
    );

const ClearIndicator = (props: ClearIndicatorProps) => {
    return (
        <components.ClearIndicator {...props}>
            <Close width="0.75rem" height="0.75rem" />
        </components.ClearIndicator>
    );
};

const MultiValueRemove = (props: MultiValueRemoveProps) => {
    return (
        <components.MultiValueRemove {...props}>
            <Close width="0.5rem" height="0.5rem" />
        </components.MultiValueRemove>
    );
};

const DropdownIndicator = (props: DropdownIndicatorProps) => {
    return (
        <components.DropdownIndicator {...props}>
            <CarretDown width="0.7rem" height="0.7rem" />
        </components.DropdownIndicator>
    );
};

const Option = (props: OptionProps) => {
    const { label, imgSrc, imgAlt, isDisabled } =
        props.data as SelectMultipleOptionProps;
    return (
        <components.Option {...props}>
            <S.OptionWrapper isDisabled={isDisabled || false}>
                {imgSrc && (
                    <S.OptionImageWrapper>
                        <img src={imgSrc} alt={imgAlt || ""} />
                    </S.OptionImageWrapper>
                )}
                {label}
            </S.OptionWrapper>
        </components.Option>
    );
};

const MultiValue = (props: MultiValueProps) => {
    const { label, imgSrc, imgAlt } = props.data as SelectMultipleOptionProps;
    return (
        <components.MultiValue {...props}>
            <S.MultiValueWrapper>
                {imgSrc && (
                    <S.OptionImageWrapper>
                        <img src={imgSrc} alt={imgAlt || ""} />
                    </S.OptionImageWrapper>
                )}
                <span>{label}</span>
            </S.MultiValueWrapper>
        </components.MultiValue>
    );
};

export default SelectMultiple;
