import { isField } from "components/Table";

const hasManageHeading = <T extends TableRow<T>>(
    cols: RowHeading<T>[]
): boolean => {
    return cols.some((col) => col.field === "manage");
};

const rowsToTableRecords = <T extends TableRow<T>>(
    rows: T[],
    cols: RowHeading<T>[]
): Tuple[] => {
    if (!rows.length) return [];

    // Check if all "field" props in heading Heading correspond to a property (in the first row)
    if (
        !cols.every(
            (col) =>
                col.field &&
                typeof rows[0][col.field as keyof T] !== "undefined"
        )
    ) {
        throw new Error(
            `Invalid Heading: a "field" does not have a matching property in "rows", or is undefined`
        );
    }

    return rows.map((row: T, index) => {
        const values = cols.reduce<Field[]>((acc, heading) => {
            const fieldKey = heading.field as keyof T;
            if (fieldKey && row.hasOwnProperty(fieldKey)) {
                const fieldValue = row[fieldKey];
                if (isField(fieldValue)) {
                    acc.push(fieldValue);
                } else {
                    throw new Error(
                        `Invalid Field: "${String(
                            fieldKey
                        )}" is not of type Field: ${JSON.stringify(fieldValue)}`
                    );
                }
            } else {
                throw new Error(
                    `Missing Field: "${String(
                        fieldKey
                    )}" does not exist in rows[${index}]:\n${JSON.stringify(
                        row
                    )}`
                );
            }
            return acc;
        }, []);

        if (values.length !== cols.length) {
            throw new Error(
                `Mismatch between the number of Headings (${cols.length}) and the number of Fields ${values.length} build from rows[${index}]`
            );
        }

        return {
            id: row.id.value,
            values: values,
        };
    });
};

// Only rows with ids found in objects of "match" will be returned
const filteredRowsToTableRecords = <T extends TableRow<T>>(
    rows: T[],
    cols: RowHeading<T>[],
    match: Tuple[]
): Tuple[] => {
    return rowsToTableRecords(
        rows.filter((row: T) => {
            return match.find((d: Tuple) => {
                return d.id === row.id.value;
            });
        }),
        cols
    );
};

export { hasManageHeading, rowsToTableRecords, filteredRowsToTableRecords };
