import styled from "styled-components";
import { properties as p } from "global-style";
import colors from "theme/colors";
import Button from "components/Button";
import Modal from "components/Modal";

export const WalletModal = styled(Modal)`
    z-index: ${p.zIndex.manageWallet};
`;

export const WidgetWrapper = styled.span`
    position: relative;
`;

export const Close = styled(Button)`
    position: absolute;
    top: 0.667rem;
    right: 0.667rem;
    cursor: pointer;
    z-index: ${p.zIndex.modal};
    color: ${colors.text};
`;
