import styled from "styled-components";
import { sizes as s } from "global-style";
import spacing from "theme/spacing";

export const Filters = styled.section`
    margin-top: ${spacing.sm};
    margin-bottom: ${spacing.md};
    gap: ${spacing.sm};
    display: flex;
    flex-direction: column;
`;
export const Download = styled.div`
    flex-grow: 1;
    text-align: right;
`;

export const FiltersRow = styled.div`
    display: flex;
    align-items: baseline;
    gap: ${spacing.sm};
    flex-direction: column;
    fieldset {
        width: 100%;
    }
    @media screen and (min-width: ${s.md}) {
        flex-direction: row;
        fieldset {
            max-width: calc(100% / 4);
        }
    }
`;
