// TODO: Chore: I think this is only used by <SwapFormProvider>, which is near-obsolete
import { useCallback } from "react";
import { useAvailableNetworks } from "./useAvailableNetworks";
import { AvailableToken, tokens } from "default-variables";

export const useTokens = () => {
    const { getNetworkById } = useAvailableNetworks();

    const getSellTokens = (networkId: string) => {
        const network = getNetworkById(networkId);
        if (!network) {
            throw new Error(`The specified network is unavailable`);
        }

        return (
            // Find the tokens for the current network
            network.tokensForSale.reduce<AvailableToken[]>((forSale, t) => {
                const sellable =
                    // ...confirm it's also available on this network
                    tokens.find(
                        ({ symbol, networks }) =>
                            symbol === t &&
                            networks.find((n) => n.networkId === networkId)
                    );

                // If swappable and on this network, add, else ignore
                return sellable ? [...forSale, sellable] : forSale;
            }, [])
        );
    };

    const getBuyTokens = (networkId: string, symbol: string) => {
        const token = tokens.find((token) => token.symbol === symbol);
        if (!token) return false;

        const network = token.networks.find(
            (network) => network.networkId === networkId
        );
        if (!network) return false;

        return network.swapsTo.reduce<AvailableToken[]>(
            (toBuy, availableTokenNetworkSwapTo) => {
                const buyable =
                    // ...is also available on this network
                    tokens.find(
                        ({ symbol, networks }) =>
                            symbol === availableTokenNetworkSwapTo.token &&
                            networks.find((n) => n.networkId === networkId)
                    );

                // If swappable and on this network, add, else ignore
                return buyable ? [...toBuy, buyable] : toBuy;
            },
            []
        );
    };

    const getTokenOnNetwork = useCallback(
        (networkId: string, symbol: string) => {
            const token = tokens.find((token) => token.symbol === symbol);
            if (!token) return false;
            const network = token.networks.find(
                (network) => network.networkId === networkId
            );
            if (!network) return false;
            return network;
        },
        []
    );

    type Price = {
        symbol: string;
        usdValue: null | number;
        lastUpdated: null | Date;
        networkId: string;
    };
    const setupAllTokenPrices = () => {
        return tokens.reduce<Price[]>((prices, token) => {
            const newPrices = token.networks.map(({ networkId }) => {
                return {
                    symbol: token.symbol, // Fix: use token.symbol instead of just symbol
                    usdValue: null,
                    lastUpdated: null,
                    networkId: networkId,
                };
            });
            return [...prices, ...newPrices];
        }, []);
    };

    const getTokenByAddressOnNetwork = (
        networkId: string,
        tokenAddress: string
    ) => {
        if (!networkId || !tokenAddress) return false;
        return (
            tokens.find(
                ({ networks }) =>
                    networks.filter(
                        (network) =>
                            network.networkId === networkId &&
                            network.tokenAddress.toLowerCase() ===
                                tokenAddress.toLowerCase()
                    ).length
            ) || false
        );
    };

    return {
        getBuyTokens,
        getSellTokens,
        getTokenOnNetwork,
        setupAllTokenPrices,
        getTokenByAddressOnNetwork,
    };
};
