import styled, { keyframes } from "styled-components";
import { properties as p } from "global-style";
import radius from "theme/radius";
import spacing from "theme/spacing";

const loadingAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
`;

export const LoadingBox = styled.div<{
    height: string;
    animationDelay?: number;
}>`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.05);
    height: ${(props) => props.height};
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${loadingAnimation};
    animation-delay: ${(props) =>
        props.animationDelay ? `${props.animationDelay}ms` : "0s"};
    animation-timing-function: linear;
    border-radius: ${radius.sm};
`;

export const Table = styled.table`
    border-spacing: 0;
    border-radius: ${radius.md};
    box-shadow: ${p.shadowMd};
    width: 100%;

    ${LoadingBox} {
        border-radius: 0;
    }

    thead tr {
        th {
            ${LoadingBox} {
                border-top-right-radius: ${radius.md};
                border-top-left-radius: ${radius.md};
            }
        }
    }

    ${LoadingBox} {
        border-radius: 0;
    }
    tbody tr td {
        padding: ${spacing.xs} ${spacing.xxs};
        &:first-child {
            padding: ${spacing.xs} ${spacing.sm};
        }
        &:last-child {
            padding: ${spacing.xs} ${spacing.sm};
        }
        ${LoadingBox} {
            border-radius: ${radius.md};
        }
    }
`;
