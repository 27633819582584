import * as S from "./style";
import { useCheckoutForm } from "checkout/context/CheckoutForm";
import { CheckoutPanel } from "checkout/components/CartAndForm";
import ManagedDetails, {
    ManagedDetailsProps,
} from "components/Details/ManagedDetails";
import SelectNetwork from "checkout/components/StepOneDetails/SelectNetwork";
import SelectToken from "checkout/components/StepOneDetails/SelectToken";
import ConnectedWalletSection from "checkout/components/StepOneDetails/ConnectedWalletSection";
import WalletDetailsSection from "checkout/components/StepOneDetails/WalletDetailsSection";

interface DropdownPaymentProps
    extends Omit<ManagedDetailsProps<CheckoutPanel.PAYMENT>, "children"> {}

const StepOneDetails = (props: DropdownPaymentProps) => {
    const { networkFieldRef, tokenFieldRef } = useCheckoutForm();

    return (
        <ManagedDetails {...props}>
            <S.NetworkAndToken>
                <SelectNetwork ref={networkFieldRef} />
                <SelectToken ref={tokenFieldRef} />
            </S.NetworkAndToken>
            <WalletDetailsSection />
            <ConnectedWalletSection />
        </ManagedDetails>
    );
};

export default StepOneDetails;
