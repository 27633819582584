import { ReactNode, useEffect } from "react";
import {
    ReactNodesAndMemoizedObjects,
    renderIntoReactNode,
} from "utils/components";
import { DetailsState, useDetailsManager } from "context/DetailsManager";
import Details, { DetailsProps } from ".";

export type DetailsNames = string | number;

export interface ManagedDetailsProps<T extends DetailsNames>
    extends Omit<DetailsProps, "summary"> {
    name: T;
    summary?: ReactNodesAndMemoizedObjects;
}

export const ManagedDetails = <T extends DetailsNames>({
    name,
    children,
    summary,
    open,
    disabled,
    ...props
}: ManagedDetailsProps<T>) => {
    const { details, setDetailsSummary, setDetailsOpen, setDetailsDisabled } =
        useDetailsManager<T>();

    useEffect(() => {
        if (!summary) return;
        setDetailsSummary(name, renderIntoReactNode(summary));
    }, [name, summary, setDetailsSummary]);

    useEffect(() => {
        if (!open) return;
        setDetailsOpen(name, open);
    }, [name, open, setDetailsOpen]);

    useEffect(() => {
        if (!disabled) return;
        setDetailsDisabled(name, disabled);
    }, [name, disabled, setDetailsDisabled]);

    return (
        <Details
            {...props}
            summary={renderIntoReactNode(details[name]?.summary)}
            open={details[name]?.open}
            disabled={details[name]?.disabled}
            onToggle={(event: any) => setDetailsOpen(name, event.target.open)}
        >
            {children}
        </Details>
    );
};

export default ManagedDetails;
