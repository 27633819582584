import * as S from "./style";
import { forwardRef, Ref } from "react";
import { formatCurrency } from "utils/financial";
import { GeneralTokenDetailsResponse } from "api";
import { convertTokenToCents } from "utils/exchangeRates";

export interface Authorization {
    balance: number;
    allowance: number;
    token: GeneralTokenDetailsResponse;
    rate?: number;
}

export interface AuthorizationInformationProps {
    authorization: Authorization;
    usd: boolean;
}

export type AuthorizationRef = {};

const AuthorizationInformation = (
    { authorization, usd }: AuthorizationInformationProps,
    ref: Ref<AuthorizationRef>
) => {
    const { balance, rate, token, allowance } = authorization;
    return (
        <S.AuthorizationInformation>
            <S.InformationRow>
                <strong>Balance</strong>

                <S.InformationCellRight>
                    {usd && (
                        <>
                            {rate && (
                                <p>
                                    {formatCurrency(
                                        convertTokenToCents(balance, rate)
                                    )}
                                </p>
                            )}
                            <small>
                                {balance} {token.symbol}
                            </small>
                        </>
                    )}
                    {!usd && (
                        <>
                            <p>
                                {balance} {token.symbol}
                            </p>
                            {rate && (
                                <small>
                                    {formatCurrency(
                                        convertTokenToCents(balance, rate)
                                    )}
                                </small>
                            )}
                        </>
                    )}
                </S.InformationCellRight>
            </S.InformationRow>
            <S.InformationRow>
                <strong>Allowance</strong>
                <S.InformationCellRight>
                    {usd && (
                        <>
                            {rate && (
                                <p>
                                    {formatCurrency(
                                        convertTokenToCents(allowance, rate)
                                    )}
                                </p>
                            )}
                            <small>
                                {allowance} {token.symbol}
                            </small>
                        </>
                    )}
                    {!usd && (
                        <>
                            <p>
                                {allowance} {token.symbol}
                            </p>
                            {rate && (
                                <small>
                                    {formatCurrency(
                                        convertTokenToCents(allowance, rate)
                                    )}
                                </small>
                            )}
                        </>
                    )}
                </S.InformationCellRight>
            </S.InformationRow>
        </S.AuthorizationInformation>
    );
};
export default forwardRef(AuthorizationInformation);
