import { colorsDeprecated as c, properties as p } from "global-style";
import styled from "styled-components";
import spacing from "theme/spacing";
import Card, { CardFooter, CardSection } from "components/Card";
import Title from "components/Title";
import DescriptionList from "components/DescriptionList";
import WalletConnectPanel from "components/WalletConnectPanel";
import Button from "components/Button";

export const CheckoutLayout = styled.div`
    display: grid;
`;

export const SummaryCard = styled(Card)``;
export const PaymentCard = styled(Card)``;

export const CardHeading = styled(Title)`
    padding-block-end: ${spacing.sm};
    border-block-end: 1px solid ${c.border};
`;

export const InvoiceAmt = styled.data`
    display: block;
`;

export const ChangeBtn = styled(Button)`
    font-size: ${p.small};
`;

export const NetworkAndToken = styled(CardSection)`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    text-align: right;
    margin-block-end: ${spacing.xxs};
`;

// [ ] There may be a component for this
export const Balance = styled.small`
    display: block;
    text-align: right;
`;

export const Terms = styled(CardFooter)`
    font-size: 0.8rem;
    text-align: center;
    color: ${c.lightOnSurface};
`;

export const ExchangeRate = styled(DescriptionList)`
    margin-block: ${spacing.md};
`;

export const WalletPanel = styled(WalletConnectPanel)<{ connected: boolean }>`
    margin-block: ${({ connected }) =>
        connected ? spacing.md : `${spacing.sm} ${spacing.none}`};
`;
