import styled from "styled-components";
import { colorsDeprecated as c, properties as p } from "global-style";
import colors from "theme/colors";

export const DropdownWrapper = styled.div`
    display: inline-block;
    position: relative;
`;

export const Dropdown = styled.ul`
    padding: 0;
    border: 1px solid ${c.inputBorder};
    border-radius: 0.2rem;
    display: inline-block;
    box-shadow: ${p.shadowMd};
    position: absolute;
    margin: 0;
    display: flex;
    flex-direction: column;
    background: ${colors.white};
    z-index: ${p.zIndex.dropdown};
`;
