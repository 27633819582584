// @ts-nocheck
import React, {
    forwardRef,
    useState,
    useRef,
    useEffect,
    useImperativeHandle,
} from "react";
import Input from "components/Input";
import { validateDateTimeRange } from "utils/formValidation";
import { formatDateTimeLocalForInput } from "utils/datetime";
import * as S from "./style";

interface TransferBillDateFieldProps {
    disabled: boolean;
    defaultBillDate?: Date;
    onChangeBillDate?: (billDate: Date) => void;
}

export type TransferBillDateFieldRef = {
    billDate: Date;
    billDateForApi: number;
    hasChanged: boolean;
    validate: () => boolean;
};

const TransferBillDateField: React.ForwardRefRenderFunction<
    TransferBillDateFieldRef,
    TransferBillDateFieldProps
> = ({ disabled, defaultBillDate = new Date(), onChangeBillDate }, ref) => {
    const [billDate, setBillDate] = useState(defaultBillDate);
    const billDateRef = useRef<HTMLInputElement>(null);

    const today = new Date();
    const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
    const thirtyDaysAgo = new Date(today.getTime() - thirtyDaysInMilliseconds);
    const errorMessage = "Date must be within the last 30 days";

    const validateInput = (input: HTMLInputElement, reportValidity: boolean) =>
        validateDateTimeRange({
            input: input,
            min: thirtyDaysAgo,
            reportValidity,
            errorMessage,
        });

    const validate = () => {
        if (!billDateRef.current) return false;

        return validateInput(billDateRef.current, true);
    };

    const handleDateInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const inputValue = event.target.value;
        const selectedDate = new Date(inputValue);
        if (!isNaN(selectedDate.valueOf())) setBillDate(selectedDate);
    };

    useEffect(() => {
        if (onChangeBillDate) onChangeBillDate(billDate);
    }, [billDate]);

    useImperativeHandle(ref, () => ({
        billDate,
        billDateForApi: Math.floor(billDate.getTime() / 1000),
        hasChanged: billDate !== defaultBillDate,
        validate,
    }));

    return (
        <>
            <Input
                type="datetime-local"
                name="billDate"
                innerRef={billDateRef}
                value={formatDateTimeLocalForInput(billDate)}
                onChange={handleDateInputChange}
                onBlur={(event) => validateInput(event.target, false)}
                disabled={disabled}
                min={formatDateTimeLocalForInput(thirtyDaysAgo)}
            />
            {billDate.getTime() < thirtyDaysAgo.getTime() && (
                <S.WarnDates error>{errorMessage}</S.WarnDates>
            )}
        </>
    );
};

export default forwardRef(TransferBillDateField);
