import React from "react";

const Refresh = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 12 12"
        >
            {name && <title>{name}</title>}
            <path
                d="M9.766 2.233A5.305 5.305 0 0 0 6 .667 5.326 5.326 0 0 0 .673 6 5.326 5.326 0 0 0 6 11.333c2.486 0 4.56-1.7 5.153-4H9.766A3.994 3.994 0 0 1 6 10c-2.207 0-4-1.793-4-4s1.793-4 4-4c1.106 0 2.093.46 2.813 1.187L6.666 5.333h4.667V.667L9.766 2.233Z"
                fill="inherit"
            />
        </svg>
    );
};

export default Refresh;
