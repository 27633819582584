import { useState, useEffect } from "react";
import { TimeFrequency } from "utils/dates";
import { addTimePeriodToDate } from "utils/datetime";
import { toDollar } from "utils/financial";

export interface CouponDetails extends CouponResponse {
    couponDiscountForDisplay: string;
    couponDurationSeconds: number;
}

export const useCouponsWithDetails = (coupons: CouponResponse[]) => {
    const [couponsWithDetails, setCouponsWithDetails] = useState<
        CouponDetails[]
    >([]);

    useEffect(() => {
        setCouponsWithDetails(
            coupons.map((coupon) => {
                let couponDiscountForDisplay = "";
                if (coupon.discountAmount) {
                    couponDiscountForDisplay = toDollar(coupon.discountAmount);
                } else if (coupon.discountPercentage) {
                    couponDiscountForDisplay = `${coupon.discountPercentage}%`;
                }

                let couponDurationSeconds = 0;
                if (coupon.durationDays) {
                    couponDurationSeconds = addTimePeriodToDate({
                        addend: coupon.durationDays,
                        type: TimeFrequency.DAY,
                    });
                }
                return {
                    ...coupon,
                    couponDiscountForDisplay,
                    couponDurationSeconds,
                };
            })
        );
    }, [coupons]);

    return couponsWithDetails;
};
