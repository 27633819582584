import styled from "styled-components";
import { colorsDeprecated as c } from "global-style";
import { CardFooter, CardSection } from "components/Card";
import DescriptionList from "components/DescriptionList";
import Spinner from "components/Spinner";
import Loop from "components/icons/Loop";
import colors from "theme/colors";

// StepOneDetails
export const NetworkAndToken = styled(CardSection)`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    text-align: right;
`;
export const Terms = styled(CardFooter)`
    font-size: 0.8rem;
    text-align: center;
    color: ${c.lightOnSurface};
`;

// CurrencyConversion
export const CurrencyConversion = styled(DescriptionList)`
    margin-block: 0.25rem;
`;

export const UsdLoading = styled(Spinner)`
    display: block;
    height: 1.4rem;
    width: 1.4rem;
    margin-inline-start: auto;
`;
export const FlipFlop = styled(Loop)`
    height: 1rem;
    width: 1rem;
    fill: ${colors.primary};
`;

export const WrapMoreMessage = styled.div`
    text-align: center;
    margin-bottom: 1rem;
`;
