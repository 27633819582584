import { useReducer } from "react";

export type Transition<State extends number> = Record<State, State[]>;

interface UseStateMachineProps<State extends number> {
    initialState: State;
    validTransitions: Transition<State>;
}

export const useStateMachine = <State extends number>({
    initialState,
    validTransitions,
}: UseStateMachineProps<State>) => {
    const isValidTransition = (currentStep: State, nextStep: State) => {
        return validTransitions[currentStep].includes(nextStep);
    };

    const reducer = (state: { step: State }, action: { type: State }) => {
        const nextStep = action.type;

        return isValidTransition(state.step, nextStep)
            ? { ...state, step: nextStep }
            : state;
    };

    const [state, dispatch] = useReducer(reducer, { step: initialState });

    return { state, dispatch };
};
