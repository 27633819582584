import * as S from "./style";
import { HTMLAttributes } from "react";
import { Navigate } from "react-router-dom";
import { BlockExplorerEntityType } from "utils/urls";
import { toDollar } from "utils/financial";
import { useInvoicePay } from "pay/context/InvoicePay";
import DescriptionList from "components/DescriptionList";
import DynamicAddressDisplay from "components/DynamicAddressDisplay";
import Button from "components/Button";

interface ConfirmedPaymentProps extends HTMLAttributes<HTMLDivElement> {}

const ConfirmedPayment = ({ ...props }: ConfirmedPaymentProps) => {
    const {
        entityAlias,
        entity,
        invoiceId,
        invoiceAmt,
        invoiceEmail,
        confirmed,
        network,
    } = useInvoicePay();

    // If at any point, these three pieces of information are not present, navigate back to start
    if (!invoiceId || !invoiceAmt || !invoiceEmail) {
        const to = `/${entityAlias}${invoiceId ? `/id/${invoiceId}` : ""}${
            invoiceAmt ? `/amount/${invoiceAmt}` : ""
        }${invoiceEmail ? `/email/${invoiceEmail}` : ""}`;
        return <Navigate to={to} />;
    }

    // If at any point, these three pieces of information are not present, navigate back to checkout
    if (!confirmed?.txHash || !network || !entity) {
        const to = `checkout/${entityAlias}`;
        return <Navigate to={to} />;
    }

    const paymentItems = [
        {
            term: invoiceId,
            definition: <data value={invoiceAmt}>{toDollar(invoiceAmt)}</data>,
            style: S.ItemRow,
        },
        {
            term: confirmed.isMultisig ? `Safe transaction hash` : `Hash`,
            definition: confirmed.isMultisig ? (
                <DynamicAddressDisplay
                    address={confirmed.txHash}
                    shorten={true}
                    networkId={network?.hexId}
                    type={BlockExplorerEntityType.SafeTx}
                    safeAddress={confirmed.walletAddress}
                />
            ) : (
                <DynamicAddressDisplay
                    address={confirmed.txHash}
                    shorten={true}
                    networkId={network?.hexId}
                    type={BlockExplorerEntityType.Transaction}
                />
            ),
            style: S.ItemRow,
        },
        {
            term: `Receipt`,
            definition: <data value={invoiceEmail}>{invoiceEmail}</data>,
            style: S.ItemRow,
        },
    ];

    return (
        <S.ConfirmedPayment {...props}>
            <S.CardHeading level="h2">
                <S.Circle>
                    <S.Checkmark />
                </S.Circle>
                {confirmed.isMultisig
                    ? `Sent to Safe for approval`
                    : `Paid ${entity.name}`}
            </S.CardHeading>
            <DescriptionList items={paymentItems} />
            {/* // Temporarily hide the button
            <Button href={import.meta.env.VITE_ATLAS_URL} full>
                See all my payment
            </Button> */}
        </S.ConfirmedPayment>
    );
};

export default ConfirmedPayment;
