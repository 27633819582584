import { Company } from "company/types";
import * as S from "./style";
import { Ref } from "react";
import { onlyUnique } from "utils/arrays";
import Close from "components/icons/Close";
import { colorsDeprecated as c } from "global-style";
import { itemAmountForDisplay, itemFrequencyForDisplay } from "utils/items";
import { useModal } from "context/ModalProvider";
import EditItemForm from "company/components/items/EditItemForm";
import Pencil from "components/icons/Pencil";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";
import { firstToUpper } from "utils/strings";

export interface ItemRowProps {
    item: Company.Item;
    onChange?: (itemIds: Company.Item["id"][]) => void;
    onRemoveItem?: (itemId: Company.Item["id"]) => void;
}

export type ItemRowRef = {
    itemSourceId: number;
};

function ItemRow({ item, onRemoveItem }: ItemRowProps, ref: Ref<ItemRowRef>) {
    const { tokens } = useGetTokensMetadata();
    const { networks } = useGetNetworks();

    const networkIds = Object.keys(item.acceptedTokensByNetwork).map((n) =>
        Number(n)
    );
    const itemNetworks = networks.filter((network) =>
        networkIds.includes(network.id)
    );
    const itemTokens = networkIds
        .map((networkId) => {
            const tokenAddresses = item.acceptedTokensByNetwork[networkId];
            const networkTokens = tokens.filter((token) =>
                tokenAddresses.includes(token.address.toLocaleLowerCase())
            );
            return networkTokens;
        })
        .flat();

    const { openModal, closeModal } = useModal();

    const handleOpenEditItemFormModal = () => {
        openModal(
            <EditItemForm item={item} onSuccess={closeModal} />,
            `Edit item`
        );
    };

    return (
        <S.ItemRow>
            <S.ItemInformation>
                <strong>{item.name}</strong>
                <span>
                    {itemAmountForDisplay(item.amount)}
                    {" / "}
                    {itemFrequencyForDisplay(item.frequency)}
                </span>
                <S.EditItem onClick={handleOpenEditItemFormModal}>
                    <Pencil
                        fill={c.popoverCloseLink}
                        height="1rem"
                        width="1rem"
                    />
                </S.EditItem>
            </S.ItemInformation>
            <S.ItemAcceptedTokens>
                {itemTokens.length === 0 && <>No accepted tokens</>}
                {itemTokens.length > 0 && (
                    <>
                        Pay with{" "}
                        <strong>
                            {itemTokens
                                .map((t) => t.symbol)
                                .filter(onlyUnique)
                                .join(", ")}
                        </strong>{" "}
                        on{" "}
                        <strong>
                            {itemNetworks
                                .map((n) => firstToUpper(n.name))
                                .join(", ")}
                        </strong>
                    </>
                )}
            </S.ItemAcceptedTokens>

            {onRemoveItem && (
                <S.RemoveItem onClick={() => onRemoveItem(item.id)}>
                    <Close
                        fill={c.popoverCloseLink}
                        height="0.75rem"
                        width="0.75rem"
                    />
                </S.RemoveItem>
            )}
        </S.ItemRow>
    );
}

export default ItemRow;
