import { useGetCompanyConfig } from "./useGetCompanyConfig";
import { useUser } from "context/User";

export const useHasChildEntities = () => {
    const { getEntityId } = useUser();
    const {
        config: { entities },
        getCompanyConfigIsError,
        getCompanyConfigIsLoading,
    } = useGetCompanyConfig();

    // Return only entities whose parent is the logged in user
    const parentsDirectChildEntities = entities.filter(
        ({ parentId }: any) => parentId === getEntityId()
    );

    return (
        !getCompanyConfigIsError &&
        !getCompanyConfigIsLoading &&
        parentsDirectChildEntities.length > 0
    );
};
