import { FunctionComponent } from "react";
import Notification, {
    NotificationContext,
    NotificationType,
} from "components/Notification";
import Anchor from "components/Anchor";

interface FailedDataFetchingMessageProps {
    title?: string;
}

const FailedDataFetchingMessage: FunctionComponent<FailedDataFetchingMessageProps> =
    ({ title = "Failed to fetch the data" }) => {
        return (
            <Notification
                key={"msg.id"}
                message={{
                    id: "webhook",
                    msg: (
                        <>
                            <strong>{title}</strong>
                            <p>
                                If this keeps happening please{" "}
                                <Anchor
                                    href={`mailto:${
                                        import.meta.env.VITE_EMAIL_SUPPORT
                                    }`}
                                >
                                    contact us
                                </Anchor>
                                .
                            </p>
                        </>
                    ),
                    type: NotificationType.ERROR,
                    context: NotificationContext.STATIC,
                    expires: false,
                }}
                removeNotification={false}
            />
        );
    };

export default FailedDataFetchingMessage;
