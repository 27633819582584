import { useCallback, useEffect, useState } from "react";
import {
    useDynamicContext,
    useSwitchWallet,
    useUserWallets,
} from "@dynamic-labs/sdk-react-core";
import { getSigner, getWeb3Provider } from "@dynamic-labs/ethers-v6";
import { isSolanaWallet } from "@dynamic-labs/solana";
import { BlockchainNetwork } from "default-variables";
import {
    EvmWalletSigner,
    EvmProvider,
    SolProvider,
    SolWalletSigner,
} from "types/common";
import { SafeWallet } from "context/Wallet/hooks/useWalletSafes";

export interface WalletApp {
    icon: string | null;
    label: string;
}

interface PrimaryWalletAccountBase extends WalletApp {
    id: string;
    address: string;
    ens: string | null;
    proxyFor: string | null;
    safe: Promise<SafeWallet | null>;
}

export type SolWallet = {
    chain: "SOL";
    signer: SolWalletSigner;
    provider: SolProvider;
};

export type EvmWallet = {
    chain: "EVM";
    signer: EvmWalletSigner;
    provider: EvmProvider;
};

export type PrimaryWalletAccount =
    | (PrimaryWalletAccountBase & SolWallet)
    | (PrimaryWalletAccountBase & EvmWallet);

export interface ConnectedWallet extends WalletApp {
    addresses: {
        id: string;
        address: string;
        chain: BlockchainNetwork;
    }[];
}

/* As far as "walletsAvailable" go, it seems that Dynamic's "additionalAddresses" array is only set when
not in "connect-only" made (non-signatured). Thus, only the primary address of a wallet is available, 
rather than with Onboard, where each address/account in a wallet that was used would then be available
and could be switched to. Revisit this if we go with a premium account and use dynamic's signature */

const useWalletConnected = (networkId: number | undefined) => {
    const [isWalletConnecting, setIsWalletConnecting] = useState(false);
    const [wallet, setWallet] = useState<PrimaryWalletAccount | null>(null);
    const [walletsAvailable, setWalletsAvailable] = useState<ConnectedWallet[]>(
        []
    );
    const { primaryWallet, handleLogOut } = useDynamicContext();
    const switchWallet = useSwitchWallet();
    const userWallets = useUserWallets();

    const setPrimaryWallet = useCallback(
        (walletId: string) => {
            switchWallet(walletId);
        },
        [switchWallet]
    );

    // [ ] Move this to `useSafeWallets` and move `proxyFor` to a variable and privitize it, exposing "isProxying" and "getActiveAddress"
    const setProxyWallet = useCallback((proxyAddr: string | null) => {
        setWallet((prevWallet) => {
            if (!prevWallet) return null;

            return {
                ...prevWallet,
                proxyFor: proxyAddr,
            };
        });
    }, []);

    useEffect(() => {
        const newWallets: ConnectedWallet[] = userWallets.reduce(
            (acc, wallet) => {
                const existingWallet = acc.find(
                    (w) => w.label === wallet.connector.name
                );
                const newAddress = {
                    id: wallet.id,
                    address: wallet.address,
                    chain: wallet.connector.connectedChain as BlockchainNetwork,
                };
                if (existingWallet) {
                    existingWallet.addresses.push(newAddress);
                } else {
                    const walletObj =
                        wallet.connector.constructorProps.walletBook.wallets[
                            wallet.key
                        ];
                    acc.push({
                        addresses: [newAddress],
                        icon: walletObj?.brand.spriteId
                            ? `https://iconic.dynamic-static-assets.com/icons/sprite.svg#${walletObj.brand.spriteId}`
                            : ``,
                        label: wallet.connector.name,
                    });
                }
                return acc;
            },
            [] as ConnectedWallet[]
        );

        setWalletsAvailable(newWallets);
    }, [userWallets]);

    useEffect(() => {
        if (!primaryWallet?.address) {
            setIsWalletConnecting(false);
            setWallet(null);
            return;
        }

        (async () => {
            setIsWalletConnecting(true);

            try {
                const walletByChain: SolWallet | EvmWallet = isSolanaWallet(
                    primaryWallet
                )
                    ? {
                          chain: "SOL",
                          signer: await primaryWallet.getSigner(),
                          provider: await primaryWallet.getConnection(),
                      }
                    : {
                          chain: "EVM",
                          signer: await getSigner(primaryWallet),
                          provider: await getWeb3Provider(primaryWallet),
                      };

                const walletObj =
                    primaryWallet.connector.constructorProps.walletBook.wallets[
                        primaryWallet.key
                    ];

                setWallet({
                    id: primaryWallet.id,
                    address: primaryWallet.address,
                    icon: walletObj?.brand.spriteId
                        ? `https://iconic.dynamic-static-assets.com/icons/sprite.svg#${walletObj.brand.spriteId}`
                        : ``,
                    label: walletObj?.name ?? ``,
                    ens: null,
                    proxyFor: null,
                    safe: Promise.resolve(null),
                    ...walletByChain,
                });
            } catch (error) {
                console.error(`Failed to connect wallet`, error);
                setWallet(null);
                setIsWalletConnecting(false);
                handleLogOut();
            }
        })();

        // Does adding primaryWallet to the dependency array cause unnecessary loading?
    }, [
        primaryWallet?.id,
        primaryWallet?.address,
        primaryWallet?.connector,
        networkId,
        handleLogOut,
    ]);

    useEffect(() => {
        if (!wallet) return;

        setIsWalletConnecting(false);
    }, [wallet]);

    return {
        isWalletConnecting,
        wallet,
        walletsAvailable,
        setPrimaryWallet,
        setProxyWallet,
    };
};

export default useWalletConnected;
