import styled from "styled-components";
import { colorsDeprecated as c, sizes as s } from "global-style";
import colors from "theme/colors";
import FooterBar from "components/FooterBar";

export const Layout = styled.main`
    display: grid;
    min-height: 100vh;
    grid-template-rows: auto 1fr;
    @media screen and (min-width: ${s.md}) {
        grid-template-rows: none;
        grid-template-columns: minmax(auto, 2fr) 3fr;
    }
    @media screen and (min-width: ${s.lg}) {
        grid-template-rows: none;
        grid-template-columns: minmax(auto, 36rem) auto;
    }
`;

export const Marketing = styled.article`
    display: flex;
    flex-direction: column;
    background-image: ${c.bgGradientDark};
    background-attachment: fixed;
    color: ${colors.white};
    padding: 1rem;
    text-align: center;

    @media screen and (min-width: ${s.sm}) {
        padding: 3rem;
    }
    @media screen and (min-width: ${s.md}) {
        padding: 4rem;
        text-align: start;
    }
    @media screen and (min-width: ${s.lg}) {
        padding: 4rem;
    }
`;

export const Logo = styled.img`
    height: 2rem;
    @media screen and (min-width: ${s.sm}) {
        height: 2.5rem;
    }
`;

export const Message = styled.section`
    flex-grow: 1;
    margin: 0;

    @media screen and (min-width: ${s.md}) {
        margin-block-start: 6rem;
    }
`;

export const Slogan = styled.p`
    font-size: 1rem;
    margin-block: 1rem;
    @media screen and (min-width: ${s.md}) {
        font-size: 1.25rem;
    }
    @media screen and (min-width: ${s.lg}) {
        font-size: 1.5rem;
    }
`;

export const Features = styled.ul`
    display: none;
    flex-direction: column;
    width: auto;
    margin: 3rem 0;
    max-width: ${s.sm};
    gap: 2rem;
    text-align: start;

    li {
        display: flex;
        gap: 1rem;
        width: 20rem;
        align-items: center;
        span {
            flex-grow: 1;
        }
    }

    @media screen and (min-width: ${s.md}) {
        display: flex;
    }

    @media screen and (min-width: ${s.lg}) {
        max-width: 18rem;
        font-size: 1.25rem;
    }
`;

export const Icon = styled.span`
    fill: ${colors.white};
    height: 2rem;
    min-height: 2rem;
    width: 2rem;
    min-width: 2rem;
`;

export const TrustedBy = styled.footer`
    @media screen and (min-width: ${s.sm}) {
        width: 100%;
        max-width: 35rem;
        margin: 0 auto;
    }
    @media screen and (min-width: ${s.md}) {
        max-width: none;
        margin: 0;
    }

    h2 {
        font-size: 1rem;
        @media screen and (min-width: ${s.md}) {
            margin-block: 0 1rem;
        }
        @media screen and (min-width: ${s.lg}) {
            text-align: center;
        }
    }
`;

export const Partners = styled.ul`
    display: flex;
    flex-direction: row;
    margin: 0;
    justify-content: center;
    gap: 1rem;
    li {
        img {
            height: 1.5rem;
        }
    }

    @media screen and (min-width: ${s.sm}) {
        justify-content: space-between;
        li {
            img {
                height: 2rem;
            }
        }
    }

    @media screen and (min-width: ${s.md}) {
        flex-direction: column;
    }

    @media screen and (min-width: ${s.lg}) {
        flex-direction: row;
    }
`;

export const Content = styled.article`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    align-items: center;
    @media screen and (min-width: ${s.sm}) {
        padding: 3rem;
    }
    @media screen and (min-width: ${s.lg}) {
        padding: 4rem;
    }
`;

export const RouteComponent = styled.section`
    display: grid;
    align-items: center;
    flex-grow: 1;
    max-width: ${s.sm};
    width: 100%;
`;

export const Footer = styled(FooterBar)`
    margin: 0 auto;
`;
