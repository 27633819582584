import styled, { css } from "styled-components";
import Form from "components/Form";
import Field from "components/Field";
import { colorsDeprecated as c, properties as p } from "global-style";
import spacing from "theme/spacing";
import DescriptionList from "components/DescriptionList";

export const BatchPayForm = styled(Form)`
    max-width: 1100px;
`;

export const GridColumn = styled.div<{ col: number }>`
    display: grid;
    grid-template-columns: repeat(${(props) => props.col}, 1fr);
    grid-gap: 1rem;
    align-items: top;
`;

export const WalletField = styled(Field)`
    grid-column: span 2;
`;

export const CheckboxField = styled(Field)`
    padding-top: 2.5rem;
`;

export const PaymentReceiverContainer = styled.div`
    display: flex;
`;

export const RemovePaymentReceiver = styled.div`
    padding-left: 1rem;
    padding-top: 0.5rem;
    min-width: 5rem;
`;

export const LineItem = css`
    margin-block: 1rem;
`;

export const Subtotals = styled(DescriptionList)`
    border-block-start: 1px solid ${c.border};
    padding: 0 1rem;
    margin-right: 5rem;
`;

export const PaymentReceiverTotalContainer = styled.div`
    background: ${c.inputDisabled};
    border-radius: ${p.radiusLg};
    display: flex;
    padding: 1rem;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    margin-right: 5rem;
    justify-content: space-between;
    align-items: center;
`;

export const PaymentReceiverTotalRight = styled.div`
    text-align: right;
`;

export const Footer = styled.footer`
    margin-block-end: ${spacing.md};
`;
