import { useMemo } from "react";
import { NetworkOnChain } from "types/common";
import { useAvailableNetworks } from "hooks/useAvailableNetworks";

// Take a common Network object and return it with its chain property
export const useNetworkOnChain = <T extends NetworkOnChain>(
    networks: BaseNetwork[]
) => {
    const { getAvailableNetworks } = useAvailableNetworks();

    const checkoutNetworks = useMemo<T[]>(() => {
        if (!networks) return [] as T[];

        const availableNetworks = getAvailableNetworks();

        return networks.reduce<T[]>((acc: T[], network: BaseNetwork) => {
            const networkData = availableNetworks.find(
                ({ id }) => id === network.id
            );
            if (!networkData) return acc;
            return [
                ...acc,
                {
                    ...network,
                    chain: networkData.chain,
                } as T,
            ];
        }, []);
    }, [networks, getAvailableNetworks]);

    return checkoutNetworks;
};
