import { Company } from "company/types";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import Badge from "components/Badge";

export interface ItemEntityCellProps {
    item: Company.Item;
}

export type ItemEntityCellRef = {};

export default function ItemEntityCell({ item }: ItemEntityCellProps) {
    const {
        config: { entities },
        getCompanyConfigIsLoading,
        getCompanyConfigIsError,
    } = useGetCompanyConfig();

    const entity = entities.find(
        (e: Company.Entity) => e.entityId === item.entityId
    );

    if (getCompanyConfigIsLoading) return <p>Loading</p>;
    if (getCompanyConfigIsError) return <p>Error</p>;
    if (!entity) return <p>Missing</p>;

    const isParent = !entity.parentId;

    return (
        <>
            {entity.name} {isParent && <Badge>Parent</Badge>}
        </>
    );
}
