import styled from "styled-components";
import colors from "theme/colors";

export const Marker = styled.div<{ block: boolean }>`
    display: ${({ block }) => (block ? `flex` : `inline-flex`)};
    height: 1.5rem;
    width: 1.5rem;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    line-height: 1;
    vertical-align: bottom;
    border-radius: 0.75rem;
    background-color: ${colors.primary};
    color: ${colors.textOnPrimary};
`;
