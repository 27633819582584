import styled from "styled-components";
import { colorsDeprecated as c } from "global-style";

export const HeaderRightSideContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const Paragraph = styled.p`
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

export const TopParagraph = styled.p`
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-right: 2rem;
`;

export const List = styled.ul`
    margin-top: 2rem;
`;

export const ListItem = styled.li`
    margin-bottom: 1rem;
    display: flex;
`;

export const ListItemContent = styled.div`
    flex: 1;
    padding-left: 1rem;
`;

export const ListIconContainer = styled.div`
    padding-top: 0.25rem;
`;
