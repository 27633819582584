import { useLoaderData } from "react-router-dom";
import { InvoiceDataLoader } from "invoice/types";
import spacing from "theme/spacing";
import useInvoiceTable from "invoice/hooks/useInvoiceTable";
import Table from "components/Table";
import LoadingBox, { LoadingPlaceholderStyle } from "components/LoadingBox";
import Anchor from "components/Anchor";
import EmptyTableMessage from "components/EmptyTableMessage";
import RefreshCounter, { CounterType } from "components/RefreshCounter";
import FailedDataFetchingMessage from "components/FailedDataFetchingMessage";

const InvoicesTable = () => {
    const { pagination: initialPagination, sort: initialSort } =
        useLoaderData<InvoiceDataLoader>();

    const {
        headings,
        records,
        totalResults,
        isLoading,
        isFetching,
        isError,
        pagination,
        sort,
        refetch,
        dataUpdatedAt,
        handleSortChange,
        handlePageChange,
    } = useInvoiceTable({
        initialFilters: {},
        initialSort,
        initialPagination,
    });

    return (
        <>
            <RefreshCounter
                refreshedAt={dataUpdatedAt}
                counter={CounterType.Running}
                onRefresh={refetch}
                refreshing={isFetching}
            />
            {isLoading ? (
                <LoadingBox
                    height={spacing.md}
                    placeholderStyle={LoadingPlaceholderStyle.Table}
                    tablePlaceholderRows={initialPagination.perPage}
                    tablePlaceholderCols={headings.length}
                />
            ) : isError ? (
                <FailedDataFetchingMessage />
            ) : (
                <Table
                    data={{ headings, records }}
                    totalRecords={totalResults}
                    pagination={pagination}
                    externalPageAndSort={true}
                    defaultSort={sort}
                    onSort={handleSortChange}
                    onPageChange={handlePageChange}
                    refetching={isFetching}
                    ifNoRecords={
                        <EmptyTableMessage
                            title="No invoice payments found"
                            description={
                                <>
                                    Need help?{` `}
                                    <Anchor
                                        href={`mailto:${
                                            import.meta.env.VITE_EMAIL_SUPPORT
                                        }`}
                                    >
                                        Contact us
                                    </Anchor>
                                    .
                                </>
                            }
                        />
                    }
                />
            )}
        </>
    );
};

export default InvoicesTable;
