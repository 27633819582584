import * as S from "./style";
import { HTMLAttributes, ReactNode } from "react";
import withStyledComponentOrProperties, {
    StyledComponentOrProperties,
} from "hoc/withStyledComponentOrProperties";

export interface DescriptionListItem {
    term: ReactNode;
    definition: ReactNode;
    style?: StyledComponentOrProperties;
}

interface DescriptionListProps extends HTMLAttributes<HTMLDListElement> {
    items: DescriptionListItem[];
    inline?: boolean;
    separator?: string;
}

const DescriptionList = ({
    items,
    inline = false,
    separator = ``,
    ...props
}: DescriptionListProps) => {
    return (
        <S.DescriptionList inline={inline} {...props}>
            {items.map(({ term, definition, style }, i) => {
                const Row = withStyledComponentOrProperties(S.Row);
                return (
                    <Row key={i} style={style}>
                        <S.Term separator={separator}>{term}</S.Term>
                        <S.Definition>{definition}</S.Definition>
                    </Row>
                );
            })}
        </S.DescriptionList>
    );
};

export default DescriptionList;
