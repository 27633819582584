import styled, { css } from "styled-components";
import { TitleLevel } from ".";
import { fontSizes, fontWeight } from "theme/typography";
import spacing, { Spacing } from "theme/spacing";

const fontSizeForTitle = {
    h1: fontSizes.xxl,
    h2: fontSizes.xl,
    h3: fontSizes.ml,
    h4: fontSizes.md,
    h5: fontSizes.sm,
    h6: fontSizes.xs,
};

export const Title = styled.h1<{
    $size: TitleLevel;
    $bold: boolean;
    $spacing?: Spacing;
    $wrap?: boolean;
}>`
    font-size: ${({ $size }) => fontSizeForTitle[$size]};
    font-weight: ${({ $bold }) =>
        $bold ? fontWeight.bold : fontWeight.normal};
    margin: 0;
    ${({ $spacing }) =>
        $spacing &&
        css`
            margin-block: ${spacing[$spacing]};
        `}
    ${({ $wrap }) => {
        if (!$wrap) {
            return css`
                white-space: nowrap;
                overflow-x: clip;
                text-overflow: ellipsis;
            `;
        }
    }}
`;
