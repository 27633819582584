import Section from "components/Section";
import ItemsTable from "company/components/items/ItemsTable";
import { ItemValues } from "company/hooks/useFormatCompanyItemsTable";
import { ItemFrequencyNames } from "utils/items";

const headings = [
    ItemValues.entity,
    ItemValues.name,
    ItemValues.amount,
    ItemValues.tokens,
    ItemValues.frequency,
    ItemValues.invoicing,
    ItemValues.initialOffset,
    ItemValues.manage,
];

const SubscriptionsInactive = () => {
    return (
        <>
            <Section>
                <ItemsTable
                    headings={headings}
                    itemFrequency={ItemFrequencyNames.Subscription}
                    active={false}
                />
            </Section>
        </>
    );
};

export default SubscriptionsInactive;
