import { ListValuesFromIds, firstToUpper } from "utils/strings";
import { useCheckoutData } from "checkout/context/CheckoutData";
import { useCheckoutForm } from "checkout/context/CheckoutForm";
import Notification, {
    NotificationContext,
    NotificationType,
} from "components/Notification";
import Anchor from "components/Anchor";

const AlreadySubscribed = () => {
    const { mainItem, networks } = useCheckoutData();
    const { subscribed } = useCheckoutForm();

    if (!subscribed || !subscribed.isSubscribed || !mainItem) return <></>;

    const networkNames = ListValuesFromIds({
        objects: networks,
        pks: subscribed.networks,
        matchProp: `id`,
        valueProp: `name`,
    });

    return (
        <Notification
            message={{
                id: `already-subscribed`,
                type: NotificationType.INFO,
                msg: (
                    <>
                        <h4>Already purchased</h4>
                        <p>
                            This wallet has already purchased{" "}
                            <strong>{firstToUpper(mainItem.name || ``)}</strong>
                            {networkNames && <> on {networkNames}</>}. You may
                            continue or manage your purchases on Loop's{" "}
                            <Anchor
                                href={import.meta.env.VITE_DASHBOARD_URL}
                                icon
                            >
                                Customer portal
                            </Anchor>
                            .
                        </p>
                    </>
                ),
                expires: false,
                context: NotificationContext.STATIC,
            }}
        />
    );
};

export default AlreadySubscribed;
