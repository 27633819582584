import styled from "styled-components";
import colors from "theme/colors";

export const Subpoint = styled.small`
    display: block;
    white-space: nowrap;
    color: ${colors.lightText};
`;

export const Status = styled.span`
    white-space: nowrap;
    vertical-align: top;
`;
