import styled from "styled-components";
import Field from "components/Field";
import { Label } from "components/Label/styles";
import spacing from "theme/spacing";

export const InputWrapper = styled.div``;

export const EntityInboundTreasuryField = styled(Field)`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: ${spacing.xxxs};

    ${Label} {
        width: 100%;
        margin: 0;
    }
    ${InputWrapper} {
        width: 100%;
        margin: 0;
    }
`;
