import Spinner from "components/Spinner";
import styled from "styled-components";

export const RefreshCounter = styled.time`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    height: 2rem;
`;

export const RefreshingMessage = styled.small`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    height: 2rem;
`;

export const RefreshingSpinner = styled(Spinner)`
    width: 1.2rem;
    height: 1.2rem;
`;
