export const getBooleanFromFnOrBool = (
    fnOrBool: boolean | (() => boolean) = true
): boolean => {
    return (typeof fnOrBool === `boolean` && fnOrBool) ||
        (fnOrBool instanceof Function && fnOrBool())
        ? true
        : false;
};

export const booleanFromString = (str: string): boolean => {
    if (!Array.of(`true`, `false`, `1`, `0`).includes(str.trim().toLowerCase()))
        throw new RangeError(`Can not create a boolean`);

    return Boolean(JSON.parse(str.trim().toLowerCase()));
};
