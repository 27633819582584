import styled from "styled-components";
import { HintType } from ".";
import colors from "theme/colors";

const getColor = (type: HintType) =>
    type === HintType.Info
        ? colors.primary
        : type === HintType.Success
        ? colors.success
        : type === HintType.Warning
        ? colors.warning
        : type === HintType.Error
        ? colors.error
        : colors.text;

export const Hint = styled.small<{ type: HintType }>`
    font-style: italic;
    display: block;
    color: ${({ type }) => getColor(type)};
`;
