import styled, { css } from "styled-components";
import { colorsDeprecated as c, properties as p, spin } from "global-style";
import colors from "theme/colors";
import { Status, StatusValues } from ".";

const getColors = (status: StatusValues) => {
    return status === Status.ERROR
        ? css`
              border-color: ${colors.error};
              color: ${colors.error};
          `
        : ``;
};

const getContentState = (status: StatusValues) => {
    return status === Status.SIGNING
        ? css`
              content: "sign transaction";
              margin-right: 5px;
              color: ${colors.primary};
              font-size: 0.8em;
              font-weight: 700;
              top: 0.8em;
          `
        : status === Status.LOADING
        ? css`
              background-color: ${colors.primary};
              mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 22'%3E%3Cpath d='M11 0v3a8 8 0 0 0-6.76 12.26L5.7 13.8A6 6 0 0 1 11 5v3l4-4-4-4zm6 11a6 6 0 0 1-6 6v-3l-4 4 4 4v-3a8 8 0 0 0 6.76-12.26L16.3 8.2c.45.83.7 1.79.7 2.8z' /%3E%3C/svg%3E");
              animation: 1s linear ${spin} infinite;
              width: 1.6em;
          `
        : status === Status.COMPLETE
        ? css`
              background-color: ${colors.textOnPrimary};
              mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.459 12.609 13.89 0 16 1.331l-8.777 14.67L0 11.552l1.39-2.046 5.069 3.102v.001Z' /%3E%3C/svg%3E");
              width: 1.6em;
              mask-size: 70%;
              mask-position: center center;
          `
        : status === Status.IDLE
        ? css`
              background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%234A3AFA' fill-rule='evenodd' clip-rule='evenodd' d='M12 1V4C7.58 4 4 7.58 4 12C4 13.57 4.46 15.03 5.24 16.26L6.7 14.8C6.25 13.97 6 13.01 6 12C6 8.69 8.69 6 12 6V9L16 5L12 1ZM18 12C18 15.31 15.31 18 12 18V15L8 19L12 23V20C16.42 20 20 16.42 20 12C20 10.43 19.54 8.97 18.76 7.74L17.3 9.2C17.75 10.03 18 10.99 18 12Z'/%3E%3C/svg%3E");
              filter: saturate(0);
              opacity: 0.4;
              width: 1.6em;
          `
        : status === Status.ERROR
        ? css`
              content: "failed";
              margin-right: 5px;
              color: ${colors.error};
              font-size: 0.8em;
              font-weight: 700;
              top: 0.8em;
          `
        : ``;
};

export const StatusUpdate = styled.div<{
    status: StatusValues;
    disabled: boolean;
    square: boolean;
}>`
    position: relative;
    line-height: 1.2;
    padding: 0.6em 1.2em;
    border: 1px solid ${colors.primary};
    border-radius: ${({ square }) => (square ? p.radiusSm : `1.2em`)};
    background-color: ${({ status }) =>
        status === `complete` ? colors.primary : `transparent`};
    color: ${({ status }) =>
        status === `complete` ? colors.textOnPrimary : colors.primary};
    ${({ status }) => getColors(status)}
    &[disabled] {
        color: ${c.onDisabled};
        border-color: ${c.onDisabled};
    }

    ::after {
        content: "";
        position: absolute;
        right: 0.6em;
        top: 0.4em;
        height: 1.6em;
        mask-size: cover;
        mask-repeat: no-repeat;
        ${({ status }) => getContentState(status)}
    }
`;
