import styled from "styled-components";

export const Paginate = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
`;
export const Controls = styled.div`
    display: flex;
    gap: 0.5rem;
`;
