import styled from "styled-components";
import { Checkbox } from "components/Checkbox/styles";
import { Radio } from "components/Radio/styles";
import { colorsDeprecated as c } from "global-style";

export const Optional = styled.span`
    color: ${c.onDisabled};
    margin-left: 0.5rem;
    font-weight: 400;
`;

export const Required = styled.span`
    color: ${c.onDisabled};
    margin-left: 0.5rem;
    font-weight: 400;
`;

export const MainLabel = styled.div`
    width: fit-content;
    display: flex;
    gap: 0.25rem;
    align-items: center;
    width: 100%;
`;

export const SubLabel = styled.small`
    display: inline-block;
    font-size: 1rem;
    color: ${c.lightOnSurface};
    width: 100%;
    margin-top: 0.25rem;
`;

export const Label = styled.label<{ inline: boolean }>`
    width: 100%;
    display: inline-block;
    ${Checkbox}, ${Radio} {
        flex: initial;
        margin-right: 0.25rem;
    }
    &:has(${Radio}, ${Checkbox}) {
        flex: initial;
        margin-right: 0.5rem;
        font-weight: 400;
    }

    ${MainLabel} {
        font-weight: ${({ inline }) => (inline ? 200 : 600)};
    }
`;
