import { CSSProperties, ComponentType, ReactNode } from "react";
import styled, {
    isStyledComponent,
    FlattenInterpolation,
    StyledComponent,
    ThemeProps,
} from "styled-components";

/*
The `style` prop applied to the wrapped component can be of type:

    StyledComponent<any, any>:
        styled.div`
            padding-block: 1rem;
            font-weight: 700;
        `

    Partial<CSSProperties>:
        {
            paddingBlock: 1rem;
            fontWeight: 700;
        }

    FlattenInterpolation<ThemeProps<any>>:
        css`
            padding-block: 1rem;
            font-weight: 700;
        `
*/
export type StyledComponentOrProperties =
    | StyledComponent<any, any>
    | Partial<CSSProperties>
    | FlattenInterpolation<ThemeProps<any>>;

interface StyleOrStyledComponentProps {
    style?: StyledComponentOrProperties;
    children: ReactNode;
}

const withStyledComponentOrProperties = (
    WrappedComponent: ComponentType<any>
) => {
    return (props: StyleOrStyledComponentProps) => {
        const { style, children, ...rest } = props;

        if (isStyledComponent(style)) {
            const StyledWrapper = style;
            return (
                <StyledWrapper>
                    <WrappedComponent {...rest}>{children}</WrappedComponent>
                </StyledWrapper>
            );
        }

        if (Array.isArray(style)) {
            const StyledWrappedComponent = styled(WrappedComponent)`
                ${style}
            `;
            return (
                <StyledWrappedComponent {...rest}>
                    {children}
                </StyledWrappedComponent>
            );
        }

        return (
            <WrappedComponent style={style as CSSProperties} {...rest}>
                {children}
            </WrappedComponent>
        );
    };
};

export default withStyledComponentOrProperties;
