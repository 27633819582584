import Button from "components/Button";
import styled from "styled-components";
import { colorsDeprecated as c, properties as p } from "global-style";

export const InputText = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.5rem;
    span {
        flex-basis: 100%;
    }
    &[disabled] {
        background-color: ${c.disabled};
        color: ${c.onDisabled};
    }
    ${({ flex }) => (flex ? `flex: ${flex};` : ``)}
`;

export const Input = styled.input`
    flex-grow: 1;
    line-height: 1;
    padding: 0.6em 0.5em;
    border: 1px solid ${c.border};
    border-radius: ${p.radiusLg};
`;

export const SideButton = styled(Button)`
    border-radius: ${p.radiusLg};
`;
