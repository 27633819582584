import * as S from "./style";
import { forwardRef, Ref } from "react";
import { formatTokenAndUsd } from "utils/financial";
import { useCheckoutForm } from "checkout/context/CheckoutForm";
import { useWallet } from "context/Wallet";

interface NativeTokenBalancesDescriptionListRef {}

const NativeTokenBalancesDescriptionList = forwardRef(
    (_, ref: Ref<NativeTokenBalancesDescriptionListRef>) => {
        const {
            token,
            nativeToken,
            wrapperBalance,
            loadingBalance,
            isTokenPricedCheckout,
        } = useCheckoutForm();
        const { walletConnected } = useWallet();
        if (!walletConnected) return <></>;

        const items = [];
        if (token && nativeToken) {
            items.push({
                term: `${nativeToken.symbol} balance`,
                definition: loadingBalance
                    ? `Loading`
                    : formatTokenAndUsd({
                          amount: wrapperBalance,
                          symbol: nativeToken.symbol,
                          usdValue: token.exchange.rate,
                          round: undefined,
                          hideUsd: isTokenPricedCheckout,
                      }),
            });
        }

        return <S.NativeTokenBalancesDescriptionList items={items} />;
    }
);

export default NativeTokenBalancesDescriptionList;
