import React from "react";
import * as S from "./styles";

interface FormProps {
    children: React.ReactNode;
    preventDefault?: boolean;
    preventEnterKeySubmission?: boolean;
    className?: string;
    onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
}

const Form: React.FunctionComponent<FormProps> = ({
    children,
    preventDefault = true, // we can safely assume we always want to prevent native browser submit
    preventEnterKeySubmission = false,
    className,
    onSubmit,
}) => {
    const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        if (preventDefault) {
            event.preventDefault();
        }
        if (onSubmit) {
            onSubmit(event);
        }
    };

    // For some long forms, we want to avoid the "accidental enter key submit" behavior
    const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
        if (preventEnterKeySubmission && event.code === "Enter") {
            event.preventDefault();
        }
    };

    return (
        <S.Form
            onSubmit={handleOnSubmit}
            className={className}
            onKeyDown={handleKeyDown}
        >
            {children}
        </S.Form>
    );
};

export default Form;
