import { FunctionComponent } from "react";
import { Company, PaymentPlatformSource } from "company/types";
import Chargebee from "components/logos/Chargebee";
import QuickBooks from "components/logos/QuickBooks";
import Stripe from "components/logos/Stripe";
import Xero from "components/logos/Xero";
import { entityPaymentPlatformSourceForDisplay } from "company/utils/entities";

export interface EntityPaymentPlatformProviderLogoProps {
    paymentPlatformProvider: Company.Entity["paymentPlatformProvider"];
    height?: string;
}

const EntityPaymentPlatformProviderLogo: FunctionComponent<EntityPaymentPlatformProviderLogoProps> =
    ({ paymentPlatformProvider, height }) => {
        if (!paymentPlatformProvider) return <></>;

        if (paymentPlatformProvider === PaymentPlatformSource.Stripe) {
            return <Stripe height={height} />;
        }

        if (paymentPlatformProvider === PaymentPlatformSource.QuickBooks) {
            return <QuickBooks height={height} />;
        }

        if (paymentPlatformProvider === PaymentPlatformSource.Chargebee) {
            return <Chargebee height={height} />;
        }

        if (paymentPlatformProvider === PaymentPlatformSource.Xero) {
            return <Xero height={height} />;
        }

        // If no logo, return string
        return (
            <span>
                {entityPaymentPlatformSourceForDisplay[paymentPlatformProvider]}
            </span>
        );
    };

export default EntityPaymentPlatformProviderLogo;
