import { Dispatch, ReactNode, useCallback } from "react";
import { useNotificationQueue } from "context/NotificationQueue";
import { postAgreeToItems } from "api";
import { useCheckoutData } from "checkout/context/CheckoutData";
import { NotificationType } from "components/Notification";
import { useWallet } from "context/Wallet";

const useCheckoutSubscribe = (
    totalPaidToday: ReactNode,
    hasMinimumAllowance: boolean,
    setCheckoutComplete: Dispatch<
        React.SetStateAction<CheckoutPostResponse | null>
    >
) => {
    const { walletConnected, networkConnected } = useWallet();
    const { couponsWithDetails } = useCheckoutData();
    const couponDetails = couponsWithDetails[0];
    const { addNotification, removeNotification } = useNotificationQueue();

    const sendSubscribe = useCallback(
        async (
            itemIds: string[],
            entityId: string,
            authToken: string | undefined,
            tokenAddress: string | undefined,
            {
                sub: externalSubscriptionId,
                coupon: couponCodeId,
                discountPercent: discount,
                trialDays,
                refId: externalReferenceId,
                invoiceId: invoiceNumber,
                invoiceAmount,
                location,
                metalType,
                plan,
                planType,
                plugin,
                solanaType,
                billDate: subscriptionBillDate,
                newCustomer,
            }: CheckoutQueryParams,
            email: string,
            billDate?: number
        ) => {
            if (
                !authToken ||
                !tokenAddress ||
                !walletConnected ||
                !networkConnected
            )
                return Promise.reject(
                    `Checkout was not completed successfully`
                );

            if (!email) {
                return Promise.reject(`Email not set`);
            }

            const couponCodeIsForRequest = couponCodeId || couponDetails?.id;
            const bodyRequest: PostAgreementCheckoutHttpRequest = {
                network: parseInt(networkConnected.networkId, 16),
                email: email,
                entityId: entityId,
                token: tokenAddress,
                itemIds: itemIds,
                pendingMessageHash: ``,
                externalSubscriptionId: externalSubscriptionId ?? ``,
                couponCodeId: couponCodeIsForRequest ?? ``,
                externalReferenceId: externalReferenceId ?? ``,
                trialDays: trialDays ?? undefined,
                discount: discount ?? undefined,
                invoiceNumber: invoiceNumber ?? undefined,
                invoiceAmount: invoiceAmount ?? undefined,
                billDate: subscriptionBillDate ?? billDate ?? undefined,
                location: location ?? undefined,
                metalType: metalType ?? undefined,
                plan: plan ?? undefined,
                planType: planType ?? undefined,
                plugin: plugin ?? undefined,
                solanaType: solanaType ?? undefined,
                newCustomer: newCustomer ?? undefined,
            };
            const notificationLoadingId = addNotification({
                msg: `Completing your checkout...`,
                type: NotificationType.WORKING,
            });

            const { data, response, error } = await postAgreeToItems(
                walletConnected?.proxyFor || walletConnected?.address,
                bodyRequest,
                {
                    Authorization: authToken,
                    address: walletConnected.address,
                }
            );

            removeNotification(notificationLoadingId);

            if (response.ok && data) {
                // If the response is successful, handle it as CheckoutAgreementResponse
                setCheckoutComplete({
                    ...data?.agreements[0],
                    transactionHash: data?.transactionHash,
                    amountPaid: totalPaidToday,
                    billDate: billDate ?? undefined,
                    hasMinimumAllowance: hasMinimumAllowance,
                });
            } else if (!response.ok) {
                let errorForDisplay =
                    "There was a problem completing your checkout";

                if (error && error.code === 400 && error.message) {
                    errorForDisplay = error.message;
                }

                addNotification({
                    msg: errorForDisplay,
                    type: NotificationType.ERROR,
                });
            }
        },
        [
            walletConnected,
            networkConnected,
            couponDetails?.id,
            addNotification,
            removeNotification,
            setCheckoutComplete,
            totalPaidToday,
            hasMinimumAllowance,
        ]
    );

    return {
        sendSubscribe,
    };
};

export { useCheckoutSubscribe };
