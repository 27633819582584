import * as S from "./style";

const OptionList = ({
    defaultValue,
    onChange,
    data,
    name,
    label,
    ...props
}) => {
    // Note: This is setup as an uncontrolled component, as far as React is concerned
    // Read more here: https://reactjs.org/docs/uncontrolled-components.html
    // One of it's fault are the requirement of a name prop to control the set (can be randomized)
    // Consider switching to controlled, but check accessibility implications first

    /*
    Structure of `data`:
    [
        {
            value: 123,
            label: `Option displayed to the user`
        },
        ...
    ]
    */

    return (
        <S.OptionList
            role="radiogroup"
            aria-label={label ? label : name}
            defaultValue={defaultValue}
            onChange={onChange}
            {...props}
        >
            {data.map(({ value, label }) => (
                <Option
                    key={value}
                    value={value}
                    name={name}
                    checked={value === defaultValue}
                >
                    {label}
                </Option>
            ))}
        </S.OptionList>
    );
};

const Option = ({ children, name, value, checked = false }) => {
    return (
        <S.Option>
            <input
                type="radio"
                name={name}
                value={value}
                defaultChecked={checked}
            />
            <span
                role="radio"
                aria-label={children}
                /* tabIndex={checked ? `0` : `-1`} */ aria-checked={checked}
            >
                {children}
            </span>
        </S.Option>
    );
};

export default OptionList;
