import { getCompanyExternalSubscriptionDetails } from "api";
import { Company, GetCompanyExternalSubscriptionResponse } from "company/types";
import {
    canImportEntityExternalSubscription,
    entityPaymentPlatformSourceForDisplay,
    subscriptionIdHintForDisplay,
    validateSubscriptionId,
} from "company/utils/entities";
import Button from "components/Button";
import Field from "components/Field";
import Label from "components/Label";
import Form from "components/Form";
import Hint from "components/Hint";
import Input from "components/Input";
import { useCallback, useState } from "react";
import { validateWithFunction } from "utils/formValidation";
import { NotificationType } from "components/Notification";
import { useNotificationQueue } from "context/NotificationQueue";
import { useUser } from "context/User";

interface ImportExternalSubscriptionFormProps {
    entity: Company.Entity;
    onSuccess: (
        companyExternalSubscriptionDetails: GetCompanyExternalSubscriptionResponse
    ) => void;
    defaultExternalSubscriptionId?: string;
}

const ImportExternalSubscriptionForm: React.FunctionComponent<ImportExternalSubscriptionFormProps> =
    ({ entity, onSuccess, defaultExternalSubscriptionId }) => {
        const [externalSubscriptionId, setExternalSubscriptionId] =
            useState<string>(defaultExternalSubscriptionId || "");
        const { getEntityId, getSessionToken } = useUser();
        const [loading, setLoading] = useState<boolean>(false);
        const { addNotification } = useNotificationQueue();

        const isValidSubscriptionId = validateSubscriptionId(
            externalSubscriptionId,
            entity.paymentPlatformProvider
        );

        const fetchCompanyExternalSubscriptionDetails = useCallback(
            async (subscriptionId: string) => {
                const { data, response } =
                    await getCompanyExternalSubscriptionDetails(
                        getEntityId(),
                        subscriptionId,
                        { Authorization: getSessionToken() }
                    );

                if (!response.ok || !data) {
                    return addNotification({
                        msg: "Something went wrong while looking for subscription details",
                        type: NotificationType.ERROR,
                    });
                }

                if (!data.externalSubscriptionId) {
                    return addNotification({
                        msg: "Could not find a subscription",
                        type: NotificationType.ERROR,
                    });
                }

                if (data.items.length === 0) {
                    return addNotification({
                        msg: "Could not find items related to this subscription",
                        type: NotificationType.ERROR,
                    });
                }

                onSuccess(data);
            },
            [addNotification, getEntityId, getSessionToken, onSuccess]
        );

        if (!entity.paymentPlatformProvider) {
            return <p>{entity.name} doesn't support subscription import</p>;
        }

        const canImport =
            entity.paymentPlatformProvider &&
            canImportEntityExternalSubscription(entity);

        if (!canImport) {
            return (
                <p>
                    {entity.name} doesn't support charging an existing
                    subscription
                </p>
            );
        }

        const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
            if (loading) return;

            if (externalSubscriptionId) {
                setLoading(true);
                await fetchCompanyExternalSubscriptionDetails(
                    externalSubscriptionId
                );
                setLoading(false);
            }
        };

        const paymentPlatformSourceForDisplay =
            entityPaymentPlatformSourceForDisplay[
                entity.paymentPlatformProvider
            ];

        const subscriptionIdHint =
            subscriptionIdHintForDisplay[entity.paymentPlatformProvider];

        const buttonText = loading
            ? `Fetching`
            : `Fetch ${paymentPlatformSourceForDisplay} subscription details`;

        const buttonDisabled = loading || !isValidSubscriptionId;

        return (
            <Form onSubmit={onSubmit}>
                <Field>
                    <Label>
                        {paymentPlatformSourceForDisplay} subscription ID
                    </Label>
                    <Input
                        value={externalSubscriptionId}
                        disabled={loading}
                        onChange={(event) =>
                            setExternalSubscriptionId(event.target.value)
                        }
                        onBlur={(event) =>
                            event.target.value.trim().length > 0 &&
                            validateWithFunction({
                                input: event.target,
                                reportValidity: true,
                                validateFunction: () =>
                                    validateSubscriptionId(
                                        event.target.value,
                                        entity.paymentPlatformProvider
                                    ),
                                errorMessage: `Please enter a valid ${paymentPlatformSourceForDisplay} subscription ID `,
                            })
                        }
                    />
                    <Hint>{subscriptionIdHint}</Hint>
                </Field>
                <Button
                    type="submit"
                    disabled={buttonDisabled}
                    loading={loading}
                >
                    {buttonText}
                </Button>
            </Form>
        );
    };

export default ImportExternalSubscriptionForm;
