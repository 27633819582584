import styled from "styled-components";
import Anchor from "components/Anchor";
import BackButton from "components/BackButton";

export const LearnMore = styled(Anchor)`
    color: inherit;
`;

export const BackToTx = styled(BackButton)`
    margin-right: 1rem;
`;
