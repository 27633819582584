import { useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { apiServerUrl } from "default-variables";
import { useNotificationQueue } from "context/NotificationQueue";
import SetPasswordForm from "./SetPasswordForm";
import Loading from "components/Loading";
import { NotificationType } from "components/Notification";
import { useUser, UserRole, tierStrToEnum } from "context/User";
import { LogUserInResponse } from "components/LoginWeb2";
import { getErrorMessage } from "utils/errors";
import Button, { ButtonSizes } from "components/Button";
import Title from "components/Title";
import { Spacing } from "theme/spacing";
import Paragraph from "components/Paragraph";
import Field from "components/Field";

const ResetPasswordForm = () => {
    const { resetPasswordHash } = useParams();
    const { addNotification } = useNotificationQueue();
    const { newUserSession } = useUser();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (evt: any, newPassword: string) => {
        evt.preventDefault();
        setLoading(true);

        let res, data;
        try {
            res = await fetch(`${apiServerUrl}/v1/user/reset-password`, {
                method: `POST`,
                body: JSON.stringify({
                    newPassword,
                    resetPasswordHash,
                }),
            });

            data = await res.json();

            if (!res.ok) {
                throw data;
            }
        } catch (error: unknown) {
            const errorMsg = getErrorMessage(error);
            addNotification({ msg: errorMsg, type: NotificationType.ERROR });
            return;
        } finally {
            setLoading(false);
        }

        const {
            token,
            entityId,
            companyName,
            roles,
            delegatedSigning,
            email: serverEmail,
        }: LogUserInResponse = data;
        if (token && entityId) {
            newUserSession({
                token,
                roles: roles as UserRole[],
                entityId,
                tier: tierStrToEnum(`guest`),
                companyName,
                email: serverEmail,
                delegatedSigning,
            });
            navigate(`/`);
        } else {
            addNotification({
                msg: `Something went wrong.`,
                type: NotificationType.ERROR,
            });
        }
    };

    return (
        <>
            {loading && <Loading />}
            <Title level="h2" spacing={Spacing.sm}>
                Welcome back
            </Title>
            <Paragraph spacing={Spacing.sm}>
                Please set your new password.
            </Paragraph>
            <SetPasswordForm onSubmit={handleSubmit}>
                {({ isValid }: any) => {
                    return (
                        <>
                            <Field>
                                <Button
                                    type="submit"
                                    disabled={!isValid}
                                    loading={loading}
                                    full
                                    size={ButtonSizes.Large}
                                >
                                    Reset Password
                                </Button>
                            </Field>
                        </>
                    );
                }}
            </SetPasswordForm>
            <Link to="/login" style={{ textDecoration: "none" }}>
                Login
            </Link>
        </>
    );
};

export default ResetPasswordForm;
