import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { ExchangableToken } from "types/common";
import { InvoiceEntity } from "api/types/invoice";
import {
    ConfirmedPayment,
    InvoicePayNetwork,
    InvoicePayToken,
} from "pay/types";
import { useExchangableTokens } from "hooks/useExchangableTokens";
import { useNetworkOnChain } from "hooks/useNetworkOnChain";
import useGetInvoiceEntity from "pay/hooks/useGetInvoiceEntity";

interface InvoicePayProps {
    children: ReactNode;
    entityAlias: string | undefined;
}

interface InvoicePayValue {
    entityAlias: string;
    loading: boolean;
    entity: InvoiceEntity;
    networks: InvoicePayNetwork[];
    tokens: ExchangableToken[];
    invoiceId: string | undefined;
    invoiceAmt: string | undefined;
    invoiceEmail: string | undefined;
    network: InvoicePayNetwork | undefined;
    token: InvoicePayToken | undefined;
    tokenAmt: string | undefined;
    confirmed: ConfirmedPayment | null;
    setInvoiceId: (invoiceId: string) => void;
    setInvoiceAmt: (invoiceAmt: string) => void;
    setInvoiceEmail: (invoiceEmail: string) => void;
    setNetwork: (network: InvoicePayNetwork | undefined) => void;
    setToken: (token: InvoicePayToken | undefined) => void;
    setTokenAmt: (tokenAmt: string | undefined) => void;
    setConfirmed: (confirmed: ConfirmedPayment | null) => void;
}

const InvoicePayContext = createContext<InvoicePayValue>({} as InvoicePayValue);

const InvoicePayProvider = ({
    children,
    entityAlias,
    ...props
}: InvoicePayProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [invoiceId, setInvoiceId] = useState<string>();
    const [invoiceAmt, setInvoiceAmt] = useState<string>();
    const [invoiceEmail, setInvoiceEmail] = useState<string>();
    const [network, setNetwork] = useState<InvoicePayNetwork | undefined>();
    const [token, setToken] = useState<InvoicePayToken | undefined>();
    const [tokenAmt, setTokenAmt] = useState<string | undefined>();
    const [confirmed, setConfirmed] = useState<ConfirmedPayment | null>(null);

    const {
        data: { entity, networks: resultNetworks = [] } = {},
        loading: dataLoading,
    } = useGetInvoiceEntity({
        entityAlias: entityAlias,
    });

    const networks = useNetworkOnChain<InvoicePayNetwork>(resultNetworks);

    const { exchangableTokens: tokens } = useExchangableTokens(network?.tokens);

    useEffect(() => {
        setLoading(dataLoading);
    }, [dataLoading]);

    const value: InvoicePayValue = useMemo(() => {
        return {
            entityAlias: entityAlias || ``,
            loading,
            entity: entity as InvoiceEntity,
            networks,
            tokens,
            invoiceId,
            invoiceAmt,
            invoiceEmail,
            network,
            token,
            tokenAmt,
            confirmed,
            setInvoiceId,
            setInvoiceAmt,
            setInvoiceEmail,
            setNetwork,
            setToken,
            setTokenAmt,
            setConfirmed,
        };
    }, [
        entityAlias,
        loading,
        entity,
        networks,
        tokens,
        invoiceId,
        invoiceAmt,
        invoiceEmail,
        network,
        token,
        tokenAmt,
        confirmed,
    ]);

    return (
        <InvoicePayContext.Provider value={value} {...props}>
            {children}
        </InvoicePayContext.Provider>
    );
};

const useInvoicePay = (): InvoicePayValue => {
    const context = useContext<InvoicePayValue>(InvoicePayContext);
    if (context === undefined) {
        throw new Error(
            `useInvoicePay() must be used within a InvoicePayProvider`
        );
    }
    return context;
};

export { InvoicePayProvider, useInvoicePay };
