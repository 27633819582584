import { useCallback, useEffect, useRef, useState } from "react";
import { apiServerUrl } from "default-variables";
import { useFetch } from "hooks/useFetch";
import { useWallet } from "context/Wallet";

// ! contractAddress and network are for the POST request, but will be removed as requirements from the backend

const useEmailByWallet = (authToken: string, contractAddress: string) => {
    const {
        walletConnected,
        getWalletEmail,
        setWalletEmail,
        networkConnected,
    } = useWallet();
    const [email, setEmail] = useState<string>(getWalletEmail() || ``);

    useEffect(() => {
        if (!walletConnected) return;

        // Email should be obtained from the backend - this is a temporary solution
        setEmail(getWalletEmail() || ``);
    }, [walletConnected, getWalletEmail]);

    const {
        data: dataFromGet,
        loading: isGettingEmail,
        refetch: fetchGet,
    } = useFetch(
        `${apiServerUrl}/wallet/${
            walletConnected?.proxyFor || walletConnected?.address || ``
        }`,
        {},
        false
    );

    useEffect(() => {
        if (!dataFromGet) return;

        setEmail(dataFromGet || ``);
    }, [dataFromGet]);

    const fetchEmailGet = useCallback(async () => {
        await fetchGet(true, {
            method: `GET`,
            headers: {
                "Content-Type": "application/json",
                Authorization: authToken,
                address: walletConnected?.address,
            },
        });
    }, [authToken, walletConnected?.address, fetchGet]);

    const {
        data: dataFromPost,
        loading: isPostingEmail,
        refetch: fetchPost,
    } = useFetch(
        `${apiServerUrl}/wallet/${
            walletConnected?.proxyFor || walletConnected?.address || ``
        }`,
        {},
        false
    );

    useEffect(() => {
        if (!dataFromPost || !tempEmail.current) return;

        setEmail(tempEmail.current);
    }, [dataFromPost]);

    const tempEmail = useRef<string>(``);
    const fetchEmailPost = useCallback(
        async (emailAddr: string) => {
            if (!walletConnected?.address || !networkConnected?.networkId)
                return;

            tempEmail.current = emailAddr;

            await fetchPost(true, {
                method: `POST`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: authToken,
                    address: walletConnected.address,
                },
                body: JSON.stringify({
                    email: emailAddr,
                    contractAddress,
                    network: parseInt(networkConnected.networkId, 16),
                    pendingMessageHash: ``,
                }),
            });
        },
        [
            authToken,
            walletConnected?.address,
            networkConnected?.networkId,
            contractAddress,
            fetchPost,
        ]
    );

    // Put the email address in the Wallet context
    useEffect(() => {
        if (!email) return;
        setWalletEmail(email);
    }, [email, setWalletEmail]);

    return {
        email,
        fetchEmailGet,
        isGettingEmail,
        fetchEmailPost,
        isPostingEmail,
    };
};

export { useEmailByWallet };
