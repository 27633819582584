import { memo, forwardRef, ForwardedRef, useCallback, RefObject } from "react";
import { validatePositiveNumber } from "utils/formValidation";
import { Company } from "company/types";
import Field from "components/Field";
import Label from "components/Label";
import InputWithAddon from "components/InputWithAddon";
import { regexToPattern, regex } from "utils/regex";
import { strNumToDecimalPrecision } from "utils/financial";
import { GeneralTokenDetailsResponse } from "api";

interface InvoiceAmountProps {
    token?: GeneralTokenDetailsResponse;
    usd?: boolean;
    value?: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    disabled?: boolean;
}

const InvoiceAmount = memo(
    forwardRef<HTMLInputElement, InvoiceAmountProps>(
        (
            {
                token,
                usd,
                value,
                setValue,
                disabled = false,
            }: InvoiceAmountProps,
            ref: ForwardedRef<HTMLInputElement>
        ) => {
            const handleChange = useCallback(
                (event: React.ChangeEvent<HTMLInputElement>) => {
                    setValue(event.target.value);
                },
                [setValue]
            );

            const decimals = usd ? 2 : token?.decimals || 6;

            return (
                <Field>
                    <Label htmlFor="amount" required>
                        Amount
                    </Label>
                    <InputWithAddon
                        inputProps={{
                            type: "text",
                            name: "amount",
                            innerRef: ref as RefObject<HTMLInputElement>,
                            placeholder: `0.${"0".padEnd(decimals, "0")}`,
                            value: strNumToDecimalPrecision(
                                String(value),
                                decimals
                            ),
                            pattern: regexToPattern(regex.coins),
                            onChange: handleChange,
                            onBlur: (event) =>
                                validatePositiveNumber({
                                    input: event.target,
                                    reportValidity: false,
                                }),
                            disabled,
                        }}
                        addon={{
                            content: usd ? "$" : token ? token.symbol : "",
                            position: usd ? "left" : "right",
                        }}
                    />
                </Field>
            );
        }
    )
);

export default InvoiceAmount;
