import styled from "styled-components";
import colors from "theme/colors";

export const Indicator = styled.sup`
    display: inline-block;
    font-size: 0.5625rem;
    line-height: 1;
    min-width: 1.5em;
    background-color: ${colors.error};
    color: ${colors.textOnError};
    text-align: center;
    border-radius: 0.75em;
    margin-inline-start: 0.125em;
    padding: 0.25em;
`;
