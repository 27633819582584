import * as S from "./style";
import { ReactNode } from "react";
import colors from "theme/colors";
import { firstToUpper } from "utils/strings";

export enum NotificationContext {
    HOVERING = `hovering`,
    STATIC = `static`,
}

export enum NotificationType {
    INFO = `info`,
    SUCCESS = `success`,
    WARNING = `warning`,
    ERROR = `error`,
    WORKING = `working`,
}

interface BaseMsgProps {
    id: string;
    type: NotificationType;
    msg: ReactNode;
    context?: NotificationContext;
    style?: React.CSSProperties;
}
// Exclude 'working' as an option if the notification can auto-expire
interface MsgWillExpire extends Omit<BaseMsgProps, "type"> {
    expires: number;
    type: Exclude<NotificationType, NotificationType.WORKING>;
}
interface MsgWontExpire extends BaseMsgProps {
    expires: false;
    type: NotificationType;
}

export type NotificationMessage = MsgWillExpire | MsgWontExpire;

interface NotificationProps {
    message: NotificationMessage;
    removeNotification?: ((id: string) => void) | false;
}

const Notification = ({
    message: {
        id,
        type,
        msg,
        expires,
        style = {},
        context = NotificationContext.HOVERING,
    },
    removeNotification,
}: NotificationProps) => {
    const handleClose = () =>
        removeNotification ? removeNotification(id) : false;
    return (
        <S.Notification
            type={type}
            expires={expires}
            role="alertdialog"
            aria-label={`${firstToUpper(type, true)} notification`}
            aria-describedby={`msg-${id}`}
            context={context}
            style={style}
        >
            {type === NotificationType.WORKING && (
                <S.Working color={colors.textOnPrimary} />
            )}
            <S.Message id={`msg-${id}`}>{msg}</S.Message>
            {removeNotification && type !== NotificationType.WORKING && (
                <S.Close
                    aria-label="Close notification"
                    onClick={handleClose}
                />
            )}
        </S.Notification>
    );
};

export default Notification;
