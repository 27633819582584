import { FunctionComponent } from "react";
import { Company } from "company/types";
import ItemActiveForm from "company/components/ItemActiveForm";
import ItemAutoInvoiceForm from "company/components/ItemAutoInvoiceForm";
import Dropdown from "components/Dropdown";
import DropdownItem from "components/DropdownItem";
import VerticalDots from "components/icons/VerticalDots";
import { useModal } from "context/ModalProvider";
import EditItemForm from "company/components/items/EditItemForm";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import { useUser, UserRole } from "context/User";
import {
    canEditItemAutoInvoice,
    itemIsSubscription,
} from "company/utils/items";

interface ItemManageProps {
    item: Company.Item;
}

const ItemManage: FunctionComponent<ItemManageProps> = ({ item }) => {
    const {
        config: { entities },
    } = useGetCompanyConfig();

    const { hasRole } = useUser();
    const { openModal, closeModal } = useModal();

    const canManage = hasRole(UserRole.COMPANY);

    const handleOpenEditItemFormModal = () => {
        openModal(
            <EditItemForm item={item} onSuccess={closeModal} />,
            `Edit item`
        );
    };

    const handleOpenItemActiveFormModal = () => {
        openModal(
            <ItemActiveForm
                item={item}
                onSuccess={closeModal}
                onCancel={closeModal}
            />
        );
    };

    const handleOpenItemAutoInvoiceFormModal = () => {
        openModal(
            <ItemAutoInvoiceForm
                item={item}
                onSuccess={closeModal}
                onCancel={closeModal}
            />
        );
    };

    const entity = entities.find((entity) => entity.entityId === item.entityId);
    const isSubscription = itemIsSubscription(item);
    const canEnableAutoInvoice =
        canManage &&
        entity &&
        entity.delegatedSigning &&
        entity.inboundTreasuries &&
        Object.keys(entity.inboundTreasuries).length > 0 &&
        isSubscription &&
        canEditItemAutoInvoice(item);

    return (
        <Dropdown
            anchorEl={
                <a href="#menu" onClick={(event) => event.preventDefault()}>
                    <VerticalDots width="1rem" height="1rem" fill="#888" />
                </a>
            }
        >
            {item.active && (
                <DropdownItem onClick={handleOpenEditItemFormModal}>
                    Edit
                </DropdownItem>
            )}
            <DropdownItem onClick={handleOpenItemActiveFormModal}>
                {item.active ? "Disable" : "Enable"}
            </DropdownItem>
            {canEnableAutoInvoice && (
                <DropdownItem onClick={handleOpenItemAutoInvoiceFormModal}>
                    {item.autoInvoice ? "Disable" : "Enable"} automatic
                    invoicing
                </DropdownItem>
            )}
        </Dropdown>
    );
};

export default ItemManage;
