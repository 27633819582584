import {
    forwardRef,
    Ref,
    useRef,
    useImperativeHandle,
    useCallback,
    ChangeEvent,
    useEffect,
    ComponentProps,
    ReactNode,
} from "react";
import { useWallet } from "context/Wallet";
import { useNetworkAndToken } from "context/NetworkAndToken";
import Field from "components/Field";
import Label from "components/Label";
import Select from "components/Select";

interface SelectTokenProps
    extends Omit<ComponentProps<typeof Field>, "children"> {
    children?: ReactNode;
}

const SelectToken = forwardRef(
    ({ ...props }: SelectTokenProps, ref: Ref<TokenFieldRef>) => {
        const { isNetworkSetting } = useWallet();
        const { tokenValue, handleTokenValueChange, tokenOptions } =
            useNetworkAndToken();

        const tokenRef = useRef<HTMLSelectElement>(null);
        useImperativeHandle(ref, () => ({ value: tokenValue }));

        const handleTokenChange = useCallback(
            ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
                handleTokenValueChange(value);
            },
            [handleTokenValueChange]
        );

        // When tokenOptions update, set the first token as the selected value
        useEffect(() => {
            handleTokenValueChange(tokenOptions[0]?.value || ``);
        }, [tokenOptions, handleTokenValueChange]);

        return (
            <Field {...props}>
                <Label htmlFor="tokenSelect">Token</Label>
                <Select
                    innerRef={tokenRef}
                    name="tokenSelect"
                    value={tokenValue || ``}
                    onChange={handleTokenChange}
                    options={tokenOptions}
                    disabled={!tokenValue || isNetworkSetting}
                    className={"fs-unmask"}
                />
            </Field>
        );
    }
);

export default SelectToken;
