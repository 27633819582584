import { useState, useEffect } from "react";
import * as S from "./style";
import { useSwapForm } from "recurring/context/SwapForm";
import OptionList from "components/OptionList";
import ExchangeCurrencies from "recurring/components/ExchangeCurrencies";
import SubmitButton from "recurring/components/SubmitButton";
import { frequencyTypes } from "default-variables";
import { CardSection, CardFooter } from "components/Card";
import { useScreenController } from "recurring/context/ScreenController";
import { useNotificationQueue } from "context/NotificationQueue";
import Tooltip from "components/Tooltip";
import InfoToolTip from "components/InfoToolTip";
import { useTokens } from "hooks/useTokens";
import Gear from "components/icons/Gear";
import { useWallet } from "context/Wallet";

const RecurringSwapForm = () => {
    const { exchange, frequency, setFrequency, fee } = useSwapForm();
    const { networkConnected, walletConnected, getTokenBalance } = useWallet();
    const { getTokenOnNetwork } = useTokens();
    const { onFormComplete } = useScreenController();
    const [validity, setValidity] = useState({ sell: true, buy: true });
    const [canSubmit, setCanSubmit] = useState(false);
    const { addNotification } = useNotificationQueue();

    const handleRecurringSwapOrderSubmit = (event) => {
        event.preventDefault();

        let valid = {
            sell: true,
            buy: true,
        };
        const { minSellAmount } = getTokenOnNetwork(
            networkConnected.networkId,
            exchange.sell.currency
        );
        if (
            !exchange.sell.amount ||
            Number(exchange.sell.amount) < minSellAmount
        ) {
            valid.sell = false;
        }
        if (!exchange.buy.amount || Number(exchange.buy.amount) === 0) {
            valid.buy = false;
        }
        setValidity(valid);

        if (!valid.sell || !valid.buy) {
            addNotification({
                msg: !valid.sell
                    ? `Invalid value: amount for swap must > ${minSellAmount} ${exchange.sell.currency}`
                    : `Update your token values to process the swap.`,
            });
            return;
        }

        onFormComplete();
    };

    const handleFormChanged = () => {
        setValidity({ sell: true, buy: true });
    };

    useEffect(() => {
        if (!exchange.sell.currency || !exchange.sell.amount) {
            setCanSubmit(false);
            return;
        }
        (async () => {
            const balance = Number(
                await getTokenBalance({
                    tokenSymbol: exchange.sell.currency,
                })
            );
            if (!balance) {
                setCanSubmit(false);
                return;
            }
            setCanSubmit(
                exchange.sell.amount > 0 &&
                    Number(exchange.sell.amount) <= balance
            );
        })();
    }, [exchange.sell, getTokenBalance]);

    return (
        <S.RecurringSwapForm
            forwardedAs="form"
            onSubmit={handleRecurringSwapOrderSubmit}
            onChange={handleFormChanged}
        >
            <S.SwapHeader>
                <h1>Recurring swap</h1>
                <Tooltip
                    placement="left"
                    title={
                        <>
                            Slippage is currently set to 0.5%.
                            <br />
                            See our FAQ for more on slippage.
                        </>
                    }
                >
                    <Gear height="1.5rem" width="1.5rem" fill="inherit" />
                </Tooltip>
            </S.SwapHeader>
            <CardSection>
                <h2 hidden>Currency</h2>
                <ExchangeCurrencies validity={validity} />
            </CardSection>
            <CardSection>
                <h2>
                    Frequency{` `}
                    <InfoToolTip
                        size="1em"
                        title={`The first swap will take place now.`}
                    />
                </h2>
                <S.InfoText>
                    How often would you like this swap to take place?
                </S.InfoText>
                <OptionList
                    name="frequency"
                    onChange={(e) => setFrequency(e.target.value)}
                    defaultValue={frequency}
                    data={frequencyTypes}
                />
            </CardSection>
            <CardFooter>
                <SubmitButton canSubmit={canSubmit}>Submit order</SubmitButton>
                {fee.baseFee && (
                    <S.FooterTxt>
                        Network gas fees apply only at initial buy. Thereafter,
                        there is a
                        <br />
                        {fee.baseFee} + {fee.percentFee} keeper fee charged at
                        each swap.
                    </S.FooterTxt>
                )}
            </CardFooter>
        </S.RecurringSwapForm>
    );
};

export default RecurringSwapForm;
