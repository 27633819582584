import styled from "styled-components";
import c from "theme/colors";
import spacing, { SpacingTrack, getSpacing } from "theme/spacing";
import { Separator } from ".";

export const SubSection = styled.section<{
    spacing?: SpacingTrack;
    separator?: Separator | `${Separator}`;
}>`
    ${({ spacing }) =>
        spacing && `margin-block: ${getSpacing(spacing)} !important;`}

    ${({ separator }) =>
        separator === Separator.Top
            ? `border-block-start: 1px solid ${c.borderLight};`
            : separator === Separator.Bottom
            ? `border-block-end: 1px solid ${c.borderLight};`
            : separator === Separator.Both
            ? `border-block: 1px solid ${c.borderLight};`
            : ``}
`;

export const TitleWrapper = styled.header`
    display: flex;
    flex-direction: column;
    gap: ${spacing.xxxs};
    margin-bottom: ${spacing.sm};
`;
