import { useEffect } from "react";
import { useDetailsManager } from "context/DetailsManager";
import { CheckoutSteps } from "checkout/hooks/useCheckoutStateMachine";
import { CheckoutPanel, panelSummaries } from "checkout/components/CartAndForm";

const useManageDetailsPanels = (state: { step: CheckoutSteps }) => {
    const { setDetailsState } = useDetailsManager();
    useEffect(() => {
        switch (state.step) {
            case CheckoutSteps.PAYMENT:
                setDetailsState({
                    [CheckoutPanel.PAYMENT]: {
                        open: true,
                        disabled: false,
                        summary: panelSummaries[CheckoutPanel.PAYMENT].idle,
                    },
                    [CheckoutPanel.CONTACT]: {
                        open: false,
                        disabled: true,
                        summary: panelSummaries[CheckoutPanel.CONTACT].idle,
                    },
                    [CheckoutPanel.APPROVE]: {
                        open: false,
                        disabled: true,
                        summary: panelSummaries[CheckoutPanel.APPROVE].idle,
                    },
                });
                break;
            case CheckoutSteps.CONTACT:
                setDetailsState({
                    [CheckoutPanel.PAYMENT]: {
                        open: false,
                        disabled: false,
                        summary: panelSummaries[CheckoutPanel.PAYMENT].complete,
                    },
                    [CheckoutPanel.CONTACT]: {
                        open: true,
                        disabled: false,
                        summary: panelSummaries[CheckoutPanel.CONTACT].idle,
                    },
                    [CheckoutPanel.APPROVE]: {
                        open: false,
                        disabled: true,
                        summary: panelSummaries[CheckoutPanel.APPROVE].idle,
                    },
                });
                break;
            case CheckoutSteps.APPROVE:
                setDetailsState({
                    [CheckoutPanel.PAYMENT]: {
                        open: false,
                        disabled: false,
                        summary: panelSummaries[CheckoutPanel.PAYMENT].complete,
                    },
                    [CheckoutPanel.CONTACT]: {
                        open: false,
                        disabled: false,
                        summary: panelSummaries[CheckoutPanel.CONTACT].complete,
                    },
                    [CheckoutPanel.APPROVE]: {
                        open: true,
                        disabled: false,
                        summary: panelSummaries[CheckoutPanel.APPROVE].idle,
                    },
                });
                break;
            case CheckoutSteps.COMPLETE:
                setDetailsState({
                    [CheckoutPanel.PAYMENT]: {
                        open: false,
                        disabled: false,
                        summary: panelSummaries[CheckoutPanel.PAYMENT].complete,
                    },
                    [CheckoutPanel.CONTACT]: {
                        open: false,
                        disabled: false,
                        summary: panelSummaries[CheckoutPanel.CONTACT].complete,
                    },
                    [CheckoutPanel.APPROVE]: {
                        open: false,
                        disabled: false,
                        summary: panelSummaries[CheckoutPanel.APPROVE].complete,
                    },
                });
                break;
        }
    }, [state.step, setDetailsState]);
};

export default useManageDetailsPanels;
