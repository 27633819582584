import { Company, CompanyFrequency, CreateItemRequest } from "company/types";
import Button from "components/Button";
import Form from "components/Form";
import { FormEvent, FunctionComponent, useMemo, useRef, useState } from "react";
import { ItemType } from "types/common-enums";
import ItemAcceptedTokensField, {
    ItemAcceptedTokensFieldRef,
} from "company/components/items/ItemAcceptedTokensField";
import ItemAmountField, {
    ItemAmountFieldRef,
} from "company/components/items/ItemAmountField";
import ItemAutoInvoiceField, {
    InvoicePlurality,
    ItemAutoInvoiceFieldRef,
} from "company/components/items/ItemAutoInvoiceField";
import ItemFrequencyField, {
    ItemFrequencyFieldRef,
} from "company/components/items/ItemFrequencyField";
import ItemInitialOffsetField, {
    ItemInitialOffsetFieldRef,
} from "company/components/items/ItemInitialOffsetField";
import ItemNameField, {
    ItemNameFieldRef,
} from "company/components/items/ItemNameField";
import ItemPriceMetadataField, {
    ItemPriceMetadataFieldRef,
} from "company/components/items/ItemPriceMetadataField";
import ItemPricingModelField, {
    ItemPricingModelFieldRef,
} from "company/components/items/ItemPricingModelField";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";
import * as S from "./style";
import { useGetSelfServeAcceptedTokens } from "hooks/useGetSelfServeAcceptedTokens";
import LoadingBox from "components/LoadingBox";
import FailedDataFetchingMessage from "components/FailedDataFetchingMessage";
import { ItemNetwork, ItemNetworkDisabledReason } from "company/utils/items";
import { getItemPriceType, isPricedInToken } from "utils/items";

interface CreateItemRequestFormProps {
    onSubmit: (item: CreateItemRequest) => void;
    item: CreateItemRequest;
    overrideAmount?: boolean;
}

const CreateItemRequestForm: FunctionComponent<CreateItemRequestFormProps> = ({
    onSubmit,
    item,
    overrideAmount,
}) => {
    const { tokens } = useGetTokensMetadata();
    const { networks } = useGetNetworks();
    const {
        acceptedTokensByNetwork,
        acceptedNetworkIds,
        getSelfServeAcceptedTokensIsLoading,
        getSelfServeAcceptedTokensIsError,
    } = useGetSelfServeAcceptedTokens();

    const itemNetworks: ItemNetwork[] = useMemo(() => {
        return networks
            .filter((network) => network.active)
            .map((network) => {
                const isSupported = acceptedNetworkIds.includes(network.id);

                const acceptedTokensAddresses =
                    acceptedTokensByNetwork[network.id];

                const acceptedTokens = tokens.filter(
                    (token) =>
                        acceptedTokensAddresses &&
                        acceptedTokensAddresses.includes(token.address) &&
                        token.networkId === network.id
                );

                let selectedTokens = acceptedTokens;
                if (item.acceptedTokens) {
                    const selectedTokensAddresses =
                        item.acceptedTokens[network.id];

                    selectedTokens = tokens.filter(
                        (token) =>
                            selectedTokensAddresses &&
                            selectedTokensAddresses.includes(token.address) &&
                            token.networkId === network.id
                    );
                }

                return {
                    network: network,
                    acceptedTokens: acceptedTokens,
                    selectedTokens: selectedTokens,
                    disabled: !isSupported,
                    disabledReason: isSupported
                        ? undefined
                        : ItemNetworkDisabledReason.SelfServeUpgradeRequired,
                };
            });
    }, [
        acceptedTokensByNetwork,
        item.acceptedTokens,
        networks,
        acceptedNetworkIds,
        tokens,
    ]);

    const [fixedPrice, setFixedPrice] = useState<boolean>(item.amount !== 0);
    const [autoInvoice, setAutoInvoice] = useState<Company.Item["autoInvoice"]>(
        item.autoInvoice || true
    );

    const isSubscription = item.frequencyCount > 0;
    const isOneTimePayment = item.frequencyCount === 0;

    // Refs to the different item fields
    const itemNameFieldRef = useRef<ItemNameFieldRef>(null);
    const itemPriceMetadataFieldRef = useRef<ItemPriceMetadataFieldRef>(null);

    const itemAcceptedTokensFieldRef = useRef<ItemAcceptedTokensFieldRef>(null);
    const itemAmountFieldRef = useRef<ItemAmountFieldRef>(null);
    const itemFrequencyFieldRef = useRef<ItemFrequencyFieldRef>(null);
    const itemPricingModelFieldRef = useRef<ItemPricingModelFieldRef>(null);
    const itemAutoInvoiceFieldRef = useRef<ItemAutoInvoiceFieldRef>(null);
    const itemInitialOffsetFieldRef = useRef<ItemInitialOffsetFieldRef>(null);

    // Submit
    const createItem = async (event: FormEvent<HTMLFormElement>) => {
        // Get & Validate name
        let name: string = "";
        if (itemNameFieldRef.current) {
            name = itemNameFieldRef.current.name;
            if (!itemNameFieldRef.current.validate()) return;
        }
        if (!name) return;

        // Get priceMetadata
        let priceMetadata: string | undefined = undefined;
        if (itemPriceMetadataFieldRef.current) {
            priceMetadata = itemPriceMetadataFieldRef.current.priceMetadata;
        }

        const typeId: ItemType = isOneTimePayment
            ? ItemType.One_Time
            : ItemType.Subscription; // Default to subscription

        const acceptedTokensByNetwork =
            itemAcceptedTokensFieldRef?.current?.acceptedTokensByNetwork;

        // Get & Validate amount
        let amount: number = 0;
        if (fixedPrice && itemAmountFieldRef.current) {
            amount = itemAmountFieldRef.current.amount;
            if (!itemAmountFieldRef.current.validate()) return;
        }

        // Get & Validate frequency
        let frequency: CompanyFrequency | undefined = undefined;

        // 0 as default for OTP
        let frequencyCount: number | undefined = 0;

        // Only Subscription can have frequency
        if (isSubscription && itemFrequencyFieldRef.current) {
            frequency = itemFrequencyFieldRef.current.frequency;
            frequencyCount = itemFrequencyFieldRef.current.frequencyCount;
            if (!itemFrequencyFieldRef.current.validate()) return;
            if (!frequency) return;
            if (!frequencyCount) return;
        }

        // Get & Validate payment collection settings
        let autoInvoice: boolean = false;
        let initialOffset: number | undefined = undefined;

        // autoInvoice and initialOffset are only available for fixed amount
        if (fixedPrice && itemAutoInvoiceFieldRef.current) {
            autoInvoice = itemAutoInvoiceFieldRef.current.autoInvoice;
        }

        // InitialOffset are only available for fixed amount
        if (fixedPrice && autoInvoice && itemInitialOffsetFieldRef.current) {
            initialOffset = itemInitialOffsetFieldRef.current.initialOffset;
        }

        const newItem: CreateItemRequest = {
            name,
            priceMetadata,
            typeId,
            acceptedTokens: acceptedTokensByNetwork,
            amount,
            frequency,
            frequencyCount,
            autoInvoice,
            initialOffset,
        };

        onSubmit(newItem);
    };

    if (getSelfServeAcceptedTokensIsLoading)
        return <LoadingBox height="20rem" />;
    if (getSelfServeAcceptedTokensIsError) return <FailedDataFetchingMessage />;

    return (
        <Form onSubmit={createItem} preventEnterKeySubmission>
            {/* Name */}
            <ItemNameField ref={itemNameFieldRef} defaultName={item.name} />

            {/* Price model (fixed or variable) */}
            <ItemPricingModelField
                ref={itemPricingModelFieldRef}
                defaultFixedPrice={fixedPrice}
                onChangeFixedPrice={(fixedPrice) => setFixedPrice(fixedPrice)}
            />

            {/* Price amount & description */}
            <S.PriceSectionWrapper>
                <S.AmountWrapper>
                    <ItemAmountField
                        ref={itemAmountFieldRef}
                        fixedPrice={fixedPrice}
                        priceType={getItemPriceType(item.amount)}
                        defaultAmount={
                            overrideAmount
                                ? ""
                                : isPricedInToken(item.amount)
                                ? "N/A"
                                : String(item.amount)
                        }
                    />
                </S.AmountWrapper>
                <S.PriceMetadataWrapper>
                    <ItemPriceMetadataField
                        defaultPriceMetadata={item.priceMetadata}
                        ref={itemPriceMetadataFieldRef}
                    />
                </S.PriceMetadataWrapper>
            </S.PriceSectionWrapper>

            {/* Subscription-only: Frequency */}
            {isSubscription && (
                <ItemFrequencyField ref={itemFrequencyFieldRef} />
            )}

            {/* Automatic/Manual */}
            <ItemAutoInvoiceField
                ref={itemAutoInvoiceFieldRef}
                plurality={InvoicePlurality.Multiple}
                canAutoInvoice={true}
                defaultAutoInvoice={autoInvoice}
                onChange={(autoInvoice) => setAutoInvoice(autoInvoice)}
                fixedPrice={fixedPrice}
                isSubscription={true}
            />

            {/* Free trials */}
            <ItemInitialOffsetField
                ref={itemInitialOffsetFieldRef}
                defaultInitialOffset={0}
                canEdit={autoInvoice}
            />

            {/* Accepted tokens */}
            <ItemAcceptedTokensField
                ref={itemAcceptedTokensFieldRef}
                itemNetworks={itemNetworks}
            />

            <Button>Save</Button>
        </Form>
    );
};

export default CreateItemRequestForm;
