import styled from "styled-components";
import colors from "theme/colors";
import radius from "theme/radius";
import spacing from "theme/spacing";
import { paragraphMaxWidth } from "theme/typography";

export const EmptyTableMessage = styled.div`
    padding: ${spacing.xxl} ${spacing.md};
    text-align: center;
    border-radius: ${radius.md};
    border: 1px dashed ${colors.borderLight};
    margin-bottom: ${spacing.md};
`;

export const EmptyTableMessageWrapper = styled.div`
    max-width: ${paragraphMaxWidth};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: ${spacing.xs};
`;
