import { FC, MemoExoticComponent, ReactNode, isValidElement } from "react";

const isDescendantOf = (parent: HTMLElement, child: HTMLElement): boolean => {
    return [...parent.children].filter(
        (component) =>
            component === child ||
            isDescendantOf(component as HTMLElement, child)
    ).length
        ? true
        : false;
};

// If given a react component, this function flattens all of its children contents into a string
// NOTE: Ultimately, this function dead-ends if a component returns without `children`, even if it returns embedded JSX elements
const getStrIfComponent = (component: ReactNode) => {
    // Return if not a React component
    if (!isValidElement(component))
        return typeof component === `string`
            ? component.trim()
            : component ?? ``;

    // If it's a single child, not an Array of children
    if (!Array.isArray(component.props.children))
        return (
            component.props.children?.trim?.() ||
            (component.props?.children ?? ``)
        );

    return component.props.children
        ?.reduce(
            (str: string, val: ReactNode) =>
                str
                    .concat(
                        ` `,
                        isValidElement(val) ? getStrIfComponent(val) : val ?? ``
                    )
                    .trim(),
            ``
        )
        .trim();
};

// Helper type to force summary to be memoized, if it's an object - preventing a re-render loop
type ReactNodesThatDontNeedMemoization =
    | string
    | number
    | boolean
    | null
    | undefined;

export type ReactNodesAndMemoizedObjects =
    | ReactNodesThatDontNeedMemoization
    | MemoExoticComponent<FC<any>>;

const primitiveTypes = ["string", "number", "boolean"];
const renderIntoReactNode = (
    fakeReactNode: ReactNodesAndMemoizedObjects
): ReactNode => {
    const typeOfNode = typeof fakeReactNode;

    // Primitive types that don't need memoization can just be passed through
    if (primitiveTypes.includes(typeOfNode) || fakeReactNode == null) {
        return fakeReactNode as ReactNode;
    }

    // Handles objects memoized with React.memo()
    if ((fakeReactNode as any).$$typeof === Symbol.for("react.memo")) {
        const MemoComponent = fakeReactNode as MemoExoticComponent<any>;
        return <MemoComponent />;
    }

    // Handle valid React elements
    if (isValidElement(fakeReactNode)) {
        return fakeReactNode;
    }

    return null;
};

export { isDescendantOf, getStrIfComponent, renderIntoReactNode };
