import styled, { css } from "styled-components";

const slideToX = css`
    span:nth-child(1) {
        translate: 0 0.40625em;
        rotate: 40deg;
        width: 1.4em;
        /* transition in: slide first, then rotate */
        transition: width calc(var(--speed) / 2) linear calc(var(--speed) / 2),
            height 0s linear calc(var(--speed) / 2),
            translate calc(var(--speed) / 2) linear,
            rotate calc(var(--speed) / 2) linear calc(var(--speed) / 2);
    }
    span:nth-child(2) {
        height: 0;
        width: 1.4em;
    }
    span:nth-child(3) {
        translate: 0 -0.40625em;
        rotate: -40deg;
        width: 1.4em;
        /* transition in: slide first, then rotate */
        transition: width calc(var(--speed) / 2) linear calc(var(--speed) / 2),
            height 0s linear calc(var(--speed) / 2),
            translate calc(var(--speed) / 2) linear,
            rotate calc(var(--speed) / 2) linear calc(var(--speed) / 2);
    }
`;

export const Burger = styled.span<{
    open: boolean;
    speed: string;
    $color: string;
}>`
    --speed: ${({ speed }) => speed};
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    color: ${({ $color }) => $color};
    width: 1.25em;
    height: 1em;
    align-items: center;
    transform-origin: 50% 0;

    span {
        width: 100%;
        height: 0.1875em;
        background-color: currentColor;
        translate: 0 0;
        rotate: 0deg;
        transform: translateY(0) rotate(0deg);

        /* transition out: rotate first, then slide */
        transition: width calc(var(--speed) / 2) linear calc(var(--speed) / 2),
            height 0s linear calc(var(--speed) / 2),
            rotate calc(var(--speed) / 2) linear,
            translate calc(var(--speed) / 2) linear calc(var(--speed) / 2);
    }

    ${({ open }) => (open ? slideToX : undefined)}
`;
