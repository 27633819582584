import { forwardRef, useImperativeHandle, useState, Ref } from "react";
import Label from "components/Label";
import Field from "components/Field";
import Input from "components/Input";

export interface ItemPriceMetadataFieldProps {
    disabled?: boolean;
    defaultPriceMetadata?: string | null;
}

export type ItemPriceMetadataFieldRef = {
    priceMetadata: string;
};

function ItemPriceMetadataField(
    { disabled, defaultPriceMetadata = "" }: ItemPriceMetadataFieldProps,
    ref: Ref<ItemPriceMetadataFieldRef>
) {
    const [priceMetadata, setPriceMetadata] = useState<string>(
        defaultPriceMetadata || ""
    );

    useImperativeHandle(ref, () => ({ priceMetadata }));

    return (
        <Field disabled={disabled}>
            <Label
                htmlFor="priceMetadata"
                optional
                subLabel="This will show under the price on the cart"
            >
                Price description
            </Label>
            <Input
                type="text"
                disabled={disabled}
                name="priceMetadata"
                value={priceMetadata}
                onChange={(event) => setPriceMetadata(event.target.value)}
                placeholder="Details about pricing"
            />
        </Field>
    );
}
export default forwardRef(ItemPriceMetadataField);
