import * as S from "./styles";
import { FunctionComponent, HTMLAttributes } from "react";
import Tooltip from "components/Tooltip";

interface LabelBase extends HTMLAttributes<HTMLLabelElement> {
    children: React.ReactNode;
    subLabel?: React.ReactNode;
    htmlFor?: string;
    inline?: boolean;
    className?: string;
}
interface RequiredLabel extends LabelBase {
    required?: boolean;
    optional?: never;
}
interface OptionalLabel extends LabelBase {
    required?: never;
    optional?: boolean;
}

// Make sure only optional *or* required can be defined at the same time, not both
type LabelProps = RequiredLabel | OptionalLabel;

export const Label: FunctionComponent<LabelProps> = ({
    children,
    subLabel,
    htmlFor,
    optional = false,
    required = false,
    inline = false,
    className,
    ...props
}) => {
    return (
        <S.Label
            htmlFor={htmlFor}
            inline={inline}
            className={className}
            {...props}
        >
            <S.MainLabel>
                {children}
                {required && (
                    <Tooltip placement="right" title="Required" slim>
                        <S.Required>*</S.Required>
                    </Tooltip>
                )}
                {optional && <S.Optional>(optional)</S.Optional>}
            </S.MainLabel>

            {subLabel && <S.SubLabel>{subLabel}</S.SubLabel>}
        </S.Label>
    );
};

export default Label;
