import * as S from "./style";
import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { useCustomerData } from "customer/context/CustomerData";
import { formateDateForUrl } from "utils/datetime";
import DownloadCsv from "components/DownloadCsv";
import ErrorMessage from "components/ErrorMessage";
import Anchor from "components/Anchor";
import RefreshCounter, { CounterType } from "components/RefreshCounter";
import Section from "components/Section";
import TransactionTable from "components/TransactionTable";
import { rowsToTableRecords } from "utils/tables";
import { TransactionDataLoader, TransactionTableRow } from "customer/types";
import EmptyTableMessage from "components/EmptyTableMessage";

const tableHeadings: RowHeading<TransactionTableRow>[] = [
    { label: `Date due`, field: `dateDue`, sortable: true },
    {
        label: `Invoiced`,
        field: `invoiced`,
        style: { shrink: true, textAlign: "right" },
    },
    { label: `Authorization`, field: `authorization` },
    { label: `Item`, field: `item` },
    { label: `Invoice #`, field: `invoice`, sortable: true },
];

const csvHeadings: RowHeading<TransactionTableRow>[] = [
    { label: `Date due`, field: `dateDue` },
    { label: `Invoiced`, field: "invoicedAmt" },
    { label: `Token`, field: "invoicedToken" },
    { label: `Authorization`, field: `authorization` },
    { label: `Item`, field: "itemName" },
    { label: `Frequency`, field: "frequency" },
    { label: `Invoice #`, field: `invoice` },
];

const Upcoming = () => {
    const {
        lastFetched,
        refetch,
        transactions: { upcoming, due },
    } = useCustomerData();

    const [now, setNow] = useState<Tuple[]>([]);
    const [later, setLater] = useState<Tuple[]>([]);
    const { pagination, sort: sortUpcoming } =
        useLoaderData<TransactionDataLoader>();
    const sortDue: SortBy = { ...sortUpcoming, isAscending: false };

    useEffect(() => {
        if (!upcoming || !due) return;

        setNow(rowsToTableRecords(due, tableHeadings));
        setLater(rowsToTableRecords(upcoming, tableHeadings));
    }, [upcoming, due]);

    const handleRefresh = () => {
        if (!refetch) return;

        // Could replace tables with spinning loader animations
        refetch();
    };

    return (
        <>
            <S.Filters>
                <RefreshCounter
                    refreshedAt={lastFetched}
                    counter={CounterType.Running}
                    onRefresh={handleRefresh}
                />
                <S.Download>
                    <DownloadCsv
                        filename={`due_and_upcoming-transactions_${formateDateForUrl(
                            Date.now()
                        )}.csv`}
                        columns={csvHeadings}
                        rows={rowsToTableRecords(
                            [...due, ...upcoming],
                            csvHeadings
                        )}
                    />
                </S.Download>
            </S.Filters>

            <Section>
                {now.length + later.length > 0 ? (
                    <>
                        <S.TableSection>
                            <h3>Due</h3>
                            <TransactionTable
                                headings={tableHeadings}
                                records={now}
                                pagination={pagination}
                                sort={sortDue}
                                ifNoRecords={
                                    <EmptyTableMessage
                                        title="No transactions due immediately"
                                        description={
                                            <>
                                                Need help?{` `}
                                                <Anchor
                                                    href={`mailto:${
                                                        import.meta.env
                                                            .VITE_EMAIL_HELP
                                                    }`}
                                                >
                                                    Contact us
                                                </Anchor>
                                                .
                                            </>
                                        }
                                    />
                                }
                            ></TransactionTable>
                        </S.TableSection>
                        <S.TableSection>
                            <h3>Upcoming</h3>
                            <p>
                                <S.Explainer>
                                    Companies can send invoices any time. This
                                    represents the Invoice that Loop is made
                                    aware of. You might have more invoices due
                                    that we have yet to receive.
                                </S.Explainer>
                            </p>
                            <TransactionTable
                                headings={tableHeadings}
                                records={later}
                                pagination={pagination}
                                sort={sortUpcoming}
                                ifNoRecords={
                                    <EmptyTableMessage
                                        title="No upcoming transactions"
                                        description={
                                            <>
                                                Need help?{` `}
                                                <Anchor
                                                    href={`mailto:${
                                                        import.meta.env
                                                            .VITE_EMAIL_HELP
                                                    }`}
                                                >
                                                    Contact us
                                                </Anchor>
                                                .
                                            </>
                                        }
                                    />
                                }
                            ></TransactionTable>
                        </S.TableSection>
                    </>
                ) : (
                    <ErrorMessage msg="No upcoming transactions">
                        <p>
                            Need help?{` `}
                            <Anchor
                                href={`mailto:${
                                    import.meta.env.VITE_EMAIL_HELP
                                }`}
                            >
                                Contact us
                            </Anchor>
                            .
                        </p>
                    </ErrorMessage>
                )}
            </Section>
        </>
    );
};

export default Upcoming;
