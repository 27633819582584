import { FunctionComponent, HTMLAttributes, ReactNode } from "react";
import * as S from "./styles";

export enum Density {
    COMPACT = `COMPACT`,
    DEFAULT = `DEFAULT`,
}

export type BadgeColors =
    | "blue"
    | "green"
    | "yellow"
    | "orange"
    | "red"
    | "gray"
    | "purple";

export interface BadgeProps extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode;
    variant?: BadgeColors;
    uppercase?: boolean;
    border?: boolean;
    density?: Density;
}

// Note: useful to apply styles to Badge children like label and inputs
export const Badge: FunctionComponent<BadgeProps> = ({
    children,
    variant = "blue",
    uppercase,
    border,
    density = Density.DEFAULT,
    ...props
}) => {
    return (
        <S.Badge
            variant={variant}
            uppercase={uppercase}
            border={border}
            density={density}
            {...props}
        >
            {children}
        </S.Badge>
    );
};

export default Badge;
