import {
    forwardRef,
    useImperativeHandle,
    useState,
    Ref,
    useRef,
    useEffect,
    useCallback,
} from "react";
import Label from "components/Label";
import Input from "components/Input";
import { validateUrl } from "utils/formValidation";
import { SelfServeNewEntityConfig } from "api";
import * as S from "./styles";

export interface EntityUrlFieldProps {
    disabled?: boolean;
    label?: React.ReactNode;
    defaultUrl?: string;
    onChange?: (defaultUrl: string) => void;
}

export type EntityUrlFieldRef = {
    value: string;
    validate: () => boolean;
};

function EntityUrlField(
    {
        disabled,
        label = "Website",
        defaultUrl = "",
        onChange,
    }: EntityUrlFieldProps,
    ref: Ref<EntityUrlFieldRef>
) {
    const [url, setUrl] = useState<SelfServeNewEntityConfig["url"]>(defaultUrl);
    const urlRef = useRef<HTMLInputElement>(null);
    const validationMessage =
        "Must be a valid url beginning with 'http://' or 'https://'";

    const [isValid, setIsValid] = useState<boolean>(true);

    const validate = useCallback(() => {
        if (!urlRef.current) {
            return false;
        }
        const isValid = validateUrl({
            input: urlRef.current,
            errorMessage: validationMessage,
            reportValidity: false,
        });
        setIsValid(isValid);
        return isValid;
    }, [urlRef]);

    useImperativeHandle(ref, () => ({ value: url, validate }), [url, validate]);

    useEffect(() => {
        if (onChange) onChange(url);
    }, [onChange, url]);

    return (
        <S.EntityUrlField disabled={disabled}>
            <Label htmlFor="url">{label}</Label>
            <Input
                innerRef={urlRef}
                type="url"
                name="url"
                placeholder="https://..."
                value={url}
                disabled={disabled}
                onChange={(event) => setUrl(event.target.value)}
            />
            {!isValid && <S.ErrorMsg>{validationMessage}</S.ErrorMsg>}
        </S.EntityUrlField>
    );
}
export default forwardRef(EntityUrlField);
