import { toDollar } from "utils/financial";
import { useCheckoutData } from "checkout/context/CheckoutData";
import { useNetworkAndToken } from "checkout/context/NetworkAndToken";
import CheckoutTokenPriceForDisplay from "./CheckoutTokenPriceForDisplay";

const TotalPriceForDisplay = () => {
    const {
        usdTotalDueToday,
        isAnyPriceVarious,
        totalPricePerCommonToken,
        getTotalPriceByAddressAndNetwork,
    } = useCheckoutData();

    const { selectedNetwork, selectedToken } = useNetworkAndToken();

    const usdPrice = isAnyPriceVarious ? (
        <>Price varies</>
    ) : usdTotalDueToday !== undefined ? (
        <>{toDollar(usdTotalDueToday)}</>
    ) : (
        false
    );

    // We have a price that match the user's network/token selection
    const checkoutTokenPriceMatchWallet =
        selectedNetwork &&
        selectedToken &&
        getTotalPriceByAddressAndNetwork(
            selectedToken.address,
            selectedNetwork.id
        );

    const checkoutTokenPriceUsdc = totalPricePerCommonToken.find(
        (token) => token.symbol === "USDC"
    );

    const checkoutTokenPriceFirst = totalPricePerCommonToken[0];

    // [ ] This logic is duplicated in ItemList.tsx
    // Price display logic
    // 1. Show the token price matching user selected network/token if any, or
    // 2. Show USD price if available, or
    // 3. Show the USDC token price if available, or
    // 4. Show the first token price if available, or
    // 5. Show "No price" (=> internal issue with item's configuration, has no usd or token price)
    return checkoutTokenPriceMatchWallet ? (
        <CheckoutTokenPriceForDisplay
            checkoutTokenPrice={checkoutTokenPriceMatchWallet}
            priceType="totalDueToday"
        />
    ) : usdPrice ? (
        usdPrice
    ) : checkoutTokenPriceUsdc ? (
        <CheckoutTokenPriceForDisplay
            checkoutTokenPrice={checkoutTokenPriceUsdc}
            priceType="totalDueToday"
        />
    ) : checkoutTokenPriceFirst ? (
        <CheckoutTokenPriceForDisplay
            checkoutTokenPrice={checkoutTokenPriceFirst}
            priceType="totalDueToday"
        />
    ) : (
        <small>No Price</small>
    );
};

export default TotalPriceForDisplay;
