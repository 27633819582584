import { forwardRef, useImperativeHandle, useState, Ref, useRef } from "react";
import Input from "components/Input";

export interface TransferNotesFieldProps {
    disabled: boolean;
    defaultNotes?: string;
}

export type TransferNotesFieldRef = {
    notes: string;
    hasChanged: boolean;
    validate: () => boolean;
};

function TransferNotesField(
    { disabled, defaultNotes = "" }: TransferNotesFieldProps,
    ref: Ref<TransferNotesFieldRef>
) {
    const [notes, setNotes] = useState<string>(defaultNotes);

    const notesRef = useRef<HTMLInputElement>(null);

    // maybe length?
    const validate = () => {
        return true;
    };

    const hasChanged = notes !== defaultNotes;

    useImperativeHandle(ref, () => ({
        notes,
        validate,
        hasChanged,
    }));

    return (
        <Input
            name="notes"
            value={notes}
            innerRef={notesRef}
            disabled={disabled}
            placeholder="Additional notes"
            onChange={(event) => setNotes(event.target.value)}
        />
    );
}
export default forwardRef(TransferNotesField);
