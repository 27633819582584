import styled from "styled-components";
import {
    colorsDeprecated as c,
    properties as p,
    sizes as s,
} from "global-style";
import { NavLink } from "react-router-dom";
import colors from "theme/colors";

export const Submenu = styled.ul`
    width: 100%;
    margin: 0;
    @media screen and (min-width: ${s.md}) {
        display: flex;
        width: auto;
    }
`;

export const Item = styled(NavLink)`
    display: block;
    padding: 0.33em 0.5em;
    border: 0 solid ${c.lightestOnSurface};
    border-width: 0 0 0 0.25rem;
    font-weight: 700;
    font-family: ${p.font.heading};
    text-decoration: none;
    color: inherit;
    &.active,
    &:hover,
    &:focus {
        border-color: ${colors.primary};
    }

    @media screen and (min-width: ${s.md}) {
        border-width: 0 0 0.25rem 0;
    }
`;
