import * as S from "./styles";
import React from "react";
import { firstToUpper } from "utils/strings";
import DynamicWalletAddressDisplay from "components/DynamicWalletAddress/DynamicWalletAddressDisplay";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useEns } from "contexts/EnsProvider";

interface InboundTreasuryTableCellProps {
    walletAddress: string;
    networkId: number;
}

const InboundTreasuryTableCell: React.FunctionComponent<InboundTreasuryTableCellProps> =
    ({ walletAddress, networkId }) => {
        const { getEnsRecord } = useEns();
        const {
            config: { contracts },
        } = useGetCompanyConfig();
        const { networks } = useGetNetworks();

        const treasuryNetwork = networks.find(
            (network) => network.id === networkId
        );

        const showNetwork = treasuryNetwork && contracts.length > 1;

        return (
            <li>
                <DynamicWalletAddressDisplay
                    address={walletAddress}
                    ensName={getEnsRecord(walletAddress)?.name}
                    networkId={treasuryNetwork?.hexId}
                />
                {showNetwork && treasuryNetwork?.name && (
                    <S.NetworkContainer>
                        {" "}
                        ({firstToUpper(treasuryNetwork.name)})
                    </S.NetworkContainer>
                )}
            </li>
        );
    };

export default InboundTreasuryTableCell;
