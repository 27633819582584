import Title from "components/Title";
import * as S from "./style";
import { ReactNode } from "react";

interface SectionProps {
    children: ReactNode;
    narrow?: boolean;
    title?: ReactNode;
    className?: string;
}

const Section = ({
    children,
    narrow = false,
    title,
    className,
}: SectionProps) => {
    return (
        <S.Section className={className} $narrow={narrow}>
            {title && (
                <S.TitleWrapper>
                    <Title level="h2" bold={false}>
                        {title}
                    </Title>
                </S.TitleWrapper>
            )}
            {children}
        </S.Section>
    );
};

export default Section;
