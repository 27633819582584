import {
    ReactNode,
    createContext,
    useContext,
    useState,
    useMemo,
    useEffect,
} from "react";
import { apiServerUrl } from "default-variables";
import { addTimePeriodToDate } from "utils/datetime";
import { Customer, TransactionTableRow } from "customer/types";
import { useSession } from "context/Session";
import { DataLoaderStatus, useFetch } from "hooks/useFetch";
import { useAvailableNetworks } from "hooks/useAvailableNetworks";
import {
    AuthorizationsByEntity,
    EntityAuthorizations,
    useAuthorizations,
} from "customer/hooks/useAuthorizations";
import { useFormatTransactions } from "customer/hooks/useFormatTransactions";
import { useCancelAuthorizations } from "customer/hooks/useCancelAuthorizations";
import { useWalletConnected } from "context/Wallet/WalletConnectedContext";

interface CustomerDataProps {
    children: ReactNode;
}

interface CustomerDataValue extends DataLoaderStatus {
    refetch: (showLoader?: boolean) => void;
    lastFetched: number;
    transactions: {
        upcoming: TransactionTableRow[];
        confirmed: TransactionTableRow[];
        due: TransactionTableRow[];
    };
    items: Customer.Item[];
    entities: Customer.Entity[];
    agreements: Customer.Agreement[];
    tokens: Customer.Token[];
    contracts: Customer.Contract[];
    entityAuthorizations: EntityAuthorizations[];
    getAuthorizationRecordsByEntity: AuthorizationsByEntity;
    cancelAuthorization: (id: string, name: string, entity: string) => void;
}

const CustomerDataContext = createContext<CustomerDataValue>(
    {} as CustomerDataValue
);

const startOfThisYear = new Date(new Date().getFullYear(), 0).valueOf() / 1000;
const minDataWindow =
    addTimePeriodToDate(-12 + 1, true, Date.now() / 1000, `months`) / 1000;

const CustomerDataProvider = ({ children, ...props }: CustomerDataProps) => {
    const { getSessionToken } = useSession();
    const { getNetworkById } = useAvailableNetworks();
    const { walletConnected, networkConnected } = useWalletConnected();
    const [address, setAddress] = useState(
        walletConnected.proxyFor || walletConnected.address
    );

    // ******************** FETCH AND STORE DATA
    const [tokens, setTokens] = useState<Customer.Token[]>([]);
    const [entities, setEntities] = useState<Customer.Entity[]>([]);
    const [items, setItems] = useState<Customer.Item[]>([]);
    const [agreements, setAgreements] = useState<Customer.Agreement[]>([]);
    const [networks, setNetworks] = useState<Customer.Network[]>([]);
    const [contracts, setContracts] = useState<Customer.Contract[]>([]);
    const [upcomingPayments, setUpcomingPayments] = useState<
        Customer.Transaction[]
    >([]);
    const [paymentHistory, setPaymentHistory] = useState<
        Customer.Transaction[]
    >([]);

    const {
        data,
        error,
        loading,
        refetch,
        lastFetched,
        reset,
        isError,
        isSuccess,
    } = useFetch(
        `${apiServerUrl}/api/v1/customer/transactions/${address}?networkId=${parseInt(
            networkConnected?.networkId || `0x0`,
            16
        )}&dateFrom=${
            startOfThisYear < minDataWindow ? startOfThisYear : minDataWindow
        }`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: getSessionToken(),
                address: walletConnected.address,
            },
        },
        false
    );

    const refetchData = async (showLoader = true) => {
        await refetch(showLoader);
    };

    // ******************** USER HAS CHANGED WALLET OR CHAIN
    useEffect(() => {
        // abandon outstanding request and reset data
        if (!networkConnected?.networkId) {
            reset();
            return;
        }

        try {
            // Ensures the selected network is available, before refetching
            getNetworkById(networkConnected?.networkId);
            refetch(true);
        } catch (e) {
            reset();
        }
    }, [networkConnected?.networkId, address]);

    useEffect(() => {
        setAddress(walletConnected.proxyFor || walletConnected.address);
    }, [walletConnected.proxyFor, walletConnected.address]);

    // ******************** DATA IS UPDATED FROM ENDPOINT
    useMemo(() => {
        if (!data) {
            setNetworks([]);
            setEntities([]);
            setTokens([]);
            setItems([]);
            setAgreements([]);
            setContracts([]);
            setUpcomingPayments([]);
            setPaymentHistory([]);
            return;
        }

        setNetworks(data?.networks || []);
        setEntities(data?.entities || []);
        setTokens(data?.tokens || []);
        setItems(data?.items || []);
        setAgreements(data?.agreements || []);
        setContracts(data?.contracts || []);
        setUpcomingPayments(data?.transactions?.upcomingPayments || []);
        setPaymentHistory(data?.transactions?.paymentHistory || []);
    }, [data]);

    // ******************** TRANSACTION FORMATTING
    const { upcoming, confirmed, due } = useFormatTransactions(
        paymentHistory,
        upcomingPayments,
        tokens,
        entities,
        items,
        agreements,
        networks
    );

    // ******************** CANCEL AUTHENTICATION
    const { cancelAuthorization } = useCancelAuthorizations(setAgreements);

    // ******************** BUILD AUTHORIZATIONS
    const { entityAuthorizations, getAuthorizationRecordsByEntity } =
        useAuthorizations(entities, agreements, items, tokens);

    // ******************** RETURN
    return (
        <CustomerDataContext.Provider
            value={{
                error,
                loading,
                refetch: refetchData,
                lastFetched,
                transactions: { upcoming, confirmed, due },
                items,
                entities,
                agreements,
                tokens,
                contracts,
                entityAuthorizations,
                isError,
                isSuccess,
                getAuthorizationRecordsByEntity,
                cancelAuthorization,
            }}
            {...props}
        >
            {children}
        </CustomerDataContext.Provider>
    );
};

const useCustomerData = (): CustomerDataValue => {
    const context = useContext<CustomerDataValue>(CustomerDataContext);
    if (context === undefined) {
        throw new Error(
            `useCustomerData() must be used within a CustomerDataProvider`
        );
    }
    return context;
};

export { CustomerDataProvider, useCustomerData };
