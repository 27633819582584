import * as S from "./style";
import {
    ChangeEvent,
    Ref,
    forwardRef,
    useCallback,
    useImperativeHandle,
    useRef,
} from "react";
import { formatDateTimeLocalForInput } from "utils/datetime";
import { getLocalStartOfDayFromString } from "utils/dates";
import { validateInput } from "utils/formValidation";
import Field from "components/Field";
import Label from "components/Label";
import Input from "components/Input";

interface PaymentDateProps {
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    disabled: boolean;
}

const PaymentDate = forwardRef(
    (
        { value, onChange, disabled }: PaymentDateProps,
        ref: Ref<PayDateFieldRef>
    ) => {
        const payDateRef = useRef<HTMLInputElement>(null);

        const validate = useCallback(() => {
            if (!payDateRef.current || !payDateRef.current?.value) return false;

            let selectedLocalMidnight, todayLocalMidnight;
            try {
                // Ensure that the date set is greater-than or equal-to today.
                selectedLocalMidnight = getLocalStartOfDayFromString(
                    payDateRef.current?.value
                );
                todayLocalMidnight = getLocalStartOfDayFromString(
                    formatDateTimeLocalForInput(new Date(), {
                        dateOnly: true,
                    })
                );
            } catch (error) {
                return validateInput({
                    valid: false,
                    input: payDateRef.current,
                    reportValidity: true,
                    errorMessage: `Value entered is an invalid date`,
                });
            }

            return validateInput({
                valid: selectedLocalMidnight >= todayLocalMidnight,
                input: payDateRef.current,
                reportValidity: true,
                errorMessage: `Date must be today, or in the future`,
            });
        }, []);

        useImperativeHandle(
            ref,
            () => ({
                value: () =>
                    payDateRef.current?.value
                        ? getLocalStartOfDayFromString(
                              payDateRef.current.value,
                              { unix: true }
                          )
                        : undefined,
                validate,
            }),
            [validate]
        );

        const handleDateChange = useCallback(
            (event: ChangeEvent<HTMLInputElement>) => {
                if (onChange) onChange(event);
            },
            [onChange]
        );

        return (
            <Field>
                <Label htmlFor="payDate">Payment date</Label>
                <S.DatePanel>
                    <Input
                        type="date"
                        name="payDate"
                        innerRef={payDateRef}
                        value={value}
                        onChange={handleDateChange}
                        onBlur={(event) => validate()}
                        disabled={disabled}
                        min={formatDateTimeLocalForInput(new Date(), {
                            dateOnly: true,
                        })}
                    />
                    <S.SubMsg>Loop lets you schedule a payment</S.SubMsg>
                </S.DatePanel>
            </Field>
        );
    }
);

export default PaymentDate;
