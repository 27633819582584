import {
    CreateItemRequest,
    EntityInboundTreasuriesByNetworkId,
} from "company/types";
import { ComponentProps, memo, useEffect, useState } from "react";
import {
    PostSelfServeCreateAccountResponse,
    SelfServeNewEntityConfig,
    SelfServeNewUser,
} from "api";
import { useUser, UserRole, tierStrToEnum } from "context/User";
import SelfServeDeployingContracts from "company/components/entities/SelfServeDeployingContracts";
import ItemCheckoutUrlField from "company/components/items/ItemCheckoutUrlField";
import Section from "components/Section";
import SubSection from "components/SubSection";
import * as S from "./style";
import Anchor from "components/Anchor";
import Paragraph from "components/Paragraph";
import { Spacing } from "theme/spacing";
import { DetailsManagerProvider } from "context/DetailsManager";
import FormPanels from "./FormPanels";
import { clarity } from "react-microsoft-clarity";

if (!clarity.hasStarted()) clarity.init("nk4e5a8fli");

export enum SelfServePanel {
    ITEM = "Add your first subscription or one-time item",
    WALLETS = "Set your receiving wallets",
    COMPANY = "Enter your company's details",
    CREDENTIALS = "Create a username and password",
}

export type PanelSummaries = Record<
    SelfServePanel,
    { idle: any; complete: any }
>;
export const panelSummaries: PanelSummaries = {
    [SelfServePanel.ITEM]: {
        idle: memo(() => (
            <S.Summary>
                <S.Idle plainText={true}>1.</S.Idle>
                <span>{SelfServePanel.ITEM}</span>
            </S.Summary>
        )),
        complete: memo(() => (
            <S.Summary>
                <S.Complete>
                    <S.Checkmark />
                </S.Complete>
                <span>{SelfServePanel.ITEM}</span>
            </S.Summary>
        )),
    },
    [SelfServePanel.WALLETS]: {
        idle: memo(() => (
            <S.Summary>
                <S.Idle plainText={true}>2.</S.Idle>
                <span>{SelfServePanel.WALLETS}</span>
            </S.Summary>
        )),
        complete: memo(() => (
            <S.Summary>
                <S.Complete>
                    <S.Checkmark />
                </S.Complete>
                <span>{SelfServePanel.WALLETS}</span>
            </S.Summary>
        )),
    },
    [SelfServePanel.COMPANY]: {
        idle: memo(() => (
            <S.Summary>
                <S.Idle plainText={true}>3.</S.Idle>
                <span>{SelfServePanel.COMPANY}</span>
            </S.Summary>
        )),
        complete: memo(() => (
            <S.Summary>
                <S.Complete>
                    <S.Checkmark />
                </S.Complete>
                <span>{SelfServePanel.COMPANY}</span>
            </S.Summary>
        )),
    },
    [SelfServePanel.CREDENTIALS]: {
        idle: memo(() => (
            <S.Summary>
                <S.Idle plainText={true}>4.</S.Idle>
                <span>{SelfServePanel.CREDENTIALS}</span>
            </S.Summary>
        )),
        complete: memo(() => (
            <S.Summary>
                <S.Complete>
                    <S.Checkmark />
                </S.Complete>
                <span>{SelfServePanel.CREDENTIALS}</span>
            </S.Summary>
        )),
    },
};

export type SelfServerEntityDetailsOnly = Omit<
    SelfServeNewEntityConfig,
    "inboundTreasuries"
>;

const SelfServe = () => {
    const { newUserSession } = useUser();

    // Form state - success
    const [contractsDeployed, setContractsDeployed] = useState<boolean>(false);

    // Form state - Account created response
    const [
        postSelfServeCreateAccountResponse,
        setPostSelfServeCreateAccountResponse,
    ] = useState<PostSelfServeCreateAccountResponse>();

    // Entity

    const [selfServeNewEntityConfig, setSelfServeNewEntityConfig] =
        useState<SelfServerEntityDetailsOnly>();
    const [selfServeNewEntityConfigValid, setSelfServeNewEntityConfigValid] =
        useState<boolean>(false);

    // User
    const [selfServeNewUser, setSelfServeNewUser] =
        useState<SelfServeNewUser>();
    const [selfServeNewUserValid, setSelfServeNewUserValid] =
        useState<boolean>(false);

    // Items
    const [createItemRequests, setCreateItemRequests] = useState<
        CreateItemRequest[]
    >([]);

    const [selfServeInboundTreasuries, setSelfServeInboundTreasuries] =
        useState<EntityInboundTreasuriesByNetworkId>({});

    // These items "require" inbound treasury for these networks
    const itemsNetworkIds = createItemRequests
        .map((item) =>
            item.acceptedTokens
                ? Object.keys(item.acceptedTokens).map((n) => Number(n))
                : []
        )
        .flat();

    // Callback - Contracts are done deploying
    const onAllContractsDeployed = function () {
        if (!postSelfServeCreateAccountResponse) return;
        if (!selfServeNewUser) return;
        const { auth, entity } = postSelfServeCreateAccountResponse;
        newUserSession({
            token: auth.token,
            roles: auth.roles as UserRole[],
            entityId: entity.entityId,
            tier: tierStrToEnum(`guest`),
            companyName: entity.name,
            email: selfServeNewUser.email,
            delegatedSigning: entity.delegatedSigning || false,
        });
        setContractsDeployed(true);
    };

    useEffect(() => {
        if (clarity.hasStarted()) clarity.setTag("self serve started", "true");
    }, []);

    // Success
    if (contractsDeployed && postSelfServeCreateAccountResponse) {
        // Only pick the first item (the second is outbound payment)
        const selectedItems = [postSelfServeCreateAccountResponse.items[0]];
        return (
            <Section narrow={true} title="You're done! 🥳">
                <SubSection
                    title="Use this link to get paid today!"
                    description="The item's base link will be available on your
                            company dashboard. It does not expire and can be
                            used more than once."
                >
                    <ItemCheckoutUrlField label={false} items={selectedItems} />
                </SubSection>
            </Section>
        );
    }

    // Contract deploying
    if (postSelfServeCreateAccountResponse && selfServeNewUser) {
        return (
            <Section narrow={true}>
                <SelfServeDeployingContracts
                    pendingTransactionsDetails={
                        postSelfServeCreateAccountResponse.pendingTransactionsDetails
                    }
                    onAllContractsDeployed={onAllContractsDeployed}
                />
            </Section>
        );
    }

    const formPanelProps: ComponentProps<typeof FormPanels> = {
        itemsNetworkIds,
        createItemRequests,
        setCreateItemRequests,
        selfServeInboundTreasuries,
        setSelfServeInboundTreasuries,
        selfServeNewEntityConfig,
        setSelfServeNewEntityConfig,
        selfServeNewEntityConfigValid,
        setSelfServeNewEntityConfigValid,
        selfServeNewUser,
        setSelfServeNewUser,
        selfServeNewUserValid,
        setSelfServeNewUserValid,
        setPostSelfServeCreateAccountResponse,
    };

    // Form
    return (
        <S.SelfServe>
            <S.Terms>
                <Paragraph spacing={Spacing.xs}>
                    Please refer to our{" "}
                    <Anchor
                        href={import.meta.env.VITE_LOOP_DOCS_GETTING_STARTED}
                        underlined={false}
                    >
                        getting started guide
                    </Anchor>{" "}
                    for how to create a checkout link.
                </Paragraph>
                <Paragraph spacing={Spacing.xs}>
                    If you need additional support,{" "}
                    <Anchor
                        href={import.meta.env.VITE_LOOP_TELEGRAM}
                        underlined={false}
                    >
                        message us on Telegram
                    </Anchor>{" "}
                    or email us at{" "}
                    <Anchor
                        href={`mailto:${import.meta.env.VITE_EMAIL_SUPPORT}`}
                        underlined={false}
                    >
                        {import.meta.env.VITE_EMAIL_SUPPORT}
                    </Anchor>
                    .
                </Paragraph>
                <Paragraph spacing={Spacing.xs}>
                    By using this page to create a checkout link, you agree to
                    Loop Crypto's{" "}
                    <Anchor
                        href={import.meta.env.VITE_TERMS_AND_CONDITIONS}
                        underlined={false}
                    >
                        Terms and Conditions
                    </Anchor>
                    .
                </Paragraph>
            </S.Terms>
            <S.Steps title="We just need a few details to get you started">
                <DetailsManagerProvider>
                    <FormPanels {...formPanelProps} />
                </DetailsManagerProvider>
            </S.Steps>
        </S.SelfServe>
    );
};

export default SelfServe;
