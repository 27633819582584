import Table from "components/Table";
import Input from "components/Input";
import styled from "styled-components";

export const TokenAllowanceTable = styled(Table)`
    margin: 0;
    & tr > :is(td, th):last-child {
        text-align: right;
        & > * {
            justify-content: end;
        }
    }
`;

export const SmallInput = styled(Input)`
    max-width: 120px;
    text-align: right;
    padding: 0.25rem 0.5rem;
`;
