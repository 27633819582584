import { forwardRef, useImperativeHandle, useState, Ref } from "react";
import Label from "components/Label";
import Field from "components/Field";
import Radio from "components/Radio";
import {
    ItemSourceType,
    itemSourceForCheckout,
    itemSourceForDisplay,
} from "company/types";
import { onlyUnique } from "utils/arrays";
import * as S from "./style";
import ItemSourceLogo from "company/components/items/ItemSourceLogo";
import { useGetCompanyItems } from "company/hooks/useGetCompanyItems";
import { Spacing } from "theme/spacing";

export interface ItemSourceIdFieldProps {
    disabled?: boolean;
    onChange?: (sourceId: ItemSourceType) => void;
    defaultSource?: ItemSourceType;
    label?: React.ReactNode | false;
}

export type ItemSourceIdFieldRef = {
    itemSourceId: number;
};

const ItemSourceIdField = (
    {
        disabled = false,
        onChange,
        defaultSource = ItemSourceType.Loop,
        label = "Source",
    }: ItemSourceIdFieldProps,
    ref: Ref<ItemSourceIdFieldRef>
) => {
    const { items, getCompanyItemsIsLoading } = useGetCompanyItems();

    // The source of items
    const [itemSourceId, setItemSourceId] =
        useState<ItemSourceType>(defaultSource);

    useImperativeHandle(ref, () => ({
        itemSourceId,
    }));

    const itemSourceInputName = "itemSource";

    type itemSourcesRadio = {
        value: ItemSourceType;
        label: string;
        enabled: boolean;
    };

    const itemsUniqueSourceIds = items
        .map((item) => item.sourceId)
        .filter(onlyUnique);

    const itemSourcesRadios: itemSourcesRadio[] = itemSourceForCheckout
        .map((itemSource) => {
            return {
                value: itemSource,
                label: itemSourceForDisplay[itemSource],
                enabled:
                    itemSource === ItemSourceType.Loop ||
                    itemsUniqueSourceIds.includes(itemSource),
            };
        })
        .sort((a, b) => {
            if (a.enabled === b.enabled) {
                return 0;
            }
            return a.enabled ? -1 : 1;
        });

    const handleSourceChange = (value: ItemSourceType) => {
        setItemSourceId(value);
        if (onChange) onChange(value);
    };

    return (
        <Field disabled={disabled} spacing={[Spacing.lg, Spacing.none]}>
            {label && <Label htmlFor={itemSourceInputName}>{label}</Label>}
            <S.SourceLabelsContainer>
                {itemSourcesRadios.map((itemSourceRadio) => (
                    <S.SourceLabel
                        key={itemSourceRadio.value}
                        htmlFor={itemSourceForDisplay[itemSourceRadio.value]}
                        disabled={!itemSourceRadio.enabled}
                    >
                        <Radio
                            name={itemSourceForDisplay[itemSourceRadio.value]}
                            value={itemSourceRadio.value}
                            checked={itemSourceRadio.value === itemSourceId}
                            disabled={
                                getCompanyItemsIsLoading ||
                                disabled ||
                                !itemSourceRadio.enabled
                            }
                            onChange={() =>
                                handleSourceChange(itemSourceRadio.value)
                            }
                        />
                        <ItemSourceLogo
                            itemSourceId={itemSourceRadio.value}
                            height="1.25rem"
                        />
                    </S.SourceLabel>
                ))}
            </S.SourceLabelsContainer>
        </Field>
    );
};
export default forwardRef(ItemSourceIdField);
