import { firstToUpper } from "utils/strings";
import moment from "moment-timezone";

export const SECONDS_IN_MINUTE = 60;
export const SECONDS_IN_HOUR = 3600;
export const SECONDS_IN_DAY = 86400;
export const SECONDS_IN_WEEK = 604800;
export const SECONDS_IN_YEAR = 31449600;

export const formatDateTimeLocalForInput = (
    dateObject,
    { dateOnly = false } = {}
) => {
    const minutes = dateObject.getMinutes();
    const minuteForDisplay = minutes < 10 ? `0${minutes}` : minutes;
    const month = dateObject.getMonth() + 1;
    const monthForDisplay = month < 10 ? `0${month}` : month;
    const hours = dateObject.getHours();
    const hoursForDisplay = hours < 10 ? `0${hours}` : hours;
    const date = dateObject.getDate();
    const dateForDisplay = date < 10 ? `0${date}` : date;
    return dateOnly
        ? `${dateObject.getFullYear()}-${monthForDisplay}-${dateForDisplay}`
        : `${dateObject.getFullYear()}-${monthForDisplay}-${dateForDisplay}T${hoursForDisplay}:${minuteForDisplay}:00`;
};

export const getDateTimeFromSeconds = (
    seconds = getNowDateInSeconds(),
    withAmPm = false
) => {
    const date = new Date(seconds * 1000);
    const timezone = moment.tz.guess();
    const timezoneForDisplay = moment.tz(date, timezone).format("z");

    return {
        date: date.toLocaleDateString("en-US"),
        time: date.toLocaleTimeString("en-US", {
            hour12: withAmPm,
            hour: "2-digit",
            minute: "2-digit",
        }),
        zone: timezoneForDisplay,
    };
};

// Most common formatting across applications
//   For custom use cases, try `getDateTimeFromSeconds`
export const formatDateTimeFromSeconds = (seconds, withAmPm = true) => {
    const { date, time, zone } = getDateTimeFromSeconds(seconds, withAmPm);
    return `${date} ${time} ${zone}`;
};

export const formateDateForUrl = (ms = Date.now()) => {
    const date = new Date(ms);
    return `${String(date.getDate()).padStart(2, `0`)}${date
        .toLocaleDateString("en-us", {
            year: "2-digit",
            month: "short",
        })
        .toLowerCase()
        .replaceAll(` `, ``)}`;
};

// TODO rename to `addDaystoSeconds`
export const addDays = (seconds, days) => {
    return seconds + SECONDS_IN_DAY * days;
};

export const strDurationFromSeconds = (seconds, prefix = `every `) => {
    if (seconds < 0) {
        return `-${strDurationFromSeconds(Math.abs(seconds), ``)}`;
    }

    const mins = Math.round(seconds / SECONDS_IN_MINUTE);
    const hours = Math.round(seconds / SECONDS_IN_HOUR);
    const days = Math.round(seconds / SECONDS_IN_DAY);
    if (mins < 60) {
        return mins <= 1
            ? `${prefix ? prefix : `1 `}minute`
            : `${prefix}${mins} minutes`;
    } else if (hours < 24) {
        return hours === 1
            ? `${prefix ? prefix : `1 `}hour`
            : `${prefix}${hours} hours`;
    } else {
        return days === 1
            ? `${prefix ? prefix : `1`}day`
            : `${prefix}${days} days`;
    }
};

export const adverbFrequency = (unit) => {
    if (unit === "day") {
        return "Daily";
    } else {
        return firstToUpper(`${unit}ly`);
    }
};

// TODO: This should be on utils/items
export const strFrequency = (amount, unit) => {
    // 0 is for one time payments
    if (amount === 0) {
        return "One-time payment";
    } else if (!unit) {
        return "";
    } else if (amount === 1) {
        return adverbFrequency(unit);
    }
    return `Every ${amount} ${unit}s`;
};

export const getNowDateInSeconds = () => {
    return Math.floor(Date.now() / 1000);
};

const Frequency = {
    DAYS: `days`,
    MONTHS: `months`,
};

export const addTimePeriodToDate = (
    // These props should realistically be moved to an "options" object to simplify usage at some point down the road
    addend,
    fromStartOfType = false,
    from = Date.now() / 1000,
    type = Frequency.DAYS,
    shiftTz = false
) => {
    // Convert to ms from s
    from = new Date(from * 1000);

    // Start counting from the start of a day (midnight)
    if (fromStartOfType) {
        from = new Date(
            from.getFullYear(),
            from.getMonth(),
            type === Frequency.MONTHS ? 1 : from.getDate(),
            // Shift from UTC to the user's local timezone
            shiftTz ? from.getTimezoneOffset() / SECONDS_IN_MINUTE : 0
        );
    }

    return type === Frequency.MONTHS
        ? new Date(from.setMonth(from.getMonth() + addend)).valueOf()
        : new Date(from.setDate(from.getDate() + addend)).valueOf();
};

const FrequencyUnitToSecondsMap = [
    SECONDS_IN_MINUTE,
    SECONDS_IN_HOUR,
    SECONDS_IN_DAY,
];

export const FrequencyUnit = {
    Minute: 0,
    Hour: 1,
    Day: 2,
};

export const frequencyToSeconds = (count, frequencyUnit) => {
    return count * FrequencyUnitToSecondsMap[frequencyUnit];
};
