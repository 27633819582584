import { useNotificationQueue } from "context/NotificationQueue";
import { useWallet } from "context/Wallet";
import { JsonRpcProvider, Contract, formatEther } from "ethers";
import { useTokens } from "hooks/useTokens";
import { exchangeRateExpiry as rateExpiry } from "recurring/default-variables";
import { NotificationType } from "components/Notification";

export const useExchangeRate = () => {
    const { getTokenOnNetwork } = useTokens();
    const { addNotification } = useNotificationQueue();
    const { networkConnected } = useWallet();

    const findUsdRate = (priceTable, onNetwork, searchSymbol) => {
        return (
            priceTable.find(
                ({ symbol, networkId }) =>
                    symbol === searchSymbol && networkId === onNetwork
            )?.usdValue || 0
        );
    };

    const getOrUpdateUsdPrice = async (
        priceTable,
        onNetwork,
        searchSymbol,
        forceFetch = false
    ) => {
        // Find the latest price, if one was already retrieved for this symbol
        const token = priceTable.find(
            ({ symbol, networkId }) =>
                symbol === searchSymbol && networkId === onNetwork
        );

        // Skip this fetch if:
        //  - A rate and timestamp are set, AND
        //  - The timestamp hasn't yet expired, AND
        //  - There isn't a force fetch flag set
        if (
            token &&
            token.usdValue &&
            token.lastUpdated &&
            Date.now() - token.lastUpdated < rateExpiry &&
            !forceFetch
        ) {
            return token;
        }

        try {
            // Search for this token's price-lookup address in the full token table
            const tokenFound = getTokenOnNetwork(onNetwork, searchSymbol);

            if (!tokenFound) {
                throw new Error(
                    `There was a problem getting a rate for ${searchSymbol}`
                );
            }

            const { aggregatorAddress } = tokenFound;

            // Update the token price list if the token/network pair already exists in it
            if (token) {
                token.usdValue = await fetchExchange(
                    aggregatorAddress,
                    networkConnected?.rpcUrl
                );
                token.lastUpdated = Date.now();

                return token;
            }

            throw new Error(`There was a problem getting the latest rates.`);
        } catch (e) {
            throw new Error(
                `There was a problem getting a rate for ${searchSymbol}`
            );
        }
    };

    const calculateExchange = async (prices, onNetwork, from, to = false) => {
        if (!from) return 0;

        let rateFrom, rateTo;
        try {
            rateFrom = await getOrUpdateUsdPrice(prices, onNetwork, from);
            if (!rateFrom) return false;
            else if (!to) return rateFrom?.usdValue;
        } catch (error) {
            addNotification({
                msg: `There was a problem getting a rate for ${from}`,
                type: NotificationType.ERROR,
            });
            throw new Error(error);
        }

        try {
            rateTo = await getOrUpdateUsdPrice(prices, onNetwork, to);
            if (!rateTo) return false;
            return rateFrom?.usdValue / rateTo?.usdValue;
        } catch (error) {
            addNotification({
                msg: `There was a problem getting a rate for ${to}`,
                type: NotificationType.ERROR,
            });
            throw new Error(error);
        }
    };

    const fetchExchange = async (addr, rpcUrl) => {
        const provider = new JsonRpcProvider(rpcUrl);
        const priceFeed = new Contract(
            addr,
            aggregatorV3InterfaceABI,
            provider
        );
        const rate = await priceFeed.latestRoundData();

        return formatEther(rate.answer) * 1_000_000_000_000;
    };

    return { findUsdRate, calculateExchange, fetchExchange };
};

const aggregatorV3InterfaceABI = [
    {
        inputs: [],
        name: "decimals",
        outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "description",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [{ internalType: "uint80", name: "_roundId", type: "uint80" }],
        name: "getRoundData",
        outputs: [
            { internalType: "uint80", name: "roundId", type: "uint80" },
            { internalType: "int256", name: "answer", type: "int256" },
            { internalType: "uint256", name: "startedAt", type: "uint256" },
            { internalType: "uint256", name: "updatedAt", type: "uint256" },
            { internalType: "uint80", name: "answeredInRound", type: "uint80" },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "latestRoundData",
        outputs: [
            { internalType: "uint80", name: "roundId", type: "uint80" },
            { internalType: "int256", name: "answer", type: "int256" },
            { internalType: "uint256", name: "startedAt", type: "uint256" },
            { internalType: "uint256", name: "updatedAt", type: "uint256" },
            { internalType: "uint80", name: "answeredInRound", type: "uint80" },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "version",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
    },
];
