import {
    colorsDeprecated as c,
    properties as p,
    sizes as s,
    link,
} from "global-style";
import styled, { css } from "styled-components";
import Logo from "components/Logo";
import Button from "components/Button";
import Badge from "components/Badge";
import EnvironmentTag from "components/EnvironmentTag";
import colors from "theme/colors";
import { Section } from "components/Section/style";
import spacing from "theme/spacing";
import Title from "components/Title";
import User from "components/icons/User";

export const Navigation = styled.nav<{ open: boolean }>`
    position: ${({ open }) => (open ? `fixed` : `sticky`)};
    width: 100%;
    top: 0;
    left: 0;
    z-index: ${p.zIndex.navigation};

    display: grid;
    grid-template-areas:
        "logo env btn"
        "menu menu menu";
    grid-template-columns: 1fr auto auto;
    grid-template-rows: auto 1fr;
    grid-column-gap: 1rem;
    height: ${({ open }) => (open ? `100vh` : `max-content`)};
    background-color: ${colors.neutralBackground};
    align-items: center;
    transition: height 0.2s linear;
    font-family: ${p.font.heading};
    --nav-padding: ${p.space.vGutters.xs};
    --nav-padding-inline: ${p.space.vGutters.xs.split(" ").at(-1)};

    & a:focus {
        outline: none;
        text-decoration: underline;
    }

    @media screen and (min-width: ${s.sm}) {
        --nav-padding: ${p.space.vGutters.sm};
        --nav-padding-inline: ${p.space.vGutters.sm.split(" ").at(-1)};
    }
    @media screen and (min-width: ${s.md}) {
        position: relative;
        z-index: revert;
        display: flex;
        flex-direction: column;
        height: revert;
        --nav-padding: ${p.space.vGutters.md};
        --nav-padding-inline: ${p.space.vGutters.md.split(" ").at(-1)};
        background-attachment: fixed;
    }

    padding: var(--nav-padding);
    --max-nav-content-width: calc(15rem - calc(var(--nav-padding-inline) * 2));
`;

export const LoginLogout = styled(Button)<{ $theme: "light" | "dark" }>`
    color: ${({ $theme }) =>
        $theme === "light" ? colors.primary10 : colors.primary99};

    &:focus,
    &:focus-within {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;

export const EnvBadge = styled(EnvironmentTag)`
    @media screen and (min-width: ${s.md}) {
        margin-block-start: 2rem;
    }
`;

export const RolesBadge = styled(Badge)`
    width: 100%;
    grid-area: env;
    font-weight: 700;
    line-height: 1;
    @media screen and (min-width: ${s.md}) {
        margin-block-start: 1rem;
        line-height: unset;
    }
`;

export const LoopLogoSmVp = styled(Logo)`
    display: flex;
    height: 1.5rem;
    margin: 1rem 0;
    grid-area: logo;
    justify-self: start;
    align-self: center;
    border-bottom: 1px solid transparent;
    :focus {
        border-color: currentColor;
    }
    @media screen and (min-width: ${s.md}) {
        display: none;
    }
`;

export const LoopLogoLgVp = styled(Logo)`
    display: none;
    margin: 1rem 0;
    grid-area: logo;
    border-bottom: 1px solid transparent;
    :focus {
        border-color: currentColor;
    }
    @media screen and (min-width: ${s.md}) {
        display: block;
        margin: 2rem 0 1rem 0;
        width: 100%;
    }
`;

export const NavBtn = styled(Button)`
    display: flex;
    align-items: center;
    grid-area: btn;
    &:hover:not([disabled]) {
        border-color: transparent;
    }
    @media screen and (min-width: ${s.md}) {
        display: none;
    }
`;

const closeMenu = css`
    /* The display prop change is strictly to solve the tab index, but will temp prevent animation open/close */
    display: none;
    overflow: hidden;
    /* height: 0; */
`;

export const Menus = styled.div<{ open: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: start;
    width: 100%;
    height: 100%;
    grid-area: menu;
    overflow: scroll;
    ${({ open }) => (!open ? closeMenu : undefined)};

    @media screen and (min-width: ${s.md}) {
        display: flex;
        max-height: none;
        overflow: revert;
        text-align: revert;
    }
`;

export const BottomMenus = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: relative;
    width: 100%;
`;

export const Sticky = styled.div`
    position: sticky;
    bottom: 0;
`;

export const UserAndLogout = styled.footer`
    margin-block: 2rem 1rem;
    @media screen and (min-width: ${s.md}) {
        margin-block: 2rem;
    }
`;

export const Username = styled.div`
    display: flex;
    align-items: center;
    margin-block-end: 1rem;
    @media screen and (min-width: ${s.sm}) {
        max-width: var(--max-nav-content-width);
    }
`;

export const UserIcon = styled(User)`
    flex-shrink: 0;
`;

export const DeadLink = styled.div`
    ${link}
    max-width: 100%;
`;

export const NameLabel = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Content = styled.main`
    display: flex;
    flex-direction: column;
    background-color: ${colors.white};
    color: ${colors.text};
    padding: ${p.space.vGutters.xs};
    min-height: 100%;
    margin-block-end: 2rem;
    @media screen and (min-width: ${s.sm}) {
        padding: ${p.space.vGutters.sm};
    }
    @media screen and (min-width: ${s.md}) {
        padding: ${p.space.vGutters.md};
    }
    @media screen and (min-width: ${s.lg}) {
        padding: ${p.space.vGutters.lg};
        margin-inline: auto;
        width: 100%;
        max-width: 1500px;
    }
    & > :last-child {
        margin-block-end: 0;
    }

    ${Section} {
        &:not(:last-child) {
            margin-bottom: ${spacing.lg};
        }
    }
`;

export const Greeting = styled(Title)`
    font-size: 2em;
    margin: 1rem 0;
    grid-area: heading;
`;

export const Description = styled.p`
    grid-area: desc;
`;

export const TopRight = styled.div`
    grid-area: wallet;
    display: grid;
    align-items: center;
    justify-self: end;
    justify-content: end;
    column-gap: 1rem;
    @media screen and (min-width: ${s.md}) {
        grid-template-columns: auto auto;
    }
    & > :only-child {
        grid-column: span 2;
    }
`;

export const Email = styled.small`
    display: block;
    text-align: end;
    :only-child {
        margin-block: 1rem;
    }
`;

export const Help = styled(Button)`
    padding-block: 0.75em;
    border-color: ${colors.text};
    grid-area: help;
    margin-block: 1rem;
    justify-self: end;
`;

export const Header = styled.header`
    display: grid;
    grid-template-areas:
        "wallet wallet"
        "heading help"
        "desc desc";
    grid-template-columns: 1fr auto;
    grid-column-gap: 1rem;
    align-content: start;
    border-bottom: 1px solid ${c.border};
    padding-bottom: 1rem;
    margin-bottom: ${spacing.lg};
    @media screen and (min-width: ${s.md}) {
        grid-template-areas:
            "heading wallet help"
            "desc . .";
        grid-template-columns: 1fr auto auto;
        margin: 1rem 0;
        margin-bottom: ${spacing.lg};
        align-items: start;
        &:not(:has(> :first-child)) {
            margin-block-end: 0;
        }
        &:not(:has(> ${Help})) {
            grid-template-areas:
                "heading wallet"
                "desc .";
            grid-template-columns: 1fr auto;
        }
    }
`;

export const Dashboard = styled.div<{ theme: string }>`
    ${({ theme }) => {
        /* RP: LC-657
        This is a temporary solution to theming our application. Similar temporary
        code exists in other files, search "LC-657" to find other instances */
        if (theme === `dark`) {
            return `
            & ${Navigation} {
                background-color: #000;
                & ::selection {
                    color: ${colors.white};
                    background-color:  ${c.loopColA};
                }
                & ${LoopLogoSmVp}, & ${LoopLogoLgVp} {
                    color: ${colors.white};
                }
                & ${Menus} {
                    fill: ${colors.white};
                    color: ${colors.white};
                    a:hover, a.active {
                        color: ${c.loopColA};
                        fill: ${c.loopColA};
                    }
                }
                & ${NavBtn}:focus {
                    color: ${c.loopColA};
                }
            }
            `;
        } else {
            return `
            & ${Navigation} {
                background-image: ${c.bgGradientLight};
                & ${Menus} {
                    fill: #000;
                    color: #000;
                }
                & ${NavBtn}:focus {
                    color: ${c.onPrimaryHover};
                }
            }
            `;
        }
    }}
    min-height: 100vh;
    display: grid;
    grid-template: max-content / minmax(0, 1fr);
    background-color: ${colors.white};
    @media screen and (min-width: ${s.md}) {
        grid-template: none / minmax(15rem, min-content) minmax(0, 1fr);
    }
    @media screen and (min-width: ${s.md}) {
        padding-top: 0;
    }
`;
