import * as S from "./style";
import { forwardRef, Ref } from "react";
import { formatTokenAndUsd, Round } from "utils/financial";
import { useCheckoutForm } from "checkout/context/CheckoutForm";
import { useWallet } from "context/Wallet";
import InfoToolTip from "components/InfoToolTip";

interface MinimumBalanceRequiredDescriptionListRef {}

const MinimumBalanceRequiredDescriptionList = forwardRef(
    (_, ref: Ref<MinimumBalanceRequiredDescriptionListRef>) => {
        const {
            token,
            loadingBalance,
            tokenMinimumBalance,
            hasSufficientBalance,
            isTokenPricedCheckout,
        } = useCheckoutForm();
        const { walletConnected } = useWallet();

        if (!walletConnected || !tokenMinimumBalance) return <></>;

        const items = [];
        if (token) {
            items.push({
                term: (
                    <>
                        Min. {token.symbol} balance to{" "}
                        <span style={{ whiteSpace: `nowrap` }}>
                            proceed{" "}
                            <InfoToolTip title="Only the total amount due shown in the cart will be charged. A minimum balance is required to proceed to account for exchange rate fluctuations and, in some cases, ensure you have enough for future payments." />
                        </span>
                    </>
                ),
                definition: loadingBalance
                    ? `Loading`
                    : formatTokenAndUsd({
                          amount: tokenMinimumBalance,
                          symbol: token.symbol,
                          usdValue: token.exchange.rate,
                          round: Round.CEIL,
                          hideUsd: isTokenPricedCheckout,
                      }),
            });

            // [ ] This component re-renders several times, every time the page is resized!! This needs to be fixed
        }

        return (
            <S.MinimumBalanceRequiredDescriptionList
                items={items}
                hasSufficientBalance={hasSufficientBalance}
            />
        );
    }
);

export default MinimumBalanceRequiredDescriptionList;
