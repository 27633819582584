import { useEffect, useReducer, useRef, useState } from "react";
import * as S from "./style";
import { toCoin } from "utils/financial";
import { toNumber } from "utils/numbers";
import { useSwapForm } from "recurring/context/SwapForm";
import CurrencySelector from "components/CurrencySelector";
import { useNotificationQueue } from "context/NotificationQueue";
import { useExchangeRate } from "hooks/useExchangeRate";
import InfoToolTip from "components/InfoToolTip";
import Loading from "components/Loading";
import { NotificationType } from "components/Notification";
import { useWallet } from "context/Wallet";

// State reducer actions
const stateUpdate = {
    sellCurrency: `SELLCURRENCY`,
    sellAmount: `SELLAMOUNT`,
    buyCurrency: `BUYCURRENCY`,
    buyAmount: `BUYAMOUNT`,
};

const exchangeComponents = {
    buy: `BUY`,
    sell: `SELL`,
};

const ExchangeCurrencies = ({ validity }) => {
    const { prices, tokens, exchange, setExchange } = useSwapForm();
    const { addNotification } = useNotificationQueue();
    const { walletConnected, networkConnected, getTokenBalance } = useWallet();
    const { findUsdRate, calculateExchange } = useExchangeRate();
    const [balance, setBalance] = useState(null);

    const [loading, setLoading] = useState({
        sell: false,
        buy: false,
    });

    /* 
    # BASIS OF EXCHANGE
    The "basis of exchange" is the fixed amount of a currency by which the exchange is calculated as a function of. The basis is set to whichever of the two "amount" <input> values was last manually changed. If a "currency" type is changed, the basis of exchange value will remain fixed, and the opposing number will update to reflect the new exchange calculation. */
    const basisForExchange = useRef(exchangeComponents.sell);
    // const storeState = useRef(structuredClone(exchange));
    const storeState = useRef({ ...exchange });

    const convertCurrency = (_, action) => {
        switch (action.type) {
            case stateUpdate.sellCurrency:
                storeState.current.sell.currency = action.currency;
                break;
            case stateUpdate.sellAmount:
                storeState.current.sell.amount = action.amount;
                break;
            case stateUpdate.buyCurrency:
                storeState.current.buy.currency = action.currency;
                break;
            case stateUpdate.buyAmount:
                storeState.current.buy.amount = action.amount;
                break;
            default:
                addNotification({
                    msg: `Something went wrong while building this swap`,
                    type: NotificationType.ERROR,
                });
                throw new Error(
                    `Something went wrong while building this swap`
                );
        }

        return storeState.current;
    };

    const [buyAndSell, dispatch] = useReducer(
        convertCurrency,
        storeState.current
    );

    // Force a rate search when the tokens change (ie, network changes)
    useEffect(() => {
        setLoading({ buy: true, sell: true });
        if (tokens.buy.length === 0 || tokens.sell.length === 0) return;

        const getNewRates = async () => {
            await calculateExchange(
                prices,
                networkConnected.networkId,
                tokens.sell[0]?.symbol,
                tokens.buy[0]?.symbol
            );
            setLoading({ buy: false, sell: false });

            dispatch({
                type: stateUpdate.sellCurrency,
                amount: 0,
                currency: tokens.sell[0]?.symbol,
            });
        };
        getNewRates();
    }, [tokens]);

    useEffect(() => {
        setLoading({ buy: true, sell: true });

        const getNewRates = async () => {
            const newBuyAmt = String(
                toNumber(buyAndSell.sell.amount) *
                    (await calculateExchange(
                        prices,
                        networkConnected.networkId,
                        buyAndSell.sell.currency,
                        buyAndSell.buy.currency
                    ))
            );
            setLoading({ buy: false, sell: false });

            if (basisForExchange.current === exchangeComponents.buy) return;
            dispatch({
                type: stateUpdate.buyAmount,
                amount: newBuyAmt,
                currency: buyAndSell.buy.currency,
            });
        };
        getNewRates();
    }, [buyAndSell.sell.amount]);

    useEffect(() => {
        setLoading({ buy: true, sell: true });

        const getNewRates = async () => {
            const newSellAmt = String(
                toNumber(buyAndSell.buy.amount) *
                    (await calculateExchange(
                        prices,
                        networkConnected.networkId,
                        buyAndSell.buy.currency,
                        buyAndSell.sell.currency
                    ))
            );
            setLoading({ buy: false, sell: false });

            if (basisForExchange.current === exchangeComponents.sell) return;
            dispatch({
                type: stateUpdate.sellAmount,
                amount: newSellAmt,
                currency: buyAndSell.sell.currency,
            });
        };
        getNewRates();
    }, [buyAndSell.buy.amount]);

    useEffect(() => {
        setLoading({ buy: true, sell: true });

        const getNewRates = async () => {
            if (basisForExchange.current === exchangeComponents.sell) {
                const newBuyAmt = String(
                    toNumber(buyAndSell.sell.amount) *
                        (await calculateExchange(
                            prices,
                            networkConnected.networkId,
                            buyAndSell.sell.currency,
                            buyAndSell.buy.currency
                        ))
                );

                dispatch({
                    type: stateUpdate.buyAmount,
                    amount: newBuyAmt,
                    currency: buyAndSell.buy.currency,
                });
            } else {
                const newSellAmt = String(
                    toNumber(buyAndSell.buy.amount) *
                        (await calculateExchange(
                            prices,
                            networkConnected.networkId,
                            buyAndSell.buy.currency,
                            buyAndSell.sell.currency
                        ))
                );

                dispatch({
                    type: stateUpdate.sellAmount,
                    amount: newSellAmt,
                    currency: buyAndSell.sell.currency,
                });
            }
            setLoading({ buy: false, sell: false });
        };
        getNewRates();

        // PROBABLY NEED TO MOVE THE TOKEN ADDRESS TO THE GET-BALANCE CALL
    }, [buyAndSell.sell.currency]);

    useEffect(() => {
        setLoading({ buy: true, sell: true });

        const getNewRates = async () => {
            if (basisForExchange.current === exchangeComponents.buy) {
                const newSellAmt = String(
                    toNumber(buyAndSell.buy.amount) *
                        (await calculateExchange(
                            prices,
                            networkConnected.networkId,
                            buyAndSell.buy.currency,
                            buyAndSell.sell.currency
                        ))
                );

                dispatch({
                    type: stateUpdate.sellAmount,
                    amount: newSellAmt,
                    currency: buyAndSell.sell.currency,
                });
            } else {
                const newBuyAmt = String(
                    toNumber(buyAndSell.sell.amount) *
                        (await calculateExchange(
                            prices,
                            networkConnected.networkId,
                            buyAndSell.sell.currency,
                            buyAndSell.buy.currency
                        ))
                );

                dispatch({
                    type: stateUpdate.buyAmount,
                    amount: newBuyAmt,
                    currency: buyAndSell.buy.currency,
                });
            }

            setLoading({ buy: false, sell: false });
        };
        getNewRates();
    }, [buyAndSell.buy.currency]);

    useEffect(() => {
        (async () => {
            setBalance(
                Number(
                    await getTokenBalance({
                        tokenSymbol: buyAndSell.sell.currency,
                    })
                )
            );
        })();
    }, [buyAndSell.buy.currency, getTokenBalance]);

    useEffect(() => {
        storeState.current = { ...exchange };
    }, [exchange]);

    useEffect(() => {
        if (!buyAndSell.buy.currency || !buyAndSell.sell.currency) return;
        setExchange(buyAndSell);
    }, [buyAndSell]);

    /*
    EVENT LISTENERS
    */
    const handleSellCurrencyChange = async (type, amt) => {
        dispatch({
            type: stateUpdate.sellCurrency,
            amount: amt,
            currency: type,
        });
    };

    const handleSellAmountChange = (type, amt) => {
        basisForExchange.current = exchangeComponents.sell;
        dispatch({ type: stateUpdate.sellAmount, amount: amt, currency: type });
    };

    const handleBuyCurrencyChange = async (type, amt) => {
        dispatch({
            type: stateUpdate.buyCurrency,
            amount: amt,
            currency: type,
        });
    };

    const handleBuyAmountChange = (type, amt) => {
        basisForExchange.current = exchangeComponents.buy;
        dispatch({ type: stateUpdate.buyAmount, amount: amt, currency: type });
    };

    return (
        <>
            {!buyAndSell.sell.currency || !buyAndSell.buy.currency ? (
                <>
                    <Loading />
                    <S.Loading>Loading rates...</S.Loading>
                </>
            ) : (
                <>
                    <CurrencySelector
                        label={
                            <>
                                Sell{" "}
                                <InfoToolTip
                                    size="1em"
                                    title={
                                        <>
                                            A fixed amount of{" "}
                                            {buyAndSell.sell.currency}
                                            <br />
                                            will be sold each period
                                            <br />
                                            in exchange for{" "}
                                            {buyAndSell.buy.currency}.
                                            <br />
                                            <br />
                                            The first swap will
                                            <br />
                                            take place immediately.
                                        </>
                                    }
                                />
                            </>
                        }
                        balance={balance}
                        data={tokens.sell}
                        amount={
                            basisForExchange.current === exchangeComponents.sell
                                ? buyAndSell.sell.amount
                                : toCoin(toNumber(buyAndSell.sell.amount))
                        }
                        currency={buyAndSell.sell.currency}
                        usdValue={findUsdRate(
                            prices,
                            networkConnected.networkId,
                            buyAndSell.sell.currency
                        )}
                        onCurrencyChange={handleSellCurrencyChange}
                        onAmountChange={handleSellAmountChange}
                        loading={loading.sell}
                        valid={validity.sell}
                        id="sellCurrency"
                    />
                    <CurrencySelector
                        label={`Buy`}
                        subLabel={`(estimated)`}
                        data={tokens.buy}
                        amount={
                            basisForExchange.current === exchangeComponents.buy
                                ? buyAndSell.buy.amount
                                : toCoin(toNumber(buyAndSell.buy.amount))
                        }
                        currency={buyAndSell.buy.currency}
                        usdValue={findUsdRate(
                            prices,
                            networkConnected.networkId,
                            buyAndSell.buy.currency
                        )}
                        onCurrencyChange={handleBuyCurrencyChange}
                        onAmountChange={handleBuyAmountChange}
                        loading={loading.buy}
                        valid={validity.buy}
                        id="buyCurrency"
                    />
                </>
            )}
        </>
    );
};

export default ExchangeCurrencies;
