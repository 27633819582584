import React from "react";

const FastForward = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            {...props}
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-3 0 27 16"
        >
            {name && <title>{name}</title>}

            <path
                fill="inherit"
                d="M15 5.15 19.04 8 15 10.85v-5.7Zm-12 0L7.04 8 3 10.85v-5.7ZM12.33 0v16l11.34-8-11.34-8Zm-12 0v16l11.34-8L.33 0Z"
            />
        </svg>
    );
};

export default FastForward;
