import React from "react";

const ArrowRight = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
        >
            {name && <title>{name}</title>}
            <path d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z" />
        </svg>
    );
};

export default ArrowRight;
