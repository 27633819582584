import { forwardRef, useState, useEffect } from "react";
import * as S from "./style";

const InputText = forwardRef(
    (
        {
            value = ``,
            label = ``,
            button = false,
            flex = null,
            readOnly = false,
            ...props
        },
        ref
    ) => {
        const { label: btnLabel, onClick: onBtnClick, ...btnProps } = button;
        const [inputValue, setInputValue] = useState(value);

        useEffect(() => {
            setInputValue(value);
        }, [value]);
        return (
            <S.InputText flex={flex} as={label && `label`}>
                {label && <span>{label}</span>}
                <S.Input
                    type="text"
                    value={inputValue}
                    ref={ref}
                    readOnly={readOnly}
                    onChange={(e) => setInputValue(e.target.value)}
                    {...props}
                />
                {button && (
                    <S.SideButton
                        type="button"
                        onClick={onBtnClick}
                        {...btnProps}
                    >
                        {btnLabel}
                    </S.SideButton>
                )}
            </S.InputText>
        );
    }
);

export default InputText;
