import { forwardRef, useImperativeHandle, useState, Ref, useRef } from "react";
import Label from "components/Label";
import Field from "components/Field";
import Input from "components/Input";
import { validatePresence } from "utils/formValidation";

export interface ItemNameFieldProps {
    disabled?: boolean;
    label?: React.ReactNode;
    defaultName?: string;
}

export type ItemNameFieldRef = {
    name: string;
    validate: () => boolean;
};

function ItemNameField(
    { disabled, label = "Name", defaultName = "" }: ItemNameFieldProps,
    ref: Ref<ItemNameFieldRef>
) {
    const [name, setName] = useState<string>(defaultName);
    const nameRef = useRef<HTMLInputElement>(null);
    const nameValidationMessage = "Please enter a name";

    const validate = () => {
        if (!nameRef.current) {
            return false;
        }

        return validatePresence({
            input: nameRef.current,
            errorMessage: nameValidationMessage,
        });
    };
    useImperativeHandle(ref, () => ({ name, validate }));

    return (
        <Field disabled={disabled}>
            <Label htmlFor="itemName" subLabel="Displayed on cart">
                {label}
            </Label>
            <Input
                type="text"
                disabled={disabled}
                name="itemName"
                value={name}
                innerRef={nameRef}
                onChange={(event) => setName(event.target.value)}
                onBlur={(event) =>
                    validatePresence({
                        input: event.target,
                        reportValidity: true,
                        errorMessage: nameValidationMessage,
                    })
                }
                placeholder="Name"
            />
        </Field>
    );
}
export default forwardRef(ItemNameField);
