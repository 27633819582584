import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { useEmailByWallet } from "checkout/hooks/useEmailByWallet";
import { useCheckoutForm } from "checkout/context/CheckoutForm";
import { useCheckoutData } from "checkout/context/CheckoutData";
import { useWallet } from "context/Wallet";

interface ContactInfoProps {
    children: ReactNode;
}

interface ContactInfoValue {
    inputEmail: string;
    setInputEmail: Dispatch<SetStateAction<string>>;
    serverEmail: string;
    isGettingEmail: boolean;
    isPostingEmail: boolean;
    isEdited: boolean;
    isSetByQueryParams: boolean;
    handleSaveAndOrContinue: () => Promise<void>;
}

const ContactInfoContext = createContext<ContactInfoValue>(
    {} as ContactInfoValue
);

const ContactInfoProvider = ({ children, ...props }: ContactInfoProps) => {
    const { walletConnected, getWalletEmail } = useWallet();
    const { queryParams } = useCheckoutData();
    const {
        authToken,
        contract,
        confirmContact,
        setEmail,
        setEmailBeingEdited,
    } = useCheckoutForm();
    const {
        email: serverEmail,
        fetchEmailGet,
        isGettingEmail,
        fetchEmailPost,
        isPostingEmail,
    } = useEmailByWallet(authToken, contract?.address || ``);

    const [inputEmail, setInputEmail] = useState<string>(
        queryParams.email || serverEmail
    );
    const [isEdited, setIsEdited] = useState(serverEmail !== inputEmail);

    const handleSaveAndOrContinue = useCallback(async () => {
        if (isEdited) {
            // Go store the new email address
            await fetchEmailPost(inputEmail);
            setIsEdited(false);
            setEmailBeingEdited(false);
        }
        // Even if the post fails, use the email for the form and continue
        setEmail(inputEmail);
        confirmContact();
    }, [
        inputEmail,
        isEdited,
        fetchEmailPost,
        confirmContact,
        setEmail,
        setEmailBeingEdited,
    ]);

    // If email is set from the query params, store it on the server as soon the user is validated to do so
    useEffect(() => {
        (async () => {
            // Ensure the post has everything it needs before calling it
            if (!queryParams.email || !authToken || !contract?.address) return;
            await fetchEmailPost(queryParams.email);
        })();
    }, [fetchEmailPost, queryParams.email, authToken, contract?.address]);

    // Whenever the server email is updated, update the local email
    useEffect(() => {
        setInputEmail(serverEmail);
        setEmail(serverEmail);
    }, [serverEmail, setEmail]);

    // Check for a mismatch
    useEffect(() => {
        const editing = inputEmail !== serverEmail;
        setIsEdited(editing);
        setEmailBeingEdited(editing);
    }, [inputEmail, serverEmail, setEmailBeingEdited]);

    // Signature or wallet address has changed, get the email address stored on the server
    useEffect(() => {
        if (!authToken || !walletConnected) {
            setInputEmail(``);
            return;
        }

        const storedEmail = getWalletEmail() || ``;
        setInputEmail(storedEmail);
    }, [
        authToken,
        walletConnected,
        walletConnected?.proxyFor,
        walletConnected?.address,
        fetchEmailGet,
        getWalletEmail,
    ]);

    return (
        <ContactInfoContext.Provider
            value={{
                inputEmail,
                setInputEmail,
                serverEmail,
                isGettingEmail,
                isPostingEmail,
                isEdited,
                isSetByQueryParams: !!queryParams.email,
                handleSaveAndOrContinue,
            }}
            {...props}
        >
            {children}
        </ContactInfoContext.Provider>
    );
};

const useContactInfo = (): ContactInfoValue => {
    const context = useContext<ContactInfoValue>(ContactInfoContext);
    if (context === undefined) {
        throw new Error(
            `useContactInfo() must be used within a ContactInfoProvider`
        );
    }
    return context;
};

export { ContactInfoProvider, useContactInfo };
