import { useState } from "react";
import Form from "components/Form";
import Field from "components/Field";
import Input, { InputSizes } from "components/Input";
import Hint, { HintType } from "components/Hint";

const SetPasswordForm = ({ children, onSubmit }: any) => {
    const [formValues, setFormValues] = useState({
        newPassword: ``,
        passwordRetype: ``,
    });
    const [formErrors, setFormErrors] = useState({
        newPassword: ``,
        passwordRetype: ``,
    });

    const { newPassword, passwordRetype } = formValues;

    const handleNewPasswordChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setFormValues({ ...formValues, newPassword: event.target.value });
        // TODO clear passwordRetype state?

        // Clear error
        if (
            event.target.value.length >= 8 &&
            formErrors.newPassword.length > 0
        ) {
            setFormErrors({ ...formErrors, newPassword: `` });
        }
    };

    const handlePasswordRetypeChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setFormValues({ ...formValues, passwordRetype: event.target.value });

        // Clear error
        if (
            event.target.value === newPassword &&
            formErrors.passwordRetype.length > 0
        ) {
            setFormErrors({ ...formErrors, passwordRetype: `` });
        }
    };

    const validateNewPassword = () => {
        if (newPassword.length < 8) {
            return `Password must be at least 8 characters long.`;
        }

        if (newPassword.length > 64) {
            return `Password must no more than 8 characters long.`;
        }

        return ``;
    };

    const handleNewPasswordBlur = () => {
        setFormErrors({ ...formErrors, newPassword: validateNewPassword() });
    };

    const validatePasswordRetype = () => {
        if (passwordRetype !== newPassword) {
            return `Passwords must match.`;
        }

        return ``;
    };

    const handlePasswordRetypeBlur = () => {
        setFormErrors({
            ...formErrors,
            passwordRetype: validatePasswordRetype(),
        });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        onSubmit(event, newPassword, passwordRetype);
    };

    const passwordIsValid = !validateNewPassword();
    const passwordRetypeIsValid = !validatePasswordRetype();

    return (
        <Form onSubmit={handleSubmit}>
            <Field>
                <Input
                    placeholder="New password"
                    type="password"
                    inputSize={InputSizes.Large}
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    onBlur={handleNewPasswordBlur}
                    aria-invalid={!!formErrors.newPassword}
                />
                {formErrors.newPassword && (
                    <Hint type={HintType.Error}>{formErrors.newPassword}</Hint>
                )}
            </Field>
            <Field>
                <Input
                    placeholder="Retype password"
                    type="password"
                    inputSize={InputSizes.Large}
                    value={passwordRetype}
                    onChange={handlePasswordRetypeChange}
                    onBlur={handlePasswordRetypeBlur}
                    aria-invalid={!!formErrors.passwordRetype}
                />
                {formErrors.passwordRetype && (
                    <Hint type={HintType.Error}>
                        {formErrors.passwordRetype}
                    </Hint>
                )}
            </Field>
            {children({
                isValid: passwordIsValid && passwordRetypeIsValid,
                passwordIsValid,
                passwordRetypeIsValid,
            })}
        </Form>
    );
};

export default SetPasswordForm;
