import * as S from "./style";
import { colorsDeprecated as c } from "global-style";
import {
    forwardRef,
    Ref,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import { Company } from "company/types";
import {
    minimumAllowanceAmountForCheckout,
    calculateMinimumAllowance,
} from "utils/checkout";
import { toDollar } from "utils/financial";
import Tooltip from "components/Tooltip";
import Info from "components/icons/Info";

export interface ItemsCheckoutMinimumAllowanceFormProps {
    disabled?: boolean;
    items: Company.Item[];
    minimumBalanceRequired: number;
    onChange: (minimumAllowance: number) => void;
}

export type ItemsCheckoutMinimumAllowanceFormRef = {
    minimumAllowance: number;
    wasEdited: boolean;
};

function ItemsCheckoutMinimumAllowanceForm(
    {
        disabled = false,
        items,
        minimumBalanceRequired,
        onChange,
    }: ItemsCheckoutMinimumAllowanceFormProps,
    ref: Ref<ItemsCheckoutMinimumAllowanceFormRef>
) {
    const [defaultValue, setDefaultValue] = useState<number>(
        calculateMinimumAllowance(undefined, items, 0)
    );

    const [minimumAllowance, setMinimumAllowance] =
        useState<number>(defaultValue);

    // Minimum allowance: Changed from default
    const [wasEdited, setWasEdited] = useState<boolean>(
        minimumAllowance !== defaultValue
    );

    // Input props that affect the calculation have been updated
    useEffect(() => {
        // Recalculate the new default minimum allowance
        const newDefaultMinimumAllowance = calculateMinimumAllowance(
            undefined,
            items,
            minimumBalanceRequired
        );
        setDefaultValue(newDefaultMinimumAllowance);

        // Currently, the minimum allowance is the same as its default because it's not editable
        setMinimumAllowance(newDefaultMinimumAllowance);
    }, [items, minimumBalanceRequired]);

    // This is not used at present, but calculated none the less
    useEffect(() => {
        setWasEdited(minimumAllowance !== defaultValue);
    }, [defaultValue, minimumAllowance]);

    // Update the parent component with the new minimum allowance
    useEffect(() => {
        onChange(minimumAllowance);
    }, [onChange, minimumAllowance]);

    useImperativeHandle(
        ref,
        () => ({
            minimumAllowance,
            wasEdited,
        }),
        [minimumAllowance, wasEdited]
    );

    // The "checkout equivalent" (ie: slippage included)
    const checkoutMinimumAllowance =
        minimumAllowanceAmountForCheckout(minimumAllowance);

    return (
        <S.AmountSetting>
            <Tooltip title="Loop will stop a wallet from finishing checkout if the minimum required authorization amount is not met.">
                <S.AmountSettingHeader>
                    <Info
                        height="1rem"
                        width="1rem"
                        fill={c.popoverCloseLink}
                    />
                    Minimum required authorization:
                </S.AmountSettingHeader>
            </Tooltip>
            <S.AmountSettingsValue>
                {toDollar(checkoutMinimumAllowance)}{" "}
            </S.AmountSettingsValue>
        </S.AmountSetting>
    );
}

export default forwardRef(ItemsCheckoutMinimumAllowanceForm);
