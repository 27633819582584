import { createCompanyItems } from "api";
import { Company, CompanyFrequency, CreateItemRequest } from "company/types";
import Button from "components/Button";
import Form from "components/Form";
import { useNotificationQueue } from "context/NotificationQueue";
import { useUser } from "context/User";
import { FormEvent, FunctionComponent, useMemo, useRef, useState } from "react";
import { ItemType } from "types/common-enums";
import { ItemFrequencyNames } from "utils/items";
// Item Form specific components
import ItemAcceptedTokensField, {
    ItemAcceptedTokensFieldRef,
} from "company/components/items/ItemAcceptedTokensField";
import ItemAmountField, {
    ItemAmountFieldRef,
} from "company/components/items/ItemAmountField";
import ItemAutoInvoiceField, {
    InvoicePlurality,
    ItemAutoInvoiceFieldRef,
} from "company/components/items/ItemAutoInvoiceField";
import ItemEntityField, {
    ItemEntityFieldRef,
} from "company/components/items/ItemEntityField";
import ItemFrequencyField, {
    ItemFrequencyFieldRef,
} from "company/components/items/ItemFrequencyField";
import ItemInitialOffsetField, {
    ItemInitialOffsetFieldRef,
} from "company/components/items/ItemInitialOffsetField";
import ItemNameField, {
    ItemNameFieldRef,
} from "company/components/items/ItemNameField";
import ItemPriceMetadataField, {
    ItemPriceMetadataFieldRef,
} from "company/components/items/ItemPriceMetadataField";
import ItemPricingModelField, {
    ItemPricingModelFieldRef,
} from "company/components/items/ItemPricingModelField";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import { useGetCompanyItems } from "company/hooks/useGetCompanyItems";
import { NotificationType } from "components/Notification";
import { useGetNetworks } from "hooks/useGetNetworks";
import * as S from "./style";
import { getInboundTreasuryAddressByNetworkId } from "company/utils/entities";
import { ItemNetwork, ItemNetworkDisabledReason } from "company/utils/items";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";
import { useAcceptedTokensByNetwork } from "company/hooks/useAcceptedTokensByNetwork";

interface CreateItemFormProps {
    onSuccess?: () => void;
    itemFrequencyName: ItemFrequencyNames;
}

const CreateItemForm: FunctionComponent<CreateItemFormProps> = ({
    onSuccess,
    itemFrequencyName,
}) => {
    const {
        config: { entities, contracts },
    } = useGetCompanyConfig();

    const { acceptedTokensByNetwork } = useAcceptedTokensByNetwork();
    const { networks } = useGetNetworks();
    const { tokens } = useGetTokensMetadata();
    const { getCompanyItemsRefetch } = useGetCompanyItems();

    const { addNotification } = useNotificationQueue();
    const [loading, setLoading] = useState<boolean>(false);
    const [entityId, setEntityId] = useState<string>(entities[0].entityId);
    const entity = entities.find((entity) => entity.entityId === entityId);
    const inboundTreasuries = getInboundTreasuryAddressByNetworkId(entity);
    const [fixedPrice, setFixedPrice] = useState<boolean>(true);
    const [autoInvoice, setAutoInvoice] =
        useState<Company.Item["autoInvoice"]>(false);

    const { getEntityId, getSessionToken } = useUser();

    const inboundTreasury = entity && entity.inboundTreasuries;
    const canAutoInvoice =
        fixedPrice &&
        entity &&
        entity.delegatedSigning &&
        inboundTreasury &&
        Object.keys(inboundTreasuries).length > 0
            ? true
            : false;

    const itemNetworks: ItemNetwork[] = useMemo(() => {
        return networks
            .filter((network) => network.active)
            .map((network) => {
                const isSupported = contracts.some(
                    (c) => c.networkId === network.id
                );

                const acceptedTokensAddresses =
                    acceptedTokensByNetwork[network.id];
                const acceptedTokens = tokens.filter(
                    (token) =>
                        acceptedTokensAddresses &&
                        acceptedTokensAddresses.includes(token.address) &&
                        token.networkId === network.id
                );
                const selectedTokens = acceptedTokens;
                return {
                    network: network,
                    acceptedTokens: acceptedTokens,
                    selectedTokens: selectedTokens,
                    inboundTreasuryAddress: inboundTreasuries[network.id],
                    showInboundTreasuryAddress: autoInvoice,
                    disabled: !isSupported,
                    disabledReason: isSupported
                        ? undefined
                        : ItemNetworkDisabledReason.MissingContract,
                };
            });
    }, [
        acceptedTokensByNetwork,
        autoInvoice,
        contracts,
        inboundTreasuries,
        networks,
        tokens,
    ]);

    // Different UX / payload (frequency)
    const isSubscription =
        itemFrequencyName === ItemFrequencyNames.Subscription;
    const isOneTimePayment =
        itemFrequencyName === ItemFrequencyNames.OneTimePayment;

    const displayEntityField = entities.length > 1;

    // Refs to the different item fields
    const itemNameFieldRef = useRef<ItemNameFieldRef>(null);
    const itemPriceMetadataFieldRef = useRef<ItemPriceMetadataFieldRef>(null);
    const itemEntityFieldRef = useRef<ItemEntityFieldRef>(null);
    const itemAcceptedTokensFieldRef = useRef<ItemAcceptedTokensFieldRef>(null);
    const itemAmountFieldRef = useRef<ItemAmountFieldRef>(null);
    const itemFrequencyFieldRef = useRef<ItemFrequencyFieldRef>(null);
    const itemPricingModelFieldRef = useRef<ItemPricingModelFieldRef>(null);
    const itemAutoInvoiceFieldRef = useRef<ItemAutoInvoiceFieldRef>(null);
    const itemInitialOffsetFieldRef = useRef<ItemInitialOffsetFieldRef>(null);

    // Submit
    const createItem = async (event: FormEvent<HTMLFormElement>) => {
        // Get & Validate name
        let name: string = "";
        if (itemNameFieldRef.current) {
            name = itemNameFieldRef.current.name;
            if (!itemNameFieldRef.current.validate()) return;
        }
        if (!name) return;

        // Get priceMetadata
        let priceMetadata: string | undefined = undefined;
        if (itemPriceMetadataFieldRef.current) {
            priceMetadata = itemPriceMetadataFieldRef.current.priceMetadata;
        }

        const typeId: ItemType = isOneTimePayment
            ? ItemType.One_Time
            : ItemType.Subscription; // Default to subscription

        // Get entityId
        let entityId: string;
        if (displayEntityField && itemEntityFieldRef.current) {
            entityId = itemEntityFieldRef.current.entityId;
        } else {
            entityId = entities[0].entityId;
        }

        const acceptedTokensByNetwork =
            itemAcceptedTokensFieldRef?.current?.acceptedTokensByNetwork;

        // Get & Validate amount
        let amount: number = 0;
        if (fixedPrice && itemAmountFieldRef.current) {
            amount = itemAmountFieldRef.current.amount;
            if (!itemAmountFieldRef.current.validate()) return;
        }

        // Get & Validate frequency
        let frequency: CompanyFrequency | undefined = undefined;

        // 0 as default for OTP
        let frequencyCount: number | undefined = 0;

        // Only Subscription can have frequency
        if (isSubscription && itemFrequencyFieldRef.current) {
            frequency = itemFrequencyFieldRef.current.frequency;
            frequencyCount = itemFrequencyFieldRef.current.frequencyCount;
            if (!itemFrequencyFieldRef.current.validate()) return;
            if (!frequency) return;
            if (!frequencyCount) return;
        }

        // Get & Validate payment collection settings
        let autoInvoice: boolean = false;
        let initialOffset: number | undefined = undefined;

        // autoInvoice and initialOffset are only available for fixed amount
        if (fixedPrice && itemAutoInvoiceFieldRef.current) {
            autoInvoice = itemAutoInvoiceFieldRef.current.autoInvoice;
        }

        // InitialOffset are only available for fixed amount
        if (
            !isOneTimePayment &&
            fixedPrice &&
            autoInvoice &&
            itemInitialOffsetFieldRef.current
        ) {
            initialOffset = itemInitialOffsetFieldRef.current.initialOffset;
        }

        const newItem: CreateItemRequest = {
            name,
            priceMetadata,
            typeId,
            entityId,
            acceptedTokens: acceptedTokensByNetwork,
            amount,
            frequency,
            frequencyCount,
            autoInvoice,
            initialOffset,
        };

        setLoading(true);

        const { response } = await createCompanyItems([newItem], {
            Authorization: getSessionToken(),
            "Content-Type": "application/json",
            "entity-id": getEntityId(),
        });

        const successFullResponse = response.ok && response.status === 200;

        if (successFullResponse) {
            await getCompanyItemsRefetch();
            addNotification({
                msg: `"${newItem.name}" successfully created`,
                type: NotificationType.SUCCESS,
            });
            if (onSuccess) onSuccess();
        } else {
            addNotification({
                msg: `There was an error while creating "${newItem.name}"`,
                type: NotificationType.ERROR,
            });
        }

        setLoading(false);
    };

    return (
        <Form onSubmit={createItem} preventEnterKeySubmission>
            {/* Name */}
            <ItemNameField disabled={loading} ref={itemNameFieldRef} />

            {/* Entity */}
            {displayEntityField && (
                <ItemEntityField
                    disabled={loading}
                    ref={itemEntityFieldRef}
                    onChangeEntityId={(entityId) => setEntityId(entityId)}
                />
            )}

            {/* Price model (fixed or variable) */}
            <ItemPricingModelField
                disabled={loading}
                ref={itemPricingModelFieldRef}
                onChangeFixedPrice={(fixedPrice) => setFixedPrice(fixedPrice)}
            />

            {/* Price amount & description */}
            <S.PriceSectionWrapper>
                <S.AmountWrapper>
                    <ItemAmountField
                        disabled={loading}
                        ref={itemAmountFieldRef}
                        fixedPrice={fixedPrice}
                    />
                </S.AmountWrapper>
                <S.PriceMetadataWrapper>
                    <ItemPriceMetadataField
                        disabled={loading}
                        ref={itemPriceMetadataFieldRef}
                    />
                </S.PriceMetadataWrapper>
            </S.PriceSectionWrapper>

            {/* Subscription-only: Frequency */}
            {isSubscription && (
                <ItemFrequencyField
                    disabled={loading}
                    ref={itemFrequencyFieldRef}
                />
            )}

            {/* Automatic/Manual */}
            <ItemAutoInvoiceField
                disabled={loading}
                ref={itemAutoInvoiceFieldRef}
                canAutoInvoice={canAutoInvoice}
                plurality={InvoicePlurality.Multiple}
                defaultAutoInvoice={canAutoInvoice}
                onChange={(autoInvoice) => setAutoInvoice(autoInvoice)}
                fixedPrice={fixedPrice}
                isSubscription={isSubscription}
            />

            {/* Free trials */}
            {!isOneTimePayment && (
                <ItemInitialOffsetField
                    disabled={loading}
                    ref={itemInitialOffsetFieldRef}
                    defaultInitialOffset={0}
                    canEdit={autoInvoice}
                />
            )}

            {/* Accepted tokens */}
            <ItemAcceptedTokensField
                disabled={loading}
                itemNetworks={itemNetworks}
                ref={itemAcceptedTokensFieldRef}
            />

            <Button disabled={loading}>
                {loading ? "Creating" : "Create"}
            </Button>
        </Form>
    );
};

export default CreateItemForm;
