import { useEffect, useState } from "react";
import { CheckoutItemResponse } from "api/types/checkout";
import { BaseItem } from "types/common";
import {
    minimumBalanceRequiredAmountForCheckout,
    calculateMinimumBalance,
    DiscountInfo,
} from "utils/checkout";
import { CheckoutItem } from "checkout/types";

export const useMinimumBalance = (
    invoiceAmount: number | undefined,
    items: CheckoutItemResponse[] | BaseItem[] | CheckoutItem[],
    minimumBalanceRequired: number | undefined,
    coupons: CouponResponse[]
) => {
    const [minimumBalance, setMinimumBalance] = useState<number>(0);

    useEffect(() => {
        const discountInfo: DiscountInfo = {
            discountPercentage: coupons[0]?.discountPercentage ?? null,
            discountAmount: coupons[0]?.discountAmount ?? null,
            singleUse: coupons[0]?.singleUse ?? false,
        };

        setMinimumBalance(
            minimumBalanceRequiredAmountForCheckout(
                calculateMinimumBalance(
                    invoiceAmount,
                    items,
                    minimumBalanceRequired,
                    discountInfo
                )
            )
        );
    }, [invoiceAmount, items, minimumBalanceRequired, coupons]);

    return minimumBalance;
};
