import { Box, Container, styled } from "@mui/material";
import { Routes, Route, Navigate, Link } from "react-router-dom";
import Card from "components/Card";
import LoginForm from "./LoginForm";
import Image from "components/ImageDEPRECATED";

const FullCard = styled(Card)`
    width: 100%;
    margin-inline: auto;
`;

const Login = () => {
    return (
        <Box
            minHeight="100vh"
            display="flex"
            flexDirection="column"
            alignContent="center"
            sx={{
                background: `linear-gradient(180deg, #3545E8 0%, #6F7BF9 124.22%)`,
            }}
        >
            <Box padding={4}>
                <Link to="/login">
                    <Image
                        src="/img/loop-logo/loop-crypto-tall-white.svg"
                        alt="Loop Crypto"
                        width="6rem"
                    />
                </Link>
            </Box>
            <Container
                maxWidth="md"
                sx={{
                    flexGrow: `1`,
                    display: `flex`,
                    flexDirection: `column`,
                    justifyContent: `center`,
                    width: {
                        xs: `100%`,
                        sm: `36rem`,
                    },
                }}
            >
                <FullCard>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        mx="auto"
                        maxWidth="24rem"
                    >
                        <Box
                            component={`header`}
                            my={3}
                            width={{ xs: `8rem`, sm: `12rem` }}
                        >
                            <Image
                                src="/img/loop-logo/loop-crypto-tall.svg"
                                alt="Loop Crypto"
                            />
                        </Box>
                        <Box mb={8} alignSelf="stretch">
                            <Routes>
                                <Route path="/" element={<LoginForm />} />
                                <Route
                                    path="*"
                                    element={<Navigate to={`/login`} />}
                                />
                            </Routes>
                        </Box>
                    </Box>
                </FullCard>
            </Container>
        </Box>
    );
};

export default Login;
