import { FunctionComponent } from "react";
import * as S from "./style";
import { CommonBlockchainNetworkResponse } from "api";
import { firstToUpper } from "utils/strings";

interface InboundTreasuriesNetworkProps {
    networkId: number;
    networks: CommonBlockchainNetworkResponse[];
    inboundTreasuries: Record<string, string[]>;
}

const InboundTreasuriesNetwork: FunctionComponent<InboundTreasuriesNetworkProps> =
    ({ networkId, networks, inboundTreasuries }) => {
        const network = networks.find((n) => n.id === networkId);
        if (!network) return <></>;

        const treasuryAddresses = inboundTreasuries[networkId];

        // We only list the first (latest) inbound treasury address for a given network
        const inboundTreasury = treasuryAddresses[0];
        return (
            <S.NetworkWrapper>
                <S.Network>{firstToUpper(network.name)}</S.Network>
                <S.List>
                    <S.ListItem key={inboundTreasury}>
                        {inboundTreasury}
                    </S.ListItem>
                </S.List>
            </S.NetworkWrapper>
        );
    };

export default InboundTreasuriesNetwork;
