import { ReactNode } from "react";
import { useHistory } from "hooks/useHistory";
import { useNavigate } from "react-router-dom";
import LessThan from "components/icons/LessThan";
import Button from "components/Button";

interface BackButtonProps {
    children?: ReactNode;
    priorHistory?: string[];
    fallback?: string;
}

const BackButton = ({
    children,
    priorHistory = [],
    fallback = `/`,
    ...props
}: BackButtonProps) => {
    const stack = useHistory(priorHistory);
    const navigate = useNavigate();

    const handleBackClick = () => {
        /* When arriving at the application and loading for the first time, useNavigationType 
        will return `POP` within useHistory, therefor creating an empty stack is created, thus
        using the fallback, as not to go "back" beyond this application.
        
        However, as a side effect of this, directing from `/login` (as an example) will allow
        that route to be the "back" and will bypass the `fallback`. As a future possible solution
        to that (if one was required), would be to use the `priorStack` to keep the button
        insync with the applications top-level history. Adding a `bypass` Array property to this
        component would then allow checking if the last route on the stack was in a banned list
        and force the fallback action.
        */
        if (stack.length > 0) navigate(-1);
        else navigate(fallback);
    };

    return (
        <Button onClick={handleBackClick} noStyles {...props}>
            {children || <LessThan fill="inherit" />}
        </Button>
    );
};

export default BackButton;
