import Paragraph from "components/Paragraph";
import * as S from "./style";
import Title from "components/Title";

interface Props {
    msg?: string | JSX.Element;
    children?: any;
}
const ErrorMessage = ({ msg = ``, children, ...props }: Props) => {
    return (
        <>
            <S.ErrorMessage {...props}>
                {msg && <Title level="h2">{msg}</Title>}
                {children && <Paragraph>{children}</Paragraph>}
            </S.ErrorMessage>
        </>
    );
};

export default ErrorMessage;
