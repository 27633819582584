import React from "react";

const Xero = ({
    name = "Xero",
    height = `1.5rem`,
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    const originalWidth = 113;
    const originHeight = 35;
    const aspectRatio = originalWidth / originHeight;

    const calculatedWidth = `calc(${height} * ${aspectRatio})`;

    return (
        <svg
            role="img"
            name={name}
            width={calculatedWidth} // Use calculated width
            height={height} // Use provided height
            viewBox={`0 0 ${originalWidth} ${originHeight}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            {name && <title>{name}</title>}
            <path
                d="M17.5667 35C27.2318 35 35.0667 27.165 35.0667 17.5C35.0667 7.83501 27.2318 -9.90335e-07 17.5667 -9.90335e-07C7.90173 -9.90335e-07 0.0667114 7.83501 0.0667114 17.5C0.0667114 27.165 7.90173 35 17.5667 35Z"
                fill="#1AB4D7"
            />
            <path
                d="M19.5653 17.501L25.684 10.9838C25.8868 10.7642 26 10.4733 26 10.1649C26 9.52204 25.5082 9 24.9032 9C24.6085 9 24.3322 9.12273 24.1239 9.34682C24.1232 9.34811 18.0107 15.839 18.0107 15.839L11.8702 9.3371C11.6627 9.11975 11.3877 9 11.0962 9C10.4919 9 10 9.52178 10 10.164C10 10.4772 10.1177 10.7712 10.329 10.9926L16.4468 17.492L10.3321 24.001C10.1172 24.2259 10 24.5205 10 24.8351C10 25.4778 10.4919 25.9992 11.0962 25.9992C11.3882 25.9992 11.6634 25.8788 11.8702 25.659L18.0012 19.1536L24.1089 25.6353C24.3255 25.8732 24.6049 26 24.9032 26C25.5076 26 25.9994 25.4778 25.9994 24.8351C25.9994 24.525 25.8863 24.2336 25.6805 24.014L19.5653 17.501Z"
                fill="white"
            />
            <path
                d="M54.8851 17.9903L62.4877 10.3689C62.7398 10.112 62.8804 9.77186 62.8804 9.41114C62.8804 8.6594 62.2693 8.04892 61.5176 8.04892C61.1514 8.04892 60.8081 8.19244 60.5493 8.45449C60.5485 8.45601 52.9535 16.0466 52.9535 16.0466L45.3237 8.44312C45.0659 8.18896 44.7243 8.04892 44.3621 8.04892C43.6112 8.04892 43 8.6591 43 9.41008C43 9.77641 43.1463 10.1202 43.4088 10.3792L51.0103 17.9797L43.4127 25.5915C43.1456 25.8545 43 26.199 43 26.567C43 27.3186 43.6112 27.9283 44.3621 27.9283C44.7249 27.9283 45.0668 27.7875 45.3237 27.5304L52.9417 19.9228L60.5306 27.5027C60.7998 27.781 61.147 27.9292 61.5176 27.9292C62.2686 27.9292 62.8797 27.3186 62.8797 26.567C62.8797 26.2043 62.7392 25.8636 62.4835 25.6067L54.8851 17.9903Z"
                fill="#1AB4D7"
            />
            <path
                d="M100.081 17.9881C100.081 19.353 101.191 20.4634 102.558 20.4634C103.921 20.4634 105.032 19.353 105.032 17.9881C105.032 16.6228 103.921 15.5126 102.558 15.5126C101.191 15.5126 100.081 16.6228 100.081 17.9881Z"
                fill="#1AB4D7"
            />
            <path
                d="M95.3866 17.9891C95.3866 14.0364 98.6021 10.8202 102.555 10.8202C106.506 10.8202 109.723 14.0364 109.723 17.9891C109.723 21.9409 106.506 25.1556 102.555 25.1556C98.6021 25.1556 95.3866 21.9409 95.3866 17.9891ZM92.5667 17.9891C92.5667 23.4959 97.0476 27.9762 102.555 27.9762C108.063 27.9762 112.546 23.4959 112.546 17.9891C112.546 12.4815 108.063 7.99997 102.555 7.99997C97.0476 7.99997 92.5667 12.4815 92.5667 17.9891Z"
                fill="#1AB4D7"
            />
            <path
                d="M91.8575 8.17056L91.4385 8.16934C90.1803 8.16934 88.9669 8.56613 87.9535 9.34713C87.8199 8.73603 87.2737 8.27619 86.6231 8.27619C85.8742 8.27619 85.2742 8.87622 85.2723 9.6266C85.2723 9.62933 85.2769 26.4499 85.2769 26.4499C85.2789 27.1989 85.8898 27.8077 86.6391 27.8077C87.3883 27.8077 87.9991 27.1989 88.001 26.4482C88.001 26.4449 88.0017 16.104 88.0017 16.104C88.0017 12.6563 88.3169 11.2639 91.2702 10.8949C91.5432 10.8609 91.8401 10.8662 91.8413 10.8662C92.6494 10.8385 93.2237 10.2832 93.2237 9.53264C93.2237 8.78165 92.6106 8.17056 91.8575 8.17056Z"
                fill="#1AB4D7"
            />
            <path
                d="M65.702 16.3447C65.702 16.3075 65.7049 16.2687 65.7067 16.2303C66.4975 13.1041 69.3289 10.7906 72.7008 10.7906C76.1135 10.7906 78.9707 13.1609 79.7211 16.3447H65.702ZM82.5106 16.0876C81.9236 13.3084 80.4021 11.0256 78.0852 9.5595C74.6984 7.4093 70.2261 7.52828 66.9548 9.85459C64.2864 11.7529 62.7461 14.8582 62.7461 18.0571C62.7461 18.8591 62.8428 19.6688 63.0441 20.4657C64.0516 24.4293 67.459 27.4298 71.5215 27.9271C72.727 28.073 73.9004 28.0031 75.1155 27.6885C76.1596 27.4345 77.1699 27.011 78.1008 26.4145C79.067 25.7934 79.8742 24.9743 80.6559 23.9939C80.6717 23.9759 80.6876 23.9599 80.7034 23.941C81.246 23.2681 81.1453 22.3111 80.5491 21.8543C80.0462 21.4689 79.202 21.3126 78.5377 22.1633C78.3949 22.3667 78.2357 22.576 78.0603 22.7853C77.5321 23.369 76.8768 23.9345 76.0915 24.3731C75.0923 24.9069 73.9536 25.212 72.7431 25.2188C68.781 25.1747 66.6608 22.409 65.9066 20.4349C65.7749 20.0663 65.6733 19.6839 65.6026 19.2902C65.5933 19.2165 65.5865 19.1454 65.5838 19.08C66.403 19.08 79.802 19.0777 79.802 19.0777C81.7513 19.0368 82.8004 17.6609 82.5106 16.0876Z"
                fill="#1AB4D7"
            />
        </svg>
    );
};

export default Xero;
