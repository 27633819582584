import styled from "styled-components";
import { fontSizes } from "theme/typography";
import colors from "theme/colors";

export const NetworkWrapper = styled.article`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const Network = styled.div`
    font-size: ${fontSizes.xs};
    width: 100%;
    max-width: 10rem;
`;

export const List = styled.ul`
    margin: 0;
    width: 100%;
`;

export const ListItem = styled.li`
    margin: 0;
    font-size: ${fontSizes.xs};
    color: ${colors.neutral40};
`;
