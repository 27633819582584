import React from "react";

const Chargebee = ({
    name = "Chargebee",
    height = `1.5rem`,
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    const originalWidth = 250;
    const originHeight = 35;
    const aspectRatio = originalWidth / originHeight;

    const calculatedWidth = `calc(${height} * ${aspectRatio})`;

    return (
        <svg
            role="img"
            name={name}
            width={calculatedWidth} // Use calculated width
            height={height} // Use provided height
            viewBox={`0 0 ${originalWidth} ${originHeight}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            {name && <title>{name}</title>}
            <path
                d="M10.1338 17.4823L30.5854 12.6043V2H19.9811L10.1338 17.4823Z"
                fill="#FF3300"
            />
            <path
                d="M3.36531e-06 17.2963C-0.000819909 18.5339 0.149412 19.767 0.447367 20.9682L10.1355 17.4823L0.366842 13.9571C0.123445 15.0523 0.000435128 16.1708 3.36531e-06 17.2927V17.2963Z"
                fill="#FF3300"
            />
            <path
                d="M3.60217 7.43636L10.1319 17.4823L13.1418 2.1521C9.41934 2.67761 6.0221 4.55943 3.60217 7.43636Z"
                fill="#FF3300"
            />
            <path
                d="M10.1338 17.4823L30.5854 22.3568V32.9576H19.9811L10.1338 17.4823Z"
                fill="#FF3300"
            />
            <path
                d="M3.60392 27.5248L10.1318 17.4788L13.14 32.8072C9.41797 32.2803 6.02125 30.3987 3.60034 27.523L3.60392 27.5248Z"
                fill="#FF3300"
            />
            <path
                d="M42.9742 17.5374C42.9742 11.532 47.6075 7.27441 54.1 7.27441C58.2747 7.27441 61.5764 8.92934 63.0313 12.1705C63.0313 12.1806 57.6967 13.585 57.6967 13.585C57.2369 13.0799 56.6746 12.6788 56.0474 12.4084C55.4202 12.138 54.7425 12.0045 54.0595 12.0169C51.1296 12.0169 48.8301 14.0578 48.8301 17.5394C48.8301 21.021 51.1296 23.0639 54.0595 23.0639C54.7385 23.081 55.4131 22.952 56.0379 22.6856C56.6626 22.4193 57.2228 22.0218 57.6806 21.5201L63.0172 22.7952C61.5623 25.9616 58.2706 27.8084 54.0939 27.8084C47.6075 27.8084 42.9701 23.5448 42.9701 17.5374H42.9742Z"
                fill="#012A38"
            />
            <path
                d="M86.477 16.0926V27.5114H80.6959V16.9736C80.6959 13.7405 79.2107 12.2655 76.6546 12.2655C73.8741 12.2655 71.8716 13.9729 71.8716 17.6425V27.5053H66.0885V1.41447L71.8696 0V9.64061C73.4296 8.08267 75.6543 7.27441 78.2125 7.27441C82.9207 7.27441 86.4811 10.0165 86.4811 16.1007L86.477 16.0926Z"
                fill="#012A38"
            />
            <path
                d="M111.725 6.63184V27.5114H106.201V25.2119C104.756 26.9537 102.64 27.8084 99.973 27.8084C94.335 27.8084 89.999 23.8035 89.999 17.5374C89.999 11.2713 94.335 7.27441 99.969 7.27441C102.416 7.27441 104.493 8.05439 105.938 9.68304V8.06651L111.721 6.65204L111.725 6.63184ZM106.051 17.5434C106.051 14.0962 103.828 12.0189 100.971 12.0189C98.114 12.0189 95.855 14.0962 95.855 17.5434C95.855 20.9907 98.078 23.0659 100.971 23.0659C103.865 23.0659 106.051 20.9846 106.051 17.5374V17.5434Z"
                fill="#012A38"
            />
            <path
                d="M129.044 7.27441V12.6049C128.561 12.5706 128.19 12.5322 127.747 12.5322C124.559 12.5322 122.443 14.274 122.443 18.0931V27.5114H116.662V7.9675L122.184 6.63184V10.1963C123.595 8.26858 125.965 7.27441 129.044 7.27441Z"
                fill="#012A38"
            />
            <path
                d="M152.767 6.63184V24.1733C152.767 31.5891 148.764 35 141.571 35C137.79 35 134.116 34.0725 131.782 32.258L134.082 28.1055C135.785 29.4775 138.568 30.3666 141.124 30.3666C145.204 30.3666 146.984 28.5137 146.984 24.9108V24.0581C145.464 25.7251 143.276 26.5415 140.681 26.5415C135.159 26.5415 130.709 22.7224 130.709 16.9009C130.709 11.0793 135.159 7.27441 140.681 7.27441C143.464 7.27441 145.761 8.2019 147.283 10.1296V7.98366L152.771 6.63588L152.767 6.63184ZM147.051 16.9069C147.051 13.977 144.864 12.0149 141.821 12.0149C138.778 12.0149 136.555 13.981 136.555 16.9069C136.555 19.8329 138.778 21.799 141.821 21.799C144.864 21.799 147.051 19.8329 147.051 16.9069Z"
                fill="#012A38"
            />
            <path
                d="M162.889 20.823C163.842 22.3809 165.564 23.173 167.872 23.173C169.987 23.173 171.509 22.5426 172.879 21.2453L175.958 24.5835C174.105 26.6991 171.323 27.8165 167.728 27.8165C160.83 27.8165 156.344 23.4802 156.344 17.5455C156.344 11.6108 160.868 7.27441 167.023 7.27441C172.535 7.27441 177.092 10.9541 177.328 17.0827L162.893 20.827M162.02 17.402L171.765 15.0762C171.21 13.0353 169.432 11.6411 167.023 11.6411C164.131 11.6471 162.02 13.4859 162.02 17.402Z"
                fill="#012A38"
            />
            <path
                d="M211.397 20.823C212.35 22.3809 214.072 23.173 216.382 23.173C218.495 23.173 220.019 22.5426 221.387 21.2453L224.466 24.5835C222.613 26.6991 219.831 27.8165 216.236 27.8165C209.338 27.8165 204.852 23.4802 204.852 17.5455C204.852 11.6108 209.376 7.27441 215.531 7.27441C221.043 7.27441 225.6 10.9541 225.836 17.0827L211.407 20.8311M210.534 17.406L220.282 15.0803C219.724 13.0394 217.946 11.6451 215.537 11.6451C212.639 11.6471 210.528 13.4859 210.528 17.402L210.534 17.406Z"
                fill="#012A38"
            />
            <path
                d="M234.586 20.823C235.54 22.3809 237.261 23.173 239.571 23.173C241.685 23.173 243.208 22.5426 244.576 21.2453L247.656 24.5835C245.803 26.6991 243.02 27.8165 239.423 27.8165C232.527 27.8165 228.041 23.4802 228.041 17.5455C228.041 11.6108 232.565 7.27441 238.72 7.27441C244.233 7.27441 248.789 10.9541 249.026 17.0827L234.596 20.8311M233.723 17.406L243.469 15.0803C242.913 13.0394 241.135 11.6451 238.726 11.6451C235.829 11.6471 233.717 13.4859 233.717 17.402L233.723 17.406Z"
                fill="#012A38"
            />
            <path
                d="M202.595 17.5374C202.595 23.8015 198.258 27.8084 192.657 27.8084C189.986 27.8084 187.84 26.9537 186.393 25.2119V27.5114H180.866V1.41447L186.656 0V9.67496C188.133 8.04226 190.172 7.27441 192.655 7.27441C198.252 7.27441 202.593 11.2773 202.593 17.5455L202.595 17.5374ZM196.735 17.5374C196.735 14.0901 194.548 12.0149 191.653 12.0149C188.757 12.0149 186.575 14.0901 186.575 17.5374C186.575 20.9846 188.763 23.0599 191.655 23.0599C194.546 23.0599 196.737 20.9846 196.737 17.5374H196.735Z"
                fill="#012A38"
            />
        </svg>
    );
};

export default Chargebee;
