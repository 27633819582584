import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

interface Header {
    // disablePadding: boolean;
    //   id: keyof Data;
    label: string;
    numeric?: boolean;
    hide?: boolean;
}

export interface EnhancedTableProps {
    headers: Header[];
    children: any;
}

const EnhancedTable = ({ headers, children }: EnhancedTableProps) => {
    return (
        <TableContainer sx={{ borderRadius: `6px 6px 0px 0px` }}>
            <Table>
                <TableHead>
                    <TableRow
                        sx={{
                            backgroundColor: `#ebebeb`,
                            whiteSpace: "nowrap",
                        }}
                    >
                        {headers.map((header: Header) => {
                            return (
                                <TableCell
                                    align={header.numeric ? "right" : "left"}
                                    sx={{
                                        color: `#555`,
                                        textTransform: `uppercase`,
                                        fontWeight: `bold`,
                                    }}
                                    key={header.label}
                                >
                                    {header.hide ? "" : header.label}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>{children}</TableBody>
            </Table>
        </TableContainer>
    );
};

export default EnhancedTable;
