import styled from "styled-components";
import { contentBox, shadowBox } from "global-style";

export const Card = styled.div<{ noShadow?: boolean; noSurface?: boolean }>`
    ${({ noShadow }) => (noShadow ? contentBox : shadowBox)}
    ${({ noSurface }) => (noSurface ? `background-color: transparent;` : ``)}
`;

export const CardHeader = styled.header``;

export const CardSection = styled.section`
    margin: 1rem 0;
`;

export const CardFooter = styled.footer`
    margin-top: 1.5em;
`;
