import styled from "styled-components";
import { colorsDeprecated as c, sizes as s } from "global-style";
import Card from "components/Card";
import Field from "components/Field";
import Anchor from "components/Anchor";
import colors from "theme/colors";

export const SearchByInvoice = styled.main`
    display: grid;
    min-height: 100vh;
    grid-template-rows: auto 1fr;
    @media screen and (min-width: ${s.md}) {
        grid-template-rows: none;
        grid-template-columns: minmax(auto, 2fr) 3fr;
    }
    @media screen and (min-width: ${s.lg}) {
        grid-template-rows: none;
        grid-template-columns: minmax(auto, 36rem) auto;
    }
`;

export const Marketing = styled.article`
    display: flex;
    flex-direction: column;
    background-image: ${c.bgGradientDark};
    background-attachment: fixed;
    color: ${colors.white};
    padding: 1rem;
    text-align: center;

    @media screen and (min-width: ${s.sm}) {
        padding: 3rem;
    }
    @media screen and (min-width: ${s.md}) {
        padding: 4rem;
        text-align: start;
    }
    @media screen and (min-width: ${s.lg}) {
        padding: 4rem;
    }
`;

export const Logo = styled.img`
    height: 2rem;
    @media screen and (min-width: ${s.sm}) {
        height: 2.5rem;
    }
`;

export const Content = styled.section`
    flex-grow: 1;
    margin-block: 1.5rem;

    @media screen and (min-width: ${s.sm}) {
        max-width: 35rem;
        margin: 0 auto;
        margin-block-start: 2rem;
    }
    @media screen and (min-width: ${s.md}) {
        margin-block-start: 5rem;
        max-width: none;
        margin: 0;
        margin-block-start: 6rem;
    }
`;

export const Slogan = styled.p`
    font-size: 1.25rem;
    margin-block: 1rem;
    @media screen and (min-width: ${s.lg}) {
        font-size: 1.5rem;
    }
`;

export const Features = styled.ul`
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: 1rem auto;
    max-width: 18rem;
    gap: 1rem;
    text-align: start;

    li {
        display: flex;
        gap: 1rem;
        width: 20rem;
        align-items: center;
        span {
            flex-grow: 1;
        }
    }

    @media screen and (min-width: ${s.sm}) {
        display: flex;
        gap: 2rem;
        margin: 2rem auto;
        max-width: 20rem;
    }

    @media screen and (min-width: ${s.md}) {
        flex-direction: column;
        max-width: ${s.sm};
        width: auto;
        margin: 3rem 0;
    }

    @media screen and (min-width: ${s.lg}) {
        max-width: 18rem;
    }
`;

export const Icon = styled.span`
    fill: ${colors.white};
    height: 2rem;
    min-height: 2rem;
    width: 2rem;
    min-width: 2rem;
`;

export const TrustedBy = styled.footer`
    @media screen and (min-width: ${s.sm}) {
        width: 100%;
        max-width: 35rem;
        margin: 0 auto;
    }
    @media screen and (min-width: ${s.md}) {
        max-width: none;
        margin: 0;
    }

    h2 {
        margin-block: 0;
        font-size: 1rem;
        @media screen and (min-width: ${s.sm}) {
            margin-block: 0 1rem;
        }
        @media screen and (min-width: ${s.lg}) {
            text-align: center;
        }
    }
`;

export const Partners = styled.ul`
    display: flex;
    flex-direction: row;
    margin: 0;
    justify-content: center;
    gap: 1rem;
    li {
        img {
            height: 1.5rem;
        }
    }

    @media screen and (min-width: ${s.sm}) {
        justify-content: space-between;
        li {
            img {
                height: 2rem;
            }
        }
    }

    @media screen and (min-width: ${s.md}) {
        flex-direction: column;
    }

    @media screen and (min-width: ${s.lg}) {
        flex-direction: row;
    }
`;

export const InvoiceSearch = styled.article`
    display: grid;
    place-content: center;
    padding: 1rem;
    @media screen and (min-width: ${s.sm}) {
        padding: 3rem;
    }
    @media screen and (min-width: ${s.lg}) {
        padding: 4rem;
    }
`;

export const Form = styled(Card)`
    max-width: 30rem;
    h1 {
        margin-block: 1rem;
    }
`;

export const FormFields = styled(Field)`
    margin-block: 1rem;
`;

export const Help = styled(Anchor)`
    margin-block: 1rem;
`;
