import * as S from "./style";
import { memo, useCallback, useEffect, useReducer } from "react";
import { formatUnits } from "ethers";
import { Company } from "company/types";
import { GeneralTokenDetailsResponse } from "api";
import Button, { ButtonSizes, ButtonVariants } from "components/Button";
import TokenAllowance from "components/TokenAllowance";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import { SmartContractType } from "types/common-enums";
import { useWallet } from "context/Wallet";
import { toNetworkHex } from "utils/addresses";
import { toCoin } from "utils/financial";

interface BalanceAndAllowanceCellProps {
    token: GeneralTokenDetailsResponse;
    walletAddress: string;
}

enum WalletDataState {
    IDLE = "idle",
    LOADING = "loading",
    LOADED = "loaded",
    ERROR = "error",
}

type Action =
    | { type: "SET_LOADING" }
    | { type: "SET_LOADED"; balance: number; allowance: string }
    | { type: "SET_ERROR" }
    | { type: "SET_ALLOWANCE"; allowance: string }
    | { type: "SET_BALANCE"; balance: number };

type State = {
    balance: number | null;
    allowance: string | null;
    state: WalletDataState;
};

const initialState: State = {
    balance: null,
    allowance: null,
    state: WalletDataState.IDLE,
};

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "SET_LOADING":
            return {
                ...state,
                state:
                    state.state !== WalletDataState.ERROR
                        ? WalletDataState.LOADING
                        : WalletDataState.ERROR,
            };
        case "SET_ALLOWANCE":
            return { ...state, allowance: action.allowance };
        case "SET_BALANCE":
            return { ...state, balance: action.balance };
        case "SET_LOADED":
            return {
                balance: action.balance,
                allowance: action.allowance,
                state: WalletDataState.LOADED,
            };
        case "SET_ERROR":
            return {
                ...state,
                state: WalletDataState.ERROR,
            };
        default:
            return state;
    }
};

const BalanceAndAllowanceCell = memo(
    ({ token, walletAddress }: BalanceAndAllowanceCellProps) => {
        const [{ state, balance, allowance }, dispatch] = useReducer(
            reducer,
            initialState
        );
        const {
            walletConnected,
            getTokenBalance,
            getTokenAllowance,
            hasTokenBalanceStored,
            hasTokenAllowanceStored,
        } = useWallet();

        const {
            config: { contracts },
        } = useGetCompanyConfig();

        const contract = contracts.find(
            (c: Company.Contract) =>
                c.contractType === SmartContractType.VariableRate &&
                c.networkId === token.networkId
        );

        const senderWalletIsConnectedWallet =
            walletConnected &&
            walletAddress.toLocaleLowerCase() ===
                walletConnected?.address.toLocaleLowerCase()
                ? true
                : false;

        const fetchBalance = useCallback(async () => {
            try {
                const balance = await getTokenBalance({
                    tokenAddress: token.address,
                    walletAddress: walletAddress,
                    networkId: toNetworkHex(token.networkId),
                });

                dispatch({ type: "SET_BALANCE", balance: Number(balance) });
            } catch (error) {
                console.error("Error fetching balance:", error);
                dispatch({ type: "SET_ERROR" });
            }
        }, [token.address, token.networkId, walletAddress, getTokenBalance]);

        const fetchAllowance = useCallback(async () => {
            if (!contract?.address) {
                dispatch({ type: "SET_ALLOWANCE", allowance: "-" });
                return;
            }

            try {
                const allowanceRaw = await getTokenAllowance({
                    tokenAddress: token.address,
                    contractAddress: contract.address,
                    walletAddress: walletAddress,
                    networkId: toNetworkHex(token.networkId),
                });
                const allowance = formatUnits(allowanceRaw, token.decimals);

                dispatch({ type: "SET_ALLOWANCE", allowance });
            } catch (error) {
                console.error("Error fetching authorization:", error);
                dispatch({ type: "SET_ERROR" });
            }
        }, [
            token.address,
            token.networkId,
            token.decimals,
            contract?.address,
            walletAddress,
            getTokenAllowance,
        ]);

        const handleClick = useCallback(async () => {
            dispatch({ type: "SET_LOADING" });
            fetchBalance();
            fetchAllowance();
        }, [fetchBalance, fetchAllowance]);

        useEffect(() => {
            // A balance was updated, if one matches this network-wallet-token, update the state
            if (
                hasTokenBalanceStored({
                    tokenAddress: token.address,
                    walletAddress: walletAddress,
                    networkId: toNetworkHex(token.networkId),
                })
            ) {
                fetchBalance();
            }
        }, [
            token.address,
            token.networkId,
            walletAddress,
            hasTokenBalanceStored,
            fetchBalance,
        ]);

        useEffect(() => {
            if (!contract?.address) return;

            // An allowance was updated, if one matches this network-wallet-token-contract, update the state
            if (
                hasTokenAllowanceStored({
                    tokenAddress: token.address,
                    contractAddress: contract?.address,
                    walletAddress: walletAddress,
                    networkId: toNetworkHex(token.networkId),
                })
            ) {
                fetchAllowance();
            }
        }, [
            token.address,
            token.networkId,
            contract?.address,
            walletAddress,
            hasTokenAllowanceStored,
            fetchAllowance,
        ]);

        useEffect(() => {
            if (
                balance !== null &&
                allowance !== null &&
                state !== WalletDataState.ERROR
            ) {
                dispatch({
                    type: "SET_LOADED",
                    balance: balance,
                    allowance: allowance,
                });
            }
        }, [balance, allowance, state]);

        if (
            state === WalletDataState.LOADED &&
            allowance !== null &&
            balance !== null
        ) {
            return (
                <S.DefinitionList>
                    <div>
                        <dt>Authorization:</dt>{" "}
                        <dd>
                            {!contract ? (
                                <S.NoBr>Error (No contract)</S.NoBr>
                            ) : senderWalletIsConnectedWallet ? (
                                <TokenAllowance
                                    token={token}
                                    contract={contract.address}
                                    icons
                                />
                            ) : (
                                <>
                                    <span>{token.symbol}</span>
                                    <span>{toCoin(allowance)}</span>
                                </>
                            )}
                        </dd>
                    </div>
                    <div>
                        <dt>Balance:</dt>{" "}
                        <dd>
                            <span>{token.symbol}</span>
                            <span>{toCoin(balance)}</span>
                        </dd>
                    </div>
                </S.DefinitionList>
            );
        }

        return (
            <>
                <Button
                    variant={ButtonVariants.NeutralOutlined}
                    size={ButtonSizes.Small}
                    onClick={handleClick}
                    loading={state === WalletDataState.LOADING}
                >
                    Get Data
                </Button>
            </>
        );
    }
);
export default BalanceAndAllowanceCell;
