import styled from "styled-components";
import { colorsDeprecated as c, properties as p } from "global-style";
import Form from "components/Form";
import Label from "components/Label";
import colors from "theme/colors";
import InputWithAddon from "components/InputWithAddon";

export const WrapNativeTokenForm = styled(Form)`
    background-color: ${colors.neutralBackground};
    border: 0.25rem solid ${colors.white};
    border-radius: ${p.radiusLg};
    padding: 1rem;
    position: relative;
    border: 1px solid ${c.border};
`;

export const WrapField = styled.fieldset`
    display: flex;
    gap: 1rem;
    width: 100%;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 1rem;
    align-items: start;
    input {
        text-align: right;
        max-width: 8rem;
    }
`;

export const WrapLabel = styled(Label)`
    font-weight: 300;
    margin: 0;
`;

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    text-align: end;
    white-space: nowrap;
`;

export const InputWrapAmount = styled(InputWithAddon)`
    width: fit-content;
`;

export const CancelWrapper = styled.span`
    position: absolute;
    top: 0.75rem;
    right: 1rem;
`;
