import styled from "styled-components";
import Form from "components/Form";
import colors from "theme/colors";
import Title from "components/Title";

export const Article = styled.article`
    &:not(:last-child) {
        margin-bottom: 2rem;
    }
`;

export const Notes = styled.p`
    white-space: pre-wrap;
`;

export const EmptyNotes = styled.p`
    font-style: italic;
`;

export const Header = styled(Title)`
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    color: ${colors.text};
    width: 100%;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${colors.borderLight};
`;

export const Row = styled.li`
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 5fr;
    margin-block: 0.75rem;
    align-items: start;
`;

export const RowValue = styled.dd`
    display: grid;
    gap: 0.5rem;
    align-items: start;
`;
export const RowBadgeContainer = styled.dd`
    gap: 0.5rem;
    display: flex;
    align-items: center;
`;

export const AmountWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
`;

export const EditForm = styled(Form)`
    display: flex;
    gap: 1rem;
    align-items: center;
`;
