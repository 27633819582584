import styled from "styled-components";
import Field from "components/Field";
import Hint from "components/Hint";

export const EntityCompanyNameField = styled(Field)`
    align-items: center;
`;

export const ErrorMsg = styled(Hint)`
    display: block;
`;
