import { BlockExplorerEntityType } from "utils/urls";
import { EnsAddressProps, useEnsOrAddress } from "./useEnsOrAddress";
import DynamicAddressDisplay, {
    DynamicAddressDisplayProps,
} from "components/DynamicAddressDisplay";

interface DynamicWalletAddressDisplayProps
    extends EnsAddressProps,
        Omit<DynamicAddressDisplayProps, "address" | "ens" | "type"> {
    type?: BlockExplorerEntityType.Address;
}

const DynamicWalletAddressDisplay = ({
    children,
    address,
    ensName,
    networkId,
    loading,
    ...dynamicAddressDisplayProps
}: DynamicWalletAddressDisplayProps) => {
    const { addressOrEns } = useEnsOrAddress({ address, ensName, loading });

    return (
        <DynamicAddressDisplay
            address={address}
            ens={addressOrEns || undefined}
            networkId={networkId}
            {...dynamicAddressDisplayProps}
        >
            {children}
        </DynamicAddressDisplay>
    );
};

export default DynamicWalletAddressDisplay;
