import React from "react";
import Tooltip from "components/Tooltip";
import * as S from "./styles";
import { firstToUpper } from "utils/strings";
import { GeneralTokenDetailsResponse } from "api";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import { useGetNetworks } from "hooks/useGetNetworks";

interface TokenTableCellProps {
    token: GeneralTokenDetailsResponse;
    showNetwork?: boolean;
}

const TokenTableCell: React.FunctionComponent<TokenTableCellProps> = ({
    token,
    showNetwork = true,
}) => {
    const {
        config: { contracts },
    } = useGetCompanyConfig();
    const { networks } = useGetNetworks();

    const tokenNetwork =
        token && networks.find((network) => network.id === token.networkId);
    const showTokenNetwork =
        showNetwork && tokenNetwork && contracts.length > 1;

    return (
        <Tooltip
            placement="bottom"
            title={token.address}
            key={`${token.networkId}-${token.symbol}-${token.address}`}
        >
            <span>
                {token.symbol}
                {showTokenNetwork && (
                    <S.NetworkContainer>
                        {" "}
                        ({firstToUpper(tokenNetwork.name)})
                    </S.NetworkContainer>
                )}
            </span>
        </Tooltip>
    );
};

export default TokenTableCell;
