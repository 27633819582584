import {
    createContext,
    ReactNode,
    useState,
    useContext,
    useMemo,
    useEffect,
} from "react";
import { firstToUpper } from "utils/strings";
import { SelectOption } from "components/Select";
import { CheckoutNetwork } from "checkout/types";
import { ExchangableToken, NetworkOnChain } from "types/common";
import { useWallet } from "context/Wallet";
import { hasMultipleUniqueValues } from "utils/arrays";

interface NetworkAndTokenProps {
    children: ReactNode;
    tokens: ExchangableToken[];
    networks: NetworkOnChain[];
    tokenFilter?: (token: ExchangableToken) => boolean;
    networkFilter?: (network: NetworkOnChain) => boolean;
    onNetworkChange?: (networkId: string) => void;
    onTokenChange?: (tokenAddress: string) => void;
}

interface NetworkAndTokenValue {
    networkOptions: SelectOption<string>[];
    tokenOptions: SelectOption<string>[];
    networkValue: string;
    tokenValue: string;
    handleNetworkValueChange: (network: string) => Promise<void>;
    handleTokenValueChange: (token: string) => Promise<void>;
}

const NetworkAndTokenContext = createContext<NetworkAndTokenValue>(
    {} as NetworkAndTokenValue
);

const getNetworkHexIdIfNetworkAvailable = (
    connectedOn: string | undefined,
    networks: CheckoutNetwork[]
) => {
    return connectedOn && networks.find(({ hexId }) => hexId === connectedOn)
        ? connectedOn
        : ``;
};

const NetworkAndTokenProvider = ({
    children,
    tokens,
    networks,
    tokenFilter = () => true,
    networkFilter = () => true,
    onNetworkChange,
    onTokenChange,
}: NetworkAndTokenProps) => {
    const { walletConnected, networkConnected, setConnectedNetwork } =
        useWallet();

    const [networkValue, setNetworkValue] = useState<string>(
        getNetworkHexIdIfNetworkAvailable(networkConnected?.networkId, networks)
    );
    const [tokenValue, setTokenValue] = useState<string>(``);

    const { availableNetworks, networkOptions } = useMemo(() => {
        const availableNetworks = networks.filter(networkFilter);

        // Sort options on the chain the wallet is connected to to the top
        if (hasMultipleUniqueValues(availableNetworks, "chain")) {
            availableNetworks.sort((a, b) => {
                if (a.chain === walletConnected?.chain) return -1;
                if (b.chain === walletConnected?.chain) return 1;
                return 0;
            });
        }

        return {
            availableNetworks,
            networkOptions: availableNetworks.map(({ hexId, name, chain }) => ({
                value: hexId,
                label: `${firstToUpper(name)}${
                    chain !== walletConnected?.chain
                        ? ` (Switch to compatible wallet)`
                        : ``
                }`,
                disabled: chain !== walletConnected?.chain,
            })) as SelectOption<string>[],
        };
    }, [networks, networkFilter, walletConnected?.chain]);

    const tokenOptions: SelectOption<string>[] = useMemo(() => {
        return tokens
            .filter(({ networkId }) => networkId === parseInt(networkValue))
            .filter(tokenFilter)
            .map(({ symbol, address }) => ({
                value: address,
                label: symbol,
            }));
    }, [tokens, networkValue, tokenFilter]);

    // When wallet connects/changes, set the network dropdown to match the wallet's network
    useEffect(() => {
        if (!walletConnected?.address || !networkConnected?.networkId) {
            setNetworkValue(``);
            return;
        }

        const hexId = getNetworkHexIdIfNetworkAvailable(
            networkConnected.networkId,
            availableNetworks
        );

        setNetworkValue(hexId);
    }, [
        walletConnected?.address,
        networkConnected?.networkId,
        availableNetworks,
    ]);

    // When network updates, notify the form provider
    useEffect(() => {
        if (!onNetworkChange) return;
        onNetworkChange(networkValue);
    }, [networkValue, onNetworkChange]);

    // When token updates, notify the form provider
    useEffect(() => {
        if (!onTokenChange) return;
        onTokenChange(tokenValue);
    }, [tokenValue, networkValue, onTokenChange]);

    const handleNetworkValueChange = async (updatedNetworkHexId: string) => {
        if (!walletConnected) {
            setNetworkValue(updatedNetworkHexId);
            return;
        }

        setConnectedNetwork({ networkId: updatedNetworkHexId }).then(
            (didChange: boolean) => {
                // If wallet wasn't changed, update the network dropdown to match the network
                setNetworkValue(
                    didChange
                        ? updatedNetworkHexId
                        : getNetworkHexIdIfNetworkAvailable(
                              networkConnected?.networkId,
                              availableNetworks
                          )
                );
                return;
            }
        );
    };

    const handleTokenValueChange = async (updatedTokenAddress: string) => {
        setTokenValue(updatedTokenAddress);
    };

    return (
        <NetworkAndTokenContext.Provider
            value={{
                networkOptions,
                tokenOptions,
                networkValue,
                tokenValue,
                handleNetworkValueChange,
                handleTokenValueChange,
            }}
        >
            {children}
        </NetworkAndTokenContext.Provider>
    );
};

const useNetworkAndToken = (): NetworkAndTokenValue => {
    const context = useContext(NetworkAndTokenContext);
    if (context === undefined) {
        throw new Error(
            `useNetworkAndToken() must be used within a NetworkAndTokenProvider`
        );
    }
    return context;
};

export { NetworkAndTokenProvider, useNetworkAndToken };
