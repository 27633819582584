export const sumArray = (array: number[]): number => {
    return array.reduce((partialSum, a) => partialSum + a, 0);
};

export const arrayEqualsCheck = (a: any, b: any): boolean => {
    // If they point to the same instance of the array
    if (a === b) return true;

    // If they point to the same instance of date
    if (a instanceof Date && b instanceof Date)
        return a.getTime() === b.getTime();

    // If both of them are not null and their type is not an object
    if (!a || !b || (typeof a !== "object" && typeof b !== "object"))
        return a === b;

    // This means the elements are objects
    // If they are not the same type of objects
    if (a.prototype !== b.prototype) return false;

    // Check if both of the objects have the same number of keys
    const keys = Object.keys(a);
    if (keys.length !== Object.keys(b).length) return false;

    // Check recursively for every key in both
    // @ts-ignore
    return keys.every((k) => arrayEqualsCheck(a[k], b[k]));
};

export function onlyUnique(value: any, index: number, array: any[]) {
    return array.indexOf(value) === index;
}

export function uniqueArray<T extends Record<K, unknown>, K extends keyof T>(
    arr: T[],
    key: K
): T[] {
    const uniqueSet = new Set<T[K]>();

    return arr.filter((obj) => {
        const value = obj[key];
        if (!uniqueSet.has(value)) {
            uniqueSet.add(value);
            return true;
        }
        return false;
    });
}

// Identical to the above `uniqueArray`, but returns a callback for use this way: arr.filter(uniqueByKey("propName"))
export const uniqueByKey = <T extends Record<K, unknown>, K extends keyof T>(
    key: K
) => {
    const uniqueSet = new Set<T[K]>();
    return (obj: T): boolean => {
        const value = obj[key];
        if (!uniqueSet.has(value)) {
            uniqueSet.add(value);
            return true;
        }
        return false;
    };
};

// Check if every value in an array is one of the values of an enum
export const isArrayOfEnumValues = (
    arrayToCheck: any[],
    enumeration: object
): boolean => {
    if (!Array.isArray(arrayToCheck)) return false;
    return arrayToCheck.every((value) =>
        Object.values(enumeration).includes(value)
    );
};

// Check if every value in an array is one of the properties of an enum
export const isArrayOfEnumProperties = (
    arrayToCheck: any[],
    enumeration: object
): boolean => {
    if (!Array.isArray(arrayToCheck)) return false;
    return arrayToCheck.every((value) => value in enumeration);
};

export const enumToNumArray = (enumeration: object): number[] => {
    return Object.values(enumeration)
        .filter((value) => Number.isFinite(value))
        .map(Number);
};

type Predicate<T> = (item: T) => boolean;
export const extractObjFromArray = <T,>(
    array: T[],
    predicate: Predicate<T>
): { found: T | undefined; remaining: T[] } => {
    const found = array.find(predicate);
    const remaining = found ? array.filter((item) => item !== found) : array;

    return { found, remaining };
};

export const hasMultipleUniqueValues = <T, K extends keyof T>(
    items: T[],
    property: K,
    threshold: number = 1
): boolean => {
    const valueCount = items.reduce<Record<string, number>>((acc, item) => {
        const value = String(item[property]); // Convert value to string to use as object key
        acc[value] = (acc[value] || 0) + 1;
        return acc;
    }, {});

    return Object.keys(valueCount).length > threshold;
};

export const isUint8Array = (value: any): value is Uint8Array => {
    return value instanceof Uint8Array;
};

export type PropertySelector<T> = (keyof T)[];
type Matcher<T> = (item: T) => boolean;
export const extractPartials = <T extends Record<string, unknown>>(
    items: T[],
    match: Matcher<T>,
    properties?: PropertySelector<T>
): Partial<T>[] => {
    return items
        .filter(match)
        .map((item) =>
            properties
                ? (Object.fromEntries(
                      properties
                          .filter((prop) => prop in item)
                          .map((prop) => [prop, item[prop]])
                  ) as Partial<T>)
                : item
        )
        .filter((partial) => Object.keys(partial).length > 0);
};
