import React from "react";

const Lock = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            {...props}
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 22 29"
        >
            {name && <title>{name}</title>}
            <path
                d="M18.9999 9.66667h-1.3333V7c0-3.68-2.9867-6.666664-6.6667-6.666664C7.31992.333336 4.33325 3.32 4.33325 7v2.66667H2.99992c-1.46667 0-2.666668 1.20003-2.666668 2.66663v13.3334c0 1.4666 1.199998 2.6666 2.666668 2.6666H18.9999c1.4667 0 2.6667-1.2 2.6667-2.6666V12.3333c0-1.4666-1.2-2.66663-2.6667-2.66663ZM6.99992 7c0-2.21333 1.78667-4 3.99998-4 2.2134 0 4 1.78667 4 4v2.66667H6.99992V7ZM18.9999 25.6667H2.99992V12.3333H18.9999v13.3334Zm-8-4c1.4667 0 2.6667-1.2 2.6667-2.6667 0-1.4667-1.2-2.6667-2.6667-2.6667-1.46665 0-2.66665 1.2-2.66665 2.6667 0 1.4667 1.2 2.6667 2.66665 2.6667Z"
                fill="inherit"
            />
        </svg>
    );
};

export default Lock;
