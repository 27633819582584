import styled, { createGlobalStyle } from "styled-components";
import { colorsDeprecated as c, properties as p, fade } from "global-style";

export const GlobalStyle = createGlobalStyle`
    body {
        overflow: hidden;
        
        /* If the page has a scroll, we can add 15px to minimize the jank, though it's hardly noticeable. 
        The best solution would be a @container query, but support is currently limited. Leaving this
        note here so we can perhaps revisit this down the road - not a priority at this time. */
        // padding-right: 15px;
    }
`;

export const Modal = styled.dialog<{ gradient: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: ${p.zIndex.modal};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    padding: 2rem;
    margin: 0;
    background-color: ${c.modalA};
    overflow: auto;
    ${({ gradient }) =>
        gradient &&
        `background-image: linear-gradient(180deg, ${c.modalB}, ${c.modalC});`}
    animation: ${fade} 0.2s ease;
`;
