import * as S from "./style";
import LoadingLogo from "components/LoadingLogo";

const Loading = ({ ...props }) => {
    return (
        <S.Loading {...props}>
            <LoadingLogo />
        </S.Loading>
    );
};

export default Loading;
