import * as S from "./style";
import { forwardRef, useImperativeHandle, useState, Ref, useRef } from "react";
import Label from "components/Label";
import Input from "components/Input";
import Select from "components/Select";
import Field from "components/Field";
import {
    validatePositiveNumber,
    validateZeroOrHigher,
} from "utils/formValidation";
import { CompanyFrequency } from "company/types";
import { ItemFrequencyNames } from "utils/items";

export interface ItemFrequencyFieldProps {
    disabled?: boolean;
    defaultFrequency?: CompanyFrequency;
    defaultFrequencyCount?: number;
}

export type ItemFrequencyFieldRef = {
    frequencyCount: number;
    frequency: CompanyFrequency;
    validate: () => boolean;
};

function ItemFrequencyField(
    {
        disabled,
        defaultFrequency = CompanyFrequency.Week,
        defaultFrequencyCount = 1,
    }: ItemFrequencyFieldProps,
    ref: Ref<ItemFrequencyFieldRef>
) {
    const frequencyCountRef = useRef<HTMLInputElement>(null);
    const [frequency, setFrequency] =
        useState<CompanyFrequency>(defaultFrequency);
    const [frequencyCount, setFrequencyCount] = useState<string>(
        defaultFrequencyCount.toString()
    );

    const frequencyTypeOptions = (
        Object.keys(ItemFrequencyNames) as Array<
            keyof typeof ItemFrequencyNames
        >
    ).map((key) => {
        const label = ItemFrequencyNames[key] as string;
        return {
            value: label,
            label: key,
        };
    });

    const frequencyOptions = (
        Object.keys(CompanyFrequency) as Array<keyof typeof CompanyFrequency>
    ).map((key) => {
        const label = CompanyFrequency[key] as string;
        return {
            value: label,
            label: key,
        };
    });

    const validate = () => {
        if (!frequencyCountRef.current) {
            return false;
        }

        return validateZeroOrHigher({
            input: frequencyCountRef.current,
        });
    };

    useImperativeHandle(ref, () => ({
        frequencyCount: parseInt(frequencyCount),
        frequency,
        validate,
    }));

    return (
        <Field disabled={disabled}>
            <Label
                htmlFor="frequencyCount"
                subLabel="How often the customer is charged"
            >
                Payment frequency
            </Label>
            <S.FrequencyInputsContainer>
                <Input
                    type="number"
                    disabled={disabled}
                    name="frequencyCount"
                    value={frequencyCount}
                    innerRef={frequencyCountRef}
                    onChange={(event) => setFrequencyCount(event.target.value)}
                    onBlur={(event) =>
                        validatePositiveNumber({
                            input: event.target,
                            reportValidity: false,
                        })
                    }
                    step="1"
                    min="1"
                    placeholder=""
                />
                <Select
                    disabled={disabled}
                    name="frequency"
                    value={frequency}
                    options={frequencyOptions}
                    onChange={(event) =>
                        setFrequency(event.target.value as CompanyFrequency)
                    }
                />
            </S.FrequencyInputsContainer>
        </Field>
    );
}
export default forwardRef(ItemFrequencyField);
