import { Spacing } from "theme/spacing";
import * as S from "./styles";

interface ParagraphProps extends React.HTMLAttributes<HTMLTimeElement> {
    bold?: boolean;
    small?: boolean;
    spacing?: Spacing;
}

const Paragraph: React.FC<ParagraphProps> = ({
    children,
    bold = false,
    small = false,
    spacing,
}) => (
    <S.Paragraph $bold={bold} $small={small} $spacing={spacing}>
        {children}
    </S.Paragraph>
);

export default Paragraph;
