import { FunctionComponent } from "react";
import * as S from "./styles";

export enum HintType {
    Default = `default`,
    Info = `info`,
    Success = `success`,
    Warning = `warning`,
    Error = `error`,
}

interface HintProps {
    children: React.ReactNode;
    className?: string;
    type?: HintType;
}

const Hint: FunctionComponent<HintProps> = ({
    children,
    className,
    type = HintType.Default,
}) => {
    return (
        <S.Hint className={className} type={type}>
            {children}
        </S.Hint>
    );
};

export default Hint;
