import * as S from "./style";
import {
    forwardRef,
    useImperativeHandle,
    useState,
    Ref,
    useRef,
    useEffect,
} from "react";
import Label from "components/Label";
import Input from "components/Input";
import { validatePresence, validateStringLength } from "utils/formValidation";
import { SelfServeNewUser } from "api";
import { HintType } from "components/Hint";
import { Spacing } from "theme/spacing";

export interface UserPasswordFieldProps {
    disabled?: boolean;
    label?: React.ReactNode;
    defaultPassword?: string;
    onChange?: (password: string) => void;
}

export type UserPasswordFieldRef = {
    password: string;
    validate: () => boolean;
    isValid: boolean;
};

export const MIN_CHARACTER_COUNT = 8;
function UserPasswordField(
    {
        disabled,
        label = "Password",
        defaultPassword = "",
        onChange,
    }: UserPasswordFieldProps,
    ref: Ref<UserPasswordFieldRef>
) {
    const [password, setPassword] =
        useState<SelfServeNewUser["password"]>(defaultPassword);
    const [showHint, setShowHint] = useState<boolean>(false);
    const passwordRef = useRef<HTMLInputElement>(null);
    const validationMessage = "Please enter a valid password";

    const isValid = password.length >= MIN_CHARACTER_COUNT;

    const validate = () => {
        if (!passwordRef.current) {
            return false;
        }

        const valid = validatePresence({
            input: passwordRef.current,
            errorMessage: validationMessage,
        });

        if (!valid) {
            setShowHint(true);
        }

        return valid;
    };
    useImperativeHandle(ref, () => ({ password, validate, isValid }));

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const valid = validateStringLength({
            input: event.target,
            errorMessage: validationMessage,
            reportValidity: false,
            min: MIN_CHARACTER_COUNT,
        });

        if (!valid) {
            setShowHint(true);
        }

        return valid;
    };

    useEffect(() => {
        if (onChange) onChange(password);
        setShowHint(false);
    }, [onChange, password]);

    return (
        <S.UserPasswordField disabled={disabled} spacing={[Spacing.md]}>
            <Label htmlFor="password">{label}</Label>
            <Input
                innerRef={passwordRef}
                type="password"
                name="password"
                placeholder="******** (8 characters minimum)"
                value={password}
                disabled={disabled}
                onChange={(event) => setPassword(event.target.value)}
                onBlur={handleBlur}
            />
            {showHint && (
                <S.Error type={HintType.Error}>
                    Minimum {MIN_CHARACTER_COUNT} characters
                </S.Error>
            )}
        </S.UserPasswordField>
    );
}
export default forwardRef(UserPasswordField);
