import styled from "styled-components";
import { sizes } from "global-style";
import { Label } from "components/Label/styles";
import { Input } from "components/Input/styles";
import Form from "components/Form";
import spacing from "theme/spacing";
import { UserEmailField } from "../UserEmailField/style";
import { UserPasswordField } from "../UserPasswordField/style";

export const SelfServeNewUserForm = styled(Form)`
    margin-block: ${spacing.lg};
    @media screen and (min-width: ${sizes.md}) {
        ${UserPasswordField}, ${UserEmailField} {
            display: flex;
            ${Label} {
                flex: 1;
                margin: 0;
            }
            ${Input} {
                flex-basis: 50%;
                align-self: center;
            }
        }
    }
`;
