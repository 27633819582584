import {
    forwardRef,
    useImperativeHandle,
    useState,
    Ref,
    useEffect,
} from "react";
import { Company } from "company/types";
import Label from "components/Label";
import Field from "components/Field";
import Select from "components/Select";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";

export interface ItemEntityFieldProps {
    disabled: boolean;
    onChangeEntityId?: (entityId: string) => void;
}

export type ItemEntityFieldRef = {
    entityId: string;
};

// It's useful to have a <ItemEntityField> from a usability standpoint
function ItemEntityField(
    { disabled, onChangeEntityId }: ItemEntityFieldProps,
    ref: Ref<ItemEntityFieldRef>
) {
    const {
        config: { entities },
    } = useGetCompanyConfig();

    const entityIdOptions = entities.map((entity: Company.Entity) => {
        return {
            value: entity.entityId,
            label: entity.name,
        };
    });
    const defaultEntityId = entityIdOptions[0].value;
    const [entityId, setEntityId] = useState<string>(defaultEntityId);

    useImperativeHandle(ref, () => ({ entityId }));

    useEffect(() => {
        if (onChangeEntityId) {
            onChangeEntityId(entityId);
        }
    }, [entityId, onChangeEntityId]);

    return (
        <Field disabled={disabled}>
            <Label htmlFor="entityId">Entity</Label>
            <Select
                value={entityId}
                disabled={disabled}
                onChange={(event) => setEntityId(event.target.value)}
                options={entityIdOptions}
                name="entityId"
            />
        </Field>
    );
}
export default forwardRef(ItemEntityField);
