import { useEffect, useState } from "react";
import { CheckoutItemResponse } from "api/types/checkout";
import { BaseItem } from "types/common";
import {
    suggestedAllowanceAmountForCheckout,
    calculateSuggestedAllowance,
    DiscountInfo,
} from "utils/checkout";

export const useSuggestedAllowance = (
    invoiceAmount: number | undefined,
    items: CheckoutItemResponse[] | BaseItem[],
    minimumBalanceRequired: number | undefined,
    defaultSpendingCap: number | undefined,
    coupons: CouponResponse[] = []
) => {
    const [suggestedAllowance, setSuggestedAllowance] = useState<number>(0);

    useEffect(() => {
        const discountInfo: DiscountInfo = {
            discountPercentage: coupons[0]?.discountPercentage ?? null,
            discountAmount: coupons[0]?.discountAmount ?? null,
            singleUse: coupons[0]?.singleUse ?? false,
        };

        setSuggestedAllowance(
            suggestedAllowanceAmountForCheckout(
                calculateSuggestedAllowance(
                    invoiceAmount,
                    items,
                    minimumBalanceRequired,
                    defaultSpendingCap,
                    discountInfo
                )
            )
        );
    }, [
        invoiceAmount,
        items,
        minimumBalanceRequired,
        defaultSpendingCap,
        coupons,
    ]);

    return suggestedAllowance;
};
