import { reactAppBuildType } from "default-variables";
import * as S from "./style";

const EnvironmentTag = ({ ...props }) => {
    let Tag = <></>;
    if (reactAppBuildType === `local`) {
        Tag = (
            <S.EnvBadge variant="blue" uppercase border {...props}>
                Local
            </S.EnvBadge>
        );
    } else if (reactAppBuildType === `development`) {
        Tag = (
            <S.EnvBadge variant="green" uppercase border {...props}>
                Develop
            </S.EnvBadge>
        );
    } else if (reactAppBuildType === `staging`) {
        Tag = (
            <S.EnvBadge variant="yellow" uppercase border {...props}>
                Staging
            </S.EnvBadge>
        );
    } else if (reactAppBuildType === `demo`) {
        Tag = (
            <S.EnvBadge variant="red" uppercase border {...props}>
                Demo
            </S.EnvBadge>
        );
    }
    return Tag;
};

export default EnvironmentTag;
