import {
    forwardRef,
    useImperativeHandle,
    useState,
    Ref,
    useCallback,
    useMemo,
} from "react";
import { AcceptedTokensPayload } from "company/types";
import Label from "components/Label";
import Field from "components/Field";
import ItemAcceptedTokensForNetworkField from "company/components/items/ItemAcceptedTokensForNetworkField";
import { GeneralTokenDetailsResponse } from "api";
import { ItemNetwork } from "company/utils/items";

export interface ItemAcceptedTokensFieldProps {
    disabled?: boolean;
    itemNetworks: ItemNetwork[];
}

export type ItemAcceptedTokensFieldRef = {
    acceptedTokensByNetwork: AcceptedTokensPayload;
};

function ItemAcceptedTokensField(
    { disabled, itemNetworks }: ItemAcceptedTokensFieldProps,
    ref: Ref<ItemAcceptedTokensFieldRef>
) {
    const defaultSelectedTokens = useMemo(() => {
        return itemNetworks
            .map((itemNetwork) => itemNetwork.selectedTokens)
            .flat();
    }, [itemNetworks]);

    const [selectedTokens, setSelectedTokens] = useState<
        GeneralTokenDetailsResponse[]
    >(defaultSelectedTokens);

    const updateAcceptedTokensForNetworkId = useCallback(
        (
            tokens: GeneralTokenDetailsResponse[],
            networkId: GeneralTokenDetailsResponse["networkId"]
        ) => {
            // Keep the other tokens
            const otherAcceptedTokens = selectedTokens.filter(
                (t) => t.networkId !== networkId
            );

            setSelectedTokens([...otherAcceptedTokens, ...tokens]);
        },
        [selectedTokens]
    );

    const itemNetworksSorted = itemNetworks.sort((a, b) => {
        // First, sort by the 'disabled' field
        if (a.disabled && !b.disabled) {
            return 1; // Move disabled items to the front
        } else if (!a.disabled && b.disabled) {
            return -1; // Move enabled items to the back
        } else {
            // If both items have the same 'disabled' status, sort by 'network.id'
            return a.network.id - b.network.id;
        }
    });

    // Accessible from the parent components
    const acceptedTokensByNetwork: AcceptedTokensPayload = useMemo(() => {
        let result: AcceptedTokensPayload = {};
        selectedTokens.map((token) => {
            if (result[token.networkId]) {
                result[token.networkId].push(token.address);
            } else {
                result[token.networkId] = [token.address];
            }
        });
        return result;
    }, [selectedTokens]);

    useImperativeHandle(ref, () => ({ acceptedTokensByNetwork }));

    return (
        <Field disabled={disabled}>
            <Label subLabel="Loop works with any ERC-20 tokens.">
                Accepted tokens
            </Label>
            {itemNetworksSorted.map((itemNetwork) => (
                <ItemAcceptedTokensForNetworkField
                    key={itemNetwork.network.id}
                    itemNetwork={itemNetwork}
                    disabled={disabled || itemNetwork.disabled}
                    selectedTokens={selectedTokens}
                    onChange={(tokens) =>
                        updateAcceptedTokensForNetworkId(
                            tokens,
                            itemNetwork.network.id
                        )
                    }
                />
            ))}
        </Field>
    );
}
export default forwardRef(ItemAcceptedTokensField);
