import styled from "styled-components";
import { colorsDeprecated as c } from "global-style";

export const Warning = styled.strong`
    display: block;
    color: ${(props) => (props.color ? props.color : c.error)};
    margin: 1rem 0;
    text-align: center;

    &::before {
        content: "";
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
        margin-right: 0.5rem;
        background-image: ${(props) => (props.color ? props.color : c.error)};
        mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.64 16.36L11.53 2.3A1.85 1.85 0 0 0 10 1.21 1.85 1.85 0 0 0 8.48 2.3L.36 16.36C-.48 17.81.21 19 1.88 19h16.24c1.67 0 2.36-1.19 1.52-2.64zM11 16H9v-2h2zm0-4H9V6h2z'/%3E%3C/svg%3E");
    }
`;
