import * as S from "./style";
import { ReactNode } from "react";

export interface SubmenuItem {
    to: string;
    label: ReactNode;
}

interface SubmenuProps {
    data: SubmenuItem[];
    name?: string;
}

const Submenu = ({ data, name = ``, ...props }: SubmenuProps) => {
    return (
        <S.Submenu {...props} role="navigation" aria-label={name}>
            {data.map(({ to, label }) => (
                <li key={to}>
                    <S.Item to={to}>{label}</S.Item>
                </li>
            ))}
        </S.Submenu>
    );
};

export default Submenu;
