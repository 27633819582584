import * as S from "./style";
import { HTMLAttributes, ReactNode } from "react";

interface PanelProps extends HTMLAttributes<HTMLElement> {
    children: ReactNode;
}
const Panel = ({ children, ...props }: PanelProps) => {
    return <S.Panel {...props}>{children}</S.Panel>;
};

export default Panel;
