import { FunctionComponent } from "react";
import { Company, itemSourceForDisplay, ItemSourceType } from "company/types";
import Chargebee from "components/logos/Chargebee";
import QuickBooks from "components/logos/QuickBooks";
import Stripe from "components/logos/Stripe";
import Xero from "components/logos/Xero";
import Logo from "components/Logo";

export interface ItemSourceLogoProps {
    itemSourceId: Company.Item["sourceId"];
    height?: string;
}

const ItemSourceLogo: FunctionComponent<ItemSourceLogoProps> = ({
    itemSourceId,
    height,
}) => {
    if (itemSourceId === ItemSourceType.Stripe) {
        return <Stripe height={height} />;
    }

    if (itemSourceId === ItemSourceType.QuickBooks) {
        return <QuickBooks height={height} />;
    }

    if (itemSourceId === ItemSourceType.Chargebee) {
        return <Chargebee height={height} />;
    }

    if (itemSourceId === ItemSourceType.Xero) {
        return <Xero height={height} />;
    }

    if (itemSourceId === ItemSourceType.Loop) {
        return <Logo href="" height={height} />;
    }

    // If no logo, return string
    return <span>{itemSourceForDisplay[itemSourceId]}</span>;
};

export default ItemSourceLogo;
