import * as S from "./style";
import loopLogo from "assets/img/logos/loop-crypto-long-white.svg";
import integralLogo from "assets/img/logos/integral.svg";
import neynarLogo from "assets/img/logos/neynar.svg";
import pinataLogo from "assets/img/logos/pinata.svg";
import { HTMLAttributes } from "react";
import { Outlet } from "react-router-dom";
import { useInvoicePay } from "pay/context/InvoicePay";
import FastForward from "components/icons/FastForward";
import Route from "components/icons/Route";
import Lock from "components/icons/Lock";
import Anchor from "components/Anchor";
import ErrorMessage from "components/ErrorMessage";
import Loading from "components/Loading";

interface InvoicePayLayoutProps extends HTMLAttributes<HTMLDivElement> {}

const InvoicePayLayout = ({ ...props }: InvoicePayLayoutProps) => {
    const { loading, entity, networks } = useInvoicePay();

    return (
        <S.Layout {...props}>
            <S.Marketing>
                <header>
                    <h1>
                        <S.Logo src={loopLogo} alt="Loop Crypto logo" />
                    </h1>
                </header>
                <S.Message>
                    <S.Slogan>
                        The easiest way to pay on chain.
                        <br />
                        Safe, simple and secure.
                    </S.Slogan>
                    <S.Features>
                        <li>
                            <S.Icon as={FastForward} />
                            <span>Quickly pay an invoice</span>
                        </li>
                        <li>
                            <S.Icon as={Route} />
                            <span>Receive confirmation instantly</span>
                        </li>
                        <li>
                            <S.Icon as={Lock} />
                            <span>Never lose track of your payments</span>
                        </li>
                    </S.Features>
                </S.Message>
                <S.TrustedBy>
                    <h2>Trusted by</h2>
                    <S.Partners>
                        <li>
                            <img src={integralLogo} alt="Integral logo" />
                        </li>
                        <li>
                            <img src={pinataLogo} alt="Pinata logo" />
                        </li>
                        <li>
                            <img src={neynarLogo} alt="Neynar logo" />
                        </li>
                    </S.Partners>
                </S.TrustedBy>
            </S.Marketing>
            <S.Content>
                <S.RouteComponent>
                    {loading ? (
                        <Loading />
                    ) : !loading && (!entity || !networks) ? (
                        <ErrorMessage msg="Invalid payee">
                            Payee was not found
                        </ErrorMessage>
                    ) : (
                        <Outlet />
                    )}
                </S.RouteComponent>
                <S.Footer showCart={true} />
            </S.Content>
        </S.Layout>
    );
};
export default InvoicePayLayout;
