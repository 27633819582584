import { useCallback, useState } from "react";
import { AvailableNetwork } from "default-variables";
import { getEvmTokenBalance, getSolTokenBalance } from "utils/tokens";
import { SolProvider, EvmProvider } from "types/common";
import { useChainProvider } from "hooks/useChainProvider";
import { PrimaryWalletAccount } from "context/Wallet/hooks/useWalletConnected";

export interface WalletTokenBalance {
    networkId: string; // hex
    wallet: string;
    token: string;
    symbol: string;
    balance: string;
    updatedAt: number;
}

export interface TokenBalanceProps {
    tokenSymbol?: string | null; // [ ] Still necessary to support "null"?
    tokenAddress?: string | null; // [ ] Still necessary to support "null"?
    walletAddress?: string; // [ ] Can search other wallets, but must be on same chain and network
    networkId?: string;
}

export interface GetUpdatedTokenBalanceProps extends TokenBalanceProps {
    force?: boolean;
}

const balanceExpiryLimit = 3 * 60 * 1000; // 3 minutes

const useWalletBalance = (
    connectedWallet: PrimaryWalletAccount | null,
    connectedNetwork: AvailableNetwork | null
) => {
    const [balances, setBalances] = useState<WalletTokenBalance[]>([]);
    const { getProviderForAnyWalletAndNetwork } = useChainProvider(
        connectedWallet,
        connectedNetwork
    );

    const getStoredTokenBalanceIndex = useCallback(
        ({
            tokenSymbol,
            tokenAddress,
            walletAddress,
            networkId,
        }: TokenBalanceProps): {
            existingIndex: number;
            wallet: string;
            network: AvailableNetwork;
        } => {
            const { wallet, network } = getProviderForAnyWalletAndNetwork({
                walletAddress,
                networkId,
            });

            // Use the wallet's address to represent the native token address
            const tokenAddressForStorage = tokenAddress || wallet;

            // Use the default token if no symbol is provided
            const tokenSymbolForStorage = tokenSymbol || network.token;

            const existingIndex = balances.findIndex(
                (existing) =>
                    existing.token === tokenAddressForStorage &&
                    existing.symbol === tokenSymbolForStorage &&
                    existing.wallet === wallet &&
                    existing.networkId === network.networkId
            );

            return { existingIndex, wallet, network };
        },
        [balances, getProviderForAnyWalletAndNetwork]
    );

    const fetchTokenBalance = useCallback(
        async ({
            tokenAddress,
            walletAddress,
            networkId,
        }: Omit<TokenBalanceProps, "tokenSymbol">): Promise<string> => {
            const { chainAndProvider, wallet } =
                getProviderForAnyWalletAndNetwork({
                    walletAddress,
                    networkId,
                });

            return chainAndProvider.chain === "SOL"
                ? await getSolTokenBalance({
                      tokenAddress: tokenAddress || wallet,
                      walletAddress: wallet,
                      provider: chainAndProvider.provider as SolProvider,
                  })
                : await getEvmTokenBalance({
                      tokenAddress,
                      walletAddress: wallet,
                      provider: chainAndProvider.provider as EvmProvider,
                  });
        },
        [getProviderForAnyWalletAndNetwork]
    );

    const hasTokenBalanceStored = useCallback(
        ({
            tokenSymbol,
            tokenAddress,
            walletAddress,
            networkId,
        }: TokenBalanceProps): boolean => {
            const { existingIndex } = getStoredTokenBalanceIndex({
                tokenSymbol,
                tokenAddress,
                walletAddress,
                networkId,
            });

            return existingIndex !== -1;
        },
        [getStoredTokenBalanceIndex]
    );

    const getTokenBalance = useCallback(
        async ({
            tokenSymbol,
            tokenAddress,
            walletAddress,
            networkId,
            force = false,
        }: GetUpdatedTokenBalanceProps = {}): Promise<
            WalletTokenBalance["balance"]
        > => {
            try {
                const { existingIndex, wallet, network } =
                    getStoredTokenBalanceIndex({
                        tokenSymbol,
                        tokenAddress,
                        walletAddress,
                        networkId,
                    });

                // A balance exists for this network-wallet-token and is not expired (and not being forced to refresh)
                if (
                    existingIndex !== -1 &&
                    balances[existingIndex].updatedAt + balanceExpiryLimit >
                        Date.now() &&
                    !force
                ) {
                    return balances[existingIndex].balance;
                }

                const balance = await fetchTokenBalance({
                    tokenAddress,
                    walletAddress,
                    networkId,
                });

                setBalances((prevBalances) => {
                    // A balance already exists for this network-wallet-token
                    if (existingIndex !== -1) {
                        if (prevBalances[existingIndex].balance !== balance) {
                            const updatedBalances = [...prevBalances];
                            updatedBalances[existingIndex] = {
                                ...updatedBalances[existingIndex],
                                balance,
                                updatedAt: Date.now(),
                            };
                            return updatedBalances;
                        }

                        // No change in balance
                        return prevBalances;
                    }

                    // Use the wallet's address to represent the native token address
                    const tokenAddressForStorage = tokenAddress || wallet;

                    // Use the default token if no symbol is provided
                    const tokenSymbolForStorage = tokenSymbol || network.token;

                    // No balance exists for this network-wallet-token, store it
                    return [
                        ...prevBalances,
                        {
                            networkId: network.networkId,
                            wallet,
                            token: tokenAddressForStorage,
                            symbol: tokenSymbolForStorage,
                            balance,
                            updatedAt: Date.now(),
                        },
                    ];
                });

                return balance;
            } catch (error) {
                throw new Error(
                    `There was a problem checking your wallet's ${tokenSymbol} balance: ${error}`
                );
            }
        },
        [balances, fetchTokenBalance, getStoredTokenBalanceIndex]
    );

    return { getTokenBalance, hasTokenBalanceStored };
};

export default useWalletBalance;
