import * as S from "./style";
import { CheckoutPanel } from "checkout/components/CartAndForm";
import StepOneDetails from "../StepOneDetails";
import StepTwoDetails from "../StepTwoDetails";
import StepThreeDetails from "../StepThreeDetails";

export interface CheckoutPanelsProps {}

const CheckoutPanels = (props: CheckoutPanelsProps) => {
    return (
        <>
            <StepOneDetails name={CheckoutPanel.PAYMENT} />
            <StepTwoDetails name={CheckoutPanel.CONTACT} />
            <StepThreeDetails name={CheckoutPanel.APPROVE} />
        </>
    );
};

export default CheckoutPanels;
