import * as S from "./style";
import { forwardRef, Ref } from "react";
import { formatTokenAndUsd } from "utils/financial";
import { useCheckoutForm } from "checkout/context/CheckoutForm";
import { useWallet } from "context/Wallet";

interface TokenBalanceDescriptionListRef {}

const TokenBalanceDescriptionList = forwardRef(
    (_, ref: Ref<TokenBalanceDescriptionListRef>) => {
        const { token, tokenBalance, loadingBalance, isTokenPricedCheckout } =
            useCheckoutForm();
        const { walletConnected } = useWallet();
        if (!walletConnected) return <></>;

        const items = [];
        if (token) {
            items.push({
                term: `${token.symbol} balance`,
                definition: loadingBalance
                    ? `Loading`
                    : formatTokenAndUsd({
                          amount: tokenBalance,
                          symbol: token.symbol,
                          usdValue: token.exchange.rate,
                          round: undefined,
                          hideUsd: isTokenPricedCheckout,
                      }),
            });
        }

        return <S.TokenBalanceDescriptionList items={items} />;
    }
);

export default TokenBalanceDescriptionList;
