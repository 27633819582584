import { ItemValues } from "company/hooks/useFormatCompanyItemsTable";
import { Company, ItemSourceType, itemSourceForDisplay } from "company/types";
import Field from "components/Field";
import Input from "components/Input";
import SelectMultiple, {
    SelectMultipleOptionProps,
} from "components/SelectMultiple";
import { useEffect, useMemo, useState } from "react";
import { onlyUnique, uniqueArray } from "utils/arrays";
import * as S from "./style";
import { useGetCompanyItems } from "company/hooks/useGetCompanyItems";
import { itemFrequencyTypeForDisplay } from "utils/items";
import { FrequencyType } from "types/common-enums";

interface ItemsTableFiltersProps {
    items: Company.Item[];
    headings: ItemValues[];
    onChange: (
        search: string,
        selectedSourceIds: ItemSourceType[],
        selectedFrequencyTypes: FrequencyType[]
    ) => void;
}

const ItemsTableFilters: React.FunctionComponent<ItemsTableFiltersProps> = ({
    items,
    headings,
    onChange,
}) => {
    const [search, setSearch] = useState<string>("");
    const [selectedSourcesIds, setSelectedSourcesIds] = useState<
        ItemSourceType[]
    >([]);

    const [selectedFrequencyTypes, setSelectedFrequencyTypes] = useState<
        FrequencyType[]
    >([]);

    const { getCompanyItemsIsFetching } = useGetCompanyItems();

    const frequencyTypes = useMemo(
        () =>
            items
                .map((item) => item.frequency.type)
                .filter(
                    (frequency): frequency is FrequencyType => frequency !== ""
                )
                .filter(onlyUnique),
        [items]
    );

    const frequencyTypesOptions = useMemo(
        () =>
            frequencyTypes.map((frequencyType) => {
                return {
                    value: frequencyType,
                    label: itemFrequencyTypeForDisplay[frequencyType],
                };
            }) || [],
        [frequencyTypes]
    );

    const sourcesOptions: SelectMultipleOptionProps[] = useMemo(
        () =>
            items.map((item) => {
                const label = itemSourceForDisplay[item.sourceId];
                return {
                    value: item.sourceId,
                    label: label,
                };
            }) || [],
        [items]
    );

    const sourcesOptionsUnique = uniqueArray(sourcesOptions, "label");

    const onSourceValuesChanges = (values: number[]) => {
        setSelectedSourcesIds(values);
    };

    const onFrequencyTypesChanges = (frequencyTypes: FrequencyType[]) => {
        setSelectedFrequencyTypes(frequencyTypes);
    };

    useEffect(() => {
        onChange(search, selectedSourcesIds, selectedFrequencyTypes);
    }, [onChange, search, selectedSourcesIds, selectedFrequencyTypes]);

    return (
        <S.Filters>
            <Field>
                <Input
                    type="search"
                    name="search"
                    placeholder="Search"
                    value={search}
                    disabled={getCompanyItemsIsFetching}
                    onChange={(event) => setSearch(event.target.value)}
                />
            </Field>
            {headings.includes(ItemValues.invoicing) &&
                sourcesOptionsUnique.length > 1 && (
                    <Field>
                        <SelectMultiple
                            options={sourcesOptionsUnique}
                            name="source"
                            values={selectedSourcesIds}
                            onChange={onSourceValuesChanges}
                            disabled={getCompanyItemsIsFetching}
                            placeholder="Filter by source"
                        />
                    </Field>
                )}
            {headings.includes(ItemValues.frequency) &&
                frequencyTypesOptions.length > 1 && (
                    <Field>
                        <SelectMultiple
                            options={frequencyTypesOptions}
                            name="frequency"
                            values={selectedFrequencyTypes}
                            onChange={onFrequencyTypesChanges}
                            disabled={getCompanyItemsIsFetching}
                            placeholder="Filter by frequency"
                        />
                    </Field>
                )}
        </S.Filters>
    );
};

export default ItemsTableFilters;
