import Field from "components/Field";
import Hint from "components/Hint";
import styled from "styled-components";

export const UserPasswordField = styled(Field)`
    align-items: center;
    flex-wrap: wrap;
`;

export const InputWrapper = styled.div`
    width: 100%;
    & > * {
        width: 100%;
    }
`;
export const Error = styled(Hint)`
    flex-basis: 100%;
    padding-left: 25%;
`;
