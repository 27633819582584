import * as S from "./style";
import { CardHeader, CardSection, CardFooter } from "./style";
import { forwardRef, HTMLAttributes, ReactNode } from "react";
import { StyledProps } from "styled-components";

interface CustomCardProps {
    children?: ReactNode;
    noShadow?: boolean;
    noSurface?: boolean;
}

export type CardProps = HTMLAttributes<HTMLDivElement> &
    StyledProps<any> &
    CustomCardProps;

const Card = forwardRef<HTMLDivElement, CardProps>(
    ({ children, ...props }: CardProps, ref) => {
        return (
            <S.Card ref={ref as any} {...props}>
                {children}
            </S.Card>
        );
    }
);

export default Card;
export { CardHeader, CardSection, CardFooter };
