import { useState } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { apiServerUrl } from "default-variables";
import { useNotificationQueue } from "context/NotificationQueue";
import SetPasswordForm from "./SetPasswordForm";
import Loading from "components/Loading";
import { NotificationType } from "components/Notification";
import { useUser, UserRole, tierStrToEnum } from "context/User";
import { LogUserInResponse } from "components/LoginWeb2";
import { getErrorMessage } from "utils/errors";
import Title from "components/Title";
import { Spacing } from "theme/spacing";
import Paragraph from "components/Paragraph";
import Checkbox from "components/Checkbox";
import Button from "components/Button";
import Anchor from "components/Anchor";
import Field from "components/Field";
import Label from "components/Label";

const ActivateUserForm = () => {
    const { activationHash } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { addNotification } = useNotificationQueue();
    const { newUserSession } = useUser();

    const [formValues, setFormValues] = useState({
        agreementCheck: false,
        loading: false,
    });

    const { agreementCheck, loading } = formValues;

    const handleCheck = () => {
        setFormValues({ ...formValues, agreementCheck: !agreementCheck });
    };

    const handleSubmit = async (evt: any, password: string) => {
        evt.preventDefault();

        setFormValues({ ...formValues, loading: true });
        let res, data;
        try {
            res = await fetch(`${apiServerUrl}/v1/user/activate`, {
                method: `PATCH`,
                body: JSON.stringify({
                    email: searchParams.get(`email`),
                    password,
                    activationHash,
                }),
            });

            data = await res.json();

            if (!res.ok) {
                throw data;
            }
        } catch (error: unknown) {
            const errorMsg = getErrorMessage(error);
            addNotification({ msg: errorMsg, type: NotificationType.ERROR });
            return;
        } finally {
            setFormValues({ ...formValues, loading: false });
        }

        const {
            token,
            entityId,
            companyName,
            roles,
            delegatedSigning,
            email: serverEmail,
        }: LogUserInResponse = data;
        if (token && entityId) {
            newUserSession({
                token,
                roles: roles as UserRole[],
                entityId,
                tier: tierStrToEnum(`guest`),
                companyName,
                email: serverEmail,
                delegatedSigning,
            });
            navigate(`/`);
        } else {
            addNotification({
                msg: `Something went wrong`,
                type: NotificationType.ERROR,
            });
        }
    };

    return (
        <>
            {formValues.loading && <Loading />}
            <Title level="h2" spacing={Spacing.sm}>
                Welcome
            </Title>
            <Paragraph spacing={Spacing.sm}>
                Please set your password.
            </Paragraph>
            <SetPasswordForm onSubmit={handleSubmit}>
                {({ isValid }: any) => {
                    return (
                        <>
                            <Field>
                                <Label inline>
                                    <Checkbox
                                        checked={agreementCheck}
                                        onChange={handleCheck}
                                    />
                                    <span>
                                        By clicking <em>"Let's go"</em>, you
                                        agree to our{" "}
                                        <Anchor
                                            href={
                                                import.meta.env
                                                    .VITE_TERMS_AND_CONDITIONS
                                            }
                                        >
                                            Terms and conditions
                                        </Anchor>{" "}
                                        and{" "}
                                        <Anchor
                                            href={import.meta.env.VITE_PRIVACY}
                                        >
                                            Privacy policy
                                        </Anchor>
                                        .
                                    </span>
                                </Label>
                            </Field>
                            <Button
                                type="submit"
                                disabled={!isValid || !agreementCheck}
                                loading={loading}
                                full
                            >
                                Let's go
                            </Button>
                        </>
                    );
                }}
            </SetPasswordForm>
        </>
    );
};

export default ActivateUserForm;
