import * as S from "./style";
import { ReactNode } from "react";

interface IndicatorProps {
    children: ReactNode;
}

const Indicator = ({ children }: IndicatorProps) => {
    return <S.Indicator>{children}</S.Indicator>;
};

export default Indicator;
