import {
    colorsDeprecated as c,
    properties as p,
    fade,
    slide,
} from "global-style";
import styled from "styled-components";
import colors from "theme/colors";
import spacing from "theme/spacing";

export const Backdrop = styled.div`
    background: ${c.modalA};
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${p.zIndex.modal};
    animation: ${fade} 0.2s ease;
`;

export const Header = styled.header`
    display: flex;
    flex-direction: column;
    gap: ${spacing.xxxs};
    padding: ${spacing.sm} ${spacing.lg} ${spacing.sm} ${spacing.lg};
    border-bottom: 1px solid ${c.fullPageDropdownBorder};
`;

export const Main = styled.main`
    padding: ${spacing.lg};
`;

export const Dialog = styled.dialog`
    overflow: auto;
    width: 100%;
    border: none;
    width: 100%;
    max-width: 38rem;
    height: 100%;
    max-height: 100vh;
    left: auto;
    outline: none;
    box-shadow: ${p.shadowMd};
    background-color: ${colors.white};
    top: 0;
    padding: 0;
    &:focus,
    &:active,
    &:focus-visible,
    &:focus-within {
        border: none;
    }
    animation: ${slide} 0.2s ease;
`;
export const ClosePopoverLink = styled.button`
    background: none;
    color: ${c.popoverCloseLink};
    display: inline-block;
    position: absolute;
    top: 25px;
    right: 25px;
    font-size: 0;
    padding: 0;
`;

export const DialogLoading = styled.div`
    display: flex;
    justify-content: center;
    vertical-align: middle;
    padding: 2rem;
`;
