import * as S from "./style";
import { ItemFrequencyNames } from "utils/items";
import { useUser, UserRole } from "context/User";
import { itemIsActive, itemIsSubscription } from "company/utils/items";
import { useGetCompanyItems } from "company/hooks/useGetCompanyItems";
import Section from "components/Section";
import Button from "components/Button";
import AddItemButton from "company/components/items/AddItemButton";
import ItemsTable from "company/components/items/ItemsTable";
import { ItemValues } from "company/hooks/useFormatCompanyItemsTable";
import { useMemo } from "react";

const headings = [
    ItemValues.entity,
    ItemValues.name,
    ItemValues.amount,
    ItemValues.tokens,
    ItemValues.frequency,
    ItemValues.invoicing,
    ItemValues.initialOffset,
    ItemValues.manage,
];

const SubscriptionsActive = () => {
    const { items } = useGetCompanyItems();

    const { hasRole } = useUser();
    const canManage = hasRole(UserRole.COMPANY);

    const activeSubscriptionItems = useMemo(() => {
        return items.filter(
            (item) => itemIsSubscription(item) && itemIsActive(item)
        );
    }, [items]);

    return (
        <>
            {canManage && (
                <Section>
                    <S.Filters>
                        <AddItemButton
                            itemFrequencyName={ItemFrequencyNames.Subscription}
                        />

                        {activeSubscriptionItems.length > 0 && (
                            <Button href="/invoice">
                                Invoice for a subscription
                            </Button>
                        )}
                    </S.Filters>
                </Section>
            )}

            <Section>
                <ItemsTable
                    headings={headings}
                    itemFrequency={ItemFrequencyNames.Subscription}
                    active={true}
                />
            </Section>
        </>
    );
};

export default SubscriptionsActive;
