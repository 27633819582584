import * as S from "./style";
import { useEffect, useState } from "react";
import { Customer } from "customer/types";
import { AgreementCanceledStatuses } from "types/common-enums";
import { useCustomerData } from "customer/context/CustomerData";
import {
    AuthorizationItem,
    AuthorizedEntity,
} from "customer/components/Authorization";
import Section from "components/Section";
import Authorization from "customer/components/Authorization";
import ErrorMessage from "components/ErrorMessage";
import Anchor from "components/Anchor";

const Authorizations = () => {
    const { entities, agreements, tokens, contracts, entityAuthorizations } =
        useCustomerData();
    const [authorizations, setAuthorizations] = useState<AuthorizedEntity[]>(
        []
    );

    useEffect(() => {
        const newAuthorizations = entityAuthorizations.reduce<
            AuthorizedEntity[]
        >((authorizedEntities, { entity, records }) => {
            // Get data for the parent entity
            const topLevelEntity = entities.find(
                ({ entityId }) => entityId === entity
            );
            if (!topLevelEntity) return authorizedEntities;

            // Items and tokens used in valid authorizations
            const itemsAndTokens = records.reduce<{
                items: AuthorizationItem[];
                tokens: Customer.Token[];
            }>(
                (
                    arr,
                    {
                        id: { value: agreementId },
                        itemName: { value: itemName },
                        status: { value: status },
                    }
                ) => {
                    // Find token used for agreement
                    const agreement = agreements.find(
                        ({ id }) => id === agreementId
                    );
                    const tokenAddress = agreement?.token.toLowerCase() || ``;
                    const networkId = agreement?.networkId || 0;

                    const tokenAlreadyAdded = arr.tokens.find(
                        (token) =>
                            token.address.toLowerCase() === tokenAddress &&
                            token.networkId === networkId
                    );
                    const token = tokens.find(
                        (token) =>
                            token.address.toLowerCase() ===
                                tokenAddress.toLowerCase() &&
                            token.networkId === networkId
                    );

                    return {
                        items: [
                            ...arr.items,
                            {
                                id: agreementId,
                                name: itemName,
                                active: !AgreementCanceledStatuses.includes(
                                    status
                                ),
                            },
                        ],
                        tokens:
                            tokenAlreadyAdded || !token
                                ? arr.tokens
                                : [...arr.tokens, token],
                    };
                },
                { items: [], tokens: [] }
            );
            if (
                itemsAndTokens.items.length < 1 ||
                itemsAndTokens.tokens.length < 1
            )
                return authorizedEntities;

            // The top-level entity contract
            const authContract = contracts.find(
                ({ owner }) => owner === topLevelEntity.entityId
            );
            if (!authContract) return authorizedEntities;

            return [
                ...authorizedEntities,
                {
                    entityId: entity,
                    name: topLevelEntity.name,
                    websiteUrl: topLevelEntity.websiteUrl,
                    logoUrl: topLevelEntity.logoUrl,
                    tokens: itemsAndTokens.tokens,
                    items: itemsAndTokens.items,
                    contract: authContract,
                },
            ];
        }, []);

        setAuthorizations(newAuthorizations);
    }, [entityAuthorizations]);

    return (
        <Section>
            {authorizations.length > 0 ? (
                <S.Authorizations>
                    {authorizations.map((auth) => (
                        <Authorization data={auth} key={auth.entityId} />
                    ))}
                </S.Authorizations>
            ) : (
                <ErrorMessage msg="No authorizations to display yet">
                    <p>
                        Need help? Take a look at our{" "}
                        <Anchor href={import.meta.env.VITE_LOOP_DOCS_FAQ}>
                            documentation
                        </Anchor>{" "}
                        or{" "}
                        <Anchor
                            href={`mailto:${import.meta.env.VITE_EMAIL_HELP}`}
                        >
                            contact us
                        </Anchor>
                        .
                    </p>
                </ErrorMessage>
            )}
        </Section>
    );
};

export default Authorizations;
