import styled from "styled-components";
import Anchor from "components/Anchor";

export const LogoLink = styled(Anchor).attrs<{ href?: string }>((props) => ({
    as: !props.href ? "span" : undefined,
}))`
    line-height: 1.1;
    align-self: center;
`;

export const Logo = styled.svg<{
    tall: boolean;
    lg: boolean;
    full: boolean;
    height?: string;
}>`
    max-height: 100%;
    max-width: 100%;
    ${({ tall, lg, full, height }) =>
        full
            ? `width: 100%; height: auto;`
            : `height: ${
                  height
                      ? height
                      : tall
                      ? lg
                          ? `7em`
                          : `5em`
                      : lg
                      ? `1.5em`
                      : `1em`
              }`};
    vertical-align: middle;
`;
