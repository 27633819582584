import * as S from "./style";
import { Dispatch, SetStateAction, memo, useCallback } from "react";
import Label from "components/Label";
import Checkbox from "components/Checkbox";

interface AmountInUsdProps {
    value?: boolean;
    setValue: Dispatch<SetStateAction<boolean>>;
    disabled?: boolean;
}

const AmountInUsd = memo(
    ({ value, setValue, disabled = false }: AmountInUsdProps) => {
        const handleChange = useCallback(
            (event: any) => {
                setValue(event.target.checked);
            },
            [setValue]
        );

        return (
            <S.UsdField>
                <Label htmlFor="usd">
                    <Checkbox
                        name="usd"
                        checked={value}
                        onChange={handleChange}
                        disabled={disabled}
                    />
                    Amount in USD
                </Label>
            </S.UsdField>
        );
    }
);

export default AmountInUsd;
