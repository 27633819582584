import { useEffect, useState } from "react";
import useSubscriptionContract from "admin/hooks/useSubscriptionContract";
import { frequencyToSeconds, FrequencyUnit } from "utils/datetime";
import { useNotificationQueue } from "context/NotificationQueue";
import { NotificationType } from "components/Notification";
import Field from "components/Field";
import Label from "components/Label";
import Input from "components/Input";
import Select, { SelectOption } from "components/Select";
import InputWithAddon from "components/InputWithAddon";
import Form from "components/Form";
import Button from "components/Button";

// TODO define Contract type in a central place
// Can't import from Home.tsx, will create circular dependency
export const View = ({ form, onChange }: any) => {
    const frequencyOptions: SelectOption[] = [
        {
            value: FrequencyUnit.Minute,
            label: "Minute(s)",
        },
        {
            value: FrequencyUnit.Hour,
            label: "Hour(s)",
        },
        {
            value: FrequencyUnit.Day,
            label: "Day(s)",
        },
    ];
    return (
        <>
            <Field>
                <Label htmlFor="itemName">Item name</Label>
                <Input
                    name="itemName"
                    value={form.name}
                    onChange={onChange(`name`)}
                />
            </Field>
            <Field>
                <Label htmlFor="frequencyCount">Frequency Count</Label>
                <Input
                    name="frequencyCount"
                    value={form.frequencyCount}
                    onChange={onChange(`frequencyCount`)}
                />
            </Field>
            <Field>
                <Label htmlFor="frequencyType">Frequency Type</Label>
                <Select
                    name="frequencyType"
                    value={form.frequencyType}
                    onChange={onChange(`frequencyType`)}
                    options={frequencyOptions}
                />
            </Field>
            <Field>
                <Label htmlFor="price">Price USD</Label>
                <InputWithAddon
                    addon={{ content: "$", position: "left" }}
                    inputProps={{
                        name: "price",
                        value: form.price,
                        onChange: onChange(`price`),
                    }}
                />
            </Field>

            <Field>
                <Label htmlFor="deposit">Deposit USD</Label>
                <InputWithAddon
                    addon={{ content: "$", position: "left" }}
                    inputProps={{
                        name: "deposit",
                        value: form.deposit,
                        onChange: onChange(`deposit`),
                    }}
                />
            </Field>

            <Field>
                <Label htmlFor="fee">fee %</Label>
                <InputWithAddon
                    addon={{ content: "%", position: "right" }}
                    inputProps={{
                        name: "fee",
                        value: form.fee,
                        onChange: onChange(`fee`),
                    }}
                />
            </Field>
        </>
    );
};

// TODO use strings instead of numbers?
// parseUnits in useSubscriptionContract() requires strings
export const defaultState = {
    name: ``,
    frequencyCount: 0,
    frequencyType: FrequencyUnit.Day,
    price: 0,
    deposit: 0,
    fee: 0,
};

// TODO define Contract type in a central place
// Can't import from Home.tsx, will create circular dependency
const NewSubscriptionItemForm = ({ contract, onSubmit, closePopover }: any) => {
    const { createItem } = useSubscriptionContract();

    const [formState, setFormState] = useState(defaultState);
    const { addNotification } = useNotificationQueue();
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const { name, frequencyCount, frequencyType, price, deposit, fee } =
        formState;

    useEffect(() => {
        if (submitted) {
            closePopover();
        }
    }, [submitted]);

    const handleChangeFactory = (field: string) => {
        return (evt: any) => {
            // validate
            setFormState({ ...formState, [field]: evt.target.value });
        };
    };

    const handleSubmit = async (evt: any) => {
        evt.preventDefault();
        setSubmitting(true);
        try {
            const receipt = await createItem(
                {
                    name,
                    frequency: frequencyToSeconds(
                        frequencyCount,
                        frequencyType
                    ),
                    amount: price,
                    stakeAmount: deposit,
                    fee: fee / 100,
                },
                contract
            );
            // convert fee to %
            onSubmit({
                form: { ...formState, fee: fee / 100 },
                receipt,
                templateType: 1,
            });
            setSubmitted(true);
        } catch (err) {
            console.error(err);
            addNotification({
                msg: "Something went wrong",
                type: NotificationType.ERROR,
            });
            setSubmitting(false);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <h2>New Subscription Plan</h2>

            <View form={formState} onChange={handleChangeFactory} />

            <Button type="submit" disabled={!name} loading={submitting} full>
                {submitting ? "Creating Plan..." : "Create New Plan"}
            </Button>
        </Form>
    );
};

export default NewSubscriptionItemForm;
