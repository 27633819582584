import {
    forwardRef,
    useImperativeHandle,
    useState,
    Ref,
    useRef,
    useEffect,
    useCallback,
} from "react";
import Label from "components/Label";
import Input from "components/Input";
import { validatePresence } from "utils/formValidation";
import { SelfServeNewEntityConfig } from "api";
import * as S from "./styles";

export interface EntityCompanyNameFieldProps {
    disabled?: boolean;
    label?: React.ReactNode;
    defaultCompanyName?: string;
    onChange?: (companyName: string) => void;
}

export type EntityCompanyNameFieldRef = {
    value: string;
    validate: () => boolean;
};

function EntityCompanyNameField(
    {
        disabled,
        label = "Company name",
        defaultCompanyName = "",
        onChange,
    }: EntityCompanyNameFieldProps,
    ref: Ref<EntityCompanyNameFieldRef>
) {
    const [companyName, setCompanyName] =
        useState<SelfServeNewEntityConfig["companyName"]>(defaultCompanyName);

    const companyNameRef = useRef<HTMLInputElement>(null);
    const validationMessage = "Enter your company name";
    const [isValid, setIsValid] = useState<boolean>(true);

    const validate = useCallback(() => {
        if (!companyNameRef.current) {
            return false;
        }

        const isValid = validatePresence({
            input: companyNameRef.current,
            errorMessage: validationMessage,
            reportValidity: false,
        });

        setIsValid(isValid);
        return isValid;
    }, [companyNameRef]);

    useImperativeHandle(ref, () => ({ value: companyName, validate }), [
        companyName,
        validate,
    ]);

    useEffect(() => {
        if (onChange) onChange(companyName);
    }, [onChange, companyName]);

    return (
        <S.EntityCompanyNameField disabled={disabled}>
            <Label htmlFor="companyName">{label}</Label>
            <Input
                innerRef={companyNameRef}
                type="text"
                name="companyName"
                placeholder="Company Name"
                value={companyName}
                disabled={disabled}
                onChange={(event) => setCompanyName(event.target.value)}
            />
            {!isValid && <S.ErrorMsg>{validationMessage}</S.ErrorMsg>}
        </S.EntityCompanyNameField>
    );
}
export default forwardRef(EntityCompanyNameField);
