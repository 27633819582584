import { colorsDeprecated as c } from "global-style";
import { useCallback, useEffect, useState } from "react";
import Button, { ButtonVariants } from "components/Button";
import Tooltip from "components/Tooltip";
import Download from "components/icons/Download";
import colors from "theme/colors";

interface HoverTipProps {
    children: JSX.Element;
    disabled?: boolean;
    title?: string;
}

const HoverTip = ({
    children,
    disabled = false,
    title = ``,
}: HoverTipProps) => {
    return disabled ? (
        children
    ) : (
        <Tooltip title={title}>
            <span>{children}</span>
        </Tooltip>
    );
};

// Behavior:
//  - If `disabled` prop is true, no ToolTop is shown, button appears disabled
//  - If `disabled` prop is false/undefined, ToolTop is shown, button depends on number of rows

interface DownloadCsvProps {
    filename: string;
    columns: Array<Heading>;
    rows: Array<Tuple>;
    disabled?: boolean;
}

const DownloadCsv = ({
    filename,
    columns,
    rows,
    disabled = false,
    ...props
}: DownloadCsvProps) => {
    const [csv, setCsv] = useState("");
    const [active, setActive] = useState(disabled);

    useEffect(() => {
        if (!rows.length || disabled) {
            setActive(false);
            return;
        }
        setActive(true);
    }, [rows, disabled]);

    const handleDownloadCsv = useCallback(() => {
        if (!active) return;

        const header =
            columns
                .filter((column) => !column.hide)
                .map(({ label }) => label.toUpperCase())
                .join(`,`) + `\r\n`;
        const records = rows

            .map(({ id, values }) => ({
                id: id,
                values: values.filter(
                    (_value, index) => columns[index] && !columns[index].hide
                ),
            }))
            .map(({ values }) =>
                // eslint-disable-next-line no-useless-escape
                values.map(({ text }: Field) => `\"${text}\"`).join(`,`)
            )
            .join(`\r\n`);

        setCsv(header + records);
    }, [columns, rows, active]);

    return (
        <HoverTip
            disabled={disabled}
            title={rows.length < 1 ? `Nothing to download` : `Download as CSV`}
        >
            <Button
                href={`data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`}
                onClick={handleDownloadCsv}
                download={filename}
                variant={ButtonVariants.NeutralOutlined}
                disabled={!active || rows.length === 0}
                {...props}
            >
                <Download
                    fill={!active ? c.disabled : colors.primary}
                    height="1rem"
                    width="1rem"
                />
            </Button>
        </HoverTip>
    );
};

export default DownloadCsv;
