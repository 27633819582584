import IWethAbi from "abis/abi/IWeth.json";
import { tokenABI } from "default-variables";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import {
    Contract,
    getAddress,
    Interface,
    parseEther,
    parseUnits,
} from "ethers";
import Safe from "@safe-global/protocol-kit";
import {
    SafeTransactionDataPartial,
    OperationType,
} from "@safe-global/safe-core-sdk-types";
import { getWrapperSymbols, getNativeTokenBySymbol } from "utils/tokens";
import { EvmWalletSigner } from "types/common";
import { WrapperToken } from "hooks/useWrappableTokens";
import { SafeWallet } from "context/Wallet/hooks/useWalletSafes";
import { PrimaryWalletAccount } from "context/Wallet/hooks/useWalletConnected";

const useSafeApi = (
    walletConnected: PrimaryWalletAccount | null,
    safeWallet: Promise<SafeWallet | null>
) => {
    const [safeConnected, setSafeConnected] = useState<SafeWallet | null>(null);

    const safe = useRef<Safe | null>(null);

    useEffect(() => {
        let isMounted = true;

        safeWallet
            .then((wallet) => {
                if (isMounted) {
                    setSafeConnected(wallet);
                }
            })
            .catch(() => {
                if (isMounted) {
                    setSafeConnected(null);
                }
            });

        return () => {
            isMounted = false;
        };
    }, [safeWallet]);

    // [ ] Perhaps safe should be assigned within here, so that we can ditch the useRef and simply store it as state, that resolves when `walletConnected` updates from it's resolution
    // This entire hook needs to be reworked.
    // Initialization should happen immediately if possible, and recreated each time the wallet changes
    // Should store the state of initialization, and which wallet was initialized, then can manage state better

    const initializeSafe = useCallback(async () => {
        if (!walletConnected?.proxyFor || !safeConnected?.adapter) {
            safe.current = null;
            return Promise.reject(`A Safe wallet has not been configured`);
        }

        // Create Safe instance
        return await Safe.create({
            ethAdapter: safeConnected.adapter,
            safeAddress: getAddress(walletConnected.proxyFor),
        })
            .then((newSafe) => {
                safe.current = newSafe;
                return Promise.resolve(safe.current);
            })
            .catch((e) => {
                return Promise.reject(`Safe could not be initialized`);
            });
    }, [walletConnected?.proxyFor, safeConnected?.adapter]);

    useEffect(() => {
        (async () => {
            await initializeSafe().catch(() => null);
        })();
    }, [initializeSafe]);

    const createAndProposeSafeTx = async (data: SafeTransactionDataPartial) => {
        if (!walletConnected?.proxyFor)
            return Promise.reject(
                `Your wallet is not being used as a proxy for a Safe wallet`
            );
        if (walletConnected.chain !== `EVM`)
            return Promise.reject(
                `Safe wallets are not available on this chain`
            );

        try {
            safe.current ||= await initializeSafe().catch(() => {
                throw new Error(`Safe was not initialized correctly`);
            });

            const safeTransaction = await safe.current.createTransaction({
                transactions: [data],
            });

            const senderAddress = await walletConnected?.signer?.getAddress();
            const safeTxHash = await safe.current.getTransactionHash(
                safeTransaction
            );
            const signature = await safe.current
                .signTransactionHash(safeTxHash)
                .catch(() => {
                    return Promise.reject(
                        `Transaction was rejected by your wallet`
                    );
                });

            // Propose transaction to the service
            await safeConnected?.service?.proposeTransaction({
                safeAddress: getAddress(walletConnected.proxyFor),
                safeTransactionData: safeTransaction.data,
                safeTxHash,
                senderAddress,
                senderSignature: signature.data,
            });

            return {
                safeTransaction,
                safeTxHash,
            };
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    const sendApproveAllownaceForSafe = async ({
        token,
        contract,
        amount,
    }: {
        token: Pick<BaseToken, "address" | "decimals" | "symbol"> &
            Partial<BaseToken>;
        contract: string;
        amount: string;
    }): Promise<string | ReactNode> => {
        if (!walletConnected?.proxyFor)
            return Promise.reject(
                `Your wallet is not being used as a proxy for a Safe wallet`
            );
        if (walletConnected.chain !== `EVM`)
            return Promise.reject(
                `Safe wallets are not available on this chain`
            );

        safe.current ||= await initializeSafe().catch(() => {
            throw new Error(`Safe was not initialized correctly`);
        });

        const tokenContract = new Contract(
            getAddress(token.address),
            tokenABI,
            walletConnected.signer as EvmWalletSigner
        );

        // Encode the data for the approve function
        const data = tokenContract.interface.encodeFunctionData(`approve`, [
            getAddress(contract),
            parseUnits(amount, token.decimals),
        ]);

        const nextNonce = await safeConnected?.service?.getNextNonce(
            getAddress(walletConnected.proxyFor)
        );

        // Create transaction
        const safeTransactionData: SafeTransactionDataPartial = {
            to: getAddress(token.address),
            value: "0",
            data,
            operation: OperationType.Call,
            nonce: nextNonce,
        };

        // Propose transaction to the service
        return createAndProposeSafeTx(safeTransactionData)
            .then(() => {
                return Promise.resolve(
                    <>
                        Transaction to update {token.symbol} authorization to{" "}
                        {amount} was sent to your proxied Safe's queue.
                        <br />
                        The authorization value will not be updated until the
                        transaction is accepted and processed by your Safe
                        wallet.
                    </>
                );
            })
            .catch((e) => {
                console.error(e);
                return Promise.reject(
                    `There was a problem sending the authorization transaction to your proxied Safe's queue`
                );
            });
    };

    const sendWrapNativeToken = async ({
        token,
        amount,
    }: {
        token: Pick<WrapperToken, "address" | "symbol" | "wrapsTo"> &
            Partial<WrapperToken>;
        amount: string;
    }) => {
        if (!walletConnected?.proxyFor)
            return Promise.reject(
                `Your wallet is not being used as a proxy for a Safe wallet`
            );
        if (walletConnected.chain !== `EVM`)
            return Promise.reject(
                `Safe wallets are not available on this chain`
            );

        safe.current ||= await initializeSafe().catch(() => {
            throw new Error(`Safe was not initialized correctly`);
        });

        const supportedWrappers = getWrapperSymbols();

        if (
            !supportedWrappers.includes(token.wrapsTo.toUpperCase()) ||
            walletConnected.chain !== "EVM"
        ) {
            return Promise.reject(
                `${token.symbol.toUpperCase()} cannot be wrapped to ${token.wrapsTo.toUpperCase()}`
            );
        }
        const wrapContract = new Contract(
            getAddress(token.address),
            IWethAbi, // IWethAbi deposit function is identical for IWmaticAbi
            walletConnected.signer
        );

        // Encode the data for the approve function
        const data = wrapContract.interface.encodeFunctionData("deposit", []);

        const nextNonce = await safeConnected?.service?.getNextNonce(
            getAddress(walletConnected.proxyFor)
        );

        const decimals = getNativeTokenBySymbol(token.symbol)?.decimals;
        if (!decimals) {
            return Promise.reject(
                `There was a problem formatting ${amount} ${
                    token.symbol
                } to ${token.wrapsTo.toUpperCase()}`
            );
        }

        // Create transaction
        const safeTransactionData: SafeTransactionDataPartial = {
            to: getAddress(token.address),
            value: parseUnits(amount, decimals).toString(),
            data,
            operation: OperationType.Call,
            nonce: nextNonce,
        };

        // Propose transaction to the service
        return createAndProposeSafeTx(safeTransactionData)
            .then(() => {
                return Promise.resolve(
                    <>
                        Transaction to swap {amount}{" "}
                        {token.wrapsTo.toUpperCase()} to{" "}
                        {token.symbol.toUpperCase()} was sent to your proxied
                        Safe's queue.
                        <br />
                        The balances will not be updated until the transaction
                        is accepted and processed by your Safe wallet.
                    </>
                );
            })
            .catch((e) => {
                console.error(e);
                return Promise.reject(
                    `There was a problem sending the token wrapping transaction to your proxied Safe's queue`
                );
            });
    };

    const sendSafeTokenPayment = async ({
        token,
        amount,
        toAddress,
        nativeToken = false,
    }: {
        token: Pick<BaseToken, "address" | "decimals" | "symbol"> &
            Partial<BaseToken>;
        amount: string;
        toAddress: string;
        nativeToken?: boolean;
    }): Promise<string> => {
        if (!walletConnected?.proxyFor)
            throw new Error(
                `Your wallet is not being used as a proxy for a Safe wallet`
            );
        if (walletConnected.chain !== `EVM`)
            throw new Error(`Safe wallets are not available on this chain`);

        safe.current ||= await initializeSafe().catch(() => {
            throw new Error(`Safe was not initialized correctly`);
        });

        let data = "0x";
        let value = "0";
        if (!nativeToken) {
            const erc20Interface = new Interface([
                "function transfer(address to, uint256 amount)",
            ]);
            data = erc20Interface.encodeFunctionData("transfer", [
                toAddress,
                parseUnits(amount, token.decimals),
            ]);
        } else {
            value = parseEther(amount).toString();
        }

        const nextNonce = await safeConnected?.service?.getNextNonce(
            getAddress(walletConnected.proxyFor)
        );

        const safeTransactionData: SafeTransactionDataPartial = {
            to: nativeToken ? getAddress(toAddress) : getAddress(token.address),
            value,
            data,
            operation: OperationType.Call,
            nonce: nextNonce,
        };

        try {
            return createAndProposeSafeTx(safeTransactionData)
                .then((receipt) => {
                    if (!receipt || !receipt.safeTxHash)
                        throw new Error(
                            `Sending the transaction to Safe failed`
                        );
                    return receipt.safeTxHash;
                })
                .catch((error) => {
                    console.error(`Failed to send EVM token payment: ${error}`);
                    throw error;
                });
        } catch (error) {
            console.error("Failed to send Safe token payment:", error);
            throw error;
        }
    };

    return {
        sendApproveAllownaceForSafe,
        sendWrapNativeToken,
        sendSafeTokenPayment,
    };
};

export { useSafeApi };
