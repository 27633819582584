import * as S from "./style";
import { Company } from "company/types";
import Section from "components/Section";
import Anchor from "components/Anchor";
import Table from "components/Table";
import ExternalLink from "components/icons/ExternalLink";
import InboundTreasuryTableCell from "company/components/InboundTreasuryTableCell";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";

const Entities = () => {
    const {
        config: { entities },
        getCompanyConfigIsFetching,
    } = useGetCompanyConfig();

    const childEntities = entities.filter(
        ({ parentId }: Company.Entity) => parentId
    );

    return childEntities.length === 0 ? (
        <Section>No entities to display</Section>
    ) : (
        <Section>
            <h2>Child Entities</h2>
            <Table
                refetching={getCompanyConfigIsFetching}
                data={{
                    headings: [
                        { label: `Entity ID` },
                        { label: `Name` },
                        { label: `Reply to` },
                        { label: `Inbound Treasuries` },
                        { label: `Logo`, style: { shrink: true } },
                        { label: `Website`, style: { shrink: true } },
                    ],
                    records: childEntities.map(
                        ({
                            entityId,
                            name,
                            inboundTreasuries,
                            replyTo,
                            logoUrl,
                            websiteUrl,
                        }: Company.Entity) => {
                            const renderInboundTreasuries = (
                                networkId: string
                            ) => {
                                const walletAddresses =
                                    inboundTreasuries &&
                                    inboundTreasuries[networkId];

                                if (walletAddresses) {
                                    return (
                                        <InboundTreasuryTableCell
                                            key={`${walletAddresses[0]}-${networkId}`}
                                            walletAddress={walletAddresses[0]} // Only show the first address for now
                                            networkId={Number(networkId)}
                                        />
                                    );
                                } else return `-`;
                            };

                            return {
                                id: entityId,
                                values: [
                                    {
                                        label: entityId,
                                        value: entityId,
                                        text: entityId,
                                    },
                                    {
                                        label: name,
                                        value: name,
                                        text: name,
                                    },
                                    {
                                        label: replyTo ? (
                                            <Anchor
                                                href={`mailto:${replyTo}`}
                                                inheritColor
                                            >
                                                {replyTo}
                                            </Anchor>
                                        ) : (
                                            `-`
                                        ),
                                        value: replyTo || ``,
                                        text: replyTo || `-`,
                                    },
                                    {
                                        label:
                                            inboundTreasuries &&
                                            Object.keys(inboundTreasuries)
                                                .length > 0 ? (
                                                <S.TreasuriesList>
                                                    {Object.keys(
                                                        inboundTreasuries
                                                    ).map(
                                                        renderInboundTreasuries
                                                    )}
                                                </S.TreasuriesList>
                                            ) : (
                                                `-`
                                            ),

                                        value: "Inbound Treasuries",
                                        text: "Inbound Treasuries",
                                    },
                                    {
                                        label:
                                            logoUrl &&
                                            logoUrl.trim() !== `-` ? (
                                                <Anchor href={logoUrl}>
                                                    <ExternalLink />
                                                </Anchor>
                                            ) : (
                                                `-`
                                            ),
                                        value: logoUrl || `-`,
                                        text: logoUrl || `-`,
                                        style: { textAlign: `center` },
                                    },
                                    {
                                        label: websiteUrl ? (
                                            <Anchor href={websiteUrl}>
                                                <ExternalLink />
                                            </Anchor>
                                        ) : (
                                            `-`
                                        ),
                                        value: websiteUrl || `-`,
                                        text: websiteUrl || `-`,
                                        style: { textAlign: `center` },
                                    },
                                ],
                            };
                        }
                    ),
                }}
            ></Table>
        </Section>
    );
};

export default Entities;
