import { useEffect } from "react";
import {
    Routes,
    Route,
    Navigate,
    useLocation,
    useNavigate,
} from "react-router-dom";
import { useSession } from "context/Session";
import { useNotificationQueue } from "context/NotificationQueue";
import Layout from "admin/Layout";
import HomeDashboard from "./HomeDashboard";
import { NotificationType } from "components/Notification";
import { UserRole } from "context/User";

// This role manager should ultimately live within the Session provider - something to add to a future update
const RoleManager = ({ children }: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { hasRole } = useSession();
    const { addNotification } = useNotificationQueue();

    useEffect(() => {
        if (!hasRole(UserRole.ADMIN)) {
            addNotification({
                msg: "You do not have permission to access this dashboard",
                type: NotificationType.ERROR,
            });
            navigate(`/login`);
        }
    }, [location.pathname, navigate]);

    return children;
};

const Admin = () => {
    return (
        <RoleManager>
            <Layout>
                <Routes>
                    <Route path="/" element={<HomeDashboard />} />
                    <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
            </Layout>
        </RoleManager>
    );
};

export default Admin;
