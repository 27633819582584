import styled from "styled-components";
import Copyable from "components/Copyable";

export const TableList = styled.ul`
    margin: 0;
    list-style: disc;
    padding-left: 1em;
    width: max-content;
`;

export const CopyAddress = styled(Copyable)`
    display: inline-flex;
`;

export const TreasuriesList = styled.ul`
    margin: 0;
    display: grid;
    gap: 0.5rem;
`;
