import { GetInvoiceEntityResponse } from "api/types/invoice";
import { getInvoiceEntity } from "api";
import { useCustomQuery } from "hooks/useCustomQuery";

interface UseGetInvoiceEntityProps {
    entityAlias: string | undefined;
}

interface UseGetInvoiceEntityReturn {
    data: GetInvoiceEntityResponse | undefined;
    loading: boolean;
    error: string;
    success: boolean;
}

export const GET_INVOICE_ENTITY_QUERY_KEY = `getInvoiceEntity`;

const useGetInvoiceEntity = ({
    entityAlias,
}: UseGetInvoiceEntityProps): UseGetInvoiceEntityReturn => {
    const { data, isError, isLoading, isPending, isSuccess } = useCustomQuery({
        queryKey: [GET_INVOICE_ENTITY_QUERY_KEY, entityAlias],
        queryFn: () => getInvoiceEntity(entityAlias || ""),
    });

    return {
        data,
        loading: isLoading || isPending,
        error: isError ? "Error fetching invoice entity" : "",
        success: isSuccess,
    };
};

export default useGetInvoiceEntity;
