import styled from "styled-components";
import {
    properties as p,
    colorsDeprecated as c,
    sizes as s,
} from "global-style";
import Card from "components/Card";
import Button from "components/Button";
import NetworkSelect from "components/NetworkSelect";
import { selectArrow, selectArrowPurple } from "components/Select/styles";
import colors from "theme/colors";
import spacing from "theme/spacing";
import { boxInputStyles } from "theme/shared";
import CheckmarkIcon from "components/CheckmarkIcon";

export const WalletConnect = styled.div`
    display: grid;
    gap: 0.5rem;
    margin: 1rem 0;
    align-items: center;
    width: fit-content;
    width: 100%;
    grid-template-columns: minmax(calc(50% - 0.25rem), 12rem) minmax(
            calc(50% - 0.25rem),
            12rem
        );
    @media screen and (min-width: ${s.sm}) {
        width: 100%;
        grid-template-columns: 12rem 12rem;
    }
`;
export const WalletBtn = styled.ul`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin: 0;
`;
export const Logo = styled.div`
    height: 1.5rem;
    aspect-ratio: 1 / 1;
    img {
        height: 100%;
    }
`;
export const SettingChain = styled.div`
    grid-column: 1 / -1;
    line-height: 1.24;
    text-align: right;
    & > * {
        margin: 0;
    }
`;
export const SelectNetwork = styled(NetworkSelect)`
    font-weight: 600;
`;
export const WalletSelect = styled.span`
    position: relative;
`;
export const AddrSelected = styled.span`
    flex-grow: 1;
    text-align: start;
`;
export const WalletPanel = styled(Card)`
    position: absolute;
    z-index: ${p.zIndex.wallet};
    top: calc(100% + 0.25rem);
    right: 0;
    margin: 0;
    border: 1px solid ${c.border};
    width: fit-content;
    max-width: calc(100vw - 2rem);
    @media screen and (min-width: ${s.sm}) {
        max-width: calc(100vw - 4rem);
    }

    h1 {
        font-size: 1.2rem;
    }
`;
export const CurrentWallet = styled(Button)`
    font-family: ${p.font.monospace};
    font-weight: 400;
    padding: ${spacing.xxs} ${spacing.xxs};
    ${boxInputStyles}

    & > span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &::after {
        content: "";
        display: block;
        font-size: 0.75em;
        width: 1em;
        height: 1em;
        background-image: url("${selectArrow}");
        background-position: center;
        background-repeat: no-repeat;
        transform: rotate(0deg);
        transition: transform ${p.speed};
        flex-shrink: 0;
    }
    &:focus,
    &:hover {
        &::after {
            background-image: url("${selectArrowPurple}");
        }
    }
    &[open],
    &[open] ~ ${WalletPanel} {
        outline: ${p.focus};
    }
    &[open]::after {
        transform: rotate(180deg);
    }
`;

export const Wallets = styled.ul`
    margin-block: 0 1rem;
`;

export const Wallet = styled.li`
    padding-block: 1rem;
    &:not(:last-child) {
        border-bottom: 1px solid ${c.border};
    }
`;

export const WalletHeading = styled.h2`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-block: 0.25em !important;
    font-size: 1rem !important;
`;

export const WalletAccount = styled.p`
    margin-block: 0;
    white-space: nowrap;

    &:has(svg) {
        margin-right: 1rem;
    }
`;

export const SafeWallets = styled.section`
    margin-block-start: 0.5rem;
    padding-inline-start: 0.25rem;
`;

export const Address = styled(Button)`
    font-family: ${p.font.monospace};
    padding-inline: 0.25rem;
    border-radius: ${p.radiusSm};
    width: fit-content;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:not([disabled]):hover {
        cursor: pointer;
        background-color: ${c.onTertiaryHover};
    }
    &[disabled],
    &[data-proxying="true"] {
        color: ${colors.primary};
    }
`;

export const WalletLogo = styled.img`
    height: 1.5rem;
    vertical-align: middle;
`;

export const SafeLogo = styled.img`
    height: 1rem;
    vertical-align: middle;
    margin: 0 0.125rem 0.2rem;
`;

export const ActiveAcct = styled(CheckmarkIcon)`
    margin-right: -1rem;
`;

export const WalletName = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const ConnectButton = styled(Button)`
    justify-self: end;
    grid-column: 1 / -1;
`;
