import styled from "styled-components";
import { Button } from "components/Button/style";

export const ButtonsContainer = styled.p`
    margin-top: 2em;
    ${Button} {
        margin-right: 1rem;
    }
`;
export const Paragraph = styled.p`
    margin-top: 1rem;
    margin-bottom: 1rem;
`;
