import React from "react";

const PlusUser = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            name={name}
            width={width}
            height={height}
            fill={fill}
            viewBox="0 0 22 16"
            xmlns="http://www.w3.org/2000/svg"
        >
            {name && <title>{name}</title>}
            <path d="M14 8C16.21 8 18 6.21 18 4C18 1.79 16.21 0 14 0C11.79 0 10 1.79 10 4C10 6.21 11.79 8 14 8ZM14 2C15.1 2 16 2.9 16 4C16 5.1 15.1 6 14 6C12.9 6 12 5.1 12 4C12 2.9 12.9 2 14 2ZM14 10C11.33 10 6 11.34 6 14V16H22V14C22 11.34 16.67 10 14 10ZM8 14C8.22 13.28 11.31 12 14 12C16.7 12 19.8 13.29 20 14H8ZM5 11V8H8V6H5V3H3V6H0V8H3V11H5Z" />
        </svg>
    );
};

export default PlusUser;
