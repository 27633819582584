import styled from "styled-components";
import { sizes as s, properties as p } from "global-style";
import Card from "components/Card";
import { CardHeader } from "components/Card";

export const RecurringSwapForm = styled(Card)`
    max-width: ${s.sm};
    box-shadow: ${p.shadowLg};
    margin: 1rem auto;
`;

export const SwapHeader = styled(CardHeader)`
    display: flex;
    align-items: center;
    gap: 1rem;
    h1 {
        font-size: 2rem;
        margin: 0;
        flex-grow: 1;
    }
`;

export const InfoText = styled.p`
    font-size: 0.8em;
    margin-bottom: 0.5em;
`;

export const FooterTxt = styled.p`
    font-size: 0.8em;
    margin-top: 1.5em;
`;
