import { CreateItemRequest } from "company/types";
import * as S from "./style";
import { Ref } from "react";
import { onlyUnique } from "utils/arrays";
import Close from "components/icons/Close";
import { colorsDeprecated as c } from "global-style";
import { itemAmountForDisplay } from "utils/items";
import Pencil from "components/icons/Pencil";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";
import { strFrequency } from "utils/datetime";
import { firstToUpper } from "utils/strings";

export interface CreateItemRequestListRowProps {
    item: CreateItemRequest;
    onEditItem?: (item: CreateItemRequest) => void;
    onRemoveItem?: (item: CreateItemRequest) => void;
}

export type CreateItemRequestListRowRef = {
    itemSourceId: number;
};

function CreateItemRequestListRow(
    { item, onEditItem, onRemoveItem }: CreateItemRequestListRowProps,
    ref: Ref<CreateItemRequestListRowRef>
) {
    const { tokens } = useGetTokensMetadata();
    const { networks } = useGetNetworks();

    const networkIds = Object.keys(item.acceptedTokens || {}).map((n) =>
        Number(n)
    );
    const itemNetworks = networks.filter((network) =>
        networkIds.includes(network.id)
    );
    const itemTokens = networkIds
        .map((networkId) => {
            const tokenAddresses =
                item.acceptedTokens && item.acceptedTokens[networkId];
            const networkTokens = tokens.filter(
                (token) =>
                    tokenAddresses &&
                    tokenAddresses.includes(token.address.toLocaleLowerCase())
            );
            return networkTokens;
        })
        .flat();

    return (
        <S.CreateItemRequestListRow>
            <S.ItemInformation>
                <strong>{item.name}</strong>
                <span>
                    {itemAmountForDisplay(
                        item.amount !== null ? item.amount * 100 : null
                    )}
                    {" / "}
                    {strFrequency(
                        item.frequencyCount,
                        item.frequency?.toLocaleLowerCase()
                    )}
                </span>
                {onEditItem && (
                    <S.EditItem onClick={() => onEditItem(item)}>
                        <Pencil
                            fill={c.popoverCloseLink}
                            height="1rem"
                            width="1rem"
                        />
                    </S.EditItem>
                )}
            </S.ItemInformation>
            <S.ItemAcceptedTokens>
                {itemTokens.length === 0 && <>No accepted tokens</>}
                {itemTokens.length > 0 && (
                    <>
                        Pay with{" "}
                        <strong>
                            {itemTokens
                                .map((t) => t.symbol)
                                .filter(onlyUnique)
                                .join(", ")}
                        </strong>{" "}
                        on{" "}
                        <strong>
                            {itemNetworks
                                .map((n) => firstToUpper(n.name))
                                .join(", ")}
                        </strong>
                    </>
                )}
            </S.ItemAcceptedTokens>

            {onRemoveItem && (
                <S.RemoveItem onClick={() => onRemoveItem(item)}>
                    <Close
                        fill={c.popoverCloseLink}
                        height="0.75rem"
                        width="0.75rem"
                    />
                </S.RemoveItem>
            )}
        </S.CreateItemRequestListRow>
    );
}

export default CreateItemRequestListRow;
