import * as S from "company/components/InvoiceForm/style";
import { useMemo } from "react";
import { firstToUpper } from "utils/strings";
import {
    CommonBlockchainNetworkResponse,
    GeneralTokenDetailsResponse,
} from "api";
import { Company, CompanyAgreementResponse } from "company/types";

interface PanelSummaryProps {
    Panels: Record<string, string>;
    panelsOpen: Record<string, { open: boolean }>;
    agreement?: CompanyAgreementResponse;
    item?: Company.Item;
    agreements: CompanyAgreementResponse[];
    network?: CommonBlockchainNetworkResponse;
    token?: GeneralTokenDetailsResponse;
    valid: boolean;
    isFormWorking: boolean;
}

const usePanelSummaryAsInvoiceFormStatus = ({
    Panels,
    panelsOpen,
    agreement,
    item,
    agreements,
    network,
    token,
    valid,
    isFormWorking,
}: PanelSummaryProps) => {
    const summaryItem = useMemo(() => {
        if (panelsOpen[Panels.ITEM].open || !item) {
            return (
                <S.SummaryRow isNextStep={!agreement?.id}>
                    <span>
                        {isFormWorking && valid ? <S.CheckDone /> : `1.`}
                    </span>
                    <span>Select a subscription or one-time payment</span>
                </S.SummaryRow>
            );
        } else {
            return (
                <S.SummaryRow>
                    <S.CheckDone />
                    <span>
                        {!item.frequency?.value
                            ? `One-time payment: `
                            : item.type
                            ? `${item.type}: `
                            : ``}
                        <S.SummaryValue>
                            {firstToUpper(item.name)}
                        </S.SummaryValue>
                    </span>
                </S.SummaryRow>
            );
        }
    }, [Panels.ITEM, panelsOpen, agreement?.id, item, isFormWorking, valid]);

    const summaryAgreement = useMemo(() => {
        if (panelsOpen[Panels.AGREEMENT].open || !agreement?.id) {
            return (
                <S.SummaryRow isNextStep={!!item?.id && !agreement?.id}>
                    <span>
                        {isFormWorking && valid ? <S.CheckDone /> : `2.`}
                    </span>
                    <span>Choose a customer to invoice</span>
                </S.SummaryRow>
            );
        } else {
            const agrmnt = agreements.find(({ id }) => id === agreement?.id);
            return (
                <S.SummaryRow>
                    <S.CheckDone />
                    <span>
                        Customer:{" "}
                        <S.SummaryValue>
                            {agrmnt?.sender.email || agrmnt?.sender.wallet} with{" "}
                            {token?.symbol} ({firstToUpper(network?.name || ``)}
                            )
                        </S.SummaryValue>
                    </span>
                </S.SummaryRow>
            );
        }
    }, [
        Panels.AGREEMENT,
        item?.id,
        panelsOpen,
        agreement?.id,
        agreements,
        network?.name,
        token?.symbol,
        isFormWorking,
        valid,
    ]);

    const summarySchedule = useMemo(() => {
        if (isFormWorking && valid) {
            return (
                <S.SummaryRow>
                    <S.CheckDone />
                    <span>Scheduling payments</span>
                </S.SummaryRow>
            );
        } else {
            return (
                <S.SummaryRow
                    isNextStep={
                        !!item?.id &&
                        !!agreement?.id &&
                        !isFormWorking &&
                        !valid
                    }
                >
                    <span>3.</span>
                    <span>Complete the payment details</span>
                </S.SummaryRow>
            );
        }
    }, [item?.id, agreement?.id, isFormWorking, valid]);

    return { summaryItem, summaryAgreement, summarySchedule };
};

export { usePanelSummaryAsInvoiceFormStatus };
