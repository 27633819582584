import React from "react";

const Invoice = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "currentColor",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            viewBox="0 0 18 20"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            {name && <title>{name}</title>}
            <path
                d="M16.5 1.5L15 0L13.5 1.5L12 0L10.5 1.5L9 0L7.5 1.5L6 0L4.5 1.5L3 0L1.5 1.5L0 0V20L1.5 18.5L3 20L4.5 18.5L6 20L7.5 18.5L9 20L10.5 18.5L12 20L13.5 18.5L15 20L16.5 18.5L18 20V0L16.5 1.5ZM16 17.09H2V2.91H16V17.09ZM3 13H15V15H3V13ZM3 9H15V11H3V9ZM3 5H15V7H3V5Z"
                fill="inherit"
            />
        </svg>
    );
};

export default Invoice;
