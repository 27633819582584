import styled from "styled-components";

export const BillDateContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1.5rem;
    align-items: center;
    margin-bottom: 0.5rem;
`;

export const AddDateContainer = styled.span`
    margin-right: 0.5rem;
    margin-left: 0.5rem;
`;
