import React from "react";

const Cart = ({
    name,
    width = `1.5rem`,
    height = `1.5rem`,
    fill = "black",
    ...props
}: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
            role="img"
            name={name}
            width={width}
            height={height}
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 21 20"
            {...props}
        >
            {name && <title>{name}</title>}
            <path
                d="M14.55 11c.75 0 1.41-.41 1.75-1.03l3.58-6.49A.996.996 0 0 0 19.01 2H4.21l-.94-2H0v2h2l3.6 7.59-1.35 2.44C3.52 13.37 4.48 15 6 15h12v-2H6l1.1-2h7.45ZM5.16 4h12.15l-2.76 5H7.53L5.16 4ZM6 16c-1.1 0-1.99.9-1.99 2S4.9 20 6 20s2-.9 2-2-.9-2-2-2Zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2Z"
                fill="inherit"
            />
        </svg>
    );
};

export default Cart;
