import styled from "styled-components";

interface ImageProps {
    src: string;
    alt: string;
    width?: string;
    height?: string;
}

const Img = styled.img`
    ${({ width }: ImageProps) => width && `width: ${width}`}
    ${({ height }: ImageProps) => height && `height: ${height}`}
`;

const Image = (props: ImageProps) => {
    return <Img {...props} />;
};

export default Image;
