import * as S from "./style";
import {
    forwardRef,
    Ref,
    useRef,
    useImperativeHandle,
    useCallback,
    ChangeEvent,
} from "react";
import { validatePresence, validateUsDollars } from "utils/formValidation";
import Label from "components/Label";
import InputWithAddon from "components/InputWithAddon";

interface InvoiceAmountProps {
    defaultValue: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const InputInvoiceAmount = forwardRef(
    (
        { defaultValue, onChange }: InvoiceAmountProps,
        ref: Ref<InvoiceAmountFieldRef>
    ) => {
        const invoiceAmtRef = useRef<HTMLInputElement>(null);

        const validate = useCallback(() => {
            if (!invoiceAmtRef.current) return false;

            return (
                validatePresence({
                    input: invoiceAmtRef.current,
                    errorMessage: `Please enter an invoice amount`,
                }) && validateUsDollars({ input: invoiceAmtRef.current })
            );
        }, []);

        useImperativeHandle(
            ref,
            () => ({
                value: () => invoiceAmtRef.current?.value || ``,
                validate,
            }),
            [validate]
        );

        const handleAmountChange = useCallback(
            (event: ChangeEvent<HTMLInputElement>) => {
                if (onChange) onChange(event);
            },
            [onChange]
        );

        return (
            <S.FormFields>
                <Label htmlFor="invoiceAmt">Amount</Label>
                <InputWithAddon
                    innerRef={invoiceAmtRef}
                    name="invoiceAmt"
                    value={defaultValue}
                    placeholder="99.99"
                    onChange={handleAmountChange}
                    addon={{ content: `$` }}
                />
            </S.FormFields>
        );
    }
);

export default InputInvoiceAmount;
