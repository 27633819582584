import styled from "styled-components";
import Panel from "components/Panel";

export const DatePanel = styled(Panel)`
    margin-block-end: 0;
`;

export const SubMsg = styled.footer`
    margin-block-start: 1rem;
`;
