import { ReactNode, useContext, createContext } from "react";
import { WalletProviderValue, useWallet } from ".";
import { PrimaryWalletAccount } from "./hooks/useWalletConnected";

interface WalletConnectedContextValue
    extends Omit<WalletProviderValue, "walletConnected"> {
    walletConnected: PrimaryWalletAccount;
}

const WalletConnectedContext =
    createContext<WalletConnectedContextValue | undefined>(undefined);

export const WalletConnectedProvider: React.FC<{
    children: ReactNode;
}> = ({ children }) => {
    const { walletConnected, ...rest } = useWallet();

    if (!walletConnected) {
        return <></>;
    }

    return (
        <WalletConnectedContext.Provider value={{ walletConnected, ...rest }}>
            {children}
        </WalletConnectedContext.Provider>
    );
};

export const useWalletConnected = (): WalletConnectedContextValue => {
    const context = useContext(WalletConnectedContext);
    if (!context) {
        throw new Error(
            "useWalletConnected must be used within a WalletConnectedProvider"
        );
    }
    return context;
};
